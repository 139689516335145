import React, {Component} from 'react';

import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';
import moment from 'moment';
import { CircularProgress, Paper } from "@material-ui/core";

//import DateTimeRangePicker from '../components/DateTimeRangePicker.js';
import DateTimeRangePicker2 from '../components/DateTimeRangePicker2.js';
//import DamianDate from '../components/DamianDate.js';

import {
    Button,
    Grid,
    //Paper,
    //InputAdornment,
    TextField,
    FormControl,
} from '@material-ui/core/';

/*import {
  MuiPickersUtilsProvider,
  //KeyboardTimePicker,
  //KeyboardDatePicker,
} from '@material-ui/pickers';
*/
import GooglePlacesSearchWP from './GooglePlacesSearchWP.js';
import SelectFieldWP from '../components/forms/SelectFieldWP';

import CalendarIcon from '@material-ui/icons/CalendarToday';
import ClockIcon from '@material-ui/icons/AccessTime';

const MAX_SEARCH_DURATION_DAYS = 29;

const styles = {
    dateDropper: {
        float: 'right',
        marginRight: '20px',
    },

    searchControl: {
        //margin: '8px',
        //padding: '10px',
    },

    addressSearch: {
        borderBottom: '1px solid #e5e5e5'
    },

    /*searchButton: {
        fontSize: '20px',
    }*/
}


const SORTBY_OPTION_LIST = [
  {value:"0",label:"Sort by Price"}, 
  {value:"1",label:"Sort by Distance"}
];

const INCLUDE_ALL_LIST = [


  {value:"0",label:"Show available only"}, 
  {value:"1",label:"Include unavailable"}
];


function getTimeList(id, start, end){
  //console.log("getTimeList");
  //console.log({id});
  //console.log("start:",start.toString());
  //console.log("end:",end.toString());
  // 12:00AM - 11:45PM
  let startIndex=0;
  if (id === 'end'){
    //console.log("getTimeList - END");
    // From the momentjs docs: When including a second parameter, it will match all units equal or larger.
    // Passing in month will check month and year. Passing in day will check day, month, and year.
    if (end.isSame(start, 'day')){
      //console.log("same day!");
      let startHour = start.get('hour');
      let startMinute = start.get('minute');
      //console.log("startHour:",startHour);
      //console.log("startMinute:",startMinute);

      startIndex = (startHour *4) + startMinute/15;
      //console.log("startIndex:",startIndex);
      startIndex++;
      if (startIndex > 95){
        startIndex = 95;
      }
    }

  }

  let arrTimes = [];
  for (let i=startIndex;i<96;i++){

    let intHour = Math.floor(i/4);
    let intMinutes = (i%4)*15;

    //let value = `${("00" + Math.floor(i/4)).substr(-2)}:${("00" + (i%4)*15).substr(-2)}`;
    let value = `${("00" + intHour).substr(-2)}:${("00" + intMinutes).substr(-2)}`;
    let strLabel;
    
    if (intHour >= 12){
      if (intHour === 12){ intHour += 12; } 
      strLabel = `${intHour-12}:${("00" + intMinutes).substr(-2)} PM`;
    
      
    } else {
      if (intHour == 0){ intHour = 12; }
      strLabel = `${intHour}:${("00" + intMinutes).substr(-2)} AM`;
    }

    arrTimes.push({value: value, label: strLabel});
    //
  }

  return arrTimes;
}





export default class ParkingSearch2 extends Component {
  constructor(props) {
    super(props)
    //console.log("parkingsearch2 CONTSTRUCTOR:", this.props);
    /*    

    console.log("startTime:",this.props.startTime);
    console.log("endTime:",this.props.endTime);
   
    console.log("startTime mins:",this.props.startTime.format('hh:mm'));
    console.log("endTime mins:",this.props.endTime.format('hh:mm'));

    console.log("startDate:",moment(this.props.msm_startdate).toDate());
    console.log("startDate2:",moment(this.props.startTime).toDate());
*/

    
    this.state = {
      //searchInProgress:false
    }
  }

  // Performance - only render when appropriate props have changed
  shouldComponentUpdate(nextProps, nextState){
    //console.log("ParkingSearch2:shouldComponentUpdate");
    //console.log("this.props:",this.props);
    //console.log({nextProps});

    //console.log({nextState});
    
    //console.log("FORCE TRUE");
    //return true;

    const props = this.props;

    if (
      props.address !== nextProps.address ||
      //props.availability_mode !== nextProps.availability_mode ||
      //props.msm_startdate !== nextProps.msm_startdate ||
      props.desktopDisplay !== nextProps.desktopDisplay ||
      props.includeAll !== nextProps.includeAll ||
      props.startTime !== nextProps.startTime ||
      props.endTime !== nextProps.endTime ||
      props.sortBy !== nextProps.sortBy ||

      props.browserCSSMode !== nextProps.browserCSSMode
      ){
      //console.log("ParkingSearch2 PROPS CHANGE, return TRUE");
      return true;

    } else {
      //return false;
      //TEMP
      return true;

    }
  }



  handleDateChange(id, inDate){
    // CLONE the dates (moment mutates the original values - so create brand new dates we pass back up the stack)
    let start = this.props.startTime.clone();
    let end = this.props.endTime? this.props.endTime.clone(): null;

    //console.log("#start:",start);
    //console.log("#end:",end);

    

    //console.log("dateChangeId:",id);
    //console.log("dateChange:",inDate);
    // Update DATE portions, leave the TIME alone
    if (id==='start'){
      start.year(inDate.year());
      start.month(inDate.month());
      start.date(inDate.date());
    } else {
      end.year(inDate.year());
      end.month(inDate.month());
      end.date(inDate.date());
    }

    // If end is before start, reset to default +1 hours
    if (end && end.isBefore(start)){
      end = start.clone();
      end.add(1,'hour');
    }

    // Max next day at 11.45pm for now (Multi-Day and Long terms are coming soon)
    if (end && moment.duration(end.diff(start)).asDays() > MAX_SEARCH_DURATION_DAYS){    // *** 2 [DAMIAN]
      //console.log("end is too far away");
      //end = start.clone();
      end.year(start.year());
      end.month(start.month());
      end.date(start.date());
      end.add(24,'hour');

    }


    if (end && end.isSame(start, 'minute')){
      //console.log("same!");
      end.add(15,'minute');
    }

    //console.log("start:",start);
    //console.log("end:",end);
    this.props.onTimeRangeSelected(start,end);
  }
  /*handleTimeRangeSelected = (start, end) => {
    // Will trigger entire new search, as if they clicked handleSearchClick()
    this.setState({
        startTime: start,
        endTime: end,
    })
  }*/
  handleTimeChange(id, inTime){
    //console.log(`change time: ${id} to ${inTime}`);
    // CLONE the dates (moment mutates the original values - so create brand new dates we pass back up the stack)
    let start = this.props.startTime.clone();
    let end = this.props.endTime.clone();

    let newTime = moment(inTime, 'HH:mm');  // From 24hrs
    //console.log("newTime:",newTime);

    //console.log("hour:",newTime.get('hour'));
    //console.log("minute:",newTime.get('minute'));

    if (id==='start'){
      start.hour(newTime.get('hour'));
      start.minute(newTime.get('minute'));
    } else {
      end.hour(newTime.get('hour'));
      end.minute(newTime.get('minute'));
    }
    // If end is before start, reset to default +1 hours
    if (end.isBefore(start)){
      end = start.clone();
      end.add(1,'hour');
    }
    if (end.isSame(start, 'minute')){
      //console.log("same!");
      end.add(15,'minute');
    }
    
    //console.log("start:",start);
    //console.log("end:",end);

    this.props.onTimeRangeSelected(start,end);
  }

  render() {
    //console.log("ParkingSearch:RENDER",this.props);
    

    const { browserCSSMode, searchMode } = this.props;
    const isMobile = browserCSSMode === "mobile" ? true:false;
    const isDesktop = isMobile ? false:true;

    const minDate = searchMode == 1 ? addDays(new Date(),2):addDays(new Date(),0);  //Move to this.state 


    //console.log({searchMode});
    let dateFormatStart = "eee dd MMM";
    let dateFormatEnd = "eee dd MMM";
    if (this.props.startTime.isSame(moment(), 'day')){ dateFormatStart = "'Today'"; }
    if (this.props.endTime && this.props.endTime.isSame(moment(), 'day')){ dateFormatEnd = "'Today'"; }
    
    return (
      <>
      <div className="wps-container noselect">
        <div className="wps-searchcontrols">
          {isDesktop &&
            <div className="wps-searchmode">
            {this.props.searchMode === 0 ? <span className='active'>Casual</span>:<span onClick={()=>this.props.onSearchModeChange(0)}>Casual</span>}
            {this.props.searchMode === 1 ? <span className='active'>Monthly</span>:<span onClick={()=>this.props.onSearchModeChange(1)}>Monthly</span>}
          </div>
          }
          
          
          <p>Find Parking Near</p>
          <GooglePlacesSearchWP
            name="address" 
            onLocationSelected={this.props.onLocationSelected} 
            address={this.props.address}
            desktopDisplay={isDesktop} // this is not used anymore???
            browserCSSMode={browserCSSMode}  // destkop/mobile
          />
          {isMobile &&
            <DateTimeRangePicker2 searchMode={this.props.searchMode} onSearchModeChange={this.props.onSearchModeChange} startMoment={this.props.startTime} endMoment={this.props.endTime} onDateRangeChange={this.props.onTimeRangeSelected} />
          }

          {isDesktop &&
          <>
            <div className="wps-dategrid">
              <div className="left">
                {this.props.searchMode === 1 ? <p>Monthly Parking Starting</p>:<p>Start</p>}
                <div style={{position:'relative', marginBottom:'8px'}}>
                  <CalendarIcon className="wps-startdate2-icon" />
                  {<DatePicker
                    showPopperArrow={false}
                    selected={this.props.startTime.toDate()}                          // PERFORMANCE: move out of render loop
                    onChange={(date) => this.handleDateChange('start',moment(date))}  // PERFORMANCE: move out of render loop
                    minDate={minDate}                                   // PERFORMANCE: move out of render loop
                    maxDate={addDays(new Date(),180)}                                 // PERFORMANCE: move out of render loop
                    withPortal={isMobile}
                    dateFormat={dateFormatStart}
                    className="wps-startdate2"
                  />}
                </div>
                {this.props.searchMode === 0 &&
                <div style={{position:'relative', backgroundColor:'#ffff99'}}>
                  <ClockIcon className="wps-starttime-icon"/>
                  <SelectFieldWP
                    name="start_clock"
                    value={this.props.startTime.format('HH:mm')}                              // PERFORMANCE: move out of render loop
                    options={getTimeList('start', this.props.startTime, this.props.endTime)}  // PERFORMANCE: move out of render loop
                    InputProps={{
                      disableUnderline: true,
                    }}
                    classes={{input: "wps-starttime"}}
                    onChange={(e) => this.handleTimeChange('start',e.target.value)}           // PERFORMANCE: move out of render loop
                  />
                </div>
                }
              </div>

              {this.props.searchMode === 0 &&
              <div className="right">
                <p>End</p>
                <div style={{position:'relative', marginBottom:'8px'}}>
                  <CalendarIcon className="wps-startdate2-icon" />
                  {<DatePicker
                    showPopperArrow={false}
                    selected={this.props.endTime.toDate()}                            // PERFORMANCE: move out of render loop
                    onChange={(date) => this.handleDateChange('end',moment(date))}    // PERFORMANCE: move out of render loop
                    minDate={this.props.startTime.toDate()}                           // PERFORMANCE: move out of render loop
                    maxDate={moment(this.props.startTime).add(MAX_SEARCH_DURATION_DAYS-1,'day').toDate()}      // PERFORMANCE: move out of render loop // *** 1 [DAMIAN]
                    withPortal={isMobile}
                    dateFormat={dateFormatEnd}
                    className="wps-startdate2"
                  />}
                </div>

                {this.props.searchMode === 0 &&
                <div style={{position:'relative', backgroundColor:'#ffff99'}}>
                  <ClockIcon className="wps-starttime-icon"/>
                  <SelectFieldWP
                    name="end_clock"
                    value={this.props.endTime.format('HH:mm')}                              // PERFORMANCE: move out of render loop
                    options={getTimeList('end', this.props.startTime, this.props.endTime)}  // PERFORMANCE: move out of render loop
                    InputProps={{
                      disableUnderline: true
                    }}
                    classes={{input: "wps-starttime"}}
                    onChange={(e) => this.handleTimeChange('end',e.target.value)}     // PERFORMANCE: move out of render loop
                  />
                </div>
                }
              </div>
              }

            </div>

            {this.props.searchMode >= 0 &&
            <FormControl style={{marginLeft:'10px'}}>
              <SelectFieldWP
                name="sort_by"
                value={this.props.sortBy}
                options={SORTBY_OPTION_LIST}
                InputProps={{
                  disableUnderline: true,
                }}
                onChange={(e) => this.props.onSortByChange(e.target.value)}
              />              
            </FormControl>
            }

            {/*<FormControl style={{marginLeft:'10px'}}>
              <SelectFieldWP
                name="include_all"
                value={this.props.includeAll}
                options={INCLUDE_ALL_LIST}
                InputProps={{
                  disableUnderline: true,
                }}
                //onChange={(e) => this.props.onAvailibilitySelected(e.target.value)}
                //onChange={(e) => this.handleTimeChange('end',e.target.value)}
              >
              </SelectFieldWP>
              </FormControl>*/}
          </>
          }


        </div>
      </div>
      </>
    ); 

  }
}
