import React, {Component} from 'react';

import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';

//DatePickerField
export default class BookingBlocks extends Component{

  constructor(props){
  super(props);
    //console.log("bookingblocks:CONSTRUCTOR");
    //console.log("uuid:",this.props.uuid);
    //let thisSpace = (this.props.testBlocks && this.props.testBlocks.filter(u => u.uuid === this.props.uuid)[0]) || {};
    //console.log("thisSpace:",thisSpace);
    const baseDate = this.props.start && this.props.start.clone().set("date",1);  // Default to first day of current month

    //console.log(props);

    /*if (thisSpace.recurring) {
      recurring = thisSpace.recurring[0];
    }*/
    const spaceData = this.props.spaceData;
    
    this.state = {
      loaded:true,
      blocks:spaceData.blocksADMIN,
      recurring:spaceData.recurring,
      applied:spaceData.applied,
      //spaceData: thisSpace,
      baseDate,
      fullSize:false,
      bayIndex:1


    };
  }


  componentWillUnmount(){
    this._isMounted = false;
  }    

  componentDidMount(){
    this._isMounted = true;
    let that = this;

    /*setTimeout(() => {
      this.setState({ loaded: true });
    }, 1000);  */
  }


  render(){
    const { loaded, blocks, recurring, applied, baseDate, bayIndex, fullSize } = this.state;
    const spaceData = this.props.spaceData;   //TEMP
    //console.log("spaceData:", spaceData);
    if (!loaded){
      return (
        <div className="bb">
          <h1>ADMIN - Availability</h1>
          <div className="bb-close" onClick={this.props.onClose}>Close</div>
          <CircularProgress  size={70} thickness={4.5} style={{color:"var(--heroColor)",position:'absolute',left:'calc(50% - 35px)',top:'calc(50%)'}} />

        </div>
      );
    }

    //console.log(blocks);
    let arrDays = [];

    let dayId = baseDate.format('YYMMDD'); // 211001;
    const arrDOW = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
    const arrDOW2 = ['day_sunday','day_monday','day_tuesday','day_wednesday','day_thursday','day_friday','day_saturday'];
    const arrColors = ['full-a','full-b'];

    let lastBookingId = -1;
    let bookingColorIndex = -1;

    /*if (!recurring){
      return (
        <div className="bb">
          <div className="bb-close" onClick={this.props.onClose}>Close</div>
          UNAUTHORIZED - MISSING RECURRING
        </div>);
    }*/

    let totalDays = moment(dayId, "YYMMDD").daysInMonth();
    for (let d=0;d<totalDays;d++){
      
      let thisDay = [];
      let myday = moment(dayId, "YYMMDD");
      let intDow = myday.day();
      let dow = arrDOW[myday.day()];

      for (let i=0;i<96;i++){
        let strTitle = `${("00" + Math.floor(i/4)).substr(-2)}:${("00" + (i%4)*15).substr(-2)} to ${("00" + Math.floor(i/4)).substr(-2)}:${("00" + ((((i%4)+1)*15)-1) ).substr(-2)} [#${i}]`;

        let bookingId = null;
        if (blocks && blocks[`${dayId}_${bayIndex}`]){
          if (blocks[`${dayId}_${bayIndex}`][i] != 0){
            bookingId = blocks[`${dayId}_${bayIndex}`][i];
          }
        }

        if (bookingId){
          if (bookingId != lastBookingId){
            lastBookingId = bookingId;
            bookingColorIndex++;
            if (bookingColorIndex >= arrColors.length){
              bookingColorIndex = 0;
            }
          
          }
          // External link to view booking in CRM
          //thisDay.push(<div key={i} className={"bb-ts " + arrColors[bookingColorIndex]} title={strTitle + ` (BookId: ${bookingId})`}></div>);
          //let href = `http://localhost:8000/grandmaster/apiv1/bookingsingle/${bookingId}/change/`;
          let href = `${this.props.user.profile.admin_endpoint}/bookingsingle/${bookingId}/change/`;
          thisDay.push(<a key={i} href={href} target="_blank" className={"bb-ts " + arrColors[bookingColorIndex]} title={strTitle + ` (BookId: ${bookingId})`}></a>);
        } else {
          if (i >= 32 && i<=71 && intDow >= 1 && intDow <=5){
            thisDay.push(<div key={i} className="bb-ts empty-bh" title={strTitle}></div>);
          } else {
            thisDay.push(<div key={i} className="bb-ts empty" title={strTitle}></div>);
          }
          
        }
      }

      let divDOW;
      if (true || (recurring && recurring[arrDOW2[intDow]] == true)){
        divDOW = <div className="bb-dow-yes">{dow}</div>;
      } else {
        divDOW = <div className="bb-dow-no">{dow}</div>;
      }
      
      if (blocks && blocks[`${dayId}_${bayIndex}`]){
        arrDays.push(<div key={d} className="bb-day">{divDOW}<div className="bb-title-dom">{d+1}</div><div className="bb-title">{dayId}</div>{thisDay}<div className="bb-title">{dayId}</div></div>);
      } else {
        arrDays.push(<div key={d} className="bb-day">{divDOW}<div className="bb-title-empty-dom">{d+1}</div><div className="bb-title-empty">{dayId}</div>{thisDay}<div className="bb-title-empty">{dayId}</div></div>);
      }
      
      dayId++;
    }


    const strStart = this.props.start && this.props.start.format('ddd DD MMM hh:mma');
    const strEnd = this.props.end && this.props.end.format('ddd DD MMM hh:mma');

    let divAvailabilityResult;
    if (spaceData.available){
      divAvailabilityResult = <span className="available-yes">AVAILABLE - Bay#{spaceData.bay_index}</span>;
    } else {
      divAvailabilityResult = <span className="available-no">Not Available</span>;
    }

    // Month Selector
    let divMonth;
    divMonth = (
      <div className="bb-month-selector">
        <div className="button" onClick={() => this.setState({baseDate: baseDate.add(-1,'M')})}>&lt;</div>
        <div className="month-name">{baseDate.format('MMMM YYYY')}</div>
        <div className="button" onClick={() => this.setState({baseDate: baseDate.add(1,'M')})}>&gt;</div>
      </div>
    );

    // BayIndex Selector
    let divBayIndex;
    divBayIndex = (
      <div className="bb-bayindex-selector">
        <div className="button" onClick={() => this.setState({bayIndex: bayIndex-1})}>&lt;</div>
        <div className="month-name">{bayIndex} of {spaceData.capacity}</div>
        <div className="button" onClick={() => this.setState({bayIndex: bayIndex+1})}>&gt;</div>
      </div>
    );


    
    let strClass = "bb noselect";
    if (!fullSize){
      strClass += " shrink";
    }
    //console.log(spaceData);

      return (
        <div className={strClass}>
          <div className="bb-header">
            <div className="bb-zoom" onClick={()=>this.setState({fullSize:!fullSize})}>Zoom</div>
            <h1>ADMIN - Availability</h1>
            <p><b>Search Range:</b> {strStart} - {strEnd}</p>
            <p><b>Result:</b> {divAvailabilityResult}</p>
            <div className="bb-close" onClick={this.props.onClose}>Close</div>
          </div>
          {divBayIndex}
          {divMonth}
          {arrDays}
          <br/>
          
          <pre>Availability_Recurring:{JSON.stringify(recurring,null,2)}</pre>
          <pre className="applied">Applied:{JSON.stringify(applied,null,2)}</pre>
          
        </div>
      );
  }


}

