import React, { Component } from 'react';
//import {Select,Progress,Card,Divider,Transition, Dimmer, Loader, Grid,Form, Segment, Menu, Container, Step, Label, Button, Input, Message, Icon, List, Dropdown, Modal, Header } from 'semantic-ui-react';
//import {Form, Input, Label} from 'semantic-ui-react';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Typography
} from '@material-ui/core';


import { FieldType } from '../api/fieldTypes.js';

class TextArea extends Component {
  constructor(props) {
    super(props);

    //console.log("[TEXTSTRING:constructor]");
    //console.log("TextString: ",this.props);

    let inValue = this.props.initialValue || '';

    var placeholder;
    var labelLeft;
    var labelRight;
    var labelPosition;
    var maxLength;
    const myType = this.props.field.type;
    var keyboardType = 'text';

    placeholder = this.props.field.placeholder || '';
    maxLength = 2000;

    var errorMessage = this.props.error; //start null

    this.state = {
      myType,
      isVisible: true,
      value: inValue,
      placeholder,
      maxLength,
      keyboardType,
      errorMessage
    };


    this.onFocus = this.onFocus.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }


  onFocus(e) {
    //e.target.select();
  }

  onKeyPress(e) {
    /*    if (this.state.isLocked){
          return;
        }
    */
    if (e.key === 'Enter') {
      e.target.blur();
    }

  }

  //========================
  //Live Validation
  //========================
  onChange(e) {
    var inText = e.target.value;
    
    // if needed, accept only valid characters while typing
    //console.log("inText:",inText);
    
    this.setState({value: inText}); 
  }


  //========================
  //Final Validation
  //========================
  onBlur(e) {

    let inText = e.target.value;
    let finalValue = inText;

    const {myType} = this.state;
    var strErrorMessage = null;

    if (inText.length === 0){
      // Allow blank values, will be caught later
    } else if (myType === FieldType.TEXT_EMAIL){
      //=== EMAIL ===
        let inPattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
        let regex = new RegExp(inPattern,"i"); 

        if (!regex.test(inText)){
          strErrorMessage = "Please enter a valid email address";
          finalValue = '';
        }

    }

    //Send the valid value to the parent form
    if (this.props.onUpdate){
      this.props.onUpdate({id:this.props.field.id,finalValue});
    } 
    // ?? strErrorMessage = "xxxxxxx";
    this.setState({errorMessage:strErrorMessage});
  }


  render() {
    const {errorMessage} = this.state;

    return (
        <TextField
          maxLength={this.state.maxLength}
          onChange={this.onChange}  
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          label={this.props.field.label}
          style={{marginTop:'0'}}
            multiline
            value={this.state.value}
            rows={5}
            //label={this.props.field.label}
            //name="description"
            type="text"
            InputLabelProps={{
                shrink: true
            }}
            InputProps={{
              disableUnderline: true, 
              inputProps:{
                  //style:{paddingBottom: '5px'}
              },
            }}
            disabled={this.props.field.readOnly}     
            placeholder={this.state.placeholder}
            fullWidth
            margin="normal"
            error={this.props.error ? true:false}
            helperText={this.props.error}
        />

        
    );
    /*return (
      <Form.Field inline id={this.props.field.id} error={errorMessage ? true:false}>
        <label>{this.props.field.label}</label>
        <Input type={this.state.keyboardType} value={this.state.value} placeholder={this.state.placeholder} className={"field-text"}
               maxLength={this.state.maxLength} onChange={this.onChange} onBlur={this.onBlur} onFocus={this.onFocus}
               onKeyPress={this.onKeyPress} autoComplete="new-password" autoCorrect="off" autoCapitalize="words"
               spellCheck="false">
          <input/>
        </Input>
        <label>{errorMessage}</label>

      </Form.Field>
    );
*/

  }

}

export default TextArea;
