/* 
Conversation Inbox and Viewer
Attempting to migrate codebase to React Hooks (as much as possible)
*/
import React, {useState, useEffect} from "react";
import moment from 'moment';
import {Link} from 'react-router-dom';

import {getConversations, replyToConversation} from '../api/Oscar-api.js';

// Get rid of material ui - old library, too many issues, use a css approach
import { Grid, IconButton, Typography, TextField, Button } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import LoadingModal from '../components/LoadingModal';

const styles = {
    timestamp: {
        textTransform: 'uppercase',
    },
    notificationContainer: {
        paddingTop: '8px'
    },
    notification: {
        padding: '8px',
        border: '1px solid #efefef',
        marginTop: '16px',
        flexWrap: 'nowrap',
    }
}


/*
// Setup TypeScript later
interface IConversationData {
  subject:any,
  sender_public_name:any,
  recipient_public_name:any
  etc...
}*/

export default function Conversations({history, match}) {
  const { conversationId } = match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [singleConversation, setSingleConversation] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  //let navigate = useNavigate();

  useEffect(() => { fetchConversation(); }, []);

  const submissionValid = () => {
    return (inputMessage != '' && inputMessage.length >= 1);
    /*if (inputMessage && inputMessage != '' && inputMessage.length >= 1){
      return true;
    } else {
      return false;
    }*/
  }

  const sendMessage = () => {  
    setIsLoading(true);
    setErrorMessage(null);

    //console.log("sendMessage:",inputMessage);
    //setTimeout(()=>{setIsLoading(false);}, 1000); // TEMP - for DEV

    replyToConversation({
      message: inputMessage,
      duration:0,                 // legacy to pass validation
      start_date: '2020-01-01',   // legacy to pass validation
      start_time: '09:00',        // legacy to pass validation
      }, singleConversation.conversation.uuid).then(() => {
      //this.setState({loading:false, confirmationOpen: true});
      setIsLoading(false);
      setErrorMessage(null);
      setInputMessage('');
      fetchConversation();

    }).catch(error => {
      //console.log("Error:",error);
      setErrorMessage(`Your message cannot be sent. <a href='https://www.sharewithoscar.com.au/terms-and-conditions/' target='_new'>View our terms of use.</a>`);
      setIsLoading(false);
    });    

  }

  const fetchConversation = () => {  
    setIsLoading(true);
    setSingleConversation(null);
    setConversations(null);

    getConversations(conversationId).then(response => {
      //console.log(response.data);
      
      if (response.data.length){
        setConversations(response.data);
      } else {
        //console.log("single?");
        setSingleConversation(response.data);
      }
      //setTimeout(()=>{setIsLoading(false);}, 1000); // TEMP - for DEV
      setIsLoading(false);
      

  }).catch(error => {
      console.error(error);
      setIsLoading(false);
  })};

 
  let strClass = "mainContent topPadded";
 
  // Conversation List Mode
  if (!conversationId){
 
    // TEMP - move to css
    let divUnreadCount;
    if (conversations){
      let unreadCounter = 0;
      for (let i=0;i<conversations.length;i++){ if (conversations[i].new_for_you){ unreadCounter++; } }
      if (unreadCounter === 0){ divUnreadCount = <p style={{margin:'5px 0'}}>If you already have made a booking, check your inbox via the booking.</p>; } 
      else if (unreadCounter === 1){ divUnreadCount = <p style={{margin:'5px 0',fontWeight:'700'}}>You have 1 unread message</p>; }
      else { divUnreadCount = <p style={{margin:'5px 0',fontWeight:'700'}}>{`You have ${unreadCounter} unread messages`}</p>; }
      
    } else if (!isLoading) {
      divUnreadCount = <p style={{margin:'5px 0'}}>If you already have made a booking, check your inbox via the booking.</p>;
    }
    
    return (
      <div className="wpadmin-content top-padded-mobile" style={{paddingBottom:'80px'}}>
        <LoadingModal open={isLoading} />
        <>
        <div className="hide-desktop"><IconButton className="leftCornerButton" component={Link} to="/search"><CloseIcon /></IconButton></div>
        <h1 style={{color:'var(--heroColor)',margin:'20px 0 0 0'}}>Inbox</h1>
        {divUnreadCount}
        <div className="conversations-list">
          {conversations && conversations.map((o, index) => ( <ConversationLink key={index} data={o} history={history} /> ))}
        </div>
        </>
      </div>
    );
  }
  

  // Single Conversation mode
  if (conversationId){ strClass+= " single-conversation" }
  const thisConversation = singleConversation? singleConversation.conversation:null;
  return (
    <div className="v3-singlecc">
    <LoadingModal open={isLoading} />
    {thisConversation &&
        <>
        <IconButton className="leftCornerButton" component={Link} to="/conversations" style={{marginTop:'5px'}}><IconBack /></IconButton>
        <div className="v3-singlecc-ps">
          {singleConversation.your_user === 0 && <div className="v3-sender_public_name">{thisConversation.recipient_public_name}</div> /* show TO:person */}
          {singleConversation.your_user === 1 && <div className="v3-sender_public_name">{thisConversation.sender_public_name}</div> /* show FROM:person */}
          {thisConversation.parking_space && <div className="v3-address">{thisConversation.parking_space.type} on {thisConversation.parking_space.street_name}</div>}
        </div>
        <div className="v3-singlecc-threads">
          {singleConversation.threads && singleConversation.threads.map((o, index) => ( <ConversationThread key={index} data={o} history={history} conversation={singleConversation.conversation} /> ))}
        </div>
        <div className="v3-singlecc-input">
          <TextField
            className="oscar-lte-textarea"
            multiline
            name="description"
            type="text"
            InputLabelProps={{ shrink: true }}
            InputProps={{disableUnderline: true}}
            placeholder="Enter your message here"
            fullWidth
            margin="normal"
            value={inputMessage}
            onChange={(event) =>setInputMessage(event.target.value)}
            />
            {/*<button variant="contained" color="primary" disabled={!submissionValid()} onClick={() => sendMessage()}>Send</button>*/}
            <Button className="btn-message-send" onClick={() => sendMessage()}>Send</Button>
        </div>
        {errorMessage && <p className="oscar-lte-errormsg" dangerouslySetInnerHTML={{__html: errorMessage}}></p>}

        </>
    }
    </div>      
  );  
}


function ConversationLink(props) {
  const thisConversation = props.data;
  //console.log({thisConversation});

  let strClass = "v3-conversation-box";
  if (thisConversation.new_for_you){
    strClass+=" has-unread"
  }
  
  return (
  <div className={strClass} onClick={() => props.history.push(`/conversations/${thisConversation.uuid}/`)}>
      <img src={`https://images.sharewithoscar.com.au/${thisConversation.photo_url}?tr=w-60,h-60,fo-auto`} alt="User" style={{width: '60px', height: '60px', borderRadius: '40px', marginTop: '5px'}}></img>
      <div>
        {thisConversation.your_user === 0 && <div className="v3-sender_public_name">{thisConversation.recipient_public_name}</div> /* show TO:person */}
        {thisConversation.your_user === 1 && <div className="v3-sender_public_name">{thisConversation.sender_public_name}</div> /* show FROM:person */}
        <div className="v3-subject">{thisConversation.subject}</div>
        <div className="v3-date">{moment(thisConversation.create_date).format("ddd D MMM, h:mm a")}</div>
      </div>
  </div>
  );
}

function ConversationThread(props) {
  const thisThread = props.data;
  const thisConversation = props.conversation;
  //console.log({thisConversation});

  if (thisThread.which_user === 1){
    return (
      <div className="v3-ct-box-sender">
          <img src={`https://images.sharewithoscar.com.au/${thisConversation.sender_photo_url}?tr=w-60,h-60,fo-auto`} alt="User" style={{width: '60px', height: '60px', borderRadius: '40px', marginTop: '5px'}}></img>
          <div className="v3-content"><p>{thisThread.content}</p><span>{moment(thisConversation.create_date).format("ddd D MMM, h:mm a")}</span></div>
      </div>
      );
    
  } else {
    return (
      <div className="v3-ct-box-recipient">
          <div className="v3-content"><p>{thisThread.content}</p><span>{moment(thisConversation.create_date).format("ddd D MMM, h:mm a")}</span></div>
          <img src={`https://images.sharewithoscar.com.au/${thisConversation.recipient_photo_url}?tr=w-60,h-60,fo-auto`} alt="User" style={{width: '60px', height: '60px', borderRadius: '40px', marginTop: '5px'}}></img>
      </div>
      );
  }

}