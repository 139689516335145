import React, {Component} from 'react';

import {
    InputLabel,
    Grid,
    Typography,
    FormHelperText
} from "@material-ui/core"
import Slider from "@material-ui/lab/Slider"

const valueEnumeration = [
    undefined, "Bike", "Hatchback", "Sedan", "SUV", "Van"
]

const styles = {
    sliderValueBox: {
        border: "1px solid #acacac",
        borderRadius: "5px",
        height: '33px',
    }
}

export default class SliderField extends Component {
    handleChange = (event, value) => {
        event.target.value = valueEnumeration[value]
        event.target.name = this.props.name
        this.props.onChange(event)
    }
    
    render() {
        return(
            <div>
                <InputLabel error={this.props.error} shrink >{this.props.label}</InputLabel>
    
                <Grid style={{marginTop: '5px'}} container spacing={8} justify="space-between" alignItems="center">
                    <Grid item xs={8}>
                        <Slider 
                            min={0} 
                            max={5} 
                            step={1} 
                            name={this.props.name}
                            value={valueEnumeration.indexOf(this.props.value)}
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item xs={4} style={styles.sliderValueBox}>
                        <Typography variant="body1" align="center">
                            {this.props.value}
                        </Typography>
                    </Grid>
                </Grid>
                {this.props.error && <FormHelperText error={this.props.error}>{this.props.helperText}</FormHelperText>}
                
            </div>
        )    
    }
 }