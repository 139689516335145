import React, {Component} from 'react';

import {Link, withRouter} from 'react-router-dom';

import { 
  IconButton,
  Toolbar,
  Typography,
  withStyles 
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import NotificationIcon from '@material-ui/icons/Notifications';

//import {getNotifications} from '../api/Oscar-api.js';

const styles = {
  root: {
    flexGrow: 1,
    backgroundColor:'#0000ff',
    height:'100%'
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    position: 'absolute',
    marginLeft: -12,
    marginRight: 20,
  },
  notificationButton: {
    position: 'absolute',
    right: '8px',
  },
  unreadNotifications: {
    position: "absolute",
    left: '14px',
    top: '14px',
    height: '10px',
    width: '10px',
    borderRadius: '10px',
    backgroundColor: 'var(--heroColor2)',
  }
};

class ButtonAppBar extends Component {
  state = {unreadNotifications: false, showDebug:false}

  componentWillMount() {
    /*getNotifications().then(response => {
      const unreadNotifications = response.data.find(elem => elem.displayed === false)
      if (unreadNotifications) {
        this.setState({unreadNotifications: true})
      }
    }).catch(error => {
      console.log(error)
    })

    setTimeout(() => {
      this.checkNotifications()
    }, 30000)*/
  }

  /*checkNotifications = () => {
    getNotifications().then(response => {
      const unreadNotifications = response.data.find(elem => elem.displayed === false)
      if (unreadNotifications) {
        this.setState({unreadNotifications: true})
      }
    }).catch(error => {
      console.log(error)
    })

    setTimeout(this.checkNotifications, 30000)
  }*/

  toggleDebugOutline = () => {
    let showDebug = !this.state.showDebug;
    
    if (showDebug){
      document.body.classList.add('debug-outline');
    } else {
      document.body.classList.remove('debug-outline');
    }
    this.setState({showDebug});
    
  }

  render() {
    const classes = this.props.classes;


    let logoMobile;
    if (window.WPDomain === 'demo'){
      logoMobile = (<img src="https://meriton.sharewithoscar.com.au/assets/demo-logo2.svg" alt="Demo portal" style={{ backgroundColor:'#000000',width: '120px', height: 'auto', marginTop: '0'}} />);
    } else if (window.WPDomain === 'community'){
      logoMobile = (<img src="https://community.sharewithoscar.com.au/assets/graphics/logo-oscar-community.svg" alt="Oscar" style={{width: '120px', height: 'auto', marginTop: '0'}} />);
    } else {
      logoMobile = (<img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-blue3.svg" alt="Meriton powered by Oscar" style={{ width: '120px', height: 'auto', marginTop: '0'}} />);
    }


    // Mobil logo clicks through to /bookings
    return(
        <Toolbar style={{zIndex: 3,height:'100%'}}>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
            onClick={this.props.toggleHandler}
          >
            <MenuIcon />
          </IconButton>
          {this.props.menuOnly === undefined && <Typography variant="title" className={classes.flex} align="center">
              <Link to='/bookings'>
              {logoMobile}
                </Link>


          </Typography>}
          {this.props.user.authenticated && this.props.menuOnly === undefined &&
          <IconButton className={classes.notificationButton} color="inherit" aria-label="Menu"
            onClick={() => this.props.history.push('/Notifications')}
          >
            <NotificationIcon />
            {this.props.hasNotifications ? <div style={styles.unreadNotifications}></div>:null}
          </IconButton>}
        </Toolbar>
    );
  }
}


export default withStyles(styles)(withRouter(ButtonAppBar))
