import React, {Component} from 'react';
import moment from 'moment';
import {
    ParseDateTimeParameters,
    QueryParamDate,
    QueryParamTime
} from '../utils/TimeFormats.js';

import {
    FormControl,
    Grid,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core/';
import IconBack from '@material-ui/icons/KeyboardArrowLeft';

import {
    createAvailability,
    getListing,
    updateAvailability
} from '../api/Oscar-api.js';

import LoadingModal from '../components/LoadingModal.js';
import SelectField from '../components/forms/SelectField.js';
import SliderSwitch from '../components/forms/SliderSwitch.js';
import WeekDaySelector from '../components/forms/WeekDaySelector.js';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';

const styles = {
    addButton: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },
    daysControl: {
        marginTop: '16px',
        marginBottom: '10px',
    },
    timeControls: {        
        borderTop: '1px solid #efefef',
        paddingTop: '12px',
        paddingBottom: '12px'
    },
}

function getAvailabilityType(availability) {
    // Check if all day keys are set to true
    if (availability.day_sunday &&
        availability.day_monday && 
        availability.day_tuesday &&
        availability.day_wednesday &&
        availability.day_thursday &&
        availability.day_friday &&
        availability.day_saturday) {
        // This is either a 24/7 or single availability
        if (availability.end.diff(availability.start, 'years') > 1) {
            // This is a 24/7 availability (available all days for over 90 years)
            return "24/7"
        } else {
            // This is a single availability (available all days for a fixed period of time)
            return "Single"
        }
    } else {
        // This is a weekly or weekly recurring availability
        if (availability.end.diff(availability.start, 'years') > 1) {
            // This is a recurring weekly availability (available on certain days for over 90 years)
            return "Weekly"
        } else {
            // This is a weekly availability (available on certain days for a fixed period of time)
            return "Weekly"
        }
    }
}

// Set form defaults
const formDefaults = {
    selectedDays: [false, false, false, false, false, false, false],
    start: moment().hour(7).minute(0),
    end: moment().add(7, 'days').add(100, 'years').hour(18).minute(30),
    repeatWeekly: true,

    singleStart: moment().hour(7).minute(0),
    singleEnd: moment().add(7, 'days').hour(18).minute(30),
}

export default class AvailabilitiesCreateEdit extends Component {
    constructor(props) {
        super(props)

        this.state = { 
            loading: true, 
            listing: undefined,
            mainType: undefined,        
            editPage: this.props.match.params.availability !== undefined
        }

        this.state = { ...this.state, ...formDefaults }
    }

    componentWillMount() {
        getListing(this.props.match.params.parkingSpace).then(response => {
            if (this.props.match.params.availability !== undefined) {
                // Editting an availability
                const availId = this.props.match.params.availability
                const editAvail = response.data.availabilityrecurring_set.filter(avail => {
                    return avail.uuid === availId
                })[0]
                editAvail.start = moment(`${editAvail.start_date} ${editAvail.start_time}`, ParseDateTimeParameters)
                if (editAvail.cease_time === "23:59:00") {
                    editAvail.cease_time = "23:45:00"
                }
                editAvail.end = moment(`${editAvail.cease_date} ${editAvail.cease_time}`, ParseDateTimeParameters)

                let formSettings = {}
                const availType = getAvailabilityType(editAvail)
                switch(availType) {
                    case "24/7":
                        formSettings.mainType = '24/7'
                        break;
                    case "Weekly":
                        formSettings.mainType = 'Weekly'
                        formSettings.selectedDays = [
                            editAvail.day_sunday,
                            editAvail.day_monday,
                            editAvail.day_tuesday,
                            editAvail.day_wednesday,
                            editAvail.day_thursday,
                            editAvail.day_friday,
                            editAvail.day_saturday
                        ]
                        formSettings.start = editAvail.start
                        formSettings.end = editAvail.end
                        formSettings.repeatWeekly = editAvail.end.diff(editAvail.start, 'years') > 1
                        break;
                    case "Single":
                        formSettings.mainType = 'Single'
                        formSettings.singleStart = editAvail.start
                        formSettings.singleEnd = editAvail.end
                        break;
                    default:
                        break;
                }

                this.setState({
                    loading: false,
                    listing: response.data,
                    ...formSettings
                })
            }

            this.setState({
                loading: false,
                listing: response.data
            })
        }).catch(error => {
            console.log(error)
        })
    }
    
    handleTypeSelect = (event) => {
        this.setState({mainType: event.target.value})
    }

    handleDaySelect = (index) => {
        let actives = this.state.selectedDays;
        actives[index] = !actives[index]
        this.setState({selectedDays: actives})
    }
    handleTimeRangeSelected = (start, end) => {
        let newStart = moment(this.state.start)
        let newEnd = moment(this.state.end)

        newStart.hour(start.hour())
        newStart.minute(start.minute())
        newEnd.hour(end.hour())
        newEnd.minute(end.minute())

        this.setState({start: newStart, end: newEnd})
    }
    handleDateTimeRangeSelected = (start, end) => {
        this.setState({singleStart: start, singleEnd: end})
    }
    handleToggleRepeatWeekly = () => {
        if (this.state.repeatWeekly) {
            this.setState({
                repeatWeekly: false,
                end: moment(this.state.end).subtract(100, 'years')
            })
        } else {
            this.setState({
                repeatWeekly: true,
                end: moment(this.state.end).add(100, 'years')
            })
        }
    }
    handleAddAvailability = () => {
        this.setState({loading: true})

        let params
        switch(this.state.mainType) {
            case '24/7':
                params = {
                    start_date: moment().format(QueryParamDate),
                    cease_date: moment().add(100, 'years').format(QueryParamDate),
                    start_time: "00:00",
                    cease_time: "23:59",
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    day_sunday: true,
                    day_monday: true,
                    day_tuesday: true,
                    day_wednesday: true,
                    day_thursday: true,
                    day_friday: true,
                    day_saturday: true,
                }
                break;
            case 'Weekly':
                params = {
                    start_date: this.state.start.format(QueryParamDate),
                    cease_date: this.state.end.format(QueryParamDate),
                    start_time: this.state.start.format(QueryParamTime),
                    cease_time: this.state.end.format(QueryParamTime),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    day_sunday: this.state.selectedDays[0],
                    day_monday: this.state.selectedDays[1],
                    day_tuesday: this.state.selectedDays[2],
                    day_wednesday: this.state.selectedDays[3],
                    day_thursday: this.state.selectedDays[4],
                    day_friday: this.state.selectedDays[5],
                    day_saturday: this.state.selectedDays[6],
                }
                break;
            case 'Single':
                params = {
                    start_date: this.state.singleStart.format(QueryParamDate),
                    cease_date: this.state.singleEnd.format(QueryParamDate),
                    start_time: this.state.singleStart.format(QueryParamTime),
                    cease_time: this.state.singleEnd.format(QueryParamTime),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    day_sunday: true,
                    day_monday: true,
                    day_tuesday: true,
                    day_wednesday: true,
                    day_thursday: true,
                    day_friday: true,
                    day_saturday: true,
                }
                break;
            default: 
                break;
        }

        let submit
        if (this.state.editPage) {
            submit = updateAvailability(params, this.props.match.params.parkingSpace, this.props.match.params.availability)
        } else {
            submit = createAvailability(params, this.props.match.params.parkingSpace)
        }
        submit.then(() => {
            this.setState({
                loading: false,
            })
            this.props.history.push(`/Availabilities/${this.props.match.params.parkingSpace}`)
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return(
            <div>
                <LoadingModal open={this.state.loading} />

                <div className="mainContent topPadded">
                    <IconButton className="leftCornerButton"
                    onClick={this.props.history.goBack}>
                        <IconBack />
                    </IconButton>
                
                    <Typography style={{marginBottom: '4px'}} variant="display1" color="primary">
                        {this.state.editPage ? "Edit" : "Add"} availability:
                    </Typography>
                    
                    {this.state.loading ? null : <div>
                        <Typography variant="body1">
                            {this.state.listing.type} on {this.state.listing.street_name}
                        </Typography>
                        <FormControl fullWidth >
                            <SelectField 
                                label="Availability Type" 
                                name="availabilityType"
                                placeHolder="Select availability type"
                                options={["24/7", "Weekly", "Single"]}
                                value={this.state.mainType}
                                onChange={this.handleTypeSelect}
                            />
                        </FormControl>

                        {this.state.mainType === "Weekly" && <div>
                            <div>
                                <Typography style={styles.daysControl} variant="body1">
                                    Days of the week
                                </Typography>
                                <WeekDaySelector selectedDays={this.state.selectedDays} onChange={this.handleDaySelect} />
                                
                                <DateTimeRangePicker 
                                    withDate={false}
                                    verticalForm={true}
                                    enforceFuture={false}
                                    start={this.state.start}
                                    end={this.state.end}
                                    startText="From"
                                    endText="To"
                                    onTimeRangeSelected={this.handleTimeRangeSelected}
                                    disableAutoEditEnd={true}
                                />
                                <Grid style={{...styles.timeControls, paddingTop:'4px'}} container justify="space-between" alignItems="center" >
                                    <Grid>Repeat Weekly</Grid>
                                    <Grid>
                                        <SliderSwitch checked={this.state.repeatWeekly} onChange={this.handleToggleRepeatWeekly} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>}

                        
                        {this.state.mainType === "Single" && <div>
                            <div style={{paddingTop: '16px'}}>
                                <DateTimeRangePicker 
                                    withDate={true}
                                    verticalForm={true}
                                    enforceFuture={false}
                                    start={this.state.singleStart}
                                    end={this.state.singleEnd}
                                    startText="From"
                                    endText="To"
                                    onTimeRangeSelected={this.handleDateTimeRangeSelected}
                                />
                            </div>
                            <Grid style={styles.timeControls}></Grid>
                        </div>}

                        <Button style={styles.addButton} color="primary" variant="contained"
                        onClick={this.handleAddAvailability} disabled={this.state.mainType === undefined ? true
                            : !this.state.selectedDays.find(elem => elem) && this.state.mainType === 'Weekly' ? true : false
                        } >
                            {this.state.editPage ? "Save": "Add"}
                        </Button>
                    </div>}
                </div>
            </div>
        )
    }
}