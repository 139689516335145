import React, {Component} from 'react';

import {
    Modal
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import {isNativeWebview} from '../utils/IsNativeWebview.js';

const modalStyles = {
    container: {
      //backgroundColor:'#ff9999',
      boxShadow:'none'
    },
}
  
const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '8',
        backgroundColor: '#ffffff', // theme.palette.background.paper,
        boxShadow: theme.shadows[5],

        display: 'flex',
        flexDirection: 'column',
    }
});


class FullPageModal extends Component {
    render() {
        const { classes } = this.props;
  
        if (isNativeWebview()) {
            return(
                <>
                    <Modal
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                        open={this.props.open}
                        onClose={this.props.onClose}
                    >
                    <div style={modalStyles.container} className={classes.paper}>
                        {this.props.open === true && <div style={modalStyles.container} className={(window.WPDomain || "oscar") + " " + classes.paper}>
                            {this.props.children}
                        </div>}
                    </div>
                    </Modal>
                </>
            )
        }

        return(
            <div className="fullpagemodal">
                {this.props.open === true && <div style={modalStyles.container} className={classes.paper}>
                    {this.props.children}
                </div>}
            </div>
        )
    }
}

export default withStyles(styles)(FullPageModal)