import React, { Component } from "react";
import ImageCompressor from "image-compressor.js";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  withStyles
} from "@material-ui/core";

import { whiteInputStyles } from "../styles/OscarTheme.js";

import IconBack from "@material-ui/icons/KeyboardArrowLeft";
import IconClose from "@material-ui/icons/Close";
import IconEdit from "@material-ui/icons/AddAPhoto";
import IconForward from "@material-ui/icons/KeyboardArrowRight";
import IconSelect from "@material-ui/icons/Add";

import {
  createVehicle,
  updateVehicle,
  uploadProfilePicture
} from "../api/Oscar-api";
import { isNativeWebview } from "../utils/IsNativeWebview.js";
import {
  requestPushNotification,
  checkNotificationPermissionHeld
} from "../utils/PushNotification";

import LoadingModal from "../components/LoadingModal";
import FullPageModal from "../utils/FullPageModal";
import CRUDForm from "../components/CRUDForm";
import HiddenField from "../components/forms/HiddenField.js";
import SelectField from "../components/forms/SelectField.js";
import SliderSwitch from "../components/forms/SliderSwitch.js";
import PaymentMenu from "./PaymentDetails.js";
import { updateUserProfile, updateUserProfileAjax, logOut } from "../utils/Authentication.js";
import EditItem_Form from '../components/EditItem_Form.js';


let theme = {
  saveButton: {
    marginTop: "-8px",
    marginLeft: "auto",
    marginRight: "8px",
    marginBottom: "16px",
    color: "#1AA5A2",
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover, &:focus": {
      backgroundColor: "white"
    }
  }
};

//theme = { ...whiteInputStyles, ...theme };
theme = { ...theme };

const styles = {
  rightCornerButton: {
    position: "absolute",
    top: "8px",
    right: "10px"
  },
  nextButton: {
    position: "absolute",
    bottom: 16,
    right: 16
  },
  addButton: {
    backgroundColor: "white",
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0
  },

  /*topColored: {
    position: "relative",
    paddingTop: "80px",
    backgroundColor: "#1AA5A2",
    paddingBottom: "8px"
  },*/
  guestProfileImage: {
    backgroundColor: "white",
    borderRadius: "50px",
    position: "absolute",
    right: "16px",
    bottom: "-31px",
    width: "62px",
    height: "62px"
  },
  editProfileImage: {
    position: "absolute",
    right: "0px",
    bottom: "-52px"
  },
  paymentSetting: {
    paddingTop: "2px",
    paddingBottom: "2px",
    borderBottom: "1px solid #efefef"
  },
  switchField: {
    //borderTop: '1px solid #acacac',
    //borderBottom: '1px solid #acacac',
    paddingTop: "4px",
    paddingBottom: "4px",
    //marginTop: '12px',
    marginBottom: "12px"
  },
  transactionsContainer: {
    marginTop: "16px"
  },
  transactionContainer: {
    borderTop: "1px solid #efefef",
    paddingTop: "8px",
    paddingBottom: "6px"
  },
  switchBorder: {
    marginTop: "24px",
    borderTop: "1px solid #efefef"
  },
  vehicleContainer: {
    marginTop: "8px",
    paddingBottom: "8px",
    borderBottom: "1px solid #efefef"
  },
  fileInput: {
    display: "none"
  },
  logoutButton: {
    marginTop: "24px",
    paddingLeft: "0px"
  }
};

const MAX_IMAGE_UPLOAD_SIZE = 1000000;

class VehicleForm extends Component {
  state = {
    loading: false,
    endPoint:
      this.props.editVehicle !== undefined ? updateVehicle : createVehicle,
    carValues:
      this.props.editVehicle !== undefined
        ? this.props.editVehicle
        : { default: true },
    defaultVehicle:
      this.props.editVehicle !== undefined
        ? this.props.editVehicle.default
        : true
  };

  handleFormSuccess = () => {
    this.setState({ loading: true });
    updateUserProfileAjax()
      .then(response => {
        //console.log(response);
        this.props.authChange();
        if (this.props.onClose){
          this.props.onClose();
        }
        
        //this.setState({ loading: false });
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <LoadingModal open={this.state.loading} />

<div className="up-container">
        <div className="up-header xmainContent xtop-colored xtopPadded xgradient-page">
          <IconButton className="leftCornerButton" onClick={this.props.onClose}>
            <IconBack style={{ color: "white" }} />
          </IconButton>

          <h1>Vehicle Details</h1>
           
          
        </div>
        <div className="up-content">          
          {this.state.loading ? null : (
            <>
              <CRUDForm
                endpoint={this.state.endPoint}
                onSuccess={this.handleFormSuccess}
                hydrateFromObject={{
                  values: { ...this.state.carValues },
                  errors: {}
                }}
                uuid={this.state.carValues.pk}
              >
                <Grid container spacing={8} justify="center">
                  <Grid item xs={12}>
                    <TextField
                      label="Vehicle registration"
                      name="registration"
                      type="text"
                      FormHelperTextProps={{
                        classes: {
                          root: classes.helperText,
                          error: classes.helperTextError
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                          error: classes.inputError
                        }
                      }}
                      InputLabelProps={{
                        classes: { root: classes.label },
                        FormLabelClasses: {
                          root: classes.formLabelRoot,
                          error: classes.formLabelError,
                          focused: classes.formLabelFocused
                        },
                        shrink: true
                      }}
                      placeholder="eg. QW89BV"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <SelectField
                        label="Vehicle type"
                        name="car_type"
                        placeHolder="Select your vehicle type"
                        options={[
                          "Bike",
                          "Hatchback",
                          "Sedan",
                          "4WD/SUV",
                          "Van"
                        ]}
                        white={true}
                        classes={classes}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Vehicle make"
                      name="make"
                      type="text"
                      FormHelperTextProps={{
                        classes: {
                          root: classes.helperText,
                          error: classes.helperTextError
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                          error: classes.inputError
                        }
                      }}
                      InputLabelProps={{
                        classes: { root: classes.label },
                        FormLabelClasses: {
                          root: classes.formLabelRoot,
                          error: classes.formLabelError,
                          focused: classes.formLabelFocused
                        },
                        shrink: true
                      }}
                      placeholder="eg. Toyota"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Vehicle model"
                      name="model"
                      type="text"
                      FormHelperTextProps={{
                        classes: {
                          root: classes.helperText,
                          error: classes.helperTextError
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.input,
                          error: classes.inputError
                        }
                      }}
                      InputLabelProps={{
                        classes: { root: classes.label },
                        FormLabelClasses: {
                          root: classes.formLabelRoot,
                          error: classes.formLabelError,
                          focused: classes.formLabelFocused
                        },
                        shrink: true
                      }}
                      placeholder="eg. Corolla"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      style={styles.switchField}
                      container
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography variant="body1">
                          Make this my default vehicle
                        </Typography>
                      </Grid>
                      <Grid item className="sliderswitch">
                        <SliderSwitch
                          checked={this.state.defaultVehicle}
                          onChange={() => {
                            this._toggleSwitch.reportChange(
                              !this.state.defaultVehicle
                            );
                            this.setState({
                              defaultVehicle: !this.state.defaultVehicle
                            });
                          }}
                        />
                        <HiddenField
                          value={this.state.defaultVehicle}
                          name="default"
                          customValue={this.state.defaultVehicle}
                          ref={c => (this._toggleSwitch = c)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

                
                <Button
                    //classes={{ root: classes.saveButton }}
                    //color="primary"
                    //variant="contained"
                    fullWidth
                    className="wp-button"
                    type="submit"
                  >
                    Save
                  </Button>

                

              </CRUDForm>
            </>
          )}
        </div>
        </div>        
      </>
    );
  }
}
function VehicleFormStyled(props) {
  const StyledForm = withStyles(theme)(VehicleForm);
  return <StyledForm {...props} />;
}

class VehiclesMenu extends Component {
  state = {
    loading: false,
    vehicleFormOpen: (this.props.isCheckoutMode && this.props.userProfile.vehicles && this.props.userProfile.vehicles.length == 0) ? true:false,  // Edit on open if no vehicles in checkout
    editVehicle: undefined
  };

  // componentWillMount() {
  //     getVehicles().then(response => {
  //         this.setState({
  //             loading: false,
  //             vehicles: response.data
  //         })
  //     }).catch(error => {
  //         console.log(error)
  //     })
  // }

  render() {
    const sortedVehicles = this.props.userProfile.vehicles.sort(
      (a, b) => a.pk - b.pk
    );

    const isCheckoutMode = this.props.isCheckoutMode || false;

    return (
      <>
        <FullPageModal open={this.state.vehicleFormOpen}>
          <VehicleFormStyled
            editVehicle={this.state.editVehicle}
            authChange={this.props.authChange}
            onClose={isCheckoutMode ? () =>  { this.props.onSelectVehicle()} : () => this.setState({ vehicleFormOpen: false })}
            //onClose={() => this.setState({ vehicleFormOpen: false })}
            //onClose={isCheckoutMode ? () => {this.props.onSelectVehicle && this.props.onSelectVehicle(editVehicle)}:() => this.setState({vehicleFormOpen: true, editVehicle: vehicle})}            
          />
        </FullPageModal>

        <div className="up-container">
          
        <div className="up-header">
          <IconButton className="leftCornerButton" onClick={this.props.onClose}>
            <IconBack style={{ color: "white" }} />
          </IconButton>

          {isCheckoutMode ? <h1>Select a Vehicle</h1>:<h1>My Vehicles</h1>}
          
          
        </div>
        <div className="up-content">
          <div
            style={{
              marginTop: "24px",
              flexGrow: "0.89",
              flexBasis: "1px",
              overflowY: "auto"
            }}
          >
            {sortedVehicles.length > 0 ? (
              sortedVehicles.map((vehicle, i) => {
                return (
                  <Grid
                    key={i}
                    style={styles.vehicleContainer}
                    container
                    justify="space-between"
                    alignItems="center"
                    className="wp-hovercursor" 
                    onClick={isCheckoutMode ? () => {this.props.onSelectVehicle && this.props.onSelectVehicle(vehicle)}:() => this.setState({vehicleFormOpen: true, editVehicle: vehicle})}
                  >
                    <Grid item>
                      <Typography variant="body1">
                        {vehicle.make} {vehicle.model}{" "}
                        {vehicle.default ? (
                          <span className="vehicle-default">(Default)</span>
                        ) : null}
                      </Typography>
                      <Typography variant="caption">
                        Registration: {vehicle.registration && vehicle.registration.toUpperCase()}
                      </Typography>
                    </Grid>
                    <Grid item><IconButton>{isCheckoutMode ? <IconSelect />:<IconForward />}</IconButton></Grid>
                  </Grid>
                );
              })
            ) : (
              <div>
                <Typography variant="body1">
                  You haven't entered any vehicle details
                </Typography>
              </div>
            )}
          </div>
          
            <Button
            className="wp-button"
              fullWidth
              style={{ marginTop:'20px' }}
              onClick={() => this.setState({ vehicleFormOpen: true })}
            >
              Add a vehicle
            </Button>
          
          </div>
        </div>  
      </>
    );
  }
}

export default class UserProfile extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      paymentMenuOpen: false,
      vehiclesMenuOpen: false,
      holds_push: false
    };

    checkNotificationPermissionHeld().then(() => {
      this.setState({
        loading: false,
        holds_push: localStorage.getItem("USER_NOTIFICATION_PERMISSIONS_HELD")
      });
    });
  }

  compressAndSaveImage = (file, quality) => {
    //console.log(file.size)
    if (file.size < MAX_IMAGE_UPLOAD_SIZE) {
      this.saveImage(file);
      return;
    }

    new ImageCompressor(file, {
      quality: quality,
      checkOrientation: false,
      width: 800,

      success: result => {
        if (result.size < MAX_IMAGE_UPLOAD_SIZE) {
          this.saveImage(result);
        } else {
          this.compressAndSaveImage(result, quality / 1.5);
        }
      },
      error: e => {
        console.log(e.message);
      }
    });
  };

  saveImage = file => {
    uploadProfilePicture(file)
      .then(response => {
        updateUserProfileAjax().then(response => {
          //console.log(response)
          if (this.props.authChange) {this.props.authChange();} // DAMIAN - MISSING PROPS? this.props.authChange();
          //console.log(response)
          this.setState({ loading: false });
        });
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  handleFileChange = (e, file) => {
    this.setState({ loading: true });
    file = file || e.target.files[0];

    this.compressAndSaveImage(file, 0.8);
  };

  handleImagePickerClick = event => {
    if (isNativeWebview()) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({
        loading: true
      });
       // Send the postMessage to the native side saying we want to log in through google
      window.ReactNativeWebView.postMessage(JSON.stringify({ action: "image" }));
      // Set an onMessage listener to away the response token

      if (window.document.isAndroid) {
        window.document.addEventListener("message", this.handleNativeResponse);
      } else {
        window.addEventListener("message", this.handleNativeResponse);
      }
    }
  };
  handleNativeResponse = event => {
    // Process the response token as normal with our backend
    const nativeResponse = JSON.parse(event.data);
    if (nativeResponse.message === "image_upload") {
      const file = b64toBlob(nativeResponse.image_data);

      this.compressAndSaveImage(file, 0.8);
    }
    this.setState({ loading: false });
    window.removeEventListener("message", this.handleNativeResponse);
  };

  handlePageSuccess = event => {
    //console.log("event:",event);
    // Need to update our localstorage
    updateUserProfile({lease_end_date: event.lease_end_date});
  }

  render() {
    //console.log("profile:",this.props.user.profile);
    return (
      <>
        <LoadingModal open={this.state.loading} />

        <FullPageModal open={this.state.vehiclesMenuOpen}>
          <VehiclesMenu
            onClose={() => this.setState({ vehiclesMenuOpen: false })}
            userProfile={this.props.user.profile}
            authChange={this.props.authChange}
          />
        </FullPageModal>

        <FullPageModal open={this.state.paymentMenuOpen}>
          <PaymentMenu
            onClose={() => this.setState({ paymentMenuOpen: false })}
            userProfile={this.props.user.profile}
            authChange={this.props.authChange}
          />
    </FullPageModal>

      <div className="up-container">
  
        <div className="up-header" style={{position:'relative'}}>
          <IconButton
            className="leftCornerButton"
            onClick={this.props.history.goBack}
          >
            <IconClose style={{ color: "white" }} />
          </IconButton>
          
          <h1>Edit Profile</h1>
            
          

          {this.props.user.profile.image_user !== null ? (
            <img
              style={styles.guestProfileImage}
              src={this.props.user.profile.image_user.path}
              alt="user profile"
            />
          ) : (
            <img
              style={styles.guestProfileImage}
              src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg"
              alt="user profile"
            />
          )}

          <IconButton
            style={styles.editProfileImage}
            onClick={evt => this.handleImagePickerClick(evt)}
          >
            <label>
              <IconEdit style={{ fontSize: "28px" }} />
              <input
                style={styles.fileInput}
                type="file"
                //                                accept="image/*"
                onChange={this.handleFileChange}
              />
            </label>
          </IconButton>
        </div>


        <div
          className="up-content"
        >
          {window.WPDomain === 'meriton' ?
            <EditItem_Form
            history={this.props.history}
            formId={'public_meriton_edit_profile'}
            initialData={this.props.user.profile}
            itemType={'userprofile'}
            itemId={'self'}
            onPageSuccess={this.handlePageSuccess}
            />:
            <>
              <TextField
              label="Mobile"
              name="mobile"
              type="text"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                style: { color: "black" }
              }}
              fullWidth
              margin="normal"
              disabled
              value={this.props.user.profile.telephone_number}
              />
              <TextField
              label="Email"
              name="email"
              type="text"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                style: { color: "black" }
              }}
              fullWidth
              margin="normal"
              disabled
              value={this.props.user.profile.email}
              />
            </>
          }


          {/*this.props.user.profile.tenant_id ? 
          <>
          <TextField
            label="Building"
            name="default_building"
            type="text"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              style: { color: "black" }
            }}
            fullWidth
            margin="normal"
            disabled
            value={this.props.user.profile.default_building ? this.props.user.profile.default_building.building_name:""}
          />
          <TextField
            label="Unit#"
            name="tenant_id"
            type="text"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              style: { color: "black" }
            }}
            fullWidth
            margin="normal"
            disabled
            value={this.props.user.profile.tenant_id}
          />
          <TextField
            label="End of lease date"
            name="email"
            type="text"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              style: { color: "black" }
            }}
            fullWidth
            margin="normal"
            disabled
            value={this.props.user.profile.lease_end_date}
          />
          </>:null*/}




          <Grid style={{ ...styles.paymentSetting, marginTop: "24px" }} />
          <Grid
            className="wp-hovercursor"
            style={styles.paymentSetting}
            container
            justify="space-between"
            alignItems="center"
            onClick={() => this.setState({ vehiclesMenuOpen: true })}
          >
            <Grid item>
              <Typography variant="body1">Vehicle Details</Typography>
            </Grid>
            <Grid item>
              <IconButton>
                <IconForward/>
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            className="wp-hovercursor"
            container
            justify="space-between"
            alignItems="center"
            onClick={() => this.setState({ paymentMenuOpen: true })}
          >
            <Grid item>
              <Typography variant="body1">Payment Details</Typography>
            </Grid>
            <Grid item>
              <IconButton>
                <IconForward/>
              </IconButton>
            </Grid>
          </Grid>

          {isNativeWebview() && !this.state.holds_push && (
            <div>
              <Button
                style={styles.logoutButton}
                color="primary"
                onClick={() => {
                  requestPushNotification();
                }}
              >
                Enable Push Notifications
              </Button>
            </div>
          )}
          <Button className="wp-button thirdWidth" style={{margin:'40px 0'}} onClick={() => { logOut();  this.props.authChange(); this.props.history.push("/search");  }}>Log out</Button>
          
        </div>
        </div>        
      </>
    );
  }
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export { VehiclesMenu };
