import React, {Component} from 'react';
import moment from 'moment';

import {longtermEnquiry} from '../api/Oscar-api.js';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';

import { QueryParamDate, QueryParamTime } from '../utils/TimeFormats.js';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';

import LoadingModal from '../components/LoadingModal';
import WeekDaySelector from '../components/forms/WeekDaySelector.js';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';

import FullPageModal from '../utils/FullPageModal';
import NumberField from '../components/forms/NumberField.js';

const styles = {
    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '10px',
    },
    nextButton: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },

    topColored: {
        paddingTop: '80px',
        backgroundColor: '#1AA5A2',
        paddingBottom: '8px',
    },
    daysControl: {
        fontWeight: 'bold',
        marginTop: '16px',
        marginBottom: '16px',
        textTransform: 'uppercase'
    },
    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    },
    submitButton: {
        margin: '16px 0px',
    },
    submitButtonActive: {
        margin: '16px 0px',
        backgroundColor: '#1AA5A2',
        color: 'white',
    }
}

class EnquiryConfirmation extends Component {
    render() {
        return(
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className="mainContent topPadded" style={styles.alertPage}>
                <Typography gutterBottom variant="display1" color="primary">
                    Your message has been sent
                </Typography>
                
                <Typography>
                    Keep an eye on your inbox for replies from the owner
                </Typography>

                <div style={styles.alertImageContainer}>
                    <img style={styles.permImage} src="https://www.sharewithoscar.com.au/assets/graphics/first_listing.svg" alt="congratulations"/>
                </div>
                
                <Button style={styles.alertButton} fullWidth variant="contained" color="primary"
                onClick={this.props.onClose}>
                    Close
                </Button>
            </div>
            </div>
        )
    }
}

export default class LongtermEnquiry extends Component {
    state = { 
        loading: false,
        start: moment(),
        end: moment().add(3, 'months'),
        selectedDays: [false, false, false, false, false, false, false],

        rate_weekly: '',
        week_duration: '',
        comments: '',
    }

    handleDaySelect = (index) => {
        let actives = this.state.selectedDays;
        actives[index] = !actives[index]
        this.setState({selectedDays: actives})
    }

    submissionValid = () => {
      if (this.state.comment && this.state.comment != '' && this.state.comment.length >= 1){
        return true;
      } else {
        return false;
      }

    }

    handleTimeRangeSelected = (start) => {
        this.setState({start: start})
    }

    submitEnquiry = () => {
        this.setState({loading: true, errorMessage:null});

        longtermEnquiry({
          search_mode:this.props.searchMode,
          space_uuid: this.props.spaceUuid,
          space_type: this.props.spaceType,
            start_date: this.state.start.format(QueryParamDate),
            start_time: this.state.start.format(QueryParamTime),
            duration: 0, // this.state.week_duration,
            day_sunday: this.state.selectedDays[0],
            day_monday: this.state.selectedDays[1],
            day_tuesday: this.state.selectedDays[2],
            day_wednesday: this.state.selectedDays[3],
            day_thursday: this.state.selectedDays[4],
            day_friday: this.state.selectedDays[5],
            day_saturday: this.state.selectedDays[6],
            weekly_rate: this.state.rate_weekly || "0",
            comment: this.state.comment,
        }, this.props.spaceUuid).then(() => {
          this.setState({loading:false, confirmationOpen: true});
        }).catch(error => {
          console.log("Error:",error);
          this.setState({loading: false, errorMessage: `Your message cannot be sent. <a href='https://www.sharewithoscar.com.au/terms-and-conditions/' target='_new'>View our terms of use.</a>`});

        });

   
        


    }

    render() {
        return(
            <div style={{height: '100%', overflowY: 'auto'}}>
                <LoadingModal open={this.state.loading}/>
                                
                <FullPageModal open={this.state.confirmationOpen}>
                    <EnquiryConfirmation history={this.props.history} onClose={this.props.onClose}/>
                </FullPageModal>

                <div className="mainContent" style={styles.topColored}>
                    <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                        <IconBack style={{color: 'white'}}/>
                    </IconButton>

                    <Typography style={{color: 'white'}} variant="display1">
                        Send Message
                    </Typography>            
                </div>
                <div style={{backgroundColor: 'white', paddingTop: '0px'}} className="mainContent">
                
                    <TextField
                        
                        className="oscar-lte-textarea"
                        multiline
                        rows={10}
                        label="Have a question? Contact the Owner"
                        name="description"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{disableUnderline: true}}
                        placeholder="Enter your message here"
                        fullWidth
                        margin="normal"
                        value={this.state.comment}
                        onChange={(event) => this.setState({comment: event.target.value})}
                    />
                    {this.state.errorMessage && <p className="oscar-lte-errormsg" dangerouslySetInnerHTML={{__html: this.state.errorMessage}}></p>}

                    <Grid container justify="flex-end" style={{paddingTop:'10px'}}>
                        <Button variant="contained"
                        color="primary" 
                        disabled={!this.submissionValid()}
                        onClick={this.submitEnquiry}>
                            Send Message
                        </Button>
                    </Grid>
                </div>
            </div>
        )
    }
}
