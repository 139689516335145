import React, {Component} from 'react';

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'flex-start', 
        marginBottom: '24px'
    },
    dayInactive: {
        width: '38px',
        height: '38px',
        marginRight: '5px',
        paddingTop: '10px',
        color: '#efefef',
        textAlign: 'center',
        fontFamily: 'var(--font-default)',
        border: '1px solid #efefef',
        cursor: 'pointer'
    },
    daySelect: {
        width: '38px',
        height: '38px',
        marginRight: '5px',
        paddingTop: '10px',
        backgroundColor: 'var(--heroColor)',  // #1AA5A2
        color: 'white',
        textAlign: 'center',
        fontFamily: 'var(--font-default)',
        cursor: 'pointer'
    }
}

export default class WeekDaySelector extends Component {
    render() {
        const days = ["Su", "M", "Tu", "W", "Th", "F", "Sa"]
        return(
            <div style={styles.container}>
                {days.map((day, i) => {
                    return(
                        this.props.selectedDays[i] 
                        ? <div 
                            onClick={() => this.props.onChange(i)} 
                            key={i} style={styles.daySelect} >
                            {day}
                        </div>
                        : <div 
                            onClick={() => this.props.onChange(i)} 
                            key={i} style={styles.dayInactive} >
                            {day}
                        </div>
                    )
                })}
            </div>
        )
    }
}