import React, { Component } from 'react';

import { savePaymentCard_Braintree} from "../api/Oscar-api";
import { updateUserProfileAjax } from "../utils/Authentication.js";

//import moment from 'moment';
//import '../styles/EditItem.css';
import { Button } from "@material-ui/core";

import CircularProgress from '@material-ui/core/CircularProgress';

const client = require('braintree-web/client');
const hostedFields = require('braintree-web/hosted-fields');
const dataCollector = require('braintree-web/data-collector');

export default class AddCreditCard_Braintree extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.my_hostedFieldsInstance = null;
        
        //console.log("[AddCreditCard_Braintree:constructor]",this.props);

        this.state = {
            initialLoad:false,
            isUpdating:false
        }
        this.onSubmit = this.onSubmit.bind(this);

    }

    componentWillUnmount(){
        this._isMounted = false;
    }    

    componentDidMount(){
        this._isMounted = true;
        let that = this;

        // Generate tokens and create the Braintree form
        // Test card: 4111 1111 1111 1111
        client.create({
            authorization: process.env.REACT_APP_BRAINTREE_PUBLIC_KEY
        }, function (err, clientInstance) {
            if (err){
                console.log(err);
                that.setState({errorMessage:"We could not process your request, please try again"});
                return;
            } 


            const options_oscar = {
                client: clientInstance,
                styles: {
                    'input': {
                    'font-size': '18px',
                    'font-weight': '700',
                    'color': '#555555'
                    },
                    '.number': {
                    //'font-family': 'Rubik'
                    },
                    '.valid': {
                    'color': '#1aa5a2'
                    },
                    '.invalid': {
                        'color': 'red'
                    },
                    '::-webkit-input-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    },
                    ':-moz-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    },
                    '::-moz-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    },       
                    ':-ms-input-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    }
    
                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: "Card number"

                    },
                    expirationDate: {
                        placeholder:'MM/YY',
                        selector: '#expiration-date'
                    },
                    cvv: {
                      selector: '#cvv',
                      placeholder: 'CVC'
                  }
                }
              };


              const options_meriton = {
                client: clientInstance,
                styles: {
                    'input': {
                    'font-size': '18px',
                    'font-weight': '700',
                    'color': '#555555'
                    },
                    '.number': {
                    //'font-family': 'Rubik'
                    },
                    '.valid': {
                    'color': '#0e3b60'
                    },
                    '.invalid': {
                        'color': 'red'
                    },
                    '::-webkit-input-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    },
                    ':-moz-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    },
                    '::-moz-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    },       
                    ':-ms-input-placeholder': {
                      'color': '#cccccc',
                      'font-weight':'400'
                      
                    }
    
                },
                fields: {
                    number: {
                        selector: '#card-number',
                        placeholder: "Card number"

                    },
                    expirationDate: {
                        placeholder:'MM/YY',
                        selector: '#expiration-date'
                    },
                    cvv: {
                      selector: '#cvv',
                      placeholder: 'CVC'
                  }
                }
              };

            let options;
            if (window.WPMode === 'bays'){
              options = options_meriton;
            } else {
              options = options_oscar;
            }
            
            // Create the Hosted Fields
            hostedFields.create(options, function (err, hostedFieldsInstance) {
                    if (err){
                        console.log(err);
                        that.setState({errorMessage:"We could not process your request, please try again"});
                        return;
                    } 
                    
                    that.my_hostedFieldsInstance = hostedFieldsInstance;

                    //console.log("all good, creating dataCollector...");


                    dataCollector.create({
                      client: clientInstance
                    }, function (err, dataCollectorInstance) {
                      if (err) {
                        // Handle error in creation of data collector
                        console.log(err);
                        that.setState({errorMessage:"We could not process your request, please try again - A2"});
                        return;
                      }
                      // At this point, you should access the dataCollectorInstance.deviceData value and provide it
                      // to your server, e.g. by injecting it into your form as a hidden input.
                      
                      //console.log({deviceData});
                      that.device_data = dataCollectorInstance.deviceData;

                      setTimeout(() => {
                        //console.log("continue-a1");
                            that.setState({initialLoad:true}); 
                      }, 3);
                    


                    });

                
                });
          });        
    }    


    // Store the token on the server then close this form
    onSubmit(e){
        e.preventDefault();

        const messages = {
          "number": "Please check your card number",
          "cvv":"Please check your CVC",
          "expirationDate":"Please check your Expiry Date"
        };
    
        this.setState({isUpdating:true, errorMessage:null});

        let that = this;
        this.my_hostedFieldsInstance.tokenize(function (err, payload) {
            if (err) {
                console.error(err);
                let errorMessage;
                if (err.code === 'HOSTED_FIELDS_FIELDS_EMPTY'){
                  errorMessage = "Please enter your card details";
                } else if (err.code === 'HOSTED_FIELDS_FIELDS_INVALID'){
                  //errorMessage = "Please check your card details";
                  if (err.details && err.details.invalidFieldKeys){
                    errorMessage = messages[err.details.invalidFieldKeys[0]];

                    //for (let i=0;i<err.details.invalidFieldKeys.length;i++){
                    //  errorMessage+=`(${err.details.invalidFieldKeys[i]})`;
                   // }
                    //console.log("arr:",err.details.invalidFieldKeys);
                  }
                  if (!errorMessage){
                    errorMessage = "Please check your card details";    // Generic fallback
                  }
  
                } else {
                  errorMessage = err.message;
                }
                that.setState({isUpdating:false, errorMessage});
            } else {
               
                savePaymentCard_Braintree({ braintree_nonce: payload.nonce, device_data:that.device_data }).then((resp) => {
                    updateUserProfileAjax().then((response) => {
                      if (that.props.authChange) {that.props.authChange();} // DAMIAN - MISSING PROPS? this.props.authChange();
                      that.props.onClose('card');

                    });
                }).catch(function(err) {
                    console.error(err);
                    that.setState({isUpdating:false, errorMessage:"Sorry, we could not process your request"});
                });
            }
          });        
    }


    render() {
        //console.log("[AddCreditCard_Braintree:Render:",this.state);
        const { browserCSSMode } = this.props;
        const {itemType, itemId, initialLoad, isUpdating, errorMessage} = this.state;
        const data = this.state.data || {};
        const meta = this.state.meta;
        const CMS = this.props.CMS;

        //let image="https://meriton.sharewithoscar.com.au/assets/graphics/logo-meriton.png";

        let name = "Share with Oscar";
        let image = "https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_white.svg";
        

        let strClass = "braintree";

        if (window.WPMode === 'bays'){
          if (window.WPDomain === 'community'){
            // same as oscar
            strClass+=" community";
            name = "Share with Oscar";

          } else if (window.WPDomain === 'demo') {
                name = "Demo by Oscar";
                image="https://demo.parkwithoscar.com.au/assets/graphics/logo-demo.png";
                strClass+=" demo";

            } else {
                name = "Meriton by Oscar";
                image="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg";
                strClass+=" meriton";
                //<img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg" alt="Meriton powered by Oscar"></img>
            }
        } else {
          strClass+=" oscar";
        }
        

        // Render the Credit Card form
        const showSpinner = isUpdating || (!initialLoad && !errorMessage);
        return (
            <div className={strClass}>
                {showSpinner && <div className="pm-is-loading"><CircularProgress  size={70} thickness={4.5} style={{color:"var(--heroColor)",position:'absolute',left:'calc(50% - 35px)',top:'calc(50%)'}} /></div>}
                {/*browserCSSMode === 'desktop' && <div className="header"><img src={image} /></div>*/}
                
                {/*showSpinner && <CircularProgress  size={70} thickness={4.5} style={{color:"var(--heroColor)",position:'absolute',left:'calc(50% - 35px)',top:'calc(50%)'}} />*/}

                <div className={!showSpinner ? "braintree-form":"braintree-form is-loading"}>
                    <form id="braintree-form" method="post">
                      <label htmlFor="card-number">Card Number</label>
                      <div id="card-number" className="hosted-field"></div>

                      <div className="btf-2col">
                        <div>
                          <label htmlFor="expiration-date">Expiry Date</label>
                          <div id="expiration-date" className="hosted-field"></div>
                        </div>
                        <div>
                          <label htmlFor="cvv">CVC</label>
                          <div id="cvv" className="hosted-field"></div>
                        </div>
                      </div>
                      

                      {browserCSSMode != 'xxxdesktop' && 
                      
                      <div className="btlogo" >
                        <a href="https://www.braintreegateway.com/merchants/k9nt2j7vfbfmsq5b/verified" target="_blank"><img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png" width="110px" border="0"/></a>
                        <img src="https://www.sharewithoscar.com.au/assets/logo-mc.svg" height="34px" />
                        <img src="https://www.sharewithoscar.com.au/assets/logo-visa.svg" height="20px" />
                      </div>
                      }
                      {<div className="messages">{errorMessage}</div>}

                      {browserCSSMode === 'desktop' && <Button className="wp-button" type="submit" disabled={!initialLoad || isUpdating} onClick={this.onSubmit}>Add Card Details</Button>}
                      {browserCSSMode === 'mobile' && 
                        <div className='mbt-footer'>
                          <Button className="obt-back" type="button" disabled={!initialLoad || isUpdating} onClick={this.props.onClose}>Back</Button>
                          <Button className="obt-next" type="submit" disabled={!initialLoad || isUpdating} onClick={this.onSubmit}>Add Card</Button>
                        </div>
                      }
                    </form>
                </div> 
                
                

            </div>
        );
    }

}