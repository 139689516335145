import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import { 
    Button,
    IconButton,
    Typography, 
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const styles = {
    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    }
}

export default class FirstListing extends Component {
    render() {
        return(
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className="mainContent topPadded" style={styles.alertPage}>
                <IconButton className="leftCornerButton"
                component={Link} to="/PermissionsPush">
                    <CloseIcon />
                </IconButton>

                <Typography gutterBottom variant="display1" color="primary">
                    Congrats! You just listed your first spot.
                </Typography>
                
                <Typography>
                    We're excited to have you as a spot owner on Oscar. We'll make sure to let you know once someone books your spot.
                </Typography>

                <div style={styles.alertImageContainer}>
                    <img style={styles.permImage} src="https://www.sharewithoscar.com.au/assets/graphics/first_listing.svg" alt="congratulations"/>
                </div>

                <Button style={styles.alertButton} fullWidth variant="contained" 
                component={Link} to="/search" color="primary">
                    Dismiss
                </Button>
            </div>
            </div>
        )
    }
}