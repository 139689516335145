import React, {Component} from 'react';

import PlacesAutocomplete, 
{
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import {requestLocation} from '../utils/Location.js';

import {
    Card,
    CardContent,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core'

import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import IconClear from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';

import FullPageModal from '../utils/FullPageModal';

const styles = {
  addressBox: {
    padding: '9px',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    left: '5px',
  },
  searchBoxContainer: {
    backgroundColor: '#f5f5f5',
    margin: '10px',
    marginTop: '42px',
    padding: '8px 24px 8px 24px',
  },
  suggestionsContainer: {
    marginTop: '10px'
  },
  suggestionsContainerDesktop: {
    position: 'absolute',
    zIndex: '5',
    width: '500px',
    marginTop: '10px'
  },
  suggestionStyle: {
    paddingBottom: '10px',
    borderBottom: '1px solid #f5f5f5',
    marginBottom: '10px'
  }
}


class PickerModal extends Component {
  state = {
      address: this.props.address ? this.props.address : '',
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    this.setState({ address });

    const newAddr = address;
    geocodeByAddress(address)
      .then(results => {
        //console.log(results)
        return getLatLng(results[0])
      })
      .then(latLng => {
          //console.log('Success', latLng)
          this.props.onLocationSelected(newAddr, latLng)
          this.props.onClose()
      })
      .catch(error => console.error('Error', error));
  };

  render() {
      return(
          <div>
              <FullPageModal open={this.props.open} onClose={this.props.onClose}>
                  <IconButton 
                      style={styles.closeButton}
                      aria-label="Delete" 
                      color="primary"
                      onClick={this.props.onClose}
                  >
                      <BackIcon style={{fontSize: 32}}/>
                  </IconButton>
                  <PlacesAutocomplete
                      value={this.state.address}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                      searchOptions={{
                        location: new window.google.maps.LatLng(-34, 151),
                        radius: 2000,
                      }}
                  >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <Card>
                            <CardContent>
                                <div style={styles.searchBoxContainer}>
                                    <Grid container spacing={8} justify="space-between" alignItems="center">
                                        <Grid item style={{flex: 1}}>
                                            <TextField 
                                                {...getInputProps({
                                                  autoFocus: true,
                                                  fullWidth: true,
                                                  style: {marginTop: 0},
                                                  placeholder: 'Where to?',
                                                  className: 'location-search-input',
                                                  InputProps: {disableUnderline: true},
                                                })}
                                                //onClick={() => this.setState({ addressPickerOpen: true })}
                                            />
                                        </Grid>
                                        <Grid item >
                                            <IconClear style={{fontSize: '18px', marginRight: '-10px', marginTop: '2px'}} 
                                            onClick={() => this.handleChange('')} />
                                        </Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                        {suggestions.length > 0 && 
                        <Card style={styles.suggestionsContainer}>
                          <CardContent>
                          {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              let style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              
                              // merge with suggestion styles
                              style = {...style, ...styles.suggestionStyle}

                              const headline = `${suggestion.terms[0].value}`
                              let caption = ''
                              caption += suggestion.terms[1] ? `${suggestion.terms[1].value} ` : ''
                              caption += suggestion.terms[2] ? `${suggestion.terms[2].value} ` : ''
                              caption += suggestion.terms[3] ? `${suggestion.terms[3].value}` : ''

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <Typography variant="body1">
                                      {headline}
                                  </Typography>
                                  <Typography variant="caption">
                                      {caption}
                                  </Typography>
                                </div>
                              );
                          })}
                          </CardContent>
                        </Card>}
                      </div>
                      )}
                  </PlacesAutocomplete>
              </FullPageModal>
          </div>
      )
  }
}

class LocationButton extends Component {
    handleLocationRequest = () => {
      requestLocation().then(({address, latLng}) => {
          //console.log(address, latLng)
          this.props.onLocationSelected(address, latLng)
      })
    }
  
    render() {
      return(
        <LocationSearchingIcon onClick={this.handleLocationRequest}/>
      )
    }
}

export default class GooglePlacesSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            address: props.address === ' ' ? undefined : props.address,
        }
    }

    handleChange = address => {
        this.setState({ address });
      };
    
    handleSelect = address => {
        this.setState({ address });

        const newAddr = address;
        geocodeByAddress(address)
            .then(results => {
            //console.log(results)
            return getLatLng(results[0])
            })
            .then(latLng => {
                //console.log('Success', latLng)
                this.props.onLocationSelected(newAddr, latLng)
            })
        .catch(error => console.error('Error', error));
    };

    render() {
        if (this.props.desktopDisplay === true) {
            return(
            <Grid container spacing={8} justify="space-between" alignItems="center" style={styles.addressBox}>
                <Grid item>
                    <SearchIcon />
                </Grid>
                <Grid item style={{flex: 1}}>
                    <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={{
                        location: new window.google.maps.LatLng(-34, 151),
                        radius: 2000,
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <TextField 
                                {...getInputProps({
                                autoFocus: true,
                                fullWidth: true,
                                style: {marginTop: 0},
                                placeholder: 'Where to?',
                                className: 'location-search-input',
                                InputProps: {disableUnderline: true},
                                })}

                            />
                        {suggestions.length > 0 && 
                        <Card style={styles.suggestionsContainerDesktop}>
                            <CardContent>
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                let style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                
                                // merge with suggestion styles
                                style = {...style, ...styles.suggestionStyle}

                                const headline = `${suggestion.terms[0].value}`
                                let caption = ''
                                caption += suggestion.terms[1] ? `${suggestion.terms[1].value} ` : ''
                                caption += suggestion.terms[2] ? `${suggestion.terms[2].value} ` : ''
                                caption += suggestion.terms[3] ? `${suggestion.terms[3].value}` : ''
                                suggestion.id = suggestion.placeId; // missing key for React

                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    })}
                                >
                                    <Typography variant="body1">
                                        {headline}
                                    </Typography>
                                    <Typography variant="caption">
                                        {caption}
                                    </Typography>
                                </div>
                                );
                            })}
                            </CardContent>
                        </Card>}
                        </div>
                        )}
                    </PlacesAutocomplete>                
                </Grid>
                <Grid item>
                    <LocationButton onLocationSelected={(newAddr, latLng) => {
                        if (newAddr !== 'error'){
                            this.props.onLocationSelected(newAddr, latLng);
                            this.setState({address: newAddr});
                        }
                    }}/>
                </Grid>
            </Grid>        
            )            
        }

        return(
            <div>
                {this.state.addressPickerOpen &&
                <PickerModal
                    open={this.state.addressPickerOpen}
                    onClose={() => this.setState({addressPickerOpen: false})}
                    onLocationSelected={this.props.onLocationSelected}
                    address={this.props.address}
                />
                }

                <Grid style={styles.addressBox} container spacing={8} justify="space-between" alignItems="flex-end">
                    <Grid item>
                        <SearchIcon />
                    </Grid>
                    <Grid item style={{flex: 1}}>
                        <TextField 
                            style={{marginTop: '0px'}}
                            fullWidth 
                            placeholder='Where to?'
                            InputProps={{
                                disableUnderline: true, 
                                inputProps:{
                                    style:{paddingBottom: '5px'}
                                },
                            }}
                            
                            value={this.props.address}
                            onClick={() => this.setState({ addressPickerOpen: true })}
                        />
                    </Grid>
                    <Grid item>
                        <LocationButton onLocationSelected={this.props.onLocationSelected}/>
                    </Grid>
                </Grid>
            </div>
        )
    }
}