import React, {Component} from 'react';

import {Link, withRouter} from 'react-router-dom';

import { 
  IconButton,
  Toolbar,
  Typography,
  withStyles 
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import NotificationIcon from '@material-ui/icons/Notifications';

//import {getNotifications} from '../api/Oscar-api.js';

const styles = {
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    position: 'absolute',
    marginLeft: -12,
    marginRight: 20,
  },
  notificationButton: {
    position: 'absolute',
    right: '8px',
  },
  unreadNotifications: {
    position: "absolute",
    left: '14px',
    top: '14px',
    height: '10px',
    width: '10px',
    borderRadius: '10px',
    backgroundColor: '#1AA5A2'
  }
};

class ButtonAppBar extends Component {
  state = {unreadNotifications: false}

  componentWillMount() {
    /*getNotifications().then(response => {
      const unreadNotifications = response.data.find(elem => elem.displayed === false)
      if (unreadNotifications) {
        this.setState({unreadNotifications: true})
      }
    }).catch(error => {
      console.log(error)
    })

    setTimeout(() => {
      this.checkNotifications()
    }, 30000)*/
  }

/*  checkNotifications = () => {
    getNotifications().then(response => {
      const unreadNotifications = response.data.find(elem => elem.displayed === false)
      if (unreadNotifications) {
        this.setState({unreadNotifications: true})
      }
    }).catch(error => {
      console.log(error)
    })

    setTimeout(this.checkNotifications, 30000)
  }
*/
  //<div className={classes.root}>
// style={{zIndex: 3}} style={{height:'100%'}}>

  render() {
    const classes = this.props.classes;
    return(
      
        <Toolbar style={{zIndex: 3, height:'100%'}}>
          <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
            onClick={this.props.toggleHandler}
          >
            <MenuIcon />
          </IconButton>
          {this.props.menuOnly === undefined && <Typography variant="title" className={classes.flex} align="center">
            <Link to='/search'>
              <img src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo.svg" alt="Oscar logo" style={
                {
                  width: '104px', 
                  height: 'auto',
                  marginTop: '5px',
                  color: 'black',
                }} />
            </Link>
          </Typography>}
          {this.props.user.authenticated && this.props.menuOnly === undefined &&
          <IconButton className={classes.notificationButton} color="inherit" aria-label="Menu"
            onClick={() => this.props.history.push('/Notifications')}
          >
            <NotificationIcon />
            {this.state.unreadNotifications && <div style={styles.unreadNotifications}></div>}
          </IconButton>}
        </Toolbar>
      
      
    )
  }
}


export default withStyles(styles)(withRouter(ButtonAppBar))
