import React, { Component, StrictMode } from 'react';

import {
    TextField
} from '@material-ui/core';

import { FieldType } from '../api/fieldTypes.js';
import { getAutoComplete } from '../api/Oscar-api.js';

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.activeLookup = null;

    //console.log("[AUTOCOMPLETE:constructor]");
    //console.log("AUTOCOMPLETE: ",this.props);

    let inValue = this.props.initialValueJSON || {label:"",value:""};
    let value = inValue.value;
    let label = inValue.label;

    //console.log({value});
    //console.log({label});

    const myType = this.props.field.type;
    let keyboardType = 'text';
    
    let remoteDomain = this.props.field.remoteDomain;
    //console.log({remoteDomain});

    let placeholder = this.props.field.placeholder || '';
    let maxLength = 150;

    var errorMessage = this.props.error;

    this.state = {
      remoteDomain,
      myType,
      isVisible: true,
      value,
      label,
      placeholder,
      maxLength,
      keyboardType,
      errorMessage,
      results:null,
      isOpen:false
    };


    this.onFocus = this.onFocus.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

  }

  componentDidMount(){
    this._isMounted = true;
  }
  componentWillUnmount(){
    this._isMounted = false;
    if (this.activeLookup) {
      clearTimeout(this.activeLookup);
    }
  }    
  onMouseOver(e) {
    //console.log("onMouseOver");
  }
  onMouseLeave(e) {
    //console.log("onMouseLeave");
  }

  onFocus(e) {
    // On focus, we empty the input to make it easier
    //console.log("onFocus");
    this.setState({value:"", label:"", results:null, errorMessage:null, isOpen:false});
    
    let finalValueJSON = {
      id:this.props.field.id,
      value:"",
      label:""
    };
    //console.log({finalValueJSON});
    //console.log(this.props);
    if (this.props.onChange){
      this.props.onChange({finalValueJSON});
    }

  }

  onKeyPress(e) {
    //console.log("onKeyPress???");
    
    /*    if (this.state.isLocked){
          return;
        }
    */
    /*if (e.key === 'Enter') {
      e.target.blur();
    }*/

  }

  //========================
  //Live Validation
  //========================
  onChange(e) {
    let inText = e.target.value;
    
    // if needed, accept only valid characters while typing
    //console.log("inText:",inText);

    if (inText.length < 2){
      this.setState({value:"", label: inText, isOpen: false, results:null, errorMessage:null}); 
      return;
    }
    
    this.setState({value:"",label: inText, isOpen: true, inProgress:true});     

    if (this.activeLookup){
      //console.log("existing lookup...");
        clearTimeout(this.activeLookup)
        this.activeLookup = null;
    }
    let that = this;    
    let timeMS = 100;
    if (!this.state.results){
      timeMS = 500;
    }

    this.activeLookup = setTimeout(() => {
      this.activeLookup = null;
      getAutoComplete(inText, this.state.remoteDomain).then(response => {
        if (!this._isMounted){
          return;   // silently ignore
        }
        const data = response.data;
        //console.log("ac:",data.options);

        that.setState({ready:true, results:data, data, inProgress:false});
        
      }).catch(error => {
        console.log(error);
      }); 
  
    }, timeMS);     

  }


  //========================
  //Final Validation
  //========================
  onBlur(e) {
    //console.log("Blurx: ", this.state.isOpen);
    //return;
    //console.log("onBlur, close in 2000 ms");
    //console.log("state:",this.state);
    let that = this;
    //that.setState({isOpen:false});
    //return;

    setTimeout(() => {
      //console.log("state2:",that.state);
      if (that.state.value === ""){
        //that.setState({label:'', isOpen:false});
        that.setState({isOpen:false, label: ""});
        //that.setState({isOpen:false});
      } else {
        that.setState({isOpen:false});
      }
      
    }, 2000);     
    

    /*let inText = e.target.value;
    let finalValue = inText;

    const {myType} = this.state;
    var strErrorMessage = null;

    if (inText.length === 0){
      // Allow blank values, will be caught later
    } else if (myType === FieldType.TEXT_EMAIL){
      //=== EMAIL ===
        let inPattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
        let regex = new RegExp(inPattern,"i"); 

        if (!regex.test(inText)){
          strErrorMessage = "Please enter a valid email address";
          finalValue = '';
        }

    }

    //Send the valid value to the parent form
    if (this.props.onUpdate){
      this.props.onUpdate({id:this.props.field.id,finalValue});
    } 
    // ?? strErrorMessage = "xxxxxxx";
    this.setState({errorMessage:strErrorMessage});*/
  }

  onSelectOption(option){
    //console.log("onselect");

    let finalValueJSON = {
      id:this.props.field.id,
      value:option.value + ':' + option.label,
      label:option.label
    };
    //console.log({finalValueJSON});
    //console.log(this.props);
    if (this.props.onChange){
      this.props.onChange({finalValueJSON});
    }
    this.setState({isOpen:false, value:option.value, label:option.label});

  }

  render() {
    const { errorMessage, inProgress} = this.state;

    const data = this.state.data || {};
    const results = this.state.results || {};

    const remainingResults = data.remainingResults || 0;

    let arrOptions = [];
    if (results.options && results.options.length > 0){
      for (let i=0;i<results.options.length;i++){
        let thisOption = results.options[i];
        
        const q = data.q;
        let strLabel = thisOption.label; //.replace(/is/ig,'<b>q</b>');
        let strBuildingName = thisOption.building_name;
        arrOptions.push(<div key={`${i}_${thisOption.value}`} className="ac-option" onClick={() => this.onSelectOption(thisOption)}><p>{strBuildingName}</p><p>{strLabel}</p></div>);
      }
      
      if (remainingResults == 1){arrOptions.push(<div key={'remaining'} className="ac-option-more">Keep typing to see <b>{remainingResults}</b> more result...</div>);}
      if (remainingResults >= 2){arrOptions.push(<div key={'remaining'} className="ac-option-more">Keep typing to see <b>{remainingResults}</b> more results...</div>);}
      
    
      
    } else {
      if (inProgress){
        arrOptions = <div className='ac-option-searching'>Searching...</div>;
      } else {
        //arrOptions = <div className='ac-option-noresults'>No results<br/><a href="https://www.sharewithoscar.com.au/search">Click here to visit the Oscar marketplace</a></div>;
        arrOptions = <div className='ac-option-noresults'>No results<br/><div className="do-redirect" onClick={this.props.onRedirect}>Click here to visit the Oscar marketplace</div></div>;
      }
      
    }
    
    let strClass = "ac-resultbox";
    if (this.state.isOpen){
      strClass += " is-open";
    }

    return (
        <div className="ac-container" onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
        <TextField
          maxLength={this.state.maxLength}
          onChange={this.onChange}  
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          style={{marginTop:'0'}}
            //multiline
            value={this.state.label}
            //rows={3}
            label={this.props.field.label}
            name="description"
            type="text"
            InputLabelProps={{
                shrink: true
            }}
            InputProps={{
              spellCheck: 'false'
            }}
            disabled={this.props.field.readOnly}     
            placeholder={this.state.placeholder}
            fullWidth
            margin="normal"
            error={this.props.error ? true:false}
            helperText={this.props.helperText}
            //helperText={this.props.error ? "ERROR":null}
        />
        <div className={strClass}>
          {arrOptions}
          {/*<pre style={{position:'fixed',left:0,color:'#ffffff'}}>{JSON.stringify(this.state.results,null,2)}</pre>*/}
        </div>
        </div>
      );
   

  }

}

export default AutoComplete;
