import { CollectionsOutlined } from '@material-ui/icons';
import axios from 'axios';
import moment from 'moment'
import {getUserData, getTrips, getListings} from '../api/Oscar-api.js';

import {
    ParseDateTimeParameters,
} from '../utils/TimeFormats';


const BASE_URL = process.env.REACT_APP_DATA_ENDPOINT
//const BASE_URL = 'http://127.0.0.1:8000/'
//const BASE_URL = 'https://matheos-d4ye.localhost.run'

let AUTH_URL = `${BASE_URL}auth/`

if (window.WPDomain){
  axios.defaults.headers.common['wpdomain'] = window.WPDomain;
  if (window.location.host.indexOf('localhost:3000')==-1){
    //AUTH_URL = '/auth/';
  }
}


const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID
const clientSecret = process.env.REACT_APP_OAUTH_CLIENT_SECRET


function createLocalAccount(params) {
  const url = `${BASE_URL}api/v1/users/`
    return axios({
        method: 'post',
        url: url,
        data: params,
    })
}

function requestLocalToken(params) {
    const url = `${AUTH_URL}token/`
    return axios.post(url, {
        client_id: clientId, 
        client_secret: clientSecret, 
        grant_type: 'password',
        username: params.username,
        password: params.password,
    })
}

function requestTokenRefresh(params) {
  //console.log("requestTokenRefresh");  
  const url = `${AUTH_URL}token/`

    let headers = axios.defaults.headers.common
    delete headers['Authorization'];

    return axios({
        method: 'post',
        url: url, 
        headers: headers,
        data: {
            client_id: clientId, 
            client_secret: clientSecret, 
            grant_type: 'refresh_token',
            refresh_token: params.refresh_token
        }
    })
}

function exchangeOAuthToken(token, backend) {
  //console.log("exchangeOAuthToken:",token,backend);
  const url = `${AUTH_URL}convert-token/`

    return axios.post(url, {
        client_id: clientId, 
        client_secret: clientSecret, 
        grant_type: 'convert_token',
        backend: backend,
        token: token,
    });
}

function processTokenRequest(request, authSuccess, authError) {
    request.then(function(response){
        // On successful receipt of a local token:
        //console.log('processTokenRequest', response);
        
        // Save the user's access token
        const accessToken = `${response.data.token_type} ${response.data.access_token}`
        localStorage.setItem("USER_ACCESS_TOKEN", accessToken)
        axios.defaults.headers.common['Authorization'] = accessToken;
        
        // Save the user's refresh token
        localStorage.setItem("USER_REFRESH_TOKEN", response.data.refresh_token);

        return getUserData({'Authorization': accessToken})
    }).then(function(profileResponse){
        //console.log("### profileResponse",profileResponse)
        saveUserProfile(profileResponse)
    }).then((response) => {
        authSuccess(response)
    }).catch((error)=>{
        authError(error)
    })
}


function processTokenResponse(response) {
    //console.log(response);
    //console.log('processTokenResponse', response);
    // Save the user's access token
    const accessToken = `${response.data.token_type} ${response.data.access_token}`
    localStorage.setItem("USER_ACCESS_TOKEN", accessToken)
    axios.defaults.headers.common['Authorization'] = accessToken;
    
    // Save the user's refresh token
    localStorage.setItem("USER_REFRESH_TOKEN", response.data.refresh_token)

    //console.log("ptr-getuserdata...");
    return getUserData({'Authorization': accessToken})
    .then(profileResponse => {
      //console.log("ptr-saveuserpfile...",profileResponse);
        saveUserProfile(profileResponse)
    }).catch(error => {
        console.log(error)
    })
}

function saveUserProfile(profileResponse) {
    /*if (profileResponse.email !== undefined) {
        const heapIdent = profileResponse.email + '.' + profileResponse.referral
        window.heap.identify(heapIdent);
    }*/
    //console.log("saveUserProfile:",profileResponse);
    localStorage.setItem("USER_PROFILE", JSON.stringify(profileResponse.data))
}
function getUserProfile() {
  //console.log("reading USER_PROFILE");
    return JSON.parse(localStorage.getItem("USER_PROFILE"))
}

function getUserBalance() {
    const profile = getUserProfile()
    if (profile !== null) {
        return profile.balance
    } else {
        return 0
    }
}

function getUserEmail() {
    const profile = getUserProfile()
    if (profile !== null) {
        return profile.email
    } else {
        return ''
    }   
}

function logOut() {
    deleteLocalUserData();
}
function deleteLocalUserData() {
    localStorage.removeItem("USER_ACCESS_TOKEN");
    delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem("USER_REFRESH_TOKEN");
    localStorage.removeItem("USER_PROFILE");
    localStorage.removeItem("LOCAL_DATA");
    localStorage.removeItem("lastSearch");
    localStorage.removeItem("spaceSelected");

}
function checkMissingInfo() {
    let missing = []
    const profile = getUserProfile()
    if (!profile){
      // FATAL error, redirect
      //console.log("missing profile data, redirect to login");
      window.location = "/login";
      return;
    }

    let strWP = "";
    if (window.WPDomain === 'community'){
      strWP+="-community";
    }

    if (profile.first_name === "" || profile.first_name === undefined || profile.first_name === null) {
        missing.push('name'+strWP)
    }
    if (profile.last_name === "" || profile.last_name === undefined || profile.last_name === null) {
        if(missing.length === 0) {
            missing.push('name'+strWP)
        }
    }
    if (profile.email === "" || profile.email === undefined || profile.email === null) {
        missing.push('email'+strWP)
    }
    if (profile.telephone_number === "" || profile.telephone_number === undefined || profile.telephone_number === null) {
        missing.push('telephone_number'+strWP)
    }

    if (window.WPDomain){
      
      if (!profile.is_corpuser){

        if (window.WPDomain === 'community'){
          // Community
          if (missing.length == 0 && (profile.default_building_community === "" || profile.default_building_community === undefined || profile.default_building_community === null)) {
            //console.log("missing profile building");
            //console.log({profile});
            missing.push('tenant-community');
          } else if (missing.length == 0 && (profile.default_role_community === "" || profile.default_role_community === undefined || profile.default_role_community === null)) {
            //console.log("missing profile building");
            //console.log({profile});
            missing.push('tenant-community');
          }


          
        } else {

          // Meriton, Demo, Unilodge etc
        //console.log(profile);
        if (profile.tenant_type != 2) {
          // No building means no ability to list
          if (profile.default_building === "" || profile.default_building === undefined || profile.default_building === null) {
            missing.push('tenant')
          }

          // Yes, clients could fool this, we don't care
          if (profile.tenant_id === "" || profile.tenant_id === undefined || profile.first_name === null) {
            missing.push('tenant')
          }
          /*if (profile.lease_end_date === "" || profile.lease_end_date === undefined || profile.lease_end_date === null) {
            missing.push('tenant')
          }*/
        }


        }


  
      }

    }


    return missing
}
function checkProfileComplete() {
    const missingInfo = checkMissingInfo()
    return missingInfo.length === 0
}


function appUser() {
    return {authenticated: true, profileComplete: checkProfileComplete(), profile: getUserProfile()}
}
function emptyProfile() {
    return {userIsReady:true, authenticated: false, profileComplete: false, profile: {} }
}

function initializeApplicationUser(userReadyCallback) {
  //console.log("initializeApplicationUser:",redirectToken);
  /*if (redirectToken){
    deleteLocalUserData();
    tryRefreshAccessToken().then(response => {
      //console.log("refresh successful")  
      ////console.log(JSON.stringify(response));  
      //userReadyCallback(appUser())
    }).catch(error => {
        // Can't authenticate the current user, set them as anonymous
        //userReadyCallback(emptyProfile())
        console.log("ERROR Auth has expired.");
        //window.location="/login";
    })
    return;
  }*/
  // Check if there's a current access token
    const currentToken = localStorage.getItem("USER_ACCESS_TOKEN")

    if(currentToken) {
      //console.log("xxx1");  
      // Try to ask for the user's profile
        // Set the default authorization header
        //axios.defaults.headers.common['Authorization'] = currentToken;
        getUserData({'Authorization': currentToken}).then(response => {

/*          if (window.WPDomain === 'community' && !response.data.is_corpuser){
            console.log("# communit");  
            processUserData(currentToken, response).then(() => {
              console.log("x1:",JSON.stringify(response));  
              userReadyCallback(appUser())
              console.log("x3");
              return;            
            });
            //userReadyCallback(appUser());
            //console.log("x2");
            //return;
            processUserData(currentToken, response).then(() => {
              console.log(JSON.stringify(response));  
              userReadyCallback(appUser())
            })

              
          } else*/
          
          //(window.WPDomain && window.WPDomain !== 'community') || response.data.is_corpuser
          if (response.data.is_corpuser){
              // Corporate users don't load up all bookings at startup etc.
              //console.log("CorpUsers?");  
             // console.log(response);
              userReadyCallback(appUser());
              return;
          } else {
              //console.log("Normal");  
              processUserData(currentToken, response).then(() => {
                //console.log(JSON.stringify(response));  
                userReadyCallback(appUser())
              })
  
            }

        })
        .catch(error => {
            console.log(error)
            // delete axios.defaults.headers.common['Authorization'];
            // Try to refresh the token
            tryRefreshAccessToken().then(response => {
              //console.log("refresh successful")  
              //console.log(JSON.stringify(response));  
              userReadyCallback(appUser())
            }).catch(error => {
                // Can't authenticate the current user, set them as anonymous
                deleteLocalUserData()
                userReadyCallback(emptyProfile())
                console.log("Auth has expired.");
                //window.location="/login";
                
            })
        })
        // If there is currently an access token, initialize with the details
        // currently in storage
        axios.defaults.headers.common['Authorization'] = currentToken;
        return appUser()
    } else {
        // No currently saved token means the user must be anonymous
        //console.log("empty profile");
        userReadyCallback(emptyProfile());
        return emptyProfile()
    }
}

function getApplicationUser() {
    //console.log("getApplicationsUser START");
    const profile = getUserProfile()
    //console.log("getApplicationUser:",  JSON.stringify(profile));

    if (profile !== null) {
        //window.gtag('set', {'user_id': profile.referral}); // Set the user ID using signed-in user_id.
        /*if (profile.email !== undefined) {
            const heapIdent = profile.email + '.' + profile.referral
            window.heap.identify(heapIdent);    
        }*/
    }
    //window.gtag('config', 'UA-87914265-1');
    return getUserProfile() !== null ? appUser() : emptyProfile()
}

function updateUserProfileAjax() {
    //console.log("updateUserProfileAjax");
    const currentToken = localStorage.getItem("USER_ACCESS_TOKEN")
    
    return getUserData({'Authorization': currentToken}).then(response => {
        // Update the user's profile data
        return processUserData(currentToken, response)
    }).catch(error => {
        console.log(error)
    })    
}

function processUserData(currentToken, userData) {
    //saveUserProfile(userData)
    //console.log("processUserData");
    axios.defaults.headers.common['Authorization'] = currentToken;
    
    if (window.WPDomain || userData.data.is_corpuser){
      //console.log("going to save:",userData);
      saveUserProfile(userData);
      return Promise.resolve(value => {
        //console.log("inside promise");
      });
    }

    //SERIOUSLY - THIS HAS TO GO AWAY LATER.
    return Promise.all([getTrips(), getListings()]).then(([bookings, listings]) => {
        // Check bookings to see if there is an expired booking the user has not yet rated
        //console.log("updating user profile", bookings)
        const booking = bookings.data.find(elem => {
            if (elem.review === null 
            && (moment(`${elem.cease_date} ${elem.cease_time}`, ParseDateTimeParameters).isBefore(moment())
            && !elem.cancelled)) {
                return elem
            }
            return null
        })

        if (booking) {
          // Only allow reviews for old parking_spaces for now, not msm parking_bays
          if (booking.parking_space && !window.WPDomain){
            userData.data.pendingReview = booking.reference;
          }
            
        }

        if (listings.data.length > 0) {
            userData.data.listedSpaces = true
        }

        saveUserProfile(userData)
    })
}

function updateUserProfile(params) {
  //console.log("updateUserProfile:",params);
    let profile = getUserProfile()
    for(const p in params) {
        if (p === 'mobile_number') {
            profile['telephone_number'] = params[p]
        } else {
            profile[p] = params[p]
        }
    }
    /*if (params.email !== undefined) {
        const heapIdent = params.email + '.' + params.referral
        window.heap.identify(heapIdent)    
    }*/
    //console.log("updateUserProfile profile:",JSON.stringify(profile));
    localStorage.setItem("USER_PROFILE", JSON.stringify(profile))
}

async function tryRefreshAccessToken() {
    const refreshToken = localStorage.getItem("USER_REFRESH_TOKEN")
    if (refreshToken) {
        return requestTokenRefresh({refresh_token: refreshToken}).then(response => {
            processTokenResponse(response)
        })
    }
    throw new Error("no refresh token found")
}

function getUserBearerToken() {
    return localStorage.getItem("USER_ACCESS_TOKEN")
}


export {
    createLocalAccount,
    processTokenRequest, 
    processTokenResponse,
    requestLocalToken, 
    exchangeOAuthToken, 
    getUserEmail, 
    getUserProfile, 
    checkMissingInfo,
    getUserBalance,
    logOut,

    initializeApplicationUser,
    getApplicationUser,
    updateUserProfile,
    updateUserProfileAjax,

    getUserBearerToken,
}