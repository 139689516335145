const FieldType = {
    DISPLAY:1,
    MONEY_ONLY_POSITIVE_DOLLARS : 2,
    MONEY_ONLY_POSITIVE_DOLLARS_AND_CENTS : 3,
    INTEGER_ONLY_POSITIVE : 4,
  //  INTEGER_ALL : 5,
    INTEGER_YEAR : 6, //YEAR-A IS FOR 1988 ONWARDS
    PERCENTAGE : 7,
    //TFN : 8,
    //BSB : 9,
    //ABN : 10,
  
    DATE_ALL : 11,
    DATE_DOB : 12,
    //DATE_ONLY_TAXYEAR_CURRENT : 13,
    //DATE_ONLY_TAXYEAR_PREVIOUS : 14,
    //DATE_CGT : 15,
  
    PHONE_ONLY_MOBILE : 16,
    PHONE_ONLY_LANDLINE : 17,
    PHONE_ANY_AUSTRALIA : 18,
  
    ADDRESS_ONLY_AUSTRALIA : 19,
    ADDRESS_ALL: 20,
    TEXT_STRING : 21,
    TEXT_NUMERIC : 22,
    TEXT_EMAIL : 23,
    SELECT_DROPDOWN : 24,
    CHECKBOX: 25,
    VERIFICATIONCODE: 26,
    SLIDER: 27,
    TEXTAREA : 28,
    DAYSOFWEEK:29,
    TIMEPICKER:30,
    TIMERANGEPICKER:31,
    IMAGEUPLOAD:32,

    //OCCUPATIONSEARCH: 29,
    //ATTACHMENT: 30,
  
    //YES_OR_NO: 31,
    //EXPLORER:32,
    //WSM:35,
  
    ADVICE_BOX:40,
    HTML:41,
    HELP_MODAL:42,
    
 
    NAVOPTION:45,
  
    DATEBOX:46,
    AUTOCOMPLETE:47
  
  };
  module.exports = {FieldType};