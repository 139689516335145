import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {getListings} from '../api/Oscar-api';

import { 
    Button,
    Grid,
    LinearProgress,
    Typography,
    Hidden, 
} from '@material-ui/core';

import IconNext from '@material-ui/icons/KeyboardArrowRight';
import LinkIcon from '@material-ui/icons/Launch';
import MainNav from '../components/MainNav.js';
import LoadingModal from '../components/LoadingModal.js';

import { isNativeWebview } from '../utils/IsNativeWebview';

import {
    requestLocation, 
} from '../utils/Location.js';

const styles = {
    rightCornerButton: {
        position: 'absolute',
        top: 10,
        right: 5,
        color: 'black',
    },
    addButton: {
      marginTop:'40px',  
      marginBottom: '50px',
        paddingTop:'10px',
        paddingBottom:'10px',
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    listingsContainer: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',

    },
    completeListingContainer: {
        border: '1px solid #efefef',
        padding: '4px',
        marginBottom: '54px',
    },
    imageContainer: {
        textAlign: 'right',
    },
    imagePreview: {
        width: '90px',
        height: '65px',
    },
    editButton: {
        padding: '0px',
        minWidth: '0px',
        minHeight: '0px',
        fontWeight: 'bold',
        marginTop: '10px',
    },

    progressContainer: {
        width: '100%',
        padding: '8px',
        borderTop: '1px solid #efefef',
    },

    progressHeader: {
        marginTop: '2px',
        marginBottom: '16px'
    },

    cardImage: {
        width: '100%',
        height:'100px',
    },
    spaceCard: {
        paddingBottom: '5px',
        position: 'relative'
    },
    editControls: {
        position: 'absolute',
        bottom: '15px',
    },
    listingCard: {
        marginBottom: '40px',
        backgroundColor: '#f1f1f1'
    },

    noListings: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
        marginBottom:'-50px',

        backgroundImage: 'url("https://www.sharewithoscar.com.au/assets/graphics/list_spot.jpg")',
        backgroundRepeat: 'no-repeat', /* Do not repeat the image */
        backgroundSize: 'cover', /* Resize the background image to cover the entire container */
    },
    earnCard: {
        backgroundColor: 'white',
        padding: '24px',
        borderRadius: '4px',
        marginBottom: '16px',
    }
}

function calculateCompletenessPercentage(listing) {
    let complete = 10
    if (listing.form.values.address !== null && listing.form.values.address !== undefined && listing.form.values.address !== '') {
        complete += 15
    }
    if (listing.form.values.type !== null && listing.form.values.type !== undefined && listing.form.values.type !== '') {
        complete += 15
    }
    if (listing.form.values.vehicle_type !== null && listing.form.values.vehicle_type !== undefined && listing.form.values.vehicle_type !== '') {
        complete += 15
    }
    if (listing.form.values.description !== null && listing.form.values.description !== undefined && listing.form.values.description !== '') {
        complete += 15
    }
    if (listing.form.values.instruction !== null && listing.form.values.instruction !== undefined && listing.form.values.instruction !== '') {
        complete += 15
    }

    return complete
}

function externalLink(target, nativeTarget) {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}

// TODO: ELIMINATE THIS, use client post_code (map to city name) instead
const geolocationTimeout = new Promise(function(resolve, reject) { 
    setTimeout(resolve, 2000, 'geolocationTakingTooLong'); 
});

async function getLocation() {
    let location
    try {
        location = await Promise.race([requestLocation(), geolocationTimeout])
        if (location === "geolocationTakingTooLong") {
            throw new Error("invalid location")
        }
    } catch (e) {
        location = {
            address: "Sydney, NSW",
            latLng: { lat: -33.8689725, lng: 151.2099337 },
            suburb: 'Sydney',
        }             
    }
    
    return location    
}

export default class Listings extends Component {
    state = {
        loading: true,
        complete: [],
        inProgress: [],
    }
    componentWillMount() {
        //getLocation().then(location => {
            getListings().then(response => {
                //console.log(response)
                let listings = response.data

                // Read listings progress object 
                let localData = localStorage.getItem("LOCAL_DATA")
                if (localData) {
                    localData = JSON.parse(localData)
                    if ('LISTINGS_IN_PROGRESS' in localData) {
                        function checkMatchingElement(element) {
                            return element.uuid === uuid
                        }
                        for(var uuid in localData['LISTINGS_IN_PROGRESS']) {
                            const localListing = localData['LISTINGS_IN_PROGRESS'][uuid]

                            if (!listings.find(checkMatchingElement)) {
                                listings.push(localListing)
                            }
                        }
                    }
                }

                // Process listings dividing them into in progress and complete.
                // Assign progress to each listing
                let complete = []
                let inProgress = []
                for (let i = 0; i < listings.length; i++) {
                    let listing = listings[i]
                    // Check if this is a local listing or a server stored listing
                    if ('form' in listing) {
                        listing.progress = listing['form']['activePage'] * 15 + 10
                        inProgress.push(listing)
                    } else {
                        listing.progress = 55 + (listing.availabilityrecurring_set.length > 0 ? 15 : 0) + (listing.image_set.length > 0 ? 30 : 0)
                        complete.push(listing)
                    }
                }            

                this.setState({
                    loading: false,
                    complete: complete,
                    inProgress: inProgress,
                    location: 'SUBURB' // location.suburb       // <- get suburb_name from user post_code
                })
            })
        //})
    }

    handleFinishInProgress = (listing) => {
        this.props.history.push({
            pathname: '/listspace',
            state: { hydrateState: listing }
        })
    }

    handleFinishComplete = (listing) => {
        this.props.history.push({
            pathname: '/listspace',
            state: { completeListing: listing }
        })
    }

    handleEdit = (listing) => {
      //console.log("handleEdit:",listing);
        this.props.history.push({
            pathname: '/listspace',
            state: {editListing: listing}
        })
    }



    render() {
      // TODO: auto-generate from FORM
      const pageNames = {
        "address":0,
        "details":1,
        "instructions":2,
        "pricing":3,
        "availability":5,
        "photos":7
      };

    

      return(
            <div style={{height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column',paddingBottom:'50px'}}>
                <LoadingModal open={this.state.loading} />

                                    
                {!this.state.loading && this.state.complete.length === 0 && this.state.inProgress.length === 0 &&
                <div style={styles.noListings} className="mainContent topPadded">
                    <Hidden mdUp>
                    <div style={{position: 'absolute', top: '0px', left: '2px' }} >
                    <MainNav user={this.props.user} menuOnly={true} />
                    </div>                        
                    </Hidden>

                    <div style={styles.listingsContainer}>
                        <Typography gutterBottom variant="display1" color="primary">
                            Earn money by listing your spot
                        </Typography>

                        <Grid style={{flexGrow: 1, flexDirection: 'column', justifyContent: 'flex-end'}} container>
                            <Grid item xs={12} md={6}>
                                <div style={styles.earnCard}>
                                    <Typography variant="headline">
                                        What you could earn
                                    </Typography>

                                    <div style={{marginTop: '24px'}}>
                                        <Typography variant="title">Location</Typography>
                                        <Typography variant='body1'>{this.state.location}</Typography>
                                    </div>

                                    <div style={{marginTop: '24px'}}>
                                        <Typography variant="title">Spot Type</Typography>
                                        <Typography variant='body1'>Garage</Typography>
                                    </div>
                                    <hr style={{margin: '14px 0px'}}/>

                                    <Grid style={{marginBottom: '24px'}} container spacing={24}>
                                        <Grid item xs={6}>
                                            <Typography style={{marginBottom: '2px', color: 'black'}} variant='display1'>
                                                $164 AUD
                                            </Typography>
                                            <Typography variant='caption'>
                                                in monthly earnings
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='caption'>
                                                Learn more about listing with Oscar 
                                                <span onClick={() => externalLink("https://www.sharewithoscar.com.au/rent-my-parking-space/", "https://www.sharewithoscar.com.au/rent-my-parking-space/")}>
                                                    <LinkIcon style={{marginLeft: '2px', fontSize: '13px', verticalAlign: 'top'}} />
                                                </span>
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Button style={styles.addButton} fullWidth variant="contained" color="primary" 
                                    component={Link} to="/listspace/first"
                                    id="add_spot">
                                        Get started
                                    </Button>    
                                </div>  
                            </Grid>
                        </Grid>                      
                    </div>
                </div>}

                {!this.state.loading && (this.state.complete.length > 0 || this.state.inProgress.length > 0) &&
                <div className="mainContent topPadded">
                    <Hidden smUp>
                    <div style={{position: 'absolute', top: '0px', left: '2px' }} >
                    <MainNav user={this.props.user} menuOnly={true} />
                    </div>                        
                    </Hidden>

                    <Typography gutterBottom variant="display1" color="primary">
                        My Spots
                    </Typography>


                    {!this.state.loading && (this.state.complete.length > 0 || this.state.inProgress.length > 0) && 
                      this.state.complete.map((listing, i) => {
                        const my_street_name = listing.suburb ? `${listing.street_name}, ${listing.suburb}`:listing.street_name;

                        const imgAllow = <img src='https://images.sharewithoscar.com.au/assets/graphics/oscar_yes.svg'/>;
                        const imgDisallow = <img src='https://images.sharewithoscar.com.au/assets/graphics/oscar_no.svg'/>;
                        const imgAddPhotos = <img src='https://images.sharewithoscar.com.au/assets/graphics/oscar_add_photos.svg'/>;
                        // 
                        return (

                          <div key={listing.uuid} className="v3-listing-container">
                            <div className="listing-left">
                              {!listing.type && <div className="street_name">Your parking space on {my_street_name}</div>}
                              {listing.type &&  <div className="street_name">Your <b>{listing.type}</b> on {my_street_name}</div>}

                              <div className="listing-description">{listing.description}</div>

                              {pageNames[listing.list_new_progress] >= pageNames['availability'] ? <div className="permissions">{imgAllow} Space available</div>:<div className="permissions">{imgDisallow} Space unavailable</div>}
                              {pageNames[listing.list_new_progress] >= pageNames['pricing'] &&
                                <>
                                {listing.auto_approve ? <div className="permissions">{imgAllow} Instant bookings on</div>:<div className="permissions">{imgDisallow} Instant bookings off</div>}
                                {listing.allow_casual ? <div className="permissions">{imgAllow} Casual bookings on at <b>${parseFloat(listing.rate_hourly).toFixed(2)}/hr</b></div>:<div className="permissions">{imgDisallow} Casual bookings off</div>}
                                {listing.allow_monthly ? <div className="permissions">{imgAllow} Monthly bookings on at <b>${listing.rate_monthly}/mth</b></div>:<div className="permissions">{imgDisallow} Monthly bookings off</div>}
                                </>
                              }

                              <div className="image-list-container">
                                {listing.image_set && listing.image_set.map((image, i) => {
                                  let imageUrl = image.path;
                                  imageUrl = imageUrl.replace("https://www.sharewithoscar.com.au/images/","https://images.sharewithoscar.com.au/");
                                  imageUrl += "?tr=w-90,h-65,fo-auto";
                                  
                                  return(<img key={i + '_' + image.path} style={styles.imagePreview} src={imageUrl} alt="Parking space preview" />)
                                })}
                              {pageNames[listing.list_new_progress] >= pageNames['availability'] && <div id="btn-add-photos" onClick={() => this.props.history.push(`/listspace?uuid=${listing.uuid}&page=${pageNames['photos']}`)}>{imgAddPhotos}<span>Add Photos</span></div>}
                                
                            </div>
                                                                  
                            </div>
                            <div className="listing-right">
                              
                              <Button variant="contained" onClick={() => this.props.history.push(`/listspace?uuid=${listing.uuid}&page=${pageNames['address']}`)} color="primary">Edit Listing</Button>                                
                              {pageNames[listing.list_new_progress] >= pageNames['instructions'] && <Button variant="contained" onClick={() => this.props.history.push(`/listspace?uuid=${listing.uuid}&page=${pageNames['pricing']}`)} color="primary">Set Pricing</Button>}
                              {pageNames[listing.list_new_progress] >= pageNames['pricing'] && <Button variant="contained" onClick={() => this.props.history.push(`/listspace?uuid=${listing.uuid}&page=${pageNames['availability']}`)} color="primary">Set Availability</Button>}
                              
                              
                            </div>

                          </div>

                        );
                    })}

                    {!this.state.loading && (this.state.complete.length > 0 || this.state.inProgress.length > 0) && 
                      <div>
                        <Button style={styles.addButton} fullWidth variant="contained" color="primary" component={Link} to="/listspace/" id="add_spot">Add another spot</Button>
                      </div>
                    }
                    
                </div>
                }
            </div>
        )
    }
}
