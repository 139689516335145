import React, { Component } from 'react';

import moment from 'moment';
import {
    ParseDateTimeParameters,
} from '../utils/TimeFormats';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Typography
} from '@material-ui/core';

import {getEvent} from '../api/Oscar-api';

import CircularProgress from '@material-ui/core/CircularProgress';


class EventViewer extends Component {
  constructor(props) {
    super(props);
    //console.log("[EV:constructor]");

    this._isMounted = false;

    let eventId = this.props.match.params.id;
    let eventType = "event";
    if (eventId){
      if (eventId.substr(0,14)==='sg_message_id_'){
        eventType = "sendgrid";
        
      } else {

      }
    }

    this.state = {
      eventId,
      eventType,
      initialLoad:false,
      data:null
    };

    //this.onBlur = this.onBlur.bind(this);
  }


  /*onFocus(e) {

  }*/
  componentWillUnmount(){
    this._isMounted = false;
  }    

  componentDidMount(){
    this._isMounted = true;
    let that = this;
    setTimeout(() => {
      
      let queryParams = {
        eventId: this.state.eventId,
        eventType: this.state.eventType
      };

      getEvent(queryParams).then(response => {
        
        //console.log("event loaded:",response);
        that.setState({initialLoad:true, data:response.data});
      })
      .catch(error => {
        
        if (error.response && error.response.data && error.response.data.detail){
          that.setState({errorMessage:"Could not load event:" + error.response.data.detail});
        } else {
          that.setState({errorMessage:"Could not load event:" + error.toString()});
          console.error(error);
        }
        
        
        

        
      });



    }, 3);

  }



  render() {
    const {eventId, eventType, initialLoad, errorMessage, data} = this.state;

    if (errorMessage){
      return (
        <div className="wpadmin-content">
          <h1>Event Viewer</h1>
          <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
            {errorMessage}
          </div>
        </div>
      );

    }
    if (!initialLoad){
      return (
        <div className="wpadmin-content">
          <h1>Event Viewer</h1>
          <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
            <CircularProgress  size={80} thickness={4.5} style={{color:"#1AA5A2"}} />
          </div>
        </div>
      );
    }

    let arrEvents = [];
    
    let items;


    if (data.meta && data.meta.items){
      items = data.meta.items;

      /*for (let i=0;i<items.length;i++){
        let item = items[i];
        let itemJSON = JSON.parse(item.eventJSON);

        let strTime = moment(itemJSON.time).format("DD-MMM-YYYY h:mm:ssA");
        arrEvents.push(
        <div key={i} className="ev-event">
            #{i+1}: {item.detailType} at {strTime} UTC from {itemJSON.source}
            
           <pre>{JSON.stringify( itemJSON,null,2 )}</pre>
        </div>);
      }*/
  
    }

    //<pre>{JSON.stringify(data,null,2)}</pre>
    //  top-padded-mobile
    return (
      <div className="wpadmin-content">
        <h1>Event Viewer</h1>

        <div className="ev-container">
          {items.map((item, i) => {
            return(<EventItem key={i} index={i} event={item} />);
          })}

        </div>

        
        


      </div>
      

    );

  }

}

function EventItem(props) {
  
  const event = props.event;
  let eventJSON = JSON.parse(event.eventJSON);
  const detail = eventJSON.detail;
  let eventDate = moment(eventJSON.time);
  let strDate = eventDate.format("MMM DD, YYYY");
  let strTime = eventDate.format("h:mm a");

  let details; // = <div>DETAILS HERE</div>;


  
  if (eventJSON.source === 'custom.send_branded_email'){
    /*return(
      <div key={props.i} className={"ev-event " + eventJSON['detail-type'] }>
        <div className="timestamp">{strDate}<br/>{strTime}</div>
        <div className="track">IMG</div>
        <div className="details">

          {event.detailType}
          <hr/>
          Template: {detail.template_name} [{detail.wpdomain}]
          <hr/>
          Recipient: {eventJSON.detail.recipient}
          <br/>CC/BCC?
          <hr/>
          Subject: {eventJSON.detail.subject}
          <hr/>
          <div className="ev-html">
            <div dangerouslySetInnerHTML={{__html: eventJSON.detail.contentHTML}}/>
          </div>

        </div>
        
      </div>
    );*/
      details = (<>
          <p>Template: {detail.template_name} [{detail.wpdomain}]</p>
          
          <p>Recipient: {eventJSON.detail.recipient}</p>
          
          <p>CC/BCC?</p>
          
          <p>Subject: {eventJSON.detail.subject}</p>
          
          <div className="ev-html">
            <div dangerouslySetInnerHTML={{__html: eventJSON.detail.contentHTML}}/>
          </div>
      </>);
  

  } else if (eventJSON.source === 'custom.sendgrid' && eventJSON['detail-type'] === 'delivered'){
    /*return(
      <div key={props.i} className={"ev-event " + eventJSON['detail-type'] }>
        {event.detailType} at {strTime}
        <hr/>
        Response: {detail.response}
      </div>
    );*/
    details = (
      <>
        <p>{detail.response}</p>
      </>
    );


  } else if (eventJSON.source === 'custom.sendgrid' && (eventJSON['detail-type'] === 'dropped') || eventJSON['detail-type'] === 'bounce') {
    /*return(
      <div key={props.i} className={"ev-event " + eventJSON['detail-type'] }>
      {event.detailType} at {strTime}
      <hr/>
      Reason: {detail.reason}
    </div>
      );*/
      details = (
        <>
          {detail.reason}
        </>
      );

  } else if (eventJSON.source === 'custom.sendgrid' && eventJSON['detail-type'] === 'deferred') {

/*    return(
      <div key={props.i} className={"ev-event " + eventJSON['detail-type'] }>
      {event.detailType} at {strTime}
      <hr/>
      Response: {detail.response}
      <hr/>
      Attempt #{detail.attempt}
    </div>
  
    );*/
    details = (<>
      Attempt #{detail.attempt}
      <br/>
      {detail.response}
      

      </>);

  } else if (eventJSON.source === 'custom.sendgrid' && eventJSON['detail-type'] === 'open') {
        
    /*return(
      <div key={props.i} className={"ev-event " + eventJSON['detail-type'] }>
      {event.detailType} at {strTime}
      <hr/>
      User Agent: {detail.useragent}
      {detail.sg_machine_open && <p>Machine Opened</p>}
    </div>
  
    );*/
    details = (
      <>
        <p>{detail.useragent}</p>
        {detail.sg_machine_open && <p>Machine Opened</p>}
      </>
    );

  } else if (eventJSON.source === 'custom.sendgrid' && eventJSON['detail-type'] === 'click') {
    /*return(
      <div key={props.i} className={"ev-event " + eventJSON['detail-type'] }>
      {event.detailType} at {strTime}
      <hr/>
      Url: {detail.url}
      <hr/>
      User Agent: {detail.useragent}
    </div>
      );*/
      details = (
        <>
          Url: {detail.url}
          <hr/>
          User Agent: {detail.useragent}
        </>
      );

  } else if (eventJSON.source === 'custom.sendgrid' && eventJSON['detail-type'] === 'processed'){
    
    details = (
      <>
        <p>Accepted by SendGrid</p>
      </>
    );

  } else {

    /*return(
      <div key={props.i} className={"ev-event " + eventJSON['detail-type'] }>
        {event.detailType} at {strTime}
        <hr/>
        <pre>{JSON.stringify(eventJSON,null,2)}</pre>
      </div>
    );*/
    details = (
      <>
        <pre>{JSON.stringify(eventJSON,null,2)}</pre>
      </>
    );
  
  }


  //========================================
  // Return the final event content // + eventJSON['detail-type'] 
  return(
    <div key={props.i} className={"ev-event"}>
      <div className="timestamp"><div>{strDate}</div><div>{strTime}</div></div>
      <div className="track"></div>
      <div className={"details " + eventJSON['detail-type']}>

        <h1>{event.detailType}</h1>
        {details}

      </div>
      
    </div>
  );


}


export default EventViewer;
