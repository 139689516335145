import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';

export default class DefaultAjaxErrorHandler extends Component {
    render(props) {
        function HandleError(props) {
            if( props.errorCode === 401 ) {
                return <Redirect to="/Login"/>
            }
            return (<div>{props.errorCode}</div>)
        }

        return(
            <HandleError errorCode={this.props.errorCode} />

        )
    }
}