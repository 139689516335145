import React, {Component} from 'react';

import {
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText
} from "@material-ui/core"

export default class SelectField extends Component {
    render() {
        let classes = this.props.classes
        if (classes === undefined) {
            classes = {}
        }

        return(
            <div>
                <InputLabel 
                    FormLabelClasses={{
                        root: classes.formLabelRoot,
                        error: classes.formLabelError,
                        focused: classes.formLabelFocused,
                    }} 
                    error={this.props.error} shrink>{this.props.label}</InputLabel>
                <Select
                    input={
                    <Input classes={{
                        'root': classes.input,
                        'error': classes.inputError,
                    }} 
                    name={this.props.name} id="" />}
                    fullWidth
                    displayEmpty
                    error={this.props.error}
                    value={this.props.value}
                    onChange={this.props.onChange}
                >
                    {this.props.placeHolder !== undefined && 
                    <MenuItem value={undefined} disabled>
                        {this.props.placeHolder}
                    </MenuItem>}
                    {this.props.nullable === true && 
                    <MenuItem value={null}>
                        {this.props.nullOption}
                    </MenuItem>
                    }
                    {this.props.options.map((option, i) => {
                        return(
                            <MenuItem key={i} value={option}>{option}</MenuItem>
                        )
                    })}
                </Select>
                {this.props.error && 
                <FormHelperText classes={{
                        root: classes.helperText,
                        error: classes.helperTextError
                    }} error={this.props.error}>
                    {this.props.helperText}
                </FormHelperText>}
            </div>
        )
    }
}

// export default class SelectField extends Component {
//     render() {
//         if (this.props.white === true) {
//             return(
//                 <div style={{position: 'relative'}}>
//                     <InputLabel style={{color: 'white'}} error={this.props.error} shrink>{this.props.label}</InputLabel>
//                     <Select
//                         input={<Input style={styles.whiteInput} name={this.props.name} id="" />}
//                         IconComponent={(props) => <ArrowDropDownIcon {...props} style={{color: 'white'}}/>}
//                         fullWidth
//                         displayEmpty
//                         style={styles.whiteInput} 
//                         error={this.props.error}
//                         value={this.props.value}
//                         onChange={this.props.onChange}
//                     >
//                         <MenuItem value={undefined} disabled>
//                             {this.props.placeHolder}
//                         </MenuItem>
//                         {this.props.options.map((option, i) => {
//                             return(
//                                 <MenuItem key={i} value={option}>{option}</MenuItem>
//                             )
//                         })}
//                     </Select>
//                     <div style={styles.underline}></div>
//                     {this.props.error && <FormHelperText error={this.props.error}>{this.props.helperText}</FormHelperText>}
//                 </div>
//             )
//         }

//         return(
//             <div>
//                 <InputLabel error={this.props.error} shrink>{this.props.label}</InputLabel>
//                 <Select
//                     input={<Input name={this.props.name} id="" />}
//                     fullWidth
//                     displayEmpty
//                     error={this.props.error}
//                     value={this.props.value}
//                     onChange={this.props.onChange}
//                 >
//                     <MenuItem value={undefined} disabled>
//                         {this.props.placeHolder}
//                     </MenuItem>
//                     {this.props.options.map((option, i) => {
//                         return(
//                             <MenuItem key={i} value={option}>{option}</MenuItem>
//                         )
//                     })}
//                 </Select>
//                 {this.props.error && <FormHelperText error={this.props.error}>{this.props.helperText}</FormHelperText>}
//             </div>
//         )    
//     }
// }
