import React, {Component} from 'react';
import moment from 'moment';

import { 
    Button,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@material-ui/core';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';

import {getMessages, getBookingDetails, postMessage} from '../api/Oscar-api.js';

import LoadingModal from '../components/LoadingModal';
import LoadingModalWP from '../components/LoadingModalWP';

const styles = {
    topLeftButton: {
        position: 'absolute',
        top: '29px',
        left: '0px',
    },
    topSection: {
        paddingTop: '10px',
        paddingBottom: '12px',
        borderBottom: '1px solid #efefef',
        fontFamily: 'Rubik',
        lineHeight: '1.5rem'
    },
    messagingContainer: {
      fontFamily: 'Rubik',
      display: 'flex', 
      flexDirection: 'column', 
      height: '100%' 
    },
    messageContainer:{
      fontFamily: 'Rubik',
      marginBottom: '16px'
    }, 
    messageContent: {
      fontFamily: 'Rubik',
      padding: '10px 16px',
        borderRadius: '6px',
        width: '290px'
    },
    receiverMessage: {
      fontFamily: 'Rubik',
      backgroundColor: '#efefef'
    },
    senderMessage: {
      fontFamily: 'Rubik',
      color: 'white',
        backgroundColor: '#1AA5A2'
    },
    messageProfilePicture: {
        width: '48px',
        height: '48px',
        borderRadius: '28px',
        marginBottom: '-4px',
    },
    messageInput: {
      fontFamily: 'Rubik',
      borderTop: '1px solid #efefef'
    },
    messageInputField: {
      fontFamily: 'Rubik',
      margin: '8px',
        marginLeft: '16px'
    },
}

export default class MessagesCP extends Component {
  constructor(props){
    super(props);

    this._lastTimestamp = null;
    this.state = {
      loading: true,
      pollingReference: undefined
    }

  }
  
  
      
    componentWillMount() {
        const bookingRef = this.props.reference;
        
        getMessages(bookingRef).then(messages => {
          this.setState({
            loading: false,
            //localUser: localUser,
            //otherUser: otherUser,
            messages: messages.data,
          });
        this._messagesEnd.scrollIntoView();

        //this.setState({pollingReference: setTimeout(this.checkMessages, 5000) });


        }).catch(error => {
          console.error(error);
        });

/*        Promise.all([getBookingDetails(bookingRef), getMessages(bookingRef)]).then(([details, messages]) => {
            //const role = this.props.userRole;
            
            let localUser; let otherUser

            if (role === 'driver') {
                localUser = details.data.driver
                otherUser = details.data.owner
            } else  {
                localUser = details.data.owner
                otherUser = details.data.driver
            }



            this.setState({
                loading: false,
                //localUser: localUser,
                //otherUser: otherUser,
                messages: messages.data,
            })
            this._messagesEnd.scrollIntoView()

            this.setState({pollingReference: setTimeout(this.checkMessages, 5000) })
        }).catch(error => {
            console.log(error)
        })*/

    }



    componentDidUpdate(prevProps, prevState){
      if (this.props.CMS.hasMessage == 1 && this.props.CMS.timestamp !== this._lastTimestamp){
        this._lastTimestamp = this.props.CMS.timestamp;
        this.checkMessages();
      } 
    }



    checkMessages = () => {
        getMessages(this.props.reference).then(response => {
            if (response.data.length > this.state.messages.length) {           
                this._messagesEnd.scrollIntoView()
            }
            this.setState({
                messages: response.data
            })
            
            //this.setState({pollingReference: setTimeout(this.checkMessages, 5000) })
        }).catch(error => {
            console.error(error);

            //this.setState({pollingReference: setTimeout(this.checkMessages, 5000) })
        })
    }

    componentWillUnmount = () => {
        clearTimeout(this.state.pollingReference)
    }

    handleChange = (event) => {
        this.setState({
            messageValue: event.target.value
        })
    }

    handleKeyDown = (event) => {
      if(event.keyCode == 13){
         this.handleSend();
         event.preventDefault();
      }
    }

    handleSend = () => {
        const message = this.state.messageValue;
        const bookingRef = this.props.reference;

//        let currentMessages = this.state.messages
        this.setState({messageValue: ''})
        this._messagesEnd.scrollIntoView()

        postMessage({'content': message}, bookingRef).then(() => {
            getMessages(bookingRef).then(response => {
                this.setState({messages: response.data})
                ////this._messagesEnd.scrollIntoView()
            })
        }).catch(error => {
            console.error(error);
        })
    }

    render() {
      //console.log(this.state);
        return(
            <div style={{height: '100%',position:'relative'}}>
                <LoadingModalWP open={this.state.loading} />
                
                {!this.state.loading && <div style={styles.messagingContainer}>
                    <div style={styles.topSection}>
                        {/*<IconButton style={styles.topLeftButton}
                        onClick={this.props.history.goBack}>
                            <IconBack style={{fontSize: '30px'}}/>
        </IconButton>*/}
                        
                          <h2>{this.props.remoteName}</h2>
                            
                        
                    </div>
                    {this.state.messages.length === 0 ?
                    <div style={{flexGrow: 1, padding: '16px', overflowY: 'scroll'}}>
                        <Typography style={{textAlign: 'center', color:'#acacac'}}>
                            Start a conversation with {this.props.remoteName}
                        </Typography>
                    </div>
                    :<div style={{flexGrow: 1, padding: '16px', overflowY: 'scroll'}}>
                        {this.state.messages.map((message, i) => {
                            if ((this.props.userRole === 'driver' && message.is_driver === 1) ||
                                (this.props.userRole === 'owner' && message.is_driver === 0)) {
                                return(
                                    <Grid style={styles.messageContainer} spacing={8} key={i} container justify="flex-start" alignItems="flex-end">
                                        <Grid item>
                                            {message.user.image_user !== null
                                            ? <img style={styles.messageProfilePicture} src={message.user.image_user.path} alt="user profile"/>
                                            : <img style={styles.messageProfilePicture} src="https://www.sharewithoscar.com.au/assets/graphics/profile_placeholder.png" alt="user profile"/>}
                                        </Grid>
                                        <Grid style={{...styles.messageContent, ...styles.receiverMessage}} item>
                                            <Typography gutterBottom variant="body1">
                                                {message.content}
                                            </Typography>
                                            <Typography variant="caption">
                                                {moment(message.update_date).fromNow()}    
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )    
                            } else {
                                return(
                                    <Grid style={styles.messageContainer} spacing={8} key={i} container justify="flex-end" alignItems="flex-end">
                                        <Grid style={{...styles.messageContent, ...styles.senderMessage}} item>
                                            <p>{message.content}</p>
                                            <span className="x">{moment(message.update_date).fromNow()}</span>
                                        </Grid>
                                        <Grid item>
                                            {message.user.image_user !== null
                                            ? <img style={styles.messageProfilePicture} src={message.user.image_user.path} alt="user profile"/>
                                            : <img style={styles.messageProfilePicture} src="https://www.sharewithoscar.com.au/assets/graphics/profile_placeholder.png" alt="user profile"/>}
                                        </Grid>
                                    </Grid>
                                )
                            }
                        })}
                    </div>}
                    <div ref={(el) => { this._messagesEnd = el; }}></div>
                    <div style={styles.messageInput}>
                        <Grid container alignItems="center">
                            <Grid item style={{flexGrow: 1}}>
                                <TextField
                                    multiline
                                    name="access"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{disableUnderline: true}}
                                    placeholder="Write your message..."
                                    fullWidth
                                    margin="normal"
                                    style={styles.messageInputField}

                                    value={this.state.messageValue}
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                onClick={this.handleSend}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>}
            </div>
        )
    }
}