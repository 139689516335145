import React, {Component} from 'react';

import {
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText
} from "@material-ui/core"

export default class SelectFieldWP extends Component {
    render() {
        let classes = this.props.classes
        if (classes === undefined) {
            classes = {}
        }
//console.log("classes:",classes);
        return(
            <div>
                <InputLabel 
                    FormLabelClasses={{
                        root: classes.formLabelRoot,
                        error: classes.formLabelError,
                        focused: classes.formLabelFocused,
                    }} 
                    error={this.props.error} shrink>{this.props.label}</InputLabel>
                <Select
                    input={
                    <Input classes={{
                        'root': classes.input,
                        'error': classes.inputError,
                    }} 
                    name={this.props.name} id="" />}
                    fullWidth
                    displayEmpty
                    error={this.props.error}
                    value={this.props.value}
                    onChange={this.props.onChange}
                >
                    {this.props.placeHolder !== undefined && 
                    <MenuItem value={undefined} disabled>
                        {this.props.placeHolder}
                    </MenuItem>}
                    {this.props.nullable === true && 
                    <MenuItem value={null}>
                        {this.props.nullOption}
                    </MenuItem>
                    }
                    {this.props.options.map((option, i) => {
                        return(
                            <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
                        )
                    })}
                </Select>
                {this.props.error && 
                <FormHelperText classes={{
                        root: classes.helperText,
                        error: classes.helperTextError
                    }} error={this.props.error}>
                    {this.props.helperText}
                </FormHelperText>}
            </div>
        )
    }
}

