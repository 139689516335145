import axios from 'axios';
import { isWindows } from 'react-device-detect';

//console.log(window.location.host);
let s=window.location.host.split(".")[0];if(s!=='www'&&s!=='application'&&s!='oscar-ui'&&s!='localhost:3000'&&s!='oscar' && window.location.host.indexOf('192.168.1.') < 0){window.WPDomain=s;}
//console.log({s});
window.PaymentMode = "BRAINTREE";

// DEBUG - allow Corporate Portal in Oscar view with Meriton Portal in localhost mode
//if (s==='localhost:3000'){window.WPDomain='carshare';} 
//if (s==='localhost:3000'){window.WPDomain='demo';} 
//if (s==='localhost:3000'){window.WPDomain='unilodge';} 
//if (s==='localhost:3000'){window.WPDomain='community';} 
if (s==='localhost:3000'){window.WPDomain='meriton';} 
if (window.WPDomain === 'community'){
  document.title = `Oscar Community`;// ${strRoute}`;
}



if (window.WPDomain){
if (window.WPDomain === 'stockland' || window.WPDomain === 'carshare'){
    window.WPMode = 'spaces';
  } else {
    window.WPMode = 'bays';
  }
} else {
  window.WPMode = 'spaces';
}

//console.log(window.WPMode);


const BASE_URL = process.env.REACT_APP_DATA_ENDPOINT;
const API2_URL = process.env.REACT_APP_API2_ENDPOINT;
const SEARCH_URL = process.env.REACT_APP_SEARCH_ENDPOINT;
let AUTOCOMPLETE_URL = process.env.REACT_APP_SEARCH_ENDPOINT.replace('.com.au/search','.com.au/ac');
AUTOCOMPLETE_URL = AUTOCOMPLETE_URL.replace('3999/search','3999/ac');


//const BASE_URL = 'https://oscardevapi.herokuapp.com/'

let API_URL = `${BASE_URL}api/v1/`
const environment = process.env.REACT_APP_ENVIRONMENT;

axios.defaults.headers.common['Cache-Control'] = 'no-cache';

//console.log('window.WPDomain:',window.WPDomain);
if (window.WPDomain){
  if (s!='localhost:3000' && s!='oscar.mylocalhostip.com:3000' && window.location.host.indexOf('192.168.1.') < 0){
    //API_URL = '/api/v1/';
    //console.log("not localhost");
  } else {
    //console.log("localHost add header");
    axios.defaults.headers.common['wpdomain'] = window.WPDomain;
  }
}


function getReferrer(queryParams) {
    const url = `${API_URL}referrer/`
    return axios({
        method: 'get',
        url: url,
        params: queryParams
    })
}

function getUserData(headers) {
    const url = `${API_URL}users/`
    return axios.get(url, {'headers': headers})
}
function updateUserData(queryParams) {
    const url = `${API_URL}users/`
    return axios.patch(url, queryParams)
}
function resetPassword(queryParams) {
    const url = `${API_URL}users/password_reset/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}
function updatePassword(queryParams) {
    const url = `${API_URL}users/password_update/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}


function getCMS(email) {
  const queryParams = {
    email,
    environment
  };
  const url = `${API2_URL}cm`;
  return axios({
      method: 'post',
      url: url,
      data: queryParams,
  });
}


function getNotifications() {
    const url = `${API_URL}users/notifications/`
    return axios({
        method: 'get',
        url: url,
    })
}
function setNotificationsRead() {
    const url = `${API_URL}users/notifications/`
    return axios({
        method: 'post',
        url: url,
    })
}
function getConversations(conversationId=null) {
  
  let url;
  if (conversationId){
    url = `${API_URL}users/conversations/${conversationId}/`;

  } else {
    url = `${API_URL}users/conversations/`;

  }
  return axios({
      method: 'get',
      url: url,
  })
}


function getLongTermEnquiries(lteId=null) {
  
  let url;
  if (lteId){
    url = `${API_URL}users/longtermenquiries/${lteId}/`;

  } else {
    url = `${API_URL}users/longtermenquiries/`;

  }
  return axios({
      method: 'get',
      url: url,
  })
}

function getUserPayoutMethod() {
    const url = `${API_URL}users/bank_account/`
    return axios({
        method: 'get',
        url: url,
    })
}
function createUserPayoutMethod(queryParams) {
    const url = `${API_URL}users/bank_account/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams
    })
}
function updateUserPayoutMethod(queryParams) {
    const url = `${API_URL}users/bank_account/`
    return axios({
        method: 'patch',
        url: url,
        data: queryParams
    })
}

function getVehicles() {
    const url = `${API_URL}users/vehicles/`
    return axios({
        method: 'get',
        url: url
    })
}
function createVehicle(queryParams) {
    const url = `${API_URL}users/vehicles/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams
    })
}
function updateVehicle(queryParams, entity) {
    const url = `${API_URL}users/vehicles/${entity}/`
    return axios({
        method: 'patch',
        url: url,
        data: queryParams
    })
}

function getUserPaymentPreferences() {
    const url = `${API_URL}users/payment_preferences/`
    return axios({
        method: 'get',
        url: url,
    })
}
function updateUserPaymentPreferences(queryParams) {
    const url = `${API_URL}users/payment_preferences/`
    return axios({
        method: 'patch',
        url: url,
        data: queryParams
    })
}

function savePaymentCard(queryParams) {
    const url = `${API_URL}users/stripe_token/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}

function savePaymentCard_Braintree(queryParams) {
    const url = `${API_URL}users/braintree_token/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}



function savePaymentDD(queryParams) {
  const url = `${API_URL}users/dd_token/`
  return axios({
      method: 'post',
      url: url,
      data: queryParams,
  })
}


function getUserTransactions() {
    const url = `${API_URL}users/transactions/`
    return axios({
        method: 'get',
        url: url
    })
}

function getBuildingsOptionsList(){
  const url = `${API_URL}users/list_all_buildings/`
  return axios({method: 'get',url: url});

}

function updateTenantData(queryParams) {
  const url = `${API_URL}users/update_tenant/`
  return axios({
      method: 'post',
      url: url,
      data: queryParams,
  })
}

function updatePhoneNumber(queryParams) {
    const url = `${API_URL}users/update_number/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}
function verifyPhoneNumber(queryParams) {
    const url = `${API_URL}users/verify_number/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}

function getLocationSpaces(queryParams) {
    const url = `${API_URL}parking_spaces/`;
    return axios.get(url, {params:queryParams});
}
function getLocationSpacesClosest(queryParams) {
    const url = `${API_URL}parking_spaces_closest/`;
    
    // save the most recent query in local storage to return to it at a later point.
    // console.log(queryParams)
    //const lastSearch = `${encodeURI(queryParams.address)}/${queryParams.lat}/${queryParams.lng}/${queryParams.startdate}/${queryParams.starttime}/${queryParams.enddate}/${queryParams.endtime}/${queryParams.uuid}`
/*    const lastSearch = {
      searchMode: 0
    }
    localStorage.setItem('lastSearch', JSON.stringify(lastSearch));
*/
    return axios.get(url, {params:queryParams});
/*
    // Using POST for CORS OPTIONS caching
    return axios({
      method: 'post',
      url: url,
      data: queryParams
    });*/
  
}

  function getGeoSearchBeanstalk(queryParams) {

    localStorage.setItem('lastSearch', JSON.stringify(queryParams));

    // Using POST for CORS OPTIONS caching
    return axios({
      method: 'post',
      //url: 'https://search.sharewithoscar.com.au/search', // SEARCH_URL,
      url: SEARCH_URL,
      data: queryParams
    });
  }

  /*function getGeoSearchBeanstalkS(queryParams) {
    //const url = `https://9hxhae63kb.execute-api.ap-southeast-2.amazonaws.com/geosearch`;    //ap-southeast-2
    //const url = 'https://8dmlcnxhz4.execute-api.us-east-1.amazonaws.com/geosearch';   // us-east-1

    //const url = 'http://oscarsearchproduction-env.eba-xbfvm2za.ap-southeast-2.elasticbeanstalk.com/';   //production
    //const url = 'http://oscarsearchstaging-env.eba-6qnhmusx.ap-southeast-2.elasticbeanstalk.com/';  //staging
    
    //const url = 'http://localhost:3999/';
    //const url = 'http://oscarsearchproductionusa-env.eba-k2cuyaxd.us-east-1.elasticbeanstalk.com/search'; //production-usa
    
    const url = 'http://oscarsearchstaging-env.eba-pzkgbbxv.us-east-1.elasticbeanstalk.com/search'; //staging-usa
    // Using POST for CORS OPTIONS caching
    return axios({
      method: 'post',
      url: url,
      data: queryParams
    });
  }*/

  /*function getGeoSearchBeanstalkP(queryParams) {
    //const url = `https://9hxhae63kb.execute-api.ap-southeast-2.amazonaws.com/geosearch`;    //ap-southeast-2
    //const url = 'https://8dmlcnxhz4.execute-api.us-east-1.amazonaws.com/geosearch';   // us-east-1

    //const url = 'http://oscarsearchproduction-env.eba-xbfvm2za.ap-southeast-2.elasticbeanstalk.com/';   //production
    //const url = 'http://oscarsearchstaging-env.eba-6qnhmusx.ap-southeast-2.elasticbeanstalk.com/';  //staging
    
    //const url = 'http://localhost:3999/';
    
    const url = 'http://oscarsearchproductionusa-env.eba-k2cuyaxd.us-east-1.elasticbeanstalk.com/search'; //production-usa
    // Using POST for CORS OPTIONS caching
    return axios({
      method: 'post',
      url: url,
      data: queryParams
    });
  }*/
  

function getParkingSearch(queryParams) {
  const url = `${API_URL}s/`;
  // save the most recent query in local storage to return to it at a later point.
  //const lastSearch = `${encodeURI(queryParams.address)}/${queryParams.lat}/${queryParams.lng}/${queryParams.startdate}/${queryParams.starttime}/${queryParams.enddate}/${queryParams.endtime}/${queryParams.uuid}`
  //localStorage.setItem('latestSearch', lastSearch)

  // Using POST for CORS OPTIONS caching
  return axios({
    method: 'post',
    url: url,
    data: queryParams
  });
}

function getParkingSpace(queryParams, entity) {
    const url = `${API_URL}parking_spaces/${entity}/`;
    return axios.get(url);
}

function getListings() {
    const url = `${API_URL}users/parking_spaces/`;
    return axios.get(url);
}
function getListing(entity) {
    const url = `${API_URL}users/parking_spaces/${entity}`;
    return axios.get(url);
}
function getListingsBays() {
  const url = `${API_URL}users/parking_bays/`;
  return axios.get(url);
}
function getListingBay(entity) {
  const url = `${API_URL}users/parking_bays/${entity}`;
  return axios.get(url);
}


function getTrips() {
    const url = `${API_URL}users/bookings/`
    return axios.get(url, {withCredentials: true});
}
function getBookings() {
    const url = `${API_URL}users/ownerbookings/`
    return axios.get(url);
}
function getOwnerBayBookings() {
  const url = `${API_URL}users/ownerbaybookings/`
  return axios.get(url);

}
function getQuery(queryId, parentId="", page=1, per_page=10, sort_by,sort_dir,search_text="",export_type="") {
  const API_URL_TEST = 'https://oscar-admin-api.herokuapp.com/api/v1/';

  if (export_type !== ""){
      

      const url = `${API_URL_TEST}q/?qid=${queryId}&pid=${parentId}&page=${page}&per_page=${per_page}&sort_by=${sort_by}&sort_dir=${sort_dir}&search_text=${search_text}&export=${export_type}`    
      return axios.get(url);
  
    } else {
      const url = `${API_URL_TEST}q/?qid=${queryId}&pid=${parentId}&page=${page}&per_page=${per_page}&sort_by=${sort_by}&sort_dir=${sort_dir}&search_text=${search_text}&export=${export_type}`    
      return axios.get(url);
  
    }
}

// MASTER USER only for now (enforced on server)
function getEditItem(queryParams) {
    const url = `${API_URL}edit/`;
    // Using POST for CORS OPTIONS caching
    return axios({
      method: 'post',
      url: url,
      data: queryParams
    });
  }
  
  

function getBookingDetails(entity) {
    const url = `${API_URL}users/bookings/${entity}/`
    return axios({
        method: 'get',
        url: url
    })
}
function getOwnerBookingDetails(entity) {
  const url = `${API_URL}users/ownerbookings/${entity}/`
  return axios({
      method: 'get',
      url: url
  })
}
function getOwnerBookingDetailsCP(entity) {
  const url = `${API_URL}users/ownerbookings-cp/${entity}/`
  return axios({
      method: 'get',
      url: url
  })
}

function sendBookingRequest(queryParams, entity) {
    const url = `${API_URL}parking_spaces/${entity}/booking_singles/`

    return axios({
        method: 'post',
        url: url,
        data: queryParams
    })
}
function cancelBooking(queryParams, entity) {
    const url = `${API_URL}users/bookings/${entity}/cancel/`
    return axios({
        method: 'patch',
        url: url,
        data: queryParams,
    })
}
function extendBooking(queryParams, entity) {
    const url = `${API_URL}users/bookings/${entity}/extend/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}
function setBookingStatus(queryParams, entity) {
  const url = `${API_URL}users/bookings/${entity}/status/`
  return axios({
      method: 'post',
      url: url,
      data: queryParams,
  })
}

function reviewBooking(queryParams, entity) {
    const url = `${API_URL}users/bookings/${entity}/review/`
    return axios({
        method: 'patch',
        url: url,
        data: queryParams,
    })
}
function getMessages(entity) {
    const url = `${API_URL}users/bookings/${entity}/messages/`
    return axios({
        method: 'get',
        url: url,
    })
}
function postMessage(queryParams, entity) {
    const url = `${API_URL}users/bookings/${entity}/messages/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams
    })
}

function getPromoCode(code) {
    const url =`${API_URL}promo_codes/${code}/`
    return axios({
        method: 'get',
        url: url
    })
}

function sendPaymentToken(queryParams) {
    const url = "FAT_TODO"

    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}

function createParkingBay(queryParams) {
  const url = `${API_URL}users/parking_bays/`
  return axios({
      method: 'post',
      url: url,
      data: queryParams,
  })
}
function updateParkingBay(queryParams, entity) {
  const url = `${API_URL}users/parking_bays/${entity}/`
  return axios({
      method: "PATCH",
      url: url,
      data: queryParams,
  })
}


function createParkingSpace(queryParams) {
    const url = `${API_URL}users/parking_spaces/`
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}
function updateParkingSpace(queryParams, entity) {
    const url = `${API_URL}users/parking_spaces/${entity}/`
    return axios({
        method: "PATCH",
        url: url,
        data: queryParams,
    })
}

// Availabilities
function getSpotAvailabilities(entity) {
    const url = `${API_URL}users/parking_spaces/${entity}/availability_recurrings/`
    return axios.get(url);
}
function createAvailability(queryParams, entity) {
    const url = `${API_URL}users/parking_spaces/${entity}/availability_recurrings/`;
    return axios({
        method: 'post',
        url: url,
        data: queryParams,
    })
}
function updateAvailability(queryParams, entity, secondaryEntity) {
    const url = `${API_URL}users/parking_spaces/${entity}/availability_recurrings/${secondaryEntity}/`
    return axios({
        method: 'patch',
        url: url,
        data: queryParams,
    })
}
function deleteAvailability(parkingSpace, availability) {
    const url = `${API_URL}users/parking_spaces/${parkingSpace}/availability_recurrings/${availability}/`
    return axios({
        method: 'delete',
        url: url,
    })
}

function longtermEnquiry(queryParams, entity) {
    const url = `${API_URL}parking_spaces/${entity}/longterm_enquiry/`

    return axios({
        method: 'post',
        url: url,
        data: queryParams
    })
}

function replyToConversation(queryParams, entity) {
  const url = `${API_URL}conversations/${entity}/reply/`

  return axios({
      method: 'post',
      url: url,
      data: queryParams
  })
}


function uploadProfilePicture(imageFile) {
    const url = `${API_URL}users/images/`
    const formData = new FormData();

    formData.append("image", imageFile)    
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return axios.post(url, formData, config);
}

function uploadImages(queryParams, deletedImages, entity) {
    const url = `${API_URL}users/parking_spaces/${entity}/images/`;
    const formData = new FormData();
    
    formData.append("deleted_images", JSON.stringify(deletedImages))

    const imageTypes = ['general', 'street', 'access']
    for(let i = 0; i < imageTypes.length; i++) {
        for(let j = 0; j < queryParams[imageTypes[i]].length; j++) {
            if (queryParams[imageTypes[i]][j].file) {
                //console.log(`${imageTypes[i]}_${j}`)
                formData.append(`${imageTypes[i]}_${j}`, queryParams[imageTypes[i]][j].file)    
            }
        }        
    }
    
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return axios.post(url, formData, config);
}

function uploadImagesV3(imagePreviews, space_or_bay, deletedImages, entityId) {
  const url = `${API_URL}users/${space_or_bay}/${entityId}/images/`;
  const formData = new FormData();

  for(var key in imagePreviews){
    
    let imageType = key.replace('id.photos.','');     // 'id.photos.x' -> 'general', 'street', 'access'
    
    let thisGroup = imagePreviews[key];

    for (let i=0;i<thisGroup.length;i++){
      let thisPhoto = thisGroup[i];
      if (thisPhoto && thisPhoto.file){
        formData.append(`${imageType}_${i}`, thisPhoto.file)    
      }
    }
  } 
  
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }

  return axios.post(url, formData, config);
}


//TODO: move this to AWS S3 Signed requests!
function uploadParkingBayImages(queryParams, deletedImages, entity) {
  const url = `${API_URL}users/parking_bays/${entity}/images/`;
  const formData = new FormData();
  
  formData.append("deleted_images", JSON.stringify(deletedImages))

  const imageTypes = ['1', '2', '3']
  for(let i = 0; i < imageTypes.length; i++) {
      for(let j = 0; j < queryParams[imageTypes[i]].length; j++) {
          if (queryParams[imageTypes[i]][j].file) {
              //console.log(`${imageTypes[i]}_${j}`)
              formData.append(`${imageTypes[i]}_${j}`, queryParams[imageTypes[i]][j].file)    
          }
      }        
  }
  
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }

  return axios.post(url, formData, config);
}


// Admin only function
function getEvent(queryParams) {
  const url = `${API_URL}event/`;
  
  // Using POST for CORS OPTIONS caching
  return axios({
    method: 'post',
    url: url,
    data: queryParams
  });
}




// Returns the branding json blob for hotel booking first page (not hit to Heroku)
function getHotelBranding(hotelName){
  /*const url = `${API2_URL}cm`;
  return axios({
      method: 'post',
      url: url,
      //data: queryParams,
  });*/

  const queryParams = {
    hotelName,
    //email:"damian@sharewithoscar.com",
    environment
  };
  const url = `${API2_URL}cm`;
  return axios({
      method: 'post',
      url: url,
      data: queryParams,
  });


}



// Returns the branding json blob for hotel booking first page (not hit to Heroku)
function getAutoComplete(q, remoteDomain){
  /*const url = `${API2_URL}cm`;
  return axios({
      method: 'post',
      url: url,
      //data: queryParams,
  });*/

  const queryParams = {
    q,
    rd: remoteDomain,
    //email:"damian@sharewithoscar.com",
    environment
  };
 

    // Using POST for CORS OPTIONS caching
  return axios({
    method: 'post',
    url: AUTOCOMPLETE_URL,
    data: queryParams
  });


}


function getRedirectToken() {
  const url =`${API_URL}users/get_redirect/`
  return axios({
      method: 'post',
      url: url,
      data:null
  })
}


function sendOscarAnalytics(email, code, details={}) {
  //console.log(`sendOscarAnalytics(${email} > ${code})`);

  const url = `https://www.sharewithoscar.com.au/api/ev`;

  const data = {
    code,
    email,
    details
  }
  return axios({
      method: 'post',
      url: url,
      data: data
  })
}



export { 
    getReferrer,
    getUserData,
    getUserPayoutMethod,
    updateUserData,
    createUserPayoutMethod,
    updateUserPayoutMethod,
    resetPassword,
    updatePassword,
    savePaymentCard,
    savePaymentCard_Braintree,
    savePaymentDD,
    getUserPaymentPreferences,
    updateUserPaymentPreferences,
    getNotifications,
    setNotificationsRead,
    getConversations,
    getLongTermEnquiries,

    getVehicles,
    createVehicle,
    updateVehicle,

    getPromoCode,

    getUserTransactions,

    updatePhoneNumber,
    verifyPhoneNumber,
    updateTenantData,
    getBuildingsOptionsList,

    getLocationSpaces, 
    getLocationSpacesClosest,
    getParkingSpace,
    getListing, 
    getListings,
    getListingsBays,
    getListingBay,
    getOwnerBayBookings,
    getBookings,
    getQuery,
    getEditItem,
    getTrips,

    sendBookingRequest,
    cancelBooking,
    extendBooking,
    setBookingStatus,
    reviewBooking,
    getMessages,
    postMessage,
    getBookingDetails,
    getOwnerBookingDetails,
    getOwnerBookingDetailsCP,
    
    sendPaymentToken,
    createParkingSpace,
    updateParkingSpace,
    createParkingBay,
    updateParkingBay,

    longtermEnquiry,
    replyToConversation,

    getSpotAvailabilities,
    updateAvailability,
    createAvailability,
    deleteAvailability,
    
    uploadProfilePicture,
    uploadImages,
    uploadImagesV3,
    uploadParkingBayImages,

    getGeoSearchBeanstalk,
    getParkingSearch,
    getCMS,

    getEvent,
    getHotelBranding,

    getAutoComplete,
    getRedirectToken,

    sendOscarAnalytics

}
