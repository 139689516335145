import React, {Component} from 'react';

import {
    Button,
    IconButton,
    Typography
} from '@material-ui/core'

import IconClose from '@material-ui/icons/Close';
import IconPhone from '@material-ui/icons/Phone';
import IconMail from '@material-ui/icons/Mail';
import { isNativeWebview } from '../utils/IsNativeWebview';

const styles = {

    alertPage: {
        paddingTop: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    topColored: {
        paddingTop: '80px',
        backgroundColor: '#1AA5A2',
        paddingBottom: '8px',
    },
    mainGraphic: {
        marginTop: '40px',
        marginBottom: '32px',
    },
    helpButton: {
        marginTop: '12px',
        textTransform: 'none'
    },
    buttonIcon: {
        marginRight: '8px'
    }
}

export default class Help extends Component {

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    render() {
        return(
            <div style={{height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div className="mainContent" style={styles.topColored}>
                <IconButton className="leftCornerButton" onClick={this.props.history.goBack}>
                    <IconClose style={{color: 'white'}}/>
                </IconButton>

                <Typography style={{color: 'white'}} variant="display1">
                    Help
                </Typography>            
            </div>
            <div style={styles.alertPage} className="mainContent">
                <Typography variant="body1">
                    The Oscar team is here to help! Get in touch with us through phone or email.
                </Typography>                

                <div style={styles.alertImageContainer}>
                    <img src="https://www.sharewithoscar.com.au/assets/graphics/help.svg" alt="cute representation of asking for help"/>
                </div>

                <div style={{marginBottom: '16px'}}>
                    {isNativeWebview() 
                    ? <Button style={styles.helpButton} fullWidth variant="contained" color="primary"
                    onClick={() => this.externalLink('/', 'tel:+61282945818')}>
                        <IconPhone style={styles.buttonIcon}/> (02) 8294 5818
                    </Button>
                    : <a href="https://www.sharewithoscar.com.au/help/" style={{color: 'white'}}>
                        <Button style={styles.helpButton} fullWidth variant="contained" color="primary">
                            FAQ
                        </Button>
                    </a>}

                    {isNativeWebview() 
                    ? <Button style={styles.helpButton} fullWidth variant="contained" color="primary"
                    onClick={() => this.externalLink('/' ,'mailto: contact@sharewithoscar.com')}>
                        <IconMail style={styles.buttonIcon}/> contact@sharewithoscar.com
                    </Button>

                    : <a href="mailto:contact@sharewithoscar.com" target="_top" style={{color: 'white'}}>
                        <Button style={styles.helpButton} fullWidth variant="contained" color="primary">
                            <IconMail style={styles.buttonIcon}/> contact@sharewithoscar.com
                        </Button>
                    </a>}
                </div>

            </div>
            </div>
        )
    }
}