import React, {Component} from 'react';

import DefaultAjaxErrorHander from '../components/DefaultAjaxErrorHandler.js';
import Loader from '../components/Loader.js';

function AjaxEnable(WrappedComponent, dataQuery, queryArgs, entity) {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                status: 0,
                statusText: "loading",
                data: [],
                error: null,
            }
        }
        
        componentDidMount() {
            dataQuery(queryArgs, entity).then((response) => {
                //console.log(response)
                this.setState({
                    data: response.data, 
                    statusText: response.statusText,
                    status: response.status,
                })
            }).catch((error) => {
                console.log(error)
                this.setState({
                    statusText: error.response.statusText,
                    error: error.response.status
                })
            });
        }

        render() {
            const ShowResult = (props) => {
                if (props.statusText ===  "loading") {
                    return <Loader />
                } else if (props.statusText === "OK") {
                    return <WrappedComponent data={this.state.data} {...this.props}/>
                }                
                return <DefaultAjaxErrorHander errorCode={props.error} />
            }

            const data = this.state.data
            return(
                <ShowResult statusText={this.state.statusText} data={data} error={this.state.error} />
            );
        }
    }
}

export {AjaxEnable}