import React, {Component} from 'react';
import ReactDOM from 'react-dom'

import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Paper,
    Typography
} from "@material-ui/core";

import {getApplicationUser} from '../utils/Authentication.js';
import { compose, withProps } from "recompose";
import {  withGoogleMap, GoogleMap, Marker } from "react-google-maps";

import IconClose from "@material-ui/icons/Close"
import IconThumbsDown from '@material-ui/icons/ThumbDown'
import IconThumbsUp from '@material-ui/icons/ThumbUp'
import IconBolt from '@material-ui/icons/FlashOn'
import IconTick from '@material-ui/icons/Check.js'

import SmartImage from '../components/SmartImage';

import Carousel from 'react-responsive-carousel/lib/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';

import {GetStreetView} from '../utils/StreetView.js';

//import getSpaceType from '../utils/GetSpaceType.js';
import {getImageListForSpace} from '../utils/GetImageListForSpace.js';
import FullPageModal from '../utils/FullPageModal.js';
import LongTermEnquiry from '../views/LongTermEnquiry.js';
import BookingBlocks from '../components/BookingBlocks.js';

const styles = {
    /*rightTopButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },*/
    /*leftTopButton: {
        position: 'absolute',
        left: 0,
        top: 0,
    },*/
    /*longTermButton: {
        position: 'absolute',
        backgroundColor: 'white',
        color: '#1AA5A2',
        left: '15px',
        top: '8px',
        borderRadius: '20px',
        padding: '5px 15px',
        fontSize: '15px',
        fontWeight: 'bold',    
    },*/
    contentContainer: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',

        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        padding: '0px',
        flex: '1 0 auto',
        height:'100%', 
        display: 'flex',
        flexDirection: 'column',
    },
    descriptionContentContainer: {
        padding: '16px',
        marginTop: '24px',
        flexGrow: 1,
        flexBasis: 1,
        overflow: 'auto',
    },
    rate: {
        fontWeight: 'bold',
    },
    bookingButtonContainer: {
        width: '100%',
    },
    bookingButton: {
        padding: '22px',
    },
    distanceText: {
        textTransform: 'uppercase',
        color: "#acacac",
    },
    imageCarouselWP: {
      width: '100%',
      height: '400px',
      backgroundColor:'#eeeeee'
    },
    imageCarousel: {
        width: '100%',
        //height: '250px',
        backgroundColor:'#ff0000'// # damian
    },
    carouselImage: {
        width: 'auto',
        height: 'auto'
    },
    ownerProfile: {
        position: 'absolute',
        right: '16px',
        bottom: '-64px',
    },
    guestProfileImage: {
        backgroundColor: 'white',
        borderRadius: '50px',
        width: '75px',
        height: '75px',
    },
    subheading: {
        marginTop: '16px',
        textTransform: 'uppercase',
    },
    reviewIcon: {
        fontSize: '13px',
        marginRight: '4px'
    },
    verifiedIcon: {
      fontSize: '21px',
      marginRight: '1px',
      marginBottom:'-5px',
      backgroundColor:'#1aa5a2',
      color:'#ffffff',
      borderRadius:'10px',
      padding:'3px'
  }

}

const imageWidthDesktop = 774; // 768;// 374;
const imageHeightDesktop = 350; //250;
const imageHeightMobile = 250;

const mapStyles = require("../styles/oscarMapStyles.json");

const MiniMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`,border:'1px solid #d3d3d3' }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) =>
              <GoogleMap
                //defaultZoom={10}
                //defaultCenter={{ lat: -27.4697707, lng: 153.0251235 }}
                defaultOptions={{
                  styles: mapStyles,
                  scaleControl: false,
                  mapTypeControl: false,
                  panControl: false,
                  zoomControl: true,
                  rotateControl: false,
                  fullscreenControl: false,
                  streetViewControl: false,
                  gestureHandling: 'cooperative'
                }}
                center={props.newCenter ? props.newCenter : props.center}
                zoom={props.zoom}
                ref={props.collectMapRef}
                onIdle = {props.onIdle}
                onBoundsChanged={props.handleMapMoved}
                onDragEnd={props.handleMapMoved}
                onZoomChanged={props.handleZoomChanged}
                disableDefaultUI
                start={props.start}
              >             
              <Marker
                //zIndex={zIndex}
                key={props.spaceData.uuid}
                position={{ lat: props.spaceData.latitude, lng: props.spaceData.longitude }} 
                //onClick={() => props.onMarkerClick(space.uuid)}
                //onMouseOver={() => props.onMarkerHover(space.uuid)}
                //onMouseOut={() => props.onMarkerHover(null)}
                //icon={getPriceIconWP(space, props.selected, props.hoverUuid === space.uuid, props.duration, props.start,space.strTotalBays)}
              />
              </GoogleMap>
);


export default class SpaceDetails extends Component {
  state = {enquireOpen: false, showBB: false}
    render() {
      //console.log("SpaceDetails:RENDER",this.props);
      const user = getApplicationUser()
      const {spaceData, spaceType, spaceData2, browserCSSMode, searchMode} = this.props;

      let spaceUser = spaceData.user || spaceData2.company.master_user;   //???????????????????

      // parking_space, parking_bay, building
      if (!spaceData){
        return (<div>NO SPACE DATA</div>);
      }


      let building;
      let images;

//console.log("innerWidth:", window.innerWidth);

      if (window.innerWidth >= 600){  // 960
        //images = getImageListForSpace(spaceData, building,"General",false, imageWidthDesktop, imageHeightDesktop);
        images = getImageListForSpace(spaceData, building,"General",false, null, null); // No height or width, will use SmartImage
      } else {
        images = getImageListForSpace(spaceData, building,"General",false, null, null); // No height or width, will use SmartImage //imageHeightMobile);
      }
      
      //images = getImageListForSpace(spaceData, building,"General",false);      

      if (!images || images.length == 0){
        //const imagePath = GetStreetView(spaceData.latitude, spaceData.longitude);
                         
        let imagePath = 'https://images.sharewithoscar.com.au/assets/graphics/no-photo.png'; // cp-logos/no-logo-2.png';
        if (window.innerWidth >= 960){
          imagePath = GetStreetView(spaceData.latitude, spaceData.longitude, imageWidthDesktop, imageHeightDesktop); // Desktop only
        } else {
          imagePath = GetStreetView(spaceData.latitude, spaceData.longitude, 450, imageHeightMobile);
        }
        //images = (<div className="img-holder" key={spaceData.uuid} style={{backgroundColor:'#d8d8d8',padding:'100px'}}><img src={imagePath}/></div>);
        images = (<div className="img-holder" key={spaceData.uuid} style={{backgroundColor:'#d8d8d8',padding:'0'}}><img src={imagePath}/></div>);
        
        
      }

      let strSpaceType = spaceData.type.replace('Parking-lot','Parking Lot');

      let divThumbs = <div className="thumbs"><IconThumbsUp style={styles.reviewIcon} />({spaceData.reviews_positive}) <IconThumbsDown style={{...styles.reviewIcon, marginLeft: '12px'}} />({spaceData.reviews_negative})</div>;
      //let divThumbs;
      let divMapPrice;
      let divMapPriceMobile;
      
      const pricing = spaceData.pricing.output.display_details;
      divMapPrice = <div className="rate">${pricing.price}<span>{pricing.per}</span>{divThumbs}</div>;
      divMapPriceMobile = <div className="rate">${pricing.price}<span>{pricing.per}</span>{divThumbs}</div>;


      let arrOperatingHours;

      let profileImageUrl;
      if (spaceUser && spaceUser.image_user && spaceUser.image_user.path){
        profileImageUrl = spaceUser.image_user.path;
        profileImageUrl = profileImageUrl.replace('https://www.sharewithoscar.com.au/images/','https://images.sharewithoscar.com.au/');
        profileImageUrl += `?tr=w-75,h-75,fo-auto`;
      }



      return (
        <>
          {this.state.showBB &&
            ReactDOM.createPortal(<BookingBlocks
            spaceData={spaceData}
              key={spaceData.uuid}
            user={user}
            start={this.props.start}
            end={this.props.end}
            //testBlocks={this.props.testBlocks}
            uuid={spaceData.uuid} onClose={() => this.setState({showBB: false})}
            />,document.body)
          }
          
          {browserCSSMode === 'mobile' && 
              <div className="sd-section is-mobiletitle">
                <h1><span>{strSpaceType}</span> on {spaceData.street_name}</h1>
                <div className="thumb-rate">{divMapPriceMobile}</div>
                
                <IconButton className="wp-btn-close2"
                onClick={this.props.closeModal}><IconClose/></IconButton>
              </div>
            }
            <FullPageModal open={this.state.enquireOpen}>
              <LongTermEnquiry 
                onClose={() => this.setState({ enquireOpen: false })}
                history={this.props.history}
                searchMode={this.props.searchMode}
                spaceUuid={spaceData.uuid}
                spaceType={spaceType}
                spaceData={spaceData}
              />
            </FullPageModal>


          <div className="sdo-container" key={spaceData.uuid}>


            <div className="sd-section is-close">
              {browserCSSMode === 'desktop' && <IconButton className="wp-btn-close2" onClick={this.props.closeModal}><IconClose/></IconButton>}
              {/*searchMode === 0 && !spaceData.rate_monthly &&
                <Button className="btn-longterm"
                  onClick={() => {
                    if (user.authenticated) {
                        this.setState({enquireOpen: true})
                    } else {
                        this.props.history.push('/Login');
                    }                        
                }}>Enquire Long Term</Button>
              */} 
            </div>


            <Carousel 
              showArrows={true} 
              showStatus={false} 
              showIndicators={true} 
              showThumbs={false} 
              infiniteLoop={true} 
            >
              {images}
            </Carousel>

            {searchMode === 1 && <div className="monthly-guarantee"><img src="https://images.sharewithoscar.com.au/assets/moneyguarantee.png?tr=w-50,h-46,fo-auto"/>Covered by our <a href="https://www.sharewithoscar.com.au/guarantee/" target="_blank">money back guarantee</a></div>}

            <div className="sd-section is-details">                    
              {browserCSSMode === 'desktop' && <h1><span>{strSpaceType}</span> on {spaceData.street_name}</h1>}
              <h2>Description</h2>
              <div className="description" dangerouslySetInnerHTML={{__html: spaceData.description}}/>
              <h2>Maximum vehicle size</h2>
              <p>{spaceData.vehicle_type}</p>
              {spaceData.access_method && <><h2>Access method</h2><p>{spaceData.access_method}</p></>}
            </div>

            <div className="sd-section is-map">
              <h2>Location</h2>
              <div className="is-map_container">
                <SmartImage key={spaceData.uuid} externalUrl={`https://maps.googleapis.com/maps/api/staticmap?center=${spaceData.latitude},${spaceData.longitude}&zoom=17&size={width}x{height}&maptype=roadmap&markers=color:red%7Clabel:HERE%7C${spaceData.latitude},${spaceData.longitude}&key=AIzaSyCJg_hen8s4YwwFHO_uqRZ5j3q8sZ689IA`}/>
              </div>
              {/*<SmartImage key={spaceData.uuid} externalUrl={`https://maps.googleapis.com/maps/api/staticmap?center=${spaceData.latitude},${spaceData.longitude}&zoom=15&size={width}x{height}&maptype=roadmap&markers=color:blue%7Clabel:S%7C40.702147,-74.015794&markers=color:green%7Clabel:G%7C40.711614,-74.012318&markers=color:red%7Clabel:C%7C40.718217,-73.998284&key=AIzaSyCJg_hen8s4YwwFHO_uqRZ5j3q8sZ689IA`}/> */}
              {/*<div className="is-map_container">
                <MiniMapComponent
                  //onMarkerClick={this.props.desktopDisplay ? this.handleMarkerClickDesktop:this.handleMarkerClick}
                  //onIdle = {() => {console.log("MyMapComponent - GMAP ready [desktop]")}}   // disable in Production
                  //onMarkerHover={this.handleMarkerHover}
                  center={{lat: spaceData.latitude, lng: spaceData.longitude}}
                  //newCenter={this.state.selectedSpace ? {lat: this.state.selectedSpace.latitude, lng: this.state.selectedSpace.longitude} : undefined}
                  zoom={15}
                  spaceData={spaceData}
                  //data={this.state.mapPinData}
                  //activeUuid={this.state.activeUuid}
                  //selected={this.state.activeUuid}
                  //hoverUuid={this.state.hoverUuid}
                  //collectMapRef={this.getMapRef}
                  //handleZoomChanged={this.handleZoomChanged}
                  //handleMapMoved={this.handleMapMoved}
                />
            </div>*/}
            </div>

            {/*<div className="sd-section is-features">
              <h2>Features</h2>
              xxxxxxxxx
            </div>*/}
            
            {arrOperatingHours && <div className="sd-section is-hours">
              <h2>Access Hours</h2>
              -
            </div>}
            
            <div className="sd-section is-owner">
              <div className="v3-section-owner-img">
              {profileImageUrl
              ? <img key={spaceUser.image_user.path} style={styles.guestProfileImage} src={profileImageUrl} alt="user profile" />
              : <img style={styles.guestProfileImage} src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="user profile" />}
              </div>
              <div className="v3-section-owner">
                {spaceUser && spaceData.reviews_positive == 0 && <div>{spaceUser.first_name}<span>Member since {spaceUser.since}</span></div>}
                {spaceUser && spaceData.reviews_positive >=1 && <div>{spaceUser.first_name}<span><IconTick style={styles.verifiedIcon} /> Verified Member since {spaceUser.since}</span></div>}
                <button className="v3-btn-contactowner" onClick={() => { if (user.authenticated) { this.setState({enquireOpen: true}) } else { this.props.history.push('/Login'); }}}>Contact Owner</button>    
              </div>
            </div>
            

            {(user.profile && user.profile.allow_admin_availability) &&
              <>
                <div className="sd-section is-admin">
                  <Button color="primary" fullWidth onClick={() => this.setState({showBB: true})}>ADMIN Only - Check Availability</Button>
                    
                <pre><b>ADMIN PRICING:</b><br/>{JSON.stringify(spaceData.pricing,null,2)}</pre></div>
              </>
            }


          </div>
          <div className="sd-actions">

            
                {browserCSSMode === 'desktop' ? divMapPrice:<></>}
                {/*browserCSSMode === 'mobile' && !spaceData.rate_monthly && <div className="rate"></div>*/}
                {(searchMode === 1 && !spaceData.auto_approve) && <div className="approve">The owner will need to approve your request</div>}
                {/*(searchMode === 1 && spaceData.auto_approve) && <div className="approve">Your booking will be<br/>confirmed instantly</div>*/}


                {/*TODO: remove this */}
                {/*(searchMode === 0 && spaceData.rate_monthly) ?
                  <Button variant="contained" fullWidth color="primary" onClick={() => {
                    if (user.authenticated) {
                        this.setState({enquireOpen: true});
                    } else {
                        this.props.history.push('/Login');
                    }                        
                  }}>ENQUIRE LONG TERM</Button>:<></>
                */}

                {(!spaceData.available || spaceData.occupied) ?
                  <Button variant="contained" fullWidth color="primary" disabled>Unavailable</Button>
                  :
                  <>
                  {(searchMode === 1 && spaceData.rate_monthly && spaceData.auto_approve) ?
                    <Button variant="contained" fullWidth color="primary" onClick={this.props.bookClicked}>
                        <img src="https://www.sharewithoscar.com.au/assets/graphics/thunder-white-oscar.svg" style={{width:'10px',marginRight:'5px'}} />Get Started
                    </Button>:<></>
                  }
                  {(searchMode === 1 && spaceData.rate_monthly && !spaceData.auto_approve) ?
                      <Button variant="contained" fullWidth color="primary" onClick={this.props.bookClicked}>
                          Request to Book
                      </Button>:<></>
                  }
                  {searchMode === 0 && spaceData.available && !spaceData.occupied ?
                      <Button variant="contained" fullWidth color="primary" onClick={this.props.bookClicked}>
                          Book for ${spaceData.bookingPrice}
                      </Button>:<></>
                  }
                  </>
                }

            </div>

        </>
      );

    }
}
