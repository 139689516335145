import React, { Component } from "react";
import { Link } from "react-router-dom";

// Utils
import {
  requestLocalToken,
  processTokenResponse,
  processTokenRequest,
  updateUserProfileAjax,
  exchangeOAuthToken,
  logOut,
  getUserProfile
} from "../utils/Authentication";
import { checkUserNotificationPermissions } from "../utils/PushNotification.js";
//import ENUMS from '../api/ENUMS.json';
//import { FieldType } from '../api/fieldTypes.js';


// Components
import CRUDForm from "../components/CRUDForm";
import LoadingModalWP from "../components/LoadingModalWP.js";
import HiddenField from "../components/forms/HiddenField";

// Styles & UI Components
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
  Fab
} from "@material-ui/core";
import { whiteInputStyles } from "../styles/OscarTheme.js";
import IconBack from "@material-ui/icons/KeyboardArrowLeft";
import IconClear from "@material-ui/icons/Cancel";
import IconForward from "@material-ui/icons/KeyboardArrowRight";

//import AutoComplete from '../components/AutoComplete';


import {
  createLocalAccount,
//  requestLocalToken,
//  processTokenResponse,
//  logOut
} from '../utils/Authentication';

import { resetPassword, updatePassword} from '../api/Oscar-api';


const styles = {
  noAccount: {
    borderRadius: "32px",
    color: "white",
    border: "2px solid #999999"
  },
  rightCornerButton: {
    position: "absolute",
    top: 10,
    right: 5,
    color: "#999999"
  },
  progressButton: {
    backgroundColor: "#999999",
    boxShadow: "none"
  },
  oauthButton: {
    backgroundColor: "#999999",
    paddingTop: "14px",
    paddingBottom: "14px",
    borderRadius: "32px",
    color: "#1AA5A2"
  },
  signupButton: {
    marginTop: "16px",
    marginLeft: "16px",
    marginRight: "16px",
    fontWeight: "bold"
  }
};

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = value;
  });
  return vars;
}

// Duplicate in FinishProfileWP - to allow correct scrolling while remaining vertically centered
//const logoMobile = (<div className="lwpauth-logo-mobile"><img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-blue3.svg" alt="Meriton powered by Oscar"/></div>);

let logoMobile;
if (window.WPDomain === 'community'){
  logoMobile = (<div className="lwpauth-logo-mobile"><img src="https://community.sharewithoscar.com.au/assets/graphics/logo-oscar-community.svg" alt="Community Portal"/></div>);

} else if (window.WPDomain === 'demo'){
  logoMobile = (<div className="lwpauth-logo-mobile"><img src="https://meriton.sharewithoscar.com.au/assets/demo-logo2.svg" alt="Demo Portal"/></div>);
} else {
  logoMobile = (<div className="lwpauth-logo-mobile"><img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-blue3.svg" alt="Meriton powered by Oscar"/></div>);
}



export default class AuthWP extends Component {
  constructor(props) {
    super(props);
    //console.log("LoginWP:constructor:page=",this.props.page);
    
    let mobileintro = true;
    
    if (sessionStorage.getItem('mobileintro') === "done"){
      mobileintro = false;
    }
    

    this.state = {
      mobileintro,
      loading: false,
      logs: [],
      vals: []
    };
    

    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
  }

  componentDidMount() {
    //console.log("AuthWP:didMount");

    /*if (getUrlVars().code) {
      let token = getUrlVars().code;
      processTokenRequest(
        exchangeOAuthToken(token, "facebook"),
        this.authSuccess,
        this.authError
      );
    }*/
  }

  changeLoadingStatus = status => {
    let logs = this.state.logs;
    logs.push("S: " + status);
    this.setState({
      logs: logs
    });
  };

  onSuccess_forgotpassword = (response) => {
    //this.props.history.push("/ResetConfirmation")
    this.setState({resetSuccessEmail:this.state.vals.username});
  }

  onSuccess_resetpassword = (response) => {
    this.props.history.push("/login");
  }

  onSuccess_signup = (response) => {
    requestLocalToken({
        username:response.queryParams.email,
        password:response.queryParams.password
    })
    .then(response => {
        processTokenResponse(response).then(() => {
            //console.log("Yay!")
            this.props.authChange();
            this.props.history.push('/finishprofile')
        })
    })
}



  onSuccess_login = response => {
    //this.setState({ loading: true });

    ///return;
    
    processTokenResponse(response).then(() => {

      updateUserProfileAjax().then(() => {

        //this.props.authChange();
        
        let userData = getUserProfile();
        //DAMIAN: if no user data???
        if (userData.is_corpuser){
          if (window.WPDomain){
            window.location = "/cp/wp_bookings";
          } else {
            window.location = "/cp/bookings";
          }
          

        } else {
          //let redirect_path = localStorage.getItem("redirect");
          //console.log({redirect_path});

          this.props.authChange();

          //console.log('zzzzzzzzzz');
          /*if (redirect_path){
            //localStorage.removeItem("redirect");
          } else {
            redirect_path = "/search";
          }*/

          if (checkUserNotificationPermissions()) {

            // Only ask TWICE - then stop asking the client
            let pcounter = parseInt(localStorage.getItem("USER_NOTIFICATION_PERMISSIONS_REQUESTED_COUNTER") || "0");
            if (pcounter < 2){
              pcounter++;
              localStorage.setItem("USER_NOTIFICATION_PERMISSIONS_REQUESTED_COUNTER",pcounter);
  
              //xxthis.props.history.push("/search");  // Straight to search for Meriton demo
              //localStorage.removeItem("redirect");
              //this.props.history.push(redirect_path);
  
              // this.props.history.push("/PermissionsPush");    //DAMIAN - ask Louise - only first time etc?
            } else {
              //xxthis.props.history.push("/search");  
              //localStorage.removeItem("redirect");
              //this.props.history.push(redirect_path);
  
            }
            
          } else {
            //xxthis.props.history.push("/search");
            //localStorage.removeItem("redirect");
            //this.props.history.push(redirect_path);

          }

        }
        

        //this.setState({ loading: false });  //Removed
      });
    });
  };

  authSuccess = () => {
    // We successfully authenticated, so return to the landing page.
    //console.log("xxx:authSuccess");
    this.props.authChange();
    this.setState({ loading: false });
    this.props.history.push("/search");
  };
  authError = error => {
    // TODO: validation messages for errors
    console.error(error);
    let logs = this.state.logs;
    logs.push("E: " + error);
    this.setState({ loading: false, logs: logs });
  };

  updateFields = () => {
    const vals = this._mainForm.getState().values;

    //console.log("updateFields:",vals);

    const form1 = vals["username"] ? vals["username"].length > 0 : false;
    const form2 = vals["password"] ? vals["password"].length > 0 : false;
    this.setState({ form1: form1, form2: form2, vals });
  };

  render() {
    const { page } = this.props;
    //const { classes } = this.props;


    if (page === "forgotpassword"){
      return this.render_forgotpassword();

    } else if (page === "login"){
      if (this.props.user.authenticated){
        //window.location = '/search';
        this.props.history.push("/search");  // Straight to search for Meriton demo

      }
      return this.render_login();

    } else if (page === "resetpassword"){
      return this.render_resetpassword();

    } else if (page === "signup"){
      return this.render_signup();

    } else {
      return (<h1>INVALID PAGE: {page}</h1>);
    }

//height: "100%", overflowY: "auto", 
  }


  //=============================================================================
  render_mobileintro(){

    let authLogo;
    let authContent;

    if (window.WPDomain === 'community'){
      authLogo = <img src="https://community.sharewithoscar.com.au/assets/graphics/logo-oscar-community.png" alt="Community Portal"/>;
    
      authContent = <>
        <h1>Resident Parking</h1>
        <h2>Need a parking space?</h2>
        <p>Find available spaces in your building</p>
        <h2>Have a spare parking space?</h2>
        <p>List it on the parking portal for rent</p>
        <p>Learn more <a href="https://www.sharewithoscar.com.au/community-faq/" target="_blank">FAQ</a></p>
      </>;

    } else if (window.WPDomain === 'demo'){
      authLogo = <img src="https://meriton.sharewithoscar.com.au/assets/demo-logo2.svg" alt="Demo Portal"/>;
    
      authContent = <>
      <h1>Demo Portal</h1>
      <h2>Need a parking space?</h2>
      <p>Find available spaces for rent</p>
      </>;

    } else {
      authLogo = <img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg" alt="Meriton powered by Oscar"/>;
    
      authContent = <>
      <h1>Parking Portal</h1>
      <h2>Need a parking space?</h2>
      <p>Find available spaces for rent</p>
      <h2>Have a spare parking space?</h2>
      <p>List it on the parking portal for rent</p>
      <p>Learn more <a href="https://www.sharewithoscar.com.au/meriton-faq/" target="_blank">FAQ</a></p>
      </>;

    }




    return (
      <div className="lwpauth-mobileintro">
        <div className="lwpauth-imgoverlay"></div>
        
        <div className="lpauth-imgcontent">
          <div className="lwpauth-logo">
            {authLogo}
          </div>
          <div style={{flexGrow:'1',marginLeft:'auto',marginRight:'auto'}}>
            {authContent}

          </div>
          <Button style={styles.helpButton} fullWidth className="wp-button" onClick={() => {sessionStorage.setItem("mobileintro", "done");  this.setState({mobileintro:false})}}>Get Started</Button>                

        </div>

      </div>
      );
  }


  //=============================================================================
  render_login(){

    return (
      <>
      <LoadingModalWP open={this.state.loading}/>
      {this.state.mobileintro && this.render_mobileintro()}
        <div className={"lwpauth-form" + (this.state.mobileintro ? " mobileintro-visible":"")}>
          {logoMobile}

          <h1>Log In</h1>

          <CRUDForm
            submitOnEnter={true}
            endpoint={requestLocalToken}
            onSuccess={this.onSuccess_login}
            //loadColor="#ff0000"
            ref={component => {
              this._mainForm = component;
            }}
            onChange={this.updateFields}
            onKeyDown={this.handleKeyDown}
          >
            <Grid container spacing={24} justify="center">
              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  name="username"
                  type="email"
                  FormHelperTextProps={{
                    classes: {
                      //root: classes.helperText,
                      //error: classes.helperTextError
                    }
                  }}
                  InputProps={{
                    classes: {
                      //root: classes.input,
                      //error: classes.inputError
                    },
                    endAdornment: this.state.form1 && (
                      <InputAdornment position="end">
                        <IconClear
                          onClick={() =>
                            this._mainForm.handleInputChange({
                              target: { name: "username", value: "" }
                            })
                          }
                        />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    //classes: { root: classes.label },
                    FormLabelClasses: {
                      //root: classes.formLabelRoot,
                      //error: classes.formLabelError,
                      //focused: classes.formLabelFocused
                    },
                    shrink: true
                  }}
                  placeholder="Your email address"
                  fullWidth
                  margin="normal"
                  //value={this.state.username}
                  onChange={this.handleInputChange}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  FormHelperTextProps={{
                    classes: {
                      //root: classes.helperText,
                      //error: classes.helperTextError
                    }
                  }}
                  InputProps={{
                    classes: {
                      //root: classes.input,
                      //error: classes.inputError
                    },
                    endAdornment: this.state.form2 && (
                      <InputAdornment position="end">
                        <IconClear
                          onClick={() =>
                            this._mainForm.handleInputChange({
                              target: { name: "password", value: "" }
                            })
                          }
                        />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    //classes: { root: classes.label },
                    FormLabelClasses: {
                      //root: classes.formLabelRoot,
                      //error: classes.formLabelError,
                      //focused: classes.formLabelFocused
                    },
                    shrink: true
                  }}
                  placeholder="Your password here"
                  fullWidth
                  margin="normal"
                  //value={this.state.password}
                  onChange={this.handleInputChange}
                  required={true}
                />
              </Grid>

              

              <Grid item xs={12}>
                <Button id="wpb-forgotpassword" component={Link} to="/forgotpassword">Forgot Password?</Button>
              </Grid>
        


              <Grid item xs={12}>
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item xs={12}>
                    <Button type="submit">Log In</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CRUDForm>



          <Grid item xs={12} style={{ marginTop: "32px" }}>
            <Button id="wpb-createaccount" component={Link} to="/signup">Create an account</Button>
          </Grid>

          {/*
          {this.state.logs ? (
            <div style={{ width: "100%", border: "1px solid black" }}>
              {this.state.logs.map(log => {
                return <p>{log}</p>;
              })}
            </div>
          ) : (
            <p>Error</p>
          )}
          */}
        </div>
      {/*</div>*/}
      </>
    );

    
  }

  //=============================================================================
  render_forgotpassword(){
  //=============================================================================
  if (this.state.resetSuccessEmail){
      return (
        <div className="lwpauth-form">
          {logoMobile}          
          <h1>Password Recovery</h1>
          <p>If we found a user with the email address <b>{this.state.resetSuccessEmail}</b> you will receive an email from us shortly.</p>
          <p>If you do not receive the email within a few minutes, please check your junk/spam email folder.</p>
        </div>
      );
    }

    return (
      <>
      <div className="lwpauth-form">
      <LoadingModalWP open={this.state.loading}/>
      {logoMobile}

        <h1>Forgot Password</h1>
        <p>Enter the email address you used to sign up below, and we'll send you a link to reset your password.</p>


        <CRUDForm
            submitOnEnter={true}
            endpoint={resetPassword}
            onSuccess={this.onSuccess_forgotpassword}
            loadColor="blue"
            ref={(component) => {this._mainForm = component}}
            onChange={this.updateFields}

        >
            <Grid container spacing={24} justify="center">
                <Grid item xs={12}>
                    <TextField
                        label="Email Address"
                        name="username"
                        type="email"
                        FormHelperTextProps={{
                            classes: {
                                //'root': classes.helperText,
                                //'error': classes.helperTextError,
                            }
                        }}
                        InputProps={{
                            classes: {
                                //'root': classes.input,
                                //'error': classes.inputError,
                            },
                            endAdornment: this.state.form1 &&(<InputAdornment position="end">
                                    <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'username', value: ''}})} />
                            </InputAdornment>)
                        }}
                        InputLabelProps={{
                            //classes: {'root': classes.label },
                            FormLabelClasses: {
                                //root: classes.formLabelRoot,
                                //error: classes.formLabelError,
                                //focused: classes.formLabelFocused,
                            },
                            shrink: true,
                        }}
                        placeholder="Your email address"
                        fullWidth
                        margin="normal"
                        //value={"" || this.state.vals.username}
                        required={true}
                    />
                </Grid>

                <Grid item xs={12}>
                  <Button id="wpb-cancel" component={Link} to="/login">Cancel</Button>
                </Grid>


                <Grid item xs={12} style={{ marginTop: "20px" }}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Grid item xs={12}>
                      <Button type="submit">Next</Button>
                    </Grid>
                  </Grid>
                </Grid>


            </Grid>
        </CRUDForm>        
      </div>
      </>
    );
    
  }


  //=============================================================================
  render_resetpassword(){
  //=============================================================================
  return (

      <div className="lwpauth-form">
        {logoMobile}
        <h1>Reset Password</h1>
        <p>Enter your new password details below.</p>


      <CRUDForm
            submitOnEnter={true}
            loadColor="blue"
            endpoint={updatePassword}
            onSuccess={this.onSuccess_resetpassword}
  >
      <Grid container spacing={24} justify="center">
          <Grid item xs={12}>
              <TextField
                  label="New Password"
                  name="password1"
                  type="password"
                  FormHelperTextProps={{
                      classes: {
                      //    'root': classes.helperText,
                      //    'error': classes.helperTextError,
                      }
                  }}
                  InputProps={{
                      classes: {
                      //    'root': classes.input,
                      //    'error': classes.inputError,
                      }
                  }}
                  InputLabelProps={{
                      //classes: {'root': classes.label },
                      FormLabelClasses: {
                          //root: classes.formLabelRoot,
                          //error: classes.formLabelError,
                          //focused: classes.formLabelFocused,
                      },
                      shrink: true,
                  }}
                  placeholder="Your new password"
                  fullWidth
                  margin="normal"
              />
          </Grid>
          <Grid item xs={12}>
              <TextField
                  label="Confirm Password"
                  name="password2"
                  type="password"
                  FormHelperTextProps={{
                      classes: {
                          //'root': classes.helperText,
                          //'error': classes.helperTextError,
                      }
                  }}
                  InputProps={{
                      classes: {
                          //'root': classes.input,
                          //'error': classes.inputError,
                      }
                  }}
                  InputLabelProps={{
                      //classes: {'root': classes.label },
                      FormLabelClasses: {
                          //root: classes.formLabelRoot,
                          //error: classes.formLabelError,
                          //focused: classes.formLabelFocused,
                      },
                      shrink: true,
                  }}
                  placeholder="Confirm your password"
                  fullWidth
                  margin="normal"
              />
          </Grid>
          <HiddenField name="uuid" value={this.props.match.params.resetToken}/>

          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Grid container justify="flex-end" alignItems="center">
              <Grid item xs={12}>
                <Button type="submit">Next</Button>
              </Grid>
            </Grid>
          </Grid>

      </Grid>
  </CRUDForm>
    </div>
    );
  }


  //=============================================================================
  render_signup(){
  //=============================================================================

  //const thisField = {"isRequired":true, "id":"building_address", "label":"Building Address","type":FieldType.AUTOCOMPLETE, remoteDomain:'building_address', placeholder:'Your address'};
  //let fieldErrors = {};


  return (
      <>
      {this.state.mobileintro && this.render_mobileintro()}        
      <div className={"lwpauth-form" + (this.state.mobileintro ? " mobileintro-visible":"")}>
        {logoMobile}
        <h1>Sign Up</h1>
        {/*<p>Instructions</p>*/}

        <CRUDForm
            submitOnEnter={true}
            endpoint={createLocalAccount}
                        onSuccess={this.onSuccess_signup}
                        loadColor="blue"
            
                        ref={(component) => {this._mainForm = component}}
                        onChange={this.updateFields}
                    >
                        <Grid container spacing={24} justify="center">
                            {/*<Grid item xs={12}>
                              <AutoComplete field={thisField} initialValueJSON={null} error={fieldErrors[thisField.id]} onUpdate={this.processChildUpdate} />
                            </Grid>*/}

                            <Grid item xs={12}>
                                <TextField
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    FormHelperTextProps={{
                                        classes: {
                                            //'root': classes.helperText,
                                            //'error': classes.helperTextError,
                                        }
                                    }}
                                    ref={(component) => {this.form1Field = component}}
                                    InputProps={{
                                        classes: {
                                            //'root': classes.input,
                                            //'error': classes.inputError,
                                        },//&& this._mainForm.getState().values['email'].length > 0
                                        endAdornment: this.state.form1 && <InputAdornment position="end">
                                                <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'email', value: ''}})} />
                                            </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        //classes: {'root': classes.label },
                                        FormLabelClasses: {
                                          //  root: classes.formLabelRoot,
                                          //  error: classes.formLabelError,
                                          //  focused: classes.formLabelFocused,
                                        },
                                        shrink: true,
                                    }}
                                    placeholder="Your email address"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.username}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Re-type Email Address"
                                    name="email2"
                                    type="email"
                                    FormHelperTextProps={{
                                        classes: {
                                            //'root': classes.helperText,
                                            //'error': classes.helperTextError,
                                        }
                                    }}
                                    ref={(component) => {this.form1Field = component}}
                                    InputProps={{
                                        classes: {
                                            //'root': classes.input,
                                            //'error': classes.inputError,
                                        },//&& this._mainForm.getState().values['email'].length > 0
                                        endAdornment: this.state.form1 && <InputAdornment position="end">
                                                <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'email', value: ''}})} />
                                            </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        //classes: {'root': classes.label },
                                        FormLabelClasses: {
                                          //  root: classes.formLabelRoot,
                                          //  error: classes.formLabelError,
                                          //  focused: classes.formLabelFocused,
                                        },
                                        shrink: true,
                                    }}
                                    placeholder="Confirm your email address"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.username2}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <TextField
                                    label="Create Password"
                                    name="password"
                                    type="password"
                                    FormHelperTextProps={{
                                        classes: {
                                        //    'root': classes.helperText,
                                        //    'error': classes.helperTextError,
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                          //  'root': classes.input,
                                          //  'error': classes.inputError,
                                        },
                                        endAdornment: this.state.form2 && <InputAdornment position="end">
                                                <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'password', value: ''}})} />
                                        </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        //classes: {'root': classes.label },
                                        FormLabelClasses: {
                                           // root: classes.formLabelRoot,
                                           // error: classes.formLabelError,
                                           // focused: classes.formLabelFocused,
                                        },
                                        shrink: true,
                                    }}
                                    placeholder="Your password here"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <HiddenField value={this.state.referralCode} required={false} name="referral"/>
                            <HiddenField value={this.state.signupCode} required={false} name="signupCode"/>

                            <Grid item xs={12}>
                              <span>By creating an account you agree to our <a target="_blank" href="https://www.sharewithoscar.com.au/meriton-terms-and-conditions/" style={{color:'#af864e',fontWeight:'500',textDecoration:'underline'}}>terms and conditions.</a></span>
                            </Grid>

                            <Grid item xs={12} style={{ marginTop: "20px" }}>
                              <Grid container justify="flex-end" alignItems="center">
                                <Grid item xs={12}>
                                  <Button type="submit">Next</Button>
                                </Grid>
                              </Grid>
                            </Grid>

                        </Grid>
                    </CRUDForm>

                    <Grid item xs={12} style={{ marginTop: "32px" }}>
                      <span>Already have an Oscar account?</span><Button id="wpb-createaccount" component={Link} to="/login">Log In</Button>
                    </Grid>

        </div>
      </>
    );
  }

}

//export default LoginWP; //withStyles(whiteInputStyles)();
