import { isNativeWebview } from "../utils/IsNativeWebview.js";

import { getUserBearerToken } from "../utils/Authentication.js";

function requestPushNotification() {
  if (isNativeWebview()) {
    const bearerToken = getUserBearerToken();

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "push_permissions",
        user: bearerToken
      })
    );

    // Mark that we've checked for it on this system
    localStorage.setItem("USER_NOTIFICATION_PERMISSIONS_REQUESTED", true);
  } else {
    // Mark that we've checked for it on this system
    localStorage.setItem("USER_NOTIFICATION_PERMISSIONS_REQUESTED", true);
  }
}

function checkNotificationPermissionHeld() {
  if (isNativeWebview()) {
    let pushPromise = new Promise(function(resolve, reject) {
      window.addEventListener("message", handleNativeResponse);
      function handleNativeResponse(event) {
        if (typeof event.data != "string") {
          return;
        }

        // Remove the listener
        window.removeEventListener("message", handleNativeResponse);

        try {
          const nativeResponse = JSON.parse(event.data);
          if (nativeResponse.message === "push_is_granted") {
            localStorage.setItem("USER_NOTIFICATION_PERMISSIONS_HELD", true);
            resolve();
          } else {
            localStorage.setItem("USER_NOTIFICATION_PERMISSIONS_HELD", false);
            resolve();
          }
        } catch (e) {
          resolve();
        }
      }

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "has_push_permissions"
        })
      );
    });
    return pushPromise;
  }

  return new Promise(function(resolve, reject) {
    resolve();
  });
}

function checkUserNotificationPermissions() {
  return (
    localStorage.getItem("USER_NOTIFICATION_PERMISSIONS_REQUESTED") === null
  );
}

export {
  requestPushNotification,
  checkNotificationPermissionHeld,
  checkUserNotificationPermissions
};
