import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";

import {
    IconButton,
    Typography,
    withStyles,
} from '@material-ui/core';
import { whiteInputStyles } from '../styles/OscarTheme.js';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';

class ResetConfirmation extends Component {
    render() {
        return(
            <div className="gradientPage" style={{height: '100%', overflowY: 'auto'}}>
                <div className="mainContent topPadded">
                    <IconButton className="leftCornerButton"
                    component={Link} to="/Login" style={{color: 'white'}}>
                        <IconBack />
                    </IconButton>
                    <Typography gutterBottom style={{color: 'white'}} color="primary" variant="display1">
                        Reset Email Sent
                    </Typography>
                    <Typography style={{color: 'white', margin: '32px 0px'}} color="primary" variant="body1">
                        We've sent you an email to the address you entered. Open the email and follow the link to reset your password.
                    </Typography>
                   
                </div>
            </div>
        )
    }
}

export default withStyles(whiteInputStyles)(withRouter(ResetConfirmation))