import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import { 
    cancelBooking,
    reviewBooking,
    getBookingDetails,
    setBookingStatus
} from '../api/Oscar-api.js';

import {
    BookingsRangeDisplayFormat,
    ParseDateTimeParameters,
    TwelveHourTime,
    WeekdayDate,
    WPMonthlyBookingsDisplayFormat
} from '../utils/TimeFormats';


import LoadingModal from '../components/LoadingModal';
import LongTermEnquiry from '../views/LongTermEnquiry.js';
import EditItem_Form from '../components/EditItem_Form.js';


import {
    Button,
    FormControl,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";

import IconBack from '@material-ui/icons/KeyboardArrowLeft'
import IconClose from "@material-ui/icons/Close"
import IconForward from '@material-ui/icons/KeyboardArrowRight'

import Carousel from 'react-responsive-carousel/lib/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';

import FullPageModal from '../utils/FullPageModal.js';
import { isNativeWebview } from '../utils/IsNativeWebview';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';
import SelectField from '../components/forms/SelectField.js';
import { updateUserProfileAjax } from '../utils/Authentication.js';
import BookingLimitExceeded from './BookingLimitExceeded.js';
import AvailabilityError from '../components/AvailabilityError.js';
import CardPaymentError from '../components/CardPaymentError.js';

import {getSpaceType} from '../utils/GetSpaceDetails.js';
import {getImageListForSpace} from '../utils/GetImageListForSpace.js';
import ENUMS from '../api/ENUMS.json'
import { FieldType } from '../api/fieldTypes.js';
import SelectDropDown from '../components/SelectDropDown';


const styles = {
  rightTopButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  leftTopButton :{
        position: 'absolute',
        zIndex: '3',
    },
    contentContainer: {
        height: '100%',
        overflowY: 'auto',
    },
    descriptionContentContainer: {
        marginTop: '8px',
        padding: '16px',
        flexGrow: '1',
    },
    container: {
        padding: '0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    rate: {
        fontWeight: 'bold',
    },
    bookingButtonContainer: {
        width: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
    },
    bookingButton: {
        padding: '22px',
    },
    descriptions: {
        paddingLeft: '16px',
    },
    distanceText: {
        textTransform: 'uppercase',
        color: "#acacac",
    },
    imageCarousel: {
        width: '100%',
        height: '250px',
    },
    carouselImage: {
        width: 'auto',
        height: 'auto'
    },
    subheading: {
        marginTop: '16px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    reviewIcon: {
        fontSize: '13px',
        marginRight: '4px'
    },

    messageButton: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
        color: '#1AA5A2',
        backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover, &:focus': {
            backgroundColor: 'white',
        },
    },
    editButton: {
        color: '#1AA5A2',
        backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover, &:focus': {
            backgroundColor: 'white',
        },
        border: '1px solid #1AA5A2'
    },

    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '10px',
    },
    nextButton: {
        position: 'absolute',
        boxShadow: 'none',
        bottom: 16,
        right: 16,
    },

//    topColored: {
//      paddingTop: '80px',
//        backgroundColor: '#1AA5A2',
//        paddingBottom: '8px',
//    },
    paymentSetting: {
        //paddingTop: '8px',
        //paddingBottom: '8px',
        borderBottom: '1px solid #efefef'
    },
    switchField: {
        borderTop: '1px solid #acacac',
        borderBottom: '1px solid #acacac',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '12px',
        marginBottom: '12px',
    },
    transactionsContainer: {
        marginTop: '16px'
    },
    transactionContainer: {
        borderTop: '1px solid #efefef',
        paddingTop: '8px',
        paddingBottom: '6px',
    },
    switchBorder: {
        marginTop: '24px',
        borderTop: '1px solid #efefef',
    },

    pickerContainer: {
        minHeight: '550px', 
        backgroundColor: 'white',
        paddingTop: '32px',
    },

    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    },
    link: {
        display: 'inline-block',
        marginLeft: '4px',
        textDecoration: 'underline',
    }
}

function CancelSuccess(props) {
    return(
        <div className="mainContent topPadded" style={styles.alertPage}>
            <Typography variant="display1" color="primary">
                Your booking has been cancelled.
            </Typography>

            <Typography>
                Your booking has been successfully cancelled. If you cancelled more than 2 hrs before your booking, your cancellation refund will be available in your wallet.
            </Typography>

            <div style={styles.alertImageContainer}>
                <img src="https://www.sharewithoscar.com.au/assets/graphics/cancelled_booking.svg" alt="booking cancelled"/>
            </div>

            <Button style={styles.alertButton} fullWidth variant="contained" 
            onClick={() => props.history.push('/')} color="primary">
                Okay
            </Button>
        </div>
    )
}

function ExtendSuccess(props) {
    return(
        <div className="mainContent topPadded" style={styles.alertPage}>
            <Typography variant="display1" color="primary">
                Your booking has been extended.
            </Typography>

            <Typography>
                Your booking has been successfully extended to {props.end.format(TwelveHourTime)}
            </Typography>

            <div style={styles.alertImageContainer}>
                <img src="https://www.sharewithoscar.com.au/assets/graphics/extended_booking.svg" alt="booking extended"/>
            </div>

            <Button style={styles.alertButton} fullWidth variant="contained" 
            onClick={props.onClose} color="primary">
                Got it
            </Button>
        </div>
    )
}


function CancelRequestSuccess(props) {
  //      <div className="mainContent topPadded" style={styles.alertPage}>
  return(
        <div className="sd-container">
          <div className="sd-details">
            <h1>Your booking request has been cancelled.</h1>
            <p>Check out other parking spaces available in the area.</p>
          </div>
        <div className="sd-actions">
          <Button className="wp-button"  onClick={props.onClose}>Search Now</Button>
        </div>
      </div>
  );
}



class CancelBookingForm extends Component{
    state = {loading: false, successShow: false}

    handleClose = (event) => {
      this.props.onClose();
      
    }


    /*handleReason = (event) => {
        this.setState({ reason: event.target.value })
    }*/

    handleCancelBooking = () => {
        this.setState({loading: true})
        const params = {
            cancelled: true,
            cancelled_reason: this.state.reason,
        }

        cancelBooking(params, this.props.booking.reference).then(response => {
            //console.log(response)
            this.setState({loading: false})
            // Show extension success
            this.setState({
                successShow: true
            })
        }).catch(error => {
            console.error(error);
            this.setState({loading: false});
        })
    }
    
    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    render() {
        //const RoutedCancel = withRouter(CancelSuccess)
        //booking={this.props.booking} />
        //console.log("booking:",this.props.booking);

        const start_date = this.props.booking.start_date.replace(/-/g,'');   // 20211029
        const location_date = this.props.booking.location_date.replace(/-/g,'');   // 2021-10-13  
        //const location_date = "20211030";

        //console.log("start_date:",start_date);
        //console.log("location_date:",location_date);

        let formId;
        let itemType;
        let initialPage;
        if (this.props.booking.refunded){
          formId = 'public_meriton_cancel_booking';
          itemType = 'booking_refund';  //already refunded
          initialPage = 4;

        } else if (this.props.booking.cancelled){
          //console.log("is already cancelled");
          formId = 'public_meriton_cancel_booking';
          itemType = 'booking_refund';  //only allow refunds
          initialPage = 2;


        } else if ( parseInt(start_date,10) >= parseInt(location_date,10)){
          //console.log("can cancel");  // can cancel any day before the start, PLUS the actual day OF
          formId = 'public_meriton_cancel_booking';
          itemType = 'booking_cancel';

        } else {
          //console.log("only can end");
          
          if (this.props.booking.cease_date){
            const cease_date = this.props.booking.cease_date.replace(/-/g,'');   // 20211029
            //console.log("has cease_date, set to page 2:",cease_date);
            initialPage = 0;//1;

          }
          
          formId = 'public_meriton_end_booking';
          itemType = 'booking_end';

      }
        


        return (
                <EditItem_Form
                authChange={this.props.authChange}
                history={this.props.history}
                formId={formId}
                initialPage={initialPage}
                initialData={this.props.booking}  // saves a wasted trip to the server
                itemType={itemType}
                itemId={this.props.booking.reference}
                onClose={this.handleClose}
             />

        );
    }
}





class EditBookingMenu extends Component {
    state = { 
        cancelBookingFormOpen: false,
    }

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    //this.setState({cancelBookingFormOpen: false})
    render() {
      let showCancelEnd = true;
      
      //(old -> !this.props.booking.cancelled)
      // Don't show any bookings whose cease_date has passed
      if (this.props.booking.cease_date){
        const cease_date = this.props.booking.cease_date.replace(/-/g,'');   // 2021-10-13  
        const location_date = this.props.booking.location_date.replace(/-/g,'');   // 2021-10-13  
        if (cease_date < location_date){
          showCancelEnd = false;
        }
      }

      return(
            <div>

            <FullPageModal open={this.state.cancelBookingFormOpen}>
                <CancelBookingForm onClose={() => this.props.onClose()} 
                booking={this.props.booking}
                authChange={this.props.authChange} />
            </FullPageModal>

            <div className="up-header">
                <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                    <IconBack style={{color: 'white'}}/>
                </IconButton>

                <h1>Edit My Booking</h1>
            </div>
            <div style={{backgroundColor: 'white', height: '520px', paddingTop: '24px'}} className="mainContent">
                
                
                {showCancelEnd &&
                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                className="wp-hovercursor" onClick={() => this.setState({cancelBookingFormOpen: true})}>
                    <Grid item>
                        <Typography variant="body1">
                            Cancel or End My Booking
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton id="incident_report">
                            <IconForward/>
                        </IconButton>
                    </Grid>
                </Grid>}
                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                className="wp-hovercursor" onClick={() => this.externalLink(`https://www.sharewithoscar.com.au/meriton-incident/` ,`https://www.sharewithoscar.com.au/meriton-incident/`)}>
                    <Grid item>
                        <Typography variant="body1">
                            Report an Incident
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton id="incident_report">
                            <IconForward/>
                        </IconButton>
                    </Grid>
                </Grid>

            </div>
            </div>
        )
    }
}

class ReviewBooking extends Component {
    state = {loading: false}

    handleReview = (review) => {
        this.setState({loading: true})
        reviewBooking({review: review}, this.props.booking.reference).then(response => {
            if (this.props.forced) {
                updateUserProfileAjax().then(response=> {
                    this.props.authChange()
                    this.props.history.push('/')            
                    this.setState({loading: false})
                })
            } else {
                this.props.onClose()
            }
        }).catch(error => {
            console.error(error);
            this.setState({loading: false})
        })
    }

    render() {

      let divTitle;
      let divQuestion;
      if (this.props.booking.parking_bay){
        divTitle = <Typography variant="display1" color="primary">Rate {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
        divQuestion = <Typography variant="body1" >How would you rate your experience at the {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
      } else {
        divTitle = <Typography variant="display1" color="primary">Rate this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
        divQuestion = <Typography variant="body1" >How would you rate your experience at the this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
      }
        return(
            <div className="mainContent topPadded" style={styles.alertPage}>
                <LoadingModal open={this.state.loading}/>

                <IconButton className="leftCornerButton" onClick={() => this.handleReview("Neutral")}>
                    <IconClose />
                </IconButton>

                {divTitle}
                {divQuestion}

                <div style={styles.alertImageContainer}>
                    <img src="https://www.sharewithoscar.com.au/assets/graphics/rate_spot.svg" alt="review request for a spot"/>                    
                </div>
                
                <Grid container spacing={16} style={{position:"absolute", width: "93%",bottom: '16px'}}>

                    <Grid item xs={6}>
                        <Button style={styles.editButton} fullWidth variant="contained" color="primary"
                         onClick={() => this.handleReview("Negative")}>
                            Thumbs Down
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth variant="contained" color="primary"
                         onClick={() => this.handleReview("Positive")}>
                            Thumbs Up
                        </Button> 
                    </Grid>
                </Grid>
            </div>
        )
    }
}

class RepeatBooking extends Component {
    state = {enquireOpen: false}
    render() {
      let spaceType = getSpaceType(this.props.booking.parking_space);

      return(
            <div style={{height: '100%', overflowY: 'auto'}}>
                <FullPageModal open={this.state.enquireOpen}>
                    <LongTermEnquiry 
                        onClose={() => this.setState({ enquireOpen: false })}
                        history={this.props.history}
                        spaceUuid={this.props.booking.parking_space.uuid}
                        spaceType={spaceType}
                    />
                </FullPageModal>
                
                <div className="mainContent" style={styles.topColored}>
                    <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                        <IconBack style={{color: 'white'}}/>
                    </IconButton>

                    <Typography style={{color: 'white'}} variant="display1">
                        Repeat Booking
                    </Typography>            
                </div>
                <div style={{backgroundColor: 'white', paddingTop: '16px'}} className="mainContent">
                    <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                    onClick={() => this.props.history.push(`/Search/${this.props.booking.parking_space.street_name}/${this.props.booking.parking_space.latitude}/${this.props.booking.parking_space.longitude}/${this.props.booking.parking_space.uuid}`)}>
                        <Grid item>
                            <Typography variant="body1">
                                Single Booking
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton id="extend_attempt">
                                <IconForward color="primary"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                    onClick={() => this.setState({enquireOpen: true})}>
                        <Grid item>
                            <Typography variant="body1">
                                Enquire for Long Term Booking
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton id="cancel_attempt">
                                <IconForward color="primary"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            </div>            
        )
    }
}




class CancelRequest extends Component {
  state = {loading: false}

  doCancelRequest = (booking) => {
    this.setState({loading: true});


    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "4", // Cancelled by Driver
      "reason": this.state.driver_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({loading: false, cancelRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });


  }

  externalLink = (target, nativeTarget) => {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}


  render() {

    const thisField = {"isRequired":true, "id":"cancellation_reason", "label":"Reason for cancellation","type":FieldType.SELECT_DROPDOWN, domain:'driver_monthly_cancellation_reason'};
    let fieldErrors = {};
    



    let divTitle;
    let divQuestion;
    /*if (this.props.booking.parking_space){
      divTitle = <Typography variant="display1" color="primary">Rate {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
      divQuestion = <Typography variant="body1" >How would you rate your experience at the {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
    } else {
      divTitle = <Typography variant="display1" color="primary">Rate this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
      divQuestion = <Typography variant="body1" >How would you rate your experience at the this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
    }*/
      return(
          <>
          <FullPageModal open={this.state.cancelRequestSuccessShow}>
            <CancelRequestSuccess onClose={() => this.props.history.push(`/search`)}/>
          </FullPageModal>


          <div className="mainContent" style={styles.topColored}>
            <LoadingModal open={this.state.loading}/>

            <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                  <IconBack style={{color: 'white'}}/>
            </IconButton>

            <h1>Cancel your booking request</h1>
          </div>


          <div className="sd-container">

            <div className="sd-details">
              <p style={{marginBottom:'20px'}}>Are you sure you want to cancel your booking request?</p>

           
              <SelectDropDown pageData={"pageData"} field={thisField} initialValue={this.state.driver_cancel_reason} error={fieldErrors[thisField.id]} onUpdate={(option) => this.setState({driver_cancel_reason:option.finalValue})} />

              {window.WPDomain ?
              <p style={{marginTop:'20px'}}>View the <a href={`https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/`} target="_blank" className="oscar-external-link">cancellation policy</a> here.</p>
              :
              <p style={{marginTop:'20px'}}>View the <a href={`https://www.sharewithoscar.com.au/oscar-cancellation-policy/`} target="_blank" className="oscar-external-link">cancellation policy</a> here.</p>
              }
              
            </div>
              

          </div>
          <div className="sd-actions">
            <Button className="wp-button-outline" onClick={() => this.props.onClose()}>Back</Button>
            <Button className="wp-button" disabled={!this.state.driver_cancel_reason} onClick={() => this.doCancelRequest(this.props.booking)}>Cancel Request</Button> 
          </div>
        </>
      )
  }
}



export default class BookingDetailsWP extends Component {
    state = {
        loading: false,
        edittingBooking: false,
        reviewBooking: this.props.match.params.review !== undefined,
        forcedReview: this.props.match.params.review !== undefined
    }

    componentWillMount() {
        this.updateBooking()
    }

    //TODO: ONLY FETCH *CURRENT* BOOKING, not a giant list of every single one... this is so dumb
    updateBooking() {
        this.setState({loading: true})

        let that = this;
        const bookingRef = this.props.match.params.reference;
        getBookingDetails(bookingRef).then(details => {
          //console.log(details.data);
          const booking = details.data;
          booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters);
          booking.end = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters);

          that.setState({
            loading: false,
            booking
          });

        });






        /*getTrips().then(response => {
            console.log(response.data)
            
//return;
            const booking = response.data.find(elem => elem.reference === this.props.match.params.reference)
            if (booking){
              booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters)
              booking.end = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters)
              
              booking.expired = booking.end.isBefore(moment()) || booking.cancelled
  
            }

            this.setState({
                loading: false,
                booking: booking,
            })
        })*/
    }

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    render() {
        if (this.state.loading) {
            return(
                <LoadingModal open={this.state.loading} />
            )
        }

        if (!this.state.booking){
          return (<div>NO BOOKING FOUND.</div>);
        }
        

        const booking = this.state.booking;

        if (!booking.parking_bay){
          return (<h1>Please use the Share with Oscar app to view this booking.</h1>);
        }
        //console.log("booking:",booking);
        const spaceData = booking.parking_bay;
        const building = spaceData.building;
  

        let images = getImageListForSpace(spaceData, building);


        let address;
        if (booking.parking_space){
          address = this.state.booking.expired ? this.state.booking.parking_space.street_name : this.state.booking.parking_space.address.replace("Australia", this.state.booking.parking_space.postcode)

        } else {
          address = "no address??";

        }

        let strType;
        let instruction;
        if (booking.parking_space){
          strType = booking.parking_space.type;
          instruction = booking.parking_space.instruction;
          
        } else {
          strType = "MERITON";
          instruction = "no bay instructions!";
        }
        

        const start_date = moment(booking.start_date).format(WPMonthlyBookingsDisplayFormat);
        //console.log("start_date:",start_date);
        let end_date;

        if (booking.cease_date){
          end_date = moment(booking.cease_date).format(WPMonthlyBookingsDisplayFormat);
        } else {
          end_date = "Monthly Ongoing";
        }

        let divApproval;

        if (booking.approval_status === 1){
          divApproval = <div className="approval-waiting">Booking Request {ENUMS.approval_status[booking.approval_status || 0]}</div>;
        } else if (booking.approval_status === 2){
          divApproval = <div className="approval-confirmed">Booking Request {ENUMS.approval_status[booking.approval_status || 0]}</div>;
        } else if (booking.approval_status === 3 || booking.approval_status === 4){
          divApproval = <div className="approval-declined">Booking Request {ENUMS.approval_status[booking.approval_status || 0]}</div>;
        }
        
        let strApprovalCutOff = moment(booking.create_date).add(72,"hours").format("Do MMMM YYYY");


        return(
          <div className="sd-container booking-details">
            <LoadingModal open={this.state.loading}/>

            <FullPageModal open={this.state.cancelRequest}>
              <CancelRequest history={this.props.history} booking={booking} onClose={() => { this.setState({cancelRequest: false}) }} />
            </FullPageModal>



            <FullPageModal open={this.state.edittingBooking}>
                <EditBookingMenu 
                    booking={this.state.booking}
                    onClose={() => {
                        this.updateBooking()
                        this.setState({edittingBooking: false})
                    }}
                    history={this.props.history}
                    authChange={this.props.authChange}
                    user={this.props.user}
                />
            </FullPageModal>
                {/*<FullPageModal open={this.state.repeatBooking}>
                    <RepeatBooking 
                        booking={this.state.booking}
                        onClose={() => {
                           this.setState({repeatBooking: false})
                        }}
                        history={this.props.history}
                    />
                      </FullPageModal>*/}
                {/*<FullPageModal open={this.state.reviewBooking}>
                    <ReviewBooking 
                        booking={this.state.booking}
                        onClose={() => {
                           this.setState({reviewBooking: false})
                        }}
                        forced={this.state.forcedReview}
                        history={this.props.history}
                        authChange={this.props.authChange}
                    />
                      </FullPageModal>*/}


                <Carousel 
                  showArrows={true} 
                  showStatus={false} 
                  showIndicators={false} 
                  showThumbs={false} 
                  infiniteLoop={true} 
                >
                  {images}
                </Carousel>
                <IconButton style={styles.rightTopButton}
                  className="wp-btn-close"
                  onClick={() => this.props.history.push('/bookings')}>
                    <IconClose style={{color: 'rgb(207, 207, 207)'}}/>
                </IconButton>



                <div className="sd-details">

                  <h1><span>{building.building_name}</span> at {building.address}, {building.suburb} {building.state} {building.postcode} </h1>
                  <div className="booking-ref">Reference: {booking.reference}</div>
                  <div className="booking-ref">{start_date} - {end_date}</div>

                  {divApproval}

                  {booking.approval_status == 1 &&
                    <div className="description">The owner has until the end of <b>{strApprovalCutOff}</b> to accept this offer or it will be automatically cancelled.</div>
                  }                  


                  {!booking.expired && booking.approval_status === 2 &&
                  <Grid container spacing={16}>
                    <Grid item xs={12} sm={9}>
                      {building.rules_text && <div className="rules_text" dangerouslySetInnerHTML={{__html: building.rules_text}}/>}

                      <h2>Description</h2>
                      <div className="description" dangerouslySetInnerHTML={{__html: spaceData.description || building.description}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <h2>Maximum vehicle size</h2>
                      <div className="description">Bay#{spaceData.bay_marking} - {ENUMS.vehicle_types[spaceData.vehicle_type]}</div>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                      <h2>Instructions</h2>
                      <div className="description" dangerouslySetInnerHTML={{__html: spaceData.instruction || building.instruction}}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <h2>Access method</h2>
                      <div className="description">{ENUMS.access_methods[spaceData.access_method || building.access_method]}</div>
                      
                    {booking.access_code &&
                        <>
                        <h2>Access Code</h2>
                        <p>{booking.access_code}</p>
                        </>
                    }
                    </Grid>
                  </Grid>
                  }

                    {booking.approval_status === 3 &&
                      <div>
                        Unfortunately, the owner has declined your request to book. Take a look at some alternate parking spaces
                      </div>
                    }

                    {booking.approval_status === 4 &&
                      <div>
                        This booking was cancelled by the driver
                      </div>
                    }
                  


                  <Grid container spacing={16}>
                  </Grid>

                </div>
                {/*<div className="sd-actions">
                  <Button className="wp-button" disabled={booking.cancelled} onClick={() => this.props.history.push(`/messages/${this.props.match.params.reference}/driver/`)}>Contact Owner</Button>
                  <Button className="wp-button" onClick={() => this.setState({edittingBooking: true})}>Edit Booking</Button>
                  </div>*/}

                <div className='sd-actions'>
                  {booking.approval_status == 1 && 
                    <>
                      <Button className="wp-button" onClick={() => this.props.history.push(`/messages/${this.props.match.params.reference}/driver/`)}>Contact Owner</Button>                            
                      <Button className="wp-button" onClick={() => this.setState({cancelRequest: true})}>Cancel Request</Button>
                    </>
                  }

                  {booking.approval_status == 2 &&
                    <>
                      <Button className="wp-button" onClick={() => this.props.history.push(`/messages/${this.props.match.params.reference}/driver/`)}>Contact Owner</Button>                            
                      <Button className="wp-button" onClick={() => this.setState({edittingBooking: true})}>Edit Booking</Button>
                    </>                      
                  }
                </div>
            </div>
        )
    }
}
