import React, {Component} from 'react';
import moment from 'moment';

import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';

import {longtermEnquiry} from '../api/Oscar-api.js';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core';

import { QueryParamDate, QueryParamTime } from '../utils/TimeFormats.js';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';

import LoadingModal from '../components/LoadingModal';
import WeekDaySelector from '../components/forms/WeekDaySelector.js';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';

import FullPageModal from '../utils/FullPageModal';
import NumberField from '../components/forms/NumberField.js';

const styles = {
    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '10px',
    },
    nextButton: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },

/*    topColored: {
        paddingTop: '80px',
        backgroundColor: '#aaaaaa',
        paddingBottom: '8px',
    },*/
    daysControl: {
        fontWeight: 'bold',
        marginTop: '16px',
        marginBottom: '16px',
        textTransform: 'uppercase'
    },
    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom:'20px'
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    },
    submitButton: {
        margin: '16px 0px',
    },
    submitButtonActive: {
        margin: '16px 0px',
        backgroundColor: '#1AA5A2',
        color: 'white',
    }
}

class EnquiryConfirmationWP extends Component {
    render() {
      //<div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
        return(
                <>
                <div className="sd-details">                
                <h1>Your enquiry has been submitted!</h1>
                <p>The owner will get back to you over the next 2-3 days if they would like to accept your offer.</p>
                    
                <div style={styles.alertImageContainer}>
                    <img style={{width:'50%'}} src="https://www.sharewithoscar.com.au/assets/graphics/wp-enquiry-sent.svg" alt="congratulations"/>
                </div>
                
                
              </div>
              <div className="sd-actions">                
                <Button className="wp-button" onClick={this.props.onClose}>Close</Button>
              </div>
              </>
        )
    }
}

export default class LongtermEnquiryWP extends Component {
    state = { 
        loading: false,
        msm_startdate: moment().format("YYYY-MM-DD"),
        //end: moment().add(3, 'months'),
        selectedDays: [false, false, false, false, false, false, false],

        //rate_weekly: '',
        week_duration: '',
        comments: '',
    }

    handleDaySelect = (index) => {
        let actives = this.state.selectedDays;
        actives[index] = !actives[index]
        this.setState({selectedDays: actives})
    }

    submissionValid = () => {
      if (this.state.selectedDays.find(elem => elem)){
        return true;
      } else {
        return false;
      }

/*        if (this.state.rate_weekly !== undefined 
            && this.state.rate_weekly !== ''
            && this.state.week_duration !== undefined 
            && this.state.week_duration !== ''
            && this.state.selectedDays.find(elem => elem)) {
            return true
        }
        return false*/
    }

    setStartDate = (startDate) => {
      let msm_startdate = moment(startDate).format("YYYY-MM-DD");
      this.setState({msm_startdate});
    }
    handleTimeRangeSelected = (start) => {
        this.setState({start: start})
    }

    submitEnquiry = () => {
        this.setState({confirmationOpen: true});

        longtermEnquiry({
          space_uuid: this.props.spaceUuid,
          space_type: this.props.spaceType,
            start_date: this.state.msm_startdate, //.format(QueryParamDate), //this.state.start.format(QueryParamDate),
            start_time: '00:00', // this.state.start.format(QueryParamTime),
            //msm_startdate: this.state.msm_startdate,
            duration: this.state.week_duration,
            day_sunday: this.state.selectedDays[0],
            day_monday: this.state.selectedDays[1],
            day_tuesday: this.state.selectedDays[2],
            day_wednesday: this.state.selectedDays[3],
            day_thursday: this.state.selectedDays[4],
            day_friday: this.state.selectedDays[5],
            day_saturday: this.state.selectedDays[6],
            weekly_rate: this.state.rate_weekly,
            comment: this.state.comment,
        }, this.props.spaceUuid)
    }

    render() {
      const spaceData = this.props.spaceData;

      let msm_startdate = this.state.msm_startdate;
      let dp_startdate = moment(msm_startdate).toDate();

        return(
            <div style={{height: '100%', overflowY: 'auto'}}>
                <LoadingModal open={this.state.loading}/>
                                
                <FullPageModal open={this.state.confirmationOpen}>
                    <EnquiryConfirmationWP history={this.props.history} onClose={this.props.onClose}/>
                </FullPageModal>

                <div className="up-header">
                    <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                        <IconBack style={{color: 'white'}}/>
                    </IconButton>

                    <h1>Long Term Enquiry</h1>
                        
                    
                </div>
                <div style={{backgroundColor: 'white', paddingTop: '0px'}} className="mainContent">
                    <div>
                    
                        <div style={{ marginBottom: '24px'}}>
                          
                        <Typography style={styles.daysControl} variant="caption">
                            Start Date
                        </Typography>
                                              
                        <DatePicker
                                selected={dp_startdate}
                                onChange={(date) => this.setStartDate(date)}
                                minDate={addDays(new Date(),1)}
                                maxDate={addDays(new Date(),365)}
                                withPortal
                                fixedHeight
                                dateFormat="dd MMM yyyy"
                                className="lte-startdate"
                  
                                //customInput={<ExampleCustomInput />}
                              />

                        {/*<DateTimeRangePicker 
                            withDate={true}
                            verticalForm={true}
                            mixedForm={true}
                            enforceFuture={false}
                            start={this.state.start}
                            end={this.state.end}
                            startText="From"
                            endText="To"
                            onTimeRangeSelected={this.handleTimeRangeSelected}
                            disableAutoEditEnd={true}
                            disableEnd={true}
                        />*/}
                        </div>
                        <Typography style={styles.daysControl} variant="caption">
                            Duration
                        </Typography>

                        <FormControl margin="normal" style={{width: '100%',marginTop:'-10px'}}>
                            <TextField 
                                //label="Duration"
                                name="rate_hourly"
                                value={this.state.week_duration}
                                InputLabelProps={{
                                    //shrink: true,
                                }}
                                onChange={(event) => this.setState({week_duration: event.target.value})}
                                placeholder="e.g. 6 weeks or 6 months"
                                //disableAnnotation={true}
                            />
                        </FormControl>
                        
                        <Typography style={styles.daysControl} variant="caption">
                            Days required
                        </Typography>
                        <WeekDaySelector selectedDays={this.state.selectedDays} onChange={this.handleDaySelect} />
                    </div>


                    <Typography style={styles.daysControl} variant="caption">
                            Monthly Rate
                        </Typography>
                        <p>${spaceData.rate_monthly}/month</p>
                        
                    <TextField
                        multiline
                        rows={1}
                        label="Comments"
                        name="description"
                        type="text"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        placeholder="Anything else?"
                        fullWidth
                        margin="normal"
                        value={this.state.comment}
                        onChange={(event) => this.setState({comment: event.target.value})}
                    />

                    <Grid container justify="flex-end">
                        <Button style={{width:'30%',marginTop:'30px'}} className="wp-button" disabled={!this.submissionValid()} onClick={this.submitEnquiry}>Send</Button>
                    </Grid>
                </div>
            </div>
        )
    }
}
