/* 
LONG TERM ENQUIRES INBOX
*/
import React, {useState, useEffect} from "react";
import moment from 'moment';
import {Link} from 'react-router-dom';

import {getLongTermEnquiries, replyToConversation} from '../api/Oscar-api.js';

// Get rid of material ui - old library, too many issues, use a css approach
import { Grid, IconButton, Typography, TextField, Button } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import LoadingModal from '../components/LoadingModal';

const styles = {
    timestamp: {
        textTransform: 'uppercase',
    },
    notificationContainer: {
        paddingTop: '8px'
    },
    notification: {
        padding: '8px',
        border: '1px solid #efefef',
        marginTop: '16px',
        flexWrap: 'nowrap',
    }
}


/*
// Setup TypeScript later
interface IConversationData {
  subject:any,
  sender_public_name:any,
  recipient_public_name:any
  etc...
}*/

export default function LongTermEnquiries({history, match}) {
  const { lteId } = match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [singleConversation, setSingleConversation] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inputMessage, setInputMessage] = useState('');
  //let navigate = useNavigate();

  useEffect(() => { fetchConversation(); }, []);

  const submissionValid = () => {
    return (inputMessage != '' && inputMessage.length >= 1);
    /*if (inputMessage && inputMessage != '' && inputMessage.length >= 1){
      return true;
    } else {
      return false;
    }*/
  }

  const sendMessage = () => {  
    setIsLoading(true);
    setErrorMessage(null);

    //console.log("sendMessage:",inputMessage);
    //setTimeout(()=>{setIsLoading(false);}, 1000); // TEMP - for DEV

    replyToConversation({
      message: inputMessage,
      duration:0,                 // legacy to pass validation
      start_date: '2020-01-01',   // legacy to pass validation
      start_time: '09:00',        // legacy to pass validation
      }, singleConversation.conversation.uuid).then(() => {
      //this.setState({loading:false, confirmationOpen: true});
      setIsLoading(false);
      setErrorMessage(null);
      setInputMessage('');
      fetchConversation();

    }).catch(error => {
      //console.log("Error:",error);
      setErrorMessage(`Your message cannot be sent. <a href='https://www.sharewithoscar.com.au/terms-and-conditions/' target='_new'>View our terms of use.</a>`);
      setIsLoading(false);
    });    

  }

  const fetchConversation = () => {  
    setIsLoading(true);
    setSingleConversation(null);
    setConversations(null);

    getLongTermEnquiries(lteId).then(response => {
      //console.log(response.data);
      
      if (response.data.length){
        setConversations(response.data);
      } else {
        //console.log("single?");
        setSingleConversation(response.data);
      }
      //setTimeout(()=>{setIsLoading(false);}, 1000); // TEMP - for DEV
      setIsLoading(false);
      

  }).catch(error => {
      console.error(error);
      setIsLoading(false);
  })};

 
  let strClass = "mainContent topPadded";
 
  // Conversation List Mode
  if (!lteId){
 
    // TEMP - move to css
/*
    let divUnreadCount;
    if (conversations){
      //let unreadCounter = 0;
      //for (let i=0;i<conversations.length;i++){ if (conversations[i].new_for_you){ unreadCounter++; } }
      //if (unreadCounter === 0){ divUnreadCount = <p style={{margin:'5px 0'}}>If you already have made a booking, check your inbox via the booking.</p>; } 
      //else if (unreadCounter === 1){ divUnreadCount = <p style={{margin:'5px 0',fontWeight:'700'}}>You have 1 unread message</p>; }
      //else { divUnreadCount = <p style={{margin:'5px 0',fontWeight:'700'}}>{`You have ${unreadCounter} unread messages`}</p>; }
      
    } else if (!isLoading) {
      divUnreadCount = <p style={{margin:'5px 0'}}>If you already have made a booking, check your inbox via the booking.</p>;
    }
  */  
    return (
      <div className="wpadmin-content top-padded-mobile" style={{paddingBottom:'80px'}}>
        <LoadingModal open={isLoading} />
        <>
        <div className="hide-desktop"><IconButton className="leftCornerButton" component={Link} to="/search"><CloseIcon /></IconButton></div>
        <h1 style={{color:'var(--heroColor)',margin:'20px 0 0 0'}}>Enquiries</h1>
        <div className="conversations-list">
          {conversations && conversations.map((o, index) => ( <ConversationLink key={index} data={o} history={history} /> ))}
        </div>
        </>
      </div>
    );
  }
  

  // Single Conversation mode
  if (lteId){ strClass+= " single-conversation" }
  const thisLTE = singleConversation? singleConversation.conversation:null;
  return (
    <div className="v3-singlecc">
    <LoadingModal open={isLoading} />
    {thisLTE &&
        <>
        <IconButton className="leftCornerButton" component={Link} to="/longtermenquiries" style={{marginTop:'5px'}}><IconBack /></IconButton>
        <div className="v3-singlecc-ps">
          {singleConversation.your_user === 0 && <div className="v3-sender_public_name">{thisLTE.recipient_public_name}</div> /* show TO:person */}
          {singleConversation.your_user === 1 && <div className="v3-sender_public_name">{thisLTE.sender_public_name}</div> /* show FROM:person */}
          {thisLTE.parking_space && <div className="v3-address">{thisLTE.parking_space.type} on {thisLTE.parking_space.street_name}</div>}
        </div>
        <div className="v3-singlecc-threads">
          {singleConversation.threads && singleConversation.threads.map((o, index) => ( <ConversationThread key={index} data={o} history={history} conversation={singleConversation.conversation} /> ))}
        </div>
        {/*
        <div className="v3-singlecc-input">
          <TextField
            className="oscar-lte-textarea"
            multiline
            name="description"
            type="text"
            InputLabelProps={{ shrink: true }}
            InputProps={{disableUnderline: true}}
            placeholder="Enter your message here"
            fullWidth
            margin="normal"
            value={inputMessage}
            onChange={(event) =>setInputMessage(event.target.value)}
            />
            {/button variant="contained" color="primary" disabled={!submissionValid()} onClick={() => sendMessage()}>Send</button>*
            <Button className="btn-message-send" onClick={() => sendMessage()}>Send</Button>
        </div>
        */}
        {errorMessage && <p className="oscar-lte-errormsg" dangerouslySetInnerHTML={{__html: errorMessage}}></p>}

        </>
    }
    </div>      
  );  
}


function ConversationLink(props) {
  const thisLTE = props.data;
  //console.log({thisConversation});

  let strClass = "v3-conversation-box lte";
  /*if (thisConversation.new_for_you){
    strClass+=" has-unread"
  }*/
  

/* 
        'start_date',
            'start_time',
            'duration',
            'comment',
            'weekly_rate',
            'day_monday',
            'day_tuesday',
            'day_wednesday',
            'day_thursday',
            'day_friday',
            'day_saturday',
            'day_sunday'

*/

  let strDays = '';
  if (thisLTE.day_monday){strDays+='Mondays, ';}
  if (thisLTE.day_tuesday){strDays+='Tuesdays, ';}
  if (thisLTE.day_wednesday){strDays+='Wednesdays, ';}
  if (thisLTE.day_thursday){strDays+='Thursdays, ';}
  if (thisLTE.day_friday){strDays+='Fridays, ';}
  if (thisLTE.day_saturday){strDays+='Saturdays, ';}
  if (thisLTE.day_sunday){strDays+='Sundays, ';}

  if (strDays.length > 2){
    strDays = strDays.substr(0, strDays.length - 2);
  }

  // onClick={() => props.history.push(`/longtermenquiries/${thisConversation.uuid}/`)}
  return (
  <div className={strClass}>
      <div>
        {thisLTE.parking_space && <div className="v3-spotdescription">{thisLTE.parking_space.address}</div>}
        {thisLTE.parking_bay && <div className="v3-spotdescription">{thisLTE.parking_bay.building.building_name} - {thisLTE.parking_bay.building.address} - Bay#{thisLTE.parking_bay.bay_marking}</div>}
        <div className="v3-spot2"><b>FROM:</b> {thisLTE.user} - {moment(thisLTE.create_date).format("ddd D MMM, h:mm a")}</div>
        <div className="v3-spot2"><b>OWNER:</b> {thisLTE.user_owner}</div>

        <div className="v3-starting"><b>Starting:</b> {moment(`${thisLTE.start_date} ${thisLTE.start_time}`).format("dddd, D MMMM YYYY")}</div>
        <div className="v3-starting"><b>Duration:</b> {thisLTE.duration}</div>
        <div className="v3-starting"><b>Days:</b> {strDays}</div>

        <div className="v3-comment">{thisLTE.comment}</div>


      </div>
  </div>
  );
}

function ConversationThread(props) {
  const thisThread = props.data;
  const thisConversation = props.conversation;
  //console.log({thisConversation});

  if (thisThread.which_user === 1){
    return (
      <div className="v3-ct-box-sender">
          <img src={`https://images.sharewithoscar.com.au/${thisConversation.sender_photo_url}?tr=w-60,h-60,fo-auto`} alt="User" style={{width: '60px', height: '60px', borderRadius: '40px', marginTop: '5px'}}></img>
          <div className="v3-content"><p>{thisThread.content}</p><span>{moment(thisConversation.create_date).format("ddd D MMM, h:mm a")}</span></div>
      </div>
      );
    
  } else {
    return (
      <div className="v3-ct-box-recipient">
          <div className="v3-content"><p>{thisThread.content}</p><span>{moment(thisConversation.create_date).format("ddd D MMM, h:mm a")}</span></div>
          <img src={`https://images.sharewithoscar.com.au/${thisConversation.recipient_photo_url}?tr=w-60,h-60,fo-auto`} alt="User" style={{width: '60px', height: '60px', borderRadius: '40px', marginTop: '5px'}}></img>
      </div>
      );
  }

}