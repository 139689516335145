import React, {Component} from 'react';

import {
    Grid,
    Typography,
    InputLabel,
    FormControl,
    Button,
} from '@material-ui/core/';

import Loader from "../components/Loader";
import Modal from 'react-responsive-modal';

import { uploadImages, getParkingSpace } from '../api/Oscar-api';

import { isNativeWebview } from '../utils/IsNativeWebview.js';

const styles = {
    progressContainer: {
        padding: '16px',
    },
    previewImage: {
        width: '100%',
        height: '100px',
    },
    imageSet: {
        marginTop: '20px'
    },
    finishButton: {
        marginTop: '20px',
    },
    upload: {
        marginTop: '10px',
    },
    fileInput: {
        display: 'none'
    }
}


export default class SpacePictures extends Component {
    state = {
        imageSet: [
            "https://www.sharewithoscar.com.au/assets/graphics/image_placeholder.png",
            "https://www.sharewithoscar.com.au/assets/graphics/image_placeholder.png",
            "https://www.sharewithoscar.com.au/assets/graphics/image_placeholder.png",
        ],
        fileSet: [],
        loading: false,
    }

    componentWillMount() {
        this.setState({loading: true})
        getParkingSpace({}, this.props.match.params.uuid).then(response =>{
            let images = this.state.imageSet
            response.data.image_set.forEach((image, i) => {
                images[i] = image.path
            })
            this.setState({
                loading: false,
                imageSet: images
            })
        })
    }

    onFileChange = (e, file, index) => {
        file = file || e.target.files[0]
        let reader = new FileReader();
        
        reader.onload = (e) => {
            let imgs = this.state.imageSet
            let files = this.state.fileSet
            imgs[index] = reader.result
            files[index] = file
            this.setState({ 
                imageSet: imgs, 
                fileSet: files
            }); 
        }
        try {
            reader.readAsDataURL(file);
        } catch (e) {
            void(0);
        }
    }

    handleSubmit = () => {
        if (this.state.fileSet.length === 0) {
            this.props.history.push('/Listings')
        }
        this.setState({loading: true})
        // submit all non-placeholder images
        let imgUploads = []
        this.state.fileSet.forEach(img => {
            imgUploads.push(img)
        })

        uploadImages(imgUploads, this.props.match.params.uuid).then(response => {
            //console.log('yay')
            this.props.history.push('/Listings')
        }).catch(error => {
            console.log(error.response)
        })
    }

    handlePickerClick = (event, index) => {
        if (isNativeWebview()) {
            event.preventDefault()
            event.stopPropagation()

            // Send the postMessage to the native side saying we want to log in through google
            window.ReactNativeWebView.postMessage(JSON.stringify({action: "image"}));
            // Set an onMessage listener to away the response token
            window.addEventListener("message", this.handleNativeResponse )

            this.setState({
                loading: true,
                picked: index,
            })
        }
    }
    handleNativeResponse = (event) => {
        // Process the response token as normal with our backend
        const nativeResponse = JSON.parse(event.data);
        if (nativeResponse.message === "image_upload") {
            let imgs = this.state.imageSet
            let files = this.state.fileSet
            imgs[this.state.picked] = 'data:image/jpeg;base64,' + nativeResponse.image_data
            files[this.state.picked] = b64toBlob(nativeResponse.image_data)
            this.setState({ 
                imageSet: imgs, 
                fileSet: files,
            }); 
        }
        this.setState({ loading: false})
        window.removeEventListener("message", this.handleNativeResponse);
    }

    render() {
        const ImageUploadComponent = (props) => (
            <Grid style={styles.imageSet} container spacing={8}>
                <Grid item xs={6}>
                    <img src={props.imageSrc} style={styles.previewImage} alt="Upload preview"/>
                </Grid>
                <Grid item xs={6} onClick={props.onClick}>
                    <Typography variant="body1">
                        {props.description}
                    </Typography>
                    <Button 
                        variant="outlined" 
                        size="small" 
                        style={styles.upload}
                    >
                        <label>
                            Choose image
                            <input 
                                style={styles.fileInput} 
                                type="file" 
//                                accept="image/*"
                                onChange={(e, file) => props.onFileChange(e, file, props.index)} 
                                
                            />
                        </label>
                    </Button>
                </Grid>
            </Grid>
        )

        return(
            <div>
                <Modal open={this.state.loading} onClose={() => {void(0);}} center showCloseIcon={false}>
                    <Loader loading={this.state.loading} />
                </Modal>
                <Typography gutterBottom variant="display1"  color="primary">
                    My Spot
                </Typography>
                <FormControl fullWidth>
                    <InputLabel shrink>Photos</InputLabel>
                    <Typography style={{marginTop: '20px'}} gutterBottom variant="body1" >
                        Add three photos of your spot
                    </Typography>
                </FormControl>
                <Grid container>
                    <ImageUploadComponent 
                        index={0}
                        imageSrc={this.state.imageSet[0]}
                        onFileChange={this.onFileChange}
                        description="A general photo of the parking spot"
                        onClick={(evt) => this.handlePickerClick(evt, 0)}
                    />
                    <ImageUploadComponent
                        index={1}
                        imageSrc={this.state.imageSet[1]}
                        onFileChange={this.onFileChange}
                        description="A photo of the parking spot from the street"
                        onClick={(evt) => this.handlePickerClick(evt, 1)}
                    />
                    <ImageUploadComponent 
                        index={2}
                        imageSrc={this.state.imageSet[2]}
                        onFileChange={this.onFileChange}
                        description="A photo showing access to the parking spot"
                        onClick={(evt) => this.handlePickerClick(evt, 2)}
                    />
                </Grid>

                <Button fullWidth variant="contained" color="primary"
                    onClick={this.handleSubmit}
                    style={styles.finishButton}
                >
                    Finish
                </Button>
            </div>
        )
    }
}

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}