import React, {Component} from 'react';
import moment from 'moment';
import ImageCompressor from 'image-compressor.js';

import {
    createAvailability,
    createParkingSpace,
    updateParkingSpace,
    uploadImages,
} from '../api/Oscar-api.js';
import { isNativeWebview } from '../utils/IsNativeWebview.js';

import {
    QueryParamDate,
    QueryParamTime
} from '../utils/TimeFormats.js';

import {checkUserNotificationPermissions} from '../utils/PushNotification.js';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Typography
} from '@material-ui/core';
import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import IconPlus from '@material-ui/icons/Add';
import IconDelete from '@material-ui/icons/Close';

import CRUDForm from '../components/CRUDForm.js';

import SelectField from '../components/forms/SelectField';

import GooglePlacesField from '../components/forms/GooglePlacesField.js';
import HiddenField from '../components/forms/HiddenField.js';
import NumberField from '../components/forms/NumberField.js';
import SliderSwitch from '../components/forms/SliderSwitch.js';
import WeekDaySelector from '../components/forms/WeekDaySelector.js';
import Calendar from '../components/Calendar.js';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';
import LoadingModal from '../components/LoadingModal.js';

import { updateUserProfileAjax } from '../utils/Authentication.js';

const styles = {
    topBar: {
        position: 'absolute',
        zIndex: '2',
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '40px',
        backgroundColor: 'white',
    },
    formContainer: {
        padding: '16px',
        paddingTop: '80px'
    },
    leftTopButton :{
        marginTop: '-5px',
        position: 'absolute',
        left: 5,
    },
    rightTopButton: {
        position: 'absolute',
        right: 5
    },
    nextButton: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },
    switchBorder: {
        borderTop: '1px solid #efefef',
        borderBottom: '1px solid #efefef',
        marginTop:'50px'

    },
    daysControl: {
        marginTop: '16px',
        marginBottom: '10px',
    },
    timeControls: {        
        borderTop: '1px solid #efefef',
        paddingTop: '12px',
        paddingBottom: '12px'
    },
    calendarContainer: {
        margin: '16px 0px',
        width: '100%', 
        borderTop: '1px solid #efefef'
    },
    fileInput: {
        display: 'none'
    },
    uploadImagePreview: {
        width: '110px',
        height: '70px',
    },
    uploadContainer: {
    },
    skipPhotos: {
        textTransform: 'none',
        border: '1px solid #1AA5A2'
    },
    removeImage: {
        position: 'absolute',
        backgroundColor: 'white',
        height: '24px',
    }
}

const MAX_IMAGE_UPLOAD_SIZE = 1000000

function generateTemporaryLocalUuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return "TEMPORARY_LOCAL_" + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function imagesFromListing(listing) {
    let images = {'general': [], 'street': [], 'access': []}

    for (let i = 0; i < listing.image_set.length; i++ ) {
        if (listing.image_set[i].image_type === "General") {
            images['general'].push({preview: listing.image_set[i].path})
        }
        if (listing.image_set[i].image_type === "Access") {
            images['access'].push({preview: listing.image_set[i].path})
        }
        if (listing.image_set[i].image_type === "Street") {
            images['street'].push({preview: listing.image_set[i].path})
        }
    }

    return images
}

const defaultHourly = 4;
//const defaultWeekly = 50;
const defaultMonthly = 200;
const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export default class ListSpace extends Component {
    constructor(props) {
        super(props)
        this._endPoint = createParkingSpace
        this._hydrateForm = undefined
        this._creating = true
        this._firstTime = props.match.params.first === "first"
        this._requestPushPermissions = checkUserNotificationPermissions()

        if (props.location.state) {
            if (props.location.state.hydrateState) {
              //console.log("X1");
                this.state = {
                    ...props.location.state.hydrateState.local,
                    startTime:  moment("7:00 AM", 'h:mm A'),
                    endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                }
                this._hydrateForm = props.location.state.hydrateState.form
            } else if (props.location.state.completeListing) {
              //console.log("X2");
                this._creating = false
                this.state = {
                    currentUuid: props.location.state.completeListing.uuid,
            
                    loading: false,
                    activePage: props.location.state.completeListing.availabilityrecurring_set.length > 0 ? 5 : 3,
                    pageHistory: [],
                    oscarRates: props.location.state.completeListing.uuid,
                    auto_approve: true, // Default true for new listing
                    allow_casual: true, // Default true for new listing
                    allow_monthly: true, // Default true for new listing
                    alwaysAvailable: true,
                    repeatWeekly: true,
            
                    rate_hourly: defaultHourly,
                    //rate_weekly: defaultWeekly,
                    rate_monthly: defaultMonthly,
                    selectedDays: [false, false, false, false, false, false, false],
                    startTime: moment("7:00 AM", 'h:mm A'),
                    endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                    
                    previewImages: {"general": [], "street": [], "access": []},
                    imageFiles: {"general": [], "street": [], "access": []},
                    deletedImages: [],

                    spaceImages: {"general": [], "street": [], "access": []}
                }                
            } else if (props.location.state.editListing) {
              //console.log("X3");

              // Insert these slider values based on supplied rates

              //console.log(props.location.state.editListing);
                this._hydrateForm = {values: props.location.state.editListing, errors:{}}
                this._endPoint = updateParkingSpace
                this._editUuid = props.location.state.editListing.uuid
                this._creating = false

                const imageObject = imagesFromListing(props.location.state.editListing)

                this.state = {
                    currentUuid: props.location.state.editListing.uuid,
            
                    loading: false,
                    activePage: 0,
                    pageHistory: [],
                    oscarRates: props.location.state.editListing.price_by_oscar,
                    auto_approve: props.location.state.editListing.auto_approve,
                    allow_casual: props.location.state.editListing.allow_casual,
                    allow_monthly: props.location.state.editListing.allow_monthly,

                    alwaysAvailable: true,
                    repeatWeekly: true,
            
                    rate_hourly: defaultHourly,
                    //rate_weekly: defaultWeekly,
                    rate_monthly: defaultMonthly,
                    selectedDays: [false, false, false, false, false, false, false],
                    startTime: moment("7:00 AM", 'h:mm A'),
                    endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                    
                    previewImages: imageObject,
                    imageFiles: {"general": [], "street": [], "access": []},
                    deletedImages: [],

                    spaceImages: imageObject
                }
            }
        } else {
          console.log("X4");
            this.state = {
                currentUuid: generateTemporaryLocalUuid(),
        
                loading: false,
                activePage: 0,
                pageHistory: [],
                oscarRates: true,
                auto_approve: true, // Default true for new listing??????
                allow_casual: true, // Default true for new listing
                allow_monthly: true, // Default true for new listing

                alwaysAvailable: true,
                repeatWeekly: true,
        
                rate_hourly: defaultHourly,
                //rate_weekly: defaultWeekly,
                rate_monthly: defaultMonthly,
                selectedDays: [false, false, false, false, false, false, false],
                startTime: moment("7:00 AM", 'h:mm A'),
                endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                
                previewImages: {"general": [], "street": [], "access": []},
                imageFiles: {"general": [], "street": [], "access": []},
                deletedImages: [],

                spaceImages: {"general": [], "street": [], "access": []}
            }    
        }
    }

    checkValidRate(rate) {
        return !isNaN(rate) && (parseFloat(rate) >= 0)
    }
    checkPageReady = () => {
      //console.log("checkPageReady()");
      let p1Ready = false
        let p2Ready = false
        let p3Ready = true; // do this in reverse
        const formValues = this._mainForm.getState().values
        //console.log({formValues});

        if (formValues.address !== undefined &&
            formValues.type !== undefined &&
            formValues.vehicle_type !== undefined) {
            p1Ready = true
        }
        if (formValues.description !== undefined &&
            formValues.instruction !== undefined) {
            p2Ready = true
        }
        // New code to allow auto_approve, casual and monthly rate rules
        /*console.log("auto_approve:",this.state.auto_approve); // have to watch for state change
        console.log("allow_casual:",this.state.allow_casual);
        console.log("allow_monthly:",this.state.allow_monthly);
        console.log("hourly:",formValues.rate_hourly);
        console.log("monthly:",formValues.rate_monthly);*/

        // Must allow at least casual or monthly
        // FORMVALUES!
/*        if (!this.state.allow_casual && !this.state.allow_monthly){
          p3Ready = false;
        }
        // Check for missing casual rate if allowed
        if (this.state.auto_approve && this.state.allow_casual && !this.checkValidRate(formValues.rate_hourly)){
          p3Ready = false;
        }
        // Check for missing monthly rate if allowed
        if (this.state.allow_monthly && !this.checkValidRate(formValues.rate_monthly)){
          p3Ready = false;
        }
*/
        if (!formValues.allow_casual && !formValues.allow_monthly){
          p3Ready = false;
        }
        if (!formValues.auto_approve && !formValues.allow_monthly){
          p3Ready = false;
        }
        // Check for missing casual rate if allowed
        if (formValues.auto_approve && formValues.allow_casual && !this.checkValidRate(formValues.rate_hourly)){
          p3Ready = false;
        }
        // Check for missing monthly rate if allowed
        if (formValues.allow_monthly && !this.checkValidRate(formValues.rate_monthly)){
          p3Ready = false;
        }


        /*if (this.checkValidRate(formValues.rate_hourly) && 
            this.checkValidRate(formValues.rate_monthly)) {
            //p3Ready = true;
            //console.log("p3 test result:",p3Ready);

        }*/

        this.setState({
          checkPageReadyFlag:false,
            p1Ready: p1Ready,
            p2Ready: p2Ready,
            p3Ready: p3Ready,
        })
    }

    componentDidMount() {
        this.checkPageReady()
    }
    /*componentDidUpdate(){
      console.log("componentDidUpdate()");
      if (this.state.checkPageReadyFlag){
        console.log("checkPageReady");
        //this.checkPageReady();

      }
    }*/


    handleNext = () => {
      //console.log("handleNext");
      const active = this.state.activePage
        const history = this.state.pageHistory
        if (active === 5) {
            // special case for getting to the end
        } else {
          history.push(active)
            this.setState({
                pageHistory: history,
                activePage: active + 1
            })
        }
    }
    handleBack = () => {
        if (this.state.activePage === 3) {
            this.props.history.push('/Listings')
            return
        }
        if (this.state.pageHistory.length === 0) {
            this.props.history.goBack()
        } else {
            const history = this.state.pageHistory
            const page = history.pop()
            this.setState({
                pageHistory:  history,
                activePage: page
            })
        } 
    }
    handleExit = () => {
        // Save the current data to local storage.
        //const stateSnapshot = {local: this.state, form: this._mainForm.getState()}
        let myState = this._mainForm.getState();
        myState.formInputs = null;  //remove formInputs - can't be saved with JSON
              
        const stateSnapshot = {local: this.state, form: myState}
        //console.log("snapshot:",stateSnapshot);
        // Multiple users same browser...... have to make sure these saved spots are destroyed with the user creds
        let localData = localStorage.getItem("LOCAL_DATA")
        //console.log("localData=",localData);
        if (localData) {
            //console.log('local data found')
            localData = JSON.parse(localData)
            if (!('LISTINGS_IN_PROGRESS' in localData)) {
                //console.log('creating listings key in local data')
                localData['LISTINGS_IN_PROGRESS'] = {}
            }
            if (!(this.state.currentUuid in localData['LISTINGS_IN_PROGRESS'])) {
                localData['LISTINGS_IN_PROGRESS'][this.state.currentUuid] = undefined
            }
            localData['LISTINGS_IN_PROGRESS'][this.state.currentUuid] = stateSnapshot
        } else {
            //console.log('local data not found, creating new object')
            localData = {}
            localData['LISTINGS_IN_PROGRESS'] = { [this.state.currentUuid]: stateSnapshot }
            //console.log("new localData:",localData);
        }
        //console.log('saving progress to local data')
        localStorage.setItem("LOCAL_DATA", JSON.stringify(localData))

        this.props.history.push('/Listings')
    }
    handleError =  (error) => {
        console.log(error.response)
        if ('address' in error.response.data || 
            'latitude' in error.response.data || 
            'type' in error.response.data ||
            'street_name' in error.response.data ||
            'vehicle_type' in error.response.data) {
            this.setState({activePage: 0})
        } else if ('description' in error.response.data ||
                   'instruction' in error.response.data) {
            this.setState({activePage: 1})
        } else {
            this.setState({activePage: 2})
        }
    }
    handleDaySelect = (index) => {
        let actives = this.state.selectedDays;
        actives[index] = !actives[index]
        this.setState({selectedDays: actives})
    }
    handleTimeRangeSelected = (start, end) => {
        let newStart = moment(this.state.startTime)
        let newEnd = moment(this.state.endTime)

        newStart.hour(start.hour())
        newStart.minute(start.minute())
        newEnd.hour(end.hour())
        newEnd.minute(end.minute())

        this.setState({startTime: newStart, endTime: newEnd})
    }
    handleToggleRepeatWeekly = () => {
        if (this.state.repeatWeekly) {
            this.setState({
                repeatWeekly: false,
                endTime: moment(this.state.endTime).subtract(100, 'years')
            })
        } else {
            this.setState({
                repeatWeekly: true,
                endTime: moment(this.state.endTime).add(100, 'years')
            })
        }
    }
    handleCreateAvailability = () => {
        if (this.state.alwaysAvailable) {
            let params = {
                start_date: moment().format(QueryParamDate),
                cease_date: moment().add(100, 'years').format(QueryParamDate),
                start_time: "00:00",
                cease_time: "23:59",
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                day_monday: true,
                day_tuesday: true,
                day_wednesday: true,
                day_thursday: true,
                day_friday: true,
                day_saturday: true,
                day_sunday: true,
            }

            this.setState({loading: true})
            createAvailability(params, this.state.currentUuid).then(() => {
                // Skip the calendar and go straight to images
                this.setState({
                    loading: false,
                    activePage: 5
                })
            })
        } else {
            let params = {
                start_date: this.state.startTime.format(QueryParamDate),
                start_time: this.state.startTime.format(QueryParamTime),
                cease_date: this.state.endTime.format(QueryParamDate),
                cease_time: this.state.endTime.format(QueryParamTime),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }
            for (let i = 0; i < this.state.selectedDays.length; i++) {
                params['day_' + weekdays[i]] = this.state.selectedDays[i]
            }

            let hist = this.state.pageHistory
            hist.push(3)
            this.setState({
                availability: params,
                pageHistory: hist,
                activePage: 4 
            })
        }
    }
    handleCreatePartialAvailability = () => {
        this.setState({loading: true})
        createAvailability(this.state.availability, this.state.currentUuid).then(() => {
            let hist = this.state.pageHistory
            hist.pop()
            this.setState({
                pageHistory: hist,
                loading: false,
                activePage: 5
            })
        })
    }
    
    handleImagePickerClick = (event, imageType) => {
        if (isNativeWebview()) {
            event.preventDefault()
            event.stopPropagation()

            // Send the postMessage to the native side saying we want to log in through google
            window.ReactNativeWebView.postMessage(JSON.stringify({action: "image"}));
            if (window.document.isAndroid) {
                window.document.addEventListener("message", this.handleNativeResponse )
            } else {
                window.addEventListener("message", this.handleNativeResponse )
            }

            this.setState({
                loading: true,
                pickingImageType: imageType,
            })
        }        
    }
    handleNativeResponse = (event) => {
        // Process the response token as normal with our backend
        const nativeResponse = JSON.parse(event.data);
        if (nativeResponse.message === "image_upload") {
            this.compressAndSaveImage(b64toBlob(nativeResponse.image_data), this.state.pickingImageType, .8)
        }
        if (nativeResponse.message === "image_error") {
            // handle image errors here.
            this.setState({imageAddError: true})
        }
        this.setState({ loading: false})
        window.removeEventListener("message", this.handleNativeResponse);
    }

    compressAndSaveImage = (file, imageType, quality) => {
        // console.log(file.size)
        if (file.size < 1000) {
            this.setState({imageAddError: true, loading:false})
            return
        }
        //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: file.size}));
        if (file.size < MAX_IMAGE_UPLOAD_SIZE) {
            this.saveImage(file, imageType)
            return
        }

        //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "compressing"}));
        new ImageCompressor(file, {
            quality: quality,
            checkOrientation: false,
            width: 800,

            success: (result) => {      
                if (result.size < MAX_IMAGE_UPLOAD_SIZE) {
                    //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "compressing"}));
                    this.saveImage(result, imageType)
                }    
                else {
                    this.compressAndSaveImage(result, imageType, quality / 1.5)
                }            
            },
            error: (e) => {
                console.log(e.message);
                //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: e.message}));
                // handle image errors here.
                this.setState({imageAddError: true, loading:false});
            },
        });
    }

    saveImage = (result, imageType) => {
        const reader = new FileReader()
        //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "saving"}));
        reader.onload = () => {
            let imgs = this.state.spaceImages
            imgs[imageType].push({preview: reader.result, file: result}) 
            this.setState({ 
                spaceImages: imgs,
                loading: false,
                imageAddError: false,
            }); 
        }
        try {
            reader.readAsDataURL(result);
        } catch (e) {
            void(0);
        }    
    }

    handleFileChange = (e, file, imageType) => {
        file = file || e.target.files[0]

        if (file.type === 'image/jpeg' || file.type === 'image/png'){
            this.setState({loading: true});
            //TODO: AWS Lambda will compress and resize automatically later
            this.compressAndSaveImage(file, imageType, .8);
    
        } else {
            this.setState({imageAddError: true, loading:false});
        }
        
    }

    handleSkipPhotos = () => {
        this.finishFlow()
    }
    handleSavePhotos = () => {
        this.setState({loading: true})

        uploadImages(this.state.spaceImages, this.state.deletedImages, this.state.currentUuid).then(response => {
            //console.log('yay')
            this.finishFlow()
        }).catch(error => {
            console.log(error.response)
        })
    }
    handleRemoveImage = (imageType, imageIndex) => {
        let previews = this.state.spaceImages
        let img = previews[imageType].splice(imageIndex, 1)

        let deletedImages = this.state.deletedImages
        if (img[0].file === undefined) {
            deletedImages.push(img[0].preview.replace("https://www.sharewithoscar.com.au/images/", ""))
        }

        this.setState({
            spaceImages: previews,
            deletedImages: deletedImages
        })
    }

    finishFlow = () => {
        if (this._firstTime) {
            // Show the bell
            this.props.history.push('/FirstListing')
        } else if (this._requestPushPermissions) {
            // Show the push prompt
            this.props.history.push('/PermissionsPush')
        } else {
            this.props.history.push('/Listings')
        }
    }


    // Shared toggle code - checkPageReadyFlag:true will trigger ONE execution, easier than managing the form
    toggleSlider = (val) => {
      if (val === 'auto_approve'){
        this._toggleSliderAuto.reportChange(!this.state.auto_approve);
        this.setState({auto_approve:!this.state.auto_approve, checkPageReadyFlag:true});
      } else if (val === 'allow_casual'){
        this._toggleSliderCasual.reportChange(!this.state.allow_casual);
        this.setState({allow_casual:!this.state.allow_casual, checkPageReadyFlag:true});
      } else if (val === 'allow_monthly'){
        this._toggleSliderMonthly.reportChange(!this.state.allow_monthly);
        this.setState({allow_monthly:!this.state.allow_monthly, checkPageReadyFlag:true});
      }

    }

    handleCreateSuccess = (response) => {
        // Delete this temporary listing from local storage if its saved there
        let localData = localStorage.getItem("LOCAL_DATA")
        if (localData) {
            localData = JSON.parse(localData)
            if ('LISTINGS_IN_PROGRESS' in localData) {
                if (this.state.currentUuid in localData['LISTINGS_IN_PROGRESS']) {
                    delete localData['LISTINGS_IN_PROGRESS'][this.state.currentUuid]
                    localStorage.setItem("LOCAL_DATA", JSON.stringify(localData))
                }    
            }
        }
        let hist = this.state.pageHistory
        hist.push(2)
        this.setState({
            pageHistory: hist,
            activePage: this._creating ? 3 : 5,
            currentUuid: response.data.uuid
        })

        updateUserProfileAjax().then(response => {
            this.props.authChange()
        })
    }

    
    render() {
        const ImageUpload = (props) => (
            <Grid style={{marginBottom: '20px'}} container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="body1">
                        {props.description}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton
                    onClick={(evt) => this.handleImagePickerClick(evt, props.imageType)}>
                        <label>
                            <IconPlus color="primary" />
                            <input 
                                style={styles.fileInput} 
                                type="file" 
//                                accept="image/*"
                                onChange={(e, file) => this.handleFileChange(e, file, props.imageType)} 
                                
                            />
                        </label>
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    {this.state.spaceImages[props.imageType].map((image, i) => {
                        return(
                            <div key={i} style={{display: 'inline-block', position: 'relative'}}>
                                <div style={styles.removeImage} onClick={() => this.handleRemoveImage(props.imageType, i)}>
                                    <IconDelete />
                                </div>
                                <img style={styles.uploadImagePreview} src={image.preview} key={i} alt={props.description}/>
                            </div>
                        )
                    })}
                </Grid>
            </Grid>
        )        

        //console.log(this.state);
        return(
            <div style={{height: '100%', overflowY: 'auto'}}>
                <LoadingModal open={this.state.loading} />

                <div style={styles.topBar}>
                    <LinearProgress variant="determinate" value={this.state.activePage * 15 + 10} />
                    <IconButton onClick={this.handleBack} style={styles.leftTopButton}>
                        <IconBack />
                    </IconButton>

                    {/*this._creating && <Button style={styles.rightTopButton} onClick={this.handleExit}>
                        Save &amp; Exit
        </Button>*/}
                </div>

                <div>
                    <div style={this.state.activePage < 3 ? {display: 'block'} : {display:'none'}}>
                        <div style={styles.formContainer}>
                            <CRUDForm
                            endpoint={this._endPoint}
                            uuid={this._editUuid}
                            onChange={this.checkPageReady}
                            onError={this.handleError}
                            onSuccess={this.handleCreateSuccess}
                            ref={(component) => {this._mainForm = component}}
                            hydrateFromObject={this._hydrateForm}
                        >
                            <div style={this.state.activePage === 0 ? {display: 'block'} : {display:'none'} }>
                                <Typography variant="display1" color="primary">
                                    Tell us about your parking spot
                                </Typography>
                                <FormControl fullWidth>
                                    <GooglePlacesField
                                        name="address"
                                        label="Address"
                                        desktopDisplay={!isNativeWebview()}
                                    >
                                        <HiddenField name="street_name"/>
                                        <HiddenField name="latitude"/>
                                        <HiddenField name="longitude"/>
                                        <HiddenField name="postcode"/>
                                        <HiddenField name="state"/>
                                    </GooglePlacesField>
                                </FormControl>
                                <FormControl fullWidth >
                                    <SelectField 
                                        label="Type of spot" 
                                        name="type"
                                        placeHolder="What type of spot do you have?"
                                        options={["Carport", "Driveway", "Garage", "Parking-lot"]}
                                    />
                                </FormControl>
                                <FormControl fullWidth >
                                    <SelectField 
                                        label="Maximum size" 
                                        name="vehicle_type"
                                        placeHolder="How big is your spot?"
                                        options={["Bike", "Hatchback", "Sedan", "4WD/SUV", "Van"]}
                                    />
                                </FormControl>
                                <FormControl fullWidth >
                                    <SelectField 
                                        label="Access Type" 
                                        name="access_method"
                                        nullable={true}
                                        nullOption="None"
                                        options={["Key", "Remote / Swipe Card", "Ticket / Permit", "Passcode", "Boom Gate"]}
                                    />
                                </FormControl>
                                <FormControl fullWidth>
                                    <SelectField 
                                        label="Electric Charging" 
                                        name="electric_charge_type"
                                        nullable={true}
                                        nullOption="None"
                                        options={["Wall (AU/NZ)", "Type 1 (SAE J-1772)", "Type 2", "CHAdeMO"]}
                                    />
                                </FormControl>
                                <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
                                    <Button color="primary" variant="contained"
                                    onClick={this.handleNext} 
                                    disabled={!this.state.p1Ready}>
                                        Next
                                        <IconForward />
                                    </Button>
                                </div>
                            </div>
                            <div style={this.state.activePage === 1 ? {display: 'block'} : {display:'none'} }>
                                <Typography variant="display1" color="primary">
                                    Describe your parking spot
                                </Typography>
                                <TextField
                                    multiline
                                    rows={3}
                                    label="Description"
                                    name="description"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Describe what makes your spot special (eg. nearby locations, convenience)."
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    multiline
                                    rows={3}
                                    label="Access instructions"
                                    name="instruction"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Describe how to access your spot. This will be hidden until a booking is made."
                                    fullWidth
                                    margin="normal"
                                />
                                <Button style={styles.nextButton} color="primary" variant="contained"
                                onClick={this.handleNext}
                                disabled={!this.state.p2Ready}>
                                        Next
                                    <IconForward />
                                </Button>
                            </div>
                            <div style={this.state.activePage === 2 ? {display: 'block'} : {display:'none'} }>
                                <Typography variant="display1" color="primary">
                                    Price your spot
                                </Typography>
                                {/*<Grid style={styles.switchBorder} container justify="space-between" alignItems="center">
                                    <Grid>
                                        <Typography variant="body1">
                                            Let Oscar set my rates
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <SliderSwitch checked={this.state.oscarRates} onChange={this.toggleOscarRates} />
                                        <HiddenField name="price_by_oscar" value={this.state.oscarRates}
                                        ref={(c) => this._toggleOscarPrice = c}/>
                                    </Grid>
                                  </Grid>*/}

                                <Grid>
                                  <Grid>
                                    <SliderSwitch checked={this.state.auto_approve} onChange={this.toggleSlider.bind(this,'auto_approve')} />
                                    <span style={{color:'rgba(0,0,0,0.54)',fontWeight:'700'}}>INSTANTLY ACCEPT BOOKING REQUESTS</span>
                                    <p style={{marginTop:'0'}}>Accept Instant Bookings to increase your earnings and chances of your parking space being booked. If instant bookings are not allowed, you will need to accept each booking that comes through.</p>
                                    <HiddenField name="auto_approve" value={this.state.auto_approve} ref={(c) => this._toggleSliderAuto = c}/>

                                  </Grid>
                                </Grid>


                                {!this.state.auto_approve && <div>Casual bookings are only allowed if you enable Instant Bookings</div>}
                                {this.state.auto_approve &&
                                <Grid style={{marginTop:'30px'}}>
                                  <Grid>
                                    <SliderSwitch checked={this.state.allow_casual} onChange={this.toggleSlider.bind(this,'allow_casual')} />
                                    <span style={{color:'rgba(0,0,0,0.54)',fontWeight:'700'}}>ALLOW CASUAL BOOKINGS</span>
                                    <HiddenField name="allow_casual" value={this.state.allow_casual} ref={(c) => this._toggleSliderCasual = c}/>
                                    
                                  <div style={this.state.allow_casual ? {display:'block'} : {display: 'none'} }>
                                    <FormControl fullWidth style={{marginTop:'20px'}}>
                                        <NumberField 
                                            label="Hourly Rate"
                                            name="rate_hourly"
                                            value={this.state.rate_hourly}
                                            showDaily={true}
                                        />
                                    </FormControl>
                                  </div>

                                  </Grid>
                                </Grid>
                                }

                                <Grid style={{marginTop:'30px'}}>
                                  <Grid>
                                    <SliderSwitch checked={this.state.allow_monthly} onChange={this.toggleSlider.bind(this,'allow_monthly')} />
                                    <span style={{color:'rgba(0,0,0,0.54)',fontWeight:'700'}}>ALLOW MONTHLY BOOKINGS</span>
                                    <HiddenField name="allow_monthly" value={this.state.allow_monthly} ref={(c) => this._toggleSliderMonthly = c}/>
                                  
                                    <div style={this.state.allow_monthly ? {display:'block'} : {display: 'none'} }>
                                      <FormControl fullWidth style={{marginTop:'20px'}}>
                                          <NumberField 
                                              label="Monthly rate"
                                              name="rate_monthly"
                                              value={this.state.rate_monthly}
                                          />
                                      </FormControl>
                                      </div>
                                  </Grid>
                                </Grid>


                                <Button style={styles.nextButton} color="primary" variant="contained"
                                id={this.props.location.state && this.props.location.state.editListing ? "listing_edit" : "listing_create"}
                                type="submit"
                                onClick={this.handleNext}
                                disabled={!this.state.p3Ready}>
                                    Next
                                    <IconForward />
                                </Button>
                            </div>
                        </CRUDForm>
                        </div>
                    </div>
                    <div style={this.state.activePage === 3 ? {display: 'block'} : {display:'none'}}>
                        <div style={styles.formContainer}>
                            <div>
                                <Typography variant="display1" color="primary">
                                    Availability
                                </Typography>
                                <Typography variant="body1" color="primary">
                                    When is your spot available?
                                </Typography>
                                <Grid style={styles.switchBorder} container justify="space-between" alignItems="center">
                                    <Grid>
                                        <Typography variant="body1">
                                            24/7
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <SliderSwitch checked={this.state.alwaysAvailable} onChange={() => this.setState({alwaysAvailable: !this.state.alwaysAvailable})} />
                                    </Grid>
                                </Grid>
                                {this.state.alwaysAvailable ? null :
                                <div>
                                    <Typography style={styles.daysControl} variant="body1">
                                        Days of the week
                                    </Typography>
                                    <WeekDaySelector selectedDays={this.state.selectedDays} onChange={this.handleDaySelect} />
                                    
                                    <DateTimeRangePicker 
                                        withDate={false}
                                        verticalForm={true}
                                        enforceFuture={false}
                                        start={this.state.startTime}
                                        end={this.state.endTime}
                                        startText="From"
                                        endText="To"
                                        onTimeRangeSelected={this.handleTimeRangeSelected}
                                        disableAutoEditEnd={true}
                                    />
                                    <Grid style={{...styles.timeControls, paddingTop:'4px'}} container justify="space-between" alignItems="center" >
                                        <Grid>Repeat Weekly</Grid>
                                        <Grid>
                                            <SliderSwitch checked={this.state.repeatWeekly} onChange={this.handleToggleRepeatWeekly} />
                                        </Grid>
                                    </Grid>
                                </div>}
                            </div>   
                            <Button style={styles.nextButton} color="primary" variant="contained"
                            onClick={this.handleCreateAvailability} disabled={this.state.alwaysAvailable ? false : this.state.selectedDays.find(elem => elem) ? false : true}>
                                Next
                                <IconForward />
                            </Button>
                        </div>
                    </div>
                    <div style={this.state.activePage === 4 ? {display: 'block'} : {display:'none'}}>
                        <div>
                            <div style={styles.formContainer}>
                                <Typography variant="display1" color="primary">
                                    Calendar
                                </Typography>
                                <Typography variant="body1" >
                                    Review your availabilities and confirm. You can always change this later.
                                </Typography>
                            </div>
                            
                            {this.state.availability && <div style={styles.calendarContainer}>
                                <Calendar 
                                    availabilities={[this.state.availability]}
                                    maxMonthsToDraw={6}
                                    fullSize={true}
                                />
                            </div>}
                        </div>  
                        <Button style={styles.nextButton} color="primary" variant="contained"
                        onClick={this.handleCreatePartialAvailability} >
                            Next
                            <IconForward />
                        </Button>
                    </div>
                    <div style={this.state.activePage === 5 ? {display: 'block'} : {display:'none'}}>
                        <div style={styles.formContainer}>
                            <div>
                                <Typography variant="display1" color="primary">
                                    Add photos of your spot
                                </Typography>
                                <Typography style={{marginBottom: '24px'}} variant="body1" >
                                    Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.
                                </Typography>
                                {this.state.imageAddError && <div style={{padding: '16px', border: '1px solid red', borderRadius: '6px'}}>
                                    <Typography variant="body1">
                                        Unable to add that image. Please check it is not a live photo.
                                    </Typography>
                                </div>}
                                <div style={styles.uploadContainer}>
                                    <ImageUpload description="General photos of your spot" imageType="general"/>  
                                    <ImageUpload description="Photos of the spot from the street" imageType="street"/>  
                                    <ImageUpload description="Photos showing access to the spot" imageType="access"/>  
                                </div>
                            </div>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    {this.state.spaceImages["general"].filter(elem =>{return elem.file}).length > 0 || 
                                    this.state.spaceImages["street"].filter(elem =>{return elem.file}).length > 0 ||
                                    this.state.spaceImages["access"].filter(elem =>{return elem.file}).length > 0 ||
                                    this.state.deletedImages.length > 0
                                    ? <Button color="primary" variant="contained"
                                    onClick={this.handleSavePhotos} >
                                        Next
                                        <IconForward />
                                    </Button>
                                    : <Button style={styles.skipPhotos} color="primary" variant="outlined"
                                    onClick={this.handleSkipPhotos} >
                                        Skip for now
                                        <IconForward />
                                    </Button>}
                                </Grid>
                            </Grid>  
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


export {defaultHourly, defaultMonthly}