import React, { Component } from "react";

import { Button, Typography } from "@material-ui/core";

const styles = {
  rightCornerButton: {
    position: "absolute",
    top: 5,
    right: 5
  },
  alertPage: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  alertImageContainer: {
    flexGrow: 1,
    padding: "16px",
    textAlign: "center",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center"
  },
  alertButton: {
    marginBottom: "16px"
  }
};

export default class PermissionsPrompt extends Component {
  render() {
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="mainContent topPadded" style={styles.alertPage}>
          <Button
            color="primary"
            style={styles.rightCornerButton}
            onClick={this.props.onDismiss}
          >
            Skip
          </Button>
          <Typography
            style={styles.title}
            gutterBottom
            color="primary"
            variant="display1"
          >
            Turn on Location Services ?
          </Typography>
          <Typography variant="body1">
            Let Oscar do all the work, like finding and recommending the best
            parking spots based on your location.
          </Typography>

          <div style={styles.alertImageContainer}>
            <img src="https://www.sharewithoscar.com.au/assets/graphics/location.svg" alt="Permission representation" />
          </div>

          <Button
            style={{ position: "absolute", bottom: "10px", width: "94%" }}
            fullWidth
            color="primary"
            variant="contained"
            onClick={this.props.onAllow}
          >
            Allow
          </Button>
        </div>
      </div>
    );
  }
}
