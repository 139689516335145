import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";

import {
    resetPassword
} from '../api/Oscar-api';

import CRUDForm from "../components/CRUDForm";

import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import { whiteInputStyles } from '../styles/OscarTheme.js';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconClear from '@material-ui/icons/Cancel';
import IconForward from '@material-ui/icons/KeyboardArrowRight';

const styles = {
    noAccount: {
        borderRadius: '32px',
        color: 'white',
        border: '2px solid white',
    },
    rightCornerButton: {
        position: 'absolute',
        top: 10,
        right: 5,
        color: 'white',
    },
    progressButton: {
        backgroundColor: 'white',
        boxShadow: 'none',
    }
}

class ForgotPassword extends Component {
    onSuccess = (response) => {
        this.props.history.push("/ResetConfirmation")
    }

    render() {
        const {classes} = this.props

        return(
            <div className="gradientPage" style={{height: '100%', overflowY: 'auto'}}>
                <div className="mainContent topPadded">
                    <IconButton className="leftCornerButton"
                    component={Link} to="/Login" style={{color: 'white'}}>
                        <IconBack />
                    </IconButton>
                    <Typography gutterBottom style={{color: 'white'}} color="primary" variant="display1">
                        Forgot Password
                    </Typography>
                    <Typography style={{color: 'white', margin: '32px 0px'}} color="primary" variant="body1">
                        Enter the email address you used to sign up below, and we'll send you a link to reset your password.
                    </Typography>
                    <CRUDForm
                        endpoint={resetPassword}
                        onSuccess={this.onSuccess}
                        ref={(component) => {this._mainForm = component}}
                    >
                        <Grid container spacing={24} justify="center">
                            <Grid item xs={12}>
                                <TextField
                                    label="Email Address"
                                    name="username"
                                    type="email"
                                    FormHelperTextProps={{
                                        classes: {
                                            'root': classes.helperText,
                                            'error': classes.helperTextError,
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            'root': classes.input,
                                            'error': classes.inputError,
                                        },
                                        endAdornment: <InputAdornment position="end">
                                                <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'username', value: ''}})} />
                                        </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        classes: {'root': classes.label },
                                        FormLabelClasses: {
                                            root: classes.formLabelRoot,
                                            error: classes.formLabelError,
                                            focused: classes.formLabelFocused,
                                        },
                                        shrink: true,
                                    }}
                                    placeholder="Your email address"
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>

                            <Grid item xs={12} >
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid item>
                                        <Button style={styles.progressButton} variant="fab" type="submit">
                                            <IconForward color="primary"/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CRUDForm>
                </div>
            </div>
        )
    }
}

export default withStyles(whiteInputStyles)(withRouter(ForgotPassword))