import React, { Component } from "react";

import { Button, Typography } from "@material-ui/core";
import { requestPushNotification } from "../utils/PushNotification";

const styles = {
  rightCornerButton: {
    position: "absolute",
    top: 5,
    right: 5
  },
  alertPage: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  alertImageContainer: {
    flexGrow: 1,
    padding: "16px",
    textAlign: "center",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center"
  },
  alertButton: {
    position: "absolute",
    bottom: "10px",
    width: "94%"
  }
};

export default class PermissionsPrompt extends Component {
  handleSkip = () => {
    // Redirect to home page
    const redirect_path = localStorage.getItem("redirect");
    if (redirect_path){
      localStorage.removeItem("redirect");
      this.props.history.push(redirect_path);
      
    } else {
      this.props.history.push("/search");
    }
    
  };

  handleAllow = () => {
    // Ask for push permissions
    requestPushNotification();

    const redirect_path = localStorage.getItem("redirect");
    if (redirect_path){
      localStorage.removeItem("redirect");
      this.props.history.push(redirect_path);
    } else {
      this.props.history.push("/search");
    }
  };

  render() {
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="mainContent topPadded" style={styles.alertPage}>
          <Button
            color="primary"
            style={styles.rightCornerButton}
            onClick={this.handleSkip}
          >
            Skip
          </Button>
          <Typography
            style={styles.title}
            gutterBottom
            color="primary"
            variant="display1"
          >
            Turn on Push Notifications?
          </Typography>
          <Typography variant="body1">
            Turn on your push notifications to get notified if someone books
            your spot or asks you a question.
          </Typography>

          <div style={styles.alertImageContainer}>
            <img
              src="https://www.sharewithoscar.com.au/assets/graphics/oscar_push_notifications_graphic.svg"
              alt="Permission representation"
            />
          </div>

          <Button
            style={{ position: "absolute", bottom: "10px", width: "94%" }}
            fullWidth
            color="primary"
            variant="contained"
            onClick={this.handleAllow}
          >
            Allow Push Permissions
          </Button>
        </div>
      </div>
    );
  }
}
