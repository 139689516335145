import React, {Component} from 'react';

import { 
    FormHelperText,
} from '@material-ui/core';

export default class HiddenField extends Component {
    reportChange = (newVal) => {
      this.props.onChange({
            target: {
                name: this.props.name,
                value: newVal,
                type: 'text'
            }
        })
    }
    
    render() {
        return(
            <div>
                {this.props.error && <FormHelperText error={this.props.error}>
                   {this.props.helperText}
                </FormHelperText>}
            </div>
        )
    }
}