import React, {Component} from 'react';
import ReactDOM from 'react-dom'

import moment from 'moment';

import Picker from 'react-mobile-picker-scroll';
import {
  TwelveHourTime,
  WeekdayDate,
  WeekdayDateTime
} from '../utils/TimeFormats.js';

import { 
  Button,
  Grid,
  Modal,
  Typography,
  withStyles
} from '@material-ui/core'

import ClockIcon from '@material-ui/icons/AccessTime';
import ArrowIcon from '@material-ui/icons/ArrowForward';


function generateDateOptions(initialDate, futureLimit) {
  let now = initialDate;
  let now2 = initialDate.clone();

  let dateOptsYYYY = {};
  dateOptsYYYY[now2.format("ddd D MMM")] = now2.format("ddd D MMM YYYY");

  // Casual: Mon 7 Feb
  const dateFormat = "ddd D MMM";
  
  let dateOpts = [now.format(dateFormat)];
  
  for (let i = 0; i < futureLimit; i++) {
    dateOpts.push(now.add(1, 'days').format(dateFormat));
    dateOptsYYYY[now2.add(1, 'days').format("ddd D MMM")] = now2.format("ddd D MMM YYYY");

    //dateOpts.push(now.add(1, 'days').format("YYYY-MM-DD"))
  }
  //console.log({dateOpts});
  //console.log({dateOptsYYYY});
  return {dateOpts, dateOptsYYYY};
}

function generateHourOptions() {
  let hours = [];
  for(let i = 1; i < 13; i++) {
      hours.push(`${i}`)
  }
  //hours.push('1')
  
  return hours;
}
const minuteInterval = 5;
function generateMinuteOptions() {
    let minutes = [];
    for(let i = 0; i < 60; i += minuteInterval) {
        // Zero pad all minute options before 10 minutes
        minutes.push(`${i < 10 ? 0 : ''}${i}`);
    }
    return minutes;
}



export default class DateTimeRangePicker2 extends Component {
  constructor(props) {
    super(props);
    //console.log(props);

    //console.log("startTime mins:",props.startMoment.format('hh:mm A'));
    //console.log("endTime mins:",props.endMoment.format('hh:mm A'));

    // props are used for display
    // CLONED copies are used for pickers, we don't pass new values until the end (Moments are mutable)
    this.state = {
      isOpen:null,
      startMoment:props.startMoment.clone(),
      endMoment:props.endMoment ? props.endMoment.clone():null
    }
  }

//<div style={{position:'relative', marginBottom:'8px'}}>

  // Keep the modal inside this component to save the hassle of passing values around

  handleOpenModal = (name, startMoment = null, endMoment = null) => {
    const searchMode = this.props.searchMode;
    if (searchMode === 1 && name=='end'){
      //return;
      name = 'start';
    }

    //console.log("modal searchmode:",searchMode);
    // On initial open (from null), re-fetch dates
    
    if (!startMoment){
      startMoment = this.state.startMoment;
    }
    if (!endMoment){
      endMoment = this.state.endMoment;
    }

    //let startMoment = this.state.startMoment;
    //let endMoment = this.state.endMoment;
    if (!this.state.isOpen){
      startMoment = this.props.startMoment.clone();
      endMoment = this.props.endMoment && this.props.endMoment.clone();
    }
    if (endMoment && endMoment.isBefore(startMoment)){
      endMoment = startMoment.clone();
      endMoment.add(1,'hour');
    }


    let isOpen = name;   

    let valueGroups = {};
    let optionGroups;

    let dateOptsYYYY;

    if (searchMode === 1){
      //Monthly
      if (name === 'start'){
        let result = generateDateOptions(moment().add(2,"days"), 30);
        dateOptsYYYY = result.dateOptsYYYY;
        optionGroups = {
          date: result.dateOpts,
          //hour: generateHourOptions(),
          //minute: generateMinuteOptions(),
          //ampm: ['AM', 'PM']
        };
    
        valueGroups = {
          date: startMoment.format("ddd D MMM"),
          hour: startMoment.format("h"),
          minute: startMoment.format("mm"),
          ampm: startMoment.format("A"),
        }

        
      }

    } else {
      // Casual
      if (name === 'start'){
        let result = generateDateOptions(moment(), 30);
        dateOptsYYYY = result.dateOptsYYYY;
        optionGroups = {
          date: result.dateOpts,
          hour: generateHourOptions(),
          minute: generateMinuteOptions(),
          ampm: ['AM', 'PM']
        };
    
        valueGroups = {
          date: startMoment.format("ddd D MMM"),
          hour: startMoment.format("h"),
          minute: startMoment.format("mm"),
          ampm: startMoment.format("A"),
        }
   
      } else if (name === 'end'){
        // Set minimum end date rules
        // Base value from current startDate (either entered at previous step, or from parent)
        let result = generateDateOptions(startMoment.clone(), 29); // extra day (30+1)
        dateOptsYYYY = result.dateOptsYYYY;
        optionGroups = {
          date: result.dateOpts,  
          hour: generateHourOptions(),
          minute: generateMinuteOptions(),
          ampm: ['AM', 'PM']
        };
  
        valueGroups = {
          date: endMoment.format("ddd D MMM"),
          hour: endMoment.format("h"),
          minute: endMoment.format("mm"),
          ampm: endMoment.format("A"),
        }
  
      } 
  
    }



    this.setState({allowNext:true, isOpen, optionGroups, valueGroups, startMoment, endMoment, dateOptsYYYY});
  }
  

  componentDidUpdate(prevProps, prevState){
    //console.log("componentDidUpdate prevProps.searchMode:",prevProps.searchMode);
    if (prevProps.searchMode != this.props.searchMode){
      //console.log("switching searchMode");
      this.handleNext("switch");
    }
  }

  handleNext = (name) => {
    //console.log("handleNext:",name);
    let isOpen;

    let startMoment = this.state.startMoment;
    let endMoment = this.state.endMoment;


    if (name === 'switch'){
      //console.log("Switch... use props->state");
      //console.log("props:",this.props);
      //console.log("state:",this.state);
      startMoment = this.props.startMoment;
      endMoment = this.props.endMoment;
      name = 'start';
      //console.log("startMoment:",startMoment);
      //console.log("endMoment:",endMoment);
      this.handleOpenModal('start', startMoment, endMoment);
      return;
    }

    if (name === 'start'){
      
      if (this.props.searchMode === 1){
        // Monthly
        //let startMoment = this.state.startMoment;
        this.props.onDateRangeChange(startMoment, null);
        isOpen = null;
        this.setState({isOpen});

      } else {
        // Casual
        this.handleOpenModal('end');
      }


    } else if (name === 'end') {

      // If end is before start, reset to default +1 hours
      //let startMoment = this.state.startMoment;
      //let endMoment = this.state.endMoment;

      if (endMoment.isBefore(startMoment)){
        endMoment = startMoment.clone();
        endMoment.add(1,'hour');
      }


      // pass the new start and end moments to the parent (ParkingSearch2) and close the modal
      this.props.onDateRangeChange(startMoment, endMoment);
      isOpen = null;
      this.setState({isOpen});
    }

    

  }

  handlePickerChange = (name, value) => {
    // Update our local state version of the date

    let allowNext = true;

    let startMoment = this.state.startMoment;
    let endMoment = this.state.endMoment;
    let valueGroups = this.state.valueGroups;
    const dateOptsYYYY = this.state.dateOptsYYYY;
    
    valueGroups[name] = value;
    //console.log({valueGroups});


    if (this.state.isOpen === 'start'){
      //console.log(`START ${name}=${value}`);
      //console.log("old startMoment:",startMoment.toString());
      
      /*{
        "date": "Mon 13 Dec",
        "hour": "11",
        "minute": "05",
        "ampm": "PM"
      }*/
      let strStart = `${dateOptsYYYY[valueGroups.date]} ${valueGroups.hour} ${valueGroups.minute} ${valueGroups.ampm}`;
      //console.log({strStart});
      startMoment = moment(strStart,"ddd D MMM YYYY h mm A");
      //console.log("NEW startMoment:",startMoment.toString());
      const minStart =moment().add(25,"minutes");

      if (startMoment.isBefore(minStart)){
        //endMoment = startMoment.clone();
        //endMoment.add(1,'hour');
        //console.log("IS BEFORE NOW!");
        allowNext = false;
      } 
      

    } else {
      //console.log(`END ${name}=${value}`);
      let strEnd = `${dateOptsYYYY[valueGroups.date]} ${valueGroups.hour} ${valueGroups.minute} ${valueGroups.ampm}`;
      endMoment = moment(strEnd,"ddd D MMM YYYY h mm A");
      //console.log("endMoment:",endMoment.toString());

    }

    
    this.setState({allowNext, valueGroups, startMoment, endMoment});
    /*this.setState(({valueGroups}) => ({
        valueGroups: {
            ...valueGroups,
            [name]: value
        }
    }));*/
  };


  render() {
    //console.log("DateTimeRangePicker2:Render");
    const {isOpen, optionGroups, valueGroups} = this.state;
    const {searchMode} = this.props;

    let strStart;
    let strEnd;

    //console.log({searchMode});
    // Format the start and end date strings
    if (searchMode == 1){
      // Monthly
      if (this.props.startMoment.isSame(moment(), 'day')){
        strStart = this.props.startMoment.format('[Today]');
  
      } else {
        strStart = this.props.startMoment.format('ddd D MMM');
      }

      if (!this.props.endMoment){
        strEnd =  "Monthly - Ongoing";
  
      } else {
        if (this.props.endMoment.isSame(moment(), 'day')){
          strEnd =  this.props.endMoment.format('[Today]');
        } else {
          strEnd =  this.props.endMoment.format('ddd D MMM');
        }
      }
  

    } else {
      // Casual
      if (this.props.startMoment.isSame(moment(), 'day')){
        strStart = this.props.startMoment.format('[Today,] h:mm A');
  
      } else {
        strStart = this.props.startMoment.format('ddd D MMM[,] h:mm A');
      }
  
      if (this.props.endMoment.isSame(moment(), 'day')){
        strEnd =  this.props.endMoment.format('[Today,] h:mm A');
      } else {
        strEnd =  this.props.endMoment.format('ddd D MMM[,] h:mm A');
      }
  
    }

    

    
    let strModalClass = "oscar modal-daterange";
    let strContainerClass = "oscar mdr-container";
    let strTitle;
    let strStartFrom;
    
    if (isOpen === 'start'){
      strContainerClass += " open";
      strModalClass+= " open";
      strTitle = "Select Start Time";


    } else if (isOpen === 'end') {
      strContainerClass += " open";
      strModalClass+= " open";
      strTitle = "Select End Time";

      if (this.state.startMoment.isSame(moment(), 'day')){
        strStartFrom = <p>Starting {this.state.startMoment.format('[Today,] h:mm A')}</p>;

      } else {
        strStartFrom = <p>Starting on {this.state.startMoment.format('ddd D MMM[,] h:mm A')}</p>;

      }
  
    }
    
    let divSearchMode;
    
    if (true || isOpen === 'start'){
      divSearchMode = (
        <div className="wps-mobile-searchmode">
        {this.props.searchMode === 0 ? <span className='active'>Casual</span>:<span onClick={()=>this.props.onSearchModeChange(0)}>Casual</span>}
        {this.props.searchMode === 1 ? <span className='active'>Monthly</span>:<span onClick={()=>this.props.onSearchModeChange(1)}>Monthly</span>}
      </div>);
  
    }
    //{strStartFrom}
    
    return(
      <>
        {ReactDOM.createPortal(
          <>
          <div className={strContainerClass}>
                      <div className="mdr-header noselect">{strTitle}{divSearchMode}</div>
                      <div className="mdr-body">
                        {isOpen &&
                          <Picker key={isOpen}
                          optionGroups={optionGroups}
                          valueGroups={valueGroups}
                          onChange={this.handlePickerChange} />
                        }
        
                      </div>
                      <div className="mdr-footer">
                        {isOpen === 'start' && <Button className="oms-back" onClick={() => {this.handleOpenModal(null)}}>Back</Button>}
                        {isOpen === 'start' && searchMode === 0 && <Button className="oms-next" disabled={!this.state.allowNext} onClick={() => {this.handleNext('start')}}>Next</Button>}
                        {isOpen === 'start' && searchMode === 1 && <Button className="oms-next" onClick={() => {this.handleNext('start')}}>Search</Button>}
                        {isOpen === 'end' && <Button className="oms-back" onClick={()=>this.handleOpenModal('start')}>Back</Button>}
                        {isOpen === 'end' && <Button className="oms-next" onClick={()=>this.handleNext('end')}>Find Parking</Button>}
                      </div>
                    </div>
        
          <div className={strModalClass} onClick={() => {this.handleOpenModal(null)}}>
          </div></>
        ,document.body)
        }
        
        <div className="wps-daterange">
          <div onClick={() => {this.handleOpenModal('start')}}>
            <ClockIcon className="wps-daterange-clock-icon" />
            {strStart}
          </div>
          <div onClick={() => {this.handleOpenModal('end')}}>
            <ArrowIcon className="wps-daterange-arrow-icon" />
            {strEnd}
          </div>
        </div>
      </>
    );
  }
}


/* 
        <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.state.open ? true:false}
        onClose={() => this.setState({open:false})}
        >
        </Modal>

*/
