/* 
TODO: should move this to React Hooks later, but for now this will do
*/
import React, {Component} from 'react';
import moment from 'moment';
import ImageCompressor from 'image-compressor.js';

import EditItem_Form from '../components/EditItem_Form.js';
import {AVAILABLE_FORMS} from '../api/FORMS.js';


import {
    createAvailability,
    createParkingSpace,
    updateParkingSpace,
    uploadImages,
} from '../api/Oscar-api.js';
import { isNativeWebview } from '../utils/IsNativeWebview.js';


import {checkUserNotificationPermissions} from '../utils/PushNotification.js';
import { updateUserProfileAjax } from '../utils/Authentication.js';

const MAX_IMAGE_UPLOAD_SIZE = 1000000

function generateTemporaryLocalUuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return "TEMPORARY_LOCAL_" + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function imagesFromListing(listing) {
    let images = {'general': [], 'street': [], 'access': []}

    for (let i = 0; i < listing.image_set.length; i++ ) {
        if (listing.image_set[i].image_type === "General") {
            images['general'].push({preview: listing.image_set[i].path})
        }
        if (listing.image_set[i].image_type === "Access") {
            images['access'].push({preview: listing.image_set[i].path})
        }
        if (listing.image_set[i].image_type === "Street") {
            images['street'].push({preview: listing.image_set[i].path})
        }
    }

    return images
}

const defaultHourly = 4;
//const defaultWeekly = 50;
const defaultMonthly = 200;
const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export default class ListSpaceV3 extends Component {
    constructor(props) {
        super(props)
        this._endPoint = createParkingSpace
        this._hydrateForm = undefined
        this._creating = true
        this._firstTime = props.match.params.first === "first"
        this._requestPushPermissions = checkUserNotificationPermissions()

        
        if (props.location.state) {
          /*
            if (props.location.state.hydrateState) {
                this.state = {
                    ...props.location.state.hydrateState.local,
                    startTime:  moment("7:00 AM", 'h:mm A'),
                    endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                }
                this._hydrateForm = props.location.state.hydrateState.form
            } else if (props.location.state.completeListing) {
                this._creating = false
                this.state = {
                    currentUuid: props.location.state.completeListing.uuid,
            
                    loading: false,
                    activePage: props.location.state.completeListing.availabilityrecurring_set.length > 0 ? 5 : 3,
                    pageHistory: [],
                    oscarRates: props.location.state.completeListing.uuid,
                    auto_approve: true, // Default true for new listing
                    allow_casual: true, // Default true for new listing
                    allow_monthly: true, // Default true for new listing
                    alwaysAvailable: true,
                    repeatWeekly: true,
            
                    rate_hourly: defaultHourly,
                    //rate_weekly: defaultWeekly,
                    rate_monthly: defaultMonthly,
                    selectedDays: [false, false, false, false, false, false, false],
                    startTime: moment("7:00 AM", 'h:mm A'),
                    endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                    
                    previewImages: {"general": [], "street": [], "access": []},
                    imageFiles: {"general": [], "street": [], "access": []},
                    deletedImages: [],

                    spaceImages: {"general": [], "street": [], "access": []}
                }                
            } else if (props.location.state.editListing) {
              //console.log("X3");

              // Insert these slider values based on supplied rates

              //console.log(props.location.state.editListing);
                this._hydrateForm = {values: props.location.state.editListing, errors:{}}
                this._endPoint = updateParkingSpace
                this._editUuid = props.location.state.editListing.uuid
                this._creating = false

                const imageObject = imagesFromListing(props.location.state.editListing)

                this.state = {
                    currentUuid: props.location.state.editListing.uuid,
            
                    loading: false,
                    activePage: 0,
                    pageHistory: [],
                    oscarRates: props.location.state.editListing.price_by_oscar,
                    auto_approve: props.location.state.editListing.auto_approve,
                    allow_casual: props.location.state.editListing.allow_casual,
                    allow_monthly: props.location.state.editListing.allow_monthly,

                    alwaysAvailable: true,
                    repeatWeekly: true,
            
                    rate_hourly: defaultHourly,
                    //rate_weekly: defaultWeekly,
                    rate_monthly: defaultMonthly,
                    selectedDays: [false, false, false, false, false, false, false],
                    startTime: moment("7:00 AM", 'h:mm A'),
                    endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                    
                    previewImages: imageObject,
                    imageFiles: {"general": [], "street": [], "access": []},
                    deletedImages: [],

                    spaceImages: imageObject
                }
            }*/

        } else {
            // This entry point
            this.state = {
                currentUuid: generateTemporaryLocalUuid(),
        
                loading: false,
                activePage: 0,
                pageHistory: [],
                oscarRates: true,
                auto_approve: true, // Default true for new listing??????
                allow_casual: true, // Default true for new listing
                allow_monthly: true, // Default true for new listing

                alwaysAvailable: true,
                repeatWeekly: true,
        
                rate_hourly: defaultHourly,
                //rate_weekly: defaultWeekly,
                rate_monthly: defaultMonthly,
                selectedDays: [false, false, false, false, false, false, false],
                startTime: moment("7:00 AM", 'h:mm A'),
                endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                
                previewImages: {"general": [], "street": [], "access": []},
                imageFiles: {"general": [], "street": [], "access": []},
                deletedImages: [],

                spaceImages: {"general": [], "street": [], "access": []}
            }    
        }
    }




    handleRemoveImage = (imageType, imageIndex) => {
        let previews = this.state.spaceImages
        let img = previews[imageType].splice(imageIndex, 1)

        let deletedImages = this.state.deletedImages
        if (img[0].file === undefined) {
            deletedImages.push(img[0].preview.replace("https://www.sharewithoscar.com.au/images/", ""))
        }

        this.setState({
            spaceImages: previews,
            deletedImages: deletedImages
        })
    }

    
    handleClose = () => {
      this.props.history.push("/listings");
    }


    render() {

        let formId;
        let itemType;

        // TEMP: this should be data driven
        if (window.WPMode === 'bays'){
          formId = `${window.WPDomain}_listbay_v3`;   // meriton_listbay_v3, community_listbay_v3
          itemType = 'parking_bay_v3';
          
        } else {
          // Oscar
          formId = 'oscar_listspace_v3';
          itemType = 'parking_space_v3';
  
        }
        /*if (window.location.host.split(".")[0] == 'localhost:3000'){
          console.log(window.WPDomain);
          console.log({formId});
          console.log({itemType});
        }*/

        const sp = new URLSearchParams(window.location.search);
        let thisForm = AVAILABLE_FORMS[formId];
        if (!thisForm){
          return <div>ERROR LOADING FORM</div>
        }

        let itemId = sp.get('uuid') || '00000000-0000-0000-0000-000000000000';  // NEW;

        if (itemId === '00000000-0000-0000-0000-000000000000'){
          
          if (window.gtag){
            window.gtag('set', {'event': 'ListMySpotStarted'}); // Set the user ID using signed-in user_id.        
          } else {
            console.log("MISSING gtag ListMySpotStarted");
          }
          

        }

        
        let initialPage = sp.get('page') || 0;
        let last = sp.get('last') || '';

        if (initialPage === 'auto' && last !== ''){


          for (let i=0;i<thisForm.pages.length;i++){
            const thisPage = thisForm.pages[i];         // Single page forms for now
            if (thisPage.id === last){
              initialPage = i+1;
              break;
            }

          }
        }

  
        return (
                <EditItem_Form
                authChange={this.props.authChange}
                history={this.props.history}
                formId={formId}
                initialPage={initialPage}
                //initialData={initialData}  // saves a wasted trip to the server
                itemType={itemType}
                itemId={itemId}
                onClose={this.handleClose}
             />
  
        );
     
    }
}

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


export {defaultHourly, defaultMonthly}