import React, {Component} from 'react';
import { 
    InputLabel, 
    Input, 
    InputAdornment, 
    FormHelperText,
    Typography,
} from '@material-ui/core';

export default class NumberField extends Component {
    handleChange = (event) => {
        const newInput = event.target.value
        // perform custom validation logic
        if (newInput === '') {
            this.props.onChange(event)
        }
        if (!newInput.match( /^(\d+\.?\d*|\.\d+)$/ )) {
            return
        }
        
        this.props.onChange(event)
    } 
    
    render() {
        return(
            <div>
                <InputLabel error={this.props.error} shrink>{this.props.label}</InputLabel>
                <Input
                    name={this.props.name}
                    min={0}
                    inputProps={{
                        type: "text",
                        placeholder: this.props.placeholder
                    }}
                    startAdornment={this.props.disableAnnotation === undefined ?  
                        <InputAdornment style={{color: "#1AA5A2"}} disableTypography position="start">$</InputAdornment>
                        : null}
                    error={this.props.error}
                    value={this.props.value || ""}
                    onChange={this.handleChange}
                />

                {this.props.error && <FormHelperText error={this.props.error}>
                   {this.props.helperText}
                </FormHelperText>}
                
                {this.props.showDaily && this.props.value &&
                <Typography style={{marginTop: '8px'}} variant="caption">
                    This means that your daily rate will be capped at <span style={{color: '#1AA5A2'}}>${(parseFloat(this.props.value) * 7).toFixed(2)}</span>
                </Typography>}
            </div>
        )
    }
}
