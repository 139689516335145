import React, { Component } from 'react';

import {getHotelBranding} from '../api/Oscar-api.js';

import LoadingModal from '../components/LoadingModal.js';
import EditItem_Form from '../components/EditItem_Form.js';


export default class HotelBooking extends Component {
  constructor(props){
    super(props);
    console.log("HotelBooking:Constructor");

    // hotelName: ie. novotel-sydney, meriton-suites-sydney, etc.
    const hotelName = this.props.history.location.pathname.substr(5+1).replace('/','');
    // Will fetch branding/info from CF cached DynamoDB lookup
    
    console.log({hotelName});
    
    this.state = {
      hotelName,
      ready: false
    }
  }  


  componentDidMount() {
    console.log("didMount-getHotelBranding");
    let that = this;    
    setTimeout(() => {
      getHotelBranding(that.state.hotelName).then(response => {
        const data = response.data;
        console.log("hotel:",data);
        that.setState({ready:true, hotelData:data});
    
      }).catch(error => {
        console.log(error);
      }); 
  
    }, 1000);     


    /*setTimeout(() => {
          this.setState({
              nativeWebView: isNativeWebview(),
              ready: true
          });
      }, 1000);     //??? Do we need this?
      //this.testDamian2();*/
  }


  render() {
    const { hotelName, ready, hotelData } = this.state;

    if (hotelData){

    }

    console.log(this.props);  

    if (!ready){
      return(
        <div className={"hotel-container"}>
          <div className={"hotel-content"}>
            <h1 style={{textAlign:'center'}}>{hotelName}</h1>
            {/*<LoadingModal open={true}/>*/}
            <h2 style={{textAlign:'center'}}>Loading (1 sec)...</h2>
          </div>
        </div>
      );
    }
    let initialPage;
    let formId = 'create_hotel_booking';
    let itemType = 'booking_hotel';

    return (
      <div className={"hotel-container"}>
        <pre>{this.state && JSON.stringify(this.state,null,2)}</pre>
        <pre className="hotel-user">{this.props.user && JSON.stringify(this.props.user,null,2)}</pre>
        <div className={"hotel-content"}>
          <EditItem_Form
            devClassName="hotel-form"
              authChange={this.props.authChange}
              history={this.props.history}
              formId={formId}
              initialPage={initialPage}
              initialData={hotelData}  // saves a wasted trip to the server
              itemType={itemType}
              //itemId={this.props.booking.reference}
              onClose={this.handleClose}
           />          
           <div>Powered by Oscar</div>
        </div>          
      </div>
    );

  }
}