import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {
    Button,
    Grid,
    Hidden,
    IconButton,
    Typography
} from '@material-ui/core'

import IconClose from '@material-ui/icons/Close';
import IconShare from '@material-ui/icons/Share';
import IconForward from '@material-ui/icons/KeyboardArrowRight';

const styles = {

    topColored: {
        paddingTop: '80px',
        backgroundColor: '#1AA5A2',
        paddingBottom: '8px',
    },
    alertPage: {
        paddingTop: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    linkContainer: {
        border: '1px solid #efefef',
        borderRadius: '5px',
        padding: '2px 8px',
        flexWrap: 'nowrap',
        flexGrow: 1,
    },
    copiedMessage: {
        backgroundColor: '#1AA5A2',
        position: 'absolute',
        top: '-28px',
        right: '-10px',
        padding: '8px',
        borderRadius: '56px',
        color: 'white',
    }
}

function createReferralLink(user) {
    const url = window.location.href
    let parts = url.split('/')
    parts[parts.length - 1] = `Referred/${user.profile.referral}/`

    return parts.join('/')
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
        return textArea
    }

    function selectText(textArea) {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard(textArea) {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        const area = createTextArea(text);
        selectText(area);
        copyToClipboard(area);
    };

    return {
        copy: copy
    };
})(window, document, navigator);

// How to use

export default class TellYourFriends extends Component {
    state = {}

    render() {
        return(
            <div style={{height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div className="mainContent" style={styles.topColored}>
                <IconButton className="leftCornerButton" onClick={this.props.history.goBack}>
                    <IconClose style={{color: 'white'}}/>
                </IconButton>

                <Typography style={{color: 'white'}} variant="display1">
                    Give and receive free $$$
                </Typography>            
            </div>
            <div style={styles.alertPage} className="mainContent">
                <Typography variant="body1">
                Give a friend $5 to try out Oscar, and receive $5 yourself when they park with Oscar.
Better yet, receive $10 if they list their parking space and receive a booking.
Simply share your personal link and everybody wins!
                </Typography>
                
                <div style={styles.alertImageContainer}>
                    <img src="https://www.sharewithoscar.com.au/assets/graphics/tell_friends.svg" alt="card charge error"/>                 
                </div>
                
                <Grid container alignItems='center' style={{marginBottom: '24px'}} spacing={24}>
                    <Hidden smDown>
                        <Grid style={{flexGrow: 2}} item>
                            <Grid style={styles.linkContainer} container justify="space-between" alignItems="center" spacing={8}>
                                <Grid item style={{overflowX: 'hidden', whiteSpace: 'nowrap'}}>
                                    <Typography variant="body1" color="primary">
                                        {createReferralLink(this.props.user)}                            
                                    </Typography>
                                </Grid>
                                <Grid style={{position: 'relative'}} item>
                                    {this.state.showCopied && <Typography style={styles.copiedMessage} variant="caption" >
                                        Copied
                                    </Typography>}
                                    <IconShare style={{color: '#1AA5A2'}} 
                                    id="copy_referral"
                                    onClick={() => {
                                        window.Clipboard.copy(createReferralLink(this.props.user));
                                        this.setState({showCopied: true})
                                    }}/>
                                </Grid>     
                            </Grid>      
                        </Grid>         
                    </Hidden>
                    <Grid style={{flexGrow: 1}} item>
                        <Button style={{width: '100%'}} color="primary" variant="contained"
                        component={Link} to='/TellYourFriends/Share'>
                            Share your link
                            <IconForward />
                        </Button>
                    </Grid>
                </Grid>
            </div>
            </div>
        )
    }
}