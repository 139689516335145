/*
Generic Edit CRUD Form
=======================
WORK IN PROGRESS - Bare minimum to get new Meriton CP functionality, will improve and add new features later
*/

import React, { Component, forwardRef } from 'react';

import moment from 'moment';
import '../styles/EditItem.css';

import CircularProgress from '@material-ui/core/CircularProgress';

//import ENUMS from '../api/ENUMS.json';
import {AVAILABLE_FORMS} from '../api/FORMS.js';
import { FieldType } from '../api/fieldTypes.js';
import GooglePlacesSearchWP from './GooglePlacesSearchWP.js';

import HiddenField from '../components/forms/HiddenField.js';
import NumberField from '../components/forms/NumberField.js';
import SliderSwitch from '../components/forms/SliderSwitch.js';
import WeekDaySelector from '../components/forms/WeekDaySelector.js';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';

import IconPlus from '@material-ui/icons/Add';
import IconDelete from '@material-ui/icons/Close';
import ImageCompressor from 'image-compressor.js';


import { isNativeWebview } from '../utils/IsNativeWebview.js';


import { 
    getEditItem,
    uploadImagesV3,

} from '../api/Oscar-api.js';


import { updateUserProfileAjax } from "../utils/Authentication";

import {
    Button,
    FormControl,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography
} from '@material-ui/core';
import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconEdit from '@material-ui/icons/Edit';

import IconForward from '@material-ui/icons/KeyboardArrowRight';
import IconSave from '@material-ui/icons/Check';
import IconUpdating from '@material-ui/icons/Save';

import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';


import TextString from './TextString';
import TextArea from './TextArea';
import Money from './Money';
import SelectDropDown from './SelectDropDown';

import {
  QueryParamTime,
  QueryParamDate,
  WeekdayDateTime,
  WPMonthlyBookingsDisplayFormat,
} from '../utils/TimeFormats.js';


const MAX_IMAGE_UPLOAD_SIZE = 1000000;

const scrollToTop = () => {
  const formContainer = document.getElementById("form-container");
  if (formContainer){
    formContainer.scroll(0,0);
  } 
}    


export default class EditItem_Form extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    
        let itemType = this.props.itemType;
        let itemId = this.props.itemId;
        let parentId = this.props.parentId;
        //let queryDetails = queryTable[queryId];
        let thisForm = AVAILABLE_FORMS[this.props.formId]
        let fieldValues = {};
        let formDataIsValid = false;
        let initialLoad = false;

        // Booking Edit already has the data loaded for us
        if (this.props.initialData){
            fieldValues = this.props.initialData;
            formDataIsValid = true;     // We really should revalidate before sending
            initialLoad = true;     // We already have the item data, no need to fetch anything from server
        }

        /*if (thisForm.id === 'oscar_listspace_v3'){
          if (fieldValues['id.selecteddays'] === null || fieldValues['id.selecteddays'] === undefined){
            fieldValues['id.selecteddays'] = [false, false, false, false, false, false, false];
          }
  
        }*/
        

        


        formDataIsValid = true; //TEMP

        //let currentPageIndex = 0;   // Start at first page - unless we have a reason not to
        
        let currentPageIndex = this.props.initialPage || 0;



        this.state = {
            itemType,
            itemId,
            parentId,
            thisForm,
            currentPageIndex,
            formDataIsValid,
            initialLoad,
            searchText:'',
            searchTextFinal:'',
            fieldValues,
            fieldValuesCF:{},
            validation:{},
            isUpdating:false,
            tmpCounter:1,
            browserCSSMode: window.innerWidth >= 960 ? "desktop":"mobile"
        }
        this.loadData = this.loadData.bind(this);
        this.doLocalValidation = this.doLocalValidation.bind(this);
        this.processChildUpdate = this.processChildUpdate.bind(this);
        this.processChildUpdateDate = this.processChildUpdateDate.bind(this);
        this.onLocationSelected_FullDetails = this.onLocationSelected_FullDetails.bind(this);

    }
    updateDimensions = () => {
      const browserCSSMode = window.innerWidth >= 960 ? "desktop":"mobile";
      if (browserCSSMode !== this.state.browserCSSMode){
        this.setState({ browserCSSMode });    //width: window.innerWidth, height: window.innerHeight
      } 
    };


    componentWillUnmount(){
        this._isMounted = false;
        window.removeEventListener('resize', this.updateDimensions);
    }    

    componentDidMount(){
        this._isMounted = true;
        window.addEventListener('resize', this.updateDimensions);

        if (!this.state.initialLoad) {
            this.loadData();
        }
    }    

    // Shared toggle code - checkPageReadyFlag:true will trigger ONE execution, easier than managing the form
    toggleSlider = (id, isChecked) => {
      let fieldValues = this.state.fieldValues;
      let val = fieldValues[id];
      if (val === null || val === undefined){
        val = false;
      }
      fieldValues[id] = isChecked;
      
      this.setState({fieldValues});
    }



    loadData(saveData = null){
        let that = this;
        /*setTimeout(() => {
            if (!that._isMounted){
                return;
            }

            that.setState({initialLoad:true});
        }, 2000);        */
        
        // Allow an override of itemType if needed (ie. booking_cancel -> booking_refund)
        let itemType = this.state.itemType;
        let t_currentPageIndex = this.state.currentPageIndex;
        let t_thisPage = this.state.thisForm.pages[t_currentPageIndex];        

        if (t_thisPage.itemType){
          itemType = t_thisPage.itemType;
        }
        let page_id;
        page_id = t_thisPage.id;




        let tmpCounter = this.state.tmpCounter;

        let itemId = this.state.itemId;
        let queryParams = {
            type:itemType,
            id:itemId,
            parent_id:this.state.parentId,
            save:saveData,
            page_id
        };
        //const bookingRef = this.props.match.params.reference;
        getEditItem(queryParams).then(details => {
            //console.log("\n=============\nData:",details.data,"\n===========");
          
            // ie. ParkingSpace list a spot flow - store the new uuid of the object so we don't create another one on 'Next'
            if ((itemType === 'parking_space_v3' || itemType === 'parking_bay_v3') && queryParams.id === '00000000-0000-0000-0000-000000000000'){
              itemId = details.data.item.uuid;
              let strUrl = window.location.pathname+'?uuid=' + itemId + '&wasNew=true';
              this.props.history.replace(strUrl);
            }
            let fieldErrors = {};

            let currentPageIndex = that.state.currentPageIndex;

            // Redirect on success - if needed
            if (details.data.meta && details.data.meta.save_success){
                const thisForm = that.state.thisForm;
                //const currentPageIndex = that.state.currentPageIndex;
                const thisPage = thisForm.pages[currentPageIndex];        

                if (thisPage.onSuccessReturnUrl){
                    let url = localStorage.getItem("returnUrl");
                    if (url){
                      localStorage.removeItem("returnUrl");
                      this.props.history.push(url);
                      return;  

                    } 
                    
                    // if no success url was stored, will fall through to show a success message instead of redirecting [save_success]
                }

                let onSuccessUrl = thisPage.onSuccessUrl;

                if (onSuccessUrl && onSuccessUrl.indexOf("{{") >= 0){
                    onSuccessUrl = onSuccessUrl.replace("{{building.uuid}}",that.state.fieldValues.building.uuid)
                }

                if (onSuccessUrl && this.props.history){
                    this.props.history.push(onSuccessUrl);
                    return;
                }  

                if (thisForm.pages.length > 1 && thisForm.pages.length > currentPageIndex){
                  if (thisForm.pages[currentPageIndex].onSuccessUpdateUser){
                    
                    updateUserProfileAjax().then(() => {
                      this.props.authChange();
                    })


                  }
                  currentPageIndex++;
                  //that.setState({currentPageIndex:currentPageIndex+1});
                  //return;
                  
                  

                } else {
                  // Last page (or single page)
                  fieldErrors['save_success'] = 'Your changes were saved successfully';
                }

                if (that.props.onPageSuccess){
                  that.props.onPageSuccess(that.state.fieldValues);
                }
            } 

            let validation = {
                fieldErrors
            };
    
            scrollToTop();

            // Django serializer for UserProfile is singluar
            let fieldValues;
            if (details.data.item.constructor === Array){
              fieldValues = details.data.item[0];
            } else {
              fieldValues = details.data.item;
            }
            

            // Fall through here by default
            that.setState({
                tmpCounter:tmpCounter+1,
                initialLoad:true,
                loading: false,
                isUpdating: false,
                fieldValues: fieldValues || {},
                parent:details.data.parent,
                validation,
                currentPageIndex,
                itemId,
                errorMessage:null
              });

        }).catch(function(err) {
          // NEW ERROR HANDLING
              let errorMessage;
              let fieldErrors = {};

            // field erors
            if (err.response){
                if (err.response.data && err.response.data.detail){
                    errorMessage = err.response.data.detail;
                    that.setState({isUpdating:false, isLoading:false, errorMessage});
                    return;

                  }

                if (err.response.status >= 500) {
                    errorMessage = err.toString();
                } else {
                    for (let field in err.response.data) {
                        fieldErrors[field] = err.response.data[field];
    
                    }
                        
                }
  

            } 

            let validation = {
                fieldErrors
            };

            if (err.status == 500){
                errorMessage = err.response.toString();
            }
            

            that.setState({isUpdating:false, isLoading:false, validation, errorMessage});

        });



    }

    onBtnBack = (event) => {
      event.preventDefault();
      const {currentPageIndex} = this.state;

      //TODO: don't allow nav back earlier pages if required
      if (currentPageIndex > 0){
        this.setState({currentPageIndex:currentPageIndex-1, errorMessage:null});
        scrollToTop();
      }
      

    }



    onBtnNext = (event) => {
      event.preventDefault();
      const {currentPageIndex} = this.state;
      this.setState({currentPageIndex:currentPageIndex+1});
      scrollToTop();

    }

    onFormSubmit = (event) => {
      // Send the data to the server.
        // Handle the response.
        if (event){
          event.preventDefault();

        }

        const {thisForm, currentPageIndex} = this.state;
        /*if (thisForm.saveAtEnd){
            
            const totalPages = thisForm.pages.length;
            if (currentPageIndex >= totalPages-1){
                //return;
            } else {
                this.setState({currentPageIndex:currentPageIndex+1});
                return;

            }
        }*/



        this.setState({isUpdating:true});

        // Save
        this.loadData(JSON.stringify(this.state.fieldValues));
        
        /*// TEMP
        let that = this;
        setTimeout(() => {
            if (!that._isMounted){
                return;
            }

            const thisForm = that.state.thisForm;
            const fieldValues = that.state.fieldValues;
            let validation = this.doLocalValidation(thisForm, fieldValues);
            

            //that.loadData();
            that.setState({isUpdating:false, validation});
          }, 1);        
*/
    }   




    onLocationSelected_FullDetails(newValue,latLng, street_name, suburb, state_name, postcode){
      
      //fieldValues[inJSON.id] = newValue;
      let fieldValues = this.state.fieldValues;
      fieldValues['address'] = newValue;
      fieldValues['latitude'] = latLng.lat;
      fieldValues['longitude'] = latLng.lng;
      fieldValues['street_name'] = street_name;
      fieldValues['suburb'] = suburb;
      fieldValues['state'] = state_name;
      fieldValues['postcode'] = postcode;


      let validation = this.state.validation;
      
      if (validation.fieldErrors){
          validation.fieldErrors['save_success'] = false;
          validation.fieldErrors['address'] = null;
      }

      this.setState({fieldValues, validation});
    }

    processChildUpdateDate(inJSON){
  
      let fieldValues = this.state.fieldValues;
      if (inJSON.finalValue){
        let newValue = moment(inJSON.finalValue).format("YYYY-MM-DD");
        fieldValues[inJSON.id] = newValue;
  
      } else {
        fieldValues[inJSON.id] = null;
      }
  
      let fieldValuesCF = this.state.fieldValuesCF;
      fieldValuesCF[inJSON.id] = true;
  
      let validation = this.state.validation;

      if (validation.fieldErrors){
          validation.fieldErrors['save_success'] = false;
          validation.fieldErrors[inJSON.id] = null;
      }

      this.setState({fieldValues,fieldValuesCF, validation});
  
      //this.props.onUpdate(inJSON);    //Duplicate in TaxDoc parent*/
    }
  
    processChildUpdateDOW(id,dayIndex){
      let fieldValues = this.state.fieldValues;
      let dow = fieldValues[id];
      dow[dayIndex] = !dow[dayIndex];

      fieldValues[id] = dow;
      this.setState({fieldValues});
    }

    processChildUpdateTimeRangeSelected = (start, end) => {
      let fieldValues = this.state.fieldValues;
      fieldValues['start_time'] = `${start.hour()}:${start.minute()}:00`;
      fieldValues['cease_time'] = `${end.hour()}:${end.minute()}:00`;
      this.setState({fieldValues});

    }


    processChildUpdate(inJSON){
    
        let fieldValues = this.state.fieldValues;
        fieldValues[inJSON.id] = inJSON.finalValue;
    
        let fieldValuesCF = this.state.fieldValuesCF;
        fieldValuesCF[inJSON.id] = true;
    
        let validation = this.state.validation;

        if (validation.fieldErrors){
            validation.fieldErrors['save_success'] = false;

        }

        this.setState({fieldValues,fieldValuesCF, validation});
    
        //this.props.onUpdate(inJSON);    //Duplicate in TaxDoc parent*/
      }
    
    
    doLocalValidation(thisForm, fieldValues){

        const thisPage = thisForm.pages[0];         // Single page forms for now

        let fieldErrors = {};


        for (let f=0;f<thisPage.fields.length;f++){
            const thisField = thisPage.fields[f];
            const thisType = thisField.type;
            fieldErrors[thisField.id] = "Local error message - " + fieldValues[thisField.id];

        }

        let validation = {
            fieldErrors
        };

        
        return validation;
    }




  // =============================================
  // Image Uploader - TODO: move to own component
  // =============================================

  handleImagePickerClick = (event, fieldId) => {
    
    if (isNativeWebview()) {
      event.preventDefault()
      event.stopPropagation()

      // Send the postMessage to the native side saying we want to log in through google
      window.ReactNativeWebView.postMessage(JSON.stringify({action: "image"}));
      if (window.document.isAndroid) {
          window.document.addEventListener("message", this.handleNativeResponse )
      } else {
          window.addEventListener("message", this.handleNativeResponse )
      }
    }        
  }

  handleNativeResponse = (event) => {

    // Process the response token as normal with our backend
    const nativeResponse = JSON.parse(event.data);
    if (nativeResponse.message === "image_upload") {
      
      //this.state.pickingImageType
      this.compressAndSaveImage(b64toBlob(nativeResponse.image_data), "general", .8)
    } else if (nativeResponse.message === "image_error") {

      // handle image errors here.
        this.setState({imageAddError: true})
    } else {
      //xxxxwindow.location = '/userprofile'; dev testing only
    }
    this.setState({ loading: false})
    
    if (window.document.isAndroid) {
      window.document.removeEventListener("message", this.handleNativeResponse);
    } else {
      window.removeEventListener("message", this.handleNativeResponse);
    }
}


  compressAndSaveImage = (file, fieldId, quality) => {
    
    if (file.size < 1000) {
        this.setState({imageAddError: true, isUpdating:false})
        return
    }
    //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: file.size}));

    if (file.size < MAX_IMAGE_UPLOAD_SIZE) {
        //xxxxwindow.location = '/userprofile';

        this.saveImage(file, fieldId)
        return;
    }
    //window.location = '/search';
    //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "compressing"}));
    new ImageCompressor(file, {
        quality: quality,
        checkOrientation: false,
        width: 800,

        success: (result) => {      
            if (result.size < MAX_IMAGE_UPLOAD_SIZE) {
                //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "compressing"}));
                this.saveImage(result, fieldId)
            }    
            else {
                this.compressAndSaveImage(result, fieldId, quality / 1.5)
            }            
        },
        error: (e) => {
            console.log(e.message);
            //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: e.message}));
            // handle image errors here.
            this.setState({imageAddError: true,  isUpdating:false});
        },
    });
  }




  convertImageHEIC = (result, fieldId) => {

    const fieldValues = this.state.fieldValues;
    let imagePreviews = fieldValues['imagePreviews'] || {};

    //let stateId = `${fieldId}_preview_list`;
    let images = imagePreviews[fieldId] || [];


    const heic2any = require("heic2any");
    heic2any({
      blob:result,
      toType: "image/jpeg",
      multiple: false,
      quality: 0.25
    }).then((conversionResult) => {

      this.saveImage(conversionResult, fieldId);
    });
  }



  saveImage = (result, fieldId) => {
    const reader = new FileReader();
    //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "saving"}));
    reader.onload = () => {

      const fieldValues = this.state.fieldValues;
      let imagePreviews = fieldValues['imagePreviews'] || {};

      //let stateId = `${fieldId}_preview_list`;
      let images = imagePreviews[fieldId] || [];
      
//      const heic2any = require("heic2any");
     
      

      images.push({preview: reader.result, file: result});
      imagePreviews[fieldId] = images;

      this.setState({fieldValues, isUpdating:false});

    }

    try {
      reader.readAsDataURL(result);
      //window.location = '/wallet';

    } catch (e) {
      //window.location = '/search';
      void(0);
    }    
  }

  handleRemoveImage = (fieldId, imageIndex) => {
    let fieldValues = this.state.fieldValues;

    let imagePreviews = fieldValues['imagePreviews'] || {};
    let images = imagePreviews[fieldId] || [];

    if (images.length >= imageIndex){
      let thisImage = images[imageIndex] || {};
  
      if (thisImage.url){
        //console.log("EXISTING image, will need to physically delete");
        thisImage.pending_delete = true;
        let deleted_images = fieldValues['deleted_dmages'] || [];
        deleted_images.push(thisImage.url)

      } else {
        //console.log("temp image, can remove locally");
        delete images[imageIndex];
        imagePreviews[fieldId] = images;
      }
      this.setState({fieldValues});      
    }


    /*let previews = this.state.spaceImages
    let img = previews[imageType].splice(imageIndex, 1)

    let deletedImages = this.state.deletedImages
    if (img[0].file === undefined) {
        deletedImages.push(img[0].preview.replace("https://www.sharewithoscar.com.au/images/", ""))
    }

    this.setState({
        spaceImages: previews,
        deletedImages: deletedImages
    })*/
  }


  
  handleFileChange = (e, file, fieldId) => {
    file = file || e.target.files[0]
    const validation = this.state.validation || {};
    const fieldErrors = validation.fieldErrors || {};

    if (file.name.indexOf('.heic') >= 0){

      this.convertImageHEIC(file, fieldId);

    } else if (file.type === 'image/jpeg' || file.type === 'image/png'){

      const validation = this.state.validation || {};
      const fieldErrors = validation.fieldErrors || {};

      fieldErrors[fieldId] = null;
      this.setState({validation, isUpdating:false});


      this.compressAndSaveImage(file, fieldId, .8);
      

    } else {
      console.log("invalid type:",file);
      
      fieldErrors[fieldId] = "Please upload images in JPG or PNG format";
      this.setState({validation, isUpdating:false});

    }
  }

  
  handleSavePhotos = () => {
    this.setState({isUpdating:true});

    let space_or_bay = this.state.itemType === 'parking_bay_v3' ? 'parking_bays':'parking_spaces' ;

    //let imagePreviews = this.state.imagePreviews || {};
    const imagePreviews = this.state.fieldValues.imagePreviews;

    const fieldValues = this.state.fieldValues;
    const uuid = fieldValues['uuid'];   // parking space


    uploadImagesV3(imagePreviews, space_or_bay, this.state.deletedImages, uuid).then(response => {
        //console.log('yay');
        //this.finishFlow()
        this.onFormSubmit();

    }).catch(error => {
        console.log(error.response);
    });

  }




  // ================================================
  // RENDER
  // ================================================
    render() {
      const ImageUpload = (props) => (
        <Grid className="component-imageupload" container justify="space-between" alignItems="center">
            <Grid item className="image-type-title">
                {props.description}
            </Grid>
            <Grid item className="btn-add-photo">
                <IconButton onClick={(e) => this.handleImagePickerClick(e, props.id)}>
                  {/*<IconPlus />*/}
                  <label>
                  <span>+</span>
                  <input style={{display:'none'}} type="file" onChange={(e, file) => this.handleFileChange(e, file, props.id)}/>

                  </label>
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                <div className="preview-list">
                {props.imageList.map((image, i) => {

                  if (image.pending_delete){
                    return null;
                  }
                  let strUrl;
                  if (image.url){
                    strUrl = `url("https://images.sharewithoscar.com.au/${image.url}?tr=w-160,h-140,fo-auto")`;
                  } else {
                    strUrl = `url(${image.preview})`;
                  }

                  return(
                      <div key={i} className="single-preview" style={{backgroundImage:strUrl}}>
                        {/*<img src={image.preview} key={i} alt={props.description}/>*/}
                        <div className="delete-icon" onClick={() => this.handleRemoveImage(props.id, i)}><IconDelete /></div>
                      </div>
                  );
                })}
                </div>

                {props.error && <div className="errormsg">{props.error}</div>}
            </Grid>
        </Grid>
    )        
  
  

        const {browserCSSMode, initialLoad, isUpdating, errorMessage, thisForm, currentPageIndex} = this.state;
        //const data = this.state.data || {};
        const meta = this.state.meta;
        const CMS = this.props.CMS;
        
        const fieldValues = this.state.fieldValues || {};
        const validation = this.state.validation || {};
        const fieldErrors = validation.fieldErrors || {};


        if (!thisForm){
            return (<div>Missing Form: {this.props.formId}</div>);
        }

        const thisPage = thisForm.pages[currentPageIndex];         // Single page forms for now

        // NEW ERROR HANDLING (ONLY on initial load... otherwise display errors in form)
        if (errorMessage && !initialLoad){
            return (
            <div className="datagrid error">
                <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
                    <h1>ERROR</h1>
                    <p>{errorMessage}</p>

                </div>
                
            </div>
            );
        }


        if (!initialLoad){
            return (
                <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
                    <CircularProgress  size={80} thickness={4.5} style={{color:"#1AA5A2"}} />
                </div>
            );
        }


        const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
          <IconEdit style={{color:'var(--heroColor2)', fontSize: '22px', marginLeft: '8px', verticalAlign: 'text-bottom'}} 
                                      onClick={onClick} ref={ref}/>
        ));
    

        const validationId = this.state.tmpCounter; // validation.id || '1';
        const parent = this.state.parent || {};

        let arrFields = [];
        for (let f=0;f<thisPage.fields.length;f++){
            const thisField = thisPage.fields[f];
            const thisType = thisField.type;

            // let thisKey = validationId +  pageData.id + '-' + f + '-' + thisField.id;
            let thisKey = `${f}-${thisField.id}`;

            // If the field has prerequisites, check these have been achieved before display 
            const requires = thisField.requires;
            let isVisible = true;

            if (requires){
              for (let r=0;r<requires.length;r++){
                let thisRequirement = requires[r];
                if (thisRequirement.value === 'SPECIAL_ACCESS_NOT_NONE'){
                  // Special
                  if (fieldValues[thisRequirement.fieldId] === 'None' || !fieldValues[thisRequirement.fieldId]){
                    isVisible = false;
                    break;
                  }

                } else if (fieldValues[thisRequirement.fieldId] != thisRequirement.value){
                  isVisible = false;
                  break;
                }
              }
            }

            if (!isVisible){
              continue;
            }

            if (thisType === FieldType.TITLE){
                arrFields.push(<p className="title" key={thisKey}>{thisField.label}</p>);
          
            } else if (thisType === FieldType.DISPLAY){
                
                // TEMP for now
                if (thisField.id === 'building_address'){
                    let strDisplay = `${fieldValues['address']},  ${fieldValues['suburb']} ${fieldValues['state']} ${fieldValues['postcode']}`;
                    arrFields.push(<p className="" key={thisKey}>{strDisplay}</p>);
                } else {
                    arrFields.push(<p className="" key={thisKey}>{fieldValues[thisField.id]}</p>);
                }
                
            } else if (thisType === FieldType.SLIDER) {
              let isChecked = fieldValues[thisField.id];
              if (isChecked === null || isChecked === undefined){
                isChecked = thisField.default;
              }

              arrFields.push(
                <Grid style={{margin:'0 0 0 -12px'}} key={thisKey}>
                  <Grid>
                    <SliderSwitch checked={isChecked} onChange={this.toggleSlider.bind(this,thisField.id, !isChecked)} />
                    <span style={{color:'rgba(0,0,0,0.54)',fontWeight:'700'}}>{thisField.label}</span>
                    <HiddenField name={thisField.id} value={isChecked}/>
                </Grid>
              </Grid>
              );

            } else if (thisType === FieldType.DAYSOFWEEK){
              const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
              let selectedDays = fieldValues[thisField.id];
              arrFields.push(<WeekDaySelector key={thisKey} selectedDays={selectedDays} onChange={this.processChildUpdateDOW.bind(this,thisField.id)} />);

            } else if (thisType === FieldType.ADDRESS_ONLY_AUSTRALIA){

              arrFields.push(
                <GooglePlacesSearchWP
                  key={thisKey}
                  name="address" 
                  placeholder="Enter your address"
                  onLocationSelected_FullDetails={this.onLocationSelected_FullDetails} 
                  address={fieldValues['address']}
                  desktopDisplay={browserCSSMode === 'desktop' ? true:false} // this is not used anymore???
                  browserCSSMode={browserCSSMode}  // destkop/mobile
              />
              );
            } else if (thisType === FieldType.TEXT_STRING || thisType === FieldType.TEXT_NUMERIC || thisType === FieldType.TEXT_EMAIL){
              
              arrFields.push(<TextString key={thisKey} field={thisField} initialValue={fieldValues[thisField.id]} error={fieldErrors[thisField.id]} onUpdate={this.processChildUpdate} />);
          
            } else if (thisType === FieldType.TEXTAREA){
              
              // quick/dirty hack here
              if (thisField.id === 'cease_reason_string'){
                if (fieldValues['cease_reason_int'] != 10){
                  continue;   // only display on OTHER
                }
                
              }

              arrFields.push(<TextArea key={thisKey} field={thisField} initialValue={fieldValues[thisField.id]} error={fieldErrors[thisField.id]} onUpdate={this.processChildUpdate} />);
          
            } else if (thisType === FieldType.MONEY_ONLY_POSITIVE_DOLLARS_AND_CENTS ||thisType === FieldType.MONEY_ONLY_POSITIVE_DOLLARS || thisType === FieldType.PERCENTAGE || thisType === FieldType.INTEGER_ONLY_POSITIVE|| thisType === FieldType.INTEGER_YEAR){
              arrFields.push(<Money key={thisKey} placeholder={thisField.placeholder} field={thisField} initialValue={fieldValues[thisField.id]} error={fieldErrors[thisField.id]} onUpdate={this.processChildUpdate} />);
          
            } else if (thisType === FieldType.SELECT_DROPDOWN){
              arrFields.push(<SelectDropDown pageData={"pageData"} key={thisKey} field={thisField} initialValue={fieldValues[thisField.id]} error={fieldErrors[thisField.id]} onUpdate={this.processChildUpdate} />);
          
            } else if (thisType === FieldType.IMAGEUPLOAD){
              //const imagePreviews = this.state.imagePreviews || {};
              const imagePreviews = fieldValues.imagePreviews || {};

              arrFields.push(<ImageUpload key={thisKey} id={thisField.id} imageList={imagePreviews[thisField.id] || []} description={thisField.label} error={fieldErrors[thisField.id]}/>);



            } else if (thisType === FieldType.TIMERANGEPICKER){

                arrFields.push(
                  <DateTimeRangePicker 
                    key={thisKey}
                    withDate={false}
                    verticalForm={true}
                    enforceFuture={false}
                    start={moment(fieldValues['start_time'], "HH:mm:ss")}
                    end={moment(fieldValues['cease_time'], "HH:mm:ss")}
                    startText="From"
                    endText="To"
                    onTimeRangeSelected={this.processChildUpdateTimeRangeSelected}
                    disableAutoEditEnd={true}
                  />
                );              

            } else if (thisType === FieldType.DATE_ALL){

                
                let date_selected;
                let date_min;
                let date_max = addDays(new Date(),180);

                if (fieldValues[thisField.id]){
                  date_selected = moment(fieldValues[thisField.id]).toDate("YYYY-MM-DD");
                }

                if (fieldValues['min_cease_date']){
                  date_min = moment(fieldValues['min_cease_date']).toDate("YYYY-MM-DD");
                }

                // Server lets us know the maxDate value
                if (thisField['maxDate'] && fieldValues['max_cease_date']){
                  date_max = moment(fieldValues['max_cease_date']).toDate("YYYY-MM-DD");
                }

                let strValue;
                if (date_selected){
                  strValue = moment(date_selected).format(WPMonthlyBookingsDisplayFormat);
                } else {
                  strValue = "Select Date";
                }

                arrFields.push(
                  <Grid key={thisKey} container justify="space-between" alignItems="center" style={{position:'relative', borderTop:'1px solid #efefef', borderBottom:'1px solid #efefef', marginTop:'30px', padding:'14px 0'}}>
                  <Grid item>
                      <p>{thisField.label}</p>
                  </Grid>
                  <Grid item>
                      <p style={{display:'inline-block',position:'absolute',right:'60px',margin:'0',fontWeight:'700'}}>{strValue}</p>
                      <DatePicker
                        selected={date_selected}
                        //onChange={(date) => this.setMSMEndDate(date)}
                        onChange={(date) => this.processChildUpdateDate({id:thisField.id,finalValue:date})}
                        minDate={date_min}
                        maxDate={date_max}
                        isClearable={true}
                        withPortal
                        fixedHeight
                        error={fieldErrors[thisField.id]}
                        customInput={<ExampleCustomInput />}                              
                        />

                  </Grid>
                </Grid>
                );

                if (fieldErrors[thisField.id]){
                  arrFields.push(
                    <Grid key={thisKey+'error'} container justify="space-between" alignItems="flex-end">
                    <Grid item>
                        <div className="datepicker-error">{fieldErrors[thisField.id]}</div>
                    </Grid>
                  </Grid>
                  );
  
                }


              
            } else if (thisType === FieldType.ADVICE_BOX){
                //var parse = require('html-react-parser');
                //arrFields.push(<Message key={thisKey} className="advice_box">{parse(thisField.label)}</Message>);
                
                //<div dangerouslySetInnerHTML={{__html: instruction}}/>

                //arrFields.push(<div key={thisKey} className="advice_box">{thisField.label}</div>);
                let label = thisField.label;

                // TEMP
                if (label.indexOf('{{') >= 0){
                    label = label.replace("{{parent.rate_monthly_default}}", '$'+ parent.rate_monthly_default);
                }
                arrFields.push(<div key={thisKey} className="advice_box" dangerouslySetInnerHTML={{__html: label}}/>);
          
            } else if (thisType === FieldType.HTML){
                let label = thisField.label;
                // TEMP
                if (label.indexOf('{{') >= 0){
                  label = label.replace("{{reference}}", fieldValues['reference']);
                  label = label.replace("{{window.WPDomain}}", window.WPDomain);
                  //label = label.replace("{{min_cease_date}}", fieldValues['min_cease_date']);

                  if (fieldValues['min_cease_date']){
                    let strDate = moment(fieldValues['min_cease_date']).format(WPMonthlyBookingsDisplayFormat);
                    label = label.replace(/{{min_cease_date}}/g, strDate);
                  }


                  if (fieldValues['cease_date']){
                    let strDate = moment(fieldValues['cease_date']).format(WPMonthlyBookingsDisplayFormat);
                    label = label.replace(/{{cease_date}}/g, strDate);
                  }
              }

                arrFields.push(<div key={thisKey} dangerouslySetInnerHTML={{__html: label}}/>);

            } else {
                arrFields.push(
                    <div key={f}>unknown type: field: {thisField.id} - {thisField.label}</div>
                );
            }

        }

        let strTitle = thisPage.title;

        const sp = new URLSearchParams(window.location.search);

        let wasNew = sp.get('wasNew');

        if (wasNew || this.state.itemId === '00000000-0000-0000-0000-000000000000'){
            strTitle = thisPage.title_new;
        }
        if (strTitle && parent){
            strTitle = strTitle.replace("{{parent.building_name}}", parent.building_name);
        }
        

        //const title = thisForm.title ? <h1><div dangerouslySetInnerHTML={{__html: strTitle}}/></h1>:null;

        let formError;
        if (fieldErrors['detail']){
            formError = <div className="form-error">Error: {fieldErrors['detail']}</div>;
        } else if (fieldErrors['save_success']){

            formError = <div className="form-success">{fieldErrors['save_success']}</div>;
        }


        let divHeader;

        if (thisForm.formStyle == "profile"){
          let btnBack;
          if (thisPage.headerButtons){
            //TODO: Finish this properly
            let thisButton = thisPage.headerButtons[0];
            if (thisButton.id === 'btnClose'){
              btnBack = <IconButton className="leftCornerButton" onClick={this.props.onClose}><IconBack style={{color: 'white'}}/></IconButton>;
            } else {
              btnBack = <IconButton className="leftCornerButton" onClick={this.onBtnBack}><IconBack style={{color: 'white'}}/></IconButton>;
            }
            
          }
          

          divHeader = (
            <div className="up-header">
              {btnBack}
            <h1>{thisPage.title}</h1>
            </div>
          );
  
        } else {
          if (strTitle){
            divHeader = <h1><div dangerouslySetInnerHTML={{__html: strTitle}}/></h1>;
          }
  
        }

        const formClass = thisForm.className || "editform";
        const contentClass = (thisForm.className || "editform") + "-content";

        let divButtons;
        //btnClose - will trigger props.onClose
        //btnNext - will proceed to next page without submitting to server
        //btnSubmit - will submit to server and continue to next page if present
        
        if (thisPage.buttons){
          let arrButtons = [];
          for (let b=0;b<thisPage.buttons.length;b++){
            let thisButton = thisPage.buttons[b];
            let key = `${thisPage.id}_${b}`;

            //style={{minWidth:'240px', fontSize:'16px'}}
            if (thisButton.id === "btnClose"){
              arrButtons.push(
                <Button key={key} className="wp-button-outline thirdWidth" onClick={this.props.onClose}>{thisButton.label}</Button>
              );
  
            } else if (thisButton.id === "btnBack"){
              arrButtons.push(
                <Button key={key} className="wp-button-outline thirdWidth" onClick={this.onBtnBack}>{thisButton.label}</Button>
              );

            } else if (thisButton.id === "btnNext"){
              arrButtons.push(
                <Button key={key} id={thisButton.setId} className="wp-button thirdWidth" type="submit" onClick={this.onBtnNext}>{thisButton.label}</Button>
              );

            } else if (thisButton.id === "btnSubmit"){
              arrButtons.push(
                <Button key={key} id={thisButton.setId} className="wp-button thirdWidth" type="submit" onClick={this.onFormSubmit} disabled={!this.state.formDataIsValid || isUpdating}>
                {isUpdating ? <>{thisButton.label}<CircularProgress  size={31} thickness={4.5} style={{color:"#ffffff"}} /></>:
                <>{thisButton.label}</>} 
                </Button>

              );

            } else if (thisButton.id === "btnSubmitPhotos"){
              arrButtons.push(
                <Button key={key} id={thisButton.setId} className="wp-button thirdWidth" type="submit" onClick={this.handleSavePhotos} disabled={!this.state.formDataIsValid || isUpdating}>
                {isUpdating ? <>{thisButton.label}<CircularProgress  size={31} thickness={4.5} style={{color:"#ffffff"}} /></>:
                <>{thisButton.label}</>} 
                </Button>

              );

            }


          }

          if (thisPage.buttonsContainerStyle === "left"){
            divButtons = (
              <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: '40px'}}>{arrButtons}</div>
            );
  
          } else if (thisPage.buttonsContainerStyle === "space-between"){
            divButtons = (
              <div className="button-container-space-between">{arrButtons}</div>
            );

          } else {
            // Default
            divButtons = (
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>{arrButtons}</div>
            );
  
          }
        }



        //<IconForward />
        return (
          <>
            {/*this.props.devClassName &&
              <pre className={this.props.devClassName}>
                <p>thisPage:</p>
                {JSON.stringify(thisPage,null,2)}
                <p>fieldValues:</p>
                {JSON.stringify(fieldValues,null,2)}
                <p>validation:</p>
                {JSON.stringify(validation,null,2)}
              </pre>
        */}

            <div id="form-container" className={formClass + (isUpdating ? " is-updating":"")}>
                {divHeader}
          
                <div key={validationId} className={contentClass}>
                    {/*<p style={{position:'absolute',right:'0',top:'0',backgroundColor:'#ffeeff',padding:'10px'}}>pageId: <b>{thisPage.id}</b></p>*/}
                    {arrFields}
                    {formError}
                    {errorMessage && <p className="form-error">{errorMessage}</p>}
                    
                  {divButtons}
                </div>
                
            </div>
          </>
        );
    }

}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

var blob = new Blob(byteArrays, {type: contentType});
return blob;
}

