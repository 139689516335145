import React, {Component} from 'react';
import moment from 'moment';

import { 
    Button,
    Grid,
    IconButton,
    TextField,
    Typography
} from '@material-ui/core';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';

import {getMessages, getBookingDetails, postMessage} from '../api/Oscar-api.js';

import LoadingModal from '../components/LoadingModal';

const styles = {
    topLeftButton: {
        position: 'absolute',
        top: '29px',
        left: '0px',
    },
    topSection: {
        paddingTop: '40px',
        paddingBottom: '12px',
        borderBottom: '1px solid #efefef'
    },
    messagingContainer: {
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%' 
    },
    messageContainer:{
        marginBottom: '16px'
    }, 
    messageContent: {
        padding: '10px 16px',
        borderRadius: '6px',
        width: '290px'
    },
    receiverMessage: {
        backgroundColor: '#efefef'
    },
    senderMessage: {
        color: 'white',
        backgroundColor: '#1AA5A2'
    },
    messageProfilePicture: {
        width: '48px',
        height: '48px',
        borderRadius: '28px',
        marginBottom: '-4px',
    },
    messageInput: {
        borderTop: '1px solid #efefef'
    },
    messageInputField: {
        margin: '8px',
        marginLeft: '16px'
    },
}

export default class Messages extends Component {
    state = {
        loading: true,
        pollingReference: undefined
    }

    componentWillMount() {
        const bookingRef = this.props.match.params.bookingReference
        Promise.all([getBookingDetails(bookingRef), getMessages(bookingRef)]).then(([details, messages]) => {
            const role = this.props.match.params.role
            let localUser; let otherUser
            //console.log("role:",role);
            //console.log("data:",details);
            if (role === 'driver') {
                localUser = details.data.driver
                otherUser = details.data.owner || details.data.owner2
            } else  {
                localUser = details.data.owner || details.data.owner2
                otherUser = details.data.driver
            }

            if (!localUser){
              localUser = {first_name:"MISSING!"}
            }
            if (!otherUser){
              otherUser = {first_name:"MISSING!"}
            }

            this.setState({
                loading: false,
                localUser: localUser,
                otherUser: otherUser,
                messages: messages.data,
            })
            this._messagesEnd.scrollIntoView()

            //this.setState({pollingReference: setTimeout(this.checkMessages, 5000) })
        }).catch(error => {
            console.log(error)
        })
    }

    componentDidUpdate(prevProps, prevState){
      if (prevProps.CMS.hasMessage == 0 && this.props.CMS.hasMessage == 1){
        console.log("hasMessage triggered:checking messages...");
        this.checkMessages();
      }

    }



    checkMessages = () => {
        getMessages(this.props.match.params.bookingReference).then(response => {
            if (response.data.length > this.state.messages.length) {           
                this._messagesEnd.scrollIntoView()
            }
            this.setState({
                messages: response.data
            })
            
            //this.setState({pollingReference: setTimeout(this.checkMessages, 5000) })
        }).catch(error => {
            console.log(error)

            //this.setState({pollingReference: setTimeout(this.checkMessages, 5000) })
        })
    }

    componentWillUnmount = () => {
        clearTimeout(this.state.pollingReference)
    }

    handleChange = (event) => {
        this.setState({
            messageValue: event.target.value
        })
    }
    
    handleKeyDown = (event) => {
      if(event.keyCode == 13){
         this.handleSend();
         event.preventDefault();
      }
   }

    handleSend = () => {
        const message = this.state.messageValue
        const bookingRef = this.props.match.params.bookingReference

//        let currentMessages = this.state.messages
        this.setState({messageValue: ''})
        this._messagesEnd.scrollIntoView()

        postMessage({'content': message}, bookingRef).then(() => {
            getMessages(bookingRef).then(response => {
                this.setState({messages: response.data})
                this._messagesEnd.scrollIntoView()
            })
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return(
            <div style={{height: '100%'}}>
                <LoadingModal open={this.state.loading} />
                
                {!this.state.loading && <div style={styles.messagingContainer}>
                    <div style={styles.topSection}>
                        <IconButton style={styles.topLeftButton}
                        onClick={this.props.history.goBack}>
                            <IconBack style={{fontSize: '30px'}}/>
                        </IconButton>
                        <Typography variant="title" align="center">
                            {this.state.otherUser.first_name}
                        </Typography>
                    </div>
                    {this.state.messages.length === 0 ?
                    <div style={{flexGrow: 1, padding: '16px', overflowY: 'scroll'}}>
                        <Typography style={{textAlign: 'center', color:'#acacac'}}>
                            Start a conversation with {this.state.otherUser.first_name}
                        </Typography>
                    </div>
                    :<div style={{flexGrow: 1, padding: '16px', overflowY: 'scroll'}}>
                        {this.state.messages.map((message, i) => {
                            if ((this.props.match.params.role === 'driver' && message.is_driver === 1) ||
                                (this.props.match.params.role === 'owner' && message.is_driver === 0)) {
                                return(
                                    <Grid style={styles.messageContainer} spacing={8} key={i} container justify="flex-start" alignItems="flex-end">
                                        <Grid item>
                                            {message.user.image_user !== null
                                            ? <img style={styles.messageProfilePicture} src={message.user.image_user.path} alt="user profile"/>
                                            : <img style={styles.messageProfilePicture} src="https://www.sharewithoscar.com.au/assets/graphics/profile_placeholder.png" alt="user profile"/>}
                                        </Grid>
                                        <Grid style={{...styles.messageContent, ...styles.receiverMessage}} item>
                                            <Typography gutterBottom variant="body1">
                                                {message.content}
                                            </Typography>
                                            <Typography variant="caption">
                                                {moment(message.update_date).fromNow()}    
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )    
                            } else {
                                return(
                                    <Grid style={styles.messageContainer} spacing={8} key={i} container justify="flex-end" alignItems="flex-end">
                                        <Grid style={{...styles.messageContent, ...styles.senderMessage}} item>
                                            <Typography gutterBottom variant="body1" color="inherit">
                                                {message.content}
                                            </Typography>
                                            <Typography variant="caption" style={{color: 'rgba(256, 256, 256, 0.5)'}}>
                                                {moment(message.update_date).fromNow()}    
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {message.user.image_user !== null
                                            ? <img style={styles.messageProfilePicture} src={message.user.image_user.path} alt="user profile"/>
                                            : <img style={styles.messageProfilePicture} src="https://www.sharewithoscar.com.au/assets/graphics/profile_placeholder.png" alt="user profile"/>}
                                        </Grid>
                                    </Grid>
                                )
                            }
                        })}
                    </div>}
                    <div ref={(el) => { this._messagesEnd = el; }}></div>
                    <div style={styles.messageInput}>
                        <Grid container alignItems="center">
                            <Grid item style={{flexGrow: 1}}>
                                <TextField
                                    multiline
                                    name="access"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{disableUnderline: true}}
                                    placeholder="Write your message..."
                                    fullWidth
                                    margin="normal"
                                    style={styles.messageInputField}

                                    value={this.state.messageValue}
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </Grid>
                            <Grid item>
                                <Button className="btn-message-send"
                                onClick={this.handleSend}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>}
            </div>
        )
    }
}