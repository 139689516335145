import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "@material-ui/core";

import '../styles/ColorSchemeWP.css';

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();
    const { error, token } = await stripe.createToken(elements.getElement(CardElement));

    if (token !== undefined) {
      props.token(token);
    }
  };

  let image = "https://www.sharewithoscar.com.au/assets/graphics/oscar_share.png";
  if (props.WPMode === 'bays'){
    
    if (props.WPDomain === 'demo'){
      image="https://demo.parkwithoscar.com.au/assets/graphics/logo-demo.png";
    } else {
      image="https://meriton.sharewithoscar.com.au/assets/graphics/logo-meriton.png";
    }
    
    return (
      <form onSubmit={handleSubmit} className="stripe meriton">
        <div style={{textAlign:"center"}}>
          <img src={image} style={{width: 150, margin: 10}} />
        </div>
        <div style={{margin: 20}}>
          <CardElement />
        </div>
        <br/>
        <div style={{textAlign:"center"}}>
          <Button className="wp-button" style={{width:'80%'}} type="submit" disabled={(stripe === false || props.loading === true)}>
            Add Card
          </Button>
        </div>
      </form>
    );
  

  }



  return (
    <form onSubmit={handleSubmit} className="stripe oscar">
      <div style={{textAlign:"center"}}>
        <img src={image} style={{width: 150, margin: 10}} />
      </div>
      <div style={{margin: 20}}>
        <CardElement />
      </div>
      <br/>
      <div style={{textAlign:"center"}}>
        <Button className="wp-button" style={{width:'80%'}} type="submit" disabled={(stripe === false || props.loading === true)}>
            Add Card
          </Button>

      </div>
    </form>
  );
};


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const AddCreditCard = props => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
);

export default AddCreditCard;
