import React, { Component } from "react";
import {Link} from "react-router-dom";

import {
    getReferrer
} from '../api/Oscar-api.js';

import { isNativeWebview } from '../utils/IsNativeWebview';

import {
    Button,
    Grid,
    Hidden,
    Typography,
    withStyles,
} from '@material-ui/core';
import { whiteInputStyles } from '../styles/OscarTheme.js';

import LoadingModal from '../components/LoadingModal.js';

const styles = {
    noAccount: {
        borderRadius: '32px',
        color: 'white',
        border: '2px solid white',
    },
    rightCornerButton: {
        position: 'absolute',
        top: 10,
        right: 5,
        color: 'white',
    },
    progressButton: {
        backgroundColor: 'white',
        boxShadow: 'none',
    },
    button: {
        backgroundColor: 'white',
        paddingTop: '14px',
        paddingBottom: '14px',
        borderRadius: '6px',
        color: '#1AA5A2',
        boxShadow: 'none',
        fontWeight: 'bold',

        width: '320px',
    },

    gridImage: {
//        width: '260px',
        height: '185px',
        marginTop: '36px',
        marginBottom: '12px',
    },
    gridBodyText: {
        textAlign: 'center'
    }
}

class SignUpReferral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            referralCode: props.match.params.referralCode !== undefined ? props.match.params.referralCode : undefined,
            signupCode: props.match.params.promoCode !== undefined ? props.match.params.promoCode : undefined
        };
    }

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    componentWillMount() {
        this.props.forceHideNav(true)

        getReferrer({referral: this.state.referralCode}).then(result => {
            if (result.data.length > 0) {
                this.setState({referrer: result.data[0]['first_name'], loading: false})
            } else {
                this.props.history.push('/signup')
            }
        })
    }

    
    componentWillUnmount() {
        this.props.forceHideNav(false)
    }

    render() {
        return (
            <div style={{ overflowY: 'auto', height: '100%'}}>
<div className="gradientPage">
                <LoadingModal open={this.state.loading}/>

                <Hidden smUp>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: '220px', marginTop: '32px'}}>
                        <img alt="oscar" style={{width: '100%'}} src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_white.svg" />
                    </div>    
                </div>
                </Hidden>            
                
                <Grid container>
                    <Grid xs={12} sm={2}>
                        <div style={{overflow: 'hidden'}}>
                            <Hidden smUp>
                            <img alt="celebration" style={{marginLeft: '-40px'}} src="https://www.sharewithoscar.com.au/assets/graphics/popper.png" />
                            </Hidden>
                            <Hidden xsDown>
                            <img alt="celebration" style={{width: '120%', marginLeft: '-60px'}} src="https://www.sharewithoscar.com.au/assets/graphics/popper.png" />
                            </Hidden>
                        </div>
                    </Grid>

                    <Grid xs={12} sm={8}>
                        <div className="mainContent">
                            <Hidden xsDown>
                            <div style={{height: '84px'}}></div>
                            </Hidden>
                            <Typography style={{color: 'white', textAlign: 'center'}} color="primary" variant="display1">
                                {this.state.referrer} gave you $5 to try Oscar!
                            </Typography>
                            <Typography style={{color: 'white', textAlign: 'center', marginBottom: '24px'}} color="primary" variant="body1">
                                Oscar is the best way to find parking in the most congested areas of our cities. Oscar's community are sharing their parking spots with you.
                            </Typography>
                        </div>

                        <div style={{textAlign: 'center'}}>
                            <Button style={styles.button}
                            component={Link} to={`/SignUp/${this.state.referralCode}`}>
                                Sign up to claim your gift
                            </Button>
                        </div>

                        <Hidden xsDown>
                            <Typography style={{color: 'white', textDecoration: 'underline', textAlign: 'center', marginTop: '88px', marginBottom: '36px'}} color="primary" variant="body1">
                            <span
                                onClick={() => this.externalLink("https://www.sharewithoscar.com.au/terms-and-conditions/", "https://www.sharewithoscar.com.au/terms-and-conditions/")}>Terms and Conditions</span>
                            </Typography>
                        </Hidden>

                    </Grid>

                    <Grid  xs={12} sm={2} alignContent="flex-end">
                        <div style={{display: 'flex', height: '100%', alignItems: 'flex-end'}}>
                            <div style={{overflow: 'hidden', textAlign: 'right', width: '100%'}}>
                                <Hidden smUp>
                                <img alt="celebration" style={{transform: `rotate(285deg)`, marginRight: '-80px'}} src="https://www.sharewithoscar.com.au/assets/graphics/popper.png" />
                                </Hidden>
                                <Hidden xsDown>
                                <img alt="celebration" style={{width: '120%', transform: `rotate(285deg)`, marginRight: '-100px'}} src="https://www.sharewithoscar.com.au/assets/graphics/popper.png" />
                                </Hidden>
                            </div>
                        </div>
                    </Grid>
                </Grid>

            </div>

            <Hidden smUp>
                <div style={{width: '100%', marginTop: '-44px'}}>
                <Typography style={{color: 'white', textDecoration: 'underline', textAlign: 'center'}} color="primary" variant="body1">
                <span
                    onClick={() => this.externalLink("https://www.sharewithoscar.com.au/terms-and-conditions/", "https://www.sharewithoscar.com.au/terms-and-conditions/")}>Terms and Conditions</span>
                </Typography>
                </div>
            </Hidden>
            
            <div style={{padding: '46px', marginTop: '48px'}}>
                <Typography style={{color: 'black', fontSize: '28px'}} variant="display1">
                    How it works
                </Typography>

                <Grid container spacing={24}>
                    <Grid style={styles.gridBodyText} item sm={12} md={4}>
                        <img alt="look" style={styles.gridImage} src="https://www.sharewithoscar.com.au/assets/graphics/look.svg"/>
                        <Typography style={{fontWeight: 'normal'}} variant="headline">
                            Look
                        </Typography>
                        <Typography variant='body1'>
                            Find thousands  of parking spaces available to book by the hour or longer term.
                        </Typography>
                    </Grid>
                    <Grid style={styles.gridBodyText} item sm={12} md={4}>
                        <img alt="book" style={styles.gridImage} src="https://www.sharewithoscar.com.au/assets/graphics/book.svg"/>
                        <Typography style={{fontWeight: 'normal'}} variant="headline">
                            Book
                        </Typography>
                        <Typography variant='body1'>
                            Book and pay through Oscar's secure platform on-the-go or weeks in advance.
                        </Typography>
                    </Grid>
                    <Grid style={styles.gridBodyText} item sm={12} md={4}>
                        <img alt="park" style={styles.gridImage} src="https://www.sharewithoscar.com.au/assets/graphics/park.svg"/>
                        <Typography style={{fontWeight: 'normal'}} variant="headline">
                            Park
                        </Typography>
                        <Typography variant='body1'>
                            Follow the navigation directions and easy instructions. Even extend the booking time.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
            </div>
        )
    }
}

export default withStyles(whiteInputStyles)(SignUpReferral)