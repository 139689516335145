import React, {Component} from 'react';
import moment from 'moment';

import Picker from 'react-mobile-picker-scroll';
import {
    TwelveHourTime,
    WeekdayDate,
    WeekdayDateTime
} from '../utils/TimeFormats.js';

import { 
    Button,
    Grid,
    Modal,
    Typography,
    withStyles
} from '@material-ui/core'
import IconArrowDown from '@material-ui/icons/ArrowDropDown'

import '../styles/DateTime.css'

const modalStyle = {
    bottom: `0%`,    
    left: `50%`,
    transform: `translate(-50%, 0%)`,
}

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '320px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        paddingTop: theme.spacing.unit * 5,
    },
});

const elementStyles = {
    activeSelection: {
        color: 'white',
        position: 'fixed', 
        zIndex: 1400,
        //width: '146px'
    },
    inactiveSelection: {
        position: 'relative',
    },
    pickerTime: {
        flexGrow: 1,
        padding: '16px',
        paddingTop: '8px'
    },
    pickerTimeDesktop: {
        flexGrow: 1,
        //backgroundColor: 'white',
        padding: '8px 4px',
//        paddingTop: '4px'
    },
    inactiveSelectionDesktop: {
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '2px 8px',
        width: '156px',
        height: '54px',
    },
    pickerTimeHeading: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '13px',
        marginBottom: '8px',
    },
    setTimeButton: {
        position: 'absolute',
        top: 5,
        right: 6,
    },
    timeControls: {        
        borderTop: '1px solid #efefef',
        paddingTop: '12px',
        paddingBottom: '12px'
    },
    selectDropArrow: {
        // position: 'absolute',
        // right: '0px',
        // bottom: '2px'
        float: 'right'
    }
}

class PickerModal extends Component {
    state = {
        valueGroups: this.props.valueGroups
    }

    handleChange = (name, value) => {
        this.setState(({valueGroups}) => ({
            valueGroups: {
                ...valueGroups,
                [name]: value
            }
        }));
    };

    handleDone = () => {
        // Close the modal when this button is pressed whether we update anything or not
        this.props.onClose();
        
        // Deep compare the current value groups to the original props
        for(const prop in this.state.valueGroups) {
            if (this.state.valueGroups[prop] !== this.props.valueGroups[prop]) {
                this.props.onTimeChange(this.state.valueGroups)
            }
        }

        this.props.onTimeChange(this.state.valueGroups)
    }

    render() {
        const { classes } = this.props;
        const {optionGroups} = this.props;

        return(
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.props.open}
                    onClose={this.props.onClose}
                >
                    <div style={modalStyle} className={classes.paper}>
                        <Button color="primary" style={elementStyles.setTimeButton} onClick={this.handleDone}>
                            Done
                        </Button>
                        <Typography variant="headline" align="center">
                            {this.props.modalHeader}
                        </Typography>
                        <Picker 
                            optionGroups={optionGroups}
                            valueGroups={this.state.valueGroups}
                            onChange={this.handleChange} />
                    </div>
                </Modal>
            </div>
        )
    }
}


function generateDateOptions(futureLimit) {
    let now = moment();
    let dateOpts = [now.format("ddd D MMM")]
    for (let i = 0; i < futureLimit; i++) {
        dateOpts.push(now.add(1, 'days').format("ddd D MMM"))
    }
    return dateOpts
}
function generateHourOptions() {
    let hours = [];
    for(let i = 1; i < 13; i++) {
        hours.push(`${i}`)
    }
    hours.push('1')
    
    return hours
}
const minuteInterval = 15;
function generateMinuteOptions() {
    let minutes = [];
    for(let i = 0; i < 60; i += minuteInterval) {
        // Zero pad all minute options before 10 minutes
        minutes.push(`${i < 10 ? 0 : ''}${i}`)
    }
    return minutes
}
function roundMinutes(dateTime) {
    // Don't round up if we're sitting exactly on an interval
    if (dateTime.minutes() % minuteInterval === 0) {
        return dateTime
    }
    const intervalGap = minuteInterval - dateTime.minutes() % minuteInterval
    return dateTime.minutes(dateTime.minutes() + intervalGap).seconds(0)
}
export default class DateTimeRangePicker extends Component {
    constructor(props) {
        super(props)
        
        //console.log("creating date time picker")
        this.state = {
            startTime: roundMinutes(props.start),
            endTime: roundMinutes(props.end),
            open: false, 
            editTime: props.start
        }
    }

    handleStartTimeChange = (newTime) => {
        //console.log('start time change')
        let newStart;
        if(this.props.withDate) {
            if (newTime.date.indexOf("Jan__") !== -1) {
                let now = moment();
                let year = (now.month == 0) ? now.year() : now.year() + 1;
                newTime.date = year + " " + newTime.date;
                newStart = moment(`${newTime.date} ${newTime.hour}:${newTime.minute} ${newTime.ampm}`, "YYYY ddd D MMM h:mm A")
            } else {                               
                newStart = moment(`${newTime.date} ${newTime.hour}:${newTime.minute} ${newTime.ampm}`, WeekdayDateTime)
            }
        } else {
            newStart = moment(`${newTime.hour}:${newTime.minute} ${newTime.ampm}`, TwelveHourTime)

            // Base the DateTime off the start date rather than the current date.
            newStart.year(this.props.start.year())
            newStart.month(this.props.start.month())
            newStart.date(this.props.start.date())
        }
        
        // Make sure start time stays ahead of current time.
        if (this.props.enforceFuture && newStart.isBefore(moment())) {
            newStart = roundMinutes(moment())
        }

        // If start time is ahead of end time set end time an hour ahead of new start time
        let newEnd = this.state.endTime
        if (newStart.isAfter(newEnd)) {
            newEnd = moment(newStart).add(1, 'hour')
        }

        // Force end to be same day as start of this option is set
        if (this.props.singleDayRange === true) {
            newEnd.date(newStart.date())
        }

        // Automatically edit the end time after we edit the start time.
        if (this.props.disableAutoEditEnd === true) {
            this.setState({
                startTime: newStart,
                endTime: newEnd,
                open: false,
            }, this.props.onTimeRangeSelected(newStart, newEnd))
        } else {
            this.setState({
                startTime: newStart,
                endTime: newEnd,
                open: true,
                editTime: newEnd,
                editHandler: this.handleEndTimeChange,
                modalHeader: this.props.verticalForm ? 'To' : null,
                startChanged: true,
            })    //, this.props.onTimeRangeSelected(newStart, newEnd)
        }
    }
    handleEndTimeChange = (newTime) => {
        let newEnd;
        if (this.props.withDate) {
            if (newTime.date.indexOf("Jan__") !== -1) {
                let now = moment();
                let year = (now.month == 0) ? now.year() : now.year() + 1;
                newTime.date = year + " " + newTime.date;
                newEnd = moment(`${newTime.date} ${newTime.hour}:${newTime.minute} ${newTime.ampm}`, "YYYY ddd D MMM h:mm A")
            } else {                            
                newEnd = moment(`${newTime.date} ${newTime.hour}:${newTime.minute} ${newTime.ampm}`, WeekdayDateTime)
            }
        } else {

            newEnd = moment(`${newTime.hour}:${newTime.minute} ${newTime.ampm}`, TwelveHourTime);
            
            // Base the DateTime off the start date rather than the current date.
            let start = moment(this.state.startTime);

            newEnd.year(start.year())
            newEnd.month(start.month())
            newEnd.date(start.date())
        }
        // Force end time to stay > start time + 15min
        if (newEnd.isBefore(moment(this.state.startTime).add(15, 'minutes'))) {
            newEnd = moment(this.state.startTime).add(15, 'minutes')
        }

        // If we're extending discard any end time changes that are before the original end time
        if (this.props.extending) {
            if (newEnd.isBefore(this.props.end)) {
                return
            }
        }

        // Force the end time to be within 24hrs of the start time
        /*
        if (newEnd.isAfter(moment(this.state.startTime).add(24, 'hours'))) {
            newEnd = moment(this.state.startTime).add(24, 'hours')
            // do shit here to show a message.
            // alert('over 24 hrs!')
            if (this.props.onOver24HrPicked) {
                this.props.onOver24HrPicked()
            }
        }
        */
       
        this.setState({
            endTime: newEnd
        }, this.props.onTimeRangeSelected(this.state.startTime, newEnd))
    }

    render() {
        const PickerModalStyled = withStyles(styles)(PickerModal)
        let optionGroups = {
            date: generateDateOptions(30),
            hour: generateHourOptions(),
            minute: generateMinuteOptions(),
            ampm: ['AM', 'PM']
        }

        if (this.props.singleDayRange) {
            if (this.state.editTime.isSame(this.state.endTime)) {
                optionGroups.date = [
                    this.state.startTime.format("ddd D MMM"),
                    moment(this.state.startTime).add(1, 'day').format("ddd D MMM"),
                ]
            }    
        }

        let valueGroups = {
            date: this.state.editTime.format(WeekdayDate),
            hour: this.state.editTime.format("h"),
            minute: this.state.editTime.format("mm"),
            ampm: this.state.editTime.format("A"),
        }
        if (!this.props.withDate) {
            delete optionGroups.date
            delete valueGroups.date
        }
        const displayFormat = this.props.withDate ? WeekdayDateTime : TwelveHourTime
        return(
            <div>
                           
                {this.state.open &&
                <div>
                    <PickerModalStyled 
                        open={this.state.open}
                        onChange={this.handleChange}
                        onClose={() => { 
                            if (this.state.startChanged) {
                                this.setState({open: false, startChanged: false})
                                this.state.editHandler(valueGroups)
                            } else {
                                this.setState({open: false})
                            }
                        }}
                        onTimeChange={this.state.editHandler}
                        editTime={this.state.editTime}
                        modalHeader={this.state.modalHeader}
                        optionGroups={optionGroups}
                        valueGroups={valueGroups}
                    />
                </div>}

                {this.props.verticalForm ?                
                <div>
                    {!this.props.extending ?
                    <Grid style={elementStyles.timeControls} container justify="space-between" alignItems="center" onClick={() =>
                        this.setState({
                            open: true, editTime: this.state.startTime, editHandler: this.handleStartTimeChange, modalHeader: 'From'
                        })}>
                        <Grid>
                            {this.props.mixedForm !== undefined ? 
                            <Typography variant="caption" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>
                                {this.props.startText}
                            </Typography> :
                            <Typography variant="body1" >
                                {this.props.startText}
                            </Typography>}
                        </Grid>
                        <Grid>
                            <Typography variant="body1" color="primary" style={{color:'var(--heroColor2)'}}>
                                {this.state.startTime.format(displayFormat)} {/* LongTermEnquiry */}
                            </Typography>
                        </Grid>
                    </Grid>
                    :<Grid style={elementStyles.timeControls} container justify="space-between" alignItems="center">
                        <Grid>
                            <Typography variant="body1" >
                                {this.props.startText}
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant="body1" style={{color: '#acacac'}}>
                                {this.state.startTime.format(displayFormat)}
                            </Typography>
                        </Grid>
                    </Grid>}
                    {this.props.disableEnd === undefined && <Grid style={elementStyles.timeControls} container justify="space-between" alignItems="center" onClick={() =>
                        this.setState({
                            open: true, editTime: this.state.endTime, editHandler: this.handleEndTimeChange, modalHeader: 'To'
                        })}>
                        <Grid>
                            {this.props.mixedForm !== undefined ? 
                            <Typography variant="caption" style={{textTransform: 'uppercase', fontWeight: 'bold'}}>
                                {this.props.endText}
                            </Typography> :
                            <Typography variant="body1" >
                                {this.props.endText}
                            </Typography>}
                        </Grid>
                        <Grid>
                            <Typography variant="body1" color="primary">
                                {this.state.endTime.format(displayFormat)}
                            </Typography>
                        </Grid>
                    </Grid>}
                </div> :
                this.props.desktopDisplay === true ?
                <Grid container>
                    <Grid item style={elementStyles.pickerTimeDesktop} onClick={() =>
                        this.setState({
                            open: true, editTime: this.state.startTime, editHandler: this.handleStartTimeChange 
                        })}
                    >
                    <div style={elementStyles.inactiveSelectionDesktop}>
                        <div style={this.state.open && this.state.editTime === this.state.startTime ? elementStyles.activeSelection:elementStyles.inactiveSelection}>
                            <Typography color="inherit" gutterBottom variant="body2" style={elementStyles.pickerTimeHeading}>
                                {this.props.startText}
                            </Typography>
                            {this.state.open && this.state.editTime === this.state.startTime ? null :
                            <IconArrowDown style={elementStyles.selectDropArrow} />}
                            <Typography color="inherit" variant="body2" style={{whiteSpace: 'nowrap'}} >
                                {this.state.startTime.isSame(moment(), 'date')
                                ? this.state.startTime.calendar(moment(), {sameDay: '[Today] LT'})
                                : this.state.startTime.format(displayFormat)
                                }
                            </Typography>

                        </div>
                    </div>
                    </Grid>
                    <Grid item style={elementStyles.pickerTimeDesktop} onClick={() =>
                        this.setState({
                            open: true, editTime: this.state.endTime, editHandler: this.handleEndTimeChange 
                        })}
                    >
                    <div style={elementStyles.inactiveSelectionDesktop}>
                        <div style={this.state.open && this.state.editTime === this.state.endTime ? elementStyles.activeSelection:elementStyles.inactiveSelection}>
                            <Typography color="inherit" gutterBottom variant="body2" style={elementStyles.pickerTimeHeading}>
                                {this.props.endText}
                            </Typography>
                            {this.state.open && this.state.editTime === this.state.endTime ? null :
                            <IconArrowDown style={elementStyles.selectDropArrow} />}
                            <Typography color="inherit" variant="body2" style={{whiteSpace: 'nowrap'}} >
                                {this.state.endTime.isSame(moment(), 'date')
                                ? this.state.endTime.calendar(moment(), {sameDay: '[Today] LT'})
                                : this.state.endTime.format(displayFormat)
                                }                                
                            </Typography>
                        </div>
                    </div>
                    </Grid>                    
                </Grid>
                : <Grid container>
                    <Grid xs={6} item style={{...{borderRight: '1px solid #e5e5e5'}, ...elementStyles.pickerTime}}onClick={() =>
                        this.setState({
                            open: true, editTime: this.state.startTime, editHandler: this.handleStartTimeChange 
                        })}
                    >
                        <div style={this.state.open && this.state.editTime === this.state.startTime ? elementStyles.activeSelection:elementStyles.inactiveSelection}>
                            <Typography color="inherit" gutterBottom variant="body2" style={elementStyles.pickerTimeHeading}>
                                {this.props.startText}
                            </Typography>
                            {this.state.open && this.state.editTime === this.state.startTime ? null :
                            <IconArrowDown style={elementStyles.selectDropArrow} />}
                            <Typography color="inherit" variant="body2" style={{whiteSpace: 'nowrap'}} >
                                {this.state.startTime.isSame(moment(), 'date')
                                ? this.state.startTime.calendar(moment(), {sameDay: '[Today] LT'})
                                : this.state.startTime.format(displayFormat)
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid xs={6} item style={elementStyles.pickerTime} onClick={() =>
                        this.setState({
                            open: true, editTime: this.state.endTime, editHandler: this.handleEndTimeChange 
                        })}
                    >
                        <div style={this.state.open && this.state.editTime === this.state.endTime ? elementStyles.activeSelection:elementStyles.inactiveSelection}>
                            <Typography color="inherit" gutterBottom variant="body2" style={elementStyles.pickerTimeHeading}>
                                {this.props.endText}
                            </Typography>
                            {this.state.open && this.state.editTime === this.state.endTime ? null :
                            <IconArrowDown style={elementStyles.selectDropArrow} />}
                            <Typography color="inherit" variant="body2" style={{whiteSpace: 'nowrap'}}>
                                {this.state.endTime.isSame(moment(), 'date')
                                ? this.state.endTime.calendar(moment(), {sameDay: '[Today] LT'})
                                : this.state.endTime.format(displayFormat)
                                }
                                
                            </Typography>

                        </div>
                    </Grid>
                </Grid>}
            
            </div>
        )
    }
}