import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import { 
  Button
} from '@material-ui/core';

import { 
    getOwnerBookingDetailsCP,
    setBookingStatus
} from '../api/Oscar-api.js';

import {
    BookingsRangeDisplayFormat,
    ParseDateTimeParameters,
    WPMonthlyBookingsDisplayFormat
} from '../utils/TimeFormats';

//import ENUMS from '../api/ENUMS.json';
import { FieldType } from '../api/fieldTypes.js';

import MessagesCP from '../views/MessagesCP.js';

import {
    Typography,
} from "@material-ui/core";

import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingModal from '../components/LoadingModal';
import LoadingModalWP from '../components/LoadingModalWP';
import EditItem_Form from '../components/EditItem_Form.js';
import SelectDropDown from '../components/SelectDropDown';


import { isNativeWebview } from '../utils/IsNativeWebview';

const styles = {
    leftTopButton :{
        position: 'absolute',
        zIndex: '3',
    },
    contentContainer: {
        height: '100%',
        overflowY: 'auto',
    },
    descriptionContentContainer: {
        marginTop: '8px',
        padding: '16px',
        flexGrow: '1',
    },
    container: {
        padding: '0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    rate: {
        fontWeight: 'bold',
    },
    bookingButtonContainer: {
        width: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
    },
    bookingButton: {
        padding: '22px',
    },
    descriptions: {
        paddingLeft: '16px',
    },
    distanceText: {
        textTransform: 'uppercase',
        color: "#acacac",
    },
    imageCarousel: {
        width: '100%',
        height: '250px',
    },
    carouselImage: {
        width: 'auto',
        height: 'auto'
    },
    subheading: {
        marginTop: '16px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    reviewIcon: {
        fontSize: '13px',
        marginRight: '4px'
    },

    messageButton: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
        color: '#1AA5A2',
        backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover, &:focus': {
            backgroundColor: 'white',
        },
    },
    editButton: {
      fontSize:'16px',  
      color: '#1AA5A2',
        backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover, &:focus': {
            backgroundColor: 'white',
        },
        border: '1px solid #1AA5A2'
    },

    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '10px',
    },
    nextButton: {
        position: 'absolute',
        boxShadow: 'none',
        bottom: 16,
        right: 16,
    },

    topColored: {
        paddingTop: '80px',
        backgroundColor: '#1AA5A2',
        paddingBottom: '8px',
    },
    paymentSetting: {
        //paddingTop: '8px',
        //paddingBottom: '8px',
        borderBottom: '1px solid #efefef'
    },
    switchField: {
        borderTop: '1px solid #acacac',
        borderBottom: '1px solid #acacac',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '12px',
        marginBottom: '12px',
    },
    transactionsContainer: {
        marginTop: '16px'
    },
    transactionContainer: {
        borderTop: '1px solid #efefef',
        paddingTop: '8px',
        paddingBottom: '6px',
    },
    switchBorder: {
        marginTop: '24px',
        borderTop: '1px solid #efefef',
    },

    pickerContainer: {
        minHeight: '550px', 
        backgroundColor: 'white',
        paddingTop: '32px',
    },

    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    },
    link: {
        display: 'inline-block',
        marginLeft: '4px',
        textDecoration: 'underline',
    }
}


export default class BookingDetailsCP extends Component {
  state = {
      loading: true
  };

  componentDidMount() {
    //console.log("componentDidMount:BookingDetailsCP");  
    this.loadBooking();
  }

  loadBooking() {
      //console.log("loadBooking...");  

      let that = this;
      const bookingRef = this.props.match.params.reference;
      getOwnerBookingDetailsCP(bookingRef).then(details => {
        //console.log(details.data);
        const booking = details.data;
        //booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters);
        //booking.end = 

        let str_start_date;
        let str_end_date;
        if (booking.parking_space){
          str_start_date = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters).format(BookingsRangeDisplayFormat);

          if (booking.cease_date){
            str_end_date = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters).format(BookingsRangeDisplayFormat);
          } else {
            str_end_date = "Monthly Ongoing";
          }
          
        } else{
          str_start_date = moment(booking.start_date).format(WPMonthlyBookingsDisplayFormat);

          if (booking.cease_date){
            str_end_date = moment(booking.cease_date).format(WPMonthlyBookingsDisplayFormat);
          } else {
            str_end_date = "Monthly Ongoing";
          }
        }  
        booking.str_start_date = str_start_date;
        booking.str_end_date = str_end_date;



        that.setState({
          loading: false,
          booking
        });

      }).catch(function(err) {
        // NEW ERROR HANDLING
        //console.log(JSON.stringify(err));
        let errorMessage = err.toString();
        if (err.response && err.response.data && err.response.data.detail){
          //console.log("err.response.data:",err.response.data.detail);
          errorMessage= `${err.response.data.detail}`;
        }
        that.setState({errorMessage});
      });
  }

  //============================================================
  // Accept/Decline requests
  //============================================================
  doAcceptRequest = (booking) => {
    this.setState({inProgress: true});

    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "2", // Approved by Owner
//      "reason": this.state.owner_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({inProgress: false, acceptRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });
  }

  doDeclineRequest = (booking) => {
    this.setState({inProgress: true});

    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "3", // Cancelled by Owner
      "reason": this.state.owner_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({inProgress: false, declineRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });


  }  

  //============================================================
  // Render
  //============================================================
  render() {
    const { loading, inProgress, errorMessage, booking, acceptRequestOpen, acceptRequestSuccessShow, declineRequestOpen, declineRequestSuccessShow } = this.state;

    // NEW ERROR HANDLING
    if (errorMessage){
      return (
        <div className="datagrid error">
            <h1>ERROR</h1>
            <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
              <h1>ERROR</h1>
              <p>{errorMessage}</p>
            </div>
        </div>
      );
    } else if (!booking || loading){
      return (
        <LoadingModalWP open={true} />
      );  
    }

    // Pre-generate data
    const reference = this.state.booking && this.state.booking.reference;
    const remoteName = this.state.booking && this.state.booking.driver && `${this.state.booking.driver.first_name}, ${this.state.booking.owner2.first_name}`;
    let address;
    if (booking && booking.parking_space){
      address = booking.expired ? booking.parking_space.street_name : booking.parking_space.address.replace("Australia", this.state.booking.parking_space.postcode);
    } else if (booking && booking.parking_bay){
      address = `${booking.parking_bay.building.address}, ${this.state.booking.parking_bay.building.suburb} ${this.state.booking.parking_bay.building.state}`;
    }
    
    let strApprovalCutOff;
    if (booking){
      strApprovalCutOff = moment(booking.create_date).clone().add(48,"hours").format("Do MMMM YYYY");
    }
    
    // ====================================================================================
    // We show Booking Details, AcceptScreen (+confirmation), DeclineScreen (+confirmation)
    // This should be moved to the new form system later... for now this is a quick bolt on
    // ====================================================================================
    let divContent;
    let divButtons;

    if (acceptRequestOpen){

      if (acceptRequestSuccessShow){

        // Accept Success
        divContent = (
          <>
            <h1>The booking request has been accepted</h1>
            <br/>
            <p>The driver has been notified that you have accepted this booking.</p>
          </>
        );
        divButtons = (
          <div className="sd-actions">
            <Button id="db_thumbs_up"  className="wp-button" style={{fontSize:'16px'}} onClick={() => this.props.history.push(`/cp/bookings`)}>Close</Button>
          </div>
        );   

      } else {
        // AcceptScreen
        divContent = (
          <>
          <LoadingModalWP open={inProgress} />
          <h1>Accept Booking Request</h1>
          <h3>{booking.str_start_date} - {booking.str_end_date}</h3>
          <p style={{marginBottom:'30px'}}>{booking.parking_space.type} on <b>{address}</b></p>
          <p>Are you sure you want to acccept the booking request?</p>
          <p>After accepting the request, you must provide at least <b>1 month</b> notice to cancel or change the booking.</p>
          </>
        );
        divButtons = (
          <div className="sd-actions">
            <Button id="db_thumbs_up"  className="wp-button" style={styles.editButton}  onClick={() => this.setState({acceptRequestOpen: false})}>Back</Button>
            <Button id="db_thumbs_down" className="wp-button" style={{fontSize:'16px'}} onClick={() => this.doAcceptRequest(booking)}>Confirm Booking</Button>
          </div>
        );   

      }



    } else if (declineRequestOpen){

      
      if (declineRequestSuccessShow){
        // Decline Success
        divContent = (
          <>
            <h1>The booking request has been declined</h1>
            <br/>
            <p>The driver has been notified that you have declined this booking.</p>
          </>
        );
        divButtons = (
          <div className="sd-actions">
            <Button id="db_thumbs_up" className="wp-button" style={{fontSize:'16px'}} onClick={() => this.props.history.push(`/cp/bookings`)}>Close</Button>
          </div>
        );   

      } else {
        // DeclineScreen
        const thisField = {"isRequired":true, "id":"cancellation_reason", "label":"Reason for declining this booking","type":FieldType.SELECT_DROPDOWN, domain:'owner_monthly_cancellation_reason'};
        let fieldErrors = {};
        
        divContent = (
          <>
          <LoadingModalWP open={inProgress} />
          <h1>Decline Booking Request</h1>
          <h3>{booking.str_start_date} - {booking.str_end_date}</h3>
          <p style={{marginBottom:'30px'}}>{booking.parking_space.type} on <b>{address}</b></p>

          <SelectDropDown pageData={"pageData"} field={thisField} initialValue={this.state.owner_cancel_reason} error={fieldErrors[thisField.id]} onUpdate={(option) => this.setState({owner_cancel_reason:option.finalValue})} />

          </>
        );
        divButtons = (
          <div className="sd-actions">
            <Button id="db_thumbs_up" style={styles.editButton} fullWidth variant="contained" color="primary" onClick={() => this.setState({declineRequestOpen: false})}>Back</Button>
            <Button id="db_thumbs_down" className="wp-button" style={{fontSize:'16px'}} disabled={!this.state.owner_cancel_reason} onClick={() => this.doDeclineRequest(booking)}>Decline Booking</Button>
          </div>
        );   
        
      }


   
    } else {
      // Booking Details
      divContent = [];

      divContent.push(<h1 key={'title'}>Booking Details: {booking.reference}</h1>);
      if (booking.approval_status == 3){
        divContent.push(<h2 key={'cancelled'} style={{color:'#ff7777'}}>DECLINED BY OWNER</h2>);
      } else if (booking.approval_status == 4){
        divContent.push(<h2 key={'cancelled'} style={{color:'#ff7777'}}>REQUEST CANCELLED BY DRIVER</h2>);
      } else if (booking.cancelled){
        divContent.push(<h2 key={'cancelled'} style={{color:'#ff7777'}}>CANCELLED</h2>);
      }

      if (booking.parking_space){
        // Parking_Space (Oscar Casual/Monthly)
        divContent.push(
          <div key='c1'>
          <h3>{booking.str_start_date} - {booking.str_end_date}</h3>
          <p style={{marginBottom:'30px'}}>{this.state.booking.parking_space.type} on <b>{address}</b></p>
          
          <p>Name: <b>{booking.driver.first_name}</b><br/>
          Vehicle: {booking.vehicle.registration} - {booking.vehicle.make} {booking.vehicle.model}
          </p>
          
          {booking.owner_earnings ? 
          <p>Total Earnings: ${booking.owner_earnings.toFixed(2)}</p>:null
          }
          
          {booking.approval_status == 1 &&
            <p style={{marginTop:'30px'}}>You have until the end of <b>{strApprovalCutOff}</b> to accept this offer or it will be automatically cancelled.</p>
          }
          
          {booking.approval_status == 2 && 
          <>
            <h3>Description</h3>
            <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_space.description}}/>

            <h3>Access Instructions</h3>
            <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_space.instruction}}/>
          </>
          }

        </div>
        );

      } else if (booking.parking_bay){
        // Parking_Bay (Meriton/Community)
        divContent.push(
          <div key='c1'>
          <h3>{this.state.booking.str_start_date} - {this.state.booking.str_end_date}</h3>
          <p>{this.state.booking.parking_bay.building.building_name} at <b>{address}</b></p>

          <h3>Spot Owner</h3>
          <p>Name: <b>{booking.owner2.first_name}</b><br/>
          Mobile:  {booking.owner2.telephone_number}<br/>
          Tenant ID: {booking.owner2.tenant_id}<br/>
          Bay #: {booking.parking_bay.bay_marking}
          </p>

          <h3>Driver</h3>
          <p>Name: <b>{booking.driver.first_name}</b><br/>
          Mobile:  {booking.driver.telephone_number}<br/>
          Tenant ID: {booking.driver.tenant_id}<br/>
          Lease End Date: {booking.driver.lease_end_date}<br/>
          Building: {booking.driver.default_building && booking.driver.default_building.building_name}<br/>
          Vehicle: {booking.vehicle.registration} - {booking.vehicle.make} {booking.vehicle.model}

          </p>

          <h3>Description</h3>
          <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_bay.description}}/>

          <h3>Access Instructions</h3>
          <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_bay.instruction}}/>

        </div>
        );

      }
      if (booking.approval_status == 1){
        // Waiting for approval
        divButtons = (
          <div className="sd-actions">
            <Button id="db_thumbs_up" className="wp-button" style={styles.editButton} fullWidth variant="contained" color="primary" onClick={() => this.setState({declineRequestOpen: true})}>Decline Booking</Button>
            <Button id="db_thumbs_down" className="wp-button" style={{fontSize:'16px'}} onClick={() => this.setState({acceptRequestOpen: true})}>Accept Booking</Button>
          </div>
        );   
      } else if (booking.approval_status == 2){
        // Approved
        divContent.push(
          <div key='f1'>
          <h3>Access Code</h3>
          <EditItem_Form
            formId={`edit_booking`}
            initialData={booking}
            itemType={'booking'}
            itemId={booking.reference}
          />
          </div>
        );

      }
  
    }



      
    return (
      <div className="bookingcp-container">
        <div className="sd-container">
          <div className="sd-details">
            {divContent}
          </div>
          
          {divButtons}
        </div>
        <div className="message-container">
          <MessagesCP CMS={this.props.CMS} remoteName={remoteName} user={this.props.user} reference={this.props.match.params.reference} userRole={'owner'} history={this.props.history}/>
        </div>
      </div>  
      );

    }
}

//         </div>


      
            /*booking.parking_space &&
            <>
              <h3>{this.state.booking.str_start_date} - {this.state.booking.str_end_date}</h3>
              {this.state.booking.parking_space.type} on <b>{address}</b>

              <h3>Driver</h3>
              <p>Name: <b>{booking.driver.first_name}</b><br/>
              Vehicle: {booking.vehicle.registration} - {booking.vehicle.make} {booking.vehicle.model}
              </p>
              
              {booking.owner_earnings ? 
              <p>Total Earnings: ${booking.owner_earnings.toFixed(2)}</p>:null
              }
              
              {booking.approval_status == 1 &&
                <p>You have until the end of <b>{strApprovalCutOff}</b> to accept this offer or it will be automatically cancelled.</p>
              }
              
              {booking.approval_status == 2 && 
              <>
                <h3>Description</h3>
                <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_space.description}}/>

                <h3>Access Instructions</h3>
                <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_space.instruction}}/>
              </>
              }

            </>
            */

            /*booking.parking_bay &&
            <>
              <h3>{this.state.booking.str_start_date} - {this.state.booking.str_end_date}</h3>
              <p>{this.state.booking.parking_bay.building.building_name} at <b>{address}</b></p>

              <h3>Spot Owner</h3>
              <p>Name: <b>{booking.owner2.first_name}</b><br/>
              Mobile:  {booking.owner2.telephone_number}<br/>
              Tenant ID: {booking.owner2.tenant_id}<br/>
              Bay #: {booking.parking_bay.bay_marking}
              </p>

              <h3>Driver</h3>
              <p>Name: <b>{booking.driver.first_name}</b><br/>
              Mobile:  {booking.driver.telephone_number}<br/>
              Tenant ID: {booking.driver.tenant_id}<br/>
              Lease End Date: {booking.driver.lease_end_date}<br/>
              Building: {booking.driver.default_building && booking.driver.default_building.building_name}<br/>
              Vehicle: {booking.vehicle.registration} - {booking.vehicle.make} {booking.vehicle.model}

              </p>

              <h3>Description</h3>
              <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_bay.description}}/>

              <h3>Access Instructions</h3>
              <div dangerouslySetInnerHTML={{__html: this.state.booking.parking_bay.instruction}}/>

            </>
          */
