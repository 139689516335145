import React, {Component} from 'react';
import {Link, withRouter } from 'react-router-dom';
import moment from 'moment';


import { isNativeWebview } from '../utils/IsNativeWebview';
import {NavItemsWP, ProfileNavWP} from './NavigationMenu.js';

import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  Hidden,
  IconButton,
  Paper,
  Popper,
  Typography
} from '@material-ui/core'

import NotificationIcon from '@material-ui/icons/Notifications';
import {getCMS} from '../api/Oscar-api.js';
import MainNav from '../components/MainNav.js';

import '../styles/LayoutWP.css';
import '../styles/LayoutWP_Login.css';
import '../styles/LayoutWP_ParkingSearch.css';
import '../styles/LayoutWP_ParkingMap.css';
import '../styles/LayoutWP_SpaceDetails_Bookings.css';
//import "react-datepicker/dist/react-datepicker.css";
import '../styles/LayoutDateFix.css';
import '../styles/Modals.css';
import '../styles/v3Checkout.css';
import '../styles/v3Payouts.css';



const styles = {
  layoutContainer: {
      fontFamily:'Rubik',
      minWidth: '300px',
      //maxWidth: '1280px',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
  },
  navigationContainer: {
      width: '400px', 
      flexGrow: '0', 
      flexShrink: '0',
      borderRight: '2px solid #dedede'
  },
  contentContainer: {
    fontFamily:'Rubik',
      flexGrow: 1,
      alignItems: 'stretch',
      display: 'flex',
      overflowY: 'hidden',
  },
  contentWindow: {
      flexGrow: '1',  
      position: 'relative',
      flexBasis: '1px',
      overflowX: 'auto',
      overflowY: 'hidden',
  },
  appbarContainer: {
      //height: '60px',
      padding: '8px 18px',
      zIndex: '1300',
  },
  appbarButton: {
      margin: '0 0 0 16px'
  },
  userProfileButton: {
      marginLeft: '16px',
      //marginRight: '16px',
      padding: '4px 16px',
  },
  profileImage: {
      width: '30px', 
      height: '30px',
      borderRadius: '40px',
      marginRight: '8px',
  },
  unreadNotifications: {
      position: "absolute",
      left: '14px',
      top: '14px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
      backgroundColor: 'var(--heroColor2)',
  }
}

//TEMP DAMIAN
const CM_REFRESH_TIME = 15000;
const CM_REFRESH_TIME_ERROR = 20000;

// If in Messages mode, it will check notification more often
// Will only fetch from API if there are new messages (all notification checking load goes to AWS instead)
const CM_MESSAGES_REFRESH_TIME = 2000;
const CM_MESSAGES_TIME_ERROR = 10000;
class MasterLayoutWP extends Component {
  constructor(props){
    super(props);

    const location = this.props.history.location.pathname;
    const layoutMode = this.getLayoutMode(location.toLowerCase());
    //console.log("layoutMode:",layoutMode);
    //console.log("constructor",location);
    
    this._lastTimestamp = null;
    this.state = {
      layoutMode,
      ready: true,
      unreadNotifications: false,
      profileNavigationOpen: false,
      profileNavClick: true,
      nativeWebView: isNativeWebview()
    }
    
  }
  

  getLayoutMode(location){
    //console.log("getLayoutMode:",location);

    let fullScreen = false;
    let visibleNav = true;
    
    if (location.indexOf('/search') > -1){
      fullScreen = false;
      visibleNav = false;
    }

    // if location starts with any of these, it's fullScreen mode
    const arrFullscreen = [
      '/login',
      '/signup',
      '/forgotpassword',
      '/resetpassword',
      '/finishprofile',   // includes /finishprofile*
      '/referred'         // includes /referred*
    ];

    for (let i=0;i<arrFullscreen.length;i++){
      let a = arrFullscreen[i];
      let b = location.substr(0,a.length);

      //console.log("a:",a);
      //console.log("b:",b);
      //console.log(a==b);

      if (a==b){
        fullScreen = true;
        visibleNav = false;
        //console.log("match on:",arrFullscreen[i]);
      }
    }


    let layoutMode = {
      fullScreen,
      visibleNav
    }
        
    return layoutMode;

  }



  componentDidMount() {
    // Do first CMS refresh after a delay

    setTimeout(this.checkCMS, CM_MESSAGES_REFRESH_TIME);    

    /*this.backListener = browserHistory.listen(location => {
      console.log(location);
        if (location.action === "POP") {
          // Do your stuff
        }
      });     */
  }

  componentWillReceiveProps = (nextProps) => {
    //console.log("componentWillReceiveProps:",nextProps);
    //console.log("history-update:",nextProps.history.location.pathname);
    let layoutMode = this.getLayoutMode(nextProps.history.location.pathname.toLowerCase());
    //console.log("will receive - layoutMode:",layoutMode);
    this.setState({layoutMode});

  }
  /*componentDidUpdate(prevProps, prevState){
    console.log("componentDidUpdate:",this.props);
  }*/
  

  checkCMS = () => {
    // Desktop only - don't call API until user is authenticated
    if (!this.state.nativeWebView && (!this.props.user.authenticated || !this.props.user.profileComplete)){
      setTimeout(this.checkCMS, CM_REFRESH_TIME);
      return;
    }

    let REFRESH_TIME = CM_REFRESH_TIME;
    let REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;

    if (window.location.href.toLowerCase().indexOf('/messages/') > 0){
      REFRESH_TIME = CM_MESSAGES_REFRESH_TIME;
      REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;
    }


    getCMS(this.props.user.profile.email).then(response => {
      const {hasNotification, hasMessage, lastOwnerBooking} = this.props.CMS;
      const data = response.data;

      let CMS = data;
      CMS.timestamp = moment().toString();

      if (data.hasNotification !== hasNotification || data.hasMessage !== hasMessage || data.lastOwnerBooking !== lastOwnerBooking){
        this.props.setCMS(CMS);
      } else if (REFRESH_TIME == CM_MESSAGES_REFRESH_TIME) {
        this.props.setCMS(CMS);
      }

      //console.log("CM:",data);
      if (!this.state.nativeWebView) {
        setTimeout(this.checkCMS, REFRESH_TIME);
      }
  

    }).catch(error => {
      console.log(error);
      if (!this.state.nativeWebView) {
        setTimeout(this.checkCMS, REFRESH_TIME_ERROR);
      }
  
    });
  }


  /*componentDidMount() {
    console.log("[MasterLayourCP:componentDidMount]",this.props);
    //???
      setTimeout(() => {
          this.setState({
              nativeWebView: isNativeWebview(),
              ready: true
          });
      }, 1);
  }*/
  handleToggle = () => {
      this.setState(state => ({ profileNavigationOpen: !state.profileNavigationOpen }));
  };
  
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
          return;
      }

      this.setState({ profileNavigationOpen: false });
  };

  render() {
    //console.log("MasterLayoutWP:Render:",this.state);
    const CMS = this.props.CMS;

    const { layoutMode } = this.state;


/*    let strRoute = window.location.pathname.toLowerCase();
    //TODO: resetpassword, forgotpassword, finishprofile etc
    if (strRoute === '/' || strRoute === '/login'|| strRoute.substr(0,14) === '/resetpassword' || strRoute === '/signup' || strRoute.substr(0,7) === '/search' || strRoute.substr(0,9) === '/referred') {
      console.log("MasterLayoutWP:Render:visibleNav=false for strRoute: ",strRoute);
      visibleNav = false;
    } else {
      console.log("MasterLayoutWP:Render:visibleNav=true for strRoute: ",strRoute);
    }
    document.title = `Meriton powered by Oscar`;// ${strRoute}`;

    let fullScreen = false;
    if (strRoute.substr(0,6)=== '/login' || strRoute.substr(0,14)=== '/finishprofile'){
      fullScreen = true;

    } 
*/

    // Not used anymore
    if (!this.state.ready) {
        return(
            <div>Wait...</div>
        );
    }
    if (this.state.nativeWebView) {
        return(
            <div className="meriton" style={{height: '100vh'}}>
                {this.props.children}
            </div>
        ); 
    }




      if (layoutMode.fullScreen){

        let authLogo;
        let authContent;

        // Must duplicate in AuthWP for mobile intro screen
        if (window.WPDomain === 'community'){
          authLogo = <img src="https://community.sharewithoscar.com.au/assets/graphics/logo-oscar-community.svg" alt="Community Portal"/>;
        
          authContent = <>
          <h1>Resident Parking</h1>
          <h2>Need a parking space?</h2>
          <p>Find available spaces in your building</p>
          <h2>Have a spare parking space?</h2>
          <p>List it on the parking portal for rent</p>
          <p>Learn more <a href="https://www.sharewithoscar.com.au/community-faq/" target="_blank">FAQ</a></p>
          </>;

        } else if (window.WPDomain === 'demo'){
          authLogo = <img src="https://meriton.sharewithoscar.com.au/assets/demo-logo2.svg" alt="Demo Portal"/>;
        
          authContent = <>
          <h1>Demo Portal</h1>
          <h2>Need a parking space?</h2>
          <p>Find available spaces for rent</p>
          </>;

        } else if (window.WPDomain === 'bela') {

          authLogo = <img src="https://bela.parkwithoscar.com.au/assets/bela-logo.svg" alt="Bela powered by Oscar"/>;
        
          authContent = <>
          <h1>Bela Portal</h1>
          <h2>Need a parking space?</h2>
          <p>Find available spaces for rent</p>
          </>;
          

        } else {
          authLogo = <img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg" alt="Meriton powered by Oscar"/>;
        
          authContent = <>
          <h1>Parking Portal</h1>
          <h2>Need a parking space?</h2>
          <p>Find available spaces for rent</p>
          <h2>Have a spare parking space?</h2>
          <p>List it on the parking portal for rent</p>
          <p>Learn more <a href="https://www.sharewithoscar.com.au/meriton-faq/" target="_blank">FAQ</a></p>
          </>;

        }

        const divAuthImageBG = (
          <div className="lwpauth-imgholder">
            <div className="lwpauth-imgoverlay"></div>
            <div className="lpauth-imgcontent">
              <div className="lwpauth-logo">
                {authLogo}
              </div>

            <div style={{flexGrow:'1',marginLeft:'auto',marginRight:'auto'}}>
              {authContent}
            </div>
          </div>
        </div>
        );

/* 
              <div className="lwpauth-logo-mobile">
                <img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-blue3.svg" alt="Meriton powered by Oscar"/>
              </div>

*/
        
        return(
          <div className={"lwpauth-container " + window.WPDomain + " noselect"}>
            {divAuthImageBG}
            <div className="lwpauth-content">
              {this.props.children}
            </div>
            
          </div>
        ); 
      }

      let navMenu;
      let sideMenu;

      let default_building;
      if (this.props.user.authenticated){
        default_building = window.WPDomain === 'community' ? this.props.user.profile.default_building_community: this.props.user.profile.default_building;
      }


      if (this.props.user.authenticated){
        navMenu = (
          <Grid container alignItems="center" style={{height:'100%'}}>
            <Grid item>
              <a href={"https://www.sharewithoscar.com.au/"+window.WPDomain+"-faq/"} target="_blank" style={styles.appbarButton} variant="contained"
                id="wp_faq_nav">
                  FAQs
              </a>
            </Grid>

            {default_building && <Grid item><Button style={styles.appbarButton} variant="contained" component={Link} to='/listings' id="wp_add_spot_nav">List your spot</Button></Grid> }


            <Grid item>
              <Button style={styles.userProfileButton} variant="outlined" className="profile-menu-cp-toggle"
                  buttonRef={node => {
                      this.anchorEl = node;
                  }}
                  onClick={this.handleToggle}
              >
                  {this.props.user.profile.image_user === null || this.props.user.profile.image_user === undefined
                  ? <img src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" 
                      alt="Profile image"
                      style={styles.profileImage} />
                  : <img src={this.props.user.profile.image_user.path.replace("https://www.sharewithoscar.com.au/images/","https://images.sharewithoscar.com.au/") + "?tr=w-30,h-30,fo-face"} 
                      alt={`Profile image for ${this.props.user.profile.first_name} ${this.props.user.profile.last_name}`}
                      style={styles.profileImage} />}
                  {this.props.user.profile.first_name} {this.props.user.profile.last_name}
              </Button>
              <Popper open={this.state.profileNavigationOpen} anchorEl={this.anchorEl} transition disablePortal style={{zIndex:'60'}}>
                  {({ TransitionProps, placement }) => (
                  <Grow
                      {...TransitionProps}
                      id="menu-list-grow"
                      style={{ zIndex: 4, transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                      <Paper style={{backgroundColor: '#ff0000'}}>
                      <ClickAwayListener onClickAway={this.handleClose}>
                      <ProfileNavWP
                        handleClose={this.handleClose}
                        authChange={this.props.authChange}
                        user={this.props.user}
                        extraPadding={4}
                      />
                      </ClickAwayListener>
                      </Paper>
                  </Grow>
                  )}
              </Popper>
            </Grid>
            <Grid item>
                <IconButton color="inherit" aria-label="Menu"
                    component={Link} to='/notifications'
                >
                    <NotificationIcon />
                    {CMS.hasNotification == true  && <div style={styles.unreadNotifications}></div>}
                </IconButton>
            </Grid>
          </Grid>
        );
 
      } else {
        navMenu = (
          <Grid container alignItems="center" style={{height:'100%',marginRight:'10px'}}>
            <Grid item>
              <Button style={styles.appbarButton} variant="contained" component={Link} to='/login' id="wp_login">Log In</Button>
              <Button style={styles.appbarButton} variant="outlined" component={Link} to='/signup' id="wp_signup">Sign Up</Button>
            </Grid>
          </Grid>
        );
      }


      let imgLogo;
      if (window.WPDomain === 'demo'){
        imgLogo = <img src="https://meriton.sharewithoscar.com.au/assets/demo-logo2.svg" alt="Demo Portal"/>;
    
      } else if (window.WPDomain === 'community'){
        imgLogo = <img src="https://community.sharewithoscar.com.au/assets/graphics/oscar_logo_teal.svg" alt="Share with Oscar" style={{marginTop:'10px'}} />;

      } else if (window.WPDomain === 'bela'){
        imgLogo = <img src="https://bela.parkwithoscar.com.au/assets/bela-logo.svg" alt="Bela powered by Oscar"/>;

      } else {
        imgLogo = <img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg" alt="Meriton powered by Oscar"/>;

      }

      return (
        <div className={"lwp-container " + window.WPDomain}>

          <div className="lwp-header">
            <div className="lwp-logo">
              <Link to={'/search'} >{imgLogo}</Link>
            </div>
            <div className="lwp-nav">
              {navMenu}
            </div>
          </div>
          
          <div className="lwp-x">
            {layoutMode.visibleNav &&
            <div className="lwp-sidemenu">
              <NavItemsWP
                  user={this.props.user}
                  extraPadding={4}
              />
            </div>}

            <div className={"lwp-content" + (layoutMode.visibleNav ? " limit-width":"")}>
              {this.props.children}
            </div>          
          </div>
          
        </div>
      );

  }
}

export default (withRouter(MasterLayoutWP))