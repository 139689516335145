/* */
import React, {Component} from 'react';
import SmartImage from '../components/SmartImage';

const baseUrl = `https://meriton.sharewithoscar.com.au/images/`;
const baseUrl2 = `https://images.sharewithoscar.com.au/`;

function getImagePreviewForBuilding(building){

  let image;
  if (building && building.image_set && building.image_set.length > 0) {
    // Use first General(Type 1) image
    image = building.image_set.filter(image => image.image_type === 1)[0];
   
  } 

  const carouselImage = {
    width: '120px',
    height: '100px'
  }


  if (image){
    let imageUrl = image.path;
    imageUrl += "?tr=w-120,h-100,fo-auto";
  
    if (image.path.substr(0,4) === 'http'){
      //??imageUrl = imageUrl.replace("https://meriton.sharewithoscar.com.au/images/","https://images.sharewithoscar.com.au/");

      return <img style={carouselImage} key={building.uuid} src={image.path} alt="Loading image..."/>
    } else {

      return <img style={carouselImage} key={building.uuid} src={`${baseUrl2}${imageUrl}`} alt="Loading image..."/>
    }
  }

  return null;
}

function getImagePreviewUrlForBuilding(building){

  let image;
  if (building && building.image_set && building.image_set.length > 0) {
    // Use first General(Type 1) image
    image = building.image_set.filter(image => image.image_type === 1)[0];
   
  } 

  const carouselImage = {
    width: '120px',
    height: '100px'
  }


  if (image){
    if (image.path.substr(0,4) === 'http'){
      return image.path;
    } else {
      return `${baseUrl}${image.path}`;
    }
  }
  
  return null;
}


function getImagePreviewForSpace(spaceData, building){
  if (!spaceData){
    return null;
  }

  const carouselImage = {
    width: '120px',
    height: '100px'
  }
  
  let image;
  if (spaceData.image_set && spaceData.image_set.length > 0){
    // Use first tenant image if supplied
    image = spaceData.image_set[0];
  } else if (building && building.image_set && building.image_set.length > 0) {
    // Otherwise use first Access(Type 3) image
    image = building.image_set.filter(image => image.image_type === 3)[0];

    // If no Access images, try for Street (Type 2)
    if (!image || image.length == 0){
      image = building.image_set.filter(image => image.image_type === 2)[0];
    }

    // If no Street images, fall back to General (Type 1)
    if (!image || image.length == 0){
      image = building.image_set.filter(image => image.image_type === 1)[0];
    }
    
    
  } 


  if (image){
    let imageUrl = image.path;
    imageUrl += "?tr=w-120,h-100,fo-auto";
    
    if (image.path.substr(0,4) === 'http'){
      //console.log("imagePage:",image.path);
      //https://www.sharewithoscar.com.au/images/7cfca005-4403-45a0-b7ce-84fe6702ec98
      imageUrl = imageUrl.replace('https://www.sharewithoscar.com.au/images/','https://images.sharewithoscar.com.au/');
      
      return <img style={carouselImage} key={spaceData.uuid} src={imageUrl} alt="Loading image..."/>
    } else {
      //console.log("b:",image.path);
      return <img style={carouselImage} key={spaceData.uuid} src={`${baseUrl2}${imageUrl}`} alt="Loading image..."/>
    }
  }



  return null;
}

function getImageListForSpace(spaceData, building=null, filter=null, asImg=false, width=null, height=null){

  const carouselImage = {
    width: 'auto',
    height: '250px'
  }
  
  //TEMP: unblock filter so we can see General/Tenant

  filter = null;
  let images;
  let generalImages = [];
  let buildingImages = [];

  // parking_space or parking_bay
  if (spaceData && spaceData.image_set && spaceData.image_set.length > 0){
    if (filter){
      generalImages = spaceData.image_set.filter(image => image.image_type === filter);
    } else {
      generalImages = spaceData.image_set;
    }
  }  

  // If building is present, append it's photos to this parking_bay image list
  if (building && building.image_set){
    if (filter){
      buildingImages = building.image_set.filter(image => image.image_type === filter);
    } else {
      buildingImages = building.image_set;
    }
    generalImages = generalImages.concat(buildingImages);
  }

  //const baseUrl = `https://www.sharewithoscar.com.au/images/`;
 
  const baseUrl = `https://meriton.sharewithoscar.com.au/images/`;

  if (generalImages){
    
    images = generalImages.map((image, index) => {
      
      let imageUrl = image.path;
      if (asImg){
        if (image.path.substr(0,4) === 'http'){
          // Oscar
          //console.log(image.path);
          imageUrl = imageUrl.replace('https://www.sharewithoscar.com.au/images/','https://images.sharewithoscar.com.au/');
          if (width && height){
            imageUrl += `?tr=w-${width},h-${height},fo-auto`;
          } else if (height){
            imageUrl += `?tr=h-${height},fo-auto`;
          }
          //console.log("imageUrlA:",imageUrl);
          
          if (!width && !height){
            return (<SmartImage key={spaceData.uuid + '-desktop' + index} imageUrl={imageUrl}  />);
          } else {
            return (<div className="img-holder" key={spaceData.uuid + '-' + index} style={{backgroundImage: `url("${imageUrl}")`}}></div>);
          }
   
   


        } else {
          // Older style
          return <img style={carouselImage} key={spaceData.uuid + '-' + index} src={`${baseUrl}${image.path}`} alt="The parking space"/>
        }
  
      } else {
        // Meriton or Oscar3
        if (width && height){
          imageUrl += `?tr=w-${width},h-${height},fo-auto`;
        } else if (height){
          imageUrl += `?tr=h-${height},fo-auto`;
        }
        //console.log("imageUrlB:",imageUrl);

        if (image.path.substr(0,4) === 'http'){
          imageUrl = imageUrl.replace('https://www.sharewithoscar.com.au/images/','https://images.sharewithoscar.com.au/');

          if (!width && !height){
            return (<SmartImage key={spaceData.uuid + '-mobile' + index} imageUrl={imageUrl}  />);
          } else {
            return (<div className="img-holder" key={spaceData.uuid + '-' + index} style={{backgroundImage: `url("${imageUrl}")`}}></div>);

          }
          


        } else {
          // Relative filename
          return (<div className="img-holder" key={spaceData.uuid + '-' + index} style={{backgroundImage: `url("${baseUrl}${image.path}")`}}></div>);
        }
      }



      
      
    });

  } else {
    //images = (<div>NO IMAGES</div>);
  }

  return images;
}

export {getImagePreviewForSpace, getImageListForSpace, getImagePreviewForBuilding, getImagePreviewUrlForBuilding}