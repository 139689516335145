import React, {Component} from 'react';

import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Paper,
    Typography
} from "@material-ui/core";

import {getApplicationUser} from '../utils/Authentication.js';

import IconClose from "@material-ui/icons/Close"
import IconThumbsDown from '@material-ui/icons/ThumbDown'
import IconThumbsUp from '@material-ui/icons/ThumbUp'

import Carousel from 'react-responsive-carousel/lib/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';


import {getImageListForSpace} from '../utils/GetImageListForSpace.js';
import FullPageModal from '../utils/FullPageModal.js';
import LongTermEnquiryWP from '../views/LongTermEnquiryWP.js';
import ENUMS from '../api/ENUMS.json'

const styles = {
    rightTopButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    leftTopButton: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    longTermButton: {
        position: 'absolute',
        backgroundColor: 'white',
        color: '#1AA5A2',
        right: '8px',
        top: '8px',
        borderRadius: '20px',
        padding: '5px 15px',
        fontSize: '15px',
        fontWeight: 'bold',    
    },
    /*contentContainer: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',

        display: 'flex',
        flexDirection: 'column',
    },*/
    container: {
        padding: '0px',
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    descriptionContentContainer: {
        padding: '16px',
        marginTop: '24px',
        flexGrow: 1,
        flexBasis: 1,
        overflow: 'auto',
    },
    rate: {
        fontWeight: 'bold',
    },
    bookingButtonContainer: {
        width: '100%',
    },
    bookingButton: {
        padding: '22px',
    },
    distanceText: {
        textTransform: 'uppercase',
        color: "#acacac",
    },
    imageCarouselWP: {
      width: '100%',
      backgroundColor:'#00ff00'
    },
    carouselImage: {
        width: 'auto',
        height: 'auto'
    },
    /*ownerProfile: {
        position: 'absolute',
        right: '20px',
        top:'360px'
    },*/
    guestProfileImage: {
        backgroundColor: 'white',
        borderRadius: '50px',
        width: '75px',
        height: '75px',
    },
    subheading: {
        marginTop: '16px',
        textTransform: 'uppercase',
    },
    reviewIcon: {
        fontSize: '13px',
        marginRight: '4px'
    }
}


export default class SpaceDetailsWP extends Component {
  state = {enquireOpen: false}
    render() {
      const user = getApplicationUser()

      const {spaceData, spaceType, spaceData2} = this.props;

      let spaceUser = spaceData.user || spaceData2.company.master_user;

      //console.log({spaceData});
      // parking_space, parking_bay, building
      if (!spaceData){
        return (<div>NO SPACE DATA</div>);
      }

      let building;
      if (spaceData2){
        building = spaceData2.building;
      }
      let images = getImageListForSpace(spaceData, building,"General");
      //console.log({images});
      if (images.length === 0 && window.WPDomain === 'community'){
        // TEMP
        images.push(<div className="img-holder" key={'no-photo'} style={{backgroundImage: `url("https://community.sharewithoscar.com.au/assets/graphics/no-photo-available.jpg")`}}></div>);
      }
      //console.log("Render:SpaceDetails:",spaceData);



      return (
        <div className="sd-container">
            <FullPageModal open={this.state.enquireOpen}>
                <LongTermEnquiryWP 
                    onClose={() => this.setState({ enquireOpen: false })}
                    history={this.props.history}
                    spaceUuid={spaceData.uuid}
                    spaceType={spaceType}
                    spaceData={spaceData}
                />
            </FullPageModal>


            <Carousel 
              showArrows={true} 
              showStatus={false} 
              showIndicators={true} 
              showThumbs={false} 
              infiniteLoop={true} 
            >
              {images}
            </Carousel>

              <div className="owner-profile">
                        {(spaceUser && spaceUser.image_user && spaceUser.image_user.path) 
                        ? <img key={spaceUser.image_user.path} style={styles.guestProfileImage} src={spaceUser.image_user.path} alt="user profile" />
                        : <img style={styles.guestProfileImage} src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="user profile" />}
                        
                        <Typography variant="body1" align="center">
                            {(spaceUser && spaceUser.first_name)}
                        </Typography>
              </div>
              <IconButton className="wp-btn-close" style={styles.rightTopButton}
                    onClick={this.props.closeModal}>
                        <IconClose style={{zIndex:'5', color: 'rgb(207, 207, 207)'}}/>
                    </IconButton>


            <div className="sd-details">
              <h1><span>{building.building_name}</span> on {building.street_name}</h1>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={9}>
                    {building.rules_text && <div className="rules_text" dangerouslySetInnerHTML={{__html: building.rules_text}}/>}
                    
                    <h2>Description</h2>
                    <div className="description" dangerouslySetInnerHTML={{__html: spaceData.description || building.description}}/>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <h2>Maximum vehicle size</h2>
                    <p>{ENUMS.vehicle_types[spaceData.vehicle_type]}</p>
                  </Grid>

                  <Grid item xs={12} sm={9}>
                    <h2>Availability Type</h2>
                    <p>{ENUMS.availability_types[spaceData.availability_type]}</p>
  
                  </Grid>
                  <Grid item xs={12} sm={3}>
                      <h2>Access method</h2>
                      <p>{ENUMS.access_methods[spaceData.access_method || building.access_method]}</p>

                  </Grid>
              </Grid>


            </div>
            {window.WPDomain === 'community' && <p className="sd-details-disclaimer">Upon booking confirmation, please contact the owner to sort out car park access.</p>}
            {window.WPDomain !== 'community' && <p className="sd-details-disclaimer">Upon booking confirmation, the Building Manager will be in touch to sort out car park access.</p>}

            <div className="sd-actions">
              <div className="rate hide-mobile">{`$${spaceData.rate_monthly.toFixed(0)}`}<span>/MONTH</span></div>
              <Button className="wp-button" onClick={() => { this.setState({enquireOpen: true})}}>Make Enquiry</Button>
              {(spaceData.active_bookings && spaceData.active_bookings.length >0) ?
                <Button className="wp-button" disabled>Unavailable</Button>:
                <>
                {(spaceData.auto_approve || !spaceData.user) ?
                  <Button className="wp-button" onClick={this.props.bookClicked}>
                      <img src="https://www.sharewithoscar.com.au/assets/graphics/thunder-white-oscar.svg" style={{width:'10px',marginRight:'5px'}} />Book Now
                  </Button>
                  :
                  <Button className="wp-button" onClick={this.props.bookClicked}>Request to Book</Button>
                }
                </>
              }
            </div>
          </div>
        );

        
    }
}