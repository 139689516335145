/* Very quick and diry hack to get autoFocus working on mobile - clean this up later */
import React, { Component } from 'react';

class PlacesInputMobileAutoFocus extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
    };

  }

  componentDidMount(){
    let that = this;
    setTimeout(() => {
      that.myRef.current.focus();
    }, 350-100);
  }

  componentWilUpdate(){
    this.textInput.focus();

  }

  render() {
    return (
      <div className={this.props.className}>
        <div>
          <input type="text"
          style={{backgroundColor:'transparent',outline:'none',fontSize:'16px'}}
          className="autoselect"
          ref={this.myRef}  
          autoFocus={true}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onKeyDown={this.props.onKeyDown}
          spellCheck={false}
          //disableUnderline={true}
          />
        </div>
      </div>
    );
        

  }

}

export default PlacesInputMobileAutoFocus;
