import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment'

import {
    Button,
    Grid,
    IconButton,
    Typography,
    Hidden,
} from '@material-ui/core'

import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconClose from '@material-ui/icons/Close';


import {
    BookingsRangeDisplayFormat
} from '../utils/TimeFormats.js';

import {
    getUserTransactions,
} from '../api/Oscar-api'

import LoadingModal from '../components/LoadingModal';
import FullPageModal from '../utils/FullPageModal';
import PaymentMenu from './PaymentDetails'
import { updateUserProfileAjax } from '../utils/Authentication.js';

const styles = {
    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '15px',
    },
    nextButton: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },
    /*balance: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(https://www.sharewithoscar.com.au/assets/graphics/oscar_coins.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '192px',
        width: '340px',
        marginBottom: '32px'
    },*/
  topCaption: {
        color: 'white',
        textTransform: 'uppercase',
        fontSize: '15px',
        fontWeight: 'bold',
    },
    balanceAmount: {
        color: 'white',
        fontSize: '42px',
        fontWeight: 'bold',
    },
    balanceCurrency: {
        color: 'white',
        fontWeight: 'bold',
    },

    /*topColored: {
        paddingTop: '80px',
        backgroundColor: '#1AA5A2',
        paddingBottom: '8px',
    },*/
    paymentSetting: {
        paddingTop: '8px',
        paddingBottom: '8px',
        borderBottom: '1px solid #efefef'
    },
    switchField: {
        borderTop: '1px solid #acacac',
        borderBottom: '1px solid #acacac',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '12px',
        marginBottom: '12px',
    },
    transactionsContainer: {
        marginTop: '16px',
        cursor:'default'
    },
    transactionContainer: {
        borderTop: '1px solid #efefef',
        paddingTop: '8px',
        paddingBottom: '6px',
        flexWrap: 'nowrap'
    },
    switchBorder: {
        marginTop: '24px',
        borderTop: '1px solid #efefef',
    },
}


function TransactionsList(props) {
    return(
    <div className="up-container">
        <div className="up-header">
            <IconButton className="leftCornerButton" onClick={props.onClose}>
                <IconBack style={{color: 'white'}}/>
            </IconButton>
            <h1>Recent Transactions</h1>
        </div>
        <div className="up-content">
            <div style={styles.transactionsContainer}>
                {props.transactions.map((transaction, i) => {
                    // Hide pre-auth
                    if (transaction.description && transaction.description.indexOf('Pre-auth') >= 0){
                      return null;
                    }
                  
                    return(
                        <div key={i}>
                            <Grid style={styles.transactionContainer} container justify="space-between" alignItems="center">
                                <Grid item>
                                    <Typography variant="caption">
                                        {moment(transaction.create_date).format(BookingsRangeDisplayFormat)}
                                    </Typography>
                                    <Typography variant="body1">
                                        {transaction.description}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color={transaction.amount > 0 ? 'primary' : undefined }>
                                        {transaction.amount > 0 ? '+' : '-'}${Math.abs(transaction.amount).toFixed(2)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
    )
}





export default class Wallet extends Component {
    state = { 
        loading: true,
        paymentMenuOpen: this.props.match.params.payment ? true : false,
        showTransactions: false,
    }

    componentWillMount() {
        // Load transactions
        getUserTransactions().then(response => {
            updateUserProfileAjax().then(() => {
                this.props.authChange()
                            
                //console.log(response)
                this.setState({
                    loading: false,
                    transactions: response.data,
                })
            })

        }).catch(error => {
            console.log(error)
        })
    }

    render() {
      if (window.WPDomain){
        return this.renderWP();
      }

        return(
            <div style={{height: '100%', overflow: 'auto'}}>
                <LoadingModal open={this.state.loading}/>
                <FullPageModal open={this.state.showTransactions}>
                    <TransactionsList 
                        transactions={this.state.transactions}
                        onClose={() => this.setState({ showTransactions: false })} />
                </FullPageModal>

                <FullPageModal open={this.state.paymentMenuOpen}>
                    <PaymentMenu onClose={() => this.setState({ paymentMenuOpen: false })}
                        userProfile={this.props.user.profile}
                        authChange={this.props.authChange}
                    />
                </FullPageModal>

                <div style={{backgroundColor: 'white'}}>
                    <div className="mainContent topPadded">
                        <IconButton className="leftCornerButton"
                        component={Link} to={`/search`}>
                            <IconClose/>
                        </IconButton>
                        <Button style={styles.rightCornerButton} color="primary"
                        onClick={() => this.setState({ paymentMenuOpen: true })}>
                            Payment Details
                        </Button>
        
                        <Typography variant="display1" color="primary">
                            Wallet
                        </Typography>
                        
                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <div className="wallet-card">
                                <div>
                                    <Typography variant="caption" style={styles.topCaption} align="center">
                                        You have
                                    </Typography>
                                    <Typography style={styles.balanceAmount} align="center">
                                        ${this.props.user.profile.balance.toFixed(2)}
                                    </Typography>
                                    <Typography variant="body1" style={styles.balanceCurrency} align="center">
                                        {window.WPDomain ? 'in earnings':'Oscar Coins'}
                                    </Typography>
                                </div>
                            </div>
                        </div>

                        <Typography variant="headline" color="primary">
                            Recent Transactions
                        </Typography>

                        {this.state.transactions !== undefined
                        ? <div style={styles.transactionsContainer}>
                            {this.state.transactions.slice(0, 4).map((transaction, i) => {
                                // Hide pre-auth
                                if (transaction.description && transaction.description.indexOf('Pre-auth') >= 0){
                                  return null;
                                }
                                return(
                                    <div key={i}>
                                        <Grid style={styles.transactionContainer} container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="caption">
                                                    {moment(transaction.create_date).format(BookingsRangeDisplayFormat)}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {transaction.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography color={transaction.amount > 0 ? 'primary' : undefined }>
                                                    {transaction.amount > 0 ? '+' : '-'}${Math.abs(transaction.amount).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })}
                            <Grid style={styles.transactionContainer} align="center" >
                                <Button style={{color: "#cccccc"}} variant="text"
                                onClick={() => this.setState({showTransactions: true})}>
                                    Show All Transactions
                                </Button>
                            </Grid>
                        </div>
                        :                         
                        <Grid style={styles.transactionContainer} align="center" >
                            <Button disabled variant="text" >
                                No Transactions
                            </Button>
                        </Grid>}
                    </div>
                </div>
            </div>
        );
    }

    //=================================================================================
    // WP
    //=================================================================================
    renderWP(){

      return(
        <>
            <FullPageModal open={this.state.showTransactions}>
              <TransactionsList 
                  transactions={this.state.transactions}
                  onClose={() => this.setState({ showTransactions: false })} />
            </FullPageModal>
            <FullPageModal open={this.state.paymentMenuOpen}>
              <PaymentMenu onClose={() => this.setState({ paymentMenuOpen: false })}
                  userProfile={this.props.user.profile}
                  authChange={this.props.authChange}
              />
            </FullPageModal>

        <div className="wpadmin-content top-padded-mobile">
            <LoadingModal open={this.state.loading} />

            <>
              <Hidden mdUp>
                <IconButton className="leftCornerButton"
                component={Link} to="/search">
                    <IconClose />
                </IconButton>
              </Hidden>
              <h1>Wallet</h1>
              
              <Button style={styles.rightCornerButton} className="wp-textbutton"
                  onClick={() => this.setState({ paymentMenuOpen: true })}>
                  Payment Details
              </Button>
        
                        
              <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <div className="wallet-card">
                      <div>
                          <Typography variant="caption" style={styles.topCaption} align="center">
                              You have
                          </Typography>
                          <Typography style={styles.balanceAmount} align="center">
                              ${this.props.user.profile.balance.toFixed(2)}
                          </Typography>
                          <Typography variant="body1" style={styles.balanceCurrency} align="center">
                            {window.WPDomain ? 'in earnings':'Oscar Coins'}
                          </Typography>
                      </div>
                  </div>
              </div>


              <h2>Recent Transactions</h2>



              {this.state.transactions !== undefined
                        ? <div style={styles.transactionsContainer}>
                            {this.state.transactions.slice(0, 4).map((transaction, i) => {
                                return(
                                    <div key={i}>
                                        <Grid style={styles.transactionContainer} container justify="space-between" alignItems="center">
                                            <Grid item>
                                                <Typography variant="caption">
                                                    {moment(transaction.create_date).format(BookingsRangeDisplayFormat)}
                                                </Typography>
                                                <Typography variant="body1">
                                                    {transaction.description}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography color={transaction.amount > 0 ? 'primary' : undefined }>
                                                    {transaction.amount > 0 ? '+' : '-'}${Math.abs(transaction.amount).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })}
                            <Grid style={styles.transactionContainer} align="center" >
                                <Button style={{color: "#cccccc"}} variant="text"
                                onClick={() => this.setState({showTransactions: true})}>
                                    Show All Transactions
                                </Button>
                            </Grid>
                        </div>
                        :                         
                        <Grid style={styles.transactionContainer} align="center" >
                            <Button disabled variant="text" >
                                No Transactions
                            </Button>
                        </Grid>}
                    


              </>
        </div>
        </>
      );



    }

}