import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {getListingsBays} from '../api/Oscar-api';

import { 
    Button,
    Grid,
    LinearProgress,
    Typography,
    Hidden, 
    IconButton,
} from '@material-ui/core';

import IconNext from '@material-ui/icons/KeyboardArrowRight';
import LinkIcon from '@material-ui/icons/Launch';
import CloseIcon from '@material-ui/icons/Close';
import LoadingModal from '../components/LoadingModal.js';
import ListingsWP_OwnerBookings from './ListingsWP_OwnerBookings.js'

import { isNativeWebview } from '../utils/IsNativeWebview';
import {getImagePreviewForSpace} from '../utils/GetImageListForSpace.js';
import ENUMS from '../api/ENUMS.json'


import {
    requestLocation, 
} from '../utils/Location.js';

const pageNames = {
  "details":0,
  "instructions":1,
  "pricing":2,
  "photos":4
};




const styles = {
    rightCornerButton: {
        position: 'absolute',
        top: 10,
        right: 5,
        color: 'black',
    },
    addButton: {
        //marginBottom: '16px',
    },
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    listingsContainer: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',
    },
    completeListingContainer: {
        border: '1px solid #efefef',
        padding: '4px',
        marginBottom: '24px',
    },
    imageContainer: {
        textAlign: 'right',
    },
    imagePreview: {
        width: '90px',
        height: '65px',
    },
    editButton: {
        padding: '0px',
        minWidth: '0px',
        minHeight: '0px',
        fontWeight: 'bold',
        marginTop: '10px',
    },

    progressContainer: {
        width: '100%',
        padding: '8px',
        borderTop: '1px solid #efefef',
    },

    progressHeader: {
        marginTop: '2px',
        marginBottom: '16px'
    },

    cardImage: {
        width: '100%',
        height:'100px',
    },
    spaceCard: {
        paddingBottom: '5px',
        position: 'relative'
    },
    editControls: {
        position: 'absolute',
        bottom: '15px',
    },
    listingCard: {
        marginBottom: '40px',
        backgroundColor: '#f1f1f1'
    },

    noListings: {
        flexGrow: '1',
        display: 'flex',
        flexDirection: 'column',

        backgroundImage: 'url("https://www.sharewithoscar.com.au/assets/graphics/list_spot.jpg")',
        backgroundRepeat: 'no-repeat', /* Do not repeat the image */
        backgroundSize: 'cover', /* Resize the background image to cover the entire container */
    },
    earnCard: {
        backgroundColor: 'white',
        padding: '24px',
        borderRadius: '4px',
        marginBottom: '16px',
    }
}

function calculateCompletenessPercentage(listing) {
    let complete = 10
    if (listing.form.values.address !== null && listing.form.values.address !== undefined && listing.form.values.address !== '') {
        complete += 15
    }
    if (listing.form.values.type !== null && listing.form.values.type !== undefined && listing.form.values.type !== '') {
        complete += 15
    }
    if (listing.form.values.vehicle_type !== null && listing.form.values.vehicle_type !== undefined && listing.form.values.vehicle_type !== '') {
        complete += 15
    }
    if (listing.form.values.description !== null && listing.form.values.description !== undefined && listing.form.values.description !== '') {
        complete += 15
    }
    if (listing.form.values.instruction !== null && listing.form.values.instruction !== undefined && listing.form.values.instruction !== '') {
        complete += 15
    }

    return complete
}

function externalLink(target, nativeTarget) {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}

// TODO: ELIMINATE THIS, use client post_code (map to city name) instead
const geolocationTimeout = new Promise(function(resolve, reject) { 
    setTimeout(resolve, 2000, 'geolocationTakingTooLong'); 
});

async function getLocation() {
    let location
    try {
        location = await Promise.race([requestLocation(), geolocationTimeout])
        if (location === "geolocationTakingTooLong") {
            throw new Error("invalid location")
        }
    } catch (e) {
        location = {
            address: "Sydney, NSW",
            latLng: { lat: -33.8689725, lng: 151.2099337 },
            suburb: 'Sydney',
        }             
    }
    
    return location    
}



export default class ListingsWP extends Component {
    state = {
        loading: true,
        complete: [],
        inProgress: [],
    }
    componentWillMount() {

      const default_building = window.WPDomain === 'community' ? this.props.user.profile.default_building_community: this.props.user.profile.default_building;

      if (!default_building){
        this.setState({
          loading: false,
          errorMessage: "Not a tenant",
          inProgress: false,
          location: 'ERROR' //location.suburb       // <- get suburb_name from user post_code
        });
        
        return;
      }

      let that = this;
        //getLocation().then(location => {
            getListingsBays().then(response => {
                //console.log(response)
                let listings = response.data;

                //console.log("listings:",listings);

                // Read listings progress object 
                let localData = localStorage.getItem("LOCAL_DATA")
                if (localData) {
                    localData = JSON.parse(localData)
                    if ('LISTINGS_IN_PROGRESS' in localData) {
                        function checkMatchingElement(element) {
                            return element.uuid === uuid
                        }
                        for(var uuid in localData['LISTINGS_IN_PROGRESS']) {
                            const localListing = localData['LISTINGS_IN_PROGRESS'][uuid]

                            if (!listings.find(checkMatchingElement)) {
                                listings.push(localListing)
                            }
                        }
                    }
                }

                // Process listings dividing them into in progress and complete.
                // Assign progress to each listing
                let complete = []
                let inProgress = []
                for (let i = 0; i < listings.length; i++) {
                    let listing = listings[i]
                    // Check if this is a local listing or a server stored listing
                    if ('form' in listing) {
                        listing.progress = listing['form']['activePage'] * 15 + 10
                        inProgress.push(listing)
                    } else {
                        listing.progress = 55; //55 + (listing.availabilityrecurring_set.length > 0 ? 15 : 0) + (listing.image_set.length > 0 ? 30 : 0)
                        complete.push(listing)
                    }
                }            

                this.setState({
                    loading: false,
                    complete: complete,
                    inProgress: inProgress,
                    location: 'Sydney' //location.suburb       // <- get suburb_name from user post_code
                })
           // })
        }).catch(function(err) {
          that.setState({
            loading: false,
            errorMessage: err.toString(),
            //complete: complete,
            inProgress: false,
            location: 'ERROR' //location.suburb       // <- get suburb_name from user post_code
             });
             console.log("ERROR:",err.toString());


        });
    }

    handleFinishInProgress = (listing) => {
        this.props.history.push({
            pathname: '/listspace',
            state: { hydrateState: listing }
        })
    }

    handleFinishComplete = (listing) => {
        this.props.history.push({
            pathname: '/listspace',
            state: { completeListing: listing }
        })
    }

    /*handleEdit = (listing) => {
        this.props.history.push({
            pathname: '/listspace',
            state: {editListing: listing, page:0}
        });
    }*/

    handleEditPhotos = (listing) => {
      this.props.history.push({
          pathname: '/listspace',
          state: {editListing: listing, page:2}
      });
    }

    handleEditAvailability = (listing) => {
      this.props.history.push({
          pathname: '/listspace',
          state: {editListing: listing, page:1}
      });
  
    }

    //<div style={{height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
    render() {
      // TODO: auto-generate from FORM

      const {loading, complete, inProgress, errorMessage} = this.state;

        if (errorMessage){
          return (
            <div className="wpadmin-content top-padded-mobile show-scrollbar">
              <p className="error">{errorMessage}</p>
            </div>
          );

        }

        if (loading){
          return (
            <div className="wpadmin-content top-padded-mobile show-scrollbar">
              <LoadingModal open={loading} />
              <Hidden mdUp>
                <IconButton className="leftCornerButton" component={Link} to="/search"><CloseIcon /></IconButton>
              </Hidden>
              <h1>My Spots</h1>
              <p>Loading...</p>
            </div>

          )
        }

        if (complete.length === 0 && inProgress.length === 0){
          return this.render_noListingsYet();
        }


        return(
              <div className="wpadmin-content top-padded-mobile show-scrollbar">
            
                <LoadingModal open={loading} />
                <Hidden mdUp>
                  <IconButton className="leftCornerButton" component={Link} to="/search"><CloseIcon /></IconButton>
                </Hidden>

                {!loading &&
                <>
                  <h1>My Spots</h1>
                  {complete.length > 0 && complete.map((spot, i) => {return(<SpotItem history={this.props.history} key={i} spot={spot} that={this}/>);})}
                
                  {/*inProgress.length > 0 &&
                    <>
                    <h2>In Progress</h2>
                    {inProgress.map((spot, i) => {return(<SpotItem key={i} spot={spot} that={this}/>);})}
                   </>
                */}

                  <Button className="wp-textbutton" style={{margin:'10px 0 0 10px'}} component={Link} to="/listspace/">Add new listing</Button>

                  <ListingsWP_OwnerBookings history={this.props.history}/>
                  <div style={{height:'60px'}}></div>

                </>}
            </div>

        );
    }

    render_noListingsYet(){

      //const thisBuilding = this.props.user.profile.default_building || {};
      const thisBuilding = window.WPDomain === 'community' ? this.props.user.profile.default_building_community || {}: this.props.user.profile.default_building || {};
      const rate_monthly_default = thisBuilding.rate_monthly_default;
      const rate_monthly_min = thisBuilding.rate_monthly_min;
      const rate_monthly_max = thisBuilding.rate_monthly_max;
  
      let strMoney = rate_monthly_default;
      if (window.WPDomain === 'community'){
        strMoney = "250";
      }

      const {loading} = this.state;
      return (
        <div className="wpadmin-content top-padded-mobile">
           
          <LoadingModal open={loading} />
          <Hidden mdUp>
            <IconButton className="leftCornerButton" component={Link} to="/search"><CloseIcon /></IconButton>
          </Hidden>

          <h1>Find out how much you could earn from your car park</h1>

          {window.WPDomain === 'meriton' &&
            <>
            <h3 style={{marginBottom:'0'}}>Based in {thisBuilding.building_name} on {thisBuilding.street_name}, you could be earning <span className="rate">${strMoney}/month</span> from your car park space</h3>
            <p style={{margin:'10px 0 30px 0'}}>Still unsure? Check out our <a className="external-link" href={"https://www.sharewithoscar.com.au/"+window.WPDomain+"-faq/"} target="_blank">FAQs</a> or <a className="external-link" href="https://www.sharewithoscar.com.au/meriton-contact-us/" target="_blank">contact</a> our friendly team</p>
            </>
          }
          {window.WPDomain === 'community' &&
            <>
            <h3 style={{marginBottom:'0'}}>You could be earning <span className="rate">${strMoney}/month</span> from your car park space</h3>
            <p style={{margin:'10px 0 30px 0'}}>Still unsure? Check out our <a className="external-link" href={"https://www.sharewithoscar.com.au/"+window.WPDomain+"-faq/"} target="_blank">FAQs</a> or <a className="external-link" href="https://www.sharewithoscar.com.au/contact-us/" target="_blank">contact</a> our friendly team</p>
            </>
          }

          <Button style={styles.addButton} className="wp-button thirdWidth" component={Link} to="/listspace/first">Get started</Button>    

        </div>


     );
  }

}


const imgAllow = <img src='https://images.sharewithoscar.com.au/assets/graphics/oscar_yes.svg'/>;
const imgDisallow = <img src='https://images.sharewithoscar.com.au/assets/graphics/oscar_no.svg'/>;
const imgAddPhotos = <img src='https://images.sharewithoscar.com.au/assets/graphics/oscar_add_photos.svg'/>;

function SpotItem(props) {
  const spot = props.spot;

  const img = getImagePreviewForSpace(spot, spot.building);
  const building = spot.building;
  //li-spot-parent
  return (
    
    <div className="v3-listing-container">
      <div className="listing-left">

        <div className="details">      
          <div className="street_name"><span>{building.building_name}</span> on {building.street_name} <b>Bay #{spot.bay_marking}</b></div>

          <div className="listing-description">{spot.description}</div>
          

          {pageNames[spot.list_new_progress] >= pageNames['pricing'] &&
            <>
            {spot.availability_type == 1 ? <div className="permissions">{imgDisallow}{ENUMS.availability_types[spot.availability_type]}</div>:<div className="permissions">{imgAllow}{ENUMS.availability_types[spot.availability_type]}</div>}
            {spot.auto_approve ? <div className="permissions">{imgAllow} Instant bookings on</div>:<div className="permissions">{imgDisallow} Instant bookings off</div>}
            {spot.rate_monthly && <div className="permissions">{imgAllow} Monthly bookings at <b>${spot.rate_monthly}/mth</b></div>}
            </>
          }

        </div>

        <div className="image-list-container">
          {spot.image_set && spot.image_set.map((image, i) => {
            let imageUrl = 'https://images.sharewithoscar.com.au/' + image.path;  // parking_bays use different serializer than parking_spaces
            //imageUrl = imageUrl.replace("https://www.sharewithoscar.com.au/images/","https://images.sharewithoscar.com.au/");
            
            imageUrl += "?tr=w-90,h-65,fo-auto";
            
            return(<img key={i + '_' + image.path} style={styles.imagePreview} src={imageUrl} alt="Parking bay preview" />)
          })}
          {pageNames[spot.list_new_progress] >= pageNames['pricing'] && <div id="btn-add-photos" onClick={() => props.history.push(`/listspace?uuid=${spot.uuid}&page=${pageNames['photos']}`)}>{imgAddPhotos}<span>Add Photos</span></div>}
            
        </div>


      </div>

      <div className="listing-right">
        <Button className="wp-button" onClick={() => props.history.push(`/listspace?uuid=${spot.uuid}`)}>Edit Listing</Button>    
        {pageNames[spot.list_new_progress] >= pageNames['pricing'] && <Button className="wp-button" onClick={() => props.history.push(`/listspace?uuid=${spot.uuid}&page=${pageNames['pricing']}`)}>Edit Pricing</Button>}
        {pageNames[spot.list_new_progress] >= pageNames['pricing'] && <Button className="wp-button" onClick={() => props.history.push(`/listspace?uuid=${spot.uuid}&page=${pageNames['pricing']}`)}>Edit Availability</Button>}
        
      </div>

    </div>
  );
}


/* 
                              <Button variant="contained" onClick={() => this.props.history.push(`/listspace?uuid=${listing.uuid}&page=${pageNames['address']}`)} color="primary">Edit Listing</Button>                                
                              {pageNames[listing.list_new_progress] >= pageNames['instructions'] && <Button variant="contained" onClick={() => this.props.history.push(`/listspace?uuid=${listing.uuid}&page=${pageNames['pricing']}`)} color="primary">Set Pricing</Button>}
                              {pageNames[listing.list_new_progress] >= pageNames['pricing'] && <Button variant="contained" onClick={() => this.props.history.push(`/listspace?uuid=${listing.uuid}&page=${pageNames['availability']}`)} color="primary">Set Availability</Button>}

*/