import React, {Component, forwardRef} from 'react';
import StripeCheckout from 'react-stripe-checkout';
import moment from 'moment';

import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';

import {
    Button,
    Grid,
    Paper,
    Typography,
    IconButton,
    Dialog

} from "@material-ui/core";
import IconEdit from '@material-ui/icons/Edit';
import IconNext from '@material-ui/icons/KeyboardArrowRight';
import IconBack from "@material-ui/icons/KeyboardArrowLeft";
import IconClose from "@material-ui/icons/Close";


import { isNativeWebview } from '../utils/IsNativeWebview';
import {
    QueryParamTime,
    QueryParamDate,
    WeekdayDateTime,
    WPMonthlyBookingsDisplayFormat,
} from '../utils/TimeFormats.js';

import {
    getPromoCode,
    sendBookingRequest,
} from '../api/Oscar-api.js';

import {
    savePaymentCard,
} from '../api/Oscar-api'

import {VehiclesMenu} from './UserProfile.js';
import LoadingButton from '../utils/LoadingButton.js';
import LoadingModal from '../components/LoadingModal.js';
import FullPageModal from '../utils/FullPageModal.js';
import CardPaymentError from '../components/CardPaymentError.js';
import { updateUserProfileAjax } from '../utils/Authentication.js';
import CardWithBrand from "../components/CardWithBrand";
import AddCreditCard_Braintree from "../components/AddCreditCard_Braintree";
import AddDirectDebit from '../components/AddDirectDebit';
import { ControlPointSharp } from '@material-ui/icons';
//import CheckPayment from "../components/CheckPayment";

const styles = {

    contentContainer: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',

        display: 'flex',
        flexDirection: 'column',
    },
    rightTopButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      fontSize:'18px',
      color:'var(--heroColor2)',
    },
    leftTopButton :{
        position: 'absolute',
        left: 0,
        fontSize:'18px',
        color:'var(--heroColor2)',
    },
    container: {
        padding: '16px',
        paddingTop: '20px', // 80px
        paddingBottom: '0px',
        flex: '1 0 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    bookingButtonContainer: {
         width: '100%',
        //position: 'absolute',
        bottom: '0',
        left: '0',
        //backgroundColor: 'pink',
    },
    bookingButton: {
        padding: '20px 20px 20px 20px',
    },
    descriptions: {
        marginTop: '46px',
    },
    bookingProperty: {
        borderBottom: '1px solid #efefef',
        padding: '14px 0px'
    },
    bookingProperties: {
        marginTop: '8px',
        flexGrow: 1,
        flexBasis: 1,
        overflow: 'auto',
    },
    promoInput: {
        width: '80px',
        marginRight: '16px',
        fontSize: '16px',
        fontFamily: 'Rubik',
        border: '1px solid #acacac',
        borderRadius: '4px',
        padding: '4px',
        boxShadow: 'none',
        verticalAlign: 'middle',
        textTransform:'uppercase'
    }
}

const vehicleSizeMap = {"Bike": 0, "Hatchback": 1, "Sedan": 2, "4WD/SUV": 3, "SUV": 3, "Van": 4}

const CARD_TRANSACTION_FEE_PERCENTAGE = 0.019;

export default class BookingCheckoutPage extends Component {
    constructor(props) {
        super(props)

        let msm_startdate;
        let msm_enddate;

        let booking_monthly_startdate;
        let booking_monthly_enddate;

        let allowDirectDebit = false;


        if (this.props.spaceType === 'parking_bay'){

          allowDirectDebit = true;

            //const spaceData = this.props.spaceData;
          msm_startdate = this.props.msm_startdate;
          msm_enddate = this.props.msm_enddate;
          /*if (spaceData.future_bookings && spaceData.future_bookings.length > 0){
            //{this.props.end && this.props.end.format(WeekdayDateTime)} 
            let enddate = moment(spaceData.future_bookings[0].start_date).subtract(1, 'day');
            msm_enddate = enddate.format("YYYY-MM-DD"); // spaceData.future_bookings[0].start_date; //enddate.format(WPMonthlyBookingsDisplayFormat);
          } */

        } else {
          // Oscar MONTHLY
          if (this.props.searchMode == 1){
            allowDirectDebit = true;          
          }

          //let booking_monthly_startdate = moment(startDate).format("YYYY-MM-DD");
          booking_monthly_startdate = this.props.start.format("YYYY-MM-DD");
          if (this.props.end){
            booking_monthly_enddate = this.props.end.format("YYYY-MM-DD");
          }


        }


        // Create the list of "available payment methods" here
        // Will default to appropriate method if available
        let { arrAvailablePM, selectedPaymentMethodIndex } = this.buildPMList(allowDirectDebit);
        

        /*if (allowDirectDebit && this.props.user.profile.dd_account_label !== null){
          arrAvailablePM.push('dd');
          selectedPaymentMethod = 'dd';
        }*/
        


        let defaultPaymentMethod = (allowDirectDebit && this.props.user.profile.dd_account_label !== null) ? 'dd':'card';
        // If no card or bank account, we want to show DirectDebit as default option for monthly bookings
        if (allowDirectDebit && !this.props.user.profile.card_last4){
          defaultPaymentMethod = 'dd';
        }

        this.state = {
            loading: false,         
            vehiclesMenuOpen: false,
            paymentMenuOpen: false,
            transactionError: undefined,
            addCardOpen:false,
            //paymentMethod:'dd', //'card',
            paymentMethod:defaultPaymentMethod, //'card',
            currentPaymentMethod:null,
            allowDirectDebit,
            //selectedPaymentMethod,
            arrAvailablePM,
            selectedPaymentMethodIndex,

            promocode: undefined, 
            promocodeDescription: undefined, 
            discount: (total) => total,
            msm_startdate,
            msm_enddate,
            booking_monthly_startdate,
            booking_monthly_enddate
        }
    }  

    buildPMList = (allowDirectDebit) => {

      let arrAvailablePM = [];
      arrAvailablePM.push({method:'none',label:'NONE', isAllowed:false, isVisible:false}); // DUMMY

      let selectedPaymentMethodIndex = 0;
      
      if (this.props.user.profile.card_last4){
        arrAvailablePM.push({method:'card',label:this.props.user.profile.card_last4, isAllowed:true,isVisible:true});
      
        selectedPaymentMethodIndex = arrAvailablePM.length-1;   // Set as current
      } else {
        arrAvailablePM.push({method:'no-card',isVisible:false});
      }

      if (this.props.user.profile.dd_account_label !== null){
        arrAvailablePM.push({method:'dd',label:this.props.user.profile.dd_account_label, isAllowed:allowDirectDebit,isVisible:true});
        if (allowDirectDebit){
          selectedPaymentMethodIndex = arrAvailablePM.length-1;   // Set as current
        }
      } else {
        arrAvailablePM.push({method:'no-dd',isVisible:false});
      }
      
      return { arrAvailablePM, selectedPaymentMethodIndex }
      
    }

    setPayment = (pmid) => {
      this.setState({selectedPaymentMethodIndex:pmid});
    }

    onSavePayment = (pmtype = '') => {

      if (pmtype === ''){
        this.setState({ addCardOpen: false }); 
        return;
      }


      let { arrAvailablePM, selectedPaymentMethodIndex } = this.buildPMList(this.state.allowDirectDebit);
      //let selectedPaymentMethodIndex = this.state.selectedPaymentMethodIndex;

      if (pmtype == 'card'){
        selectedPaymentMethodIndex = 1;
        //arrAvailablePM[selectedPaymentMethodIndex].isVisible = true;

      } else if (pmtype == 'dd'){
        selectedPaymentMethodIndex = 2;
        //arrAvailablePM[selectedPaymentMethodIndex].isVisible = true;

      }
      this.setState({ addCardOpen: false, arrAvailablePM, selectedPaymentMethodIndex }); 
    }

    //<a href="https://www.braintreegateway.com/merchants/k9nt2j7vfbfmsq5b/verified" target="_blank"><img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png" width="164px" height ="44px" border="0"/></a>
    checkPayment = (allowDirectDebit) => {
        /*if (total <= this.props.user.profile.balance) {
            this._payment = true
            return(
                <Typography variant="body1">
                    My Oscar Coins (${this.props.user.profile.balance.toFixed(2)})
                </Typography>
            )
        } else*/
        const {browserCSSMode} = this.props;
        const {addCardOpen, paymentMethod, currentPaymentMethod, arrAvailablePM, selectedPaymentMethodIndex} = this.state;

        const selectedPM = arrAvailablePM[selectedPaymentMethodIndex];
        //let currentPMDIV = [];
        this._payment = false;
        

        // DD is only allowed for OscarMonthly, MeritonMonthly, Community?
        /*if (selectedPM.method === 'dd'){ // allowDirectDebit && this.props.user.profile.dd_account_label !== null) {
          this._payment = true;
          this._paymentType = 'dd';
          
          //return (<div className="v3_paymentby_dd"><img src="https://www.sharewithoscar.com.au/assets/logo-bank.svg" style={{width:'35px'}}/><div className="ddlabel">Direct Debit <b>{this.props.user.profile.dd_account_label}</b></div></div>);
          currentPMDIV.push(<div key='pmdd' className="v3_paymentby_dd"><img src="https://www.sharewithoscar.com.au/assets/logo-bank.svg" style={{width:'35px'}}/><div className="ddlabel">Direct Debit <b>{this.props.user.profile.dd_account_label}</b></div></div>);


        } else if (selectedPM.method === 'card') {      //this.props.user.profile.card_last4 !== null
            this._payment = true
            this._paymentType = 'card'
            
            //return (<CardWithBrand profile={this.props.user.profile} />);
            currentPMDIV.push(<CardWithBrand  key='pmcard' profile={this.props.user.profile} />);
        }*/


        


        let strModalClass = "oscar modal-braintree";
        let strContainerClass = "oscar mbt-container";
        if (addCardOpen){
          strContainerClass += " open";
          strModalClass+= " open";
        }        
        
        //if (window.PaymentMode == "BRAINTREE"){
        // Header (duplicate in PaymentDetails.js)
        let name = "Share with Oscar";
        let image = "https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_white.svg";
        let strClass = "braintree";
        if (window.WPDomain){
          if (window.WPDomain === 'community'){
            // same as oscar
            strClass+=" community";
            name = "Share with Oscar";

          } else if (window.WPDomain === 'demo') {
                name = "Demo by Oscar";
                image="https://demo.parkwithoscar.com.au/assets/graphics/logo-demo.png";
                strClass+=" demo";

            } else {
                name = "Meriton by Oscar";
                image="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg";
                strClass+=" meriton";
                //<img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg" alt="Meriton powered by Oscar"></img>
            }
        } else {
          strClass+=" oscar";
        }
        
        //console.log(this._paymentType);
        //console.log({paymentMethod});
        //console.log({arrAvailablePM});
        //console.log({selectedPaymentMethodIndex});

        // start at 1 (ignore NONE)
        let divAvailablePM = [];
        for (let i=1;i<arrAvailablePM.length;i++){
          let thisPM = arrAvailablePM[i];

          if (!thisPM.isVisible)
            continue;

          let strClass = "v3_pm_option";
          if (!thisPM.isAllowed){
            strClass+=" not-allowed";
          }
          if (i == selectedPaymentMethodIndex){
            strClass+=" is-selected";
          }
          //
          let divDetails = null;

          if (thisPM.method === 'card'){
            divDetails = (<CardWithBrand  key='pmcard' profile={this.props.user.profile} />);
          } else if (thisPM.method === 'dd'){
            divDetails = (<div key='pmdd' className="v3_paymentby_dd"><img src="https://www.sharewithoscar.com.au/assets/logo-bank.svg" style={{width:'35px'}}/><div className="ddlabel">Direct Debit <b>{this.props.user.profile.dd_account_label}</b></div></div>);
          }

          divAvailablePM.push(<div key={i} onClick={thisPM.isAllowed ? () => this.setPayment(i):null} className={strClass}>{divDetails}</div>);
        }


          return (
              <>
              {/*<div style={{backgroundColor:'#eeeeff'}}><pre>{JSON.stringify(arrAvailablePM,null,2)}</pre></div>*/}
              <div className='v3_pm_selector'>
                <h6>Payment Method</h6>
                {divAvailablePM}
              </div>
              
              {browserCSSMode === 'mobile' && window.PaymentMode === "BRAINTREE" &&
                <>

                {/*<div className={"v3_payment-methods " + (window.WPDomain || 'oscar')}>
                  <div className="header"><img src={image} /></div>
                    {allowDirectDebit &&
                      <div className="button-box">
                        <Button className={paymentMethod === "card" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"card"})}>Card</Button>
                        <Button className={paymentMethod === "dd" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"dd"})}>Direct Debit</Button>
                      </div>
                    }
                    {paymentMethod === "card" && <AddCreditCard_Braintree WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addCardOpen: false }); }}/>}
                    {paymentMethod === "dd" && <AddDirectDebit WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addCardOpen: false }); }}/>}
                  </div>*/}
                

                  <div className={strContainerClass}>
                    {allowDirectDebit &&
                      <div className="button-box">
                          <Button className={paymentMethod === "card" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"card"})}>Card</Button>
                          <Button className={paymentMethod === "dd" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"dd"})}>Direct Debit</Button>
                      </div>
                    }

                    {paymentMethod === "card" &&
                    <>
                      <div className="mbt-header"><div>Add Card Details</div></div>
                      <div className="mbt-body">
                        {addCardOpen && <AddCreditCard_Braintree onClose={this.onSavePayment} WPDomain={window.WPDomain} WPMode={window.WPMode} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} />}
                      </div>
                    </>
                    }

                    {paymentMethod === "dd" &&                      
                    <>
                      <div className="mbt-header"><div>Enter Bank Details</div></div>
                      <AddDirectDebit WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={this.onSavePayment}/>                        
                    </>
                    }
                  </div>
                <div className={strModalClass} onClick={() => {this.setState({addCardOpen:false})}}></div>
                </>
              }

              {/*currentPMDIV*/}
              <Button style={{paddingRight: '0px'}} className="wp-textbutton-booking" onClick={() => { this.setState({ addCardOpen: true}); }}>{this.state.selectedPaymentMethodIndex > 0 ? "Add another payment method":"Enter your payment method"} <IconNext /></Button>
              
              {browserCSSMode === 'desktop' &&
                <Dialog
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.addCardOpen}
                    onClose={() => {
                    this.setState({ addCardOpen: false });
                    }}
                >
                  <div className={"v3_payment-methods " + (window.WPDomain || 'oscar')}>
                    <div className="header"><img src={image} /></div>
                      {allowDirectDebit &&
                        <div className="button-box">
                          <Button className={paymentMethod === "card" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"card"})}>Card</Button>
                          <Button className={paymentMethod === "dd" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"dd"})}>Direct Debit</Button>
                        </div>
                      }
                      {paymentMethod === "card" && <AddCreditCard_Braintree WPDomain={window.WPDomain} WPMode={window.WPMode} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={this.onSavePayment}/>}
                      {paymentMethod === "dd" && <AddDirectDebit WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={this.onSavePayment}/>}
                  </div>
                </Dialog>
              }
              </>
          );
    
        //}
        
        /*else {

            let name = "Share with Oscar";
            let image="https://www.sharewithoscar.com.au/assets/graphics/oscar-icon.png";
            if (this.props.spaceType === 'parking_bay'){
              
              if (window.WPDomain === 'demo') {
                name = "Demo by Oscar";
                image="https://demo.parkwithoscar.com.au/assets/graphics/logo-demo.png";
      
              } else {
                name = "Meriton by Oscar";
                image="https://meriton.sharewithoscar.com.au/assets/graphics/logo-meriton.png";
      
              }
            }
    
            // Web browsers and iOS
            return(
                <StripeCheckout 
                    token={this.handleStripeToken} 
                    stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                    name={name} // the pop-in header title
                    description="Save your credit card details"
                    image={image}
                    panelLabel="Save card"
                    email={this.props.user.profile.email}
                    allowRememberMe={false}>
                    <Button style={{paddingRight: '0px'}} className="wp-textbutton-booking">
                        Enter your payment <IconNext />
                    </Button> 
                </StripeCheckout>
            );
            
        }*/


        // Android does not support StripeCheckout - temp solution is to show a message to use the website
        /*if (window.document.isAndroid) {
          return (
            <Button style={{paddingRight: '0px'}} color="primary" onClick={() => this.setState({ androidStripeOpen: true })}>
              Enter your Payment <IconNext />
            </Button> 
          );
        }*/
      

        // Web browsers and iOS
        /*return(
            <StripeCheckout 
                token={this.handleStripeToken} 
                stripeKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                name={name} // the pop-in header title
                description="Save your credit card details"
                image={image}
                panelLabel="Save card"
                email={this.props.user.profile.email}
                allowRememberMe={false}>
                <Button style={{paddingRight: '0px'}} className="wp-textbutton-booking">
                    Enter your payment <IconNext />
                </Button> 
            </StripeCheckout>
        )*/
    }
    
    handleStripeToken = (response) => {
        //console.log(response)
        this.setState({loading: true})

        savePaymentCard({stripe_token: response.id}).then(resp => {
            //console.log(resp)
            updateUserProfileAjax().then(response => {
                //console.log(response)
                this.props.authChange()
                this.setState({
                    loading: false,
                    topupsOpen: true,
                })
            })
        })
    }

    handlePromoCodeInput = (event) => {
        this.setState({promocode: event.target.value})
    }

    handlePromoCodeAdd = () => {
        this.setState({loading: true})

        let promoDesc = undefined
        let discount = undefined
        getPromoCode(this.state.promocode).then(response => {
            if (response.data.valid) {
                if (response.data.percentage_discount) {
                    promoDesc = `${response.data.percentage_discount * 100}% off your booking`
                    discount = (total) => (total * (1 - response.data.percentage_discount))
                } else {
                    promoDesc = `$${response.data.flat_discount} off your booking`
                    discount = (total) => Math.max(total - response.data.flat_discount, 0)
                }
            } else {
                promoDesc = "Invalid promo code"
                discount = (total) => total
            }

            if (response.data.client_display && response.data.client_display !== ''){
              promoDesc = response.data.client_display;
            }
            
            this.setState({loading: false, promocodeGood:true, promocodeDescription: promoDesc, discount: discount, validCode: this.state.promocode})
        }).catch(error => {
            this.setState({
                loading: false, 
                promocodeGood:false,
                promocodeDescription: "Invalid promo code", 
                discount: (total) => total 
            })
        })
    }

    handlePayClicked = () => {
        this.setState({loading: true});
        const spaceUuid = this.props.spaceData.uuid;
        const bay_index = (this.props.spaceData2 && this.props.spaceData2.bay_index) || 1;    // From new search system, will be merged later
        const thisPM = this.state.arrAvailablePM[this.state.selectedPaymentMethodIndex];

        let params;
        let leadSource = localStorage.getItem('leadSource');    // will remove after successful booking

        let selectedVehicle = this.state.selectedVehicle;
    
        let myVehicle = this.props.user.profile.vehicles.find(elem => elem.default);
    
        if (selectedVehicle){
          myVehicle = selectedVehicle;
        }
    


        if (this.props.spaceType === 'parking_bay'){
          params = {
            "payment_method": thisPM.method, //  paymentMethod,
            "space_uuid": spaceUuid,
            "space_type": this.props.spaceType,
              "vehicle": myVehicle.pk,
              "start_date": this.state.msm_startdate, 
              "cease_date": this.state.msm_enddate, 
              "promo_code": this.state.validCode,
              "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
              "lead_source":leadSource
          }
  
        } else {
          
          if (this.props.searchMode === 1){
            // monthly
            params = {
              "payment_method": thisPM.method, //  paymentMethod,
              "space_uuid": spaceUuid,
              "search_mode": this.props.searchMode,
              "space_type": this.props.spaceType,
              "bay_index": bay_index, //this.props.spaceData.bay_index,
                "vehicle": myVehicle.pk,
                //"start_date": this.props.start.format(QueryParamDate),
                "start_date": this.state.booking_monthly_startdate,
                "cease_date": this.state.booking_monthly_enddate,
                //"cease_date": this.props.end.format(QueryParamDate),
                //"start_time": this.props.start.format(QueryParamTime),
                //"cease_time": this.props.end.format(QueryParamTime),
                "promo_code": this.state.validCode,
                "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                "lead_source":leadSource
            }
  
          } else {
            params = {
              "payment_method": thisPM.method, //  paymentMethod,
              "space_uuid": spaceUuid,
              "search_mode": this.props.searchMode,
              "space_type": this.props.spaceType,
              "bay_index": bay_index, //this.props.spaceData.bay_index,
                "vehicle": myVehicle.pk,
                "start_date": this.props.start.format(QueryParamDate),
                "cease_date": this.props.end.format(QueryParamDate),
                "start_time": this.props.start.format(QueryParamTime),
                "cease_time": this.props.end.format(QueryParamTime),
                "promo_code": this.state.validCode,
                "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
                "lead_source":leadSource
            }
  
          }
  
        }
        //console.log("params:",params);

        ///const startDate = this.props.start.format(QueryParamDate);
        sendBookingRequest(params, this.props.spaceData.uuid).then((response) => {
            const bookingRef = response.data.reference;
            if (leadSource){
              localStorage.removeItem('leadSource');
            }
    
            updateUserProfileAjax().then(response => {
                //window.heap.track('Parking Booking', {spaceUuid: spaceUuid, startDate: startDate });

                //this.setState({loading: false})
                if (this.props.authChange){
                  this.props.authChange(); //??????????
                }
                this.props.history.push(`/BookingDetails/${bookingRef}`)                         
            })
        }).catch(error => {
            if (error.response.data.detail === "Charge could not be made to default card") {
                // Show payment error
                this.setState({
                    loading: false,
                    cardpaymentError: true
                })
            } else {
                // Show payment error
                this.setState({
                  loading: false,
                  transactionError: error.response.data.detail
              });

            }
            
            /*if (error.response.data.detail === "Parking space has been booked" || 
                error.response.data.detail === "Parking space not available") {
                // Show payment error
                this.setState({
                    loading: false,
                    transactionError: "This parking space is no longer available"
                })
            } */       


        });
    }

/* 
<span style={styles.link} 
                        onClick={() => this.externalLink("https://www.sharewithoscar.com.au/terms-and-conditions/", "https://www.sharewithoscar.com.au/terms-and-conditions/")}>here</span>.
*/

    externalLink = (target, nativeTarget) => {
      if (isNativeWebview()) {
          const message = JSON.stringify({
              action: "external_link",
              target: nativeTarget
          })
          window.ReactNativeWebView.postMessage(message);
      } else {
          window.location.href = target
      }
    }

    // Need to pass this info back up many levels to the SEARCH container...
    setMSMStartDate = (startDate) => {
      this.props.setMSMStartDate(startDate);
    }
    setMSMEndDate = (endDate) => {
      this.props.setMSMEndDate(endDate);
    }


    updateMonthlyDates = (startDate, endDate) => {

      // Check for SAME date, don't allow
      if (moment(startDate).isSame(moment(endDate))){
        endDate = moment(startDate).add(1,'days').format("YYYY-MM-DD");
      }

      // startDate>enddate... enddate = startdate+1day
      if (moment(startDate).isAfter(moment(endDate))){
        endDate = null; //moment(startDate).add(1,'days').format("YYYY-MM-DD");        
      }


      this.setState({booking_monthly_startdate:startDate, booking_monthly_enddate:endDate});
    }

    setMonthlyStartDate = (startDate) => {
      //console.log("setMonthlyStartDate:",startDate);
      let booking_monthly_startdate = moment(startDate).format("YYYY-MM-DD");
      this.updateMonthlyDates(booking_monthly_startdate, this.state.booking_monthly_enddate);
      
    }
    setMonthlyEndDate = (endDate) => {
      //console.log("setMonthlyEndDate:",endDate);
      if (!endDate){
        this.updateMonthlyDates(this.state.booking_monthly_startdate, null);
      } else {
        let booking_monthly_enddate = moment(endDate).format("YYYY-MM-DD");
        this.updateMonthlyDates(this.state.booking_monthly_startdate, booking_monthly_enddate);
      }

    }

    
    render() {


      //    const { spaceData, spaceBuilding, spaceCompany } = this.props;
      return this.render_v3Checkout(this.props.spaceType, this.props.searchMode, this.props.user, this.props.spaceData, this.props.spaceCompany, this.props.spaceBuilding);

      if (this.props.spaceType === 'parking_bay'){
        return this.render_damian();
        //return this.render_parking_bay();
      }
     
      const searchMode = this.props.searchMode;
      
      let strStartDate;
      let strEndDate;

      let dp_startdate;
      let dp_enddate;

      if (searchMode === 1){
        // Monthly
        if (this.props.start){
          //strStartDate = this.props.start.format(WPMonthlyBookingsDisplayFormat);
        }
        // TEMP:
        
        if (this.props.end){
          //strEndDate = this.props.end.format(WPMonthlyBookingsDisplayFormat);
        }


        //console.log("booking_monthly_startdate:",this.state.booking_monthly_startdate);
        //console.log("booking_monthly_enddate:",this.state.booking_monthly_enddate);
  
        strStartDate = moment(this.state.booking_monthly_startdate).format(WPMonthlyBookingsDisplayFormat);
        dp_startdate = moment(this.state.booking_monthly_startdate).toDate(); //moment(strStartDate).toDate();
        //console.log({dp_startdate})
    
        if (this.state.booking_monthly_enddate){
          strEndDate = moment(this.state.booking_monthly_enddate).format(WPMonthlyBookingsDisplayFormat);
          dp_enddate = moment(this.state.booking_monthly_enddate).toDate(); //moment(strStartDate).toDate();
  
        } else {
          strEndDate = "MONTHLY - ONGOING";
        }
  

      } else {
        // Casual
        if (this.props.start){
          strStartDate = this.props.start.format(WeekdayDateTime);
        } 
        if (this.props.end){
          strEndDate = this.props.end.format(WeekdayDateTime);
        }  
      }

      
  
      
      // #PRICING rate_event
      const total = this.state.discount(this.props.spaceData.bookingPrice);

      /*const isOvernight = moment(this.props.start).add(this.props.duration, 'minutes').isAfter(this.props.start, 'days')
                          && this.props.duration > 360
                          && this.props.spaceData.rate_event === undefined*/
      const defaultVehicle = this.props.user.profile.vehicles.find(elem => elem.default)        

      const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <IconEdit style={{fontSize: '22px', marginLeft: '4px', verticalAlign: 'text-bottom'}} 
                                    onClick={onClick} ref={ref}/>
      ));
        
      //  <div className="wp-booking"></div>
      //div style={styles.contentContainer} className="oscar-booking">
      return(
            
              <div className="sd-container oscar-booking">
                <LoadingModal open={this.state.loading}/>

                <FullPageModal open={this.state.vehiclesMenuOpen}>
                    <VehiclesMenu onClose={() => this.setState({ vehiclesMenuOpen: false })}
                        userProfile={this.props.user.profile}
                        authChange={this.props.authChange}
                    />
                </FullPageModal>

                <FullPageModal open={this.state.cardpaymentError} >
                    <CardPaymentError
                        onClose={() => this.setState({ 
                            loading: false,
                            cardpaymentError: false 
                        }, this.props.payCancel())}
                        last4={this.props.user.profile.card_last4} 
                    />
                </FullPageModal>

                <Button style={styles.leftTopButton} color="primary" onClick={this.props.payCancel}>
                    Cancel
                </Button>

                
                  <div className="sd-details">

                {/*<Paper style={styles.container}>*/}
                    <div style={styles.descriptions}>
                        {this.props.spaceData.walkingDistance !== undefined && 
                        <Typography style={{textTransform: 'uppercase'}} variant="caption" color="primary">
                            {this.props.spaceData.walkingDistance} walk from your destination
                        </Typography>}
                        <Typography style={{display: 'inline'}} variant="display1" color="primary">
                            {this.props.spaceData.type}
                        </Typography>                        
                        <Typography style={{display: 'inline'}} variant="display1">
                            {` on`} 
                        </Typography>
                        <Typography variant="display1">
                            {this.props.spaceData.street_name}
                        </Typography>
                    </div>

                    <div style={styles.bookingProperties} >
                        
                        {searchMode === 1 ?
                        <>
                          <Grid style={styles.bookingProperty} container justify="space-between" alignItems="center">
                              <Grid item><Typography variant="body1">Start Date</Typography></Grid>
                              <Grid item>
                                  <Typography variant="body1" style={{display:'inline-block',position:'absolute',right:'44px'}}>{strStartDate}</Typography>
                                  {/*<p style={{display:'inline-block',position:'absolute',right:'50px'}}>{strStartDate}</p>*/}
                                  <DatePicker
                                    selected={dp_startdate}
                                    onChange={(date) => this.setMonthlyStartDate(date)}
                                    minDate={addDays(new Date(),2)}
                                    maxDate={addDays(new Date(),179)}
                                    withPortal
                                    fixedHeight
                                    customInput={<ExampleCustomInput />}
                                  />
                              </Grid>
                          </Grid>


                          <Grid style={styles.bookingProperty} container justify="space-between" alignItems="center">
                              <Grid item><Typography variant="body1">End Date</Typography></Grid>
                              <Grid item>
                                  <Typography variant="body1" style={{display:'inline-block',position:'absolute',right:'44px'}}>{strEndDate}</Typography>
                                  <DatePicker
                                    selected={dp_enddate}
                                    onChange={(date) => this.setMonthlyEndDate(date)}
                                    minDate={addDays(dp_startdate,30)}
                                    maxDate={addDays(dp_startdate,180)}
                                    isClearable={true}
                                    withPortal
                                    fixedHeight
                                    customInput={<ExampleCustomInput />}                              
                                  />
                              </Grid>
                          </Grid>
                        </>
                        :
                        <>
                          <Grid style={styles.bookingProperty} container justify="space-between" alignItems="center">
                              <Grid item><Typography variant="body1">Arriving</Typography></Grid>
                              <Grid item><Typography variant="body1">{strStartDate}</Typography></Grid>
                          </Grid>
                        
                          <Grid style={styles.bookingProperty} container justify="space-between" alignItems="center">
                            <Grid item><Typography variant="body1">Leaving</Typography></Grid>
                            <Grid item><Typography variant="body1">{strEndDate}</Typography></Grid>
                          </Grid>
                        </>
                        }
                        

                        <Grid style={styles.bookingProperty} container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="body1">
                                    Vehicle
                                </Typography>
                            </Grid>
                            <Grid item>
                                {defaultVehicle === undefined 
                                ?<Button style={{paddingRight: '0px'}} color="primary"
                                onClick={() => this.setState({vehiclesMenuOpen: true})}>
                                    Enter your vehicle details <IconNext />
                                </Button> 
                                :<Typography variant="body1">
                                    Rego: {defaultVehicle.registration}
                                    <IconEdit style={{fontSize: '22px', marginLeft: '8px', verticalAlign: 'text-bottom'}} 
                                    onClick={() => this.setState({vehiclesMenuOpen: true})} color="primary" />
                                </Typography>}
                            </Grid>
                        </Grid>
                        {searchMode === 1 &&
                          <Grid style={styles.bookingProperty} container justify="space-between" alignItems="center">
                              <Grid item  >
                                  <Typography variant="body1">
                                      Price
                                  </Typography>
                              </Grid>
                              <Grid item  >
                                  ${parseFloat(total).toFixed(2)}/month
                              </Grid>
                          </Grid>
                        }
                        <Grid style={styles.bookingProperty} container justify="space-between" alignItems="center">
                            <Grid item  >
                                <Typography variant="body1">
                                    Payment Method
                                </Typography>
                            </Grid>
                            <Grid item  >
                                {this.checkPayment(false)}
                            </Grid>
                        </Grid>
                        <Grid style={{padding: '8px 0px', borderBottom: '1px solid #efefef'}} container justify="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="body1">
                                    Promo code
                                </Typography>
                            </Grid>
                            <Grid item>
                              <input value={this.state.promocode} onChange={this.handlePromoCodeInput} style={styles.promoInput} type="text" maxLength={6} />
                                <Button variant="contained" size="small" color="primary" style={{boxShadow: 'none'}}
                                onClick={this.handlePromoCodeAdd} disabled={this.state.promocode === undefined || this.state.promocode === ''}>
                                    Add
                                </Button>
                            </Grid>

                            {this.state.promocodeDescription !== undefined && <Grid container justify="flex-end" style={{marginTop: '16px'}}>
                                <Typography variant="body1">
                                    {this.state.promocodeDescription}
                                </Typography>
                            </Grid>}
                        </Grid>

                        {(searchMode === 1 && !this.props.spaceData.auto_approve) &&
                          <Grid style={styles.bookingProperty} container alignItems="left">
                              <Grid item  >
                                <div className="will-need-approval">You will not be charged until your booking is accepted.<br/>A hold for the amount will be placed on your card.</div>
                              </Grid>
                          </Grid>                        
                        }

                        
                    </div>

                    {searchMode === 1 && <div className="will-need-approval" style={{paddingTop:'14px'}}>See here for <a href="https://www.sharewithoscar.com.au/terms-and-conditions/" target="_blank" className="oscar-external-link" style={{fontSize:'12px'}}>terms and conditions</a></div>}

                    {this.state.transactionError && 
                    <div>
                        <Typography style={{marginLeft: '16px', color: 'red', fontSize:'24px'}} variant="caption">
                            {this.state.transactionError}
                        </Typography>
                    </div>}

                {/*</Paper>*/}
                </div>


                <div className="sd-actions">
                  <LoadingButton loading={false}>
                            <Button variant="contained" fullWidth color="primary" 
                            id={searchMode === 1 ?"monthlyconfirm":"book_spot"}
                            onClick={this.handlePayClicked}
                            disabled={!this._payment || defaultVehicle === undefined}>
                                Pay ${parseFloat(total).toFixed(2)}
                                {/*isOvernight && <span style={{textTransform: 'lowercase', marginLeft: '3px'}}>(incl. $12 overnight fee)</span>*/}
                            </Button>
                        </LoadingButton>

                </div>
             
            </div>
        );
    }


  // =============================================
  // v3 Checkout
  // ---------------------------------------------
  // Single Render Process
  // displayMode:
  // - parking_space
  // - parking_bay
  // - storage (later)
  // - others etc...
  // 
  // data: inputObject
  // =============================================

  //TODO: New Hooks style (one day, lol)
  //      return this.render_v3Checkout(this.props.spaceType, this.props.spaceData, this.props.spaceCompany, this.props.spaceBuilding);
  // fail safe

  onDateChange(id, displayMode,date){

    if (displayMode === 'parking_space'){
      if (id === 'start'){
        this.setMonthlyStartDate(date);
      } else if (id === 'end'){
        this.setMonthlyEndDate(date);
      }
      
    } else if (displayMode === 'parking_bay'){

      if (id === 'start'){
        this.setMSMStartDate(date);
      } else if (id === 'end'){
        this.setMSMEndDate(date);
      }


    } else {
      //console.log("Unknown mode:",displayMode);
    }
    
    
    
  }

  render_v3Checkout(displayMode, searchMode, user, data, company={}, building={}){

    // Start with default vehicle (if available), but also allow selection of different vehicle
    let selectedVehicle = this.state.selectedVehicle;
    const thisPM = this.state.arrAvailablePM[this.state.selectedPaymentMethodIndex];

    let defaultVehicle = this.props.user.profile.vehicles.find(elem => elem.default);

    if (selectedVehicle){
      defaultVehicle = selectedVehicle;
    }
    

    //const defaultVehicle = this.props.user.profile.vehicles.find(elem => elem.default)

    const spaceSize = vehicleSizeMap[this.props.spaceData.vehicle_type]
    const userVehicleSize = defaultVehicle ? vehicleSizeMap[defaultVehicle.car_type] : vehicleSizeMap['Bike']

    //console.log('sizes', spaceSize, userVehicleSize)
    if (userVehicleSize > spaceSize) {
        /*this.setState({
            transactionError: "This spot might be a tight squeeze!"
        })*/
    }


    let divHeaderContent;
    let divVehicheSizeWarning;
    let divFooterError;
    let divFooterButtons;


    let minDateStart_addDays = 2;


    // OSCAR
    /* 
       
      const searchMode = this.props.searchMode;
      
      let strStartDate;
      let strEndDate;

      let dp_startdate;
      let dp_enddate;



      
    
    */

      let totalDays;


/*      dp_startdate
      strStartDate
      dp_enddate
      strEndDate
*/



    let msm_startdate;
    let msm_enddate;
    let str_msm_startdate;
    let str_msm_enddate;
    let strStartDate;
    let strEndDate;
    let dp_startdate;
    let dp_enddate;
    let allowEditDates=false;
    let allowEditDates2=false;




    // =======================================================================================
    // PRICING
    // =======================================================================================

    // Defaults
    let float_bookingPrice = parseFloat(data.bookingPrice,10);
    let bookingPriceLabel = "Booking Price";
    let strDueTodayMessage;

    const allowDirectDebit = this.state.allowDirectDebit;


    if (displayMode === 'parking_space'){ 
      // ======================
      // Oscar Casual/Monthly
      // ======================

      divHeaderContent = <><span>{data.type}</span> on {data.street_name}</>;

      // Process dates (compact to keep it clean)
      if (searchMode === 1){
        // Monthly

        //allowDirectDebit = true;

        allowEditDates = true;
        allowEditDates2 = false;
        minDateStart_addDays = 2;
        

        strStartDate = moment(this.state.booking_monthly_startdate).format(WPMonthlyBookingsDisplayFormat);
        dp_startdate = moment(this.state.booking_monthly_startdate).toDate(); //moment(strStartDate).toDate();
            
        if (this.state.booking_monthly_enddate){
          strEndDate = moment(this.state.booking_monthly_enddate).format(WPMonthlyBookingsDisplayFormat);
          dp_enddate = moment(this.state.booking_monthly_enddate).toDate(); //moment(strStartDate).toDate();
  
        } else {
          strEndDate = "MONTHLY - ONGOING";
        }
  

      } else {
        // Casual
        
        //allowDirectDebit = false;
        allowEditDates = false;
        allowEditDates2 = false;
        if (this.props.start){
          strStartDate = this.props.start.format(WeekdayDateTime);
        } 
        if (this.props.end){
          strEndDate = this.props.end.format(WeekdayDateTime);
        }  
      }


    } else if (displayMode === 'parking_bay'){

      //allowDirectDebit = true;
      // ======================
      // Meriton/Community
      // ======================
      divHeaderContent = <><span>{building.building_name}</span> on {building.street_name}</>;
      allowEditDates = true;
      allowEditDates2 = true;
      minDateStart_addDays = 1;

      // Process dates (compact to keep it clean)
      msm_startdate = this.props.msm_startdate;
      msm_enddate = this.props.msm_enddate;
      str_msm_startdate = moment(msm_startdate).format(WPMonthlyBookingsDisplayFormat);
      if (msm_enddate){ str_msm_enddate = moment(msm_enddate).format(WPMonthlyBookingsDisplayFormat); } else { str_msm_enddate = "MONTHLY - ONGOING"; }
      strStartDate = str_msm_startdate; strEndDate = str_msm_enddate;
      dp_startdate = moment(msm_startdate).toDate();
      if (msm_enddate){ totalDays = moment(msm_enddate).diff(moment(msm_startdate), 'days') + 1; dp_enddate = moment(msm_enddate).toDate(); } 
      
      if (totalDays >= 31 && totalDays <=59){
        bookingPriceLabel = "Monthly Payment";
        let float_remainder = (float_bookingPrice/30) * (totalDays-30);
        strDueTodayMessage = `$${float_remainder.toFixed(2)} payment for the remaining period will be collected before the start of the next period`;
      } else if (!totalDays || totalDays > 30){
        bookingPriceLabel = "Monthly Payment";
        strDueTodayMessage = `$${float_bookingPrice.toFixed(2)} will be collected on or around the same day every month until you cancel your booking or until the end date`;
      } else {
        bookingPriceLabel = "Total Price";
      }
    
    } else {
      divHeaderContent = <p>UNKNOWN TYPE!</p>;
    }



    // All platforms use same price/discount/credit calculations
    let float_promoCodeDiscount = float_bookingPrice - this.state.discount(float_bookingPrice);
    let float_totalDue = float_bookingPrice - float_promoCodeDiscount;



    let balanceAUD = user.profile.balance_aud || 0;
    let balanceOSC = user.profile.balance_osc || 0;

    // Apply AUD
    let balanceAUDToApply = Math.min(float_totalDue,balanceAUD); // Only apply up to max current price
    float_totalDue-=balanceAUDToApply;

    // Apply OSC
    let balanceOSCToApply = Math.min(float_totalDue,balanceOSC); // Only apply up to max current price
    float_totalDue-=balanceOSCToApply;

    let float_totalCredits = balanceAUDToApply + balanceOSCToApply;


    let divPM = this.checkPayment(allowDirectDebit);


    let float_paymentSurcharge = 0;
    // Flat 1.9% for all debit/credit card transactions
    // $175 = $3.325 round it UP to $3.33?? shows $3.32
    if (float_totalDue > 0 && this.state.arrAvailablePM[this.state.selectedPaymentMethodIndex].method === 'card'){
      if (float_totalDue < 25.00){
        float_paymentSurcharge = 0.50;
      } else {
        
        // Only 1.9% for monthly bookings
        if (allowDirectDebit){
          float_paymentSurcharge = +(float_totalDue * CARD_TRANSACTION_FEE_PERCENTAGE).toFixed(2);
        }
        
      }
      
      float_totalDue += float_paymentSurcharge;
    }
        
    
    // Footer
    if (this.state.transactionError){
      divFooterError = <div className="error-box">{this.state.transactionError}</div>;
    }
    if (userVehicleSize > spaceSize) {
      divVehicheSizeWarning = <div className="warning-box">Your vehicle may be too large for this location</div>;
    }

   


    let readyToBook = false;


    if (thisPM.isAllowed && defaultVehicle){
      readyToBook = true; //this._payment
    }
    divFooterButtons = <><button className="v3_btn-standard" id={searchMode === 1 ?"monthlyconfirm":"book_spot"} onClick={this.handlePayClicked} disabled={!readyToBook}>Pay ${float_totalDue.toFixed(2)} Now</button></> ;


    let srcImgTick = "https://images.sharewithoscar.com.au/assets/graphics/oscar_promo_tick.svg";
    if (window.WPDomain === 'meriton'){
      srcImgTick = "https://images.sharewithoscar.com.au/assets/graphics/meriton_promo_tick.svg";
    } else if (window.WPDomain === 'community'){
      srcImgTick = "https://images.sharewithoscar.com.au/assets/graphics/community_promo_tick.svg";
    }




/* 
<button class="MuiButtonBase-root-161 MuiIconButton-root-164 wp-btn-close2" tabindex="0" type="button"><span class="MuiIconButton-label-169"><svg class="MuiSvgIcon-root-170" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path fill="none" d="M0 0h24v24H0z"></path></svg></span><span class="MuiTouchRipple-root-246"></span></button>
*/

    return (
      <>
      <LoadingModal open={this.state.loading}/>

      <FullPageModal open={this.state.vehiclesMenuOpen}>
        <VehiclesMenu onSelectVehicle={(selectedVehicle) => this.setState({selectedVehicle, vehiclesMenuOpen: false})} onClose={() => this.setState({ vehiclesMenuOpen: false })} userProfile={this.props.user.profile} authChange={this.props.authChange} isCheckoutMode={true} />
      </FullPageModal>
    
      <FullPageModal open={this.state.cardpaymentError} >
        <CardPaymentError onClose={() => this.setState({ loading: false, cardpaymentError: false }, this.props.payCancel())} last4={this.props.user.profile.card_last4} />
      </FullPageModal>


      <div className="v3_checkout">
        <div className="v3_checkout_header">
          <h1>{divHeaderContent}</h1>
          <IconButton className="" onClick={this.props.payCancel}><IconClose/></IconButton>
          {/*<button className="v3_btn-outline" onClick={this.props.payCancel}>
        <svg class="MuiSvgIcon-root-170" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path><path fill="none" d="M0 0h24v24H0z"></path></svg>
    </button>*/}
        </div>

        <div className="v3_checkout_content_container">
        
          <div className="v3_checkout_content">

            <div>
              <div className="crow">
                <div className="label">Starting Date</div>
                {!allowEditDates ?
                  <div className="value">{strStartDate}</div>
                  :
                  <DatePicker
                    selected={dp_startdate}
                    onChange={(date) => this.onDateChange('start',displayMode,date)}
                    minDate={addDays(new Date(),minDateStart_addDays)}
                    maxDate={addDays(new Date(),179)}
                    withPortal fixedHeight
                    customInput={<ExampleCustomInput3 strValue={strStartDate} />}
                  />
                }
              </div>

              <div className="crow">
                <div className="label">End Date</div>
                {!allowEditDates2 ?
                  <div className="value">{strEndDate}</div>
                  :
                  <DatePicker
                    selected={dp_enddate}
                    onChange={(date) => this.onDateChange('end',displayMode,date)}
                    minDate={addDays(dp_startdate,30)}
                    maxDate={addDays(dp_startdate,180)}
                    isClearable={false}   //true
                    withPortal
                    fixedHeight
                    customInput={<ExampleCustomInput3 strValue={strEndDate} />}
                  />
                }
              </div>

              <div className="crow">
                <div className="label">Vehicle</div>
                {(!defaultVehicle && <div className="value is-editable" onClick={() => this.setState({vehiclesMenuOpen: true})}>Enter your vehicle details <IconNext /></div>)}
                {(defaultVehicle &&
                  <div className="value is-editable" onClick={() => this.setState({vehiclesMenuOpen: true})}>
                    {defaultVehicle.make} {defaultVehicle.model} - <b>{defaultVehicle.registration}</b>
                    <IconEdit/>
                  </div>)}
              </div>
              {divVehicheSizeWarning}                              

            </div>

            <div className="crow-header"><div className="label">Total Payment</div></div>

            <div>
              <div className="crow"><div className="label">{bookingPriceLabel}</div><div className="value">${float_bookingPrice.toFixed(2)}</div></div>


              {this.state.promocodeGood ?
                <div className="v3_discount_box promocode">
                  <div className="label">
                    <img src={srcImgTick}/>
                    <p><b>{this.state.promocode}</b> Applied</p><span>{this.state.promocodeDescription}</span>
                  </div>
                  <div className="value">-${float_promoCodeDiscount.toFixed(2)}</div>
                </div>
                :
                <div className="crow">
                  <div className="label" style={{lineHeight:'29px'}}>Promo Code {this.state.promocodeGood == false && <span className="value-error">Invalid promo code</span>}</div>
                    <div className="value">
                      <div className="v3_inputbox_promocode">
                        <input value={this.state.promocode || ''} onChange={this.handlePromoCodeInput} type="text" maxLength={6} />
                        <Button className="wp-button" style={{height:'29px'}} onClick={this.handlePromoCodeAdd} disabled={this.state.promocode === undefined || this.state.promocode === ''}>Add</Button>

                      </div>
                  </div>
                </div>
              }
              <div className="crow">
                <div className="label">Less Balance or Free Credits</div>
                <div className="value">-${float_totalCredits.toFixed(2)}</div>
              </div>

              {float_paymentSurcharge >= 0 &&
                <div className="crow">
                  <div className="label">Transaction fee {searchMode === 1 && <span style={{display:'block',fontSize:'14px'}}>1.9% surcharge applies to all card transactions</span>}</div>
                  <div className="value">${float_paymentSurcharge.toFixed(2)}</div>
                </div>
              }

            </div>

            {/*<div className="v3_discount_box aud">
              <div className="label">Less applied AUD balance</div>
              <div className="value">-${balanceAUDToApply.toFixed(2)}</div>
            </div>*/}

            {/*<div className="v3_discount_box osc">
              <div className="label">Less Oscar Coins</div>
              <div className="value">-${balanceOSCToApply.toFixed(2)}</div>
          </div>*/}

            <div className="crow-total-bg"><div className="label">Total Payment Due Today</div><div className="value">${float_totalDue.toFixed(2)}</div></div>


            {divPM}

            {/*<div className="crow-paymentmethod">
              <div className="label">Payment Method</div>              
              <div className="value">{divPM}</div>
        </div>*/}

            

            <div className="crow-message"><div className="label">{strDueTodayMessage}</div></div>

            {window.WPDomain ?
            <div className="crow-policy"><p>View our <a href={`https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/`} target="_blank">cancellation policy</a> here</p></div>:
            <div className="crow-policy"><p>View our <a href={`https://www.sharewithoscar.com.au/oscar-cancellation-policy/`} target="_blank">cancellation policy</a> here</p></div>
            }
            

  
            
          </div>
        </div>
        <div className="v3_checkout_footer">
          {divFooterError}
          <div className="buttons-box">{divFooterButtons}</div>
          
        </div>
      </div>
      </>
    );
  }

}

const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
  <IconEdit style={{fontSize: '22px', marginLeft: '4px', verticalAlign: 'text-bottom'}}  onClick={onClick} ref={ref}/>
  ));

  const ExampleCustomInput3 = forwardRef(({ strValue, onClick }, ref) => (
    <div className="value is-editable" onClick={onClick} ref={ref}>{strValue}<IconEdit/></div>
    ));
  
/* 

*/

//{divFooterContent}
//const MyMapComponent = compose(