import React, { Component } from 'react';
import {
    Redirect,
} from 'react-router-dom';

export default class NotFoundCP extends Component {
    render() {
        return(
            <div className="datagrid error">
                <h1>Invalid page</h1>
            </div>
        )
    }
}