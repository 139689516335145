const BookingDayHeader = "Do MMM"
const BookingsRangeDisplayFormat = "Do MMMM h:mm A"
//const BookingsRangeDisplayFormat = "h:mm A, Do MMMM YYYY"
const CalendarMonth = "MMMM YYYY"
const ParseDateTimeParameters = "YYYY-MM-DD HH:mm:ss"
const QueryParamDate = "YYYY-MM-DD";
const QueryParamTime = "HH:mm";
const ShortDateTime = "YYYY-MM-DD h:mm A";
const TwelveHourTime = "h:mm A";
const WeekdayDate = "ddd D MMM";
const WeekdayDateTime = "ddd D MMM h:mm A";
const WPMonthlyBookingsDisplayFormat = "Do MMMM YYYY";

export {
    BookingDayHeader,
    BookingsRangeDisplayFormat,
    CalendarMonth,
    ParseDateTimeParameters,
    QueryParamDate,
    QueryParamTime,
    ShortDateTime,
    TwelveHourTime,
    WeekdayDate,
    WeekdayDateTime,
    WPMonthlyBookingsDisplayFormat
}