import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import { isNativeWebview } from '../utils/IsNativeWebview';
import {NavItemsCP, ProfileNavCP} from './NavigationMenu.js';


import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  Hidden,
  IconButton,
  Paper,
  Popper,
  Typography
} from '@material-ui/core'

import NotificationIcon from '@material-ui/icons/Notifications';

import {getCMS} from '../api/Oscar-api.js';

import '../styles/LayoutCP.css';


const styles = {
  layoutContainer: {
      fontFamily:'Rubik',
      minWidth: '300px',
      //maxWidth: '1280px',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
  },
  navigationContainer: {
      width: '250px', 
      flexGrow: '0', 
      flexShrink: '0',
      borderRight: '2px solid #dedede'
  },
  contentContainer: {
    fontFamily:'Rubik',
      flexGrow: 1,
      alignItems: 'stretch',
      display: 'flex',
      overflowY: 'hidden',
  },
  contentWindow: {
      flexGrow: '1',  
      position: 'relative',
      flexBasis: '1px',
      overflowX: 'auto',
      overflowY: 'hidden',
  },
  appbarContainer: {
      //height: '60px',
      padding: '8px 18px',
      zIndex: '1300',
  },
  appbarButton: {
      margin: '0px 1px'
  },
  userProfileButton: {
      marginLeft: '16px',
      //marginRight: '16px',
      padding: '4px 16px',
  },
  profileImage: {
      width: '30px', 
      height: '30px',
      borderRadius: '40px',
      marginRight: '8px',
  },
  unreadNotifications: {
      position: "absolute",
      left: '14px',
      top: '14px',
      height: '10px',
      width: '10px',
      borderRadius: '10px',
      backgroundColor: '#1AA5A2',
  }
}

const CM_REFRESH_TIME = 5000;
const CM_REFRESH_TIME_ERROR = 15000;

const CM_MESSAGES_REFRESH_TIME = 2000;
const CM_MESSAGES_REFRESH_TIME_ERROR = 5000;
export default class MasterLayoutCP extends Component {
  state = {ready: true, unreadNotifications: false, profileNavigationOpen: false, profileNavClick: true,  nativeWebView: isNativeWebview()}

  componentDidMount() {
      //this.checkNotifications();
      this.checkCMS();

  }
  


  checkCMS = () => {
    // Desktop only - don't call API until user is authenticated
    if (!this.state.nativeWebView && !this.props.user.authenticated){
      setTimeout(this.checkCMS, CM_REFRESH_TIME);
      return;
    }

    let REFRESH_TIME = CM_REFRESH_TIME;
    let REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;


    if (window.location.href.toLowerCase().indexOf('/booking/') > 0){
      REFRESH_TIME = CM_MESSAGES_REFRESH_TIME;
      REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;
    }


    getCMS(this.props.user.profile.email).then(response => {
      const {hasNotification, hasMessage, lastOwnerBooking} = this.props.CMS;
      const data = response.data;

      let CMS = data;
      CMS.timestamp = moment().toString();


      if (data.hasNotification !== hasNotification || data.hasMessage !== hasMessage || data.lastOwnerBooking !== lastOwnerBooking){
        this.props.setCMS(CMS);
      } else if (REFRESH_TIME == CM_MESSAGES_REFRESH_TIME) {
        this.props.setCMS(CMS);
      }

      if (!this.state.nativeWebView) {
        setTimeout(this.checkCMS, REFRESH_TIME);
      }
  

    }).catch(error => {
      console.log(error);
      if (!this.state.nativeWebView) {
        setTimeout(this.checkCMS, REFRESH_TIME_ERROR);
      }
  
    });
  }
  /*componentDidMount() {
    console.log("[MasterLayourCP:componentDidMount]",this.props);
    //???
      setTimeout(() => {
          this.setState({
              nativeWebView: isNativeWebview(),
              ready: true
          });
      }, 1);
  }*/
  handleToggle = () => {
      this.setState(state => ({ profileNavigationOpen: !state.profileNavigationOpen }));
  };
  
  handleClose = event => {
      if (this.anchorEl.contains(event.target)) {
          return;
      }

      this.setState({ profileNavigationOpen: false });
  };

  render() {
    const CMS = this.props.CMS;
    //console.log("MasterLayoutCP:Render");
    if (!this.state.ready) {
          return(
              <div>Wait...</div>
          )
      }
      if (this.state.nativeWebView) {
          return(
              <div className="oscar" style={{height: '100vh'}}>
                  {this.props.children}
              </div>
          )    
      }
      
      // Render the layout for web browsers
      // console.log('rendering layout')
      const homeLink = window.WPDomain ? "/cp/wp_bookings" : "/cp/bookings"


      let logo;
      if (window.WPDomain === 'meriton'){
        logo = <img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-blue3.svg" alt="Meriton powered by Oscar" style={{width: '140px', height: 'auto', marginTop: '0',marginLeft:'3px' }} />;
      } else if (window.WPDomain === 'unilodge') {
        logo = <img src="https://unilodge.parkwithoscar.com.au/assets/unilodge-logo.png" alt="Unilodge powered by Oscar" style={{width: '140px', height: 'auto', marginTop: '0',marginLeft:'3px' }} />;
      } else {
        logo = <img src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_teal.svg" alt="Oscar logo" style={{width: '160px', height: 'auto', marginTop: '18px', color: 'inherit'}} />;          
      }
/*
                                    {window.WPDomain?
                                    <img src="https://unilodge.parkwithoscar.com.au/assets/unilodge-logo.png" alt="Meriton powered by Oscar" style={{width: '140px', height: 'auto', marginTop: '0',marginLeft:'3px' }} />:
                                    <img src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_teal.svg" alt="Oscar logo" style={{width: '160px', height: 'auto', marginTop: '18px', color: 'inherit'}} />
                                    }

*/
      return (
          <div className="oscar" style={styles.layoutContainer}>
              <Hidden smDown>
                  
                  <Paper  style={{zIndex: 15}}>
                      <Grid container style={styles.appbarContainer} alignItems="center" direction="row">
                          <Grid item style={{flexGrow: 1}} >
                              <Typography variant="title" color="primary">
                                  <div style={{display: 'block', width: '80px'}}>
                                  <Link to={homeLink} >
                                      {logo}
                                  </Link>
                                  </div>
                              </Typography>
                          </Grid>
                          <Grid item>
                              <Grid container alignItems="center">
                                <Grid item className={"cp-notifications" + (CMS.hasNotification ? " waiting":"")}>
                                      <IconButton color="inherit" id="cp-notifications" aria-label="Menu"
                                          component={Link} to='/cp/notifications'
                                      >
                                          <NotificationIcon />
                                          {CMS.hasNotification == true && <div style={styles.unreadNotifications}></div>}
                                      </IconButton>
                                  </Grid>

                                  <Grid item>
                                      <Button style={styles.userProfileButton} variant="outlined" className="profile-menu-cp-toggle"
                                          buttonRef={node => {
                                              this.anchorEl = node;
                                          }}
                                          onClick={this.handleToggle}
                                      >
                                          {this.props.user.profile.image_user === null || this.props.user.profile.image_user === undefined
                                          ? <img src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" 
                                              alt="Placeholder avatar"
                                              style={styles.profileImage} />
                                          : <img src={this.props.user.profile.image_user.path} 
                                              alt="Placeholder avatar"
                                              style={styles.profileImage} />}
                                          {this.props.user.profile.first_name} {this.props.user.profile.last_name}
                                      </Button>
                                      <Popper open={this.state.profileNavigationOpen} anchorEl={this.anchorEl} transition disablePortal>
                                          {({ TransitionProps, placement }) => (
                                          <Grow
                                              {...TransitionProps}
                                              id="menu-list-grow"
                                              style={{ zIndex: 4, transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                          >
                                              <Paper style={{backgroundColor: '#efefef'}}>
                                              <ClickAwayListener onClickAway={this.handleClose}>
                                              <ProfileNavCP
                                                  authChange={this.props.authChange}
                                                  user={this.props.user}
                                                  extraPadding={4}
                                              />
                                              </ClickAwayListener>
                                              </Paper>
                                          </Grow>
                                          )}
                                      </Popper>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Paper>
                  
                  
              </Hidden>
              <div style={styles.contentContainer}>
                  <div style={{display: 'flex', flexWrap:'nowrap', width: '100%'}}>
                      
                      <Hidden smDown>
                        <div style={styles.navigationContainer}>
                            <NavItemsCP
                                user={this.props.user}
                                extraPadding={4}
                            />
                        </div>
                      </Hidden>

                      <div style={styles.contentWindow}>
                          {this.props.children}    
                      </div>
                  </div>
              </div>
          </div>
      )
  }
}
