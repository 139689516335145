import React, {Component} from 'react';

import {
    Button,
    Typography
} from "@material-ui/core";

const styles = {
    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '100px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    }
}

export default class AvailabilityError extends Component {
    render() {
        return(
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className="mainContent topPadded" style={styles.alertPage}>
                <Typography variant="display1" color="primary">
                    This booking cannot be extended.
                </Typography>

                <Typography>
                    {this.props.errorDetail || <>This spot is not available until that time.</>}
                </Typography>

                <div style={styles.alertImageContainer}>
                    <img src="https://www.sharewithoscar.com.au/assets/graphics/unable_remove_availability.svg" alt="availability error"/>                
                </div>

                <Button style={styles.alertButton} fullWidth variant="contained" 
                onClick={this.props.onClose} color="primary">
                    Got it
                </Button>
            </div>
            </div>
        )
    }
}