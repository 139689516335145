import React, { Component } from "react";

import { CircularProgress, Paper } from "@material-ui/core";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    padding: "0",
    top: "0",
    left: "0",
    right:"0",
    bottom:"0",
    //height: "100vh",
    //width: "100vw",
    backgroundColor: "rgba(256, 256, 256, 0.5)",
    zIndex: "1300",
  }
};

export default class LoadingModal extends Component {
  render() {

    if (!this.props.open)
      return null;
      //style={{color:'#ba925a'}}
    return (
          <Paper style={styles.container}>
            <CircularProgress 
             style={this.props.color ? { color: this.props.color } : {}}
            />
            {this.props.status ? <div>{this.props.status.map(text => { return <p>{text}</p>})}</div> : null}
          </Paper>
    );
  }
}
