import React, {Component} from 'react';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
    withStyles,
    Dialog,
    Icon,
  } from "@material-ui/core";
  

export default class CardWithBrand extends Component {
    render() {

        const profile = this.props.profile;
    const brandIcon =
        profile.card_brand === "Visa"
          ? "https://www.sharewithoscar.com.au/assets/graphics/cardbrand_visa.svg"
          : profile.card_brand === "American Express"
          ? "https://www.sharewithoscar.com.au/assets/graphics/cardbrand_amex.svg"
          : "https://www.sharewithoscar.com.au/assets/graphics/cardbrand_mastercard.svg";

          return (
            <div className="v3_paymentby_card">
              {/*<div className='label'>Paying with</div>*/}
              <img src={brandIcon} key={profile.card_last4} alt="card brand" />
              <div className="card-number"><span>**** **** **** </span>{("0000" + profile.card_last4).substr(-4)}</div>
            </div>
          );
    

/*      return (
        <Typography variant="body1">
          <span>Paying with</span>
          <img src={brandIcon} key={profile.card_last4} style={{ width: "32px", verticalAlign: "middle" }} alt="card brand" />
          <span style={{ verticalAlign: "sub", marginLeft: "16px", marginRight: "8px" }}>**** **** ****</span>
          {("0000" + profile.card_last4).substr(-4)}
        </Typography>
      );
*/
    }
}