import React, { Component, useEffect } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  RouterProps
} from 'react-router-dom';

import { isNativeWebview } from './utils/IsNativeWebview';



import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';

// Import theming utilities
import './App.css';
import './styles/ColorSchemeWP.css';
import "react-datepicker/dist/react-datepicker.css";

import { MuiThemeProvider } from '@material-ui/core/styles';
import {OscarTheme} from './styles/OscarTheme.js';

import {
  initializeApplicationUser,
  getApplicationUser,
  requestLocalToken,
  processTokenResponse
} from './utils/Authentication.js';
import { sendOscarAnalytics } from './api/Oscar-api.js';

import LoadingModal from './components/LoadingModal.js';

import EditItem from './components/EditItem.js';

import MasterLayoutWP from './components/MasterLayoutWP.js';
import AuthWP from './views/AuthWP.js';
import FinishProfileWP from './views/FinishProfileWP.js';
import ListSpaceWP from './views/ListSpaceWP.js';
import NotificationsWP from './views/NotificationsWP.js';
import MessagesWP from './views/MessagesWP.js';


import MasterLayoutCP from './components/MasterLayoutCP.js';
import DataGrid from './components/DataGrid';
import NotificationsCP from './views/NotificationsCP.js';
import BookingDetailsCP from './views/BookingDetailsCP.js';
import NotFoundCP from './views/404CP.js';

import MasterLayoutHotel from './components/MasterLayoutHotel.js';
import NotFoundHotel from './views/404Hotel.js';
import HotelBooking from './views/HotelBooking.js';


//import EventStream from './views/EventStream.js';
import EventViewer from './views/EventViewer.js';

import MasterLayoutWeb from './components/MasterLayoutWeb.js';
import MasterLayoutWeb2 from './components/MasterLayoutWeb2.js';

// Import views
//import CPGuestBook from './views/CPGuestBook.js';

import Availabilities from './views/Availabilities.js';
import AvailabilitiesCreateEdit from './views/AvailabilitiesCreateEdit.js';
import BookingDetails from './views/BookingDetails.js';
import BookingDetailsWP from './views/BookingDetailsWP.js';
import BookingDetailsOwnerWP from './views/BookingDetailsOwnerWP.js';
import Bookings from './views/Bookings.js';
import CalendarPage from './views/CalendarPage.js';
import FinishProfile from './views/FinishProfile.js';
import FirstListing from './views/FirstListing.js';
import ForgotPassword from './views/ForgotPassword.js';
import FriendShare from './views/FriendShare.js';
import Help from './views/Help.js';
import HelpWP from './views/HelpWP.js';
import Landing from './views/Landing.js'
import Listings from './views/Listings.js';
import ListingsWP from './views/ListingsWP.js';
import ListSpace from './views/ListSpace.js';
import ListSpaceV3 from './views/ListSpaceV3.js';
import Login from './views/Login.js';
import Messages from './views/Messages.js';
import NotFound from './views/404.js';
import Notifications from './views/Notifications.js';
import PermissionsPush from './views/PermissionsPush.js';
import ResetConfirmation from './views/ResetConfirmation.js';
import ResetPassword from './views/ResetPassword.js';
import Search from './views/Search.js';
import SignUp from './views/SignUp.js';
import SignUpReferral from './views/SignUpReferral.js';
import SpacePictures from './views/SpacePictures.js';
import TellYourFriends from './views/TellYourFriends.js';
import Trips from './views/Trips.js';
import UserProfile from './views/UserProfile.js';
import Wallet from './views/Wallet.js';
import Conversations from './views/Conversations.js';
import LongTermEnquiries from './views/LongTermEnquiries.js';

import StyleGuide from './views/StyleGuide.js';

import {osVersion, osName, deviceType, mobileVendor, mobileModel, browserName, browserVersion, engineName, engineVersion} from 'react-device-detect';
const localDeviceInfo = { device_type:deviceType, os_name:osName, os_version:osVersion, browser_name:browserName, browser_version:browserVersion, engine_name:engineName, engine_version:engineVersion, mobile_vendor:mobileVendor, mobile_model: mobileModel } 


const generateClassName = createGenerateClassName();
const jss = create(jssPreset());

// TODO: pass const WPDomain from APP state

// Import components
const ProtectedRoute = ({ WPDomain, WPMode, setCMS, CMS, user, authChange, forceHideNav, spaceData, saveSpaceData, searchParams, saveSearchParams, component: Component, ...rest }) => {
  // Unauthenticated: store redirect url for use on successful login
  if (!user.authenticated){
    //console.log("setting redirect to:",window.location.pathname);
    localStorage.setItem('redirect', window.location.pathname);
    if (window.location.pathname === 'listspace'){
      return (<Redirect to={`/signup`} />); 
    } else {
      return (<Redirect to={`/login`} />); 
    }
    
  }

  // Otherwise process as normal
  return (
    <Route {...rest} render={(props) => (
      user.profileComplete 
        ? user.profile.pendingReview === undefined ?
          <Component {...props} WPDomain={WPDomain} WPMode={WPMode} setCMS={setCMS} CMS={CMS} user={user} authChange={authChange} forceHideNav={forceHideNav} spaceData={spaceData} saveSpaceData={saveSpaceData} searchParams={searchParams} saveSearchParams={saveSearchParams} /> 
          : <Redirect to={`/bookingdetails/${user.profile.pendingReview}/review`} />
        : <Redirect to='/finishprofile' />
    )} />
  )
}

// Import components
const ProtectedRouteCM = ({ WPDomain, WPMode, setCMS, CMS, user, authChange, forceHideNav, spaceData, saveSpaceData, searchParams, saveSearchParams, component: Component, ...rest }) => {

  
  if (!user.authenticated){
    //console.log("setting redirect to:",window.location.pathname);
    localStorage.setItem('redirect', window.location.pathname);

  }

  return (
    <Route {...rest} render={(props) => (
      user.authenticated
        ? user.profileComplete 
          ? <Component {...props} WPDomain={WPDomain} WPMode={WPMode} setCMS={setCMS} CMS={CMS} user={user} authChange={authChange} forceHideNav={forceHideNav} spaceData={spaceData} saveSpaceData={saveSpaceData} searchParams={searchParams} saveSearchParams={saveSearchParams} /> 
          : <Redirect to='/finishprofile' />
        : <Redirect to='/login' />
    )} />
  )
}



const ProtectedRouteCP_WithCMS = ({WPDomain, WPMode, key, queryId, itemType, setCMS, CMS, user, authChange, component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => (
      user.authenticated
        ? user.profileComplete 
          ? <Component {...props} key={key} WPDomain={WPDomain} WPMode={WPMode} queryId={queryId} itemType={itemType} setCMS={setCMS} CMS={CMS} user={user} authChange={authChange} /> 
          : <Redirect to='/finishprofile' />
        : <Redirect to='/login' />
    )} />
  )
}



function UpdateTracking() {
  //console.log('update gtag', window.location.pathname )
  //window.gtag('config', 'UA-87914265-1', {'page_path': window.location.pathname });
  //window.fbq('track', window.location.pathname);
  return null
}


class App extends Component {
  // Inital here, then on resize listener
  state = {
    browserCSSMode: window.innerWidth >= 960 ? "desktop":"mobile",
    WPDomain: window.WPDomain,
    WPMode: window.WPMode,
    userIsReady:false, user: getApplicationUser(), forceHideNav: false, spaceData: [], CMS:{} 
  }

  componentWillMount = () => {
  
    if (window.location.pathname.substr(0,15) === '/auth_redirect/'){
      // On auth_redirect, clear all history/tokens and sign in with the new token
      let strToken = window.location.pathname.substr(15);
      requestLocalToken({
        username:"redirect_token:"+strToken,
        password:"TOKEN",
      })
      .then(response => {
        //console.log({response});  
        processTokenResponse(response).then(() => {
              //console.log("Yay!")
              //this.authChange();
              //this.history.push('/search')
            window.location='/search?x=abc';  //?monthly
          })
          
      }).catch(error => {
        console.error('Unable to process token', error);
        window.location='/search';  //?monthly

      });

      return;
    }
    // Initialize the user
    this.setState({
      user: initializeApplicationUser(this.userReady)
    })
  }

  componentDidMount() {
    //this.testDamian3();
    //console.log("App:componentDidMount");
    //console.log(this.state);
    let details = {};
    details.debug_text = `${window.location}`;
    details.device = localDeviceInfo;

    if (this.state.user && this.state.user.authenticated){
      sendOscarAnalytics(this.state.user.profile.email, "app.mount", details);
      
    } else {
      sendOscarAnalytics("anon", "app.mount", details);
    }

    
  }


  forceHideNav = (hide) => {
    this.setState({
      forceHideNav: hide,
    })
  }

  userReady = (user) => {
    if (isNativeWebview()) {
      window.ReactNativeWebView.postMessage(JSON.stringify({action: "oscarready"}));
    }
    
    this.setState({userIsReady:true, user })
  }

  handleAuthenticationChange = () => {
    //console.log("handleAuthenticationChange");
    this.setState({
      user: getApplicationUser()
    })
  }

  handleSpaceData = (data) => {
    this.setState({
      spaceData: data
    })
  }
  handleSearchParams = (params) => {
    this.setState({
      searchParams: params
    })
  }

  setCMS = (CMS) => {
    let counter = this.state.CMS.counter || 1;
    CMS.counter = counter+1;
    this.setState({
      CMS
    });

  }

  /*
  componentDidUpdate(prevProps) {
    console.log(prevProps);
    console.log(this.props);
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
  }
*/

/*testDamian3(){
  let that = this;
  setTimeout(() => {
    let tmpDamian3 = that.state.tmpDamian3 || 1;
    tmpDamian3++;
    console.log({tmpDamian3});
    that.setState({tmpDamian3});
    //printListenerCount();
    that.testDamian3();
  }, 1000);
}
*/

  
  render() {
    //return (<h1>APP #{this.state.tmpDamian3}</h1>);
    const {WPDomain, WPMode, userIsReady, user, CMS } = this.state;
    


    if (!userIsReady){
      return (<LoadingModal open={true}/>);
    }

    if (window && window.location){
      let strRoute = window.location.pathname.toLowerCase();

      // && window.location.host == 'localhost:3000'
      // TODO: REMOVE THIS - we have the new Stockland stuff to replace all of this app
      if (!WPDomain && strRoute.substr(0,6)==='/book/'){

        return (
        <BrowserRouter  >
          <MasterLayoutHotel setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} user={this.state.user} forceHideNav={this.state.forceHideNav}>
            <Switch>
              <Route render={(props) => <HotelBooking {...props} user={this.state.user} key="hotelbooking" authChange={this.handleAuthenticationChange} />} />
            </Switch>    
          </MasterLayoutHotel>
        </BrowserRouter>
      );
        

      } else if (strRoute.substr(0,4)==='/cp/'){
        //console.log("isCP");
        // Corporate Portal

        if (!user.profile.is_corpuser){
          return <NotFoundCP/>;
          
        }
        return (
          <BrowserRouter  >
            <MasterLayoutCP setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} user={this.state.user} forceHideNav={this.state.forceHideNav}>
              <Switch>
                <Route exact path="/login" render={(props) => <AuthWP {...props} user={this.state.user} key="login" page="login" authChange={this.handleAuthenticationChange} />} />

                <ProtectedRouteCP_WithCMS path="/cp/wp_bookings" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"wp_bookings"} queryId={"wp_bookings"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/wp_users" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"wp_users"} queryId={"wp_users"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/wp_parkingbays" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"wp_parkingbays"} queryId={"wp_parkingbays"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/wp_buildings" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"wp_buildings"} queryId={"wp_buildings"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>

                <ProtectedRouteCP_WithCMS path="/cp/vbookings" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"cp_vbookings"} queryId={"cp_vbookings"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/vbooking/:reference" WPDomain={WPDomain} WPMode={WPMode} component={BookingDetailsCP} key={"cp_vbookingdedetails"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/vp_visitorbays" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"vp_visitorbays"} queryId={"vp_visitorbays"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>


                <ProtectedRouteCP_WithCMS path="/cp/bookings" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"cp_bookings"} queryId={"cp_bookings"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/buildings" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"cp_buildings"} queryId={"cp_buildings"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/parkingbays" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"cp_parkingbays"} queryId={"cp_parkingbays"} user={this.state.user} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>

                <ProtectedRouteCP_WithCMS path="/cp/notifications" WPDomain={WPDomain} WPMode={WPMode} component={NotificationsCP} key={"cp_notifications"} queryId={"cp_parkingbays"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/booking/:reference" WPDomain={WPDomain} WPMode={WPMode} component={BookingDetailsCP} key={"cp_bookingdedetails"} queryId={"cp_parkingbays"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/building/:reference" WPDomain={WPDomain} WPMode={WPMode} component={EditItem} key={"cp_edit_building"} itemType={"building"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/building_bays/:parentId/:reference" WPDomain={WPDomain} WPMode={WPMode} component={EditItem} key={"cp_edit_new_parkingbay"} itemType={"parkingbay"} isNew={true} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/building_bays/:reference" WPDomain={WPDomain} WPMode={WPMode} component={DataGrid} key={"cp_building"} queryId={"cp_parkingbays"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                <ProtectedRouteCP_WithCMS path="/cp/parkingbay/:reference" WPDomain={WPDomain} WPMode={WPMode} component={EditItem} key={"cp_edit_parkingbay"} itemType={"parkingbay"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>

                <ProtectedRouteCP_WithCMS path="/cp/events/:id" WPDomain={WPDomain} WPMode={WPMode} component={EventViewer} key={"cp_events_view"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>
                {/*<ProtectedRouteCP_WithCMS path="/cp/events" WPDomain={WPDomain} component={EventStream} key={"cp_events"} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange}/>*/}

                <ProtectedRouteCP_WithCMS exact path="/conversations/:conversationId" WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} component={Conversations} key={'cp_view_conversation'}/>
                <ProtectedRouteCP_WithCMS path="/conversations" WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} component={Conversations} key={'cp_all_conversations'}/>

                <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/longtermenquiries/:lteId" component={LongTermEnquiries} key={'view_lte'}/>
                <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} path="/longtermenquiries" component={LongTermEnquiries} key={'all_longtermenquiries'}/>


                <Route component={NotFoundCP} />

              </Switch>
            </MasterLayoutCP>
          </BrowserRouter>
            );

      }
    }


    // Don't allow CorpUsers to see the normal portal
    if (user.profile.is_corpuser){
      //console.log("CORP USER!");
      const homeLink = WPDomain ? "/cp/wp_bookings" : "/cp/bookings";
      window.location=homeLink;
      return <p>Please wait...</p>;
    }



    if (WPDomain && WPMode === 'bays'){
        // White Label Portal ID - Meriton
        //console.log({WPMode});
        // Meriton / Community / Bela etc

        
      let divRoutes = [];
      if (WPDomain === 'meriton'){
        console.log("A");
        divRoutes.push(<Route path="/search/:address/:lat/:lng/:uuid" key="search_deeplink" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} updateUser={this.handleAuthenticationChange} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS}/>} />);
        divRoutes.push(<Route path="/search" key="search" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} updateUser={this.handleAuthenticationChange} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS}/>} />);


      } else {
        console.log("B");
        divRoutes.push(<ProtectedRouteCM path="/search/:address/:lat/:lng/:uuid" key="search_deeplink" WPDomain={WPDomain} WPMode={WPMode} component={Search} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} />);
        divRoutes.push(<ProtectedRouteCM path="/search" key="search" WPDomain={WPDomain} component={Search} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} />);

      }

      return (
        <BrowserRouter>
          <MasterLayoutWP setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} user={this.state.user}>
            <Switch>
              
              {/* fullScreen=true */}
              <Route exact path="/login" render={(props) => <AuthWP {...props} user={this.state.user} key="login" page="login" authChange={this.handleAuthenticationChange} />} />
              {/*<Route exact path="/signup/promo/:promoCode" render={(props) => <SignUp signupPromo={true} {...props} authChange={this.handleAuthenticationChange} />} />*/}
              <Route exact path="/signup" render={(props) => <AuthWP user={this.state.user} key="signup" {...props} page="signup" authChange={this.handleAuthenticationChange}/>} />
              <Route exact path="/forgotpassword" render={(props) => <AuthWP user={this.state.user} key="forgotpassword" {...props} page="forgotpassword" />} />
              <Route exact path="/resetpassword/:resetToken" render={(props) => <AuthWP user={this.state.user} key="resetpassword" {...props} page="resetpassword" />} />
              <Route path="/finishprofile" render={(props) => <FinishProfileWP {...props} updateUser={this.handleAuthenticationChange} />} />

              {/* visibleNav=false */}
              {/*<Route exact path="/referred/:referralCode" render={(props) => <SignUpReferral {...props} forceHideNav={this.forceHideNav} authChange={this.handleAuthenticationChange} />} />*/}
              {/*<Route exact path="/signup/:referralCode" render={(props) => <SignUp {...props} authChange={this.handleAuthenticationChange} />} />*/}
              
              {/*<ProtectedRouteCM path="/search/:address/:lat/:lng/:uuid" key="search_deeplink" WPDomain={WPDomain} WPMode={WPMode} component={Search} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} />*/}
              {/*<ProtectedRouteCM path="/search" key="search" WPDomain={WPDomain} component={Search} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} />*/}
              
              {/*<Route path="/search/:address/:lat/:lng/:uuid" key="search_deeplink" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} updateUser={this.handleAuthenticationChange} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS}/>} />*/}
              {/*<Route path="/search" key="search" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} updateUser={this.handleAuthenticationChange} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS}/>} />*/}
              {divRoutes}


              {/* visibleNav=true */}
              <ProtectedRouteCM WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/messages/:bookingReference/:role" component={MessagesWP} setCMS={this.setCMS} CMS={this.state.CMS}/>
              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} path="/bookings" component={Bookings} />
              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/bookingdetails/:reference" component={BookingDetailsWP}/>

              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/listings/pictures/:uuid" component={SpacePictures} />
              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} path="/listings" component={ListingsWP} />
              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/listspace/:first" component={ListSpaceV3} />
              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} path="/listspace" component={ListSpaceV3} />
              
              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} path="/ownerbookingdetails/:reference" component={BookingDetailsOwnerWP} />

              <ProtectedRouteCM WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} path="/notifications" component={NotificationsWP} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} />

              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} paymentOpen={true} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/wallet/:payment" component={Wallet}/>
              <ProtectedRouteCM WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/wallet" component={Wallet} setCMS={this.setCMS} CMS={this.state.CMS} />

              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/userprofile" component={UserProfile}/>
              <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/permissionspush" component={PermissionsPush}/>

              <Route path="/help" component={HelpWP}/>

              {/*<ProtectedRoute WPDomain={WPDomain} authChange={this.handleAuthenticationChange} user={this.state.user} path="/styleguide" component={StyleGuide} />*/}
            
              <Route component={NotFound} />

            </Switch>
          </MasterLayoutWP>
        </BrowserRouter>
        );
    }

/* 
            <div>
              <div id="webviewDebugConsole"></div>
              { <div style={{backgroundColor: '#acacac', display:'flex', justifyContent:'center'}}> }
              <div>

*/
    // Standard public client app
    // Oscar / CarShare / Stockland
    //console.log("xxx:spaces");
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={OscarTheme}>
          <BrowserRouter  >
              <div>
              <UpdateTracking/>
                <MasterLayoutWeb2 setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} user={this.state.user} forceHideNav={this.state.forceHideNav}>
                  <Switch>
                    {this.state.user.authenticated ? 
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} forceHideNav={this.forceHideNav} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData} searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams} exact path="/" component={Search} /> :
                    <Route exact path="/" render={(props) => <Landing {...props} authChange={this.handleAuthenticationChange} />} />
                    }
                    <Route exact path="/Login" render={(props) => <Login {...props} authChange={this.handleAuthenticationChange} />} />
                    
                    <Route exact path="/SignUp/Promo/:promoCode" render={(props) => <SignUp signupPromo={true} {...props} authChange={this.handleAuthenticationChange} />} />
                    <Route exact path="/Referred/:referralCode" render={(props) => <SignUpReferral {...props} forceHideNav={this.forceHideNav} authChange={this.handleAuthenticationChange} />} />
                    <Route exact path="/SignUp/:referralCode" render={(props) => <SignUp {...props} authChange={this.handleAuthenticationChange} />} />
                    <Route exact path="/SignUp" render={(props) => <SignUp {...props} authChange={this.handleAuthenticationChange} />} />
                    
                    <Route exact path="/ForgotPassword" component={ForgotPassword} />
                    <Route exact path="/ResetConfirmation" component={ResetConfirmation} />
                    <Route exact path="/ResetPassword/:resetToken" component={ResetPassword} />
                    <Route path="/finishprofile" render={(props) => <FinishProfile {...props} updateUser={this.handleAuthenticationChange} />} />
                    
                    {/*window.WPDomain === 'carshare' ?
                    <>
                    {//<ProtectedRouteCM path="/search" key="search" WPDomain={WPDomain} component={Search} authChange={this.handleAuthenticationChange} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} />//}
                    <ProtectedRoute exact path="/Search/:address/:lat/:lng/:startDate/:startTime/:endDate/:endTime/:uuid" component={Search} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams} />
                    <ProtectedRoute exact path="/Search/:address/:lat/:lng/:startDate/:startTime/:endDate/:endTime/" component={Search} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams} />
                    <ProtectedRoute exact path="/Search/:address/:lat/:lng/:uuid" component={Search} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams} />
                    <ProtectedRoute exact path="/Search/:address/:lat/:lng/" component={Search} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams} />
                    <ProtectedRoute path="/Search" component={Search} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams} />

                    </>
                    :
                    <>
                    <Route exact path="/Search/:address/:lat/:lng/:startDate/:startTime/:endDate/:endTime/:uuid" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route exact path="/Search/:address/:lat/:lng/:startDate/:startTime/:endDate/:endTime/" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route exact path="/Search/:address/:lat/:lng/:uuid" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route exact path="/Search/:address/:lat/:lng/" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route path="/Search" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData} searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    </>
                    */}

                    <Route exact path="/Search/:address/:lat/:lng/:startDate/:startTime/:endDate/:endTime/:uuid" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route exact path="/Search/:address/:lat/:lng/:startDate/:startTime/:endDate/:endTime/" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route exact path="/Search/:address/:lat/:lng/:uuid" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route exact path="/Search/:address/:lat/:lng/" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData}  searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />
                    <Route path="/Search" render={(props) => <Search {...props} WPDomain={WPDomain} WPMode={WPMode} forceHideNav={this.forceHideNav} user={this.state.user} setCMS={this.setCMS} CMS={this.state.CMS} authChange={this.handleAuthenticationChange} spaceData={this.state.spaceData} saveSpaceData={this.handleSpaceData} searchParams={this.state.searchParams} saveSearchParams={this.handleSearchParams}/>} />


                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/Availabilities/:uuid" component={Availabilities} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/ChangeAvailabilities/:parkingSpace/:availability" component={AvailabilitiesCreateEdit} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/ChangeAvailabilities/:parkingSpace" component={AvailabilitiesCreateEdit} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/Listings/Pictures/:uuid" component={SpacePictures} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} path="/Listings" component={Listings} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/ListSpace/:first" component={ListSpaceV3} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} path="/ListSpace" component={ListSpaceV3} />
                    <ProtectedRouteCM WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/messages/:bookingReference/:role" component={Messages} setCMS={this.setCMS} CMS={this.state.CMS}/>
                    {/*<ProtectedRoute user={this.state.user} exact path="/Messages/:bookingReference/:role" component={Messages} />*/}
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} path="/Trips" component={Trips} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} path="/Bookings" component={Bookings}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} path="/Notifications" component={Notifications} />
                    <Route exact path="/BookingDetails/:reference/:review" render={(props) => <BookingDetails {...props} WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user}  />} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/BookingDetails/:reference" component={BookingDetails}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/FirstListing" component={FirstListing}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/PermissionsPush" component={PermissionsPush}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/Calendar/:bookingReference" component={CalendarPage}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} exact path="/Calendar" component={CalendarPage}/>

                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/conversations/:conversationId" component={Conversations} key={'view_conversation'}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} path="/conversations" component={Conversations} key={'all_conversations'}/>

                   
                    
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} paymentOpen={true} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/Wallet/:payment" component={Wallet}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/Wallet" component={Wallet}/>
                    
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} user={this.state.user} exact path="/UserProfile" component={UserProfile}/>
                    
                    <Route path="/Help" component={Help}/>
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} path="/TellYourFriends/Share" component={FriendShare} />
                    <ProtectedRoute WPDomain={WPDomain} WPMode={WPMode} user={this.state.user} path="/TellYourFriends" component={TellYourFriends} />

                    <Route path="/WebSignUp" render={(props) => <Landing {...props} forceShow WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} />} />

                    {!this.state.user.authenticated && 
                    <Route path="/spot-owner" render={(props) => <Landing {...props} forceShow WPDomain={WPDomain} WPMode={WPMode} authChange={this.handleAuthenticationChange} customRedirect="/ListSpace" />} />
                    }                    
                    <Route component={NotFound} />
                  </Switch>
                </MasterLayoutWeb2>
                </div>
          </BrowserRouter>
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}



export default App;
