import React, { Component } from "react";
import { Link } from "react-router-dom";

// Utils
import {
  requestLocalToken,
  processTokenResponse,
  processTokenRequest,
  updateUserProfileAjax,
  exchangeOAuthToken,
  logOut,
  getUserProfile
} from "../utils/Authentication";
import { checkUserNotificationPermissions } from "../utils/PushNotification.js";

// Components
import CRUDForm from "../components/CRUDForm";
import LoadingModal from "../components/LoadingModal.js";
//import FacebookOAuthButton from "../components/OauthFacebook.js";
//import GoogleOAuthButton from "../components/OauthGoogle.js";

// Styles & UI Components
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
  Fab
} from "@material-ui/core";
import { whiteInputStyles } from "../styles/OscarTheme.js";
import IconBack from "@material-ui/icons/KeyboardArrowLeft";
import IconClear from "@material-ui/icons/Cancel";
import IconForward from "@material-ui/icons/KeyboardArrowRight";
import { red } from "@material-ui/core/colors";

const styles = {
  noAccount: {
    borderRadius: "32px",
    color: "white",
    border: "2px solid white"
  },
  rightCornerButton: {
    position: "absolute",
    top: 10,
    right: 5,
    color: "white"
  },
  progressButton: {
    backgroundColor: "white",
    boxShadow: "none"
  },
  oauthButton: {
    backgroundColor: "white",
    paddingTop: "14px",
    paddingBottom: "14px",
    borderRadius: "32px",
    color: "#1AA5A2"
  },
  signupButton: {
    marginTop: "16px",
    marginLeft: "16px",
    marginRight: "16px",
    fontWeight: "bold"
  }
};

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = value;
  });
  return vars;
}

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      logs: []
    };

    this.changeLoadingStatus = this.changeLoadingStatus.bind(this);
  }

  componentDidMount() {
    /*if (getUrlVars().code) {
      let token = getUrlVars().code;
      processTokenRequest(
        exchangeOAuthToken(token, "facebook"),
        this.authSuccess,
        this.authError
      );
    }*/
  }

  changeLoadingStatus = status => {
    let logs = this.state.logs;
    logs.push("S: " + status);
    this.setState({
      logs: logs
    });
  };

  onSuccess = response => {
    this.setState({ loading: true });
    
    processTokenResponse(response).then(() => {

      updateUserProfileAjax().then(() => {

        //this.props.authChange();
        
        let userData = getUserProfile();
        if (userData && userData.is_corpuser){
          if (window.WPDomain){
            window.location = "/cp/wp_bookings";
          } else {
            window.location = "/cp/bookings";
          }
          

        } else {
          this.props.authChange();

          let redirect_path = localStorage.getItem("redirect");
          if (redirect_path){
            //localStorage.removeItem("redirect");
          } else {
            redirect_path = "/search";
          }

          if (checkUserNotificationPermissions()) {

            // Only ask TWICE - then stop asking the client
            let pcounter = parseInt(localStorage.getItem("USER_NOTIFICATION_PERMISSIONS_REQUESTED_COUNTER") || "0");
            if (pcounter < 2){
              pcounter++;
              localStorage.setItem("USER_NOTIFICATION_PERMISSIONS_REQUESTED_COUNTER",pcounter);
  
              this.props.history.push("/PermissionsPush");    //DAMIAN - ask Louise - only first time etc?
            } else {
              localStorage.removeItem("redirect");
              this.props.history.push(redirect_path);  
              
            }
            
          } else {
            localStorage.removeItem("redirect");
            this.props.history.push(redirect_path);
          }

        }
        

        //this.setState({ loading: false });  //Removed
      });
    });
  };

  /*authSuccess = () => {
    // We successfully authenticated, so return to the landing page.
    this.props.authChange();
    this.setState({ loading: false });

    const redirect_path = localStorage.getItem("redirect");
    if (redirect_path){
      localStorage.removeItem("redirect");
      this.props.history.push(redirect_path);
      
    } else {
      this.props.history.push("/search");
    }

  };*/
  authError = error => {
    // TODO: validation messages for errors
    console.log(error);
    let logs = this.state.logs;
    logs.push("E: " + error);
    this.setState({ loading: false, logs: logs });
  };

  updateFields = () => {
    const vals = this._mainForm.getState().values;

    const form1 = vals["username"] ? vals["username"].length > 0 : false;
    const form2 = vals["password"] ? vals["password"].length > 0 : false;
    this.setState({ form1: form1, form2: form2 });
  };

  render() {
    const { classes } = this.props;

    return (
      <div
        className="gradientPage"
        style={{ height: "100%", overflowY: "auto" }}
      >
        <LoadingModal open={this.state.loading} color="white" />
        <div className="mainContent topPadded">
          <IconButton
            className="leftCornerButton"
            style={{ color: "white" }}
            onClick={() => {
              logOut();
              this.props.authChange();
              this.props.history.goBack();
            }}
          >
            <IconBack />
          </IconButton>
          <Button
            style={styles.rightCornerButton}
            component={Link}
            to="/ForgotPassword"
          >
            Forgot Password
          </Button>
          <Typography
            style={{ color: "white" }}
            color="primary"
            variant="display1"
          >
            Log in
          </Typography>
          <CRUDForm
             submitOnEnter={true}
            endpoint={requestLocalToken}
            onSuccess={this.onSuccess}
            loadColor="white"
            ref={component => {
              this._mainForm = component;
            }}
            onChange={this.updateFields}
          >
            <Grid container spacing={24} justify="center">
              <Grid item xs={12}>
                <TextField
                  label="Email Address"
                  name="username"
                  type="email"
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                      error: classes.helperTextError
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.input,
                      error: classes.inputError
                    },
                    endAdornment: this.state.form1 && (
                      <InputAdornment position="end">
                        <IconClear
                          onClick={() =>
                            this._mainForm.handleInputChange({
                              target: { name: "username", value: "" }
                            })
                          }
                        />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                    FormLabelClasses: {
                      root: classes.formLabelRoot,
                      error: classes.formLabelError,
                      focused: classes.formLabelFocused
                    },
                    shrink: true
                  }}
                  placeholder="Your email address"
                  fullWidth
                  margin="normal"
                  value={this.state.username}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  name="password"
                  type="password"
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText,
                      error: classes.helperTextError
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.input,
                      error: classes.inputError
                    },
                    endAdornment: this.state.form2 && (
                      <InputAdornment position="end">
                        <IconClear
                          onClick={() =>
                            this._mainForm.handleInputChange({
                              target: { name: "password", value: "" }
                            })
                          }
                        />
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    classes: { root: classes.label },
                    FormLabelClasses: {
                      root: classes.formLabelRoot,
                      error: classes.formLabelError,
                      focused: classes.formLabelFocused
                    },
                    shrink: true
                  }}
                  placeholder="Your password here"
                  fullWidth
                  margin="normal"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                />
              </Grid>

              {/*
              <Grid item xs={12} style={{ margin: "12px" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: 1 }}>
                    <hr
                      style={{
                        border: "0.25px solid rgba(255, 255, 255, 0.15)"
                      }}
                    />
                  </div>
                  <div>
                    <Typography
                      variant="body2"
                      style={{ margin: "-3px 10px", color: "white" }}
                    >
                      or
                    </Typography>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <hr
                      style={{
                        border: "0.25px solid rgba(255, 255, 255, 0.15)"
                      }}
                    />
                  </div>
                </div>
              </Grid>
              
              <Grid xs={12}>
                <div
                  id="oauth_facebook"
                  style={styles.signupButton}
                  onClick={() => this.setState({ loading: true })}
                >
                  <FacebookOAuthButton
                    authSuccess={this.authSuccess}
                    authError={this.authError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    prompt="Log in with Facebook"
                  />
                </div>
              </Grid>

              <Grid xs={12}>
                <div
                  id="oauth_google"
                  style={styles.signupButton}
                  onClick={() => this.setState({ loading: true })}
                >
                  <GoogleOAuthButton
                    authSuccess={this.authSuccess}
                    authError={this.authError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    prompt="Log in with Google"
                  />
                </div>
              </Grid>
              */}

              <Grid item xs={12} style={{ marginTop: "32px" }}>
                <Grid container justify="flex-end" alignItems="center">
                  <Grid item>
                    <Button
                      style={styles.progressButton}
                      variant="fab"
                      type="submit"
                    >
                      <IconForward color="primary" />
                    </Button>
                    {/* <p>1.9.7</p> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CRUDForm>
          {/*
          {this.state.logs ? (
            <div style={{ width: "100%", border: "1px solid black" }}>
              {this.state.logs.map(log => {
                return <p>{log}</p>;
              })}
            </div>
          ) : (
            <p>Error</p>
          )}
          */}
        </div>
      </div>
    );
  }
}

export default withStyles(whiteInputStyles)(Login);
