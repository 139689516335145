import React, {Component} from "react";
import {withRouter} from 'react-router-dom';

import moment from 'moment';

import { compose, withProps } from "recompose";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";

import FullPageModal from '../utils/FullPageModal.js';
//import LoadingModal from '../components/LoadingModal.js';
import SpaceDetails from '../views/SpaceDetails.js';
import SpaceDetailsWP from '../views/SpaceDetailsWP.js';
import BookingCheckoutPage from '../views/BookingCheckoutPage.js';
import ParkingSearch2 from '../components/ParkingSearch2.js';
import ParkingSearchWP from '../components/ParkingSearchWP.js';

import ENUMS from '../api/ENUMS.json'
import { sendOscarAnalytics, getLocationSpacesClosest, getParkingSearch, getGeoSearchBeanstalk } from '../api/Oscar-api.js';


import { QueryParamDate, QueryParamTime } from '../utils/TimeFormats.js';
import {GetStreetView} from '../utils/StreetView.js';
import MapFader from "./MapFader";

import {
  Grid,
  Paper, 
  Typography,
  IconButton
} from "@material-ui/core";

import IconWalk from '@material-ui/icons/DirectionsWalk'
import IconClose from "@material-ui/icons/Close"
import IconBack from '@material-ui/icons/ArrowBackIos';

import { RubikWoff2 } from '../utils/Base64Rubik.js';

import {getSpaceType, getSpaceData2, getSpaceBuilding, getSpaceCompany, getSpaceUser} from '../utils/GetSpaceDetails.js';
import {getImagePreviewForSpace, getImagePreviewForBuilding} from '../utils/GetImageListForSpace.js';
import calculatePrice from "../utils/CalculatePrice.js";
//import { ContactSupportOutlined } from "@material-ui/icons";

const THROTTLE_MAPMOVED = 100;
const THROTTLE_MAPZOOMOUT = 750;


// Search in progress - Desktop
const searchInProgressModal_Desktop = (
  <div style={{width:'50vw',height:'10vh',zIndex:1300,backgroundColor:'#0000ff77',position:'fixed',left:'20px',bottom:'20px',opacity:'1.0',textAlign:'center'}}>
    <h1 style={{color:'#ffffff',fontSize:'24px',margin:0}}>Searching Desktop...</h1>
  </div>);

// Search in progress - Mobile
const searchInProgressModal_Mobile = (
  <div style={{width:'50vw',height:'10vh',zIndex:1300,backgroundColor:'#0000ff77',position:'fixed',left:'20px',bottom:'20px',opacity:'1.0',textAlign:'center'}}>
    <h1 style={{color:'#ffffff',fontSize:'24px',margin:0}}>Searching Mobile...</h1>
  </div>);


function getDistanceDisplayString(distanceInKms){
  if (distanceInKms < 1){
    return Math.round(distanceInKms * 1000) + ' m';
  } else {
    let totalKms = Math.floor(distanceInKms * 100) / 100.0;
    if (totalKms === 1){
      return (totalKms + ' km');
    } else {
      return (totalKms + ' kms');
    }
    
  }
}

function roundToNearestFiveMinutes(duration) {
  const remainder = duration % 5
  if (remainder < 2.5) {
    return duration - remainder
  } else {
    return duration - remainder + 5
  }
}



function isDuplicateBay(thisBay,prevBay){
  if (!prevBay){
    //console.log("no prevbay, collapse=false");
    return false;
  }
  
  // Internal only
  if (thisBay.bay_group !== 1){
    //console.log("tenant, collapse=false");
    return false;
  }
  // If first internal (after tenant etc) don't collapse
  if (thisBay.bay_group !== prevBay.bay_group){
    return false;
  }


  // If previous bay is booked, ignore it and don't collapse
  if (!prevBay.isAvailable){
    //console.log("unavailable, collapse=false");
    return false;
  }
  

  // If previous bay has upcoming bookings, ignore it and don't collapse
  if (prevBay.future_bookings && prevBay.future_bookings.length > 0){
    //console.log("future booking, collapse=false");
    return false;
  }
  // If this bay has upcoming bookings, don't collapse it
  if (thisBay.active_bookings && thisBay.active_bookings.length > 0){
    //console.log("active booking, collapse=false");
    return false;
  }


  if (thisBay.building.uuid !== prevBay.building.uuid){
    //console.log("building doesn't match, collapse=false");
    return false;
  }
  if (thisBay.rate_monthly !== prevBay.rate_monthly){
    //console.log("rate_monthly doesn't match, collapse=false");
    return false;
  }
  if (thisBay.availability_type !== prevBay.availability_type){
    //console.log("availbility_type doesn't match, collapse=false");
    return false;
  }
  if (thisBay.vehicle_type !== prevBay.vehicle_type){
    //console.log("vehicle_type doesn't match, collapse=false");
    return false;
  }


  return true;
}



// ==================================================================
// Maps
// ==================================================================
//var siteMapStyle;
const mapStyles = require("../styles/oscarMapStyles.json");
//const mapStyles_Meriton = require("../styles/oscarMapStyles_Meriton.json");



// For testing - will switch to red color scheme if using OscarSearch as the data source
const isOscarSearch = false;

const COLOR_AVAILABLE_SELECTED = isOscarSearch ? '#ff7700':'#0a4a49';
const COLOR_AVAILABLE = isOscarSearch ? '#ff0000':'#1aa5a2';
const COLOR_UNAVILABLE = isOscarSearch ? '#770000':'#ababab';
const COLOR_UNAVAILABLE_SELECTED = '#5b5b5b';
function getColorForState(parkingSpace, selected) {
  if(parkingSpace.available === 1 && parkingSpace.occupied === 0) {
    if (parkingSpace.uuid === selected) {
      return COLOR_AVAILABLE_SELECTED;
    }
    return COLOR_AVAILABLE;

  } else {
    if (parkingSpace.uuid === selected) {
      return COLOR_UNAVAILABLE_SELECTED;
    }
    return COLOR_UNAVILABLE;
  }
}

const HEIGHT_SELECTED = 41;
const HEIGHT_DEFAULT = 33;

const icon_oscar_casual = `
<svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 40" width="100" height="{{HEIGHT}}px">
	<defs>
    <style type="text/css">
    @font-face {
      font-family: 'rubikregular';
      src: url(data:application/font-woff2;charset=utf-8;base64,${RubikWoff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
    }
    text {font-weight: 700; font-size:18px;font-family: 'rubikregular';}
    tspan {font-weight: 500; font-size:12px;}
    tspan.dollar {font-weight:500; font-size:14px;}
    .b{fill:{{COLOR}};fill-rule:evenodd;}
  </style>

    </defs>
	<path class="b" d="M94.73,0H3.27C1.46,0,0,1.34,0,2.99V28.86c0,1.65,1.46,2.99,3.27,2.99H42.96l6.04,8.15,6.04-8.15h39.7c1.8,0,3.27-1.34,3.27-2.99V2.99c0-1.65-1.46-2.99-3.27-2.99Z"/>
  <text id="pricing" x="50" y="21" fill="#ffffff" style="letter-spacing:0.05em;" text-anchor="middle">{{strDisplay}}</text>
</svg>
`;


const icon_oscar_monthly_autoApprove = `
<svg version="1.1" id="a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100" height="{{HEIGHT}}px"
	 viewBox="0 0 98 40" style="enable-background:new 0 0 98 40;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:{{COLOR}};}
	.st1{fill:#FEE100;}


  @font-face {
    font-family: 'rubikregular';
    src: url(data:application/font-woff2;charset=utf-8;base64,${RubikWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  text {font-weight: 700; font-size:18px;font-family: 'rubikregular';}
  tspan {font-weight: 500; font-size:12px;}
  tspan.dollar {font-weight:500; font-size:14px;}  
</style>
<path class="st0" d="M94.7,0H3.3C1.5,0,0,1.3,0,3v25.9c0,1.6,1.5,3,3.3,3H43l6,8.1l6-8.1h39.7c1.8,0,3.3-1.3,3.3-3V3
	C98,1.3,96.6,0,94.7,0L94.7,0z"/>
  <g id="b_00000170263604635663557040000011717798453101030017_" transform="translate(9.000000, 6.000000)">
	<path id="c_00000183936592981810319820000013834340649270618504_" class="st1" d="M6.7,7.5H2.6V0.1c0-0.3-0.3-0.3-0.5-0.1
		l-4.9,11.5c0,0.1,0,0.1,0,0.3s0.1,0.1,0.3,0.1h4.1v7.4c0,0.3,0.3,0.3,0.5,0.1L6.9,7.8c0-0.1,0-0.1,0-0.3C6.9,7.5,6.8,7.5,6.7,7.5z"
		/>
</g>

<text id="pricing" x="56" y="21" fill="#ffffff" style="letter-spacing:0.05em;" text-anchor="middle">{{strDisplay}}</text>
</svg>`


function getPriceIcon(space, selected, searchMode, hover, duration, start) {
  let color = getColorForState(space, selected);
  let height = space.uuid === selected ? HEIGHT_SELECTED : HEIGHT_DEFAULT;

  let strDisplay = `<tspan class="dollar">$</tspan>${space.mapPrice.price}`;
  if (space.mapPrice.per){
    strDisplay+=`<tspan>${space.mapPrice.per}</tspan>`;
  }

  let strIcon;
  if (searchMode == 1){
    // Monthly
    if (space.auto_approve){
      strIcon = icon_oscar_monthly_autoApprove.replace('{{strDisplay}}',strDisplay);
      height*=1.2;
      
    } else {
      strIcon = icon_oscar_casual.replace('{{strDisplay}}',strDisplay);
      height*=1.2;
  
    }

  } else {
    // Casual
    strIcon = icon_oscar_casual.replace('{{strDisplay}}',strDisplay);
    height*=1.2;

  }
  

  /*if (hover && space.uuid !== selected){
    color = "#ff9900";
  }*/
  /*if (hover){
    color = "#ff0000"; // "#ba925a"; // Meriton Gold "#009900";
  }*/
    
  strIcon = strIcon.replace('{{HEIGHT}}',height);
  strIcon = strIcon.replace('{{COLOR}}',color);
    
  const svgString = encodeURIComponent(strIcon);
  return `data:image/svg+xml,${svgString}`;
}





const baseIconWP = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 61" height="100px" width="{{WIDTH}}">
  <style type="text/css">
  @font-face {
    font-family: 'rubikregular';
    src: url(data:application/font-woff2;charset=utf-8;base64,${RubikWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  text {font-weight: 700; font-size:16px;font-family: 'rubikregular';}
  tspan {font-weight: 500; font-size:12px;letter-spacing:0.0015em;}
  tspan.dollar {font-weight:500; font-size:14px;}

  </style>

	<path clip-rule="evenodd" fill="none" d="M0 0h86v61H0z"/>
	<path d="M4 1h78c1.1 0 2 .9 2 2v47c0 1.1-.9 2-2 2H48.2s-1.6 2.2-4.7 6.6L39 52H4c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2z" fill-rule="evenodd" clip-rule="evenodd" fill="#fff" stroke="{{COLOR}}"/>
	<path d="M81.9 52.5H48.2l-4.7 6.1-4.5-6.1H4.1c-1.2 0-2.1-1-2.1-2.1V3.1C2 2 3 1 4.1 1h77.7C83 1 84 2 84 3.1v47.2c0 1.2-1 2.2-2.1 2.2z" clip-rule="evenodd" fill="none" stroke="{{COLOR}}" stroke-miterlimit="10"/>
	<path d="M2.2 23.4v26.5c0 1.3 1 2.3 2.1 2.3h34.9l4.5 6.5 4.7-6.5h33.7c1.2 0 2.1-1 2.1-2.3V23.4h-82z" fill-rule="evenodd" clip-rule="evenodd" fill="{{COLOR}}"/>
  <text id="pricing" x="43" y="18" fill="{{COLOR}}" style="letter-spacing:0.015em;" text-anchor="middle">{{TOTALBAYS}}</text>
  <text id="pricing" x="43" y="43" fill="#ffffff" style="letter-spacing:0.05em;" text-anchor="middle">{{PRICE}}</text>
</svg>
`;


function getPriceIconWP(WPDomain, space, selected, hover, duration, start, strTotalBays) {
  //let color = getColorForState(space, selected);

  let strDisplay = `<tspan class="dollar">$</tspan>${space.mapPrice.price}`;
  if (space.mapPrice.per){
    strDisplay+=`<tspan>${space.mapPrice.per}</tspan>`;
  }
  // Temp - maybe diffent icon later
  if (space.mapPrice.price === 'FULL'){
    strDisplay = '';
  }
  
  let color;
  let hoverColor;
  let colorFull;
  let hoverColorFull;
  let width = "100px";

  const COLOR_AVAILABLE_SELECTED = isOscarSearch ? '#ff7700':'#0a4a49';
const COLOR_AVAILABLE = isOscarSearch ? '#ff0000':'#1aa5a2';
const COLOR_UNAVILABLE = isOscarSearch ? '#770000':'#ababab';
const COLOR_UNAVAILABLE_SELECTED = '#5b5b5b';


  if (WPDomain === 'community'){
    color = "#1aa5a2"; // Community (Oscar Teal)
    hoverColor = "#0a4a49";
    colorFull = "#ababab";
    hoverColorFull = "#5b5b5b";
  } else {
    color = "#0e3b60"; // Meriton Blue
    hoverColor = "#af864e";
    colorFull = "#999999";
    hoverColorFull = hoverColor;
  }

  if (space.totalBays === 0){
    color = colorFull;
    hoverColor = hoverColorFull;
  }

  if (hover){
    width = "110px";
    color = hoverColor; // "#ba925a"; // Meriton Gold "#009900";
  }


  let baseIcon = baseIconWP;
  baseIcon = baseIcon.replace(/{{COLOR}}/g,color);
  baseIcon = baseIcon.replace("{{WIDTH}}",width);
  baseIcon = baseIcon.replace("{{PRICE}}",strDisplay);
  baseIcon = baseIcon.replace("{{TOTALBAYS}}",`${strTotalBays}`);
  
  const svgString = encodeURIComponent(baseIcon);
  return `data:image/svg+xml,${svgString}`;
}





const MyMapComponent = compose(
  withProps({
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) =>
  <GoogleMap
    //defaultZoom={10}
    //defaultCenter={{ lat: -27.4697707, lng: 153.0251235 }}
    defaultOptions={{
      styles: mapStyles,
      scaleControl: false,
      mapTypeControl: false,
      panControl: false,
      zoomControl: true,
      rotateControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      
    }}
    center={props.newCenter ? props.newCenter : props.center}
    zoom={props.zoom}
    ref={props.collectMapRef}
    onIdle = {props.onIdle}
    
    //onBoundsChanged={props.handleMapMoved}
    onDragEnd={props.handleMapMoved}
    onZoomChanged={props.handleZoomChanged}
    disableDefaultUI
    start={props.start}
  >
    {props.WPMode === 'spaces' && <Marker position={props.center} zIndex={10} icon="https://www.sharewithoscar.com.au/assets/graphics/google_map_destination_pin.svg"/>}

    {/*<MarkerClusterer
      //onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={10}
    >*/}
  

    {true && props.data && props.data.map(space => {
      //space.available === 1 && space.occupied === 0 ? 5 : 1
      let zIndex = 1;
      if (space.available ==1 && space.occupied === 0){
        zIndex = 5;
      }
      if (space.uuid === props.activeUuid){
        zIndex=20;
      }
      //console.log(props.hoverUuid);
      if (props.hoverUuid === space.uuid){
        zIndex = 21;
      }


    
      if (props.WPMode === 'bays'){
        if (space.mapPrice.price === 'FULL'){
          zIndex = 1;
        } else {
          zIndex = 2;
        }

        return(
          <Marker
            zIndex={zIndex}
            key={space.uuid}
            position={space.mapPosition}  //{{ lat: space.latitude, lng: space.longitude }} 
            onClick={() => props.onMarkerClick(space.uuid)}
            //onMouseOver={() => props.onMarkerHover(space.uuid)}
            //onMouseOut={() => props.onMarkerHover(null)}
            icon={getPriceIconWP(props.WPDomain, space, props.selected, props.hoverUuid === space.uuid, props.duration, props.start,space.strTotalBays)}
          />
        );

      } else {
        
        return(
          <Marker
            //???noRedraw={true}  
            //animation={window.google.maps.Animation.DROP} // DROP}
            zIndex={zIndex}
            key={space.uuid}
            position={space.mapPosition}  //{{ lat: space.latitude, lng: space.longitude }} 
            onClick={() => props.onMarkerClick(space.uuid)}
            //onMouseOver={props.onMarkerHover(space.uuid)}
            //onMouseOver={() => props.onMarkerHover(space.uuid)}
            //onMouseOut={() => props.onMarkerHover(null)}
            icon={getPriceIcon(space, props.selected, props.searchMode, props.hoverUuid === space.uuid, props.duration, props.start)}
          />
        );
        }

    })}
    {/*</MarkerClusterer>*/}
  </GoogleMap>
);


// function estimateDistance(lat1, lng1, lat2, lng2) {
//   const degLen = 110.25
//   const x = lat1 - lat2
//   const y = (lng1 - lng2) * Math.cos(lat1)
//   return degLen * Math.sqrt(x*x, y*y)
// }

function estimateDistance(lat1, lon1, lat2, lon2, unit) {
	var radlat1 = Math.PI * lat1/180
	var radlat2 = Math.PI * lat2/180
	var theta = lon1-lon2
	var radtheta = Math.PI * theta/180
	var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	if (dist > 1) {
		dist = 1;
	}
	dist = Math.acos(dist)
	dist = dist * 180/Math.PI
	dist = dist * 60 * 1.1515
	if (unit==="K") { dist = dist * 1.609344 }
	if (unit==="N") { dist = dist * 0.8684 }
	return dist
}

/*
function getWalkingDistances(spaces, center) {
  const origin = new window.google.maps.LatLng(center.lat, center.lng);
  const destinations = spaces.map(space => { return new window.google.maps.LatLng(space.latitude, space.longitude)})

  var service = new window.google.maps.DistanceMatrixService();

  return new Promise(function(resolve, reject) {
    if (true) {
      resolve([])
    } else {
      reject();
    }
  })
  // return new Promise(function(resolve, reject){
  //   service.getDistanceMatrix({
  //     origins: [origin],
  //     destinations: destinations,
  //     travelMode: 'WALKING',
  //   }, callback);
    
  //   function callback(response, status) {
  //     if (status === "OK") {
  //       resolve(response.rows[0].elements)
  //     } else {
  //       reject(status) 
  //     }
  //   }
  // })
}*/

function inBounds(space, bounds){
  const lat0 = bounds.getNorthEast().lat();
  const lng0 = bounds.getNorthEast().lng();
  const lat1 = bounds.getSouthWest().lat();
  const lng1 = bounds.getSouthWest().lng();

  const latMax = Math.max(lat0, lat1)
  const latMin = Math.min(lat0, lat1)
  const lngMax = Math.max(lng0, lng1)
  const lngMin = Math.min(lng0, lng1)

  if (latMin < space.latitude && space.latitude < latMax) {
    if (lngMin < space.longitude && space.longitude < lngMax) {
      return true
    }
  }

  return false
}
let styles = {
  rightTopButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  previewContainer: {
    width: '100%',
    position: 'absolute',
    //bottom: '50px',
  },
  parkingPreview: {
    margin: '24px',
    padding: '12px',// 20px',
    paddingRight: '24px',
    cursor:'pointer'
  },
  previewImageContainer: {
    overflow: 'hidden',
    padding: '0px',
    paddingTop: '10px',
    //marginBottom:'5px',
    textAlign: 'right',
  },
  desktopPopover: {
    position:'absolute',
    width: '380px',
    zIndex: '1'
  },
  previewImage: {
    width: '120px', //'auto',
    height: '100px', //'102px',
    backgroundColor:'#d8d8d8',
    

  },
  previewText: {
    height: '82px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '5px',
  },
  distanceIcon: {
    fontSize: '20px',
    marginBottom: '-5px',
    marginLeft: '-5px',
    marginRight: '2px',
  },
  pricePreview: {
    fontWeight: 'bold',
    marginTop: '2px',
  }
}

const vehicleSizeMap = {"Bike": 0, "Hatchback": 1, "Sedan": 2, "4WD/SUV": 3, "SUV": 3, "Van": 4}

function ParkingSpacePreview(props) {
  const spaceData = props.spaceData;
  let spaceType = getSpaceType(spaceData);

  let image = getImagePreviewForSpace(spaceData, null);
  if (!image){
    let imagePath = 'https://images.sharewithoscar.com.au/assets/graphics/no-photo.png'; // cp-logos/no-logo-2.png';

    imagePath = GetStreetView(spaceData.latitude, spaceData.longitude, 120, 100);
    image = <img style={styles.previewImage} key={spaceData.uuid} src={imagePath} alt="The parking space"/>;
    //style={{backgroundColor:'#d8d8d8',padding:'100px'}}
    //image = (<div style={styles.previewImage} key={spaceData.uuid}><img src={imagePath}/></div>);
  }

  let strSpaceType = props.spaceData.type.replace('Parking-lot','Parking Lot');

  const pricing = spaceData.pricing.output.display_details;
  ///divMapPrice = <div className="rate">${pricing.price}<span>{pricing.per}</span>{divThumbs}</div>;
  ///divMapPriceMobile = <div className="rate">${pricing.price}<span>{pricing.per}</span>{divThumbs}</div>;

  let strPrice = `$${pricing.price}${pricing.per}`;
  /*{props.spaceData.rate_monthly ? `$${props.spaceData.rate_monthly.toFixed(0)}/mth`:null}
  {(props.spaceData.rate_daily && !props.spaceData.rate_monthly) ? `$${props.spaceData.rate_daily.toFixed(2)}/day`:null}
  {!props.spaceData.rate_monthly && !props.spaceData.rate_daily ? `$${props.spaceData.rate_hourly.toFixed(2)}/hr`:null}*/


  return(
    <Paper style={styles.parkingPreview} >
      <Grid container spacing={16}>
        <Grid item xs={8}>
          <div style={styles.previewText}>
            <Typography gutterBottom variant="body1" >
              <span className="mainColorHighlight">{strSpaceType}</span> on {props.spaceData.street_name}
            </Typography>
            <Typography variant="caption">
            <div dangerouslySetInnerHTML={{__html: props.spaceData.description}}/>
            </Typography>
          </div>
          <Grid container justify="space-between">
            <Grid item>
              {(props.spaceData.strDistanceToCenter !== undefined) &&
              <Typography variant="caption">
                <IconWalk style={styles.distanceIcon} color="primary" />
                {props.spaceData.strDistanceToCenter}
              </Typography>
              }
            </Grid>
            <Grid item>
              <Typography variant="caption" color="primary" style={styles.pricePreview}>
                {strPrice}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} style={styles.previewImageContainer}>
          {image}
        </Grid>
      </Grid>
    </Paper>
  )
}



function printListenerCount() {
  /*var eles = document.getElementsByTagName("*");
  var numListeners = 0;
  for (idx in eles) { let listeners = getEventListeners(eles[idx]);
      for(eIdx in listeners)
      {
          numListeners += listeners[eIdx].length;
      }
      console.log("ele", eles[idx], "listeners", getEventListeners(eles[idx]));
  }
  console.log("numListeners", numListeners)*/
}

//===========================================================================
//===========================================================================
// ParkingMap component
//===========================================================================
//===========================================================================
export default class ParkingMap extends Component {

  constructor(props) {
    super(props);
    //console.log("ParkingMap:Constructor");

    //console.log({props});
    this.state = {
      timeTaken:null,
      activeUuid: undefined,
      data: undefined,
      loading: false,
      searchInProgress: false,
      firstProps: true,
      showBayList: false,
      showDetails: false,
      showConfirmation: false,
      vehicle: 1,
      zoom: this.props.zoom,
      mobileSearchOpen:true, // if in mobile mode, default to open (ignored for desktop)
      mobileResultsList:false // default to map mode
    }
    this._isMounted = false;
    this.movedTimeout = null;

    //console.log("ParkingMap:Constructor:",this.state);
    this.cancelExistingSearch = this.cancelExistingSearch.bind(this);
  }

  cancelExistingSearch(){
    // Cancel any outstanding searches
    if (this.movedTimeout){
      clearTimeout(this.movedTimeout);
      this.movedTimeout = null;
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.cancelExistingSearch();
  }

  // DEV TEST - used to force re-render every second etc
  testDamian(){
    return;
    let that = this;
    setTimeout(() => {
      let tmpDamian = that.state.tmpDamian || 1;
      tmpDamian++;
      console.log({tmpDamian});
      that.setState({tmpDamian});
      //printListenerCount();
      that.testDamian();
    }, 1000);
  }


  componentDidMount() {
    this._isMounted = true;

    // Loaded from searchParas/urlParams/disabled-geo-permissions: execute search with current map address
    if (this.props.searchCounter === this.props.forceSearchCounter){
      this.doSearch('search');
    }

    this.testDamian();

  }

  shouldComponentUpdate(nextProps, nextState){
    //console.log("shouldComponentUpdate:true");
    /*
    console.log({nextProps});
    console.log({nextState});
    
    console.log("scA:",nextProps.searchCounter);
    console.log("scB:",this.props.searchCounter);

    if (nextProps.address == this.props.address){
      return false;
    }*/
    return true;
  }

  componentDidUpdate(prevProps, prevState){
    //console.log("componentDidUpdate prevProps.search_type:",prevProps.search_type);

    if (prevProps.search_type === 'parking_bay'){
      if (prevProps.msm_startdate ===  this.props.msm_startdate
        && prevProps.availability_mode === this.props.availability_mode
      && prevProps.center.lat === this.props.center.lat
      && prevProps.center.lng === this.props.center.lng
      && prevProps.address === this.props.address
      && prevProps.searchCounter === this.props.searchCounter){
        // No change
        //console.log("no change");

      } else {
        //console.log("NEW CENTER/ADDRESS... do a search!");
        this.cancelExistingSearch();
        this.doSearch('search');
      }
      

    } else {

      let endChanged = false;
      if (!this.props.end && prevProps.end){
        //console.log("A1");
        endChanged = true;
      } else if (this.props.end && !prevProps.end){
        //console.log("A2");
        endChanged = true;

      } else if (prevProps.end && this.props.end && !prevProps.end.isSame(this.props.end)){
        //console.log("A3");
        endChanged = true;

      }

      if (prevProps.start.isSame(this.props.start)
      && !endChanged
      && prevProps.center.lat === this.props.center.lat
      && prevProps.center.lng === this.props.center.lng
      && prevProps.address === this.props.address
      && prevProps.searchMode === this.props.searchMode
      && prevProps.searchCounter === this.props.searchCounter){
        // No change
        if (prevProps.sortBy === this.props.sortBy){
          //console.log("no change");
        } else {
          // Re-sort (without hitting server)
          //console.log("update sort");
          //this.doSort(this.state.spaces);
          let spaces = this.state.data;
          this.doSort(spaces,this.props.sortBy);
          this.setState({data:spaces,mapPinData:spaces});
          
        }
        

      } else {

        //console.log("NEW CENTER/ADDRESS... do a search!");  //600
        this.cancelExistingSearch();

        if (prevProps.searchMode !== this.props.searchMode){
          // Switching between casual/monthly, clear results before search
          //console.log("switching modes");
          this.doSearch('search', true);
        } else {
          this.doSearch('search', false);
        }
        

  
        
      }


    }

  }

  doSearch(mapAction, clearResults=false){
    if (!this._isMounted){
      return;
    }

    let bounds;
    let zoom;

    if (this._googleMap){
      bounds = this._googleMap.getBounds();
      zoom = this._googleMap.getZoom();
    }

    if (!bounds || !zoom){
      // On first search we are called directly, no throttled/debounced
      if (this.props.searchCounter === 1){
        // console.log(`$$$$$$ GMAP - BOUNDS NOT READY [sc:${this.props.searchCounter}] retrying...`);

        let that = this;
        setTimeout(() => {
          that.doSearch(mapAction, clearResults); 
        }, 50);
  
      } else {
        // console.log("$$$ FAIL after first render"); // Debug test - should not happen
      }
      return;
    }


    // If googleMap is ready AND we can get bounds/zoom, proceed with search
    if (mapAction === 'search'){
      // New search hide details of any existing selected parking_space
      //this.setState({mobileSearchOpen:false});
      if (clearResults){
        this.setState({data:null, mapPinData:null, arrResults:null, searchInProgress:true, showDetails: false, showBayList:false, showConfirmation:false});    

      } else{
        this.setState({searchInProgress:true, showDetails: false, showBayList:false, showConfirmation:false});    
      }
      

    } else {
      // Map move/zoom so leave the current selection (will remain even if scrolled off map)
      this.setState({searchInProgress:true });    
    }
    

    const start = this.props.start;
    const end = this.props.end;
    const center = this.props.center
    const address = this.props.address;
    const activeUuid = this.props.activeUuid;
    const searchMode = this.props.searchMode;

    const lat0 = bounds.getNorthEast().lat();
    const lng0 = bounds.getNorthEast().lng();
    const lat1 = bounds.getSouthWest().lat();
    const lng1 = bounds.getSouthWest().lng();



    
    //console.log("parkingMap doSearch props:",this.props);
    let queryParams;

    if (this.props.WPMode === 'bays'){

      queryParams = {
        lastTimeTaken:this.state.timeTaken,
        msm_startdate: this.props.msm_startdate,
        //startdate: start.format(QueryParamDate),
        availability_mode: this.props.availability_mode.substr(0,1),  //TEMP!!!!!! Sending 0,2 or 3
        lat: center.lat,
        lng: center.lng,
        lat0,
        lng0,
        lat1,
        lng1,
        zoom,
        address,
        uuid: activeUuid,
        action: mapAction,
        width:window.innerWidth,
        height:window.innerHeight
      };

    } else {
      
      
      if (searchMode === 1){
        // Monthly
        queryParams = {
          lastTimeTaken:this.state.timeTaken,
          lastTimeTaken2:this.state.timeTaken2,
          searchMode,
          includeAll: 1,    //1= include unavailable
          sortBy:1, //0=price, 1=distance   
          startdate: start.format(QueryParamDate),
          //starttime: start.format(QueryParamTime),
          ////////////////enddate: end.format(QueryParamDate),
          //endtime: end.format(QueryParamTime),
          //duration:  Math.ceil(end.diff(start, 'minutes', true)),
          lat: center.lat,
          lng: center.lng,
          lat0,
          lng0,
          lat1,
          lng1,
          zoom,
          address,
          uuid: activeUuid,
          action: mapAction,
          width:window.innerWidth,
          height:window.innerHeight
        };
  
      } else {
        // Casual

        queryParams = {
          lastTimeTaken:this.state.timeTaken,
          lastTimeTaken2:this.state.timeTaken2,
          searchMode,
          includeAll: 1,    //1= include unavailable
          sortBy:1, //0=price, 1=distance   
          startdate: start.format(QueryParamDate),
          starttime: start.format(QueryParamTime),
          enddate: end.format(QueryParamDate),
          endtime: end.format(QueryParamTime),
          duration:  Math.ceil(end.diff(start, 'minutes', true)),
          lat: center.lat,
          lng: center.lng,
          lat0,
          lng0,
          lat1,
          lng1,
          zoom,
          address,
          uuid: activeUuid,
          action: mapAction,
          width:window.innerWidth,
          height:window.innerHeight
        };
  
      }

  
    }


    const firstProps = this.state.firstProps;
    const startTime = new Date();

    if (this.props.WPMode === 'bays'){
    
      getParkingSearch(queryParams).then(closest => {
        if (!this._isMounted){
          return;
        }
        const timeTaken= (new Date())-startTime;
          
        //console.log(`Search done, total results: ${closest.data.data.length}`);
        //console.log("results:",closest.data);
  
        // Our results are data.data
        if (closest.data.data.length > 0) {
          this.getSpaceDistances(closest.data, timeTaken, firstProps);
  
        } else {
          this.setState({searchInProgress:false,loading: false});
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({searchInProgress:false, loading: false});
      });
  
    } else {



      // ADMIN mode
      if (this.props.user.profile.search_token){
        //console.log("admin token:",this.props.user.profile.search_token);
        queryParams.searchToken = this.props.user.profile.search_token;
      }
      //if (mapAction === 'search'){
      queryParams.email = (this.props.user.profile && this.props.user.profile.email) || "n/a";
      //}
  
      
      // NEW search will run alongside Heroku for testing, until ready to switch over
      getGeoSearchBeanstalk(queryParams).then(closest => {
        //console.log(closest.data);
        const timeTaken= (new Date())-startTime;
        //const timeTaken=null;
        //this.setState({timeTaken2, testBlocks:closest.data});
        if (closest.data.length > 0) {
          this.getSpaceDistances(closest, timeTaken, firstProps);
  
        } else {
          // if we are using mobile, it will hide the search div on success only
          //console.log("NO RESULTS");
  
          let hoverUuid = null;
          let selectedSpace = null; // this.state.selectedSpace;
          let selectedBay = null; // this.state.selectedBay;
          let showDetails = false; // this.state.showDetails;
          let showBayList = false; // this.state.showBayList;
          let showConfirmation = false; // this.state.showConfirmation;
      
          
          this.setState({timeTaken, searchInProgress:false,loading: false, hoverUuid, selectedSpace,selectedBay,showDetails,showBayList,showConfirmation, selectedSpace2:null, selectedSpaceType:null, arrResults:[], data:[]});   // mobileSearchOpen:false
          
          /*this.state = {
            activeUuid: undefined,
            data: undefined,
            loading: false,
            searchInProgress: false,
            firstProps: true,
            showDetails: false,
            showConfirmation: false,
            vehicle: 1,
            zoomLevel: this.props.zoom
          }*/
        }


      })
      .catch(error => {
        console.error(error);
        //this.setState({searchInProgress:false, loading: false});
      });
  
      

      // Oscar casual search
      /*getLocationSpacesClosest(queryParams).then(closest => {
        if (!this._isMounted){
          return;
        }
        //console.log("results:",closest.data);
        const timeTaken= (new Date())-startTime;
    
        
        //console.log(`Search done, total results: ${closest.data.length}`);
        //console.log("Data:",closest.data);
  
        if (closest.data.length > 0) {
          this.getSpaceDistances(closest, timeTaken, firstProps);
  
        } else {
          // if we are using mobile, it will hide the search div on success only
          //console.log("NO RESULTS");
  
          let hoverUuid = null;
          let selectedSpace = null; // this.state.selectedSpace;
          let selectedBay = null; // this.state.selectedBay;
          let showDetails = false; // this.state.showDetails;
          let showBayList = false; // this.state.showBayList;
          let showConfirmation = false; // this.state.showConfirmation;
      
          
          this.setState({searchInProgress:false,loading: false, hoverUuid, selectedSpace,selectedBay,showDetails,showBayList,showConfirmation, selectedSpace2:null, selectedSpaceType:null, arrResults:[], data:[]});   // mobileSearchOpen:false
          
          //this.state = {
            activeUuid: undefined,
            data: undefined,
            loading: false,
            searchInProgress: false,
            firstProps: true,
            showDetails: false,
            showConfirmation: false,
            vehicle: 1,
            zoomLevel: this.props.zoom
          }//
        }
      })
      .catch(error => {
        console.error(error)
        this.setState({searchInProgress:false, loading: false});
      });*/
  


    }



  }


  getSpaceDistances = (response, timeTaken, firstProps) => {
    //console.log('getSpaceDistances'); // initial response value', response.data[0])
    //console.log("timeTaken:",timeTaken);
    let arrResults = [];

    let spaces;

    let companies = response.companies;
    let buildings = response.buildings;
    let parking_bays = response.parking_bays;

    let selectedSpace = this.state.selectedSpace;
    let selectedBay = this.state.selectedBay;
    let showDetails = this.state.showDetails;
    let showBayList = this.state.showBayList;
    let showConfirmation = this.state.showConfirmation;


    // Calculate prices for bays and buildings here - once on load
    // parking_bays will inherit their min/max/defaults from buildings they belong to
    if (response.buildings){
      let buildingsJSON = {};
      let bestPrices = {};
      let buildingBays = {};
      for (let b=0;b<buildings.length;b++){
        buildingsJSON[buildings[b].uuid] = buildings[b];
        bestPrices[buildings[b].uuid] = buildings[b].rate_monthly_max; // default;
        buildingBays[buildings[b].uuid] = [];
      }
  
      //console.log("bayCounts:",bayCounts);
      //parking_bays.sort((x,y) => { return x.bookingPrice - y.bookingPrice});


      spaces = response.buildings;
      // Count/assign all bays for this building
      let bayCounts = {};
      for (let p=0;p<parking_bays.length;p++){
        let thisBay = parking_bays[p];

        let buildingId = thisBay.building.uuid;
        let thisBuilding = buildingsJSON[buildingId];
        

        // Apply building default and min/max rules to the bay price
        if (!thisBay.rate_monthly){
          thisBay.rate_monthly = thisBuilding.rate_monthly_default;
        }
        if (thisBay.rate_monthly < thisBuilding.rate_monthly_min){
          thisBay.rate_monthly = thisBuilding.rate_monthly_min;
        } else if (thisBay.rate_monthly > thisBuilding.rate_monthly_max){
          thisBay.rate_monthly = thisBuilding.rate_monthly_max;
        }
        
        // Final price shown to clients - pay this amount to book
        thisBay.bookingPrice = thisBay.rate_monthly;
        //mapPrice not used for bays

        //console.log("price:",thisBay.bookingPrice);
        // Exclude UNAVAILABLE bays
        if (thisBay.active_bookings && thisBay.active_bookings.length > 0){
          thisBay.isAvailable = false;
          continue;
        } else if (thisBay.future_bookings && thisBay.future_bookings.length > 0){
          thisBay.isAvailable = false;  // Hide bays with ANY future bookings - Meriton requested this feature
          continue;
        } else {
          thisBay.isAvailable = true;
        }

        let bc = bayCounts[buildingId];
        if (!bc){
          bc = 0;
        }
        bc++;
        bayCounts[buildingId] = bc;

        buildingBays[buildingId].push(thisBay);

        // Track the cheapest bay for this building
        if (thisBay.bookingPrice < bestPrices[buildingId]){
          bestPrices[buildingId] = thisBay.bookingPrice;
          //console.log("bestPrice:",thisBay.bookingPrice);
        }
        
      }
      //console.log("bestPrices[]:",bestPrices);

      // Store best price for each building
      let buildingFound = false;

      for (let b=0;b<buildings.length;b++){
        
        if (selectedSpace && buildings[b].uuid === selectedSpace.uuid){
          buildingFound = true;
        }
        let totalBays = bayCounts[buildings[b].uuid] || 0;
        let result_limit = buildings[b].result_limit || 0;
        
        if (result_limit > 0){
          if (totalBays > result_limit){
            totalBays = result_limit;
          }
        }
        let strTotalBays;
        let strTotalSpaces;
        if (totalBays === 0){
          strTotalBays = `FULL`;
          strTotalSpaces = 'No spaces';
        } else if (totalBays === 1){
          strTotalBays = `${totalBays} bay`;
          strTotalSpaces = `${totalBays} space`;
        } else {
          strTotalBays = `${totalBays} bays`;
          strTotalSpaces = `${totalBays} spaces`;
        }
        buildings[b].totalBays = totalBays;
        buildings[b].strTotalBays = strTotalBays;
        buildings[b].strTotalSpaces = strTotalSpaces;
        
        //let bestPrice = bestPrices[buildings[b].uuid];
        //if (!bestPrice){
        let bestPrice = bestPrices[buildings[b].uuid] || buildings[b].rate_monthly_default;   // Best Price (or building default if no available bays)
        //console.log("bestPrice:",bestPrice);
        //} 
        buildings[b].rate_monthly_best = bestPrice;
        buildings[b].bookingPrice = bestPrice;  // not used?
        
        if (totalBays === 0){
          buildings[b].mapPrice = { price: 'FULL', per:'/mth'};
        } else {
          buildings[b].mapPrice =  { price: bestPrice, per:'/mth'};
        }
        
        buildings[b].bays = buildingBays[buildings[b].uuid];

        buildings[b].bays.sort((x,y) => { return x.bookingPrice - y.bookingPrice});

        // Collapse bays per building
        for (let i=0;i<buildings[b].bays.length;i++){
          let thisBay = buildings[b].bays[i];
          //console.log(`thisBay[${i}]:`,thisBay);

          if (i==0){
            //console.log("bay0, no previous, collapse=false");
            continue;
          }

          if (isDuplicateBay(thisBay,buildings[b].bays[i-1])){
            //console.log(`${i} > b:${thisBay.building.uuid} $${thisBay.rate_monthly}/mth  DUPLICATE of previous, collapse=true`);
            //console.log(thisBay);
            thisBay.collapse = true;
            

            // inc counter for parent
            for (let j=i;j>=0;j--){
              if (!buildings[b].bays[j].collapse){
                buildings[b].bays[j].totalChildren= (buildings[b].bays[j].totalChildren || 1) + 1;
                break;
              }
            }

          } else {
            //console.log(`${i} > b:${thisBay.building.uuid} $${thisBay.rate_monthly}/mth - collapse=false`);
            //console.log(thisBay);
          }
        }


        // Put all bays with no bookings at the top of the list
        buildings[b].bays.sort((x,y) => { return Math.min(x.future_bookings.length,1) - Math.min(y.future_bookings.length,1) });

      }
      if (selectedSpace && !buildingFound){
        //console.log("building NOT FOUND");
        selectedSpace = null;
        selectedBay = null;
        showDetails = false;
        showBayList = false;
        showConfirmation = false;
        
      }
      

      //showDetails: false, selectedBay: null

      spaces.sort((x,y) => { return x.bookingPrice - y.bookingPrice});
      // Estimate the distance from each spot to center.
      spaces.forEach(space => {
        space.mapPosition={ lat: space.latitude, lng: space.longitude };

        space.distanceToCenter = estimateDistance(this.props.center.lat, this.props.center.lng, space.latitude, space.longitude);
        space.strDistanceToCenter = getDistanceDisplayString(space.distanceToCenter);

      });
      for (let i=0;i<buildings.length;i++){
        arrResults.push(this.getListItem_Building(buildings[i]));
      }



    } else {

      // === OSCAR ===
      //let duration= roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)));
     
      spaces = response.data;
      
      // Estimate the distance from each spot to center.
      spaces.forEach(space => {

       // calculatePrice is ONLY used here, ONCE, for performance. Only used by Oscar.
        //space.bookingPrice = calculatePrice(space, duration, this.props.start);
        //if (space.rate_monthly){
        //  space.bookingPrice = space.rate_monthly;
        //}

        space.bookingPrice = space.pricing.output.total_price.toFixed(2);
        space.mapPrice = space.pricing.output.display_map; // calculatePrice(space, duration, this.props.start, true); //JSON
        space.mapPosition={ lat: space.latitude, lng: space.longitude };


        space.distanceToCenter = estimateDistance(this.props.center.lat, this.props.center.lng, space.latitude, space.longitude);
        space.strDistanceToCenter = getDistanceDisplayString(space.distanceToCenter);
      });

      this.doSort(spaces,this.props.sortBy);
      
      /*let results_remaining = 9999;
      const data = spaces;
      for (let i=0;i<data.length;i++){
        let thisSpace = data[i];
        if (thisSpace.available && !thisSpace.occupied){
          arrResults.push(this.getListItem_ParkingSpace(thisSpace, results_remaining));
        }
      }*/


    }
    

    const defaultVehicle = this.props.user.profile.vehicles ? 
      this.props.user.profile.vehicles.find(vehicle => vehicle.default) : null;

    // Remove spaces that are too small for our vehicle - this needs to be clearer for clients
      if(defaultVehicle) {
      const userVehicleSize = vehicleSizeMap[defaultVehicle.car_type];
      spaces.forEach(space => {
        if (vehicleSizeMap[space.vehicle_type] < userVehicleSize) {
          space.available = 0;
        }
      });
    }

    // Sort the array based on the distances
    /*spaces = spaces.sort(function(a, b){
      return a.distanceToCenter - b.distanceToCenter
    });*/

    try {
      //const visibleMapBounds = this._googleMap.getBounds()


      // Use current selected space as fallback default,
      // which allows us to keep it selected even if it scrolls out of range
      // and is no longer in results
      let selectedSpace = this.state.selectedSpace;
      let showDetails = this.state.showDetails;
      let showConfirmation = this.state.showConfirmation;

      
      // We either look for the existing uuid, or the initialUuid from url if first run
      let activeUuid = this.state.activeUuid;
      if (!activeUuid && this.state.firstProps && this.props.initialUuid){
          activeUuid = this.props.initialUuid;
          //console.log("setActiveUuid from initialUuid:",activeUuid);
      }

      //console.log({activeUuid});
      

      if (this.props.WPMode === 'spaces'){
        if (activeUuid && !selectedSpace){
          for (let i=0;i<spaces.length;i++){
            if (spaces[i].uuid === activeUuid){
              selectedSpace = spaces[i];
              // keep this as-is, otherwise mobile keeps popping up full details     showDetails = false; // true; //this.props.desktopDisplay ? true:false;
              //////showConfirmation = false;
              showConfirmation = false;
              showDetails = true;
              ///console.log("***activeeUuid:",activeUuid);
              break;
            }
          }    
        }
  
      } else {
        if (activeUuid && !selectedSpace){
          //console.log("deeplink-openbuilding");

          //console.log({spaces});
          // Find building
          let selected = spaces.find(space => {
            return space.uuid === activeUuid;
          });

          //console.log({selected});
          showBayList = true;
          showDetails = false;
          showConfirmation = false;
          selectedSpace = selected;

          /*this.setState({
            activeUuid: spaceUuid,
            selectedSpace: selected,
            selectedBay: null,
            showBayList: true,
            showDetails: false, //true,
            showConfirmation: false,
          });          */
          
          
          //handleListBuildingClick
          /* 
              this.setState({
      activeUuid: spaceUuid,
      selectedSpace: selected,
      selectedBay: null,
      showBayList: true,
      showDetails: false, //true,
      showConfirmation: false,
    });

          */
         //console.log("xxxxxxxxx1");
        }
      }

      //console.log("OK-11");

      //console.log({selectedSpace});


      this.setState({
        firstProps:false,
        timeTaken,
        searchInProgress:false,
        data: spaces,
        arrResults,
        mapPinData: spaces, // filteredSpaces,
        //listData: listData
        companies,
        buildings,
        parking_bays,
        activeUuid,
        selectedSpace,
        selectedBay,
        showDetails,
        showBayList,
        showConfirmation,
        mobileSearchOpen:false
  
      });

    } catch (e) {
      // In case of failure, unlock the screen so user can try again,
      // otherwise the app just locks up and they can't do anything.
      // TODO: maybe a client error/retry screen
      this.setState({searchInProgress:false, loading: false});
      return;
    }


/*  // Get walking distances from the center to these spaces.
    let nearestSpaces = spaces.length > topNearest ? spaces.slice(0, topNearest) : spaces;

    // Find walking distances to nearest spaces
    getWalkingDistances(nearestSpaces, this.props.center).then(response => {
      const spaceData = this.state.data
      try {
        response.forEach((resp, i) => {
          spaceData[i].walkingDistance = resp.duration.text
        })  
      } catch(e) {
        void(0);
      }
      // this.setState({loading: false})
      // this.props.saveSpaceData(this.state.data)
    });*/

  }

  //

  doSort(spaces, sortBy){
    //console.log({sortBy});
    // don't use '===' as it could be string etc
    if (sortBy == 1){
      //console.log("sorting by distance...");
      spaces.sort((x,y) => { return x.distanceToCenter - y.distanceToCenter});
    } else {
      //console.log("sorting by price...");
      spaces.sort((x,y) => { return x.bookingPrice - y.bookingPrice});
    }
  }

  handleListBayClick = (spaceUuid) => {
    //localStorage.setItem('spaceSelected', spaceUuid)

    let selectedBay = this.state.parking_bays.find(space => {
      return space.uuid === spaceUuid;
    });


    this.setState({
      //activeUuid: spaceUuid,
      //selectedSpace: selected,
      selectedBay,
      showDetails: true,
      showConfirmation: false,
    });


  }

  // Oscar3 upgrade
  handleListSpaceClick = (spaceUuid) => {
    this.handleMarkerClickDesktop(spaceUuid,false);
  }

  handleSearchMobile = (e) => {
    //console.log("handleSearchMobile");
    this.cancelExistingSearch();
    this.doSearch('search');
    
    

  }

  handleListBuildingClick = (spaceUuid) => {
    localStorage.setItem('spaceSelected', spaceUuid)

    // ParkingSpaces or Buildings
    let selected = this.state.data.find(space => {
      return space.uuid === spaceUuid;
    });

    // ParkingBays
    /*if (!selected){
      selected = this.state.parking_bays.find(space => {
        return space.uuid === spaceUuid;
      });
  
    }*/

    this.setState({
      activeUuid: spaceUuid,
      selectedSpace: selected,
      selectedBay: null,
      showBayList: true,
      showDetails: false, //true,
      showConfirmation: false,
    });


  }

   handleMarkerHover = (spaceUuid) => {
   if (spaceUuid !== this.state.hoverUuid){
    this.setState({
      hoverUuid: spaceUuid,
    });
    //console.log("handleMarkerHover:",spaceUuid);
   }
  }


  // Update marker appearances and pass the click up the tree.
  handleMarkerClick = (spaceUuid) => {
    localStorage.setItem('spaceSelected', spaceUuid)
    const selected = this.state.data.find(space => {
      return space.uuid === spaceUuid;
    });

    let spaceType = getSpaceType(selected);

    let strSearchMode = this.props.searchMode == 1 ? 'Monthly':'Casual';
    let s = selected;
    let details = {

    };    

    if (s){
      details.address_text = `${s.type} on ${s.street_name}`;
      details.uuid = s.uuid;
      details.debug_text = `${strSearchMode} - ${s.type} on ${s.street_name} for $${s.bookingPrice} Bay#${s.bay_index} [..${s.uuid.slice(-5)}]`;
    }

    if (this.props.user.authenticated) {
      sendOscarAnalytics(this.props.user.profile.email, "pin.click.mobile", details);
    } else {
      sendOscarAnalytics("anon", "pin.click.mobile", details);
    }


    this.setState({
      activeUuid: spaceUuid,
      selectedSpace: selected,
      //???? building?
      selectedSpaceType: spaceType,
      selectedBay: null,
      hoverUuid: spaceUuid,

    });
  }
  handleMarkerClickDesktop = (spaceUuid, onMap=true) => {
    localStorage.setItem('spaceSelected', spaceUuid)
    const selected = this.state.data.find(space => {
      return space.uuid === spaceUuid;
    });

    let spaceType = getSpaceType(selected);
    //console.log("*selectedSpaceType:",spaceType);
    //console.log({selected});

    let strSearchMode = this.props.searchMode == 1 ? 'Monthly':'Casual';
    let s = selected;
    let details = {

    };    

    if (s){
      details.address_text = `${s.type} on ${s.street_name}`;
      details.uuid = s.uuid;
      details.debug_text = `${strSearchMode} - ${s.type} on ${s.street_name} for $${s.bookingPrice} Bay#${s.bay_index} [..${s.uuid.slice(-5)}]`;
    }


    if (this.props.user.authenticated) {
      sendOscarAnalytics(this.props.user.profile.email, onMap ? "pin.click.desktop":"list.click.desktop", details);
    } else {
      sendOscarAnalytics("anon", onMap ? "pin.click.desktop":"list.click.desktop", details);
    }

    this.setState({
      activeUuid: spaceUuid,
      selectedSpace: selected,
      selectedSpaceType: spaceType,
      selectedBay: null,
      showBayList: spaceType === 'building' ? true: false,
      showDetails: spaceType === 'parking_space' ? true: false,
      showConfirmation: false,
      hoverUuid: spaceUuid,
    });
  }

  handleBookClicked = () => {
    
    //console.log("handleBookClicked");

    let strSearchMode = this.props.searchMode == 1 ? 'Monthly':'Casual';
    let s = this.state.selectedSpace;
    let details = {

    };
    //console.log({s});
    if (s){
      details.booking_price = s.bookingPrice;
      details.address_text = `${s.type} on ${s.street_name}`;
      details.uuid = s.uuid;

      
      details.debug_text = `${strSearchMode} - ${s.type} on ${s.street_name} for $${s.bookingPrice} Bay#${s.bay_index} [..${s.uuid.slice(-5)}]`;

    }

    
    
    if (this.props.user.authenticated) {
      sendOscarAnalytics(this.props.user.profile.email, "button.book", details);

      this.setState({
        showDetails: false,
        showConfirmation: true,
      });

    } else {
      sendOscarAnalytics('anon', "button.book", details);

      this.props.history.push('/login');  //DAMIAN???
    }
  }

  handlePayCancel = () => {
    /*this.setState({
      showConfirmation: false,
    });*/
    this.setState({showDetails:true, showConfirmation:false});
  }

  closeParkingCard = () => {
      this.setState({
        activeUuid: undefined,
      });
  }   

  getMapRef = (ref) => {
    this._googleMap = ref;
  }

  handleZoomChanged = () => {
    const zoom = this._googleMap.getZoom();

    if (zoom !== this.state.zoom) {
      if (zoom < this.state.zoom){
        this.handleMapMoved('zoom',THROTTLE_MAPZOOMOUT);   // zoom out
      }
      this.setState({zoom});
    }    
  }

  handleMapMoved = (action = 'move', throttleDelay = THROTTLE_MAPMOVED) => {
    try {
      // console.log('### map moved');
      this.cancelExistingSearch();

      //console.log(`mapMoved waiting ${throttleDelay}ms...`);
      this.movedTimeout = setTimeout(function() {
        this.movedTimeout = null;
        //console.log("mapMoved triggering search");
        this.doSearch(action);
      }.bind(this), throttleDelay);

    } catch (e) {
      console.log(e);
    }
  }

  getListItem_Building(thisBuilding){

    let thisRate;

    if (thisBuilding.totalBays > 0){
      thisRate = `$${thisBuilding.rate_monthly_best.toFixed(0)}/mth`;
  
    }

    const img = getImagePreviewForBuilding(thisBuilding);

    let strClass = "li-building";
    if (this.state.selectedSpace && thisBuilding.uuid === this.state.selectedSpace.uuid){
      //strClass +=" selected";
    } else if (this.state.hoverUuid === thisBuilding.uuid) {
      strClass += " maphover";
    }

//LAZY LOAD!
    return(
      <div className={strClass} key={thisBuilding.uuid} onClick={this.handleListBuildingClick.bind(this,thisBuilding.uuid)} onMouseOver={this.handleMarkerHover.bind(this,thisBuilding.uuid)} onMouseLeave={this.handleMarkerHover.bind(this,null)}>
        <div className="photo-box">{img}</div>
        <div className="details">
          <h2>{thisBuilding.building_name}</h2>
          <p>{thisBuilding.address}, {thisBuilding.suburb}</p>
          <p><IconWalk />{thisBuilding.strDistanceToCenter}</p>
          <p className="totalbays">{thisBuilding.strTotalSpaces} <span className="rate">{thisRate}</span></p>
        </div>
      </div>
      );

  }

  getListItem_ParkingBay(thisSpace, results_remaining){
    let thisRate = thisRate = `$${thisSpace.bookingPrice.toFixed(0)}/mth`;
    const building = this.getBuildingById(thisSpace.building.uuid);

    const img = getImagePreviewForSpace(thisSpace, building);

    let strClass = "li-bay";
    if (this.state.selectedBay && thisSpace.uuid === this.state.selectedBay.uuid){
      strClass +=" selected";
    } 


    //onMouseOver={this.handleMarkerHover.bind(this,thisBuilding.uuid)} onMouseLeave={this.handleMarkerHover.bind(this,null)}    
    //<p>User:{thisSpace.user && thisSpace.user.first_name}</p>

    let divBookings;


    if (thisSpace.active_bookings && thisSpace.active_bookings.length > 0){
      let activeBooking = thisSpace.active_bookings[0];   // Only 1 can ever be here
      strClass += " unavailable"
      divBookings = (
        <div className="active-booking">
          <div>UNAVAILABLE<br/>{activeBooking.start_date} - {activeBooking.cease_date || "Ongoing"}</div> 
        </div>
      );
    } else {
      if (thisSpace.future_bookings && thisSpace.future_bookings.length > 0){
        let futureBooking = thisSpace.future_bookings[0];   // Only 1 can ever be here
  
        divBookings = (
          <div className="future-booking">
            <div>Available<br/>until<br/>{moment(futureBooking.start_date).format("DD MMM YYYY")}</div> 
          </div>
        );
      }
    }

    let divCollapsedChildren;

  
    if (thisSpace.totalChildren){
      let intChildren = thisSpace.totalChildren;
      if (intChildren > results_remaining){
        intChildren = results_remaining;
      }
      divCollapsedChildren = (<div className="has-collapsed-children"><div>{intChildren} spaces left</div></div>);
    }



    let strVehicleType;
    if (thisSpace.vehicle_type == 4){
      strVehicleType = `Fits an ${ENUMS.vehicle_types[thisSpace.vehicle_type]}`;
    } else {
      strVehicleType = `Fits a ${ENUMS.vehicle_types[thisSpace.vehicle_type]}`;
    }
    
   
    return(
      <div className={strClass} key={thisSpace.uuid} onClick={this.handleListBayClick.bind(this,thisSpace.uuid)}>
        <div className="photo-box">{img}{divBookings}{divCollapsedChildren}</div>
        
        <div className="details">
          <div className="tagline">{thisSpace.tagline || thisSpace.description || building.tagline || building.description}</div>
          <p>{ENUMS.availability_types[thisSpace.availability_type]}</p>
          <p><b>Bay #{thisSpace.bay_marking}</b> <span className="vehicle_type">{strVehicleType}</span><span className="rate">{thisRate}</span></p>
        </div>
      </div>
    );
  }


  getBuildingById(uuid){
    const buildings = this.state.buildings;
    let thisBuilding = buildings.find(x => x.uuid === uuid);
    return thisBuilding;
  }

  getBuildingSummary(thisBuilding){
    //let thisRate = thisRate = `$${thisBuilding.rate_monthly_best.toFixed(0)}/mth`;
    return(
      <div className={"list-building-summary"} key={thisBuilding.uuid}>
        <IconButton style={styles.rightTopButton} onClick={() => this.setState({showBayList: false, showDetails: false, selectedSpace:null})}><IconClose style={{zIndex:'5', color: 'rgb(207, 207, 207)'}}/></IconButton>        
        <div className="details">
          <h2><span>{thisBuilding.building_name}</span> on {thisBuilding.street_name}, {thisBuilding.suburb}</h2>
          <p>Max Vehicle Height: {thisBuilding.max_clearance}m</p>
          <p>Access method: {ENUMS.access_methods[thisBuilding.access_method]}</p>
          <p><IconWalk />{thisBuilding.strDistanceToCenter}</p>
          <p className="totalbays">{thisBuilding.strTotalSpaces} available</p>
        </div>
      </div>
    );
  }

  getListItem_ParkingSpace(thisSpace, searchMode, results_remaining){
    //console.log({thisSpace});
    let divBookingPrice;
    let divHourlyPrice;

    const pricing = thisSpace.pricing.output;


    //let bookingPrice = `$${thisSpace.bookingPrice}`;  //.toFixed(0)
    let bookingPrice = `$${pricing.total_price.toFixed(2)}`;  //.toFixed(0)
    let mapPrice = thisSpace.mapPrice;

    divBookingPrice = <span className="rate-bold">{bookingPrice}</span>;
    

    if (searchMode == 1){
      // Monthly
      divBookingPrice = <span className="rate">{`$${thisSpace.rate_monthly}/mth`}</span>;
    } else {
      divHourlyPrice = <div className="rate-hourly">{`$${pricing.display_list.price}${pricing.display_list.per}`}</div>;
    }
    /*if (thisSpace.rate_monthly){
      divBookingPrice = <span className="rate">{`$${thisSpace.rate_monthly}/mth`}</span>;
    } else {
      divHourlyPrice = <div className="rate-hourly">{`$${pricing.display_list.price}${pricing.display_list.per}`}</div>;
    }*/

    /*// #PRICING
    if (thisSpace.rate_monthly){
      divBookingPrice = <span className="rate">{`$${mapPrice.price}${mapPrice.per}`}</span>;
      
    } else if (thisSpace.rate_daily){
      divHourlyPrice = <div className="rate-hourly">{`$${thisSpace.rate_daily.toFixed(2)}/day`}</div>;

    } else if (thisSpace.rate_hourly){
      divHourlyPrice = <div className="rate-hourly">{`$${thisSpace.rate_hourly.toFixed(2)}/hr`}</div>;

    }*/
    


    let img = getImagePreviewForSpace(thisSpace, null);
    if (!img){
      let imagePath = 'https://images.sharewithoscar.com.au/assets/graphics/no-photo.png'; // cp-logos/no-logo-2.png';
      //GetStreetView(thisSpace.latitude, thisSpace.longitude, 120, 100)
      img = <img style={styles.previewImage} key={thisSpace.uuid} src={imagePath} alt="The parking space"/>;
    }
  

    let strClass = "li-space";
    if (this.state.selectedSpace && thisSpace.uuid === this.state.selectedSpace.uuid){
      strClass +=" selected";
    } 
    /*if (this.state.hoverUuid == thisSpace.uuid) {
      strClass += " selected";
    }*/

    let divCollapsedChildren;
  
    /*if (thisSpace.totalChildren){
      let intChildren = thisSpace.totalChildren;
      if (intChildren > results_remaining){
        intChildren = results_remaining;
      }
      divCollapsedChildren = (<div className="has-collapsed-children"><div>{intChildren} spaces left</div></div>);
    }*/


    let strAddress;
    let strSuburb = thisSpace.suburb2;  //TEMP
    if (strSuburb){
      strAddress = `${thisSpace.street_name}, ${strSuburb}`;
    } else {
      strAddress = thisSpace.street_name;
    }
    let strVehicleType = `Fits a ${thisSpace.vehicle_type}`;
    // onClick={this.handleListSpaceClick.bind(this,thisSpace.uuid)} onMouseOver={this.handleMarkerHover.bind(this,thisSpace.uuid)} onMouseLeave={this.handleMarkerHover.bind(this,null)}
    return(
      <div className={strClass} key={thisSpace.uuid} onClick={this.handleListSpaceClick.bind(this,thisSpace.uuid)}>
        <div className="photo-box">{img}{divCollapsedChildren}</div>
        <div className="details">
          <div className="tagline">{strAddress}{divHourlyPrice}</div>
          {/*thisSpace.capacity > 1 && <p style={{fontWeight:'700'}}>{thisSpace.available} / {thisSpace.capacity}</p>*/}
          <p><IconWalk />{thisSpace.strDistanceToCenter}</p>
          <p><span className="vehicle_type">{strVehicleType}</span>{divBookingPrice}</p>
        </div>
      </div>
    );
  }



//<small style={{color:'#0000ff'}}><br/>{strDebug}</small>







  //==========================================================
  // Render
  //==========================================================
  render() {
    //console.log("*ParkingMap RENDER props:",this.props);
    //console.log('WPDomain:',this.props.WPDomain);
    const { buildings, companies, showBayList } = this.state; //arrResults, 
    const { browserCSSMode, WPDomain, WPMode, searchMode } = this.props;
    const isMobile = browserCSSMode === "mobile" ? true:false;
    const isDesktop = isMobile ? false:true;

    //console.log(this.props);

    

    let arrResults = this.state.arrResults;
    // ================================================================================
    // TODO: MOVE arrResults into it's own component (so we can NOT render unless needed etc)
    // ================================================================================

        // TEMP
        // TODO:DAMIAN - allow for oscar wpdomain
        if (WPMode === 'spaces' && this.state.data){
          arrResults = [];
          let results_remaining = 9999;
          //console.log("updating results");
          const data = this.state.data; //spaces;
          for (let i=0;i<data.length;i++){
            let thisSpace = data[i];
            if (thisSpace.available && !thisSpace.occupied){
              arrResults.push(this.getListItem_ParkingSpace(thisSpace, searchMode, results_remaining));
            }
          }
        }
    

    let arrBays;
    
    const divResults_Searching = !arrResults && <div className="parkingmap-searching"><p>Searching for spaces...</p></div>;
    const divResults_None = ((WPMode === 'bays' && buildings && buildings.length === 0) || (!WPDomain && arrResults && arrResults.length === 0 )) && <div className="parkingmap-noresults"><p>No available spaces found.</p><p>Try changing your search location or start date.</p></div>;


    // ==========================
    // MOVE OUT OF RENDER LOOP (create arrBays when "selectedSpace" is set)
    // ==========================
    if (WPMode === 'bays' && arrResults && arrResults.length > 0){

      if (this.state.selectedSpace && showBayList){
        //console.log(this.state.selectedSpace);
        const buildingId = this.state.selectedSpace.uuid;
        let thisBuilding;
        thisBuilding = this.getBuildingById(buildingId);

        if (thisBuilding){
          arrBays = [];
          arrBays.push(this.getBuildingSummary(thisBuilding));

          let results_remaining = thisBuilding.result_limit || 9999;

          for (let i=0;i<thisBuilding.bays.length;i++){
            //if (parking_bays[i].isAvailable && !parking_bays[i].collapse){
            if (!thisBuilding.bays[i].collapse){
              arrBays.push(this.getListItem_ParkingBay(thisBuilding.bays[i], results_remaining));
              results_remaining-=thisBuilding.bays[i].totalChildren || 1;
            }
            if (results_remaining <= 0)
              break;
            //}
          }
        }

        // index[0] is building summary
        if (!arrBays || arrBays.length === 1){
          arrBays.push(
            <div key={'no-spaces'} className="parkingmap-noavailability">
              <p>Sorry, there are no spaces available in this building.</p>
              {/*<p>Get notified when a space becomes <a href="https://www.sharewithoscar.com.au/meriton-waitlist/" target="_blank">available here.</a></p>*/}
            </div>
            );
        } else if (WPDomain !== 'community' && thisBuilding.totalBays < 3){
          arrBays.push(
            <div key={'no-spaces'} className="parkingmap-noavailability">
              <p>There are not many spaces available in this building.</p>
              {/*<p>Get notified when more spaces become <a href="https://www.sharewithoscar.com.au/meriton-waitlist/" target="_blank">available here.</a></p>*/}
            </div>
            );
        }
      }

    }     

    //console.log({arrResults});
    // ==========================
    // ==========================
    // ==========================
    // ==========================
    const detailsClass = WPMode === 'bays' ? "parkingmap-details msm":"parkingmap-details";

    const strContainerClass = this.state.showDetails ? "oscar msd-container open":"oscar msd-container";
    const strModalClass = this.state.showDetails ? "oscar modal-spacedetails open":"oscar modal-spacedetails";

    const windowHeight = window.innerHeight;
    //console.log({windowHeight});
    let stylePreview = JSON.parse(JSON.stringify(styles.previewContainer)); 
    stylePreview.top = windowHeight - 180;
    
    //console.log({WPMode});

    return(
      <div className="parkingmap-container noselect">
        
        {(WPMode === 'spaces' && isMobile) &&
        <>
          <div className={strModalClass} onClick={() => this.setState({showDetails: false, activeUuid: undefined, selectedSpace: undefined})}></div>
          <div className={strContainerClass}>
            <div className="msd-body">
              {this.state.selectedSpace &&
                <SpaceDetails 
                key={this.state.selectedSpace.uuid}
                searchMode={searchMode}
                spaceData={this.state.selectedSpace} 
                spaceData2={this.state.selectedSpace2} 
                spaceType="parking_space"
                //duration={roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)))} 
                closeModal={() => this.setState({showDetails: false, activeUuid: undefined, selectedSpace: undefined})}
                bookClicked={this.handleBookClicked}
                history={this.props.history}
                start={this.props.start}
                browserCSSMode={browserCSSMode}  // New for Oscar3
              />
            
              }
            </div>
          </div>
        </>}

        
        <div className="parkingmap-left">
          <div className="listbuildings-container">
            {WPMode === 'bays' && 
              <ParkingSearchWP key={this.state.searchCounter}
              searchInProgress={this.state.searchInProgress}
              msm_startdate={this.props.msm_startdate}
              availability_mode={this.props.availability_mode}
              address={this.props.address} 
              lat={this.props.lat}
              lng={this.props.lng} 
              startTime={this.props.startTime} 
              endTime={this.props.endTime} 
              onLocationSelected = {this.props.onLocationSelected}
              onTimeRangeSelected = {this.props.onTimeRangeSelected}
              onDateSelected={this.props.onDateSelected}
              desktopDisplay={this.props.desktopDisplay}
              onSearch={this.props.onSearch}
              
              onAvailibilitySelected={this.props.onAvailibilitySelected}
              //onOver24HrPicked={() => this.setState({over24hrs: true})}
              />}
              
              {/* Triggers Constructor on window transition @600+ to keep Desktop/Mobile in sync */}
              {(WPMode === 'spaces' && browserCSSMode === "desktop") && 
                <ParkingSearch2 key={this.state.searchCounter}
                WPDomain={WPDomain}
                WPMode={WPMode}
                browserCSSMode={browserCSSMode}  // New for Oscar3
                //searchInProgress={this.state.searchInProgress}
                searchMode={this.props.searchMode}
                msm_startdate={this.props.msm_startdate}
                availability_mode={this.props.availability_mode}
                address={this.props.address} 
                lat={this.props.lat}
                lng={this.props.lng} 
                startTime={this.props.startTime} 
                endTime={this.props.endTime} 
                sortBy={this.props.sortBy}
                
                includeAll={this.props.includeAll}
                onLocationSelected = {this.props.onLocationSelected}
                onTimeRangeSelected = {this.props.onTimeRangeSelected}
                onDateSelected={this.props.onDateSelected}
                desktopDisplay={this.props.desktopDisplay}
                onSearch={this.props.onSearch}
                
                onAvailibilitySelected={this.props.onAvailibilitySelected}
                onSortByChange={this.props.onSortByChange}
                onSearchModeChange={this.props.onSearchModeChange}
                //onOver24HrPicked={() => this.setState({over24hrs: true})}
                />
              }
            

            <div className="parkingmap-list">
                {divResults_Searching}
                {divResults_None}
                {arrResults}
            </div>
            
          </div>

          {showBayList ? 
          <div className="listbays-container open">
            <div className="parkingmap-list">
              {arrBays}
            </div>
          </div>:<div className="listbays-container"></div>

            }

        </div>
         

        <div className="parkingmap-right">
        
          {(WPMode === 'spaces' && isMobile) &&
            <div className="oscar-mobile-search">
              {<ParkingSearch2 key={this.state.searchCounter}
                WPDomain={WPDomain}
                WPMode={WPMode}
                browserCSSMode={browserCSSMode}  // New for Oscar3
                //searchInProgress={this.state.searchInProgress}
                searchMode={this.props.searchMode}

                msm_startdate={this.props.msm_startdate}
                availability_mode={this.props.availability_mode}
                address={this.props.address} 
                lat={this.props.lat}
                lng={this.props.lng} 
                startTime={this.props.startTime} 
                endTime={this.props.endTime} 
                sortBy={this.props.sortBy}
                
                includeAll={this.props.includeAll}
                onLocationSelected = {this.props.onLocationSelected}
                onTimeRangeSelected = {this.props.onTimeRangeSelected}
                onDateSelected={this.props.onDateSelected}
                desktopDisplay={this.props.desktopDisplay}
                onSearch={this.props.onSearch}
                onSearchMobile={this.handleSearchMobile}  // Used for mobile to trigger a search
                
                onAvailibilitySelected={this.props.onAvailibilitySelected}
                onSortByChange={this.props.onSortByChange}
                onSearchModeChange={this.props.onSearchModeChange}
                //onOver24HrPicked={() => this.setState({over24hrs: true})}
              />}
            </div>
          }

        
          {( (isDesktop || WPMode === 'bays') && (this.state.showDetails || this.state.showConfirmation)) ?

            <div className={detailsClass + " open"}>
              

              {(this.state.showDetails && this.state.selectedBay) && 
                <SpaceDetailsWP
                  key={this.state.selectedBay.uuid}
                  searchMode={searchMode}
                  spaceData={this.state.selectedBay} 
                  spaceType="parking_bay"
                  spaceData2={getSpaceData2(this.state.selectedBay, buildings, companies)}
                  spaceUser={getSpaceUser(this.state.selectedBay)}
                  spaceBuilding={getSpaceBuilding(this.state.selectedBay, buildings)}
                  spaceCompany={getSpaceCompany(this.state.selectedBay, companies)}
                  //duration={roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)))} 
                  closeModal={() => this.setState({showDetails: false, selectedBay: null})}
                  bookClicked={this.handleBookClicked}
                  history={this.props.history}
                  start={this.props.start}
                />
              }
              {(this.state.showDetails && !this.state.selectedBay) && 
                <SpaceDetails 
                  key={this.state.selectedSpace.uuid}
                  searchMode={searchMode}
                  //testBlocks={this.state.testBlocks}
                  start={this.props.start}
                  end={this.props.end}

                  spaceData={this.state.selectedSpace} 
                  spaceData2={this.state.selectedSpace2} 
                  spaceType="parking_space"
                  //duration={roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)))} 
                  closeModal={() => this.setState({showDetails: false, activeUuid: undefined, selectedSpace: undefined})}
                  bookClicked={this.handleBookClicked}
                  history={this.props.history}
                  browserCSSMode={browserCSSMode}  // New for Oscar3
                  
                />
              }
              {this.props.desktopDisplay && this.state.showConfirmation && 
                <BookingCheckoutPage 
                  browserCSSMode={browserCSSMode}
                  key={this.props.booking_msm_startdate + '-' + this.props.booking_msm_enddate}
                  history={this.props.history}
                  msm_startdate={this.props.booking_msm_startdate}
                  msm_enddate={this.props.booking_msm_enddate}
                  setMSMStartDate={this.props.setMSMStartDate}
                  setMSMEndDate={this.props.setMSMEndDate}

                  start={this.props.start}
                  end={this.props.end}
                  booking_monthly_startdate={this.props.booking_monthly_startdate}
                  booking_monthly_enddate={this.props.booking_monthly_enddate}

                  
                  searchMode={searchMode}
                  spaceData={this.state.selectedBay || this.state.selectedSpace} 
                  spaceData2={this.state.selectedSpace2} 
                  spaceType={getSpaceType(this.state.selectedBay)}
                  spaceBuilding={getSpaceBuilding(this.state.selectedBay, buildings)}
                  spaceCompany={getSpaceCompany(this.state.selectedBay, companies)}
                  //duration={this.props.start && roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)))} 
                  payCancel={this.handlePayCancel}
                  payClicked={this.handelPayClicked}
                  user={this.props.user}
                  authChange={this.props.authChange}
                />
              }
            </div>


            :
            <div className={detailsClass}>Details</div>
          } 


          {(isMobile && this.state.showDetails && !this.state.selectedBay && false) &&
            <div className={strContainerClass}>
              <SpaceDetails 
                key={this.state.selectedSpace.uuid}
                spaceData={this.state.selectedSpace} 
                spaceData2={this.state.selectedSpace2} 
                spaceType="parking_space"
                duration={roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)))} 
                closeModal={() => this.setState({showDetails: false, activeUuid: undefined, selectedSpace: undefined})}
                bookClicked={this.handleBookClicked}
                history={this.props.history}
                start={this.props.start}
                browserCSSMode={browserCSSMode}  // New for Oscar3
              />
            </div>
          }


          {(isMobile && this.state.showConfirmation) && 
            <FullPageModal open={this.state.showConfirmation} onClose={() => this.setState({showConfirmation: false})} >
              <BookingCheckoutPage 
                browserCSSMode={browserCSSMode}
                key={this.props.booking_msm_startdate + '-' + this.props.booking_msm_enddate}
                history={this.props.history}
                msm_startdate={this.props.booking_msm_startdate}
                msm_enddate={this.props.booking_msm_enddate}
                setMSMStartDate={this.props.setMSMStartDate}
                setMSMEndDate={this.props.setMSMEndDate}
                start={this.props.start}
                end={this.props.end}
                searchMode={searchMode}
                spaceData={this.state.selectedBay || this.state.selectedSpace} 
                spaceData2={this.state.selectedSpace2} 
                spaceType={getSpaceType(this.state.selectedBay)}
                spaceBuilding={getSpaceBuilding(this.state.selectedBay, buildings)}
                spaceCompany={getSpaceCompany(this.state.selectedBay, companies)}
                //duration={this.props.start && roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)))} 
                payCancel={this.handlePayCancel}
                payClicked={this.handelPayClicked}
                user={this.props.user}
                authChange={this.props.authChange}
              />
            </FullPageModal>
          }
      
        


        {/* VISIBLE for both mobile and desktop */}
        {<MyMapComponent
              WPDomain={WPDomain}
              WPMode={WPMode}
              searchMode={this.props.searchMode}
              onMarkerClick={this.props.desktopDisplay ? this.handleMarkerClickDesktop:this.handleMarkerClick}
              //onIdle = {() => {console.log("MyMapComponent - GMAP ready [desktop]")}}   // disable in Production
              //onMarkerHover={this.handleMarkerHover}
              center={this.props.center}
              //newCenter={this.state.selectedSpace ? {lat: this.state.selectedSpace.latitude, lng: this.state.selectedSpace.longitude} : undefined}
              zoom={this.props.zoom}
              data={this.state.mapPinData}
              activeUuid={this.state.activeUuid}
              selected={this.state.activeUuid}
              hoverUuid={this.state.hoverUuid}
              collectMapRef={this.getMapRef}
              handleZoomChanged={this.handleZoomChanged}
              handleMapMoved={this.handleMapMoved}
              //duration={this.props.end && roundToNearestFiveMinutes(Math.ceil(this.props.end.diff(this.props.start, 'minutes', true)))} 
              //start={this.props.start} 
        />}


        
        
        {(WPMode === 'spaces' && isMobile && this.state.activeUuid && this.state.selectedSpace && !this.state.showDetails) && 
        <div style={stylePreview} onClick={() => this.setState({showDetails: true})} >
            <ParkingSpacePreview 
              spaceData={this.state.selectedSpace} 
            />
        </div>}        
        

        </div>
        

        
      </div>
    );
  


 }
}



/* 

  baseIcon = `
  <svg class="damian" height="${height}px" viewBox="0 0 79 33" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <style type="text/css">
        @font-face {
          font-family: 'rubikregular';
          src: url(data:application/font-woff2;charset=utf-8;base64,${RubikWoff2}) format('woff2');
          font-weight: normal;
          font-style: normal;
        }
        text {font-weight: 700; font-size:18px;font-family: 'rubikregular';}
        tspan {font-weight: 500; font-size:12px;}
        tspan.dollar {font-weight:500; font-size:14px;}

      </style>
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Group-2" fill="${color}" fill-rule="nonzero">
              <path d="M1.79545455,0 L77.2045455,0 C78.1920455,0 79,0.9 79,2 L79,23.6 C79,24.7 78.1920455,25.6 77.2045455,25.6 L1.79545455,25.6 C0.807954545,25.6 0,24.7 0,23.6 L0,2 C0,0.9 0.807954545,0 1.79545455,0 Z" id="Rectangle-2"></path>
              <polygon id="Triangle" points="39.1 32.6 32 23 46.2 23"></polygon>
          </g>
          <path d="M20.685 14.966h-5.03V6.314c0-.338-.464-.437-.603-.128L9.027 19.594a.313.313 0 0 0 .287.44h5.033v8.652c0 .338.463.437.603.128l6.023-13.406a.313.313 0 0 0-.288-.442z" fill="#FFF" fill-rule="nonzero"/>

          <text id="pricing" x="39" y="18" fill="#ffffff" style="letter-spacing:0.05em;" text-anchor="middle">${strDisplay}</text>
          
      </g>
  </svg>
  `

  baseIcon2 = `
  <svg width="104" height="43" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <style type="text/css">
    @font-face {
      font-family: 'rubikregular';
      src: url(data:application/font-woff2;charset=utf-8;base64,${RubikWoff2}) format('woff2');
      font-weight: normal;
      font-style: normal;
    }
    text {font-weight: 700; font-size:18px;font-family: 'rubikregular';}
    tspan {font-weight: 500; font-size:12px;}
    tspan.dollar {font-weight:500; font-size:14px;}

  </style>

  <defs>
      <!--<filter x="-11.6%" y="-26.1%" width="123.1%" height="156.8%" filterUnits="objectBoundingBox" id="b"><feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/><feMorphology radius="1" in="SourceAlpha" result="shadowSpreadOuter2"/><feOffset dy="3" in="shadowSpreadOuter2" result="shadowOffsetOuter2"/><feGaussianBlur stdDeviation="1.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/><feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter3"/><feGaussianBlur stdDeviation="2" in="shadowOffsetOuter3" result="shadowBlurOuter3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" in="shadowBlurOuter3" result="shadowMatrixOuter3"/><feMerge><feMergeNode in="shadowMatrixOuter1"/><feMergeNode in="shadowMatrixOuter2"/><feMergeNode in="shadowMatrixOuter3"/></feMerge></filter><path d="M2 0h104a2 2 0 0 1 2 2v40a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" id="a"/>-->
    </defs>
    <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1" >
      <g transform="translate(-1 -1)">
        <g fill="${color}">
          <path d="M0 0h108v44H0z"/>
        </g>

      </g>
      <path d="M20.685 14.966h-5.03V6.314c0-.338-.464-.437-.603-.128L9.027 19.594a.313.313 0 0 0 .287.44h5.033v8.652c0 .338.463.437.603.128l6.023-13.406a.313.313 0 0 0-.288-.442z" fill="#FFF" fill-rule="nonzero"/>
      <text id="pricing" x="39" y="18" fill="#ffffff" style="letter-spacing:0.05em;" text-anchor="middle">${strDisplay}</text>

      </g>
    </svg>`;


*/