import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

import {
    Grid,
    IconButton,
    Typography,
    Button,
} from '@material-ui/core/';

import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

import { 
    deleteAvailability,
    getListing
} from '../api/Oscar-api.js';

import LoadingModal from '../components/LoadingModal';
import {
    BookingDayHeader,
    ParseDateTimeParameters,
    TwelveHourTime,
} from '../utils/TimeFormats';


const styles = {
    addButton: {
        backgroundColor: 'white',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
    },
    availabilitiesContainer: {
        marginTop: '24px',
    },
    availabilitiesPreviewContainer: {
        borderTop: '1px solid #efefef',
        paddingTop: '4px',
        paddingBottom: '8px',
        flexWrap: 'nowrap'
    },
}


function describeAvailability(availability) {
    // Check if all day keys are set to true
    if (availability.day_sunday &&
        availability.day_monday && 
        availability.day_tuesday &&
        availability.day_wednesday &&
        availability.day_thursday &&
        availability.day_friday &&
        availability.day_saturday) {
        // This is either a 24/7 or single availability
        if (availability.end.diff(availability.start, 'years') > 90) {
            // This is a 24/7 availability (available all days for over 90 years)
            return(
                <div>
                    <Typography variant="body1">
                        24/7 Availability
                    </Typography>
                    <Typography variant="caption">
                        12:00AM - 11:59PM
                    </Typography>
                </div>
            )
        } else {
            // This is a single availability (available all days for a fixed period of time)
            return(
                <div>
                    <Typography variant="body1">
                        {`${availability.start.format(BookingDayHeader)} - ${availability.end.format(BookingDayHeader)} ${availability.end.year()}`}
                    </Typography>
                    <Typography variant="caption">
                        {`${availability.start.format(TwelveHourTime)} - ${availability.end.format(TwelveHourTime)}`}
                    </Typography>
                </div>
            )
        }
    } else {
        // This is a weekly or weekly recurring availability
        if (availability.end.diff(availability.start, 'years') > 1) {
            // This is a recurring weekly availability (available on certain days for over 90 years)
            return(
                <div>
                    <Typography variant="body1">
                        Recurring {getBookingDays(availability)}
                    </Typography>
                    <Typography variant="caption">
                        {`${availability.start.format(TwelveHourTime)} - ${availability.end.format(TwelveHourTime)}`}
                    </Typography>
                </div>
            )
        } else {
            // This is a weekly availability (available on certain days for a fixed period of time)
            return(
                <div>
                    <Typography variant="body1">
                        {getBookingDays(availability)}
                    </Typography>
                    <Typography variant="caption">
                        {`${availability.start.format(TwelveHourTime)} - ${availability.end.format(TwelveHourTime)}`}
                    </Typography>
                </div>
            )
        }
    }
}

function getBookingDays(availability) {
    let availableDays = []
    if (availability.day_sunday) availableDays.push('Sun')
    if (availability.day_monday) availableDays.push('Mon')
    if (availability.day_tuesday) availableDays.push('Tue')
    if (availability.day_wednesday) availableDays.push('Wed')
    if (availability.day_thursday) availableDays.push('Thu')
    if (availability.day_friday) availableDays.push('Fri')
    if (availability.day_saturday) availableDays.push('Sat')

    return availableDays.join(', ')
}


export default class Availabilities extends Component {
    state={
        loading: true, 
        listing: undefined,
        availabilities: []
    }

    componentWillMount() {
        getListing(this.props.match.params.uuid).then(response => {
            response.data.availabilityrecurring_set.forEach(avail => {
                avail.start = moment(`${avail.start_date} ${avail.start_time}`, ParseDateTimeParameters)
                avail.end = moment(`${avail.cease_date} ${avail.cease_time}`, ParseDateTimeParameters)
            })
            
            this.setState({
                loading: false,
                availabilities: response.data.availabilityrecurring_set,
                listing: response.data
            })
        })
    }

    handleDelete = (availability) => {
        //console.log(availability.uuid)
        this.setState({
            loading: true
        })

        deleteAvailability(this.props.match.params.uuid, availability.uuid).then(response => {
            //console.log(response)

            const newAvails = this.state.availabilities.filter(item => item !== availability)
            this.setState({
                loading: false,
                availabilities: newAvails
            })

        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return(
            <div style={{height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
                <LoadingModal open={this.state.loading} />

                <IconButton className="leftCornerButton"
                component={Link} to="/Calendar">
                    <CloseIcon />
                </IconButton>
            
                <div className="mainContent topPadded"
                style={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                
                    <Typography gutterBottom variant="display1" color="primary">
                        Availabilities
                    </Typography>
                    
                    {this.state.loading ? null : <div style={{flexGrow: 1, flexBasis: '1px', overflowY: 'auto'}}>
                    {this.state.availabilities.length > 0 ? 
                        <div>
                            <Typography variant="body1">
                                Here are your availabilities for:
                            </Typography>

                            <Typography variant="body1">
                                {this.state.listing.type} on {this.state.listing.street_name}
                            </Typography>

                            <div style={styles.availabilitiesContainer}>
                                {this.state.availabilities.map((avail, i) => {
                                    return(
                                    <Grid style={styles.availabilitiesPreviewContainer} key={i} container justify="space-between" alignItems="center">
                                        <Grid item>
                                            {describeAvailability(avail)}

                                        </Grid>
                                        <Grid item>
                                            <IconButton component={Link} 
                                                to={`/ChangeAvailabilities/${this.props.match.params.uuid}/${avail.uuid}`}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => this.handleDelete(avail)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    )
                                })}
                            </div>
                        </div>
                        : <div>
                            <Typography variant="body1">
                                No availabilities have been added for {this.state.listing.type} on {this.state.listing.street_name}
                            </Typography>
                        </div>}
                            
                            <Grid style={styles.availabilitiesPreviewContainer}></Grid>
                    </div>}

                                                                    
                    <Button  fullWidth variant="contained" color="primary" 
                    style={{margin: '16px 0px'}}
                    id="add_availability"
                    component={Link} to={`/ChangeAvailabilities/${this.props.match.params.uuid}`}>
                        Add another availability
                    </Button>
                </div>
            </div>
        )
    }
}