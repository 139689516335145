/* 
SmartImage component
*/

import React, { useState, useLayoutEffect, useRef, Suspense } from 'react';
import useResizeObserver from '@react-hook/resize-observer'

export default function SmartImage({ externalUrl=null, imageUrl=null }) {
  const [size, setSize] = useState();
  const ref = useRef(null)
  const strClass = 'component-smartimage';

  var timerId;
  var renderCounter=0;
  
  useLayoutEffect(() => {
    if (ref.current){
      setSize(ref.current.getBoundingClientRect());
    }

    return () => { ref.current = false; }
  },[ref]);

  useResizeObserver(ref, (entry) => {
    let waitTime = 250;

    if (timerId){
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      if (ref.current){
        setSize(entry.contentRect);

      }
      timerId = null;
    }, waitTime);
  });


  if (!size || size.width === 0 || size.width === 0){
    //console.log(`0x0, return null #${renderCounter}`);
    return <div className={strClass} ref={ref}></div>;
  }
  
  //console.log(`SmartImage:RENDER:${size.width}x${size.height} render#${renderCounter}`);
  return (
    <div className={strClass} ref={ref}>
      {externalUrl && <img src={externalUrl.replace('{width}',size.width).replace('{height}',size.height)}/>}
      {imageUrl && <img src={`${imageUrl}?tr=w-${size.width},h-${size.height},fo-auto`}/> }
    </div>

  );
}