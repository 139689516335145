import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import moment from 'moment';

import {getTrips} from '../api/Oscar-api';
import {
    BookingsRangeDisplayFormat,
    ParseDateTimeParameters,
    WPMonthlyBookingsDisplayFormat
} from '../utils/TimeFormats';

import ENUMS from '../api/ENUMS.json';

import {
    Grid,
    Hidden,
    IconButton,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconNext from '@material-ui/icons/KeyboardArrowRight';

import LoadingModal from '../components/LoadingModal.js';

const styles = {
    heading: {
        color: '#3cb9c4',
        marginTop: '20px'
    },
    subHeading: {
        color:'grey'
    },
    arrowIcon: {
        color:'black',
        position: 'relative',
        top: '7px'
    },
    bookingItemContainer: {
      backgroundColor:'#ffeeee',  
      borderTop: '1px solid #efefef',
        paddingTop: '10px',
        paddingBottom: '10px',
        flexWrap: 'nowrap'
    },
    bookingPrice: {
        flexShrink: '0',
        marginLeft: '8px'
    
    }
}

function BookingItem(props) {

  // parking_bay
  if (props.booking.parking_bay){
    const spaceData = props.booking.parking_bay;
    const building = spaceData.building;
    
    const divBay = spaceData.bay_marking ? <div className="baymarking">Bay #{spaceData.bay_marking}</div>:null;

    let strClassName = "li-booking noselect";

    let divRate;
    if (props.booking.refunded){
      divRate = (<div className="rate">Refunded</div>);
      strClassName+=" cancelled";
    } else if (props.booking.cancelled){
      divRate = (<div className="rate">Cancelled</div>);
      strClassName+=" cancelled";
    } else {


      if (props.booking.cease_date && moment(props.booking.cease_date).diff(moment(props.booking.start_date), 'days') <= 30-1){
        divRate = (<div className="rate">${props.booking.headline_amount && props.booking.headline_amount.toFixed(2)}<IconNext style={{verticalAlign: 'bottom'}}/></div>);
      } else {
        divRate = (<div className="rate">${props.booking.headline_amount && props.booking.headline_amount.toFixed(2)}/month<IconNext style={{verticalAlign: 'bottom'}}/></div>);
      }
      
    }

    
    let divDateRange;
    if (props.booking.cease_date){
      divDateRange = <div className="daterange">{props.booking.start.format(WPMonthlyBookingsDisplayFormat)} - {props.booking.end.format(WPMonthlyBookingsDisplayFormat)} (Ref#{props.booking.reference})</div>;
    } else {
      divDateRange = <div className="daterange">{props.booking.start.format(WPMonthlyBookingsDisplayFormat)} - ONGOING (Ref#{props.booking.reference})</div>;
    }

    let divApproval;
    if (props.booking.refunded || props.booking.cancelled){
  
    } else if (props.booking.approval_status == 3 || props.booking.approval_status == 4){
      divApproval = <div className="approval-declined">{ENUMS.approval_status[props.booking.approval_status || 0]}</div>;
      
      if (!props.booking.refunded && !props.booking.cancelled){
        strClassName+=" cancelled"; // Already done above by divRate
      }
      
    } else {
      divApproval = <div className="approval-confirmed">{ENUMS.approval_status[props.booking.approval_status || 0]}</div>;
    }
  

    return(
      <div key={props.booking.reference} className={strClassName} onClick={props.booking.approval_status <= 2 && !props.booking.cancelled && !props.booking.refunded ? props.onBookings:null}>
        <div className="details">
          {divDateRange}
          <h3><span>{building.building_name}</span> at {building.address}, {building.suburb}</h3>
          {divApproval}
          {divBay}
        </div>
        {divRate}

      </div>
    );

  }

  // parking_space
  let str_end_date;
  if (props.booking.end){
    str_end_date = props.booking.end.format(BookingsRangeDisplayFormat)
  } else {
    str_end_date = "Ongoing";
  }
//console.log(str_end_date);
//console.log(props.booking);
  
  let strClassName = "li-booking noselect";

  let divRate;
  if (props.booking.refunded){
    divRate = (<div className="rate">Refunded</div>);
    strClassName+=" cancelled";
  } else if (props.booking.cancelled){
    divRate = (<div className="rate">Cancelled</div>);
    strClassName+=" cancelled";
  } else {


    if (props.booking.booking_type === 1){
      divRate = (<div className="rate">${props.booking.headline_amount && props.booking.headline_amount.toFixed(2)}<IconNext style={{verticalAlign: 'bottom'}}/></div>);
    } else {
      divRate = (<div className="rate">${props.booking.headline_amount && props.booking.headline_amount.toFixed(2)}/month<IconNext style={{verticalAlign: 'bottom'}}/></div>);
    }
    
  }


  let divApproval;
  if (props.booking.refunded || props.booking.cancelled){

  } else if (props.booking.approval_status == 3 || props.booking.approval_status == 4){
    divApproval = <div className="approval-declined">{ENUMS.approval_status[props.booking.approval_status || 0]}</div>;
    
    if (!props.booking.refunded && !props.booking.cancelled){
      strClassName+=" cancelled"; // Already done above by divRate
    }
    
  } else {
    divApproval = <div className="approval-confirmed">{ENUMS.approval_status[props.booking.approval_status || 0]}</div>;
  }
  
//console.log(props.booking);
  //className="li-booking noselect" 

  let strSpaceType = props.booking.parking_space.type.replace('Parking-lot','Parking Lot');
  
  
  return(
    <div key={props.booking.reference} className={strClassName} onClick={props.booking.approval_status <= 2 && !props.booking.cancelled && !props.booking.refunded ? props.onBookings:null}>
      <div className="details">
        {props.booking.booking_type == 2 && <div className="daterange">{props.booking.start.format(WPMonthlyBookingsDisplayFormat)} - {str_end_date}</div>}
        {props.booking.booking_type != 2 && <div className="daterange">{props.booking.start.format(BookingsRangeDisplayFormat)} - {str_end_date} {props.pastBooking && props.booking.end && props.booking.end.year()}</div>}

        <h3>[{props.booking.reference}] <span>{strSpaceType}</span> on {props.booking.parking_space.street_name}</h3>
        {divApproval}
        {props.booking.access_code && <p className="access_code">{props.booking.access_code}</p>}
      </div>
      {props.booking.approval_status <= 2 && props.booking.headline_amount && divRate}
      {props.booking.approval_status >= 3 && (props.booking.refunded || props.booking.cancelled) && divRate}
    </div>

  );
  
}

/* 
    <Grid onClick={props.booking.approval_status <= 2 ? props.onBookings:null} style={styles.bookingItemContainer} container justify="space-between" alignItems="center" className="li-booking noselect">
            <Grid item>
                {props.booking.booking_type == 2 && <Typography variant="caption">{props.booking.start.format(WPMonthlyBookingsDisplayFormat)} - {str_end_date}</Typography>}
                {props.booking.booking_type != 2 && <Typography variant="caption">{props.booking.start.format(BookingsRangeDisplayFormat)} - {str_end_date} {props.pastBooking && props.booking.end && props.booking.end.year()}</Typography>}

                <Typography variant="body1">
                    [{props.booking.reference}] {strSpaceType} on {props.booking.parking_space.street_name} 
                </Typography>
                {divApproval}
                {props.booking.access_code && <p className="access_code">{props.booking.access_code}</p>}
            </Grid>
            <Grid item style={styles.bookingPrice}>
              {props.booking.approval_status <= 2 &&
                <Typography variant="body1" color="primary">
                  {strAmount}
                  <IconNext style={{verticalAlign: 'bottom'}}/>
                  </Typography>
              }
            </Grid>
        </Grid>

*/

export default class Bookings extends Component {
    state = {
        loading: true, 
        upcomingBookings: [], 
        pastBookings: []
    }

    //TODO: DAMIAN = handle 401 errors here
    componentWillMount() {
        getTrips().then(response => {
            //console.log(response.data)

            let pastBookings = []
            let upcomingBookings = []
            const now = moment()

            for (let i = 0; i < response.data.length; i++) {
                const booking = response.data[i]

                if (booking.parking_bay){
                  booking.start = moment(`${booking.start_date}`, ParseDateTimeParameters)
                  if (booking.cease_date) {
                    booking.end = moment(`${booking.cease_date}`, ParseDateTimeParameters)
                  }

                  if (booking.start.isAfter(now) && !booking.cancelled) {
                    upcomingBookings.push(booking);
                  } else {
                    pastBookings.push(booking)

                  }
                  
                } else {

                  booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters)
                  
                  if (booking.cease_date) {
                    booking.end = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters)
                  }
                  
                  //booking.end && booking.end.isAfter(now) && !booking.cancelled
                  if ((!booking.end || booking.end.isAfter(now)) && !booking.cancelled) {
                      upcomingBookings.push(booking)
                  } else {
                      pastBookings.push(booking)
                  }
  
                }


            }

            //pastBookings.sort((a, b) => a.end.isBefore(b.end) ? 1 : -1)
            pastBookings.sort((a, b) => a.start.isBefore(b.start) ? 1 : -1)

            this.setState({
                loading: false,
                upcomingBookings: upcomingBookings,
                pastBookings: pastBookings,
            })
        })
    }

    render() {
        return(
            <div className="wpadmin-content top-padded-mobile" style={{paddingBottom:'80px'}}>
                <LoadingModal open={this.state.loading} />

                <>
                  <Hidden mdUp>
                    <IconButton className="leftCornerButton"
                    component={Link} to="/search">
                        <CloseIcon />
                    </IconButton>
                  </Hidden>
                    <h1>Bookings</h1>
                    
                    {this.state.loading &&
                      <h2>Loading...</h2>
                    }
                    {!this.state.loading &&
                    this.state.upcomingBookings.length === 0 && 
                    this.state.pastBookings.length === 0 &&
                        <h2>You haven't made any bookings</h2>
                    }

                    {this.state.upcomingBookings !== undefined && this.state.upcomingBookings.length > 0 &&
                    <div style={{marginTop: '24px'}}>
                        {this.state.upcomingBookings.map((booking, i) => {
                            return(
                                <BookingItem key={i} booking={booking} onBookings={() => this.props.history.push(`/BookingDetails/${booking.reference}`)}/>
                            )
                        })}
                        
                    </div>}

                    {this.state.pastBookings !== undefined && this.state.pastBookings.length > 0 && 
                    <div>
                        <Typography style={{marginTop: '32px', marginBottom: '24px'}} variant="headline">
                            Past Bookings
                        </Typography>
                        {this.state.pastBookings.map((booking, i) => {
                            return(
                                <BookingItem key={i} pastBooking={true} booking={booking} onBookings={() => this.props.history.push(`/BookingDetails/${booking.reference}`)}/>
                            )
                        })}
                        
                    </div>}
                </>
            </div>
        )
    }
}