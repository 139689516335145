/* */
function  getSpaceType(spaceData){
  let spaceType = "parking_space";

  if (spaceData){
    if (spaceData.building){
      spaceType = "parking_bay";
    } else if (spaceData.company){
      spaceType = "building";
    }
  
  }

  return spaceType;
}

function getSpaceData2(spaceData, buildings, companies, users){

  if (!spaceData)
    return null;

  let building;
  let company;
  let user;

  if (buildings){
    building = buildings.filter(b => b.uuid === spaceData.building.uuid)[0];
  }
  if (companies){
    company = companies.filter(c => c.uuid === building.company.uuid)[0];
  }
  if (true || users){
    if (spaceData.user){
      //console.log("spaceData has user:",spaceData.user);
      user = spaceData.user;
    } else {

      //console.log("spaceData has no user, default to master_user:",company.master_user);
      user = company.master_user;
    }
    //user = users.filter(u => u.id === building.company)[0];
  }
  

  return {building, company, user};
}



function getSpaceBuilding(spaceData,buildings){
  let spaceBuilding;
  if (spaceData && buildings){
    spaceBuilding = buildings.filter(b => b.uuid === spaceData.building.uuid)[0] 
  
  }

  //console.log("returning:",spaceBuilding);
  return spaceBuilding;
}

function getSpaceCompany(spaceData,companies){
  let spaceCompany;

  if (spaceData && companies){
    spaceCompany = companies.filter(b => b.id === spaceData.company)[0]  
  
  }

  return spaceCompany;
}



/* */
function  getSpaceUser(spaceData){
  let spaceUser;


  if (spaceData){
    if (spaceData.user){
      return spaceData.user;
    }
  
  }

  return spaceUser;
}


export {
 getSpaceType,
 getSpaceData2,
 getSpaceBuilding,
 getSpaceCompany,
 getSpaceUser
}
