import React, {Component} from 'react';

import AppBar from './AppBar.js';
import NavigationMenu from './NavigationMenu.js';

export default class MainNav extends Component {
    constructor(props) {
        super()

        this.state = {
            drawOpen: false,
        }

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    }

    handleDrawerToggle() {
        this.setState({ drawClosed: !this.state.drawClosed })
    }
    
    render() {
    return (
        <>
            <AppBar user={this.props.user} hasNotifications={this.props.hasNotifications} toggleHandler={this.handleDrawerToggle} menuOnly={this.props.menuOnly}/>
            <NavigationMenu 
                isOpen={this.state.drawClosed} 
                toggleDrawer={this.handleDrawerToggle} 
                user={this.props.user}
            />
        </>
    );
    }
}