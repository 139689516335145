import React, { Component } from 'react';

import moment from 'moment';
import {
    ParseDateTimeParameters,
} from '../utils/TimeFormats';
import {getQuery} from '../api/Oscar-api';

import {
  Button,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography
} from '@material-ui/core';
import IconPlus from '@material-ui/icons/AddCircle';



import CircularProgress from '@material-ui/core/CircularProgress';

//import ChatIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import NextIcon from '@material-ui/icons/ArrowForwardIos';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';

import {getImagePreviewForSpace, getImagePreviewUrlForBuilding} from '../utils/GetImageListForSpace.js';

import ENUMS from '../api/ENUMS.json';

import '../styles/DataGrid.css';
import { Link } from 'react-router-dom';


const SearchBox = (props) => {

  const showRefresh = props.newData || props.showRefresh; // TEMP
  return (
    <div className="filter">
      <input id="search-text" onChange={props.onChange} onKeyDown={props.onKeyDown} value={props.value} placeholder={props.placeholder} className={"form-control entity-editor__control "} maxLength={50} autoComplete="off" autoCorrect="off" autoCapitalize="words" spellCheck="false"/>
      <button onClick={props.onClickSearch}><SearchIcon style={{color:"#1AA5A2"}}/></button>
      {showRefresh && <button onClick={props.onClickRefresh} id="cp-btn-refresh"><RefreshIcon style={{color:"#1AA5A2"}}/></button>}
    </div>
  );
}




function numberWithCommas(x) {
  return x.toString();
  //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

// TEMP: Move this to the server

//cp_bookings_msm Ref, Driver, contact number, rego#,  Unit number - spot owner name (e.g. Unit 11 - Anita), contact number, start time, end time, bay #, type, total paid, meriton earnings
const queryTable = {
    "cp_buildings": {  mode:"flex", title:"Buildings", parser:getItem_Buildings },
    "cp_parkingbays": { 
      mode:"flex",
      title:"Parking Bays",
      parser:getItem_ParkingBays,
      addNew:true
    },  
    "cp_bookings": { 
      mode:"table", 
      title:"Bookings", 
      parser:getItem_Bookings,
      searchBox:true,
      searchBoxPlaceholder:'Driver name or Rego',
      headerFields: [
        {id:"ref",display:"Ref", sortable:true, width:100},
        {id:"driver",display:"Driver"},
        {id:"rego",display:"Rego #",width:100},
        {id:"start",display:"Start Time", sortable:true,width:300},
        {id:"end",display:"End Time", sortable:true,width:300},
        {id:"location",display:"Location",width:220},
        {id:"bay",display:"Bay #",width:100},
        {id:"type",display:"Type",width:100},
        {id:"total",display:"Total Paid",width:100},
        //{id:"msg",display:""},  //message
      ] 
    },
    "wp_bookings": { 
      mode:"table",
      exportableCSV:true, 
      title:"Bookings", 
      parser:getItem_BookingsWP,
      searchBox:true,
      searchBoxPlaceholder:'Reference, Driver name or Rego',      
      headerFields: [
        {id:"ref",display:"Ref", sortable:true, width:100},
        {id:"created",display:"Created", sortable:true, width:120},
        {id:"driver",display:"Driver"},
        {id:"phone",display:"Phone",width:140},
        {id:"rego",display:"Rego#",width:120},
        {id:"owner",display:"Owner",width:120},
        {id:"building",display:"Building",sortable:true, width:120},
        {id:"unit",display:"Unit#",width:100},
        {id:"start",display:"Start Date", sortable:true,width:150},
        {id:"end",display:"End Date", width:150},
        {id:"bay",display:"Bay #",width:100},
        {id:"type",display:"Type",width:100},
        {id:"total",display:"Rate",width:100},
        //{id:"earn",display:"After Commissions",width:100},        
        //{id:"msg",display:""},  //message
      ] 
    },
    "wp_users": { 
      mode:"table", 
      exportableCSV:true, 
      title:"Users", 
      parser:getItem_UsersWP,
      searchBox:true,
      searchBoxPlaceholder:'Email, Name or Unit#',
      headerFields: [
        {id:"created",display:"Created", sortable:true, width:120},
        {id:"email",display:"Email",sortable:true},
        {id:"first_name",display:"First Name", width:200},
        {id:"last_name",display:"Last Name", width:200},        
        {id:"phone",display:"Phone",width:180},
        {id:"building",display:"Building",sortable:true, width:200},
        {id:"tenant_id",display:"Unit#",width:100},
        {id:"lease_end_date",display:"Lease End",width:100},
        //{id:"msg",display:""},  //message
      ] 
    },
    "wp_parkingbays": { 
      mode:"table", 
      exportableCSV:true, 
      title:"Parking Bays", 
      parser:getItem_ParkingBaysWP,
      searchBox:true,
      searchBoxPlaceholder:'Owner email, Building name, Unit# or Bay#',      
      createNew:"",
      headerFields: [
        {id:"status",display:"",width:40},
        {id:"created",display:"Created", sortable:true, width:120},
        {id:"phone",display:"Phone",width:140},
        {id:"email",display:"Email",width:140},        
        {id:"first_name",display:"First Name", width:200},
        {id:"last_name",display:"Last Name", width:200},        
        {id:"building",display:"Building",sortable:true, width:120},
        {id:"tenant_id",display:"Unit#",width:100},
        {id:"bay",display:"Bay #",width:100},
        {id:"lease_end_date",display:"Lease End",width:100},
        {id:"rate_monthly",display:"Monthly"},
        {id:"rate_monthly_booked",display:"Booked"},
        {id:"group",display:"Group",width:80},
        {id:"availability_type",display:"Avail.",width:100},
        //{id:"msg",display:""},  //message
      ] 
    },
    "wp_buildings": {
      mode:"flex",
      title:"Buildings",
      parser:getItem_BuildingsWP,
      searchBox:true,
      searchBoxPlaceholder:'Enter a building name'
     },
     "cp_vbookings": { 
      mode:"table", 
      title:"VP - Bookings", 
      parser:getItem_VisitorBookings,
      searchBox:true,
      searchBoxPlaceholder:'Driver name or Rego',
      headerFields: [
        {id:"ref",display:"Ref", sortable:true, width:100},
        {id:"full_name",display:"Driver"},
        {id:"mobile_number",display:"Mobile#"},
        {id:"unit_number",display:"Unit #",width:100},
        {id:"vehicle_rego",display:"Rego #",width:100},
        {id:"bay_label",display:"Bay #",width:100},
        {id:"building_label",display:"Building",width:220},
        {id:"min_datetime",display:"MinDT (Created)", sortable:true,width:300},
        {id:"max_datetime",display:"MaxDT (Max exit)", sortable:true,width:300},
        {id:"started",display:"Started", sortable:true,width:300},
        {id:"finished",display:"Finished", sortable:true,width:300},

        //{id:"msg",display:""},  //message
      ] 
    },

    "vp_visitorbays": { 
      mode:"flex", 
      exportableCSV:false, 
      title:"VP - Visitor Bays", 
      parser:getItem_VisitorBaysBollards, //getItem_ParkingBaysWP,
      searchBox:true,
      searchBoxPlaceholder:'Search options here',      
      createNew:"",
      headerFields: [
        {id:"status",display:"",width:40},
        {id:"created",display:"Created", sortable:true, width:120},
        {id:"phone",display:"Phone",width:140},
        {id:"email",display:"Email",width:140},        
        {id:"first_name",display:"First Name", width:200},
        {id:"last_name",display:"Last Name", width:200},        
        {id:"building",display:"Building",sortable:true, width:120},
        {id:"tenant_id",display:"Unit#",width:100},
        {id:"bay",display:"Bay #",width:100},
        {id:"lease_end_date",display:"Lease End",width:100},
        {id:"rate_monthly",display:"Monthly"},
        {id:"rate_monthly_booked",display:"Booked"},
        {id:"group",display:"Group",width:80},
        {id:"availability_type",display:"Avail.",width:100},
        //{id:"msg",display:""},  //message
      ] 
    },



    //"cp_transactions": { mode:"table", title:"Transactions", parser:getItem_Transactions, headerFields: ["TXID", "Ref"] },
    //"cp_dashboard": { mode:"table", title:"Dashboard", parser:getItem_Dashboard, headerFields: ["DASH", "Ref"] }
};

//Ref, Driver, contact number, rego#, 
// Unit number - spot owner name (e.g. Unit 11 - Anita), contact number, start time, end time, bay #, type, total paid, meriton earnings 

function getItem_Bookings(that,item) {
  let bookingStart = moment(`${item.start_date} ${item.start_time}`, ParseDateTimeParameters);
  let bookingEnd = moment(`${item.cease_date} ${item.cease_time}`, ParseDateTimeParameters);
  
  let strBookingStart;
  //let strType = "Casual";

  //let strTotalPaid = `$${item.owner_earnings.toFixed(2)}`;
  let strTotalPaid = `$${item.headline_amount.toFixed(2)}`;
  let strType = ENUMS.booking_types[item.booking_type];

/*  class ENUM_Booking_Approval_Codes(models.IntegerChoices):
  Waiting = 1   # DEFAULT
  Approved = 2
  DeclinedByOwner = 3
  CancelledByDriver = 4
*/
  let strTRClass = '';

  if (item.approval_status == 1){
    strType = <span className='dg-new-request'>REQUEST</span>;
    strTotalPaid = <span className='dg-accept-request'>VIEW</span>; // onClick={that.onClick_Item.bind(that,item.reference,'booking')}
  } else if (item.approval_status == 3){
    strType = "Declined";
    strTotalPaid = "-";
    strTRClass = 'dg-row-grey';

  } else if (item.approval_status == 4){
    strType = "Cancelled";
    strTotalPaid = "-";
    strTRClass = 'dg-row-grey';

  }

  if (item.start_time){
    strBookingStart = bookingStart.format("DD-MMM-YYYY [at] hh:mmA");
  } else {
    strBookingStart = moment(item.start_date).format("DD-MMM-YYYY");
    //strType = "Monthly";
  }
  
  let strBookingEnd;
  if (item.cease_date){
    if (!bookingEnd.isAfter(bookingStart,'day')){
      strBookingEnd = bookingEnd.format("hh:mmA");
    } else {

      if (item.start_time){
        strBookingEnd = bookingEnd.format("h:mmA DD-MMM");
      } else {
        strBookingEnd = bookingEnd.format("DD-MM-YYYY");      // ie. Monthly
      }
      
    }
    
  } else {
    strBookingEnd = "Ongoing";
  }
  
  let strBayMarking = "-";
  let strLocation = item.parking_space_name || '-';

    return (<tr key={item.id} onClick={that.onClick_Item.bind(that,item.reference,'booking')} className={strTRClass}>
        <td>{item.reference}</td>
        <td>{item.user.first_name}</td>
        <td>{item.vehicle.registration}</td>
        <td>{strBookingStart}</td>
        <td>{strBookingEnd}</td>
        <td>{strLocation}</td>
        <td>{strBayMarking}</td>
        <td>{strType}</td>
        <td>{strTotalPaid}</td>
    </tr>);
    //<td><ChatIcon style={{color:"#cccccc"}}/></td>
}

// Meriton Bookings
function getItem_BookingsWP(that,item) {
  let bookingStart = moment(`${item.start_date} ${item.start_time}`, ParseDateTimeParameters);
  let bookingEnd = moment(`${item.cease_date} ${item.cease_time}`, ParseDateTimeParameters);
  
  let strCreateDate = moment(item.create_date).format("DD-MMM-YYYY");

  let strBookingStart;
  let strType = "Casual";
  if (item.start_time){
    strBookingStart = bookingStart.format("DD-MMM-YYYY h:mmA");
  } else {
    strBookingStart = moment(item.start_date).format("DD-MMM-YYYY");
    strType = "Monthly";
  }
  
  let strBookingEnd;
  if (item.cease_date){
    strBookingEnd = bookingEnd.format("DD-MMM-YYYY");
  } else {
    strBookingEnd = "Ongoing";
  }
  
  //console.log(item);

  let strBayMarking = (item.parking_bay && item.parking_bay.bay_marking) || "-";
  let strBuildingName = (item.parking_bay && item.parking_bay.building && item.parking_bay.building.building_name) || "-";

  let strPaid = `${item.headline_amount.toFixed(2)}`;   //`${item.driver_paid.toFixed(2)}`;
  let strEarned = `${item.owner_earnings.toFixed(2)}`;

  if (item.cancelled){
    //strBuildingName = "Cancelled";
    strPaid = '-';
    strEarned = '-';
  }
  
    return (<tr key={item.id} onClick={that.onClick_Item.bind(that,item.reference,'booking')}>
        <td>{item.reference}</td>
        <td>{strCreateDate}</td>
        <td>{item.user.first_name}</td>
        <td>{item.user.telephone_number}</td>
        <td>{item.vehicle.registration}</td>
        <td>{item.user2.first_name}</td>
        <td>{strBuildingName}</td>
        <td>{item.user.tenant_id}</td>
        <td>{strBookingStart}</td>
        <td>{strBookingEnd}</td>
        <td>{strBayMarking}</td>
        <td>{strType}</td>
        <td>{strPaid}</td>
    </tr>);
    //<td>{strEarned}</td>
    //<td><ChatIcon style={{color:"#cccccc"}}/></td>
}

function getItem_VisitorBookings(that,item) {
  let bookingStart = moment(`${item.start_date} ${item.start_time}`, ParseDateTimeParameters);
  let bookingEnd = moment(`${item.cease_date} ${item.cease_time}`, ParseDateTimeParameters);
  
  let strBookingStart;
  //let strType = "Casual";

  let strTotalPaid = `x`; // `$${item.owner_earnings.toFixed(2)}`;
  let strType = ENUMS.booking_types[item.booking_type];

/*  class ENUM_Booking_Approval_Codes(models.IntegerChoices):
  Waiting = 1   # DEFAULT
  Approved = 2
  DeclinedByOwner = 3
  CancelledByDriver = 4
*/
  let strTRClass = '';

  /*if (item.approval_status == 1){
    strType = <span className='dg-new-request'>REQUEST</span>;
    strTotalPaid = <span className='dg-accept-request'>VIEW</span>; // onClick={that.onClick_Item.bind(that,item.reference,'booking')}
  } else if (item.approval_status == 3){
    strType = "Declined";
    strTotalPaid = "-";
    strTRClass = 'dg-row-grey';

  } else if (item.approval_status == 4){
    strType = "Cancelled";
    strTotalPaid = "-";
    strTRClass = 'dg-row-grey';

  }*/

  /*if (item.start_time){
    strBookingStart = bookingStart.format("DD-MMM-YYYY [at] hh:mmA");
  } else {
    strBookingStart = moment(item.start_date).format("DD-MMM-YYYY");
    //strType = "Monthly";
  }
  
  let strBookingEnd;
  if (item.cease_date){
    if (!bookingEnd.isAfter(bookingStart,'day')){
      strBookingEnd = bookingEnd.format("hh:mmA");
    } else {
      strBookingEnd = bookingEnd.format("h:mmA DD-MMM");
    }
    
  } else {
    strBookingEnd = "Ongoing";
  }*/

  let strMinDT = '-';
  let strMaxDT = '-';
  let strStarted = '-';
  let strFinished = '-';

  //let bookingStart = moment(`${item.start_date} ${item.start_time}`, ParseDateTimeParameters);
  //let bookingEnd = moment(`${item.cease_date} ${item.cease_time}`, ParseDateTimeParameters);
    //strBookingStart = bookingStart.format("DD-MMM-YYYY [at] hh:mmA");

  if (item.min_datetime){ strMinDT = moment(item.min_datetime).format("DD-MMM hh:mmA"); }
  if (item.max_datetime){ strMaxDT = moment(item.max_datetime).format("DD-MMM hh:mmA"); }


  if (item.started_datetime){ strStarted = moment(item.started_datetime).format("DD-MMM hh:mmA"); }
  if (item.finished_datetime){ strFinished = moment(item.finished_datetime).format("DD-MMM hh:mmA"); }
//
 // if (item.started_datetime){ strStarted = item.started_datetime; }
 // if (item.finished_datetime){ strFinished = item.finished_datetime; }
  
  let strBayMarking = "-";
  let strLocation = '?'; // item.bay_label || '-';

  //<tr key={item.id} onClick={that.onClick_Item.bind(that,item.reference,'vbooking')} className={strTRClass}>
    return (<tr key={item.id} className={strTRClass}>
        <td>{item.reference}</td>
        <td>{item.full_name}</td>
        <td>{item.mobile_number}</td>
        <td>{item.unit_number}</td>
        <td>{item.vehicle_rego}</td>
        <td>{item.bay_label}</td>
        <td>{item.building_label}</td>
        <td>{strMinDT}</td>
        <td>{strMaxDT}</td>
        <td>{strStarted}</td>
        <td>{strFinished}</td>
    </tr>);
    //<td><ChatIcon style={{color:"#cccccc"}}/></td>
}




function getItem_Dashboard(that,item) {
    return (<tr key={item.id}>
        <td>PS {item.id}</td>
        <td>{item.user.first_name}</td>
    </tr>);
}

// Meriton Users
function getItem_UsersWP(that,item) {
  
  let strCreateDate = moment(item.create_date).format("DD-MMM-YYYY");
  return (<tr key={item.email} >
  <td>{strCreateDate}</td>
  <td>{item.email}</td>
  <td>{item.first_name}</td>
  <td>{item.last_name}</td>
  <td>{item.telephone_number}</td>  
  <td>{item.default_building && item.default_building.building_name}</td>
  <td>{item.tenant_id}</td>
  <td>{item.lease_end_date}</td>
  </tr>);

}

// Meriton ParkingBays
function getItem_ParkingBaysWP(that,item) {

  const user = item.user || {};
  let strCreateDate = moment(item.create_date).format("DD-MMM-YYYY");

  let thisRate;
  if (item.rate_monthly){
    thisRate = <td>${item.rate_monthly}</td>;
  } else {
    thisRate = <td>${item.building.rate_monthly_default}</td>;
  }

  let bookedRate;
  let hasBooking;
  if (item.active_bookings && item.active_bookings.length > 0){
    hasBooking = true;
    let rate = item.active_bookings[0].headline_amount || '';
    
    bookedRate = <td>${rate}</td>;
  } else {
    hasBooking = false;
    bookedRate = <td>-</td>;
  }

  //<td>{user.default_building && user.default_building.building_name}</td>

  return (<tr key={item.uuid} onClick={that.onClick_Item.bind(that,item.uuid,'parkingbay')}>
  <td>{hasBooking ? <div className="tr-status-booked"></div>:<div className="tr-status-available"></div>}</td>
  <td>{strCreateDate}</td>
  <td>{user.telephone_number}</td>
  <td>{user.email}</td>  
  <td>{user.first_name}</td>
  <td>{user.last_name}</td>
  <td>{item.building.building_name}</td>
  <td>{user.tenant_id}</td>
  <td>{item.bay_marking}</td>
  <td>{user.lease_end_date}</td>
  {thisRate}
  {bookedRate}
  <td>{ENUMS.group_types[item.bay_group]}</td>
  <td>{ENUMS.availability_types[item.availability_type]}</td>
  </tr>);

}

/* 
  {id:"phone",display:"Phone",width:140},
        {id:"email",display:"Email",width:140},        
        {id:"building",display:"Building",sortable:true, width:120},
        {id:"tenant_id",display:"Unit#",width:100},
        {id:"bay",display:"Bay #",width:100},
        {id:"lease_end_date",display:"Lease End Date"},
        {id:"rate_monthly",display:"Monthly"},
        {id:"type",display:"Type",width:100},
        {id:"availability_type",display:"Avail.",width:100},
*/


function getItem_Buildings(that,item) {
    return (<div key={item.uuid} className="building">
      <div className="photo-box"></div>
      <div className="content">
      <h3>{item.building_name}</h3>
      <p>{item.address}, {item.suburb} {item.state}</p>
        <pre>{JSON.stringify(item,null,2)}</pre>

      </div>
    </div>);
}

function getItem_BuildingsWP(that,item) {
  
  let img;
  let imageUrl = getImagePreviewUrlForBuilding(item);
  if (imageUrl){
    //TEMP
    imageUrl = imageUrl.replace("https://meriton.sharewithoscar.com.au/images/","https://images.sharewithoscar.com.au/");
    imageUrl += "?tr=w-318,h-240,fo-auto";
    //
    //console.log(imageUrl);

    img = <div className="img-holder-dg" key={'img'} style={{backgroundImage: `url("${imageUrl}")`}}></div>;
  }

  let strBayCount = item.bay_count.toString() + ' bay';
  if (item.bay_count === 0 || item.bay_count > 1){
    strBayCount+='s';
  }
  //<h3>{strBayCount}</h3>
  
  //<pre>WP:{JSON.stringify(item,null,2)}</pre>
  return (<div key={item.uuid} className="building">
    <div className="photo-box">
      <div className="btn-editbays" onClick={that.onClick_Item.bind(that,item.uuid,'building_bays')}>Edit Bays</div>
      {img}
    </div>
    <div className="content">
      <h2>{item.building_name}</h2>
      <p>{item.address}, {item.suburb} {item.state}</p>
      <div className="btn-editbuilding" onClick={that.onClick_Item.bind(that,item.uuid,'building')}>Edit Building</div>
    </div>
  </div>);
}


function getItem_ParkingBays(that,item) {
  let thisRate;
  if (item.rate_monthly){
    thisRate = <span className="rate">${item.rate_monthly}/mth</span>;
  } else {
    thisRate = <span className="rate">${item.building.rate_monthly_default}/mth</span>;
  }

  let divBayType;
  
  if (item.bay_group){
    divBayType = <span className={"group-" + item.bay_group}>{ENUMS.group_types[item.bay_group]} - {ENUMS.availability_types[item.availability_type]}</span>;
  } else {
    divBayType = <span className="group-none">-</span>;    
  }
  
  //<td>{ENUMS.group_types[item.bay_group]}</td>
  //<td>{ENUMS.availability_types[item.availability_type]}</td>
  
  let availabilityDays = <span className="availability">{ENUMS.availability_types[item.availability_type]}</span>;
//<pre>{JSON.stringify(item,null,2)}</pre>
  let divDriver = [];
  let divFutureDriver = [];
  let divOwner;
  if (item.user){
    //divOwner = <div className="owner"><b style={{marginRight:'5px'}}>Owner:</b>{item.user.email}</div>;
    divOwner = <div className="owner">{item.user.email}</div>;
  } else {
    divOwner = <div className="owner">&nbsp;</div>
  }
  
  let hasBooking;
  if (item.active_bookings && item.active_bookings.length > 0 && item.active_bookings[0].user){
    hasBooking = true;

    for (let d=0;d<item.active_bookings.length;d++){
      //divDriver = <div className="driver"><b style={{marginRight:'5px'}}>Driver:</b>{item.active_bookings[0].reference} {item.active_bookings[0].user.email}</div>
      let thisBooking = item.active_bookings[d];
      let strStart = moment(thisBooking.start_date).format("DD MMM"); // item.active_bookings[d].start_date;
      let strEnd;
      let divRate;

      let divPromo
      if (thisBooking.promo_code_used){
        divPromo = <small>({thisBooking.promo_code_used}) </small>;
      }
  

      if (thisBooking.cease_date && moment(thisBooking.cease_date).diff(moment(thisBooking.start_date), 'days') <= 30-1){
        divRate = <span className="rate">{divPromo}${(thisBooking.headline_amount||0).toFixed(2)}</span>;
      } else {
        divRate = <span className="rate">{divPromo}${(thisBooking.headline_amount||0)}/mth</span>;
      }
    
      if (item.active_bookings[d].cease_date){
        strEnd = moment(thisBooking.cease_date).format("DD MMM");
        
      } else {
        strEnd = "Ongoing";
      }
      divDriver.push(<div key={d} className="driver"><b style={{marginRight:'5px'}}>{item.active_bookings[d].reference}</b>
      <div className="driver-dates">{strStart} - {strEnd}</div>
      {divRate}
      <br/><span className="driver-email">{thisBooking.user.email}</span></div>);
    }

  } else {
    //divDriver = <div className="driver">&nbsp;</div>;
    hasBooking = false;
  }


  for (let d=0;d<item.future_bookings.length;d++){
    //divDriver = <div className="driver"><b style={{marginRight:'5px'}}>Driver:</b>{item.active_bookings[0].reference} {item.active_bookings[0].user.email}</div>
    let thisBooking = item.future_bookings[d];
    let strStart = moment(thisBooking.start_date).format("DD MMM");
    let strEnd;
    let divRate;

    let divPromo
    if (thisBooking.promo_code_used){
      divPromo = <small>({thisBooking.promo_code_used}) </small>;
    }


    if (thisBooking.cease_date && moment(thisBooking.cease_date).diff(moment(thisBooking.start_date), 'days') <= 30-1){
      divRate = <span className="rate">{divPromo}${(thisBooking.headline_amount||0).toFixed(2)}</span>;
    } else {
      divRate = <span className="rate">{divPromo}${(thisBooking.headline_amount||0)}/mth</span>;
    }

    if (thisBooking.cease_date){
      strEnd = moment(thisBooking.cease_date).format("DD MMM");
      //strRate = `$${thisBooking.headline_amount}`;

    } else {
      strEnd = "Ongoing";
      
    }
    //divFutureDriver.push(<div key={'f'+d} className="driver-future"><b style={{marginRight:'5px'}}>{thisBooking.reference}</b> {strRate} {strStart} - {strEnd}<br/>{thisBooking.user.email}</div>);
    divFutureDriver.push(
      <div key={'f'+d} className="driver-future"><b style={{marginRight:'5px'}}>{thisBooking.reference}</b>
      <div className="driver-dates">{strStart} - {strEnd}</div>
      {divRate}
      <br/><span className="driver-email">{thisBooking.user.email}</span></div>);
  }


  return (<div key={item.uuid} className={hasBooking ? "parkingbay is-booked":"parkingbay"} onClick={that.onClick_Item.bind(that,item.uuid,'parkingbay')}>
    
    {hasBooking ? <div className="status-booked"></div>:<div className="status-available"></div>}
    <div className="bay-marking">#{item.bay_marking || "n/a"}</div>
    
    {thisRate}
    {divBayType}
    {divOwner}
    {/*availabilityDays*/} 
    {divDriver}
    {divFutureDriver}
      
  </div>);
}

function getItem_Transactions(that,item) {
    return (<tr key={item.id}>
        <td>{item.create_date}</td>
        <td>{item.transaction_type}</td>
        <td>{item.description}</td>
        <td>{item.amount}</td>
    </tr>);
}




function getItem_VisitorBays(that,item) {
  
  //<td>{ENUMS.group_types[item.bay_group]}</td>
  //<td>{}</td>

  //console.log({item});

  let divBayType;
  
  if (item.bay_group){
    divBayType = <span className={"group-" + item.bay_group}>{ENUMS.group_types[item.bay_group]}<br/>{ENUMS.availability_types[item.availability_type]}</span>;
  } else {
    divBayType = <span className="group-none">-</span>;    
  }
  

//<div className="status-available"></div>
  return (<div key={item.uuid} className={"visitorbay"} onClick={that.onClick_Item.bind(that,item.uuid,'parkingbay')}>
    
    
    <div className="bay-rego">ABC123</div>
    <div className="bay-testb">{item.building.building_name}</div>

    <div className="bay-marking">#{item.bay_marking || "n/a"}</div>
    {divBayType}
   
      
  </div>);

  let img;
  let imageUrl = getImagePreviewUrlForBuilding(item);
  if (imageUrl){
    //TEMP
    imageUrl = imageUrl.replace("https://meriton.sharewithoscar.com.au/images/","https://images.sharewithoscar.com.au/");
    imageUrl += "?tr=w-318,h-240,fo-auto";
    //
    //console.log(imageUrl);

    img = <div className="img-holder-dg" key={'img'} style={{backgroundImage: `url("${imageUrl}")`}}></div>;
  }

  let strBayCount = item.bay_count.toString() + ' bay';
  if (item.bay_count === 0 || item.bay_count > 1){
    strBayCount+='s';
  }
  //<h3>{strBayCount}</h3>
  
  //<pre>WP:{JSON.stringify(item,null,2)}</pre>
  return (<div key={item.uuid} className="building">
    <div className="photo-box">
      <div className="btn-editbays" onClick={that.onClick_Item.bind(that,item.uuid,'building_bays')}>Edit Bays</div>
      {img}
    </div>
    <div className="content">
      <h2>{item.building_name}</h2>
      <p>{item.address}, {item.suburb} {item.state}</p>
      <div className="btn-editbuilding" onClick={that.onClick_Item.bind(that,item.uuid,'building')}>Edit Building</div>
    </div>
  </div>);
}


//====================================================================================
//====================================================================================
function getItem_VisitorBaysBollards(that,item) {
  
  //<td>{ENUMS.group_types[item.bay_group]}</td>
  //<td>{}</td>

  //console.log({item});

  let divBayType;
  
  if (item.bay_group){
    divBayType = <span className={"group-" + item.bay_group}>{ENUMS.group_types[item.bay_group]}<br/>{ENUMS.availability_types[item.availability_type]}</span>;
  } else {
    divBayType = <span className="group-none">-</span>;    
  }
  
  
  

//<div className="status-available"></div>
  return (<div key={item.uuid} className={"visitorbay2"} onClick={that.onClick_Item.bind(that,item.uuid,'parkingbay')}>
    
    
    <div className="bay-rego">ABC123</div>
    <div className="bay-testb">{item.building.building_name}</div>

    <div className="bay-marking">#{item.bay_marking || "n/a"}</div>
    {divBayType}
   
    {item.bollard &&
    
    
    <div className="bay-bollard">Bollard: 

      <div>car_detected: {item.bollard.car_detected ? 'YES':'no'}</div>

      <div>battery_fault: {item.bollard.battery_fault}</div>
      <div>battery_voltage: {item.bollard.battery_voltage}</div>

      {item.bollard.position_state == 0 && <div>POS: down-lock</div>}    
      {item.bollard.position_state == 1 && <div>POS: lowering</div>}    
      {item.bollard.position_state == 2 && <div>POS: FAULT</div>}    
      {item.bollard.position_state == 3 && <div>POS: up-lock</div>}    

      <pre>{item.bollard && JSON.stringify(item.bollard,null,2)}</pre>

    </div>

    }
  </div>);


///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////

  let img;
  let imageUrl = getImagePreviewUrlForBuilding(item);
  if (imageUrl){
    //TEMP
    imageUrl = imageUrl.replace("https://meriton.sharewithoscar.com.au/images/","https://images.sharewithoscar.com.au/");
    imageUrl += "?tr=w-318,h-240,fo-auto";
    //
    //console.log(imageUrl);

    img = <div className="img-holder-dg" key={'img'} style={{backgroundImage: `url("${imageUrl}")`}}></div>;
  }

  let strBayCount = item.bay_count.toString() + ' bay';
  if (item.bay_count === 0 || item.bay_count > 1){
    strBayCount+='s';
  }
  //<h3>{strBayCount}</h3>
  
  //<pre>WP:{JSON.stringify(item,null,2)}</pre>
  return (<div key={item.uuid} className="building">
    <div className="photo-box">
      <div className="btn-editbays" onClick={that.onClick_Item.bind(that,item.uuid,'building_bays')}>Edit Bays</div>
      {img}
    </div>
    <div className="content">
      <h2>{item.building_name}</h2>
      <p>{item.address}, {item.suburb} {item.state}</p>
      <div className="btn-editbuilding" onClick={that.onClick_Item.bind(that,item.uuid,'building')}>Edit Building</div>
    </div>
  </div>);
}








export default class DataGrid extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    
        //console.log("[DataGrid:constructor]",this.props);

        let queryId = this.props.queryId;
        //let parentId = this.props.parentId;
        let parentId = this.props.match.params.reference;

        let queryDetails = queryTable[queryId];

        
        this.state = {
            queryId,
            parentId,
            queryDetails,
            initialLoad:false,
            data:null,
            searchText:'',
            searchTextFinal:''
        }

        this.loadData = this.loadData.bind(this);
        this.onClick_Search = this.onClick_Search.bind(this);
        this.onClick_Refresh = this.onClick_Refresh.bind(this);
        this.onClick_Export = this.onClick_Export.bind(this);
    }


    componentWillUnmount(){
        this._isMounted = false;
    }    

    componentDidMount(){
        this._isMounted = true;

        this.loadData();
        /*let that = this;
        setTimeout(() => {
          that.loadData();
        }, 1);*/

        
    }    

    
    loadData(page=1, per_page=10, sort_by="default",sort_dir="default",search_text=null){
        if (!this._isMounted){
            //console.log("not mounted, ignore");
            return;
        }

        if (search_text != null){
          //console.log("A using search_text:",search_text);
        } else {
          search_text = this.state.searchTextFinal || "";
          //console.log("B using search_text:",search_text);
        }

        this.setState({isUpdating:true, searchTextFinal:search_text});
        let that = this;
        
        //console.log(`loadData -> qetQuery:${this.state.queryId} [page:${page}] [sortBy:${sort_by}/${sort_dir}]`);
        //moment.utc()
        getQuery(this.state.queryId, this.state.parentId, page, per_page, sort_by, sort_dir, search_text, "").then(response => {
          //console.log(response.data);
          that.setState({initialLoad:true, isUpdating:false, meta:response.data.meta, data:response.data.data });
        }).catch(function(err) {
          // NEW ERROR HANDLING
          //console.log(JSON.stringify(err));
          let errorMessage = err.toString();
          if (err.response && err.response.data && err.response.data.detail){
            //console.log("err.response.data:",err.response.data.detail);

            
            errorMessage  =  `${err.response.data.detail}`;
            
            
          } else {
            console.error(err);
          }
          that.setState({errorMessage});
        });

    }

    onClick_Page(pageNumber){
        if (this.state.isRefreshing || this.state.isUpdating){
            return;
          }
        const meta = this.state.meta;
      
        this.loadData(pageNumber, meta.per_page, meta.sort_by, meta.sort_dir);
    }

    onHandleKeyDown = (e) => {
      if (e.key === 'Enter') {
        this.onClick_Search();
      }
    }

    onChangeSearchText = (e) => {
      this.setState({ searchText: e.target.value });
    }

    onClick_Search(e){
      
      let search_text = this.state.searchText || "";

      if (this.state.isRefreshing || this.state.isUpdating){
          return;
      }
      const meta = this.state.meta;
      this.loadData(1, meta.per_page, meta.sort_by, meta.sort_dir, search_text.trim());
    }


    onClick_Refresh(){
      const meta = this.state.meta;

      this.loadData(1, meta.per_page, meta.sort_by, meta.sort_dir, "");//, search_text.trim());
      this.setState({searchText:""});
    }

    onClick_NewItem(prefix){
      if (this.state.isUpdating){
        return;
      }

      //console.log("# Create new:", this.props.history.location.pathname);
      localStorage.setItem('returnUrl', this.props.history.location.pathname);
      //this.props.history.push(`/cp/${prefix}/00000000-0000-0000-0000-000000000000`);
      this.props.history.push(`/cp/${prefix}/new`);
      //f39aaf86-3738-49b5-93ff-61bfb7973c53
      //00000000-0000-0000-0000-000000000000
    }


    onClick_Item(itemId,prefix){
      if (this.state.isUpdating){
        return;
      }
      //console.log("# Store return url:", this.props.history.location.pathname);
      //localStorage.setItem('returnUrl', this.props.history.location.pathname);
      this.props.history.push(`/cp/${prefix}/${itemId}`);
    }



    onClick_SortBy(fieldId){
      const meta = this.state.meta;
      let newDir = "asc";

      if (meta.sort_by == fieldId){
        
        if (meta.sort_dir === "asc"){
          newDir = "desc";
        }
      } else {
        //default to new field and ASC
        
      }
      
      
      this.loadData(meta.page, meta.per_page, fieldId, newDir);
    }

    onClick_Export(){
      const {meta, queryId, queryDetails } = this.state;

      let page=1;
      let per_page=10;
      let sort_by="default";
      let sort_dir="default";
      let search_text="";

      this.setState({isUpdating:true, searchTextFinal:search_text});
  
      let that = this;
      //this.loadData(1, meta.per_page, meta.sort_by, meta.sort_dir, "");//, search_text.trim());
      //this.setState({searchText:""});
      
      //this.loadData(1, meta.per_page, meta.sort_by, meta.sort_dir, "");//, search_text.trim());
      getQuery(this.state.queryId, this.state.parentId, page, per_page, sort_by, sort_dir, search_text, "csv").then(response => {
        //console.log(response);
        //console.log("data:",response.data);

        var fileDownload = require('js-file-download');        
        let filename = queryDetails.title.replace(/ /g,'_') + '.csv';
        fileDownload(response.data, filename);
        that.setState({isUpdating:false });
        
      }).catch(function(err) {
        // NEW ERROR HANDLING
        //console.log(JSON.stringify(err));
        let errorMessage = err.toString();
        if (err.response && err.response.data && err.response.data.detail){
          //console.log("err.response.data:",err.response.data.detail);

          
          errorMessage  =  `${err.response.data.detail}`;
          
          
        } else {
          console.error(err);
        }
        that.setState({errorMessage});
      });


    }




    render() {
      //console.log("DataGrid:Render:",this.state);
      const {queryId, parentId, queryDetails, initialLoad, isUpdating, errorMessage} = this.state;
      const data = this.state.data || {};
      const meta = this.state.meta;
      const CMS = this.props.CMS;

      if (!queryDetails){
          return (<div>MISSING DETAILS for {queryId}</div>);
      }

      // NEW ERROR HANDLING
      if (errorMessage){
        return (
          <div className="datagrid error">
              <h1>{queryDetails.title}</h1>
              <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
                <h1>ERROR</h1>
                <p>{errorMessage}</p>

              </div>
              
          </div>
        );
      }

      if (!initialLoad){
          return (
              <div className="datagrid">
                  <h1>{queryDetails.title}</h1>
                  <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
                    <CircularProgress  size={80} thickness={4.5} style={{color:"#1AA5A2"}} />
                  </div>
                  
              </div>
          );
      }






      //==================================================
      // PAGINATOR
      let divPaginator;
      let arrPages = [];
          
      if (meta.num_pages > 1){
        if (meta.page > 1){
            arrPages.push(<div key={'prev'} onClick={this.onClick_Page.bind(this,meta.page-1)}><BackIcon style={{color:"#1AA5A2"}}/></div>);
        } else {
            arrPages.push(<div key={'prev'}><BackIcon style={{color:"#aaaaaa"}}/></div>);
        }

        let lastVisible = true;
        for (let p=1;p<=meta.num_pages;p++){
          let isVisible = false;

          if (p==meta.page){
            isVisible = true;
          } else if (p <= 2 || p>= meta.num_pages){
            isVisible = true;
          } else if (p >= meta.page-3 && p<= meta.page+3){
            isVisible = true;
          } 

          if (isVisible){
            lastVisible = true;
            if (p==meta.page){
              arrPages.push(<div className="current" key={p}>{p}</div>);
            } else {
                arrPages.push(<div key={p} onClick={this.onClick_Page.bind(this,p)}>{p}</div>);
            }

          } else {
            if (lastVisible){
              arrPages.push(<span className="spacer" key={p}>...</span>);
              lastVisible = false;
  
            }
          }
        }

        if (meta.page < meta.num_pages){
            arrPages.push(<div key={'next'} onClick={this.onClick_Page.bind(this,meta.page+1)}><NextIcon style={{color:"#1AA5A2"}}/></div>);
        } else {
            arrPages.push(<div key={'next'}><NextIcon style={{color:"#aaaaaa"}}/></div>);
        }

      }



      let strItemCount;

      if (meta.count === 1){
          strItemCount = `${meta.count} item found`;
      } else {
          strItemCount = `${numberWithCommas(meta.count)} items found`;
      }
      
      
      divPaginator = (
          <div className="paginator">
              <span className="itemcount">{strItemCount}</span>
              <div className="pagelist">
                  {arrPages}
              </div>
              {queryDetails.exportableCSV && <><br/><div className="export-button" onClick={this.onClick_Export}>Export to CSV</div></>}                                                    
          </div>
      );
      //==================================================





      //==================================================
      // Parse items
      //==================================================
      let arrItems = [];

      try {
          for (let i=0;i<data.length;i++){
              let item = data[i];
              arrItems.push(queryDetails.parser(this,item));
          }

      } catch(e) {
          console.error("FAIL:",e);
      }
      //==================================================

        
      if (queryDetails.mode === 'flex'){

        let divBuilding;
        let divBuildingRate;
        let thisBuilding;
        if (meta.default_building_name){
          divBuilding = <span style={{color:'var(--heroColor2)'}}>- {meta.default_building_name}</span>;
        }
        if (meta.rate_monthly_default){
          //divBuildingRate = <h3 style={{color:'var(--heroColor2)',margin:'0 0 5px 0'}}>Default Rate: ${meta.rate_monthly_default}/mth (min: ${meta.rate_monthly_min} - max: ${meta.rate_monthly_max})</h3>;
          divBuildingRate = <h3 style={{color:'var(--heroColor2)',margin:'0 0 5px 0'}}>Building Default Rate: ${meta.rate_monthly_default}/mth</h3>;          
        }
        
        // onClick={that.onClick_Item.bind(that,item.reference,'booking')}
        let divCreateNew;

        //divCreateNew =  <div className="add-new" onClick={this.onClick_NewItem.bind(this,`building_bays/${this.state.parentId}`)} style={{color:'var(--heroColor2)'}}>+ Add New</div>;
        if (queryDetails.addNew){
          divCreateNew = <Button className="wp-button" style={{minWidth:'140px', fontSize:'16px',marginLeft:'50px'}} onClick={this.onClick_NewItem.bind(this,`building_bays/${this.state.parentId}`)} disabled={isUpdating}><IconPlus /> Add New</Button>;

        }

        
    


          let divCustom;
          
          if (queryId === 'cp_parkingbays'){
            divCustom = (
              <div className="legend-pb">
                <div className="legend-pb-status-booked"></div>Booked<div className="legend-pb-status-available"></div>Available</div>);
          }

          return(
              <div className="datagrid noselect">
                  <h1>{queryDetails.title} {divBuilding} {divCreateNew}</h1>
                  {divBuildingRate}

                  {queryDetails.searchBox && 
                  <SearchBox
                      value={this.state.searchText}
                      placeholder={queryDetails.searchBoxPlaceholder}
                      onChange={this.onChangeSearchText}
                      onKeyDown={this.onHandleKeyDown}
                      onClickSearch={this.onClick_Search}
                      onClickRefresh={this.onClick_Refresh}
                      showRefresh={queryId === 'vp_visitorbays'}
                      //newData={newData}

                      />
                  }

                  {divCustom}
                  <div className={"flex-box " + queryId}>
                      {arrItems}
                  </div>
                      
 
                  {divPaginator}
              </div>
          );
  
      } else {

        //Table
          let arrHeader = [];
          const sortBy = meta.sort_by; // "ref";
          const sortOrder = meta.sort_dir; // "desc";
          for (let i=0;i<queryDetails.headerFields.length;i++){

            let thisField = queryDetails.headerFields[i];
            let tstyle;
            if (thisField.width){
              tstyle = {width:thisField.width};
            }
          
            if (!thisField.sortable){
              arrHeader.push(<th style={tstyle} key={thisField.id}>{thisField.display}</th>);
            } else {

              let thisSort;
              if (sortBy == thisField.id){
                if (sortOrder == "desc"){
                  thisSort = <ArrowDownIcon style={{width:'30px',height:'30px',position:'absolute',marginLeft:'-1px',marginTop:'-1px'}}/>;
                } else {
                  thisSort = <ArrowUpIcon style={{width:'30px',height:'30px',position:'absolute',marginLeft:'-1px',marginTop:'-1px'}}/>;
                }
              }

              arrHeader.push(<th style={tstyle} className={"sortable"} key={thisField.id} onClick={this.onClick_SortBy.bind(this,thisField.id)}>{thisField.display}{thisSort}</th>);
            }

          }

          let j = 10 - arrItems.length;
          if (j>0){
            for (let i=0;i<j;i++){
              arrItems.push(<tr key={'e'+i} className="emptyrow"><td colSpan='20'>&nbsp;</td></tr>);
            }
            
          }

          //WIP
          //onChange={this.onChange} onBlur={this.onBlur}  onFocus={this.onFocus}  onKeyPress={this.onKeyPress} 
          //value={'this.state.value'}

          let strStyle = {};
          if (isUpdating){
              strStyle.opacity = 0.125;
          }
  
          let lastQuery = moment(meta.exec_timestamp);
          //console.log("lastQuery:",lastQuery);
          let lastOwnerBooking;
          let newData;
          if (this.state.lastQuery){
            lastQuery = this.state.lastQuery;
          }
          if (CMS.lastOwnerBooking){
            lastOwnerBooking = moment(CMS.lastOwnerBooking);
          }
          if (lastQuery < lastOwnerBooking){
            newData = true;
            console.log("has new data!");
          }
/* 
                    <p>lastQuery:{lastQuery && lastQuery.toISOString()}</p> - 
                    <p>lastOwnerBooking:{lastOwnerBooking && lastOwnerBooking.toISOString()}</p>

*/

          //console.log(this.props.user.profile.default_building);
          let divBuilding;
          let thisBuilding;
          if (meta.default_building_name){
            divBuilding = <span style={{color:'var(--heroColor2)'}}>- {meta.default_building_name}</span>;
          }



          return(
              <div className="datagrid noselect">
                  <h1>{queryDetails.title} {divBuilding}</h1>
                  
                  {queryDetails.searchBox ?
                    <SearchBox
                      value={this.state.searchText}
                      placeholder={queryDetails.searchBoxPlaceholder}
                      onChange={this.onChangeSearchText}
                      onKeyDown={this.onHandleKeyDown}
                      onClickSearch={this.onClick_Search}
                      onClickRefresh={this.onClick_Refresh}
                      newData={newData}

                      />: <div className="filter"></div>
                  }

                  <table>
                      <thead>
                          <tr>
                            {arrHeader}
                          </tr>
                      </thead>
                      
                      <tbody style={strStyle}>
                          {arrItems}
                      </tbody>
  
                  </table>
                  {divPaginator}

              </div>
          );
  
      }

    }
}


/* 

                    <div className="filter">
                      
                    
                      <InputLabel id="demo-simple-select-label">Type:</InputLabel>                      
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={10}
                        //onChange={handleChange}
                      >
                        <MenuItem value={10}>All Bookings</MenuItem>
                        <MenuItem value={20}>Casual</MenuItem>
                        <MenuItem value={30}>Long Term</MenuItem>
                        <MenuItem value={30}>Visitor</MenuItem>
                      </Select>
                    

      
                      <TextField
          id="filled-required"
          label="Search by Ref, Rego or Driver"
          defaultValue=""
          variant="filled"
                  />      


                    </div>

*/