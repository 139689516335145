import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Loader extends Component {
    render() {
        return(
            <Grid container justify="center" alignItems="center">
                <Grid item style={{padding: '40px'}} >
                    <CircularProgress  size={80} thickness={4.5} />
                </Grid>
            </Grid>
        )
    }
}