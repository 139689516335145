import { isNativeWebview } from '../utils/IsNativeWebview.js';

// const defaultAddress = "Sydney, NSW"
// const defaultLocation = { lat: -33.8689725, lng: 151.2099337 } // ~140 King St, Sydney

function requestLocationSuccess(position) {
    //console.log("### Location:requestLocationSuccess");
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;
    const latLng = {
      lat: parseFloat(position.coords.latitude), 
      lng: parseFloat(position.coords.longitude)
    }
    
    return new Promise(function(resolve, reject) {
        geocoder.geocode({ 'location': latLng }, (results, status) => {
            //console.log(results[0])
            if (status === OK) {
                //console.log("### Location:requestLocationSuccess:OK");
                resolve({
                    address: results[0].formatted_address, 
                    latLng: latLng,
                    suburb: results[0]['address_components'][2]['long_name']
                });
            } else {
                //console.log("### Location:requestLocationSuccess:REJECT");
                reject({code: status});
            }
        })
    })
}

function requestlocationError(error) {
    console.log(error);

    /*switch(error.code) {
      case error.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.")
          break;
      case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.")
          break;
      case error.TIMEOUT:
          console.log("The request to get user location timed out.")
          break;
      default:
          console.log("An unknown error occurred.")
          break;
    }*/
    
    // Return an error if location is unavailable (will fall back to default from post_code etc.)
    return new Promise(function(resolve, reject) {
        resolve({ address:'error' });
    })
}

function requestLocation() {    
    //console.log("### Location:requestLocation");
    if (checkUserLocationPermissions()) {
        // Return an error if location is unavailable (will fall back to default from post_code etc.)
        return new Promise(function(resolve, reject) {
            //console.log("### Location:requestLocation:Error");
            resolve({ address:'error' });
            //resolve({address: defaultAddress, latLng: defaultLocation, suburb: 'Sydney'})
        })
    }

    let geoPromise
    if (isNativeWebview()) {
        geoPromise = new Promise(function(resolve, reject){
            // Request the location from the native wrapper
            window.ReactNativeWebView.postMessage(JSON.stringify({action: "location"}));
            // Set an onMessage listener to capture the location response
            
            if (window.document.isAndroid) {
              window.document.addEventListener("message", handleNativeResponse )
            } else {
              window.addEventListener("message", handleNativeResponse )
            }
            
            //window.addEventListener("message", handleNativeResponse )

            function handleNativeResponse(event) {
                // Remove the listener
                //window.removeEventListener("message", handleNativeResponse);
                if (window.document.isAndroid) {
                  window.document.removeEventListener("message", handleNativeResponse);
                } else {
                  window.removeEventListener("message", handleNativeResponse);
                }
    
                try {
                    const nativeResponse = JSON.parse(event.data);

                    if (nativeResponse.message === "location_ready") {
                        // Process the location response as normal
                        resolve(nativeResponse.position)
                    } else {
                        reject()
                    }
                } catch (e) {
                    // Return an error if location is unavailable (will fall back to default from post_code etc.)
                    resolve({ address:'error' });
                    //resolve({address: defaultAddress, latLng: defaultLocation, suburb: 'Sydney'});
                }
            }
        })
    } else if (navigator.geolocation) {
        geoPromise = new Promise(function(resolve, reject){
            navigator.geolocation.getCurrentPosition(resolve, reject);
        })
    } else {
            // Return an error if location is unavailable (will fall back to default from post_code etc.)
            return new Promise(function(resolve, reject) {
                //console.log("### Location:requestLocation:Error2");
                resolve({ address:'error' });
                //resolve({address: defaultAddress, latLng: defaultLocation, suburb: 'Sydney'})
        })
    }

    // Process the geolocation coordinates
    return geoPromise.then(requestLocationSuccess).catch(requestlocationError)
}

function checkUserLocationPermissions() {
    if (isNativeWebview()) {
        return localStorage.getItem("USER_LOCATION_PERMISSIONS_REQUESTED") === null
    } else {
        return false;
    }
}

function requestLocationPermissions(callBack) {
    localStorage.setItem("USER_LOCATION_PERMISSIONS_REQUESTED", 'true')

    // Request permission
    navigator.geolocation.getCurrentPosition(callBack, callBack)
}

export {
    requestLocation, 
    checkUserLocationPermissions,
    requestLocationPermissions,
}