import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';

// Import Drawer menu components
import {
  Drawer,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@material-ui/core/';

import ReferIcon from '@material-ui/icons/CardGiftcard';
import BookingsIcon from '@material-ui/icons/People';
import BuildingsIcon from '@material-ui/icons/Business';
import ParkingBaysIcon from '@material-ui/icons/LocalParking';
import BackIcon from '@material-ui/icons/ExitToApp';// ArrowBackIos';
import InboxIcon from '@material-ui/icons/Mail';

import UsersIcon from '@material-ui/icons/AccountCircle';
import { getUserProfile, logOut } from '../utils/Authentication';

const styles = {
  listCP: {
    padding:'0'
    /*paddingTop: '20px',*/
    //width: 220,
  },
  list: {
    paddingTop: '20px',
    //width: 220,
  },
  fullList: {
    width: 'auto',
  },
  menuButton: {
    color: '#afafaf',
  },
  menuIcon: {
    display: 'block',
    marginBottom: '1px',
    marginRight: '16px',
  },
  profileImage: {
    width: '60px', 
    height: '60px',
    borderRadius: '40px',
    marginTop: '5px',
  },
  topSection: {
    padding: '16px',
    backgroundColor: '#efefef',
    cursor: 'pointer',
  },
  userName: {
    //width: '125px',
    fontSize: '22px',
    fontWeight: 'normal',
  },
};


class ProfileNavigationWP extends React.Component {

  render() {
    let default_building;
    if (this.props.user.authenticated){
      default_building = window.WPDomain === 'community' ? this.props.user.profile.default_building_community: this.props.user.profile.default_building;
    }

  
    return(
      <div style={this.props.maxWidth !== undefined ? {width: '230px'} : {}}  className="profile-menu-cp">
        <MenuList style={styles.list} onClick={this.props.handleClose}>
        <div style={this.props.extraPadding !== undefined ? {paddingLeft: `${this.props.extraPadding}px`}: {}}>


          <Link to="/bookings">
            <MenuItem >
              <ListItemText primary="My Bookings" />
            </MenuItem>
          </Link>
          {default_building && 
          <Link to="/listings">
            <MenuItem >
              <ListItemText primary="My Spots" />
            </MenuItem>
          </Link>
          }
          <Link to="/wallet">
            <MenuItem >
              <ListItemText primary="Wallet" />
            </MenuItem>
          </Link>
          <Link to="/help">
            <MenuItem >
              <ListItemText primary="Help" />
            </MenuItem>
          </Link>
          <Link to="/userprofile">
            <MenuItem >
              <ListItemText primary="Profile" />
            </MenuItem>
          </Link>
          

          <Link to="/login" onClick={() => {logOut(); this.props.authChange()}}>
            <MenuItem >
              <ListItemText primary="Logout" color="primary"
              primaryTypographyProps={{
                color: 'primary',
              }}/>
            </MenuItem>
          </Link>

        </div>
        </MenuList>
      </div>
    )
  }
}

class NavigationItemsWP extends React.Component {
  
  render() {
    const { classes } = this.props;
    let topSection;

    let default_building;
    if (this.props.user.authenticated){
      default_building = window.WPDomain === 'community' ? this.props.user.profile.default_building_community: this.props.user.profile.default_building;
    }


    if (this.props.user.authenticated){
      const userProfile = getUserProfile();
      
      const userImage = userProfile.image_user ? <img src={userProfile.image_user.path} alt="Your photo" />:<img src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="Upload a photo!"/>;
      
      topSection = (
        <div className="wp-profile noselect" onClick={() => this.props.history.push("/userprofile")}>
          <div className="photo-box">{userImage}</div>
          <div className="details">
            <h2>{userProfile.first_name} {userProfile.last_name}</h2>
            <div className="profile-balances">
              <p>Balance:<span>{`$${parseFloat(this.props.user.profile.balance_aud || 0).toFixed(2)}`}</span></p>
              <p>Free credits:<span>{`$${parseFloat(this.props.user.profile.balance_osc || 0).toFixed(2)}`}</span></p>
            </div>
          </div>
        </div>
      );
  
    }

    const strWPClass = window.WPDomain ? ("nav-menu-wp " + window.WPDomain):"nav-menu-wp";
    //"nav-menu-wp oscar"
    return(
      <div style={{width:'100%',height:'100%'}} className={strWPClass}>
        
        {topSection}

        <MenuList style={styles.listCP}>
          <div>

          {this.props.user.authenticated ? 
          <>
            <Link key={'search'} to="/search">
              <MenuItem >
                <ListItemText primary="Find a Spot" />
              </MenuItem>
            </Link>

            <Link key={'bookings'} to="/bookings">
              <MenuItem >
                <ListItemText primary="My Bookings" />
              </MenuItem>
            </Link>

{default_building &&
            <Link key={'listings'} to="/listings">
            <MenuItem >
              <ListItemText primary="My Spots" />
            </MenuItem>
          </Link>

}


            <Link key={'wallet'} to="/wallet">
              <MenuItem >
                <ListItemText primary="Wallet" />
              </MenuItem>
              </Link>
          </>:
          <>
            <Link key={'signup'} to="/signup">
              <MenuItem >
                <ListItemText primary="Sign Up" />
              </MenuItem>
            </Link>
          
          </>
          }
            <Link key={'help'} to="/help">
              <MenuItem >
                <ListItemText primary="Help" />
              </MenuItem>
            </Link>
            <Link to="/userprofile">
              <MenuItem >
                <ListItemText primary="Profile" />
              </MenuItem>
            </Link>


            {/*this.props.user.authenticated && this.props.user.profile.email.indexOf('@sharewithoscar.com') >= 0 &&
              <Link key={'style'} to="/styleguide"><MenuItem ><ListItemText primary="Style Guide" /> </MenuItem></Link>*/}

          </div>
        </MenuList>
      </div>
    )
  }
}


class ProfileNavigationCP extends React.Component {
  render() {
    return(
      <div style={this.props.maxWidth !== undefined ? {width: '230px'} : {}}  className="profile-menu-cp">
        <MenuList style={styles.list}>
        <div style={this.props.extraPadding !== undefined ? {paddingLeft: `${this.props.extraPadding}px`}: {}}>
          

          <Link to="/login" onClick={() => {logOut(); this.props.authChange()}}>
            <MenuItem >
              <ListItemText primary="Logout" color="primary"
              primaryTypographyProps={{
                color: 'primary',
              }}/>
            </MenuItem>
          </Link>
        </div>
        </MenuList>
      </div>
    )
  }
}


class NavigationItemsCP extends React.Component {
  render() {
    const { classes } = this.props;
    let topSection;
    //console.log(this.props.user);

    // DAMIAN: FIX THIS!!!!!!!!!!!!!!! (Server handles security anyway)
    const isMasterUser = !this.props.user.profile.default_building ? true:false;


    const allowVP = this.props.user.profile.allow_vpmanagement;

    //const showEvents = true; // read from user permission

    //let wpDomain = window.WPDomain;
    //console.log({wpDomain});

    return(
      <div style={{width:'100%',height:'100%',paddingTop:'20px'}} className="nav-menu-cp meriton">
        
        <MenuList style={styles.listCP}>
          <div style={this.props.extraPadding !== undefined ? {paddingLeft: `${this.props.extraPadding}px`}: {}}>
            

            {window.WPDomain ?
            <Link to="/cp/wp_bookings"><MenuItem ><BookingsIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Bookings" /></MenuItem></Link>:
            <Link to="/cp/bookings"><MenuItem ><BookingsIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Bookings" /></MenuItem></Link>
            }
            {/*<Link to="/conversations"><MenuItem ><InboxIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Inbox" /></MenuItem></Link>*/}
            <Link to="/longtermenquiries"><MenuItem ><InboxIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Enquiries" /></MenuItem></Link>

            {window.WPDomain && isMasterUser && <Link to="/cp/wp_users"><MenuItem ><UsersIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Users" /></MenuItem></Link>}
            {window.WPDomain && isMasterUser && <Link to="/cp/wp_buildings"><MenuItem ><BuildingsIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Buildings" /></MenuItem></Link>}
            {window.WPDomain && isMasterUser && <Link to="/cp/wp_parkingbays"><MenuItem ><ParkingBaysIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Parking Bays" /></MenuItem></Link>}
            


            {/*  STAGING ONLY! */}
            {!window.WPDomain && allowVP && 
              <>
                <hr/>
                <Link to="/cp/vp_visitorbays"><MenuItem ><ParkingBaysIcon style={{color:"#ff6666"}}/><ListItemText primary="Visitor Bays" /></MenuItem></Link>
                <Link to="/cp/vbookings"><MenuItem ><BookingsIcon style={{color:"#ff6666"}}/><ListItemText primary="Visitor Bookings" /></MenuItem></Link>
                <Link to="/cp/wp_buildings"><MenuItem ><BuildingsIcon style={{color:"#ff6666"}}/><ListItemText primary="Building Settings" /></MenuItem></Link>
                <Link to="/cp/wp_buildings"><MenuItem ><BookingsIcon style={{color:"#ff6666"}}/><ListItemText primary="Users" /></MenuItem></Link>
              </>
            }
            {/*window.WPDomain && isMasterUser && <Link to="/cp/wp_buildings"><MenuItem ><BuildingsIcon style={{color:"#1AA5A2"}}/><ListItemText primary="ZZ - Buildings" /></MenuItem></Link>*/}


            {/*showEvents && <Link to="/cp/events"><MenuItem ><UsersIcon style={{color:"#1AA5A2"}}/><ListItemText primary="Admin - Events" /></MenuItem></Link>*/}

          </div>
        </MenuList>
      </div>
    )
  }
}

/* 

*/





class ProfileNavigation extends React.Component {
  
  render() {
    return(
      <div style={this.props.maxWidth !== undefined ? {width: '230px'} : {}}>
        <MenuList style={styles.list} onClick={this.props.handleClose}>
        <div style={this.props.extraPadding !== undefined ? {paddingLeft: `${this.props.extraPadding}px`}: {}}>
          {this.props.user.authenticated
          ? <div >
          <Link to="/bookings">
            <MenuItem >
              <ListItemText primary="My Bookings" />
            </MenuItem>
          </Link>
          {(this.props.user.profile.is_corpuser || this.props.user.profile.listedSpaces) &&
          <Link to="/calendar">
            <MenuItem >
              <ListItemText primary="Calendar" />
            </MenuItem>
          </Link>}
          <Link to="/listings">
            <MenuItem >
              <ListItemText primary="My Spots" />
            </MenuItem>
          </Link>
          <Link to="/wallet">
            <MenuItem >
              <ListItemText primary="Wallet" />
            </MenuItem>
          </Link>
          <Link to="/tellyourfriends">
            <MenuItem >
              <ListItemText primary="Tell Your Friends" />
            </MenuItem>
          </Link>
          <Link to="/conversations">
            <MenuItem >
              <ListItemText primary="Inbox" />
            </MenuItem>
          </Link>
          </div>
          :<div>
            <Link to="/login">
              <MenuItem >
                <ListItemText primary="Log In" />
              </MenuItem>
            </Link>
            <Link to="/signup">
              <MenuItem >
                <ListItemText primary="Sign Up" />
              </MenuItem>
            </Link>
          </div>}
          <div>
          <Link to="/Help">
            <MenuItem >
              <ListItemText primary="Help" />
            </MenuItem>
          </Link>
          <Link to="/userprofile">
            <MenuItem >
              <ListItemText primary="Profile" />
            </MenuItem>
          </Link>


          <Link to="/login" onClick={() => {logOut(); this.props.authChange()}}>
            <MenuItem >
              <ListItemText primary="Logout" color="primary"
              primaryTypographyProps={{
                color: 'primary',
              }}/>
            </MenuItem>
          </Link>
          </div>
        </div>
        </MenuList>
      </div>
    )
  }
}


class NavigationItems extends React.Component {
  render() {
    const { classes } = this.props;
    let topSection;
    if (!this.props.user.authenticated) {
      /*topSection = (
        <div></div>
      );*/
    } else {
      const userProfile = getUserProfile();

      const userImage = userProfile.image_user ? <img src={userProfile.image_user.path} alt="Your photo" />:<img src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="Upload a photo!"/>;
    


      topSection = (
        <div className="wp-profile noselect" onClick={() => this.props.history.push("/userprofile")}>
          <div className="photo-box">{userImage}</div>
          <div className="details">
            <h2>{userProfile.first_name} {userProfile.last_name}</h2>
            <div className="profile-balances">
              <p>Balance:<span>{`$${parseFloat(this.props.user.profile.balance_aud || 0).toFixed(2)}`}</span></p>
              <p>Free credits:<span>{`$${parseFloat(this.props.user.profile.balance_osc || 0).toFixed(2)}`}</span></p>
            </div>
          </div>
        </div>
      );





      /*topSection = (
        <div style={styles.topSection}>
          <div onClick={() => this.props.history.push("/UserProfile")}>
            <Grid spacing={16} container alignItems="center" style={{flexWrap: 'nowrap'}}>
              <Grid item>
                {userProfile.image_user === null || userProfile.image_user === undefined
                ? <img src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" 
                    alt="Placeholder avatar"
                    style={styles.profileImage} />
                : <img src={userProfile.image_user.path} 
                    alt="Placeholder avatar"
                    style={styles.profileImage} />}
              </Grid>
              <Grid item >
                <Typography gutterBottom className={classes.userName} variant="title">
                  {userProfile.first_name} {userProfile.last_name}
                </Typography>
                
                <div className="profile-balances">
                  <p>Balance:<span>{`$${parseFloat(this.props.user.profile.balance_aud).toFixed(2)}`}</span></p>
                  <p>Free credits:<span>{`$${parseFloat(this.props.user.profile.balance_osc).toFixed(2)}`}</span></p>
                </div>

              </Grid>
            </Grid>
          </div>
      </div>
      )*/
    }
//console.log(this.props.user);
    return(
      <div style={this.props.maxWidth !== undefined ? {width: '230px'} : {}}>
        {topSection}
        <MenuList style={styles.list}>
        <div style={this.props.extraPadding !== undefined ? {paddingLeft: `${this.props.extraPadding}px`}: {}}>
        
          {this.props.user.authenticated
          ? <div>
          <Link to="/search">
            <MenuItem >
              <ListItemText primary="Find a Spot" />
            </MenuItem>
          </Link>
          <Link to="/bookings">
            <MenuItem >
              <ListItemText primary="My Bookings" />
            </MenuItem>
          </Link>
          {(this.props.user.profile.is_corpuser || this.props.user.profile.listedSpaces) &&
          <Link to="/calendar">
            <MenuItem >
              <ListItemText primary="Calendar" />
            </MenuItem>
          </Link>}
          <Link to="/listings">
            <MenuItem >
              <ListItemText primary="My Spots" />
            </MenuItem>
          </Link>
          <Link to="/wallet">
            <MenuItem >
              <ListItemText primary="Wallet" />
            </MenuItem>
          </Link>
          <Link to="/tellyourfriends">
            <MenuItem >
              <ListItemText primary="Tell Your Friends" 
              style={{flex: 'none'}}
              primaryTypographyProps={{
                color: 'primary',
              }}/>
              <ReferIcon color="primary"/>
            </MenuItem>
          </Link>
          <Link to="/conversations">
            <MenuItem >
              <ListItemText primary="Inbox"/>
            </MenuItem>
          </Link>
          </div>
          :<div>
            <Link to="/login">
              <MenuItem >
                <ListItemText primary="Log In" />
              </MenuItem>
            </Link>

            <Link to="/signup">
              <MenuItem >
                <ListItemText primary="Sign Up" />
              </MenuItem>
            </Link>
          </div>}
          <div>
          <Link to="/Help">
            <MenuItem >
              <ListItemText primary="Help" />
            </MenuItem>
          </Link>

          <Link to="/userprofile">
            <MenuItem >
              <ListItemText primary="Profile" />
            </MenuItem>
          </Link>
          {/*<small style={{position:'fixed',bottom:'10px',left:'10px',color:'#cccccc'}}>v.10.08.2021.004 {window.WPDomain}</small>*/}

          </div>
        </div>

        {/*this.props.user.authenticated && this.props.user.profile.is_corpuser ?

                    <MenuItem  onClick={() => window.location.href = '/cp/bookings'} style={{marginTop:'100px'}}>
                    <ListItemText primary="Go to Corporate Portal &gt;" />
                  </MenuItem>:null
        */}

        </MenuList>
      </div>
    )
  }
}

/* Mobile Nav drawer - Oscar */
class TemporaryDrawer extends React.Component {
  render() {
    const Nav = withStyles(styles)(withRouter(NavigationItems))
    return (
      <div>
        <Drawer open={this.props.isOpen} onClose={this.props.toggleDrawer} onClick={this.props.toggleDrawer}>
          <Nav user={this.props.user} maxWidth={230}/>
        </Drawer>
      </div>
    );
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};


/* Mobile Nav drawer - Meriton */
class TemporaryDrawerWP extends React.Component {

  render() {
    const Nav = withStyles(styles)(withRouter(NavigationItemsWP));
    return (
      <Drawer open={this.props.isOpen} onClose={this.props.toggleDrawer} onClick={this.props.toggleDrawer}>
        <Nav user={this.props.user} maxWidth={230}/>
      </Drawer>
    );
  }
}

TemporaryDrawerWP.propTypes = {
  classes: PropTypes.object.isRequired,
};



export default withStyles(styles)(withRouter(TemporaryDrawer));

const NavigationMenuWP = withStyles(styles)(withRouter(TemporaryDrawerWP))

const NavItems = withStyles(styles)(withRouter(NavigationItems))
const ProfileNav = withStyles(styles)(withRouter(ProfileNavigation))

const NavItemsCP = withStyles(styles)(withRouter(NavigationItemsCP))
const ProfileNavCP = withStyles(styles)(withRouter(ProfileNavigationCP))

const NavItemsWP = withStyles(styles)(withRouter(NavigationItemsWP))
const ProfileNavWP = withStyles(styles)(withRouter(ProfileNavigationWP))

export { NavItems, ProfileNav, NavItemsCP, ProfileNavCP, NavItemsWP, ProfileNavWP, NavigationMenuWP }
