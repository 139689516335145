import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import { 
    cancelBooking,
    extendBooking,
    reviewBooking,
    getBookingDetails,
    setBookingStatus
} from '../api/Oscar-api.js';

import {
    BookingsRangeDisplayFormat,
    ParseDateTimeParameters,
    TwelveHourTime,
    WeekdayDate,
    WPMonthlyBookingsDisplayFormat
} from '../utils/TimeFormats';
import ENUMS from '../api/ENUMS.json';
import { FieldType } from '../api/fieldTypes.js';

import LoadingModal from '../components/LoadingModal';
import LongTermEnquiry from '../views/LongTermEnquiry.js';
import EditItem_Form from '../components/EditItem_Form.js';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";

import IconBack from '@material-ui/icons/KeyboardArrowLeft'
import IconClose from "@material-ui/icons/Close"
import IconForward from '@material-ui/icons/KeyboardArrowRight'

import Carousel from 'react-responsive-carousel/lib/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';

import FullPageModal from '../utils/FullPageModal.js';
import { isNativeWebview } from '../utils/IsNativeWebview';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';
import SelectField from '../components/forms/SelectField.js';
import { updateUserProfileAjax } from '../utils/Authentication.js';
import BookingLimitExceeded from './BookingLimitExceeded.js';
import AvailabilityError from '../components/AvailabilityError.js';
import CardPaymentError from '../components/CardPaymentError.js';

import {GetStreetView} from '../utils/StreetView.js';
import {getSpaceType} from '../utils/GetSpaceDetails.js';
import {getImageListForSpace} from '../utils/GetImageListForSpace.js';
import SelectDropDown from '../components/SelectDropDown';


const styles = {
    leftTopButton :{
        position: 'absolute',
        zIndex: '3',
    },
    contentContainer: {
        height: '100%',
        overflowY: 'auto',
    },
    descriptionContentContainer: {
        marginTop: '8px',
        padding: '16px',
        flexGrow: '1',
    },
    container: {
    /*    padding: '0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',*/
    },
    rate: {
        fontWeight: 'bold',
    },
    bookingButtonContainer: {
        width: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
    },
    bookingButton: {
        padding: '22px',
    },
    descriptions: {
        paddingLeft: '16px',
    },
    distanceText: {
        textTransform: 'uppercase',
        color: "#acacac",
    },
    imageCarousel: {
        width: '100%',
        height: '250px',
        backgroundColor: '#ffffff'
    },
    carouselImage: {
        width: 'auto',
        height: 'auto'
    },
    subheading: {
        marginTop: '16px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    reviewIcon: {
        fontSize: '13px',
        marginRight: '4px'
    },
    messageButton: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
        //color: '#1AA5A2',
        //backgroundColor: 'white',
        //boxShadow: 'none',
        //'&:hover, &:focus': {
        //    backgroundColor: 'white',
        //},
    },
    editButton: {
        color: '#1AA5A2',
        backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover, &:focus': {
            backgroundColor: 'white',
        },
        border: '1px solid #1AA5A2'
    },

    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '10px',
    },
    nextButton: {
        position: 'absolute',
        boxShadow: 'none',
        bottom: 16,
        right: 16,
    },

    topColored: {
        paddingTop: '80px',
        backgroundColor: '#1AA5A2',
        paddingBottom: '8px',
    },
    paymentSetting: {
        //paddingTop: '8px',
        //paddingBottom: '8px',
        borderBottom: '1px solid #efefef'
    },
    switchField: {
        borderTop: '1px solid #acacac',
        borderBottom: '1px solid #acacac',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '12px',
        marginBottom: '12px',
    },
    transactionsContainer: {
        marginTop: '16px'
    },
    transactionContainer: {
        borderTop: '1px solid #efefef',
        paddingTop: '8px',
        paddingBottom: '6px',
    },
    switchBorder: {
        marginTop: '24px',
        borderTop: '1px solid #efefef',
    },

    pickerContainer: {
        minHeight: '550px', 
        backgroundColor: 'white',
        paddingTop: '32px',
    },

    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    },
    link: {
        display: 'inline-block',
        marginLeft: '4px',
        textDecoration: 'underline',
        cursor:'pointer'
    }
}

function CancelSuccess(props) {
    return(
        <div className="mainContent topPadded" style={styles.alertPage}>
            <Typography variant="display1" color="primary">
                Your booking has been cancelled.
            </Typography>

            <Typography>
                Your booking has been successfully cancelled. If you cancelled more than 2 hrs before your booking, your cancellation refund will be available in your wallet.
            </Typography>

            <div style={styles.alertImageContainer}>
                <img src="https://www.sharewithoscar.com.au/assets/graphics/cancelled_booking.svg" alt="booking cancelled"/>
            </div>

            <Button style={styles.alertButton} fullWidth variant="contained" 
            onClick={() => props.history.push('/')} color="primary">
                Okay
            </Button>
        </div>
    )
}

function ExtendSuccess(props) {
    return(
        <div className="mainContent topPadded" style={styles.alertPage}>
            <Typography variant="display1" color="primary">
                Your booking has been extended.
            </Typography>

            <Typography>
                Your booking has been successfully extended to {props.end.format(TwelveHourTime)}
            </Typography>

            <div style={styles.alertImageContainer}>
                <img src="https://www.sharewithoscar.com.au/assets/graphics/extended_booking.svg" alt="booking extended"/>
            </div>

            <Button style={styles.alertButton} fullWidth variant="contained" 
            onClick={props.onClose} color="primary">
                Got it
            </Button>
        </div>
    )
}

function CancelRequestSuccess(props) {
  //      <div className="mainContent topPadded" style={styles.alertPage}>
  return(
        <div className="sd-container">
          <div className="sd-details">

          <Typography variant="display1" color="primary">
              Your booking request has been cancelled.
          </Typography>

          <Typography>
            Check out other parking spaces available in the area.
          </Typography>

          
      </div>
      <div className="sd-actions">
        <Button style={styles.alertButton} fullWidth variant="contained" 
          onClick={props.onClose} color="primary">
          Search Now
        </Button>

      </div>
    </div>
  );
}

class ExtendBooking extends Component{
    state = {loading: false, successShow: false, over24hrs: false}

    handleTimeRangeSelected = (start, end) => {
        if (end.isAfter(this.props.booking.end)) {
            this.setState({end: end})
        }
    }

    handleExtendBooking = () => {
        this.setState({loading: true})
        const params = {
            duration: this.state.end.diff(this.props.booking.end, 'minutes'),
        }
        extendBooking(params, this.props.booking.reference).then(response => {
            //console.log(response)
            // Show extension success
            this.setState({
                successShow: true,
                loading: false
            })
        }).catch(error => {
            console.error(error.response.data);
            let errorToggle;
            let errorDetail;
            // This is dumb... will do for now

            // was: "Parking space not available"
            // was: "Charge could not be made to default card"
            
            if (error.response.data.detail && error.response.data.detail.substr(0,5) === "ERROR") {
              errorToggle = 'generalError'    // added this to allow for misc. error responses (we really should show the message)
              errorDetail = error.response.data.detail;

            } else if (error.response.data.detail === "NOT-AVAILABLE") {
                errorToggle = 'availabilityError'
            } else if (error.response.data.detail === "CARD-ERROR") {
                errorToggle = 'cardPaymentError'
            }
            this.setState({
                loading: false,
                [errorToggle]: true,
                errorDetail
            })
        })
    }

    handleClose = () => {
        this.setState({successShow: false})
        this.props.onExtend()
    }

    calculateTotal = () => {
      //console.log("parking_space:",this.props.booking.parking_space);
      const parking_space = this.props.booking.parking_space;
      const rate_hourly = parking_space.rate_hourly;
      const rate_daily = parking_space.rate_daily;
      const surcharge_overnight = parking_space.surcharge_overnight || 0;

      //console.log("rate_hourly:",rate_hourly);
      //console.log("rate_daily:",rate_daily);
      //console.log("surcharge_overnight:",surcharge_overnight);

        if (this.props.booking.rate_daily) {
            return {total:0, overnight: 0}
        }

        const bookingTime = this.props.booking.end.diff(this.props.booking.start, 'minutes')
        const underMax = Math.max(420 - bookingTime, 0) //360
        let extraTime = Math.min(this.state.end.diff(this.props.booking.end, 'minutes'), underMax)

        if (bookingTime + extraTime <= 60) {
            extraTime = 0
        }

        // see if an overnight fee applies to this extension
        let overnightFee = 0;
        if (this.props.booking.end.isSame(this.props.booking.start, 'days') && this.state.end.isAfter(this.props.booking.end, 'days')) {
            // Booking start date & end date are the same
            // Extended end date is at least one day greater than the booking end date
            if (this.state.end.diff(this.props.booking.start, 'minutes') >= 420) {
                // New duration is greater than or equal to 7 hours
                overnightFee = surcharge_overnight; //12
            }
        } else if (this.props.booking.end.isAfter(this.props.booking.start, 'days')) {
            // Booking end date is at least a day greater than the booking start date
            if (this.state.end.diff(this.props.booking.start, 'minutes') >= 420) {
                // Booking duration is less than 6 hours
                // Extended booking duration is greater then or equal to 6 hours
                overnightFee = surcharge_overnight; //12
            }
        }

        // #PRICING
        if (!this.props.booking.parking_space.rate_hourly){
          console.error("NO HOURLY RATE");
        }
        return {
            total: ((extraTime / 60 * this.props.booking.parking_space.rate_hourly) + overnightFee).toFixed(2),
            overnight: overnightFee
        }
    }

    render() {
        
        if (this.state.over24hrs) {
            return (
                <BookingLimitExceeded 
                    onDismiss={() => this.setState({over24hrs: false})}
                    buttonMessage="Return to Extend"
                />
            )            
        }

        let extension
        if (this.state.end !== undefined) {
            extension = this.calculateTotal()
        }

        return(
            <div>
                <LoadingModal open={this.state.loading}/>

                <FullPageModal open={this.state.successShow}>
                    <ExtendSuccess onClose={this.handleClose} end={this.state.end}/>
                </FullPageModal>

                <FullPageModal open={this.state.cardPaymentError} >
                    <CardPaymentError
                        onClose={() => this.setState({ 
                            loading: false,
                            cardPaymentError: false 
                        })}
                        last4={this.props.user.profile.card_last4} 
                    />
                </FullPageModal>                
                <FullPageModal open={this.state.availabilityError} >
                    <AvailabilityError
                        onClose={() => this.setState({ 
                            loading: false,
                            availabilityError: false 
                        })}
                    />
                </FullPageModal>
                <FullPageModal open={this.state.generalError} >
                    <AvailabilityError
                        errorDetail={this.state.errorDetail}
                        onClose={() => this.setState({ 
                            loading: false,
                            generalError: false 
                        })}
                    />
                </FullPageModal>

                <div className="mainContent" style={styles.topColored}>
                    <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                        <IconBack style={{color: 'white'}}/>
                    </IconButton>

                    <Typography style={{color: 'white'}} variant="display1">
                        Extend Booking
                    </Typography>            
                </div>
    
                <div className="mainContent" style={styles.pickerContainer}>
                    <Typography variant="body1" style={{marginBottom: '16px'}}>
                        {this.props.booking.start.format(WeekdayDate)}
                    </Typography>
                    <DateTimeRangePicker 
                        withDate={true}
                        verticalForm={true}
                        singleDayRange={true}
                        start={this.props.booking.start}
                        startText="Arriving"                 
                        end={this.props.booking.end}
                        endText="Leaving"
                        onTimeRangeSelected={this.handleTimeRangeSelected}
                        extending={true}
                        onOver24HrPicked={() => this.setState({over24hrs: true})}       
                    />
                    <div style={{borderTop: '1px solid #efefef'}}></div>

                    {this.state.end !== undefined &&
                    <Typography style={{marginTop: '8px'}} variant="caption">
                        Extending will cost an extra <span style={{color: "#1AA5A2"}}>${extension.total}</span>
                        {extension.overnight > 0 && <span style={{marginLeft: '3px'}}>(${extension.overnight} overnight fee applies)</span>}
                    </Typography>}
                </div>

                <Button style={styles.nextButton} variant="contained" color="primary"
                id="extend_confirm"
                onClick={this.handleExtendBooking}
                disabled={this.state.end === undefined}>
                    Extend
                </Button>
            </div>
        )
    }
}

class CancelBooking extends Component{
    state = {loading: false, successShow: false}

    handleReason = (event) => {
        this.setState({ reason: event.target.value })
    }

    handleCancelBooking = () => {
        this.setState({loading: true})
        const params = {
            cancelled: true,
            cancelled_reason: this.state.reason,
        }

        cancelBooking(params, this.props.booking.reference).then(response => {
            //console.log(response)
            this.setState({loading: false})
            // Show extension success
            this.setState({
                successShow: true
            })
        }).catch(error => {
            console.error(error)
            this.setState({loading: false})
        })
    }
    
    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    render() {
        const RoutedCancel = withRouter(CancelSuccess)
        return(
            <div>
                <LoadingModal open={this.state.loading}/>

                <FullPageModal open={this.state.successShow}>
                    <RoutedCancel onClose={this.handleClose} end={this.state.end}/>
                </FullPageModal>

                <div className="mainContent" style={styles.topColored}>
                    <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                        <IconBack style={{color: 'white'}}/>
                    </IconButton>

                    <Typography style={{color: 'white'}} variant="display1">
                        Cancel Booking
                    </Typography>            
                </div>
    
                <div className="mainContent" style={styles.pickerContainer}>
                    <Typography variant="body1" style={{marginBottom: '0px'}}>
                        Cancellations incur a 50% fee if cancelled within 2 hours or a full fee if cancelled within 1 hour from the start of your booking. View our cancellation policy 
                        <span style={styles.link} 
                        onClick={() => this.externalLink(`https://www.sharewithoscar.com.au/oscar-cancellation-policy/`, `https://www.sharewithoscar.com.au/oscar-cancellation-policy/`)}>here</span>.
                    </Typography>

                    <FormControl fullWidth>
                        <SelectField 
                            label="Reason for cancellation" 
                            placeHolder="Select your reason for cancellation"
                            options={[
                                "Spot is unavailable", 
                                "Could not access or exit spot", 
                                "Booking made for the wrong time", 
                                "Don't require the space anymore",
                                "Other"
                            ]}
                            onChange={this.handleReason}
                            value={this.state.reason}
                        />
                    </FormControl>
                </div>


                <Button style={styles.nextButton} variant="contained" color="primary"
                onClick={this.handleCancelBooking}
                id="cancel_confirm"
                disabled={this.state.reason === undefined}>
                    Submit
                </Button>
            </div>
        )
    }
}



class CancelBookingForm extends Component{
  state = {loading: false, successShow: false}

  handleClose = (event) => {
    this.props.onClose();
    
  }


  /*handleReason = (event) => {
      this.setState({ reason: event.target.value })
  }*/

  handleCancelBooking = () => {
      this.setState({loading: true})
      const params = {
          cancelled: true,
          cancelled_reason: this.state.reason,
      }

      cancelBooking(params, this.props.booking.reference).then(response => {
          //console.log(response)
          this.setState({loading: false})
          // Show extension success
          this.setState({
              successShow: true
          })
      }).catch(error => {
          console.error(error);
          this.setState({loading: false});
      })
  }
  
  externalLink = (target, nativeTarget) => {
      if (isNativeWebview()) {
          const message = JSON.stringify({
              action: "external_link",
              target: nativeTarget
          })
          window.ReactNativeWebView.postMessage(message);
      } else {
          window.location.href = target
      }
  }

  render() {
      //const RoutedCancel = withRouter(CancelSuccess)
      //booking={this.props.booking} />
      //console.log("booking:",this.props.booking);

      const start_date = this.props.booking.start_date.replace(/-/g,'');   // 20211029
      const location_date = this.props.booking.location_date.replace(/-/g,'');   // 2021-10-13  
      //const location_date = "20211030";

      //console.log("start_date:",start_date);
      //console.log("location_date:",location_date);

      let formId;
      let itemType;
      let initialPage;
      if (this.props.booking.refunded){
        formId = 'public_oscar_monthly_cancel_booking';
        itemType = 'booking_refund';  //already refunded
        initialPage = 4;

      } else if (this.props.booking.cancelled){
        //console.log("is already cancelled");
        formId = 'public_oscar_monthly_cancel_booking';
        itemType = 'booking_refund';  //only allow refunds
        initialPage = 2;


      } else if ( parseInt(start_date,10) >= parseInt(location_date,10)){
        //console.log("can cancel");  // can cancel any day before the start, PLUS the actual day OF
        formId = 'public_oscar_monthly_cancel_booking';
        itemType = 'booking_cancel';

      } else {
        //console.log("only can end");
        
        if (this.props.booking.cease_date_end){
          //const cease_date = this.props.booking.cease_date.replace(/-/g,'');   // 20211029
          //console.log("has cease_date, set to page 2:",cease_date);
          formId = 'public_oscar_monthly_end_booking';
          itemType = 'booking_end';
          initialPage = 1;

        } else {
          formId = 'public_oscar_monthly_end_booking';
          itemType = 'booking_end';
  
        }
        

    }
      


      return (
              <EditItem_Form
              authChange={this.props.authChange}
              history={this.props.history}
              formId={formId}
              initialPage={initialPage}
              initialData={this.props.booking}  // saves a wasted trip to the server
              itemType={itemType}
              itemId={this.props.booking.reference}
              onClose={this.handleClose}
           />

      );
  }
}


// What a mess...
class EditBookingMenu extends Component {
    state = { 
        cancelBookingOpen: false,
        extendBookingOpen: false,
        cancelBookingFormOpen: false  // Monthly
    }

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    render() {
      let showCancelEnd = this.props.booking.booking_type == 2 ? true:false;
      
      //(old -> !this.props.booking.cancelled)
      // Don't show any bookings whose cease_date has passed
      //console.log(this.props.booking);
      if (this.props.booking.cease_date && this.props.booking.booking_type == 2){
        const cease_date = this.props.booking.cease_date.replace(/-/g,'');   // 2021-10-13  
        const location_date = this.props.booking.location_date.replace(/-/g,'');   // 2021-10-13  
        if (cease_date < location_date){
          showCancelEnd = false;
        }
      }

      return(
            <div>
            <FullPageModal open={this.state.cancelBookingFormOpen}>
                <CancelBookingForm onClose={() => this.props.onClose()} 
                booking={this.props.booking}
                authChange={this.props.authChange} />
            </FullPageModal>

            <FullPageModal open={this.state.extendBookingOpen}>
                <ExtendBooking booking={this.props.booking}
                user={this.props.user}
                onClose={() => { 
                    this.setState({extendBookingOpen: false}) 
                } }
                onExtend={() => {
                    this.setState({extendBookingOpen: false})
                    this.props.onClose()
                }} />
            </FullPageModal>
            <FullPageModal open={this.state.cancelBookingOpen}>
                <CancelBooking onClose={() => this.setState({cancelBookingOpen: false})} 
                booking={this.props.booking} />
            </FullPageModal>

            <div className="mainContent" style={styles.topColored}>
                <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                    <IconBack style={{color: 'white'}}/>
                </IconButton>

                <Typography style={{color: 'white'}} variant="display1">
                    Edit My Booking
                </Typography>            
            </div>
            <div style={{backgroundColor: 'white', height: '520px', paddingTop: '24px'}} className="mainContent">
                {(this.props.booking.booking_type == 1) &&
                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                className="wp-hovercursor" onClick={() => this.setState({extendBookingOpen: true})}>
                    <Grid item>
                        <Typography variant="body1">
                            Extend Booking
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton id="extend_attempt">
                            <IconForward color="primary"/>
                        </IconButton>
                    </Grid>
                </Grid>
                }
                {(this.props.booking.booking_type == 1 && this.props.booking.parking_space && this.props.booking.parking_space.allow_cancellation) &&
                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                className="wp-hovercursor" onClick={() => this.setState({cancelBookingOpen: true})}>
                    <Grid item>
                        <Typography variant="body1">
                            Cancel Booking
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton id="cancel_attempt">
                            <IconForward color="primary"/>
                        </IconButton>
                    </Grid>
                </Grid>
                 }

                {showCancelEnd &&
                    <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                    className="wp-hovercursor" onClick={() => this.setState({cancelBookingFormOpen: true})}>
                        <Grid item>
                            <Typography variant="body1">
                                Cancel or End My Booking
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton id="incident_report">
                                <IconForward color="primary"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                }
                

                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                className="wp-hovercursor" onClick={() => this.externalLink(`https://www.sharewithoscar.com.au/incident/` ,`https://www.sharewithoscar.com.au/incident/`)}>
                    <Grid item>
                        <Typography variant="body1">
                            Report an Incident
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton id="incident_report">
                            <IconForward color="primary"/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
            </div>
        )
    }
}


class CancelRequest extends Component {
  state = {loading: false}

  doCancelRequest = (booking) => {
    this.setState({loading: true});


    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "4", // Cancelled by Driver
      "reason": this.state.driver_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({loading: false, cancelRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });


  }

  externalLink = (target, nativeTarget) => {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}


  render() {

    const thisField = {"isRequired":true, "id":"cancellation_reason", "label":"Reason for cancellation","type":FieldType.SELECT_DROPDOWN, domain:'driver_monthly_cancellation_reason'};
    let fieldErrors = {};
    



    let divTitle;
    let divQuestion;
    if (this.props.booking.parking_space){
      divTitle = <Typography variant="display1" color="primary">Rate {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
      divQuestion = <Typography variant="body1" >How would you rate your experience at the {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
    } else {
      divTitle = <Typography variant="display1" color="primary">Rate this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
      divQuestion = <Typography variant="body1" >How would you rate your experience at the this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
    }
      return(
          <>
          {<FullPageModal open={this.state.cancelRequestSuccessShow}>
            <CancelRequestSuccess onClose={() => this.props.history.push(`/search`)}/>
          </FullPageModal>}


          <div className="mainContent" style={styles.topColored}>
            <LoadingModal open={this.state.loading}/>

            <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                  <IconBack style={{color: 'white'}}/>
            </IconButton>


            <Typography style={{color: 'white'}} variant="display1">
                  Cancel your booking request
            </Typography>            
          </div>


          <div className="sd-container">

            <div className="sd-details">
              <p style={{marginBottom:'20px'}}>Are you sure you want to cancel your booking request?</p>

           
              <SelectDropDown pageData={"pageData"} field={thisField} initialValue={this.state.driver_cancel_reason} error={fieldErrors[thisField.id]} onUpdate={(option) => this.setState({driver_cancel_reason:option.finalValue})} />


              <p style={{marginTop:'20px'}}>View the <a href={`https://www.sharewithoscar.com.au/oscar-cancellation-policy/`} target="_blank" className="oscar-external-link">cancellation policy</a> here.</p>
            </div>
              

          </div>
          <div className="sd-actions">
            <Button id="db_thumbs_up" style={styles.editButton} fullWidth variant="contained" color="primary"
              onClick={() => this.props.onClose()}>
                Back
            </Button>

            <Button id="db_thumbs_down" fullWidth variant="contained" color="primary"
              disabled={!this.state.driver_cancel_reason}
              onClick={() => this.doCancelRequest(this.props.booking)}>
                Cancel Request
            </Button> 
          </div>
        </>
      )
  }
}





class ReviewBooking extends Component {
    state = {loading: false}

    handleReview = (review) => {
        this.setState({loading: true})
        reviewBooking({review: review}, this.props.booking.reference).then(response => {
            if (this.props.forced) {
                updateUserProfileAjax().then(response=> {
                    this.props.authChange()
                    this.props.history.push('/')            
                    this.setState({loading: false})
                })
            } else {
                this.props.onClose()
            }
        }).catch(error => {
            console.error(error)
            this.setState({loading: false})
        })
    }

    render() {

      let divTitle;
      let divQuestion;
      if (this.props.booking.parking_space){
        divTitle = <Typography variant="display1" color="primary">Rate {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
        divQuestion = <Typography variant="body1" >How would you rate your experience at the {this.props.booking.parking_space.type} on {this.props.booking.parking_space.street_name}</Typography>;
      } else {
        divTitle = <Typography variant="display1" color="primary">Rate this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
        divQuestion = <Typography variant="body1" >How would you rate your experience at the this.props.booking.parking_space.type on this.props.booking.parking_space.street_name</Typography>;
      }
        return(
            <div className="mainContent topPadded" style={styles.alertPage}>
                <LoadingModal open={this.state.loading}/>

                <IconButton className="leftCornerButton" onClick={() => this.handleReview("Neutral")}>
                    <IconClose />
                </IconButton>

                {divTitle}
                {divQuestion}

                <div style={styles.alertImageContainer}>
                    <img src="https://www.sharewithoscar.com.au/assets/graphics/rate_spot.svg" alt="review request for a spot"/>                    
                </div>
                
                <Grid container spacing={16} style={{position:"absolute", width: "93%",bottom: '16px'}}>

                    <Grid item xs={6}>
                        <Button id="db_thumbs_up" style={styles.editButton} fullWidth variant="contained" color="primary"
                         onClick={() => this.handleReview("Negative")}>
                            Thumbs Down
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button id="db_thumbs_down" fullWidth variant="contained" color="primary"
                         onClick={() => this.handleReview("Positive")}>
                            Thumbs Up
                        </Button> 
                    </Grid>
                </Grid>
            </div>
        )
    }
}

class RepeatBooking extends Component {
    state = {enquireOpen: false}
    render() {
      let spaceType = getSpaceType(this.props.booking.parking_space);

      return(
            <div style={{height: '100%', overflowY: 'auto'}}>
                <FullPageModal open={this.state.enquireOpen}>
                    <LongTermEnquiry 
                        onClose={() => this.setState({ enquireOpen: false })}
                        history={this.props.history}
                        spaceUuid={this.props.booking.parking_space.uuid}
                        spaceType={spaceType}
                    />
                </FullPageModal>
                
                <div className="mainContent" style={styles.topColored}>
                    <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                        <IconBack style={{color: 'white'}}/>
                    </IconButton>

                    <Typography style={{color: 'white'}} variant="display1">
                        Repeat Booking
                    </Typography>            
                </div>
                <div style={{backgroundColor: 'white', paddingTop: '16px'}} className="mainContent">
                    <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                    onClick={() => this.props.history.push(`/Search/${this.props.booking.parking_space.street_name}/${this.props.booking.parking_space.latitude}/${this.props.booking.parking_space.longitude}/${this.props.booking.parking_space.uuid}`)}>
                        <Grid item>
                            <Typography variant="body1">
                                Single Booking
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton id="extend_attempt">
                                <IconForward color="primary"/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    
                    {/*<Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                    onClick={() => this.setState({enquireOpen: true})}>
                        <Grid item>
                            <Typography variant="body1">
                                Enquire for Long Term Booking
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton id="cancel_attempt">
                                <IconForward color="primary"/>
                            </IconButton>
                        </Grid>
      </Grid>*/}

                </div>
            </div>            
        )
    }
}

export default class BookingDetails extends Component {
    state = {
        loading: false,
        edittingBooking: false,
        reviewBooking: this.props.match.params.review !== undefined,
        forcedReview: this.props.match.params.review !== undefined
    }

    componentWillMount() {
        this.updateBooking()
    }

    //TODO: ONLY FETCH *CURRENT* BOOKING, not a giant list of every single one... this is so dumb
    updateBooking() {
        this.setState({loading: true})

        let that = this;
        const bookingRef = this.props.match.params.reference;
        getBookingDetails(bookingRef).then(details => {
          //console.log(details.data);
          const booking = details.data;
          booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters);
          
          if (booking.cease_date) {
            booking.end = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters);
          }

          if (booking.end){
            booking.expired = booking.end.isBefore(moment()) || booking.cancelled;
          }
          

          that.setState({
            loading: false,
            booking
          });

        });






        /*getTrips().then(response => {
            console.log(response.data)
            
//return;
            const booking = response.data.find(elem => elem.reference === this.props.match.params.reference)
            if (booking){
              booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters)
              booking.end = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters)
              
              booking.expired = booking.end.isBefore(moment()) || booking.cancelled
  
            }

            this.setState({
                loading: false,
                booking: booking,
            })
        })*/
    }

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            //window.location.href = target
            const newWindow = window.open(target, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    }

    render() {
        if (this.state.loading) {
            return(
                <LoadingModal open={this.state.loading} />
            )
        }
        

        if (!this.state.booking){
          return (<div>NO BOOKING FOUND.</div>);
        }

       
        const booking = this.state.booking;

        if (!booking.parking_space){
          return (<h1>Please use the Meriton by Oscar app to view this booking.</h1>);
        }



        let images = getImageListForSpace(booking.parking_space,null,null,true,null,250);  // no size, will use SmartImage 250);
        //images = [];
        if (images.length == 0){
          //const imagePath = GetStreetView(booking.parking_space.latitude, booking.parking_space.longitude);
          let imagePath = 'https://images.sharewithoscar.com.au/assets/graphics/no-photo.png'; // cp-logos/no-logo-2.png';
          //images = (<div className="img-holder" key={booking.parking_space.uuid} style={{backgroundImage: `url("${imagePath}")`}}></div>);

          images = (<div className="img-holder" key={booking.parking_space.uuid} style={{backgroundColor:'#d8d8d8',padding:'100px'}}><img src={imagePath}/></div>);
        }
  

        let address;
        if (booking.parking_space){
          address = this.state.booking.expired ? this.state.booking.parking_space.street_name : this.state.booking.parking_space.address.replace("Australia", this.state.booking.parking_space.postcode)

        } else {
          address = "no address??";

        }

        let strType;
        let instruction;
        if (booking.parking_space){
          strType = booking.parking_space.type;
          instruction = booking.parking_space.instruction;
          
        } else {
          strType = "MERITON";
          instruction = "no bay instructions!";
        }
        

        // parking_space
        let str_end_date;
        if (booking.end){
          str_end_date = booking.end.format(BookingsRangeDisplayFormat)
        } else {
          str_end_date = "Ongoing";
        }

        let divApproval;

        if (booking.approval_status === 1){
          divApproval = <div className="approval-waiting">Booking Request {ENUMS.approval_status[booking.approval_status || 0]}</div>;
        } else if (booking.approval_status === 2){
          divApproval = <div className="approval-confirmed">Booking Request {ENUMS.approval_status[booking.approval_status || 0]}</div>;
        } else if (booking.approval_status === 3 || booking.approval_status === 4){
          divApproval = <div className="approval-declined">Booking Request {ENUMS.approval_status[booking.approval_status || 0]}</div>;
        }
        
        //console.log({booking});
        let strApprovalCutOff = moment(booking.create_date).add(48,"hours").format("Do MMMM YYYY");

        /*return (<div style={{height: '100%'}} className="booking-details">
                <LoadingModal open={this.state.loading}/>
                <FullPageModal open={this.state.cancelRequest}>
                  <CancelRequest
                    history={this.props.history}
                    booking={booking}
                    onClose={() => {
                      //this.updateBooking()
                      this.setState({cancelRequest: false})
                  }}

                  />
                  </FullPageModal>

                  <FullPageModal open={this.state.edittingBooking}>
                    <EditBookingMenu 
                        booking={booking}
                        onClose={() => {
                            this.updateBooking()
                            this.setState({edittingBooking: false})
                        }}
                        history={this.props.history}
                        authChange={this.props.authChange}
                        user={this.props.user}
                    />
                </FullPageModal>
                <FullPageModal open={this.state.repeatBooking}>
                    <RepeatBooking 
                        booking={booking}
                        onClose={() => {
                           this.setState({repeatBooking: false})
                        }}
                        history={this.props.history}
                    />
                </FullPageModal>
                <FullPageModal open={this.state.reviewBooking}>
                    <ReviewBooking 
                        booking={booking}
                        onClose={() => {
                           this.setState({reviewBooking: false})
                        }}
                        forced={this.state.forcedReview}
                        history={this.props.history}
                        authChange={this.props.authChange}
                    />
                </FullPageModal>                  
          <div className="sd-container booking-details">
            details here
            details here
            details here
            details here
            details here
            details here
            details here
            details here
            details here
            details here
            details here
            details here
            details here
            </div>
        </div>);*/
        return(
            <div className="sd-container booking-details">
                <LoadingModal open={this.state.loading}/>

                <FullPageModal open={this.state.cancelRequest}>
                  <CancelRequest
                    history={this.props.history}
                    booking={booking}
                    onClose={() => {
                      //this.updateBooking()
                      this.setState({cancelRequest: false})
                  }}

                  />
                </FullPageModal>
                <FullPageModal open={this.state.edittingBooking}>
                    <EditBookingMenu 
                        booking={booking}
                        onClose={() => {
                            this.updateBooking()
                            this.setState({edittingBooking: false})
                        }}
                        history={this.props.history}
                        authChange={this.props.authChange}
                        user={this.props.user}
                    />
                </FullPageModal>
                <FullPageModal open={this.state.repeatBooking}>
                    <RepeatBooking 
                        booking={booking}
                        onClose={() => {
                           this.setState({repeatBooking: false})
                        }}
                        history={this.props.history}
                    />
                </FullPageModal>
                <FullPageModal open={this.state.reviewBooking}>
                    <ReviewBooking 
                        booking={booking}
                        onClose={() => {
                           this.setState({reviewBooking: false})
                        }}
                        forced={this.state.forcedReview}
                        history={this.props.history}
                        authChange={this.props.authChange}
                    />
                </FullPageModal>
                      
                        
                  <IconButton style={styles.leftTopButton}
                    onClick={() => this.props.history.push('/bookings')}>
                    <IconClose style={{color: 'rgb(207, 207, 207)'}}/>
                  </IconButton>

                  <Carousel 
                    showArrows={true} 
                    showStatus={false} 
                    showIndicators={false} 
                    showThumbs={false} 
                    infiniteLoop={true} 
                  >
                    {images}
                  </Carousel>
                  <div className="oscar-owner-profile">
                  {!booking.expired && booking.approval_status == 2 &&
                    <Button variant="contained" color="primary" onClick={() => this.props.history.push(`/messages/${this.props.match.params.reference}/driver/`)}>Contact Owner</Button>                   
                    }         
                  </div>

                  <div className="sd-details">

                    <h1>{address}</h1>
                    {booking.booking_type == 2 && <div className="booking-ref">{booking.start.format(WPMonthlyBookingsDisplayFormat)} - {str_end_date}</div>}
                    {booking.booking_type != 2 && <div className="booking-ref">{booking.start.format(BookingsRangeDisplayFormat)} - {str_end_date}</div>}
                    <div className="booking-ref">Reference: {booking.reference}</div>
                    

                    {/* DISABLED for now... (this.state.booking.expired && this.state.booking.parking_space) && <Button style={styles.messageButtonxx} variant="contained"
                    onClick={() => this.setState({reviewBooking: true})}>
                        Rate this spot
                    </Button>*/}

                    {divApproval}

                    {booking.approval_status == 1 &&
                      <Typography variant="body1">The owner has until the end of <b>{strApprovalCutOff}</b> to accept this offer or it will be automatically cancelled.</Typography>
                    }


                    {booking.expired &&
                      <div>
                          <Typography style={{marginTop: '24px'}} variant="body1">
                              This booking has expired
                          </Typography>
                      </div>
                    }

                    {/*booking.approval_status === 1 &&
                      <>
                        <h2 style={{color:'#1AA5A2',fontSize:'14px',marginBottom:'0'}}>Access Instructions</h2>
                        <div style={{color:'#1AA5A2'}}>Will be supplied on approval by owner.</div>
                      </>
                    */}



                    {!booking.expired && booking.approval_status === 2 &&
                      <div>
                        <h2 style={{color:'#1AA5A2',fontSize:'14px',marginBottom:'0'}}>Access Instructions</h2>
                        <div style={{color:'#1AA5A2'}}><div dangerouslySetInnerHTML={{__html: instruction}}/></div>
                        {booking.access_code &&
                          <>
                            <h2 style={{color:'#1AA5A2',fontSize:'14px',marginBottom:'0'}}>Access Code</h2>
                            <div>{booking.access_code}</div>
                          </>
                        }

                      </div>
                    }

                    {booking.approval_status === 3 &&
                      <div>
                        Unfortunately, the owner has declined your request to book. Take a look at some alternate parking spaces
                      </div>
                    }

                    {booking.approval_status === 4 &&
                      <div>
                        This booking was cancelled by the driver
                      </div>
                    }
                  



                  
                  </div>

                  <div className='sd-actions'>
                    {booking.approval_status == 1 && 
                      <>
                        <Button variant="contained" color="primary" 
                          onClick={() => this.props.history.push(`/messages/${this.props.match.params.reference}/driver/`)}>
                            Contact Owner
                        </Button>                            
                        <Button id="bd_directions" fullWidth variant="contained" color="primary"
                        onClick={() => this.setState({cancelRequest: true})}>
                            Cancel Request
                        </Button>
                      </>
                    }

                    {booking.expired && booking.approval_status == 2 &&
                      <>
                        {booking.booking_type == 1 && <Button fullWidth variant="contained" color="primary"
                        onClick={() => this.setState({repeatBooking: true})}>
                            Re-Book This Spot
                        </Button>}
                        <Button style={styles.editButton} fullWidth variant="contained" color="primary"
                        onClick={() => this.externalLink(`https://www.sharewithoscar.com.au/incident/` ,`https://www.sharewithoscar.com.au/incident/`)}
                        >
                            Report an incident
                        </Button>
                      </>                      
                    }


                    {!booking.expired && booking.approval_status == 2 &&
                      <>
                        <Button id="bd_directions" fullWidth variant="contained" color="primary"
                          onClick={() => this.externalLink(`https://www.google.com/maps/?q=${encodeURI(booking.parking_space.address)}` ,`maps://${booking.parking_space.latitude},${booking.parking_space.longitude}?q=${encodeURI(booking.parking_space.address)}`)}>
                              Get Directions
                        </Button>
                        <Button id="bd_edit" style={styles.editButton} fullWidth variant="contained" color="primary"
                        onClick={() => this.setState({edittingBooking: true})}>
                            Edit my booking
                        </Button>
                      </>                      
                    }

                  </div>

                </div>

              
        )
    }
}

//    <div style={styles.contentContainer}>