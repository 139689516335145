import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {
    Button,
    Typography,
} from '@material-ui/core';

import { isNativeWebview } from '../utils/IsNativeWebview';

import LoadingModal from "../components/LoadingModal.js";

import Carousel from 'react-responsive-carousel/lib/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import '../styles/welcome_carousel.css';

const buttonTopMargin = '12px'
const styles = {
    outsideContainer: {
        maxWidth : '500px',
        flexGrow: '1',
    },
    logoContainer: {
        width: '100%', 
        textAlign: 'center',
        marginTop: '24px',
        marginBottom: '24px',
    },
    oscarLogo: {
        width: '200px',
        height: 'auto',
        marginLeft: '-5px',
        marginTop: '20px'    
    },
    imageCarousel: {
        width: '100%',
        //height: '250px',
    },
    carouselImage: {
        width: 'auto',
        height: 'auto'
    },
    slideHeadline: {
        color: 'white',
        marginTop: '24px',
        marginBottom: '8px',
    },
    slideText: {
        color: 'white',
        marginBottom: '24px',
    },
    createButton: {
        marginTop: buttonTopMargin,
        borderRadius: '32px',
        color: '#1AA5A2',
        backgroundColor: 'white',
        fontWeight: 'bold',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    loginButton: {
        marginTop: buttonTopMargin,
        borderRadius: '32px',
        color: 'white',
        border: '2px solid white',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    explore: {
        width: '100%',
        color: 'white',
        textDecoration: 'underline',
        margin: '24px 0px',
    },
}

export default class SignUp extends Component {
    state={loading: false, webView: false}

    componentWillMount() {
        this.checkOwnerSignup(this.props)
    }

    componentWillReceiveProps = (nextProps) => {
        this.checkOwnerSignup(nextProps)
    }

    checkOwnerSignup(props) {
        if (props.customRedirect) {
            localStorage.setItem('FINISH_PROFILE_REDIRECT', '/listspace')
        } else {
            localStorage.removeItem('FINISH_PROFILE_REDIRECT')
        }
    }

    authSuccess = () => {
        // We successfully authenticated, so return to the landing page.
        this.props.authChange();
        this.setState({loading: false});
        this.props.history.push('/search');
    }
    authError = (error) => {
        // TODO: validation messages for errors
        console.log(error)
        this.setState({loading: false});
    }

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    render() {
        return (
            <div className="gradientPage" style={{height: '100%', overflowY: 'auto'}}>
                <LoadingModal open={this.state.loading} />
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={styles.outsideContainer}>
                        <div className="mainContent">
                            <div style={styles.logoContainer}>
                                <img src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_white.svg" alt="Oscar logo" style={styles.oscarLogo} />
                            </div>

                            <div style={{textAlign: 'center'}}>
                            <div style={{width: '324px', display: 'inline-block'}}>
                                <div style={styles.imageCarousel}>
                                    <Carousel 
                                        showArrows={false} 
                                        showStatus={false} 
                                        showIndicators={true} 
                                        showThumbs={false} 
                                        infiniteLoop={true} 
                                        autoPlay={true}
                                        interval={3000}
                                    >
                                        <div style={{height: '330px'}}>
                                            <img style={styles.carouselImage} key={0} src="https://www.sharewithoscar.com.au/assets/graphics/onboard_garage.svg" alt="The parking space"/>
                                            <Typography variant="headline" style={styles.slideHeadline}>
                                                Park instantly
                                            </Typography>
                                            <Typography variant="body1" style={styles.slideText}>
                                                Book to park in thousands of private spaces across Sydney
                                            </Typography>
                                        </div>
                                        <div style={{height: '330px'}}>
                                            <img style={styles.carouselImage} key={1} src="https://www.sharewithoscar.com.au/assets/graphics/onboard_phone.svg" alt="The parking space"/>
                                            <Typography variant="headline" style={styles.slideHeadline}>
                                                Extend on the go
                                            </Typography>
                                            <Typography variant="body1" style={styles.slideText}>
                                                Extend and edit your booking from your phone
                                            </Typography>
                                        </div>
                                        <div style={{height: '330px'}}>
                                            <img style={styles.carouselImage} key={2} src="https://www.sharewithoscar.com.au/assets/graphics/onboard_money.svg" alt="The parking space"/>
                                            <Typography variant="headline" style={styles.slideHeadline}>
                                                Share and earn
                                            </Typography>
                                            <Typography variant="body1" style={styles.slideText}>
                                                Share your space for an hour, day or week and earn a passive income
                                            </Typography>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                            </div>
                            
                            <Button id="create_account" style={styles.createButton} variant="outlined" fullWidth
                                component={Link} to="/SignUp"
                            >
                                Sign up
                            </Button>
                            <Button style={styles.loginButton} variant="outlined" fullWidth
                                component={Link} to="/Login"
                            >
                                Log in
                            </Button>
                        </div>
                        
                        <div style={styles.explore} onClick={() => this.props.history.push('/Search')}>
                            <Typography variant="title" align="center" style={{color: 'white', fontSize: '20px', cursor:'pointer'}}>
                                Explore the App
                            </Typography>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}