// Quickly implemented replacement for new ATO requirements

import React, {useState, useEffect} from "react";

import { Button,
FormControl,
Grid,
IconButton,
TextField,
Typography,
withStyles,
Dialog,
Icon,
} from "@material-ui/core";
import IconBack from "@material-ui/icons/KeyboardArrowLeft";

import {
  createUserPayoutMethod,
  getUserPayoutMethod,
  savePaymentCard,
  updateUserPayoutMethod,
  getUserPaymentPreferences,
  updateUserPaymentPreferences
} from "../api/Oscar-api";

import SliderSwitch from "../components/forms/SliderSwitch";

import GooglePlacesSearchWP from "../components/GooglePlacesSearchWP";
import { FullscreenExit } from "@material-ui/icons";
//import { Button } from "@material-ui/core";



const PayoutDetailsV3 = ({ onClose }) => {
  const [fieldValues, setFieldValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);      //1 + 2

  const browserCSSMode = window.innerWidth >= 960 ? "desktop":"mobile";

  useEffect( () => {
    let ignore = false;

    getUserPayoutMethod()
      .then((response) => {
        //console.log(response.data)
        /*this.setState({
          loading: false,
          endPoint: updateUserPayoutMethod,
          formValues: response.data,
          monthlyPayout: response.data.monthly_payout,
        });*/

        const fv = response.data;
        setIsBusiness(fv.abn_dob && fv.abn_dob.length >= 12 ? true:false);
        setIsBusiness(true)
        setFieldValues(response.data);

      })
      .catch((error) => {
        /*this.setState({
          loading: false,
          endPoint: createUserPayoutMethod,
        });*/
        setIsNew(true);
        console.log("ERROR:",error);
      });


    return () => {
      //console.log("useEffect cleanup, setting ignore=true");
      ignore = true;
    };

  },[]);


  const onLocationSelected_FullDetails = (newValue,latLng, street_name, suburb, state_name, postcode) => {
    updateFieldValue({target: {id:'address',value:newValue}});

    /*//fieldValues[inJSON.id] = newValue;
    let fieldValues2 = fieldValues;
    fieldValues2['address'] = newValue.split(',')[0];
    fieldValues2['latitude'] = latLng.lat;
    fieldValues2['longitude'] = latLng.lng;
    fieldValues2['street_name'] = street_name;
    fieldValues2['suburb'] = suburb;
    fieldValues2['state'] = state_name;
    fieldValues2['postcode'] = postcode;

    setFieldValues(fieldValues2);*/
  }




  const updateFieldValue = (e) => {
    const id = e.target.id;
    const newValue = e.target.value;

    let fieldValues2 = JSON.parse(JSON.stringify(fieldValues));
    fieldValues2[id] = newValue;
    setFieldValues(fieldValues2);
  }


  const goBack = () => {
    if (currentPage > 1){
      setCurrentPage(currentPage-1);
    } else {
      onClose();
    }
  }

  
  const doSave = (thisPage) => {
    
    if (thisPage == 1){

      let strError = '';
      if (!fieldValues['account_name'] || fieldValues['account_name'] == '' || fieldValues['account_name'].length < 3){
        strError = 'Please enter your account name';
      } else if (!fieldValues['bsb'] || fieldValues['bsb'] == '' || fieldValues['bsb'].length < 6){
        strError = 'Please enter your BSB number';
      } else if (!fieldValues['account_number'] || fieldValues['account_number'] == '' || fieldValues['account_number'].length < 6){
        strError = 'Please enter your account number';
      }


      if (strError){
        setHasError(strError);
      } else {
        setHasError(null);
        setCurrentPage(2);
      }
      
      return;
    }
    

    let strError = '';
    if (!fieldValues['full_name'] || fieldValues['full_name'] == '' || fieldValues['full_name'].length < 3){
      strError = isBusiness ? 'Please enter your business name':'Please enter your full name';
    } else if (!fieldValues['abn_dob'] || fieldValues['abn_dob'] == '' || fieldValues['abn_dob'].length < 6){
      strError = isBusiness ? 'Please enter your ABN':'Please enter your date of birth';
    } else if (!fieldValues['address'] || fieldValues['address'] == '' || fieldValues['address'].length < 6){
      strError = isBusiness ? 'Please enter your business address':'Please enter your home address';
    }


    if (strError){
      setHasError(strError);
      return;
    }



    setIsLoading(true);
    setHasError(null);
    if (isNew){
      createUserPayoutMethod(fieldValues).then((response) => {
        setTimeout(() => { onClose() }, 1500);        
      })
      .catch((error) => {
        console.log("ERROR:",error);
        setHasError('Could not process your request');
        setIsLoading(false);
      });    
    
    } else {
      updateUserPayoutMethod(fieldValues).then((response) => {
        setTimeout(() => { onClose() }, 1500);        
      })
      .catch((error) => {
        console.log("ERROR:",error);
        setHasError('Could not process your request');
        setIsLoading(false);
      });    

    }

  }



  return (
    <div className={isLoading ? "v3_dd-container v3_payout_container is_loading":"v3_dd-container v3_payout_container"}>
      <div className="up-header">
          <IconButton className="leftCornerButton" onClick={goBack}>
            <IconBack style={{ color: "white" }} />
          </IconButton>
        <h1>Payout Details</h1>
      </div>
      <div className="v3_payout_content">
        

        <form>
          {currentPage == 1 &&
            <>
            <h3>We transfer your earnings to your nominated account on the 15th of every month. Please enter your bank account details below:</h3>
            
            <label htmlFor="account_name">Account name</label>
            <input value={fieldValues['account_name'] ||''} onChange={updateFieldValue} tabIndex={1} autoComplete="off" disabled={isLoading} required={true} id="account_name" name="account_name" type="text" placeholder="" />


            <label htmlFor="bsb">BSB number</label>
            <input value={fieldValues['bsb'] || ''} onChange={updateFieldValue} tabIndex={2} autoComplete="off" disabled={isLoading} required={true} id="bsb" name="bsb" type="phone" aria-invalid={undefined} placeholder="" />

            <label htmlFor="account_number">Account number</label>
            <input value={fieldValues['account_number'] || ''} onChange={updateFieldValue} tabIndex={3} autoComplete="off" maxLength={10} disabled={isLoading} required={true} id="account_number" name="account_number" type="phone" aria-invalid={undefined} placeholder="" />

            </>

          }

          {currentPage == 2 &&
          
          
          <>
          
          <h3>For us to transfer your earnings, please provide further details below:</h3>

          <div className="is_business">
            <p>I am operating as a Business with ABN</p>
            <SliderSwitch checked={isBusiness} onChange={() => setIsBusiness(!isBusiness)}/>
          </div>

          

          <label htmlFor="full_name">{isBusiness ? 'Your business name':'Your full name'}</label>
          <input value={fieldValues['full_name'] || ''} onChange={updateFieldValue} tabIndex={1} autoComplete="off" maxLength={100} disabled={isLoading} required={true} id="full_name" name="full_name" type="text" aria-invalid={undefined} placeholder="" />

          <label htmlFor="full_name">{isBusiness ? 'ABN':'Date of birth'}</label>
          <input value={fieldValues['abn_dob'] || ''} onChange={updateFieldValue} tabIndex={2} autoComplete="off" maxLength={12} disabled={isLoading} required={true} id="abn_dob" name="abn_dob" type="text" aria-invalid={undefined} placeholder="" />



          <label>{isBusiness ? 'Your Business address':'Your home address'}</label>
          <GooglePlacesSearchWP
                    name="address" 
                    placeholder="Enter your address"
                    onLocationSelected_FullDetails={onLocationSelected_FullDetails} 
                    address={fieldValues['address'] || ''}
                    desktopDisplay={browserCSSMode === 'desktop' ? true:false} // this is not used anymore???
                    browserCSSMode={browserCSSMode}  // destkop/mobile
                />

          </>
          }

            <div className="pm-dderrormsg">{hasError}</div>
        </form>


        {currentPage == 1 ?
        <Button tabIndex={5} className="wp-button" type="button" onClick={() => doSave(1)} disabled={isLoading}>{isLoading ? "Please wait...":"Next"}</Button>
        :
        <Button tabIndex={5} className="wp-button" type="submit" onClick={() => doSave(2)} disabled={isLoading}>{isLoading ? "Please wait...":"Save"}</Button>
        }


        


        </div>      
</div>
);

}
export default PayoutDetailsV3;