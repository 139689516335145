import { createMuiTheme } from '@material-ui/core/styles';

const OscarTheme = createMuiTheme({
    palette: {
        primary: {
            light: "#7ECACA",
            main: "#1AA5A2",
            dark: "#007872",
        },
        secondary: {
            light: "#FFEC66",
            main: "#FED100",
            dark: "#FA7300",
        },
    },
    typography: {
        fontFamily: "Rubik",
        display4: {
            fontWeight: "Medium",
            fontSize: '75px',
            letterSpacing: '-0.5',
        },
        display3: {
            fontWeight: "Medium",
            fontSize: '60px',
            letterSpacing: '-0.5',
        },
        display2: {
            fontWeight: "Regular",
            fontSize: '46px',
            letterSpacing: '0',
        },
        display1: {
            fontWeight: "Medium",
            fontSize: '36px',
            letterSpacing: '0.25',
            marginBottom: '20px',
        },
        headline: {
            fontWeight: "Medium",
            fontSize: '24px',
            letterSpacing: '0',
        },
        title: {
            fontWeight: "Regular",
            fontSize: '22px',
            letterSpacing: '-0.2',
        },
        body1: {
            fontWeight: "Regular",
            fontSize: '16px',
            letterSpacing: '0.5',
        },
        body2: {
            fontWeight: "Regular",
            fontSize: '14px',
            letterSpacing: '0.25',
        },
        subheading: {
            fontWeight: 100,
            fontSize: '16px',
            letterSpacing: '0.15',
        },
        caption: {
            fontWeight: "Regular",
            fontSize: '12.19px',
            letterSpacing: '0.4',
        },
        button: {
            fontWeight: "Medium",
            fontSize: '16px',
            letterSpacing: '0.15',
            textTransform: 'capitalize',
        },
    },
    shape: { // Have to supply this or Paper.js crashes... why?
      borderRadius: 5
    },
    overrides: {
        // MuiCardContent: {
        //     root: {
        //         paddingBottom: '0px!important'
        //     },
        // },
        MuiButton: {
            contained: {
                boxShadow: 'none'
            }
        },
        MuiDrawer: {
            paperAnchorBottom: {
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
            }
        },
        MuiFormControl: {
            
            fullWidth: {
                marginTop: '40px',
            }
        },
        MuiInput: {
            root: {
                width: '100%'
            },
            // underline: {
            //     '&:before': { //underline color when textfield is inactive
            //       borderBottom: '2px solid white',
            //     },
            //     '&:focused:not($disabled):before': { //underline color when hovered 
            //         borderBottom: '2px solid white',
            //     },
            //   }
        },
        MuiInputLabel: {
            formControl: {
                fontSize: '16px',
                fontWeight: 'bold',
                top: '-15px',
                textTransform: 'uppercase',    
            }
        },
        MuiMenuItem: {
            root: {
                paddingLeft: '24px',
            },
        },
        MuiPaper: {
            elevation2: {
                padding: '10px',
            },
        },
        MuiSlider: {
            root: {
                //marginTop: '20px',
                //height:'10px',
                //width: '80vw',
            },
            track: {
                height:'5px'
            }
        }
    },
});

const whiteInputStyles = {
    focused: {},
    disabled: {},
    error: {},
    input: {
        color: 'white',
        '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 1)'
        },
        '&:after': {
            borderBottom: `2px solid rgba(255, 255, 255, 1)`,
            borderBottomColor: `rgba(255, 255, 255, 1)`
        },
        '&$inputError': {
            '&:after': {borderBottomColor: `rgba(255, 255, 255, 1)`}
        },
        '&:after:$error': {
            borderBottomColor: `rgba(255, 255, 255, 1)`
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid rgba(255, 255, 255, 1)`
        }
    },
    inputError: {},
    formLabelRoot: {
        color: 'white',
        '&$formLabelError': { color: 'white' },
        '&$formLabelFocused': { color: 'white', opacity: 0.5 },
    },
    formLabelError: {},
    formLabelFocused: {},
    helperText: {
        color: 'white',
        '&$helperTextError': { color: 'white' },
    },
    helperTextError: {},

    whiteButton: {
        textTransform: 'none',
    }
}

export {OscarTheme, whiteInputStyles}