import React, { Component } from 'react';
//import {Select,Progress,Card,Divider,Transition, Dimmer, Loader, Grid,Form, Segment, Menu, Container, Step, Label, Button, Input, Message, Icon, List, Dropdown, Modal, Header } from 'semantic-ui-react';
//import {Form, Input, Label} from 'semantic-ui-react';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    Typography
} from '@material-ui/core';


import { FieldType } from '../api/fieldTypes.js';

class TextString extends Component {
  constructor(props) {
    super(props);

    //console.log("[TEXTSTRING:constructor]");
    //console.log("TextString: ",this.props);

    let inValue = this.props.initialValue || '';

    var placeholder;
    var labelLeft;
    var labelRight;
    var labelPosition;
    var maxLength;
    const myType = this.props.field.type;
    var keyboardType = 'text';


    if (myType === FieldType.TEXT_STRING) {
      //console.log("FORMAT: TEXT STRING");
      placeholder = this.props.field.placeholder || '';
      maxLength = 60;

    } else if (myType === FieldType.TEXT_NUMERIC) {
      //console.log("FORMAT: TEXT NUMERIC");
      placeholder = '';
      maxLength = 60;
      keyboardType = 'tel';

    } else if (myType === FieldType.TEXT_EMAIL) {
      //console.log("FORMAT: TEXT EMAIL");
      placeholder = 'yourname@example.com';
      maxLength = 80;
      keyboardType = 'email';

    } else {
      console.log("UNKNOWN FORMAT");
      placeholder = '0';
      maxLength = 0;

    }

    var errorMessage = this.props.error; //start null

    this.state = {
      myType,
      isVisible: true,
      value: inValue,
      placeholder,
      maxLength,
      keyboardType,
      errorMessage
    };


    this.onFocus = this.onFocus.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }


  onFocus(e) {
    //e.target.select();
  }

  onKeyPress(e) {
    /*    if (this.state.isLocked){
          return;
        }
    */
    if (e.key === 'Enter') {
      e.target.blur();
    }

  }

  //========================
  //Live Validation
  //========================
  onChange(e) {
    var inText = e.target.value;
    
    // if needed, accept only valid characters while typing
    //console.log("inText:",inText);
    
    this.setState({value: inText}); 
  }


  //========================
  //Final Validation
  //========================
  onBlur(e) {

    let inText = e.target.value;
    let finalValue = inText;

    const {myType} = this.state;
    var strErrorMessage = null;

    if (inText.length === 0){
      // Allow blank values, will be caught later
    } else if (myType === FieldType.TEXT_EMAIL){
      //=== EMAIL ===
        let inPattern = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
        let regex = new RegExp(inPattern,"i"); 

        if (!regex.test(inText)){
          strErrorMessage = "Please enter a valid email address";
          finalValue = '';
        }

    }

    //Send the valid value to the parent form
    if (this.props.onUpdate){
      this.props.onUpdate({id:this.props.field.id,finalValue});
    } 
    // ?? strErrorMessage = "xxxxxxx";
    this.setState({errorMessage:strErrorMessage});
  }


  render() {
    const {errorMessage} = this.state;

    return (
        <TextField
          maxLength={this.state.maxLength}
          onChange={this.onChange}  
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          id={this.props.field.id}
          className={this.props.field.id}
          style={{marginTop:'0'}}
          //multiline
          value={this.state.value}
          //rows={3}
          label={this.props.field.label}
          name={this.props.field.id}
          type="text"
          InputLabelProps={{
              shrink: true
          }}
          /*InputProps={{
            readOnly: true,
          }}*/
          disabled={this.props.field.readOnly}     
          placeholder={this.state.placeholder}
          fullWidth
          margin="normal"
          error={this.props.error ? true:false}
          helperText={this.props.error}
        />

        
    );
    

  }

}

export default TextString;
