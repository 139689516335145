import React, {Component} from 'react';

// Supported form elements:
import {
    Input,
    Select,
    TextField
} from "@material-ui/core";

import HiddenField from "../components/forms/HiddenField.js";
import GooglePlacesField from '../components/forms/GooglePlacesField.js';
import NumberField from '../components/forms/NumberField.js';
import SelectField from '../components/forms/SelectField.js';
import SelectFieldWP from '../components/forms/SelectFieldWP.js';
import SliderField from '../components/forms/SliderField.js';

import LoadingModal from './LoadingModal.js';
import LoadingModalWP from './LoadingModalWP.js';
import { defaultHourly, defaultWeekly } from '../views/ListSpace.js';

function isInput(input) {
  if (!input){
      return false;
  }
  if (input.key === 'autocomplete'){
    //console.log("checkPASS autocomplete");
    return true;
  }
    
    if (input.type === TextField
        || input.type === Input
        || input.type === HiddenField
        || input.type === GooglePlacesField
        || input.type === NumberField
        || input.type === SelectField
        || input.type === SelectFieldWP        
        || input.type === SliderField
        || input.type === Select) {
        //console.log('right type of component', input.type.name)
        return true
    }
    //console.log("checkFAIL key:",input.key);
    return false
}

function recursiveInputSearch(children) {
    let inputs = []
    React.Children.forEach(children, child => {
        if (isInput(child)) {
          inputs.push(child);
        }        
        if (child && child.hasOwnProperty('props') && React.Children.count(child.props.children) > 0) {
            inputs = inputs.concat(recursiveInputSearch(child.props.children))
        }
    })
    return inputs
}



export default class CRUDForm extends Component {
    componentWillMount() {        
        // Find all of the handled form element types in the children
        const formInputs = recursiveInputSearch(this.props.children);
        //console.log(formInputs)
        //this.refs = {};        
        // Initialize the state of all controlled form components 
        // to nothing when its an empty create form. 
        const isLoading = this.props.forceLoading ? true:false;

        //console.log(this.props);
        const initState = {
            fieldNames:{},
            'values': {}, 
            'errors': {}, 
            disabled: isLoading,
            loading: isLoading,
            formInputs,

        };

        // If we're hydrating an existing form, query the server for details
        // if (this.props.uuid) {
        //     // Show loading in some way
        //     initState.loading = true;
        //     this.props.endpoint({}, this.props.uuid, "GET").then(response => {
        //         // Fill in the existing data in the form components
        //         this.setState({
        //             loading: false,
        //             values: response.data
        //         })
        //         console.log(response)
        //     })
        // }

        for(let i = 0; i < formInputs.length; i++) {
          initState['values'][formInputs[i].props.name] = formInputs[i].props.value
            initState['errors'][formInputs[i].props.name] = ''
            //this.refs[formInputs[i].props.name] = React.createRef();

        }

        if (this.props.hydrateFromObject) {
          //console.log("hydrate");
          //console.log({formInputs});
            this.setState({...this.props.hydrateFromObject, formInputs: formInputs})
        } else {
          //console.log("initState:",initState);
            this.setState(initState);
        }
    }
    
    handleKeyDown = (event) => {
      if(event.keyCode == 13){
        //console.log("ENTER ON:",event.target.name);  
        /*let vals = this.state.values;
        let isValid = true;
        for (let key in vals) {
          //console.log(`key: ${key} = ${vals[key]}`);
          if (!vals[key]){
            isValid = false;

            console.log("!isValid");
            event.preventDefault();
            return;
          }
        }*/

        this.onFormSubmit(event);
        
     }

      
    }


    checkValue(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      };
      return str;
    };

    handleInputChange = (event) => {
        let value;
        let name;

        const target = event.target;
        
        if (event.finalValueJSON){
          value = event.finalValueJSON.value;
          name = event.finalValueJSON.id;
          //console.log("JSON-id:",name);
          //console.log("JSON-value:",value);
          //console.log({event});
        } else {
          // Special case to handle check boxes - their value resides in checked not value
          value = target.type === 'checkbox' ? target.checked : target.value;
          name = target.name;
        }
        
        //console.log("handleInputChange:",name);

        let newVals = this.state.values;

        if (name === 'lease_end_date'){
          //console.log(name, value);  
          let input = value;

          if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
          var values = input.split('/').map(function(v) {
            return v.replace(/\D/g, '')
          });
          if (values[0]) values[0] = this.checkValue(values[0], 31);
          if (values[1]) values[1] = this.checkValue(values[1], 12);
          var output = values.map(function(v, i) {
            return v.length == 2 && i < 2 ? v + '/' : v;
          });
          value = output.join('').substr(0, 14);          
        }
        newVals[name] = value;

        //console.log({newVals});
        this.setState({
            values: newVals,
        });

        

        if (this.props.onChange !== undefined) {
          //console.log("has onChange");
          this.props.onChange()
        } else {
          //console.log("no onChange");
        }
    }

    getState = () => {
      /* 
              const initState = {
            fieldNames:{},
            'values': {}, 
            'errors': {}, 
            disabled: isLoading,
            loading: isLoading,
            formInputs,

        };

      */  
        //let myState = this.state;
        //myState.formInputs = null;
      
      return this.state;
    }

    recursiveRenderChildren = (children) => {
      //console.log("recursiveRenderChildren:",this.state.values);
        return React.Children.map(children, child => {
            var childProps = {};
            if (child && child.props) {
              //console.log({child});

                if (child.props.type && child.props.type === "submit") {
                    // Inject logic into submit buttons.
                    childProps = {
                        onClick: this.onFormSubmit,
                        //disabled: this.state.disabled,
                    };
                }
                if (isInput(child)) {
                  //console.log(child);
                  let myName = child.props.name;
                  if (!myName && child.props.field){
                    myName = child.props.field.id;
                  }
                  

                    if (this.props.submitOnEnter){
                      /*let requires = child.props.inputProps && child.props.inputProps.requires;
                      console.log("child.props.name:",child.props);
                      console.log("requires:",requires);
                      if (requires){
                        return null;
                      }*/
                      childProps = {
                        onChange: this.handleInputChange,
                        onKeyDown: this.handleKeyDown,
                        value: this.state.values[myName],
                        //disabled: this.state.disabled,
                        error: this.state.errors[myName] ? true : false,
                        helperText: this.state.errors[myName],
                    }

                    } else {
                      
                      //console.log(`${myName} = ${this.state.values[myName]}`)
                      childProps = {
                        onChange: this.handleInputChange,
                        value: this.state.values[myName],
                        //disabled: this.state.disabled,
                        error: this.state.errors[myName] ? true : false,
                        helperText: this.state.errors[myName],
                    }

                    }
                } else {
                  


                  let strRequires = child.props.requires;
                  if (strRequires){
                    let requires = strRequires.split(':');  //0 = field, 1= value  ie. external_user:2
        
                    let vals = this.state.values;
                    if (!vals[requires[0]]){
                      //console.log(`IGNORE - requires [${requires[0]} val of ${requires[1]}] whose value is: ${vals[requires]}`);
                      return null;
                      //continue;
                    } else {
                      if (vals[requires[0]] != requires[1]){
                        //console.log(`IGNORE - value ${vals[requires[0]]} doesn't match ${requires[1]}`);
                        return null;
                        
                      }
                      //console.log(`PASS - requires [${requires[0]}] val of ${requires[1]} whose value is: ${vals[requires]}`);
                    }
                    
                  }
                  //console.log(child);
                }
              
                // String has no Prop
                childProps.children = this.recursiveRenderChildren(child.props.children);

                
                return React.cloneElement(child, childProps);
            }
            return child;
        })
    }

    onFormSubmit = (event) => {
        //console.log("onFormSubmit:",event);  
      // Send the data to the server.
        // Handle the response.
        event.preventDefault();
        //console.log('click!')
        //console.log(this.state)


        // Cast appropriate fields to numbers
        let vals = this.state.values;
        let isValid = true;
        let errorSummary = {};

        const formInputs = this.state.formInputs;
        //console.log({formInputs});
        //console.log({vals});

        // If present, extract the auto_approve, allow_casual, allow_monthly slider values
        let auto_approve;
        let allow_casual;
        let allow_monthly;

        let has_email2 = false;

        for(let i = 0; i < formInputs.length; i++) {
          let key = formInputs[i].props.name || '';
          if (key === 'auto_approve'){
            //console.log(formInputs[i].props.name + " = " + formInputs[i].props.value);
            auto_approve = vals['auto_approve']; // formInputs[i].props.value;
          } else if (key === 'allow_casual'){
            allow_casual = vals['allow_casual'];  //formInputs[i].props.value;
          } else if (key === 'allow_monthly'){
            allow_monthly = vals['allow_monthly'];  //formInputs[i].props.value;
          }
        }
        //console.log({auto_approve});
        //console.log({allow_casual});
        //console.log({allow_monthly});


        // Oscar/Stockland etc Only - all other portals are monthly
        if (window.WPMode === 'spaces'){
          if (!auto_approve){
            allow_casual = false;
          }
          if (!allow_monthly){
            vals['rate_monthly'] = null;
          }
          if (!allow_casual){
            vals['rate_hourly'] = null;
          }
        }

        /*if (!allow_casual && !allow_monthly){
          isValid = false;
          //console.log("!isValid:",key);
          errorSummary['allow_casual'] = "Missing";
          errorSummary['allow_monthly'] = "Missing";
        }*/

        //console.log(this.state);
        for(let i = 0; i < formInputs.length; i++) {
          
          //console.log(formInputs[i]);
          let key = formInputs[i].props.name || '';
          if (!key){

            if (formInputs[i].props.field){
              let field = formInputs[i].props.field;
              //console.log({field});
              key = field.name;
            }

            
          }
          //console.log(key + ":",vals[key]);

          let strRequires = formInputs[i].props.inputProps && formInputs[i].props.inputProps.requires;
          if (strRequires){
            let requires = strRequires.split(':');  //0 = field, 1= value  ie. external_user:2

            if (!vals[requires[0]]){
              //console.log(`IGNORE - requires [${requires[0]} val of ${requires[1]}] whose value is: ${vals[requires]}`);
              continue;
            } else {
              if (vals[requires[0]] != requires[1]){
                //console.log(`IGNORE - value ${vals[requires[0]]} doesn't match ${requires[1]}`);
                continue;
              }
              //console.log(`PASS - requires [${requires[0]}] val of ${requires[1]} whose value is: ${vals[requires]}`);
            }
          }

          if (key==='email2'){
            has_email2 = true;
          }

          if (key === 'monthly_payout' && this.props.WPDomain){
            // ok to ignore
          } else if (key !== 'auto_approve' && key !== 'allow_casual' && key !== 'allow_monthly' && key !== 'lease_end_date' && key !== 'access_method' && key !== 'referral' && key !== 'signupCode' && key !== 'default' && key!== 'monthly_payout' && key!== 'price_by_oscar' && key !== 'electric_charge_type' && (!vals[key] || vals[key] == '')){

            if (key == 'rate_monthly' && !allow_monthly){
              // is valid
              //console.log("a1");

            } else if (key === 'rate_hourly' && !allow_casual){
              //console.log("a2");

            } else {
              isValid = false;
              //console.log("!isValid:",key);
              errorSummary[key] = "This field is required";
  
            }
          }

          if (key.includes('rate')) {
            vals[key] = parseFloat(vals[key])     //?? int??
          }

       
        }


        /*let key2 = formInputs[i]['email2'];
        if (key2){
        }*/

        if (has_email2){
          if (vals['email'] != vals['email2']){
            isValid = false;
            errorSummary['email2'] = "Email addresses do not match";
  
          }
  
        }


        /*for (let key in vals) {
          //console.log(`key: ${key} = ${vals[key]}`); // default is for vehicle
          if (key !== 'referral' && key !== 'signupCode' && key !== 'default' && (!vals[key] || vals[key] == '')){
            isValid = false;
            //console.log("!isValid");
            errorSummary[key] = "This field is required";
          }

          if (key.includes('rate')) {
              vals[key] = parseFloat(vals[key])
          }
        }*/

        if (!isValid){
          //console.log("notValid",errorSummary);
          this.setState({errors: errorSummary});
          return;
        } else {
          //console.log("VALID!");
        }

        this.setState({
          disabled: true,
          loading: true,
        });



        /*if (vals['price_by_oscar']) {
            vals['rate_hourly'] = defaultHourly
            vals['rate_weekly'] = defaultWeekly
        }*/

        let query;
        if (this.props.uuid) {
            query = this.props.endpoint(vals, this.props.uuid, 'PUT')
        } else {
            query = this.props.endpoint(vals)
        }
        query.then(response => {
            //this.setState({
                //loading: false
            //})
            response.queryParams = this.state.values
            //console.log(this.state.values);
            this.props.onSuccess(response)
        })
        .catch(error => {
            // handle error
            // Re-enable the form
            this.setState({
                disabled: false,
                loading: false,
            })
            
            //console.log("error:",error.response);
            //console.log(error.response.data)
            //let errorSummary = {}
            if (error.response){
                for (let field in error.response.data) {
                    errorSummary[field] = error.response.data[field];
                }
            }

            // SPECIAL CASE FOR different error format from login endpoint
            if ('error_description' in errorSummary) {
                errorSummary = {"username": "Please check your email or password and try again."}
            }
            // SPECIAL CASE FOR different error format from login endpoint
            if ('detail' in errorSummary) {
                if (errorSummary['detail'] === "Please enter a valid username" ) {
                    errorSummary = {"username": "We can't find an account with that email address."}
                }
                if (errorSummary['detail'] === "Please enter a valid password" ) {
                    errorSummary = {"password1": "Please enter a valid password."}
                }
                if (errorSummary['detail'] === "Passwords must match" ) {
                    errorSummary = {"password2": "Passwords must match"}
                }
            }

            this.setState({errors: errorSummary})

            if( this.props.onError ) {
                this.props.onError(error)
            }
        })
    }

    render() {
      if (window.WPDomain){
        return(
          <div>
              <LoadingModalWP open={this.state.loading}/>
              {this.recursiveRenderChildren(this.props.children)}
          </div>
        );

      }
      return(
        <div>
            <LoadingModal open={this.state.loading} color={this.props.loadColor}/>
            {this.recursiveRenderChildren(this.props.children)}
        </div>
        );
    }
}
