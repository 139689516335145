import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import moment from 'moment';

import {getOwnerBayBookings} from '../api/Oscar-api';
import {
    BookingsRangeDisplayFormat,
    ParseDateTimeParameters,
    WPMonthlyBookingsDisplayFormat
} from '../utils/TimeFormats';
import ENUMS from '../api/ENUMS.json'

import {
    Grid,
    Hidden,
    IconButton,
    Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconNext from '@material-ui/icons/KeyboardArrowRight';

import LoadingModal from '../components/LoadingModal.js';

const styles = {
    heading: {
        color: '#3cb9c4',
        marginTop: '20px'
    },
    subHeading: {
        color:'grey'
    },
    arrowIcon: {
        color:'black',
        position: 'relative',
        top: '7px'
    },
    bookingItemContainer: {
        borderTop: '1px solid #efefef',
        paddingTop: '10px',
        paddingBottom: '10px',
        flexWrap: 'nowrap'
    },
    bookingPrice: {
        flexShrink: '0',
        marginLeft: '8px',
    }
}

function OwnerBookingItem(props) {
  //return(<div><pre>{JSON.stringify(props.booking,null,2)}</pre></div>);
  
  const spaceData = props.booking.parking_bay;
  const building = spaceData.building;
    
  const divBay = spaceData.bay_marking ? <div className="baymarking">Bay #{spaceData.bay_marking}</div>:null;

  let strAmount;
  if (props.booking.owner_earnings){
    strAmount = `$${props.booking.owner_earnings.toFixed(2)}/month`;
  } else {
    strAmount = "";
  }
  
  let divRate;
  if (false && props.booking.refunded){
    divRate = (<div className="rate">Refunded</div>); // don't show this
  } else if (props.booking.cancelled){
    divRate = (<div className="rate">Cancelled</div>);
  } else {


    if (props.booking.cease_date && moment(props.booking.cease_date).diff(moment(props.booking.start_date), 'days') <= 30-1){
      divRate = (<div className="rate">${props.booking.owner_earnings.toFixed(2)}<IconNext style={{verticalAlign: 'bottom'}}/></div>);
    } else {
      divRate = (<div className="rate">${props.booking.owner_earnings.toFixed(2)}/month<IconNext style={{verticalAlign: 'bottom'}}/></div>);
    }
    
  }
/* 
        <div className="rate">
            {strAmount}
            <IconNext style={{verticalAlign: 'bottom'}}/>
        </div>

*/

  let strClassName = "li-booking noselect";

 
  let divDateRange;
  if (props.booking.cease_date){
    divDateRange = <div className="daterange">{props.booking.start.format(WPMonthlyBookingsDisplayFormat)} - {props.booking.end.format(WPMonthlyBookingsDisplayFormat)} (Ref#{props.booking.reference})</div>;
  } else {
    divDateRange = <div className="daterange">{props.booking.start.format(WPMonthlyBookingsDisplayFormat)} - ONGOING (Ref#{props.booking.reference})</div>;
  }

  let divApproval;
  if (props.booking.approval_status == 3 || props.booking.approval_status == 4){
    divApproval = <div className="approval-declined">{ENUMS.approval_status[props.booking.approval_status || 0]}</div>;
    strClassName+=" cancelled";
  } else {
    divApproval = <div className="approval-confirmed">{ENUMS.approval_status[props.booking.approval_status || 0]}</div>;
  }
  
  return(
    <div key={props.booking.reference} className={strClassName} onClick={props.onBookings}>
      <div className="details">
        {divDateRange}
        <h3><span>{building.building_name}</span> at {building.address}, {building.suburb}</h3>
        {divApproval}  
        {divBay}
      </div>
      {divRate}
      

    </div>
  );
}

export default class ListingsWP_OwnerBookings extends Component {
    state = {
        loading: true, 
        upcomingBookings: [], 
        pastBookings: []
    }

    //TODO: DAMIAN = handle 401 errors here
    componentWillMount() {
      getOwnerBayBookings().then(response => {
            //console.log(response.data);

            let pastBookings = []
            let upcomingBookings = []
            const now = moment();

            for (let i = 0; i < response.data.length; i++) {
                const booking = response.data[i]
                //console.log("owner-booking:",booking);

                booking.start = moment(`${booking.start_date}`, ParseDateTimeParameters)
                if (booking.cease_date) {
                  booking.end = moment(`${booking.cease_date}`, ParseDateTimeParameters)
                }

                if (booking.start.isAfter(now) && !booking.cancelled) {
                  upcomingBookings.push(booking);
                } else {
                  pastBookings.push(booking)

                }
                

            }

            //pastBookings.sort((a, b) => a.end.isBefore(b.end) ? 1 : -1)
            pastBookings.sort((a, b) => a.start.isBefore(b.start) ? 1 : -1)

            this.setState({
                loading: false,
                upcomingBookings: upcomingBookings,
                pastBookings: pastBookings,
            })
        })
    }

    render() {
        return(
                <>
                    <h1 style={{marginTop:'60px'}}>Received Bookings</h1>
                    
                    {this.state.loading &&
                      <h2>Loading...</h2>
                    }
                    {!this.state.loading &&
                    this.state.upcomingBookings.length === 0 && 
                    this.state.pastBookings.length === 0 &&
                        <h2>You haven't received any bookings yet</h2>
                    }

                    {this.state.upcomingBookings !== undefined && this.state.upcomingBookings.length > 0 &&
                    <div style={{marginTop: '24px'}}>
                        {this.state.upcomingBookings.map((booking, i) => {
                            return(
                                <OwnerBookingItem key={i} booking={booking} onBookings={() => this.props.history.push(`/ownerbookingdetails/${booking.reference}`)}/>
                            )
                        })}
                        
                    </div>}

                    {this.state.pastBookings !== undefined && this.state.pastBookings.length > 0 && 
                    <div>
                        <Typography style={{marginTop: '32px', marginBottom: '24px'}} variant="headline">
                            Past Bookings
                        </Typography>
                        {this.state.pastBookings.map((booking, i) => {
                            return(
                                <OwnerBookingItem key={i} pastBooking={true} booking={booking} onBookings={() => this.props.history.push(`/ownerbookingdetails/${booking.reference}`)}/>
                            )
                        })}
                        
                    </div>}
                </>
        )
    }
}