import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import { 
    cancelBooking,
    extendBooking,
    reviewBooking,
    getOwnerBookingDetails,
    setBookingStatus
} from '../api/Oscar-api.js';
import { FieldType } from '../api/fieldTypes.js';
import SelectDropDown from '../components/SelectDropDown';


import {
    BookingsRangeDisplayFormat,
    ParseDateTimeParameters,
    TwelveHourTime,
    WeekdayDate,
    WPMonthlyBookingsDisplayFormat
} from '../utils/TimeFormats';

import LoadingModal from '../components/LoadingModal';
import LongTermEnquiry from '../views/LongTermEnquiry.js';
import EditItem_Form from '../components/EditItem_Form.js';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    Typography,
} from "@material-ui/core";

import IconBack from '@material-ui/icons/KeyboardArrowLeft'
import IconClose from "@material-ui/icons/Close"
import IconForward from '@material-ui/icons/KeyboardArrowRight'

import Carousel from 'react-responsive-carousel/lib/components/Carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';

import FullPageModal from '../utils/FullPageModal.js';
import { isNativeWebview } from '../utils/IsNativeWebview';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';
import SelectField from '../components/forms/SelectField.js';
import { updateUserProfileAjax } from '../utils/Authentication.js';
import BookingLimitExceeded from './BookingLimitExceeded.js';
import AvailabilityError from '../components/AvailabilityError.js';
import CardPaymentError from '../components/CardPaymentError.js';

import {getSpaceType} from '../utils/GetSpaceDetails.js';
import {getImageListForSpace} from '../utils/GetImageListForSpace.js';
import ENUMS from '../api/ENUMS.json'


const styles = {
  bookingDetailsProperty: {
    padding: '10px 0px',
  },
  rightTopButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
    leftTopButton :{
        position: 'absolute',
        zIndex: '3',
    },
    contentContainer: {
        height: '100%',
        overflowY: 'auto',
    },
    descriptionContentContainer: {
        marginTop: '8px',
        padding: '16px',
        flexGrow: '1',
    },
    container: {
        padding: '0px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    rate: {
        fontWeight: 'bold',
    },
    bookingButtonContainer: {
        width: '100%',
        position: 'absolute',
        bottom: '0',
        left: '0',
    },
    bookingButton: {
        padding: '22px',
    },
    descriptions: {
        paddingLeft: '16px',
    },
    distanceText: {
        textTransform: 'uppercase',
        color: "#acacac",
    },
    imageCarousel: {
        width: '100%',
        height: '250px',
    },
    carouselImage: {
        width: 'auto',
        height: 'auto'
    },
    subheading: {
        marginTop: '16px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    reviewIcon: {
        fontSize: '13px',
        marginRight: '4px'
    },

    messageButton: {
        position: 'absolute',
        bottom: '16px',
        right: '16px',
        color: '#1AA5A2',
        backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover, &:focus': {
            backgroundColor: 'white',
        },
    },
    editButton: {
        color: '#1AA5A2',
        backgroundColor: 'white',
        boxShadow: 'none',
        '&:hover, &:focus': {
            backgroundColor: 'white',
        },
        border: '1px solid #1AA5A2'
    },

    rightCornerButton: {
        position: 'absolute',
        top: '8px',
        right: '10px',
    },
    nextButton: {
        position: 'absolute',
        boxShadow: 'none',
        bottom: 16,
        right: 16,
    },

//    topColored: {
//      paddingTop: '80px',
//        backgroundColor: '#1AA5A2',
//        paddingBottom: '8px',
//    },
    paymentSetting: {
        //paddingTop: '8px',
        //paddingBottom: '8px',
        borderBottom: '1px solid #efefef',
        textAlign:'right'

    },
    switchField: {
        borderTop: '1px solid #acacac',
        borderBottom: '1px solid #acacac',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginTop: '12px',
        marginBottom: '12px',
    },
    transactionsContainer: {
        marginTop: '16px'
    },
    transactionContainer: {
        borderTop: '1px solid #efefef',
        paddingTop: '8px',
        paddingBottom: '6px',
    },
    switchBorder: {
        marginTop: '24px',
        borderTop: '1px solid #efefef',
    },

    pickerContainer: {
        minHeight: '550px', 
        backgroundColor: 'white',
        paddingTop: '32px',
    },

    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    },
    link: {
        display: 'inline-block',
        marginLeft: '4px',
        textDecoration: 'underline',
    },
    guestProfileImage: {
      backgroundColor: 'white',
      borderRadius: '50px',
      width: '75px',
      height: '75px',
    },
}

class CancelRequest extends Component {
  state = {loading: false}

  doCancelRequest = (booking) => {
    this.setState({loading: true});


    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "3", // Cancelled by Owner
      "reason": this.state.owner_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({loading: false, cancelRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });


  }

  externalLink = (target, nativeTarget) => {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}


  render() {

    const thisField = {"isRequired":true, "id":"cancellation_reason", "label":"Reason for declining this booking","type":FieldType.SELECT_DROPDOWN, domain:'owner_monthly_cancellation_reason'};
    let fieldErrors = {};
    

    return(
        <>
        <FullPageModal open={this.state.cancelRequestSuccessShow}>
          <CancelRequestSuccess onClose={() => this.props.history.push(`/search`)}/>
        </FullPageModal>

        <div className="mainContent" style={styles.topColored}>
            <LoadingModal open={this.state.loading}/>

            <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                  <IconBack style={{color: 'white'}}/>
              </IconButton>


              <h1>Decline Booking Request</h1>
                  
              
        </div>
        <div className="sd-container">
          <div className="sd-details">
          
            <p style={{marginBottom:'20px'}}>Are you sure you want to decline this booking request?</p>
         
            <SelectDropDown pageData={"pageData"} field={thisField} initialValue={this.state.owner_cancel_reason} error={fieldErrors[thisField.id]} onUpdate={(option) => this.setState({owner_cancel_reason:option.finalValue})} />

          </div>
        </div>
        <div className="sd-actions">
            <Button className="wp-button-outline"
              onClick={() => this.props.onClose()}>
                Back
            </Button>
            <Button className="wp-button" disabled={!this.state.owner_cancel_reason} onClick={() => this.doCancelRequest(this.props.booking)}>Decline Booking</Button> 
          </div>

        </>
    );
  }
}

function CancelRequestSuccess(props) {
  return(
    <div className="sd-container">
      <div className="sd-details">

        <h1>The booking request has been declined</h1>
          <p>The driver has been notified that you have declined this booking.</p>
      </div>

      <div className="sd-actions">
        <Button className="wp-button"
          onClick={props.onClose} color="primary">
          Close
        </Button>

      </div>
    </div>
  );
}

class AcceptRequest extends Component {
  state = {loading: false}

  doAcceptRequest = (booking) => {
    this.setState({loading: true});


    //this.setState({declineRequestOpen: true});
    let params = {
      "status": "2", // Approved by Owner
//      "reason": this.state.owner_cancel_reason
    };

    ///const startDate = this.props.start.format(QueryParamDate);
    setBookingStatus(params, booking.reference).then((response) => {
      //const bookingRef = response.data.reference
      //console.log({response});
      this.setState({loading: false, acceptRequestSuccessShow:true});

    }).catch(error => {
      console.log("ERROR:",error);        
      this.setState({loading: false})
    });


  }

  externalLink = (target, nativeTarget) => {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}


  render() {

    //const thisField = {"isRequired":true, "id":"cancellation_reason", "label":"Reason for declining this booking","type":FieldType.SELECT_DROPDOWN, domain:'owner_monthly_cancellation_reason'};
    //let fieldErrors = {};
    

    return(
        <>
        <FullPageModal open={this.state.acceptRequestSuccessShow}>
          <AcceptRequestSuccess onClose={() => this.props.history.push(`/search`)}/>
        </FullPageModal>

        <div className="mainContent" style={styles.topColored}>
            <LoadingModal open={this.state.loading}/>

            <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                  <IconBack style={{color: 'white'}}/>
              </IconButton>
              <h1>Confirm Booking Request</h1>
        </div>
        <div className="sd-container">
          <div className="sd-details">
          
            <p>By clicking 'Confirm Booking' you automatically accept the driver's request to park in your car space.</p>
            
            <p>View the <a href={`https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/`} target="_blank" className="oscar-external-link">cancellation policy</a> here.</p>

        
            
          </div>
        </div>
        <div className="sd-actions">
            <Button className="wp-button-outline"
              onClick={() => this.props.onClose()}>
                Back
            </Button>

            <Button className="wp-button"
              onClick={() => this.doAcceptRequest(this.props.booking)}>
                Confirm Booking
            </Button> 
          </div>

        </>
    );
  }
}

function AcceptRequestSuccess(props) {
  return(
    <div className="sd-container">
      <div className="sd-details">
        <h1>The booking request has been accepted</h1>
        <p>The driver has been notified that you have accepted this booking.</p>
    </div>

    <div className="sd-actions">
      <Button className="wp-button"
        onClick={props.onClose} color="primary">
        Close
      </Button>

    </div>
  </div>
  );
}







function CancelSuccess(props) {
    return(
        <div className="mainContent topPadded" style={styles.alertPage}>
            <Typography variant="display1" color="primary">
                Your booking has been cancelled.
            </Typography>

            <Typography>
                Your booking has been successfully cancelled. If you cancelled more than 2 hrs before your booking, your cancellation refund will be available in your wallet.
            </Typography>

            <div style={styles.alertImageContainer}>
                <img src="https://www.sharewithoscar.com.au/assets/graphics/cancelled_booking.svg" alt="booking cancelled"/>
            </div>

            <Button style={styles.alertButton} fullWidth variant="contained" 
            onClick={() => props.history.push('/')} color="primary">
                Okay
            </Button>
        </div>
    )
}

function ExtendSuccess(props) {
    return(
        <div className="mainContent topPadded" style={styles.alertPage}>
            <Typography variant="display1" color="primary">
                Your booking has been extended.
            </Typography>

            <Typography>
                Your booking has been successfully extended to {props.end.format(TwelveHourTime)}
            </Typography>

            <div style={styles.alertImageContainer}>
                <img src="https://www.sharewithoscar.com.au/assets/graphics/extended_booking.svg" alt="booking extended"/>
            </div>

            <Button style={styles.alertButton} fullWidth variant="contained" 
            onClick={props.onClose} color="primary">
                Got it
            </Button>
        </div>
    )
}

class ExtendBooking extends Component{
    state = {loading: false, successShow: false, over24hrs: false}

    handleTimeRangeSelected = (start, end) => {
        if (end.isAfter(this.props.booking.end)) {
            this.setState({end: end})
        }
    }

    handleExtendBooking = () => {
        this.setState({loading: true})
        const params = {
            duration: this.state.end.diff(this.props.booking.end, 'minutes'),
        }
        extendBooking(params, this.props.booking.reference).then(response => {
            //console.log(response)
            // Show extension success
            this.setState({
                successShow: true,
                loading: false
            })
        }).catch(error => {
            //console.log(error.response.data)
            let errorToggle
            if (error.response.data.detail === "Parking space not available") {
                errorToggle = 'availabilityError'
            }
            if (error.response.data.detail === "Charge could not be made to default card") {
                errorToggle = 'cardPaymentError'
            }
            this.setState({
                loading: false,
                [errorToggle]: true,
            })
        })
    }

    handleClose = () => {
        this.setState({successShow: false})
        this.props.onExtend()
    }

    calculateTotal = () => {
        if (this.props.booking.event) {
            return {total:0, overnight: 0}
        }

        const bookingTime = this.props.booking.end.diff(this.props.booking.start, 'minutes')
        const underMax = Math.max(360 - bookingTime, 0)
        let extraTime = Math.min(this.state.end.diff(this.props.booking.end, 'minutes'), underMax)

        if (bookingTime + extraTime <= 60) {
            extraTime = 0
        }

        // see if an overnight fee applies to this extension
        let overnightFee = 0;
        if (this.props.booking.end.isSame(this.props.booking.start, 'days') && this.state.end.isAfter(this.props.booking.end, 'days')) {
            // Booking start date & end date are the same
            // Extended end date is at least one day greater than the booking end date
            if (this.state.end.diff(this.props.booking.start, 'minutes') >= 360) {
                // New duration is greater than or equal to 6 hours
                overnightFee = 12
            }
        } else if (this.props.booking.end.isAfter(this.props.booking.start, 'days')) {
            // Booking end date is at least a day greater than the booking start date
            if (this.state.end.diff(this.props.booking.start, 'minutes') >= 360) {
                // Booking duration is less than 6 hours
                // Extended booking duration is greater then or equal to 6 hours
                overnightFee = 12
            }
        }

        return {
            total: ((extraTime / 60 * this.props.booking.parking_space.rate_hourly) + overnightFee).toFixed(2),
            overnight: overnightFee
        }
    }

    render() {
        
        if (this.state.over24hrs) {
            return (
                <BookingLimitExceeded 
                    onDismiss={() => this.setState({over24hrs: false})}
                    buttonMessage="Return to Extend"
                />
            )            
        }

        let extension
        if (this.state.end !== undefined) {
            extension = this.calculateTotal()
        }

        return(
            <div>
                <LoadingModal open={this.state.loading}/>

                <FullPageModal open={this.state.successShow}>
                    <ExtendSuccess onClose={this.handleClose} end={this.state.end}/>
                </FullPageModal>

                <FullPageModal open={this.state.cardPaymentError} >
                    <CardPaymentError
                        onClose={() => this.setState({ 
                            loading: false,
                            cardPaymentError: false 
                        })}
                        last4={this.props.user.profile.card_last4} 
                    />
                </FullPageModal>                
                <FullPageModal open={this.state.availabilityError} >
                    <AvailabilityError
                        onClose={() => this.setState({ 
                            loading: false,
                            availabilityError: false 
                        })}
                    />
                </FullPageModal>


                <div className="mainContent top-colored">
                    <IconButton className="leftCornerButton" onClick={this.props.onClose}>
                        <IconBack style={{color: 'white'}}/>
                    </IconButton>

                    <Typography style={{color: 'white'}} variant="display1">
                        Extend Booking
                    </Typography>            
                      </div>
    
                <div className="mainContent" style={styles.pickerContainer}>
                    <Typography variant="body1" style={{marginBottom: '16px'}}>
                        {this.props.booking.start.format(WeekdayDate)}
                    </Typography>
                    <DateTimeRangePicker 
                        withDate={true}
                        verticalForm={true}
                        singleDayRange={true}
                        start={this.props.booking.start}
                        startText="Arriving"                 
                        end={this.props.booking.end}
                        endText="Leaving"
                        onTimeRangeSelected={this.handleTimeRangeSelected}
                        extending={true}
                        onOver24HrPicked={() => this.setState({over24hrs: true})}       
                    />
                    <div style={{borderTop: '1px solid #efefef'}}></div>

                    {this.state.end !== undefined &&
                    <Typography style={{marginTop: '8px'}} variant="caption">
                        Extending will cost an extra <span style={{color: "#1AA5A2"}}>${extension.total}</span>
                        {extension.overnight > 0 && <span style={{marginLeft: '3px'}}>($12 overnight fee applies)</span>}
                    </Typography>}
                </div>

                <Button style={styles.nextButton} variant="contained" color="primary"
                id="extend_confirm"
                onClick={this.handleExtendBooking}
                disabled={this.state.end === undefined}>
                    Extend
                </Button>
            </div>
        )
    }
}



class CancelBookingForm extends Component{
  state = {loading: false, successShow: false}

  handleClose = (event) => {
    this.props.onClose();
    
  }

  handleCancelBooking = () => {
      this.setState({loading: true})
      const params = {
          cancelled: true,
          cancelled_reason: this.state.reason,
      }

      cancelBooking(params, this.props.booking.reference).then(response => {
          //console.log(response)
          this.setState({loading: false})
          // Show extension success
          this.setState({
              successShow: true
          })
      }).catch(error => {
          console.error(error);
          this.setState({loading: false});
      })
  }
  
  externalLink = (target, nativeTarget) => {
      if (isNativeWebview()) {
          const message = JSON.stringify({
              action: "external_link",
              target: nativeTarget
          })
          window.ReactNativeWebView.postMessage(message);
      } else {
          window.location.href = target
      }
  }

  render() {
      //const RoutedCancel = withRouter(CancelSuccess)
      //booking={this.props.booking} />
      //console.log("booking:",this.props.booking);

      const start_date = this.props.booking.start_date.replace(/-/g,'');   // 20211029
      const location_date = this.props.booking.location_date.replace(/-/g,'');   // 2021-10-13  
      //const location_date = "20211030";

      //console.log("start_date:",start_date);
      //console.log("location_date:",location_date);

      let formId;
      let itemType;
      let initialPage;
      
      /*
      if (this.props.booking.refunded){
        formId = 'public_meriton_cancel_booking';
        itemType = 'booking_refund';  //already refunded
        initialPage = 4;

      } else if (this.props.booking.cancelled){
        //console.log("is already cancelled");
        formId = 'public_meriton_cancel_booking';
        itemType = 'booking_refund';  //only allow refunds
        initialPage = 2;


      } else if ( parseInt(start_date,10) >= parseInt(location_date,10)){
        //console.log("can cancel");  // can cancel any day before the start, PLUS the actual day OF
        formId = 'public_meriton_cancel_booking';
        itemType = 'booking_cancel';

      } else {
        //console.log("only can end");
        
        if (this.props.booking.cease_date){
          const cease_date = this.props.booking.cease_date.replace(/-/g,'');   // 20211029
          //console.log("has cease_date, set to page 2:",cease_date);
          initialPage = 0;//1;

        }
        
        formId = 'public_meriton_end_booking';
        itemType = 'booking_end';

    }*/
      
      formId = 'public_meriton_end_booking_owner';
      itemType = 'booking_end_owner';


      return (
              <EditItem_Form
              authChange={this.props.authChange}
              history={this.props.history}
              formId={formId}
              initialPage={initialPage}
              initialData={this.props.booking}  // saves a wasted trip to the server
              itemType={itemType}
              itemId={this.props.booking.reference}
              onClose={this.handleClose}
           />

      );
  }
}

export default class BookingDetailsOwnerWP extends Component {
    state = {
        loading: false,
        edittingBooking: false,
        isOpenForm_EndBooking: false,
        reviewBooking: this.props.match.params.review !== undefined,
        forcedReview: this.props.match.params.review !== undefined,
        acceptRequestOpen: false,
        declineRequestOpen: false
        
    }

    componentWillMount() {
        this.loadBooking()
    }

    //TODO: ONLY FETCH *CURRENT* BOOKING, not a giant list of every single one... this is so dumb
    loadBooking() {
        this.setState({loading: true})

        let that = this;
        const bookingRef = this.props.match.params.reference;
        getOwnerBookingDetails(bookingRef).then(details => {
          //console.log(details.data);
          const booking = details.data;
          //console.log({booking});
          booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters);
          booking.end = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters);
          booking.create_date2 = moment(booking.create_date);


          that.setState({
            loading: false,
            booking
          });

        });






        /*getTrips().then(response => {
            console.log(response.data)
            
//return;
            const booking = response.data.find(elem => elem.reference === this.props.match.params.reference)
            if (booking){
              booking.start = moment(`${booking.start_date} ${booking.start_time}`, ParseDateTimeParameters)
              booking.end = moment(`${booking.cease_date} ${booking.cease_time}`, ParseDateTimeParameters)
              
              booking.expired = booking.end.isBefore(moment()) || booking.cancelled
  
            }

            this.setState({
                loading: false,
                booking: booking,
            })
        })*/
    }

    externalLink = (target, nativeTarget) => {
        if (isNativeWebview()) {
            const message = JSON.stringify({
                action: "external_link",
                target: nativeTarget
            })
            window.ReactNativeWebView.postMessage(message);
        } else {
            window.location.href = target
        }
    }

    render() {
        if (this.state.loading) {
            return(
                <LoadingModal open={this.state.loading} />
            )
        }

        if (!this.state.booking){
          return (<div>NO BOOKING FOUND.</div>);
        }
        

        const booking = this.state.booking;

        if (!booking.parking_bay){
          return (<h1>Please use the Share with Oscar app to view this booking.</h1>);
        }
        //console.log("booking:",booking);
        const spaceData = booking.parking_bay;
        const building = spaceData.building;
  
        //let images = getImageListForSpace(spaceData, building);



        /*let address;
        if (booking.parking_space){
          address = this.state.booking.expired ? this.state.booking.parking_space.street_name : this.state.booking.parking_space.address.replace("Australia", this.state.booking.parking_space.postcode)

        } else {
          address = "No address info";

        }*/

        let strType;
        let instruction;
        let divOwnerEarnings;
  
        const spaceUser = booking.driver;

        divOwnerEarnings = (`$${booking.owner_earnings.toFixed(2)}/month`);



        let strBookingName = spaceUser.first_name;
        if (strBookingName.substr(-1) !== 's'){
          strBookingName+="'s";   // ie. Peter's
        } else {
          strBookingName+="'";    // ie. Ross'
        }
    
        let strBookingType;
        if (booking.booking_type == 2){
          strBookingType="Monthly";
        } else {
          strBookingType = "Casual";
        }
    
        if (booking.approval_status === 1){
          strBookingName+=" Booking Request";
        } else {
          strBookingName+=" Booking";
        }
        
        let strApprovalCutOff = booking.create_date2.clone().add(48,"hours").format("Do MMMM YYYY");



        let divRate;
        if (false && booking.refunded){
          divRate = (<div className="rate">Refunded</div>); // don't show this
        } else if (booking.cancelled){
          divRate = (<div className="rate">Cancelled</div>);
        } else {
      
      
          if (booking.cease_date && moment(booking.cease_date).diff(moment(booking.start_date), 'days') <= 30-1){
            divRate = (<div className="rate">${booking.owner_earnings.toFixed(2)}</div>);
          } else {
            divRate = (<div className="rate">${booking.owner_earnings.toFixed(2)}/month</div>);
          }
          
        }

        //console.log(booking);
        const b_start_date = booking.start_date.replace(/-/g,'');   // 20211029
        const b_location_date = booking.location_date.replace(/-/g,'');   // 2021-10-13  
        let allowEditDate = false;
        if (booking.refunded || booking.cancelled || booking.approval_status != 2){
          allowEditDate = false;
        } else if ( parseInt(b_start_date,10) >= parseInt(b_location_date,10)){
            // can't edit end_date, only cancel
        } else {
          allowEditDate = true;
        }







        const start_date = moment(booking.start_date).format(WPMonthlyBookingsDisplayFormat);
        
        let end_date;

        if (booking.cease_date){
          end_date = moment(booking.cease_date).format(WPMonthlyBookingsDisplayFormat);
        } else {
          end_date = "Monthly Ongoing";
        }

        let strDriverName = booking.driver.first_name;
        if (strDriverName.slice(-1)!=='s'){
          strDriverName+="'s";    //Damian -> Damian's Booking
        } else {
          strDriverName+="'";     //Chris -> Chris' Booking
        }

        return(
          
          <div className="ownerbooking sd-container">
            <LoadingModal open={this.state.loading}/>

              <FullPageModal open={this.state.isOpenForm_EndBooking}>
                <CancelBookingForm onClose={() => this.setState({isOpenForm_EndBooking: false})} 
                  booking={booking}
                  authChange={this.props.authChange} />
              </FullPageModal>            

              <FullPageModal open={this.state.acceptRequestOpen}>
                <AcceptRequest
                  history={this.props.history}
                  booking={booking}
                  onClose={() => {
                    //this.updateBooking()
                    this.setState({acceptRequestOpen: false})
                  }}
                />
              </FullPageModal>

              <FullPageModal open={this.state.declineRequestOpen}>
                <CancelRequest
                  history={this.props.history}
                  booking={booking}
                  onClose={() => {
                    //this.updateBooking()
                    this.setState({declineRequestOpen: false})
                  }}
                />
              </FullPageModal>



              <div className="ownerbooking-header">
                <h1>{strBookingName}</h1>
                <h3>Reference: {booking.reference}</h3>
                <p>Bay# {booking.parking_bay.bay_marking} - <span>{building.building_name}</span></p>
                <p>{building.address}, {building.suburb} {building.state} {building.postcode}</p>
                {booking.approval_status != 2 && <div style={{color:"#ffffff",cursor:'pointer',marginTop:'10px',textDecoration:'underline',fontWeight:'600'}}  onClick={() => this.props.history.push(`/Messages/${booking.reference}/owner/`)}>Contact Driver</div>}

              </div>

                {/*<div className="driver-profile">
                        {(spaceUser && spaceUser.image_user && spaceUser.image_user.path) 
                        ? <img key={spaceUser.image_user.path} style={styles.guestProfileImage} src={spaceUser.image_user.path} alt="user profile" />
                        : <img style={styles.guestProfileImage} src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" alt="user profile" />}
                        
                        <Typography variant="body1" align="center">
                          {spaceUser && spaceUser.first_name}
                        </Typography>
                        </div>*/}
                <IconButton style={styles.rightTopButton}
                  className="wp-btn-close"
                  onClick={() => this.props.history.push('/listings')}>
                    <IconClose style={{color: 'rgb(207, 207, 207)'}}/>
                </IconButton>



                <div className="ob-details">



                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center">
                    <Grid item>
                        <h3>Start Date</h3>
                    </Grid>
                    <Grid item>
                      <h3>{start_date}</h3>
                    </Grid>
                </Grid>

                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center">
                    <Grid item>
                        <h3>End Date</h3>
                    </Grid>
                    <Grid item>
                      {allowEditDate ?
                      <>
                        <h3 style={{marginBottom:'0'}}>{end_date}</h3>
                        <span className="ob-end-booking" onClick={() => this.setState({isOpenForm_EndBooking: true})}>Edit Date</span>
                      </>:
                        <h3>{end_date}</h3>
                      }

                      
                    </Grid>
                </Grid>


                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center">
                    <Grid item>
                        <h3>Vehicle</h3>
                    </Grid>
                    <Grid item>
                        <h3>{booking.vehicle.make} {booking.vehicle.model} {booking.vehicle.registration}</h3>
                    </Grid>
                </Grid>

                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center">
                    <Grid item>
                      <h3>Total Earnings</h3>
                    </Grid>
                    <Grid item>
                      <h3>{divRate}</h3>
                    </Grid>
                </Grid>
                

                <Grid style={styles.paymentSetting} container justify="space-between" alignItems="center"
                onClick={() => this.externalLink(`https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/` ,`https://www.sharewithoscar.com.au/${window.WPDomain}-cancellation-policy/`)}>
                    <Grid item>
                      <h3>Cancellation Policy</h3>
                    </Grid>
                    <Grid item>
                        <IconButton id="incident_report">
                            <IconForward/>
                        </IconButton>
                    </Grid>
                </Grid>

                {booking.approval_status == 1 ?
                  <Grid style={styles.bookingDetailsProperty} container justify="space-between">
                    <Grid>
                      <Typography variant="body1">You have until the end of <b>{strApprovalCutOff}</b> to accept this offer or it will be automatically cancelled.</Typography>
                    </Grid>
                  </Grid>
                :<></>
                }
                </div>
                {booking.approval_status == 1 &&
                  <div className="sd-actions">
                    <Button className="wp-button" onClick={() => this.setState({declineRequestOpen:true})}>Decline Booking</Button>
                    <Button className="wp-button" onClick={() => this.setState({acceptRequestOpen:true})}>Accept Booking</Button>
                  </div>}
                {booking.approval_status == 2 &&
                  <div className="sd-actions">
                    <div className="notetext">Please contact the driver to organise access to the car park before the start date</div>
                    <Button className="wp-button" onClick={() => this.props.history.push(`/Messages/${booking.reference}/owner/`)}>Contact Driver</Button>
                  </div>
                }            


                
            
            </div>
        )
    }
}
