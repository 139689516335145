import React, {Component} from 'react';

import {
    IconButton,
    Button,
    Typography,
} from '@material-ui/core';

import IconClose from "@material-ui/icons/Close"

const styles = {
    leftCornerButton: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    alertPage: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    alertButton: {
        marginBottom: '16px'
    }
}

export default class BookingLimitExceeded extends Component {
    render() {
        return(
            <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                <div className="mainContent topPadded" style={styles.alertPage}>
                    <IconButton style={styles.leftCornerButton} onClick={this.props.onDismiss}>
                        <IconClose />
                    </IconButton>
                    <Typography style={styles.title} gutterBottom color="primary" variant="display1">
                        24 hour limit
                    </Typography>
                    <Typography variant="body1">
                        Sorry you can't book for more than 24 hours. If you need more time,
                        simply make another booking for the next day or pick a spot and submit a 
                        'long term enquiry'
                    </Typography>

                    <div style={styles.alertImageContainer}>
                      <img src="https://www.sharewithoscar.com.au/assets/graphics/unable_remove_availability.svg" alt="availability error"/>                
                    </div>
                    
                    <Button style={styles.alertButton} fullWidth color="primary" variant="contained" 
                    onClick={this.props.onDismiss}>
                        {this.props.buttonMessage}
                    </Button>
                </div>
            </div>
        )
    }
}