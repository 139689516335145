import React, {Component} from 'react';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { 
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputAdornment,
    InputLabel, 
    Typography,
} from '@material-ui/core';

import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import IconClear from '@material-ui/icons/Cancel';

import FullPageModal from '../../utils/FullPageModal';

const styles = {
    searchBox: {
        width: "73%"
    },
    suggestionsContainer: {
        //position: "absolute",
        zIndex: "3000",
    },
    suggestionCard: {
        width: "100%",
    },
    modalTop: {
        borderBottom: '1px solid #efefef',
        paddingTop: '12px',
    },
    searchContainer: {
        padding: '16px',
        height: '600px',
        position: 'relative'
    },
    searchClear: {
        marginRight: '-8px'
    },
    suggestion: {
        padding: '16px 0px',
        borderBottom: '1px solid #efefef'
    },
}

export default class GooglePlacesField extends Component {
    state = {modalOpen: false}

    createUpdateEvent = (name, value) => {
        this.props.onChange({
            target: {
                name: name,
                value: value,
                type: 'text'
            }
        })
    }

    updateHiddenFields = (lat, lng, postcode, state, street) => {
        this.createUpdateEvent("latitude", lat)
        this.createUpdateEvent("longitude", lng)
        this.createUpdateEvent("postcode", postcode)
        this.createUpdateEvent("state", state)
        this.createUpdateEvent("street_name", street)
    }
    
    handleChange = (value) => {
        this.createUpdateEvent(this.props.name, value)
        this.updateHiddenFields()
    }

    handleSelect = (address) => {
        this.createUpdateEvent(this.props.name, address)

        geocodeByAddress(address).then(results => {
            const result = results[0]
            // Report the new street name, postcode, state, latitude and longitude
            
            const postcode = (result.address_components.find(elem => {
                return elem.types.indexOf("postal_code") !== -1
            }) || {short_name: '0000'}).short_name;

            // Not every address suggestion will have a street
            const streetResult = result.address_components.find(elem => {
                return elem.types.indexOf("route") !== -1
            })
            const street = streetResult ? streetResult.short_name : undefined

            const state = result.address_components.find(elem => { 
                return elem.types.indexOf("administrative_area_level_1") !== -1 
            }).short_name

            getLatLng(result).then(latLng => {
                //console.log(latLng)
                //console.log(street, state, postcode)
                this.updateHiddenFields(latLng.lat, latLng.lng, postcode, state, street)
            })
        })

        this.setState({modalOpen: false})
    }

    render() {
        const formError = this.props.error || this.props.children[0].props.error
        if (this.props.desktopDisplay) {
            return(
                <PlacesAutocomplete
                        value={this.props.value}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={{
                            location: new window.google.maps.LatLng(-34, 151),
                            radius: 2000,
                            types: ['address']
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <FormControl fullWidth>
                                <InputLabel error={formError} shrink>{this.props.label}</InputLabel>
                                <Input 
                                    {...getInputProps({
                                    placeholder: 'Enter your address',
                                    className: 'location-search-input',
                                    type: "text",
                                    style: {marginTop: '15px'},
                                    })}
                                    endAdornment={
                                        <InputAdornment style={styles.searchClear} position="end">
                                            <IconButton
                                                onClick={() => this.handleChange('')}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                <IconClear style={{fontSize: '18px'}} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                                {this.props.error ? 
                <FormHelperText error={this.props.error}>
                   {this.props.helperText}
                </FormHelperText> : 
                this.props.children[0].props.error &&
                <FormHelperText error={true}>
                   Please select a street address from the suggestions
                </FormHelperText>
                }
                            </FormControl>
                            <div className="autocomplete-dropdown-container" style={styles.suggestionsContainer}>
                            {/* {loading && <div>Loading...</div>} */}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    })}
                                >
                                    <div style={styles.suggestion}>
                                        <Typography variant="body1">
                                            {suggestion.description}
                                        </Typography>
                                    </div>
                                </div>
                                );
                            })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
            )
        }
        
        
        return(
            <div>
                <InputLabel error={formError} shrink>{this.props.label}</InputLabel>
                <Input 
                    inputProps={{disabled: true, style: {color: '#545454', WebkitOpacity: 1, WebkitTextFillColor: '#545454'} }}
                    placeholder='Enter your address'
                    className= 'location-search-input'
                    type="text"
                    style={{marginTop: '15px'}}
                    value={this.props.value}
                    onClick={() => this.setState({modalOpen: true})}
                />
                <FullPageModal open={this.state.modalOpen}>
                <div style={{backgroundColor: 'white'}} >
                    <div style={styles.modalTop}>
                        <IconButton 
                            style={styles.closeButton}
                            onClick={() => this.setState({modalOpen: false})}
                        >
                            <BackIcon style={{fontSize: '24px'}}/>
                        </IconButton>
                    </div>
                    <div style={styles.searchContainer}>
                    <PlacesAutocomplete
                        value={this.props.value}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={{
                            location: new window.google.maps.LatLng(-34, 151),
                            radius: 2000,
                            types: ['address']
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                            <FormControl fullWidth>
                                <InputLabel error={formError} shrink>{this.props.label}</InputLabel>
                                <Input 
                                    {...getInputProps({
                                    placeholder: 'Enter your address',
                                    className: 'location-search-input',
                                    type: "text",
                                    style: {marginTop: '15px'},
                                    })}
                                    endAdornment={
                                        <InputAdornment style={styles.searchClear} position="end">
                                            <IconButton
                                                onClick={() => this.handleChange('')}
                                                onMouseDown={this.handleMouseDownPassword}
                                            >
                                                <IconClear style={{fontSize: '18px'}} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <div className="autocomplete-dropdown-container" style={styles.suggestionsContainer}>
                            {/* {loading && <div>Loading...</div>} */}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    })}
                                >
                                    <div style={styles.suggestion}>
                                        <Typography variant="body1">
                                            {suggestion.description}
                                        </Typography>
                                    </div>
                                </div>
                                );
                            })}
                            </div>
                        </div>
                        )}
                    </PlacesAutocomplete>
                    </div>
                </div>
                </FullPageModal>
                {this.props.error ? 
                <FormHelperText error={this.props.error}>
                   {this.props.helperText}
                </FormHelperText> : 
                this.props.children[0].props.error &&
                <FormHelperText error={true}>
                   Please select a street address from the suggestions
                </FormHelperText>
                }

            </div>
        )
    }
}