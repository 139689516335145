import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import { isNativeWebview } from '../utils/IsNativeWebview';
import {NavItems, ProfileNav} from './NavigationMenu.js';

import LoadingModal from '../components/LoadingModal.js';

import {
    Button,
    ClickAwayListener,
    Grid,
    Grow,
    Hidden,
    IconButton,
    Paper,
    Popper,
    Typography
} from '@material-ui/core'

import NotificationIcon from '@material-ui/icons/Notifications';

import {getCMS} from '../api/Oscar-api.js';

import '../styles/LayoutWeb.css';


const styles = {
    layoutContainer: {
        minWidth: '300px',
        //maxWidth: '1280px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column'
    },
    navigationContainer: {
        width: '400px', 
        flexGrow: '0', 
        flexShrink: '0',
        borderRight: '2px solid #dedede',
        overflowY:'auto'
    },
    contentContainer: {
        flexGrow: 1,
        alignItems: 'stretch',
        display: 'flex',
        overflowY: 'hidden',
    },
    contentWindow: {
        flexGrow: '1',  
        position: 'relative',
        flexBasis: '1px',
        overflowX: 'auto',
        overflowY: 'hidden',
    },
    appbarContainer: {
        //height: '60px',
        padding: '8px 18px',
        zIndex: '1300',
    },
    appbarButton: {
        margin: '0px 4px'
    },
    userProfileButton: {
        marginLeft: '16px',
        //marginRight: '16px',
        padding: '4px 16px',
    },
    profileImage: {
        width: '30px', 
        height: '30px',
        borderRadius: '40px',
        marginRight: '8px',
    },
    unreadNotifications: {
        position: "absolute",
        left: '14px',
        top: '14px',
        height: '10px',
        width: '10px',
        borderRadius: '10px',
        backgroundColor: '#1AA5A2',
    }
}




const CM_REFRESH_TIME = 10000;
const CM_REFRESH_TIME_ERROR = 20000;

// If in Messages mode, it will check notification more often
// Will only fetch from API if there are new messages (all notification checking load goes to AWS instead)
const CM_MESSAGES_REFRESH_TIME = 2000;
const CM_MESSAGES_TIME_ERROR = 10000;
class MasterLayoutWeb extends Component {
    //state = {ready: false, unreadNotifications: false, profileNavigationOpen: false, profileNavClick: true}


    constructor(props){
      super(props);
  
      const location = this.props.history.location.pathname;
      const layoutMode = this.getLayoutMode(location.toLowerCase());
      //console.log("layoutMode:",layoutMode);
      //console.log("constructor",location);
      
      this._lastTimestamp = null;
      this.state = {
        layoutMode,
        ready: false,
        unreadNotifications: false,
        profileNavigationOpen: false,
        profileNavClick: true,
        nativeWebView: isNativeWebview()
      }
  
     
    }
    
    getLayoutMode(location){
      //console.log("getLayoutMode:",location);
  
      let fullScreen = false;
      let visibleNav = true;
      
      if (location.indexOf('/search') > -1 || location === '/'){
        fullScreen = false;
        visibleNav = false;
      }

      if (location === '/'){
        fullScreen = true;
        visibleNav = true;
      }
      
      // if location starts with any of these, it's fullScreen mode
      const arrFullscreen = [
        '/websignup',
        '/login',
        '/signup',
        '/forgotpassword',
        '/resetpassword',
        '/finishprofile',   // includes /finishprofile*
        '/referred'         // includes /referred*
      ];
  
      for (let i=0;i<arrFullscreen.length;i++){
        let a = arrFullscreen[i];
        let b = location.substr(0,a.length);
  
        //console.log("a:",a);
        //console.log("b:",b);
        //console.log(a==b);
  
        if (a==b){
          fullScreen = true;    //fullScreen means "full-width" for now
          //visibleNav = false;
          //console.log("match on:",arrFullscreen[i]);
        }
      }
  
      let layoutMode = {
        fullScreen,
        visibleNav
      }
          
      return layoutMode;
  
    }
  
  

    recursiveChildSearch(children, target) {
        React.Children.forEach(children, child => {
            // Check if this child is the target component
            //console.log(child.type)
            if (child.type === target) {
                return true // Target found, stop the recursion
            }

            if (child.hasOwnProperty('props') 
                && React.Children.count(child.props.children) > 0) {
                // if the component has its own children search those children.
                const found = this.recursiveChildSearch(child.props.children, target)
                if (found) {
                    return true
                }
            }
        })

        return false;
    }

    componentWillMount() {
        this.checkCMS();
    }
    

    componentWillReceiveProps = (nextProps) => {
      //console.log("componentWillReceiveProps:",nextProps);
      //console.log("history-update:",nextProps.history.location.pathname);
      let layoutMode = this.getLayoutMode(nextProps.history.location.pathname.toLowerCase());
      //console.log("will receive - layoutMode:",layoutMode);
      this.setState({layoutMode});
  
    }

    checkCMS = () => {
      // Desktop only - don't call API until user is authenticated
      
      if (!this.state.nativeWebView && !this.props.user.authenticated || (this.props.user.profile && !this.props.user.profile.telephone_number)){
        setTimeout(this.checkCMS, CM_REFRESH_TIME);
        return;
      }
  
      let REFRESH_TIME = CM_REFRESH_TIME;
      let REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;
  
      if (window.location.href.indexOf('/messages/') > 0){
        REFRESH_TIME = CM_MESSAGES_REFRESH_TIME;
        REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;
      }
  
  
      getCMS(this.props.user.profile.email).then(response => {
        const {hasNotification, hasMessage, lastOwnerBooking} = this.props.CMS;
        const data = response.data;
  
        let CMS = data;
  
        if (data.hasNotification !== hasNotification || data.hasMessage !== hasMessage || data.lastOwnerBooking !== lastOwnerBooking){
          this.props.setCMS(CMS);
        } 
  
        //console.log("CM:",data);
        if (!this.state.nativeWebView) {
          setTimeout(this.checkCMS, REFRESH_TIME);
        }
    
  
      }).catch(error => {
        console.log(error);
        if (!this.state.nativeWebView) {
          setTimeout(this.checkCMS, REFRESH_TIME_ERROR);
        }
    
      });
    }
  
    componentDidMount() {
      setTimeout(() => {
            this.setState({
                nativeWebView: isNativeWebview(),
                ready: true
            });
        }, 1000);     //??? Do we need this?
    }
    handleToggle = () => {
        this.setState(state => ({ profileNavigationOpen: !state.profileNavigationOpen }));
    };
    
    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ profileNavigationOpen: false });
    };
    render() {

      const CMS = this.props.CMS;
      const { layoutMode } = this.state;


      if (!this.state.ready) {
            return(
              <LoadingModal open={true}/>
            )
        }
        if (this.state.nativeWebView) {
            return(
                <div className="oscar" style={{height: '100vh'}}>
                    {this.props.children}
                </div>
            )    
        }

        //TODO: cover all routes like signup/finishprofile etc
        let visibleNav = true;
        let strRoute = window.location.pathname.toLowerCase();
        if ((strRoute === '/' && this.props.user.authenticated) || strRoute.substr(0,7) === '/search' || strRoute.substr(0,9) === '/referred') {
          visibleNav = false;
        }




        let navMenu;

        if (this.props.user.authenticated){
          navMenu = (
            <Grid container alignItems="center" style={{height:'100%'}}>
              <Grid item>
                <a href="https://www.sharewithoscar.com.au/about-us/" target="_blank" style={styles.appbarButton} variant="contained"
                  id="wp_faq_nav">
                    About Oscar
                </a>
              </Grid>
  
              <Grid item>
                <Button style={styles.appbarButton} variant="contained"
                  component={Link} to='/listings'
                  id="wp_add_spot_nav">
                    List your spot
                </Button>
              </Grid>
  
  
              <Grid item>
                <Button style={styles.userProfileButton} variant="outlined" className="profile-menu-cp-toggle"
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    onClick={this.handleToggle}
                >
                    {this.props.user.profile.image_user === null || this.props.user.profile.image_user === undefined
                    ? <img src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" 
                        alt="Placeholder avatar"
                        style={styles.profileImage} />
                    : <img src={this.props.user.profile.image_user.path} 
                        alt="Placeholder avatar"
                        style={styles.profileImage} />}
                    {this.props.user.profile.first_name} {this.props.user.profile.last_name}
                </Button>
                <Popper open={this.state.profileNavigationOpen} anchorEl={this.anchorEl} transition disablePortal style={{zIndex:'60'}}>
                    {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ zIndex: 4, transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper style={{backgroundColor: '#ffffff'}}>
                        <ClickAwayListener onClickAway={this.handleClose}>
                        <ProfileNav
                          handleClose={this.handleClose}
                          authChange={this.props.authChange}
                          user={this.props.user}
                          extraPadding={4}
                        />
                        </ClickAwayListener>
                        </Paper>
                    </Grow>
                    )}
                </Popper>
              </Grid>
              <Grid item>
                  <IconButton color="inherit" aria-label="Menu"
                      component={Link} to='/notifications'
                  >
                      <NotificationIcon />
                      {CMS.hasNotification == true  && <div style={styles.unreadNotifications}></div>}
                  </IconButton>
              </Grid>
            </Grid>
          );
   
        } else {

          navMenu = (
            <Grid container alignItems="center" style={{height:'100%'}}>
              <Grid item>
                <a href="https://www.sharewithoscar.com.au/about-us/" target="_blank" style={styles.appbarButton} variant="contained"
                  id="wp_faq_nav">
                    About Oscar
                </a>
              </Grid>
  
              <Grid item>
                <Button style={styles.appbarButton} variant="contained"
                  component={Link} to='/listings'
                  id="wp_add_spot_nav">
                    List your spot
                </Button>
              </Grid>

              <Grid item>
                <Button style={styles.appbarButton}
                component={Link} to="/Login">
                    Log In
                </Button>
              </Grid>
              <Grid item>
                <Button style={styles.appbarButton}
                  component={Link} to="/WebSignUp">
                      Sign Up
                  </Button>
              </Grid>

  
            </Grid>
          );


        }
  

        return (
          <div className="lwp-container oscar">
  
            <div className="oscar-header">
              <div className="oscar-logo">
                <Link to={'/search'} ><img src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_teal.svg" alt="Share with Oscar" /></Link>
              </div>
              <div className="oscar-nav">
                {navMenu}
              </div>
            </div>
            
            <div className="lwp-x">
              {layoutMode.visibleNav &&
              <div className="lwp-sidemenu">
                <NavItems
                    user={this.props.user}
                    extraPadding={4}
                />
              </div>}
  
              <div className={"lwp-content" + ((layoutMode.visibleNav && !layoutMode.fullScreen)? " limit-width":"")}>
                {this.props.children}
              </div>          
            </div>
            
          </div>
        );









        // Render the layout for web browsers
        // console.log('rendering layout')
        return (
            <div className="oscar" style={styles.layoutContainer}>
                <Hidden smDown>
                    {this.props.user.authenticated ? 
                    <Paper  style={{zIndex: 15}}>
                        <Grid container style={styles.appbarContainer} alignItems="center" direction="row">
                            <Grid item style={{flexGrow: 1}} >
                                <Typography variant="title" color="primary">
                                    <div style={{display: 'block', width: '80px'}}>
                                    <Link to='/search' >
                                        <img src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_teal.svg" alt="Oscar logo" style={
                                            {
                                            width: '104px', 
                                            height: 'auto',
                                            marginTop: '12px',
                                            color: 'inherit',
                                            }} />
                                    </Link>
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Button style={styles.appbarButton}
                                        onClick={() => window.location.href = "https://www.sharewithoscar.com.au/about-us/"}>
                                            About Oscar
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button style={styles.appbarButton} variant="contained" color="primary"
                                         component={Link} to='/listspace'
                                         id="add_spot_nav">
                                            List your spot
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button style={styles.userProfileButton} variant="outlined"
                                            buttonRef={node => {
                                                this.anchorEl = node;
                                            }}
                                            onClick={this.handleToggle}
                                        >
                                            {this.props.user.profile.image_user === null || this.props.user.profile.image_user === undefined
                                            ? <img src="https://www.sharewithoscar.com.au/assets/graphics/placeholder-avatar.svg" 
                                                alt="Placeholder avatar"
                                                style={styles.profileImage} />
                                            : <img src={this.props.user.profile.image_user.path} 
                                                alt="Placeholder avatar"
                                                style={styles.profileImage} />}
                                            {this.props.user.profile.first_name} {this.props.user.profile.last_name}
                                        </Button>
                                        <Popper open={this.state.profileNavigationOpen} anchorEl={this.anchorEl} transition disablePortal>
                                            {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                id="menu-list-grow"
                                                style={{ zIndex: 4, transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper style={{backgroundColor: '#efefef'}}>
                                                <ClickAwayListener onClickAway={this.handleClose}>
                                                <ProfileNav
                                                    authChange={this.props.authChange}
                                                    user={this.props.user}
                                                    extraPadding={4}
                                                />
                                                </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                            )}
                                        </Popper>
                                    </Grid>
                                    <Grid item>
                                        <IconButton color="inherit" aria-label="Menu"
                                            component={Link} to='/Notifications'
                                        >
                                            <NotificationIcon />
                                            {this.state.unreadNotifications && <div style={styles.unreadNotifications}></div>}
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    :<Paper>
                        <Grid style={styles.appbarContainer} container alignItems="center">
                            <Grid item style={{flexGrow: 1}}>
                                <Typography variant="title" color="primary">
                                    <div style={{display: 'block', width: '80px'}}>
                                    <Link to='/search'>
                                        <img src="https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_teal.svg" alt="Oscar logo" style={
                                            {
                                            width: '104px', 
                                            height: 'auto',
                                            marginTop: '12px',
                                            color: 'inherit',
                                            }} />
                                    </Link>
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button style={styles.appbarButton}
                                onClick={() => window.location.href = "https://www.sharewithoscar.com.au/about-us/"}>
                                    About Oscar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button style={styles.appbarButton} variant="contained" color="primary"
                                component={Link} to='/spot-owner' >
                                    List your spot
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button style={styles.appbarButton}
                                component={Link} to="/Login">
                                    Log In
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button style={styles.appbarButton}
                                component={Link} to="/WebSignUp">
                                    Sign Up
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                    }
                </Hidden>
                <div style={styles.contentContainer}>
                    <div style={{display: 'flex', flexWrap:'nowrap', width: '100%'}}>
                        {visibleNav && <Hidden smDown>
                            <div style={styles.navigationContainer}>
                                <NavItems
                                    user={this.props.user}
                                    extraPadding={4}
                                />
                            </div>
                        </Hidden>}
                        <div style={styles.contentWindow}>
                            {this.props.children}    
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default (withRouter(MasterLayoutWeb))