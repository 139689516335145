import React, {Component} from 'react';
import { Route } from 'react-router-dom';

import CRUDForm from '../components/CRUDForm';

import {
    checkMissingInfo,
    updateUserProfile,
} from '../utils/Authentication.js';
import {
    updateUserData,
    updatePhoneNumber,
    verifyPhoneNumber,
} from '../api/Oscar-api.js';

import {
    Button,
    Grid,
    IconButton,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import {whiteInputStyles} from '../styles/OscarTheme.js';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import HiddenField from '../components/forms/HiddenField';

const styles = {
    noAccount: {
        borderRadius: '32px',
        color: 'white',
        border: '2px solid white',
    },
    rightCornerButton: {
        position: 'absolute',
        top: 5,
        right: 0,
        color: 'white',
    },
    progressButton: {
        backgroundColor: 'white',
        boxShadow: 'none',
    },
    title: {
        marginTop: '80px'
    },
    codeInput: {
        fontSize: '40px',
        width: '150px',
        marginTop: '20px'
    }
}

class ProfileForm extends Component {
    onFormSuccess = ({queryParams}) => {
        updateUserProfile(queryParams)
        this.props.updateUser()
        if (this.props.nextForm === `/verify/`) {
            this.props.history.push(`/finishprofile${this.props.nextForm}${queryParams.mobile_number}/`)
        } else {
            this.props.history.push(`/finishprofile/${this.props.nextForm}/`)
        }
    }
    render() {
        const {classes} = this.props

        return(
            <div className="gradientPage" style={{height: '100%', overflowY: 'auto'}}>
                <div className="mainContent topPadded">
                    <IconButton className="leftCornerButton"
                        onClick={this.props.history.goBack} style={{color: 'white'}}>
                        <IconBack />
                    </IconButton>
                    {/* <Button style={styles.rightCornerButton}
                    onClick={() => {logOut(); this.props.updateUser(); this.props.history.push('/')}}>
                        Log Out
                    </Button> */}
                    <Typography style={{color: 'white'}} color="primary" variant="display1">
                        {this.props.title}
                    </Typography>
                    <CRUDForm 
                        submitOnEnter={true}
                        endpoint={this.props.endpoint}
                        onSuccess={this.onFormSuccess}
                        loadColor="white"
                    >
                        <Grid container spacing={24} justify="center">
                            {this.props.fields.map((field, i)=> {
                                return(
                                <Grid key={i} item xs={12}>
                                    <TextField
                                        label={field.label}
                                        name={field.name}
                                        type="text"
                                        FormHelperTextProps={{
                                            classes: {
                                                'root': classes.helperText,
                                                'error': classes.helperTextError,
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                'root': classes.input,
                                                'error': classes.inputError,
                                            }
                                        }}
                                        InputLabelProps={{
                                            classes: {'root': classes.label },
                                            FormLabelClasses: {
                                                root: classes.formLabelRoot,
                                                error: classes.formLabelError,
                                                focused: classes.formLabelFocused,
                                            },
                                            shrink: true,
                                        }}
                                        placeholder={field.placeholder}
                                        fullWidth
                                        margin="normal"
                                    />
                                </Grid>    
                                )
                            })}

                            <Grid item xs={12} >
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid item>
                                        <Button style={styles.progressButton} variant="fab" type="submit">
                                            <IconForward color="primary"/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CRUDForm>
                </div>
            </div>            
        )
    }
}

class VerifyForm extends Component {
    handleResend = () => {
        updatePhoneNumber({mobile_number: this.props.match.params.phoneNumber})
    }
    onSuccess = () => {
        updateUserProfile({mobile_number: this.props.match.params.phoneNumber})
        this.props.updateUser()

        const customRedirect = localStorage.getItem('FINISH_PROFILE_REDIRECT')
        if (customRedirect !== null) {
            localStorage.removeItem('FINISH_PROFILE_REDIRECT')
            this.props.history.push(customRedirect)
        } else {
            this.props.history.push('/search');
        }
    }
    render() {
        return(
            <div style={{height: '100%', overflowY: 'auto'}}>
                <div className="mainContent topNav">
                    <IconButton className="leftCornerButton"
                        onClick={this.props.history.goBack}>
                        <IconBack color="primary"  />
                    </IconButton>
                    <Typography style={styles.title} color="primary" variant="display1">
                        Verify
                    </Typography>
                    <Typography variant="body1">
                        Enter the 4-digit code sent to you at {this.props.match.params.phoneNumber}
                    </Typography>
                    
                    <CRUDForm 
                        submitOnEnter={true}
                        endpoint={verifyPhoneNumber}
                        onSuccess={this.onSuccess}
                    >
                        <Grid container spacing={24} justify="center">
                            <Grid item xs={12}>
                                <TextField 
                                    name="entered_pin"
                                    inputProps={{
                                        maxLength: 4,
                                        style:styles.codeInput
                                    }}
                                />
                                <HiddenField name="mobile_number" value={this.props.match.params.phoneNumber} />
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Button color="primary" onClick={this.handleResend}>
                                            Resend code
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="fab" color="primary" type="submit" id="phone-number-verify">
                                            <IconForward />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CRUDForm>
                </div>
            </div>        
        )    
    }
}

function ProfileName(props) {
    const formProps = getPropsFor('name')
    const Form = withStyles(whiteInputStyles)(ProfileForm)
    return(
        <Form {...props} {...formProps} />
    )
}
function ProfileEmail(props) {
    const formProps = getPropsFor('email')
    const Form = withStyles(whiteInputStyles)(ProfileForm)
    return(
        <Form {...props} {...formProps} />
    )
}
function ProfilePhone(props) {
    const formProps = getPropsFor('telephone_number')
    const Form = withStyles(whiteInputStyles)(ProfileForm)
    return(
        <Form {...props} {...formProps} />
    )
}

function getPropsFor(missingInfo) {
    const missing = checkMissingInfo()
    const misIndex = missing.indexOf(missingInfo)
    const next = misIndex + 1 < missing.length ? missing[misIndex + 1] : '/'

    switch (missingInfo) {
        case 'name':
            return {
                endpoint: updateUserData,
                fields:[
                    {'label': 'First name', 'name': 'first_name', 'placeholder': 'Your first name'},                        
                    {'label': 'Last name', 'name': 'last_name', 'placeholder': 'Your last name'},
                    {'label': 'Postcode', 'name': 'post_code', 'placeholder': '0000'}
                ],
                nextForm: next,  
                title:"What's your name?", 
            }
        case 'email':
            return {
                endpoint: updateUserData,
                fields:[
                    {'label': 'Email address', 'name': 'email', 'placeholder': 'Your email address'},                        
                ],
                nextForm: next, 
                title: "What's your email?",
            }
        case 'telephone_number':
            return {
                endpoint: updatePhoneNumber,
                fields:[
                    {'label': 'Phone number', 'name': 'mobile_number', 'placeholder': 'eg 0400 000 000'},                        
                ],
                nextForm: '/verify/',   
                title: "What's your number?",
            }
        default:
            return null;
    }
}

export default class FinishProfile extends Component {
    componentDidMount() {
      //console.log("FinishProfile componentDidMount");
        // Check which user profile properties are missing
        const missing = checkMissingInfo()
        this.props.history.replace(`/finishprofile/${missing[0]}/`)
    }

    render() {
      //console.log("FinishProfile render");
        return(
            <div style={{height: '100%'}}>
                <Route exact path={'/finishprofile/name'} render={(props) => <ProfileName updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={'/finishprofile/email'} render={(props) => <ProfileEmail updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={'/finishprofile/telephone_number'} render={(props) => <ProfilePhone updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={`/finishprofile/verify/:phoneNumber`} render={(props) => <VerifyForm updateUser={this.props.updateUser} {...props}/>} />
            </div>
        )
    }
}