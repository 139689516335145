import React, {Component} from 'react';

import {
    Grid,
    Hidden,
    IconButton,
    Typography
} from '@material-ui/core'

import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    TwitterShareButton,
} from 'react-share';

import { isNativeWebview } from '../utils/IsNativeWebview';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';

const styles = {

    topColored: {
        paddingTop: '80px',
        backgroundColor: '#1AA5A2',
        paddingBottom: '8px',
    },
    alertPage: {
        paddingTop: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    alertImageContainer: {
        flexGrow: 1,
        padding: '16px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
    },
    codeContainer: {
        border: '1px solid #efefef',
        borderRadius: '5px',
        padding: '10px 16px',
        margin: '16px 0px',
        marginBottom: '24px'
    },
    codeCaption: {
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: '8px',
    },
    codeCode: {
        textAlign: 'center',
        color: '#1AA5A2',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '16px',
    },
    copiedMessage: {
        backgroundColor: '#1AA5A2',
        position: 'absolute',
        top: '-28px',
        right: '-10px',
        padding: '8px',
        borderRadius: '56px',
        color: 'white',
    },
    shareIcon: {
        width: '32px',
        float: 'left',
        marginRight: '10px',
    },
    shareText: {
        paddingTop: '4px',
    },
    shareOption: {
        paddingBottom: '24px',
        marginBottom: '24px',
        borderBottom: '1px solid #efefef',
    }
}

function createReferralLink(user) {
    const url = window.location.href
    let parts = url.split('/')
    //parts[parts.length - 2] = `SignUp/${user.profile.referral}/`

    return [parts[0], parts[1], parts[2], `Referred/${user.profile.referral}/`].join('/')
}

function externalLink(target, nativeTarget) {
    if (isNativeWebview()) {
        const message = JSON.stringify({
            action: "external_link",
            target: nativeTarget
        })
        window.ReactNativeWebView.postMessage(message);
    } else {
        window.location.href = target
    }
}

function twitterLink(link) {
    return `https://twitter.com/intent/tweet?text=I%E2%80%99m%20lovin%20Oscar.%20I%E2%80%99m%20using%20this%20new%20app%20called%20Share%20with%20Oscar%20which%20lets%20me%20find%20parking%20in%20people%E2%80%99s%20driveways%21%20Use%20this%20link%20to%20get%20%245%20off%20when%20you%20book&hashtags=sharewithoscar&url=${encodeURI(link)}&original_referer=https%3A%2F%2Ftwitter.com%2Fshare%3Furl%3Dhttp%253A%252F%252Foscar-ui-dev.herokuapp.com%252FSignUp%252FY9DFK094%252F%26text%3DI%25E2%2580%2599m%2520lovin%2520Oscar.%2520I%25E2%2580%2599m%2520using%2520this%2520new%2520app%2520called%2520Share%2520with%2520Oscar%2520which%2520lets%2520me%2520find%2520parking%2520in%2520people%25E2%2580%2599s%2520driveways%21%2520Use%2520this%2520link%2520to%2520get%2520%25245%2520off%2520when%2520you%2520book%26hashtags=sharewithoscar`
}
function facebookLink(link) {
    return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(link)}&quote=I%E2%80%99m%20lovin%20Oscar.%20I%E2%80%99m%20using%20this%20new%20app%20called%20Share%20with%20Oscar%2C%20which%20lets%20me%20find%20parking%20in%20people%E2%80%99s%20driveways!%20Use%20this%20link%20to%20get%20%245%20off%20when%20you%20book&hashtag=%23sharewithoscar`
}

window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
        return textArea
    }

    function selectText(textArea) {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard(textArea) {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        const area = createTextArea(text);
        selectText(area);
        copyToClipboard(area);
    };

    return {
        copy: copy
    };
})(window, document, navigator);

export default class FriendShare extends Component {
    state = {}

    render() {
        return(
            <div style={{height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div className="mainContent" style={styles.topColored}>
                <IconButton className="leftCornerButton" onClick={this.props.history.goBack}>
                    <IconBack style={{color: 'white'}}/>
                </IconButton>

                <Typography style={{color: 'white'}} variant="display1">
                Give and receive free $$$
                </Typography>            
            </div>
            <div style={styles.alertPage} className="mainContent">
                <Grid container spacing={40}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Hidden smDown>
                            <Typography variant="body1">
                            Give a friend $5 to try out Oscar, and receive $5 yourself when they park with Oscar.
                            Better yet, receive $10 if they list their parking space and receive a booking.
                            Simply share your personal link and everybody wins!
                            </Typography>
                            </Hidden>
                        {/* <div style={styles.codeContainer}>
                            <Typography style={styles.codeCaption} variant="caption">
                                Share your referral code
                            </Typography>
                            <Typography style={styles.codeCode} variant="">
                                {this.props.user.profile.referral}
                            </Typography>
                        </div> */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div>
                            <div style={styles.shareOption}>
                                <EmailShareButton subject="Sign Up with Oscar" body={"Hey,\nI’m giving you $5 off your first booking with the Oscar app. It lets you find parking in people’s driveways! Try it out here " + createReferralLink(this.props.user)} url={createReferralLink(this.props.user)}>
                                    <img alt="email share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/email.svg" />
                                    <Typography style={styles.shareText}>
                                        Email
                                    </Typography>
                                </EmailShareButton>
                            </div>
                            <div style={styles.shareOption}>
                                <div onClick={() => {
                                    window.Clipboard.copy(createReferralLink(this.props.user));
                                    this.setState({showCopied: true})
                                }}>
                                    <img alt="copy to clipboard" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/copy.svg" />
                                    <Typography style={styles.shareText}>
                                        Copy Link {this.state.showCopied && <span style={{color: '#1AA5A2', marginLeft: '48px'}}>Copied!</span>}
                                    </Typography>
                                </div>
                            </div>

                            {isNativeWebview() && 
                            <div>
                                <div style={styles.shareOption}
                                onClick={() => window.location.href = `sms:%20${window.document.isAndroid ? '?' : '&'}body=${encodeURIComponent("Hey, I’m giving you $5 off your first booking with the Oscar app. It lets you find parking in people’s driveways! Try it out here " + createReferralLink(this.props.user))}`}>
                                    <img alt="sms share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/SMS.svg" />
                                    <Typography style={styles.shareText}>
                                        SMS
                                    </Typography>
                                </div>
                                <div style={styles.shareOption}>
                                    <div onClick={() => externalLink(facebookLink(createReferralLink(this.props.user)) , facebookLink(createReferralLink(this.props.user)))}>
                                        <img alt="facebook share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/facebook.svg" />
                                        <Typography style={styles.shareText}>
                                            Facebook
                                        </Typography>
                                    </div>
                                </div>
                                <div style={styles.shareOption}>
                                    <div onClick={() => externalLink(twitterLink(createReferralLink(this.props.user)) , twitterLink(createReferralLink(this.props.user)))}>
                                        <img alt="twitter share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/twitter.svg" />
                                        <Typography style={styles.shareText}>
                                            Twitter
                                        </Typography>
                                    </div>
                                </div>
                                <div style={styles.shareOption}>
                                    <WhatsappShareButton title="Hey, I’m giving you $5 off your first booking with the Oscar app. It lets you find parking in people’s driveways! Try it out here: " url={createReferralLink(this.props.user)}>
                                        <img alt="whatsapp share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/whatsapp.svg" />
                                        <Typography style={styles.shareText}>
                                            WhatsApp
                                        </Typography>
                                    </WhatsappShareButton>
                                </div>
                                {/* <div style={styles.shareOption}
                                onClick={() => window.location.href = 'sms:;body=test'}>
                                    <img style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/wechat.svg" />
                                    <Typography style={styles.shareText}>
                                        WeChat
                                    </Typography>
                                </div> */}
                            </div>}

                            {!isNativeWebview() && 
                            <div>
                                <div style={styles.shareOption}>
                                    <FacebookShareButton hashtag="#sharewithoscar" quote="I’m lovin Oscar. I’m using this new app called Share with Oscar, which lets me find parking in people’s driveways! Use this link to get $5 off when you book" url={createReferralLink(this.props.user)}>
                                        <img alt="facebook share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/facebook.svg" />
                                        <Typography style={styles.shareText}>
                                            Facebook
                                        </Typography>
                                    </FacebookShareButton>
                                </div>
                                <div style={styles.shareOption}>
                                    <TwitterShareButton hashtags={['sharewithoscar']} title="I’m lovin Oscar. I’m using this new app called Share with Oscar which lets me find parking in people’s driveways! Use this link to get $5 off when you book" url={createReferralLink(this.props.user)}>
                                        <img alt="twitter share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/twitter.svg" />
                                        <Typography style={styles.shareText}>
                                            Twitter
                                        </Typography>
                                    </TwitterShareButton>
                                </div>
                                <div style={styles.shareOption}>
                                    <WhatsappShareButton title="Hey, I’m giving you $5 off your first booking with the Oscar app. It lets you find parking in people’s driveways! Try it out here: " url={createReferralLink(this.props.user)}>
                                        <img alt="whatsapp share" style={styles.shareIcon} src="https://www.sharewithoscar.com.au/assets/graphics/share/whatsapp.svg" />
                                        <Typography style={styles.shareText}>
                                            WhatsApp
                                        </Typography>
                                    </WhatsappShareButton>
                                </div>
                            </div>}
                        </div>
                    </Grid>
                </Grid>

            </div>
            </div>
        )
    }
}