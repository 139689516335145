import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App';
//import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

/*if ('serviceWorker' in navigator && 'PushManager' in window) {
    //console.log('Service Worker and Push is supported');
  
    //navigator.serviceWorker.register('simpleServiceWorker.js').then(function(registration) {
        // console.log('Excellent, registered with scope: ', registration.scope);
        
        // registration.pushManager.getSubscription().then(function(subscription) {
        //     const isSubscribed = !(subscription === null);

        //     if (isSubscribed) {
        //         console.log('User IS subscribed.');
        //         updateSubscriptionOnServer(subscription);
        //     } else {
        //         console.log('User is NOT subscribed.');
        //         subscribeUser(registration);
        //     }
        // })
    //});
};*/

// const applicationServerPublicKey = 'BLUzTR0TeZ3xkoQ6Ibc3XcxFcxeaunebZX-5QlNZCxkMmphlxy0q8s2ZToyACU2NCV789AidWbEGH8WlWoN3DkM';
// function subscribeUser(registration) {
//     const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
//     registration.pushManager.subscribe({
//         userVisibleOnly: true,
//         applicationServerKey: applicationServerKey
//     })
//     .then(function(subscription) {
//         console.log('User is subscribed.');
    
//         updateSubscriptionOnServer(subscription);
//     })
//     .catch(function(err) {
//         console.log('Failed to subscribe the user: ', err);
//     });
// }

// function updateSubscriptionOnServer(subscription) {
//     // TODO: Send subscription to application server  
//     console.log(JSON.stringify(subscription));
//     //document.getElementById("tokenElem").innerText = JSON.stringify(subscription);
// }

// function urlB64ToUint8Array(base64String) {
//     const padding = '='.repeat((4 - base64String.length % 4) % 4);
//     const base64 = (base64String + padding)
//         .replace(/\-/g, '+')
//         .replace(/_/g, '/');
  
//     const rawData = window.atob(base64);
//     const outputArray = new Uint8Array(rawData.length);
  
//     for (let i = 0; i < rawData.length; ++i) {
//         outputArray[i] = rawData.charCodeAt(i);
//     }
//     return outputArray;
// }