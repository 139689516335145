import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import { isNativeWebview } from '../utils/IsNativeWebview';
import {NavItems, ProfileNav} from './NavigationMenu.js';


import {
    Button,
    ClickAwayListener,
    Grid,
    Grow,
    Hidden,
    IconButton,
    Paper,
    Popper,
    Typography
} from '@material-ui/core'

import NotificationIcon from '@material-ui/icons/Notifications';

import {getCMS, getHotelBranding} from '../api/Oscar-api.js';

import '../styles/Hotels.css';
//import '../styles/Admin.css';

const CM_REFRESH_TIME = 10000;
const CM_REFRESH_TIME_ERROR = 20000;

// If in Messages mode, it will check notification more often
// Will only fetch from API if there are new messages (all notification checking load goes to AWS instead)
const CM_MESSAGES_REFRESH_TIME = 2000;
const CM_MESSAGES_TIME_ERROR = 10000;

class MasterLayoutHotel extends Component {
    constructor(props){
      super(props);
      console.log("MasterLayoutHotel:Constructor");

      // hotelName: ie. novotel-sydney, meriton-suites-sydney, etc.
      const hotelName = this.props.history.location.pathname.substr(5+1).replace('/','');
      // Will fetch branding/info from CF cached DynamoDB lookup
      
      console.log({hotelName});
      const layoutMode = this.getLayoutMode(hotelName.toLowerCase());
      //console.log("layoutMode:",layoutMode);
      
      this._lastTimestamp = null;
      this.state = {
        hotelName,
        layoutMode,
        //ready: false,
        unreadNotifications: false,
        profileNavigationOpen: false,
        profileNavClick: true,
        nativeWebView: isNativeWebview()
      }
  
     
    }
    
    getLayoutMode(location){
      //console.log("getLayoutMode:",location);
      //console.log("isAuthenticated:",this.props.user.authenticated);
  
      let fullScreen = false;
      let visibleNav = true;
      
      if (location.indexOf('/search') > -1 || location === '/'){
        fullScreen = false;
        visibleNav = false;
      }

      // if at root and NOT signed in, we want full screen
      if (!this.props.user.authenticated && location === '/'){
        fullScreen = true;
        visibleNav = true;
      }
      
      // if location starts with any of these, it's fullScreen mode
      const arrFullscreen = [
        '/websignup',
        '/login',
        '/signup',
        '/forgotpassword',
        '/resetpassword',
        '/finishprofile',   // includes /finishprofile*
        '/referred'         // includes /referred*
      ];
  
      for (let i=0;i<arrFullscreen.length;i++){
        let a = arrFullscreen[i];
        let b = location.substr(0,a.length);
  
        //console.log("a:",a);
        //console.log("b:",b);
        //console.log(a==b);
  
        if (a==b){
          fullScreen = true;    //fullScreen means "full-width" for now
          //visibleNav = false;
          //console.log("match on:",arrFullscreen[i]);
        }
      }
  
      let layoutMode = {
        fullScreen,
        visibleNav
      }
      
      //console.log({layoutMode});
      return layoutMode;
  
    }
  
  
    componentWillMount() {
        
        //console.log("willMount");
        //this.checkCMS();
    }
    
/*    componentWillReceiveProps = (nextProps) => {
      console.log("componentWillReceiveProps:",nextProps);
      //console.log("history-update:",nextProps.history.location.pathname);
      let layoutMode = this.getLayoutMode(nextProps.history.location.pathname.toLowerCase());
      //console.log("will receive - layoutMode:",layoutMode);
      this.setState({layoutMode});
  
    }
*/
    checkCMS = () => {
      // Desktop only - don't call API until user is authenticated
      
      if (!this.state.nativeWebView && !this.props.user.authenticated || (this.props.user.profile && !this.props.user.profile.telephone_number)){
        setTimeout(this.checkCMS, CM_REFRESH_TIME);
        return;
      }
  
      let REFRESH_TIME = CM_REFRESH_TIME;
      let REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;
  
      if (window.location.href.indexOf('/messages/') > 0){
        REFRESH_TIME = CM_MESSAGES_REFRESH_TIME;
        REFRESH_TIME_ERROR = CM_REFRESH_TIME_ERROR;
      }
  
  
      getCMS(this.props.user.profile.email).then(response => {
        const {hasNotification, hasMessage, lastOwnerBooking} = this.props.CMS;
        const data = response.data;
  
        let CMS = data;
  
        if (data.hasNotification !== hasNotification || data.hasMessage !== hasMessage || data.lastOwnerBooking !== lastOwnerBooking){
          this.props.setCMS(CMS);
        } 
  
        //console.log("CM:",data);
        if (!this.state.nativeWebView) {
          setTimeout(this.checkCMS, REFRESH_TIME);
        }
    
  
      }).catch(error => {
        console.log(error);
        if (!this.state.nativeWebView) {
          setTimeout(this.checkCMS, REFRESH_TIME_ERROR);
        }
    
      });
    }
  
    handleToggle = () => {
        this.setState(state => ({ profileNavigationOpen: !state.profileNavigationOpen }));
    };
    
    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ profileNavigationOpen: false });
    };


    testDamian2(){
      let that = this;
      setTimeout(() => {
        let tmpDamian2 = that.state.tmpDamian2 || 1;
        tmpDamian2++;
        console.log({tmpDamian2});
        that.setState({tmpDamian2});
        //printListenerCount();
        that.testDamian2();
      }, 1000);
    }
  
    render() {
      console.log("MasterLayoutHotel:RENDER");
      //return <h1>TEST</h1>;

      const CMS = this.props.CMS;
      const { hotelName, layoutMode } = this.state;

      // Branding comes from cached DynamoDB lookup (no Heroku API hit until second page)

      // Show nice placeholder
      /*if (!this.state.ready) {
            return(
              <>
                <h1 style={{textAlign:'center'}}>{hotelName}</h1>
                <LoadingModal open={true}/>
              </>
              
            )
        }*/
        /*if (this.state.nativeWebView) {
            return(
                <div className="oscar" style={{height: '100vh'}}>
                    {this.props.children}
                </div>
            )    
        }*/

 

        console.log("State:",this.state);
        

        return (
          <>
              {this.props.children}
          </>
        );







    }
}

export default (withRouter(MasterLayoutHotel))