import React, { Component } from 'react';
import moment from 'moment';

import { 
    Grid,
    Hidden
} from '@material-ui/core';

import MainNav from '../components/MainNav.js';
import MainNavWP from '../components/MainNavWP.js';
import ParkingSearch from '../components/ParkingSearch.js';
import ParkingMap from '../components/ParkingMap.js';

import {
    requestLocation, 
    checkUserLocationPermissions, 
    requestLocationPermissions
} from '../utils/Location.js';
import {
    ParseDateTimeParameters
} from '../utils/TimeFormats.js';

import PermissionsPrompt from './PermissionsPrompt.js';
import BookingLimitExceeded from './BookingLimitExceeded.js';



//======================================================================
// Summary of Search/ParkingMap location on startup
//----------------------------------------------------------------------
// Search.js [this.state] -> props for -> ParkingSearch.js + ParkingMap.js
//----------------------------------------------------------------------
// ParkingMap.js is then allowed to operate freely as an uncontrolled
// component, until a new Search is initiated
//----------------------------------------------------------------------
/*
componentWillMount() -> this.setupDefaultMap(this.props)
{
    if (props.searchParams){
        parkingMap.doSearch(searchParams);
        return;
    }

    location = props.urlParams;
    if (location){
        parkingMap.doSearch(location);
    }
    else {
        location = authenticated_user.profile.post_code || DEFAULT_POST_CODE;

        const askPermissionMobile = checkUserLocationPermissions();        

        setState()->INITIAL_MOUNT + RENDER: [Search.js + ParkingSearch.js + ParkingMap.js]

        // Mobile displays askPermission screen to trigger allow/deny
        if (askPermissionMobile)
            return;

        // Safari Desktop
        if (!navigator.permissions) {
            new_location = getGeoLocation();                    // Attempt geo search
            
            if (!new_location){
                parkingMap.doSearch(location);                 // User clicked 'block' -OR- geolocation timeout
            } else {
                parkingMap.doSearch(new_location);             // Valid geolocation return
            }
            return;
        } 


        // Standard Desktop (Chrome/Firefox etc)
        if (geopermission == 'denied'){
            parkingMap.doSearch(location);
        } else {
            if (geopermission = 'prompt'){
                setState({geoWaiting:'prompt'});     // Asking user for permission
            }
            new_location = getGeoLocation();
            
            if (!new_location){
                parkingMap.doSearch(location);                 // User clicked 'block' -OR- geolocation timeout
            } else {
                parkingMap.doSearch(new_location);             // Valid geolocation return
            }
        }


    }
}
*/
//----------------------------------------------------------------------
// DEFAULT SETTINGS
//----------------------------------------------------------------------
const DEFAULT_POST_CODE = 2000;
const DEFAULT_ZOOM = 15;

const TIMEOUT_GEOLOCATION_SEARCH = 3000; //15000; // 3000;
const TIMEOUT_GEOLOCATION_DESKTOP_PROMPT = 5000; //10000; //15000; // 10000;
// TODO: what happens prompt->allow->geolocation-failure (wait the entire 30s?)

// Snap user to nearest city based on post_code (first render before geolocation)
const postCodes = [
    { min:1000, max:1999, city:'sydney' },
    { min:2000, max:2599, city:'sydney' },
    { min:2619, max:2899, city:'sydney' },
    { min:2921, max:2999, city:'sydney' },
    { min:200, max:299, city:'canberra' },
    { min:2600, max:2618, city:'canberra'},
    { min:2900, max:2920, city:'canberra' },
    { min:3000, max:3999, city:'melbourne' },
    { min:8000, max:8999, city:'melbourne' },
    { min:4000, max:4999, city:'brisbane' },
    { min:9000, max:9999, city:'brisbane' },
    { min:5000, max:5799, city:'adelaide' },
    { min:5800, max:5999, city:'adelaide' },
    { min:6000, max:6797, city:'perth' },
    { min:6800, max:6999, city:'perth' },
    { min:7000, max:7799, city:'hobart' },
    { min:7800, max:7999, city:'hobart' },
    { min:800, max:899, city:'darwin' },
    { min:900, max:999, city:'darwin' },
];

const cityLocations = {
    'sydney':{ address:'Sydney NSW, Australia', cityName:'Sydney', lat:-33.86989440900426, lng:151.20701948300706 },
    'canberra':{ address:'Canberra ACT, Australia', cityName:'Canberra', lat:-35.281795030466505, lng:149.12885602002373 },
    'melbourne':{ address:'Melbourne VIC, Australia', cityName:'Melbourne', lat:-37.81250104162191, lng:144.9628845032138 },
    'brisbane':{ address:'Brisbane QLD, Australia', cityName:'Brisbane', lat:-27.47057211943487, lng:153.02672826909514 },
    'adelaide':{ address:'Adelaide SA, Australia', cityName:'Adelaide', lat:-34.931407762875644, lng:138.603772510881 },
    'perth':{ address:'Perth WA, Australia', cityName:'Perth', lat:-31.9487155198541, lng:115.85739360838747 },
    'hobart':{ address:'Hobart TAS, Australia', cityName:'Hobart', lat:-42.881631438612736, lng:147.327124098757 },
    'darwin':{ address:'Darwin NT, Australia', cityName:'Darwin', lat:-12.456651760036392, lng:130.83957904520204 },
    'pagewood':{ address:'Pagewood NSW, Australia', cityName:'Pagewood', lat:-33.9403504493707, lng:151.2135043443449 },
    'eastgardens':{ address:'Eastgardens NSW, Australia', cityName:'Eastgardens', lat:-33.944840065493665, lng:151.2248423578093 },
     
};

const styles = {
    mapContainer: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        //zIndex: '-1',
    },
    mapContainerDesktop: {
        height: '100%'
    }
}


// Geolocation search in progress
const geoWaitingModal_Desktop_Search = (
    <div className="modal-geosearch noselect">
        <h1>Finding parking spaces...</h1>
    </div>);

/*
const geoWaitingModal_Desktop_Search = (
    <div style={{width:'100vw',height:'100vh',zIndex:1300,backgroundColor:'#ffffffaa',position:'fixed',bottom:'0',opacity:'1.0',textAlign:'center'}}>
        <h1 style={{marginTop:'40vh',color:'#1aa5a2',fontSize:'36px'}}>Finding parking spaces...</h1>
    </div>);

*/

// Waiting for user prompt - allow/deny -> then geolocation search
/*const geoWaitingModal_Desktop_Prompt = (
    <div style={{width:'100vw',height:'100vh',zIndex:1300,backgroundColor:'#0099ff77',position:'fixed',bottom:'0',opacity:'1.0',textAlign:'center'}}>
        <h1 style={{marginTop:'30vh',color:'#ffffff',fontSize:'36px'}}>GEO - USER PROMPT</h1>
    </div>);*/

const geoWaitingModal_Desktop_Prompt = geoWaitingModal_Desktop_Search;
//----------------------------------------------------------------------
// geoLocation Function
//----------------------------------------------------------------------
async function getGeoLocation(timeoutValue) {
    //console.log("### getGeoLocation");

    let location = await Promise.race([
        requestLocation(),
        new Promise(function(resolve, reject) { setTimeout(resolve, timeoutValue, { error:'geolocationTakingTooLong' })})
    ]);

    if (location.error || location.address === 'error'){
        // On error we will execute parking_space search on current position
        //console.log("### getGeoLocation - error");
        location.error = 'error'; 
        return location;

    } else {
        // On success extract address for current location, which triggers a parking_space search
        //console.log("### getGeoLocation - success",location);
        return {
            address: location.address,            
            lat: location.latLng.lat, 
            lng: location.latLng.lng,
        }    
    }
}

//----------------------------------------------------------------------
// Process URL params
// This modifies the original input - really should be immutable
//----------------------------------------------------------------------
function processParams(params, qs=null) {
  
    //console.log("processParams:",JSON.stringify(params,null,2));  
    params.lat = parseFloat(params.lat)
    params.lng = parseFloat(params.lng)

    // search?monthly
    // search?monthly&lead=xxxx
    // search?lead=xxx
    let searchMode = 0;  // Casual





    if (qs){
      if (qs.indexOf('?monthly') >= 0){
        searchMode = 1; // Monthly
      }    

      // TODO: we need to do proper qs extraction, this is a quick fix
      let j = qs.indexOf('lead=');
      if (j > 0){
        let leadSource = qs.substr(j+5);
        localStorage.setItem('leadSource', leadSource);
      }
  
    }
    


    if (isNaN(params.lat) || isNaN(params.lng)) {
      //console.log("no params");  
      return undefined;
    }


    if (window.WPMode === 'bays'){
      //console.log("MERITON");

      searchMode = 1;
      //const monthly_default = moment().add(2,'day');
      
      const monthly_default_meriton = moment().add(1,'day').format('YYYY-MM-DD');
      params.search_type='parking_bay';
      params.msm_startdate=monthly_default_meriton; //monthly_default_meriton;
      params.booking_msm_startdate=monthly_default_meriton; //monthly_default_meriton;
      params.booking_msm_enddate=null;
      params.availability_mode='0';  //-All parking options' //'all' // default to ALL *** THIS IS SOOOO STUPID... will fix our SELECT component soon ***
      params.initialUuid = params.uuid;

      params.sortBy = 1;  //DISTANCE
//      console.log("paramsM:",params);
      return params;

      /*
      locationDefault = {
        ...defaultGeo,
        search_type: 'parking_bay',
        msm_startdate: monthly_default_meriton, // '2021-07-18',
        booking_msm_startdate: monthly_default_meriton,
        booking_msm_enddate: null,
        availability_mode: '0', //-All parking options' //'all' // default to ALL *** THIS IS SOOOO STUPID... will fix our SELECT component soon ***
        //startDate: now,
        //startTime: now,
        //endDate: later,
        //endTime: later,
        //duration: later.diff(now, 'minutes'),
      } */   

    } else if (searchMode == 0 && params.startDate && params.endDate) {
      // Check if date params were supplied (is this ONLY from homepage search?)      
      params.startDate = round_time(moment(`${params.startDate} ${params.startTime}`, ParseDateTimeParameters), moment.duration(15, "minutes"), "ceil").add(1,'hour');
        params.startTime = params.startDate
        params.endDate = round_time(moment(`${params.endDate} ${params.endTime}`, ParseDateTimeParameters), moment.duration(15, "minutes"), "ceil").add(1,'hour');
        params.endTime = params.endDate;
        params.sortBy = 1;  //DISTANCE
        //console.log("params2:",params);  
        // Verify that params are valid
        const now = moment()
        if (params.startDate.isSame(params.endDate, 'days')) {
            if (params.startDate.isBefore(params.endDate)) {
                if (params.startDate.isAfter(now)) {
                    return params
                }
            }
        }
        //console.log("no params2");  

        return undefined;
    } else {

        const monthly_default = moment().add(2,'day');
        //const now = round_time(moment().add(1,'hour'), moment.duration(15, "minutes"), "ceil");
        //const later = round_time(moment().add(1+1,'hour'), moment.duration(15, "minutes"), "ceil"); //+1 hour

        if (searchMode == 1){
          params.startDate = monthly_default;
          params.startTime = monthly_default;
          params.endDate = null;
          params.endTime = null;
          params.booking_monthly_startdate = monthly_default;
          params.booking_monthly_enddate = null;
          params.sortBy = 1;  //DISTANCE
  
        } else {
          const now = round_time(moment().add(1,'hour'), moment.duration(15, "minutes"), "ceil");
          const later = round_time(moment().add(1+1,'hour'), moment.duration(15, "minutes"), "ceil"); //+1 hour
      
          params.startDate = now;
          params.startTime = now;
          params.endDate = later;
          params.endTime = later;
          params.sortBy = 1;  //DISTANCE
  
        }
        
        params.search_type = 'parking_space';
        params.searchMode = searchMode;    //0 = default casual, 1=monthly

        //params.activeUuid = params.uuid;
        //params.selectedSpace = params.uuid;
        params.initialUuid = params.uuid;
        //console.log("return params:",params);  
        //activeUuid={this.props.match.params.uuid}        
        //console.log("paramsX:",params);
        return params;

    }
}
//======================================================================


function round_time(date, duration, method) {
  //console.log("round_time:");
  //console.log({duration});
  //console.log({method});
  return moment(Math[method]((+date) / (+duration)) * (+duration)); 
}

export default class Search extends Component {
    constructor(props){
      super(props);
      //console.log({props});

      this.state = {
        width:0,
        height:0,
        browserCSSMode: window.innerWidth >= 960 ? "desktop":"mobile"
      }
      //this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      
    }

    updateDimensions = () => {
      const browserCSSMode = window.innerWidth >= 960 ? "desktop":"mobile";
      if (browserCSSMode !== this.state.browserCSSMode){
        this.setState({ browserCSSMode });    //width: window.innerWidth, height: window.innerHeight
      } 
    };
  

    /*updateWindowDimensions() {
      console.log("Search:updateWindowDimensions");
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }*/

    componentDidMount() {
      //console.log("didmount");
      //this.updateWindowDimensions();
      window.addEventListener('resize', this.updateDimensions);

      let redirect_path = localStorage.getItem("redirect");
      
      //console.log({redirect_path});  
      if (redirect_path){
        console.log(`REDIRECTING to ${redirect_path}`);
        localStorage.removeItem("redirect");
        this.props.history.push(redirect_path);
      } 

    }

    componentWillMount() {
      //console.log("willmount");
      window.removeEventListener('resize', this.updateDimensions); 

      this.setupDefaultMap(this.props);
              

//http://localhost:3000/search/The%20Peninsula%20on%20Walker%20St/-33.824486/151.088399/f39aaf86-3738-49b5-93ff-61bfb7973c53
      
    }

    setupDefaultMap(props) {
        const searchCounter = 0;
        //console.log("Search:setupDegaultMap+forceHideNav -> true");
        //props.forceHideNav(true);

        // Existing paramaters (return from Booking screen or other menu item)
        if (props.searchParams){
          //console.log("XX:hasSearchParams");
            this.setState({
                ...props.searchParams,
                //spaceData: props.spaceData,
                searchCounter:searchCounter+1,
                forceSearchCounter:searchCounter+1

            });
            return;
        }

        // urlParams passed in from browser (ie. link from www website)
        
        let urlParams = processParams(this.props.match.params, this.props.location.search);
        if (urlParams) {
          //console.log("XX:hasUrlParams");
          //console.log({urlParams});

/* 
{
    "address": "World Square at Sydney, NSW",
    "cityName": "Sydney",
    "lat": -33.87595384191041,
    "lng": 151.20696883068274,
    "search_type": "parking_bay",
    "msm_startdate": "2023-02-20",
    "booking_msm_startdate": "2023-02-20",
    "booking_msm_enddate": null,
    "availability_mode": "0"
}
*/

            this.setState({
                ...urlParams,
                searchCounter:searchCounter+1,
                forceSearchCounter:searchCounter+1
            });
            return;
        }

        // Determine initial offline location from user post_code
        const user = this.props.user;
        let post_code = DEFAULT_POST_CODE;

        if (user && user.profile){
          if (props.WPDomain === 'community'){
            if (user.profile.post_code_community) {
              post_code = user.profile.post_code_community;
            }
            
          } else {
            if (user.profile.post_code) {
              post_code = user.profile.post_code; 

            }

          }
        } 

  
        let defaultGeo;
        for (let i=0; i < postCodes.length; i++) {
            if (post_code >= postCodes[i].min && post_code <= postCodes[i].max){
                defaultGeo = cityLocations[postCodes[i].city];
                break;
            }
        }
        if (!defaultGeo){
            defaultGeo = cityLocations['sydney'];       //Fail-safe
        }

        if (props.WPMode === 'bays'){
          const default_building = window.WPDomain === 'community' ? this.props.user.profile.default_building_community: this.props.user.profile.default_building;

          //const default_building = user.profile.default_building;

          if (default_building && default_building.suburb){
            // only use if we have enough data
            defaultGeo = { address:`${default_building.building_name} at ${default_building.suburb}, ${default_building.state}`, cityName:default_building.suburb, lat:default_building.latitude, lng:default_building.longitude};
          } else {
            //defaultGeo = cityLocations['eastgardens'];       //Fail-safe
            defaultGeo = cityLocations['sydney'];       //Fail-safe Meriton
          }
          //console.log("defaultGeo:",defaultGeo);
          //defaultGeo = cityLocations['eastgardens'];
        }

        const now = round_time(moment().add(1,'hour'), moment.duration(15, "minutes"), "ceil");
        const later = round_time(moment().add(1+1,'hour'), moment.duration(15, "minutes"), "ceil"); //+1 hour
        const monthly_default_meriton = moment().add(1,'day').format('YYYY-MM-DD');

        let locationDefault;

        let lastSearchJSON;
        const lastSearch = localStorage.getItem("lastSearch");
        
        try {
          lastSearchJSON = JSON.parse(lastSearch);
          //console.log("searchMode:", lastSearchJSON);

        } catch (e) {

        }

        //console.log({lastSearchJSON});


        if (props.WPMode === 'bays'){
          

          //console.log("xxx");
          locationDefault = {
            ...defaultGeo,
            search_type: 'parking_bay',
            msm_startdate: monthly_default_meriton, // '2021-07-18',
            booking_msm_startdate: monthly_default_meriton,
            booking_msm_enddate: null,
            availability_mode: '0', //-All parking options' //'all' // default to ALL *** THIS IS SOOOO STUPID... will fix our SELECT component soon ***
            //startDate: now,
            //startTime: now,
            //endDate: later,
            //endTime: later,
            //duration: later.diff(now, 'minutes'),
          }    
          //console.log({locationDefault});

        } else {
          //console.log("zzz");
          //console.log("now:",now.toString());
          //console.log("later:",later.toString());

          let searchMode = 0;  // Casual
          let qs = this.props.location.search;

          if (qs && qs.indexOf('?monthly') >= 0){
            // QS overrides everything (ie. from Community)
            searchMode = 1; // Monthly
            //console.log("set search to monthly");

          } else if (lastSearchJSON) {
            // Override with last search
            searchMode = lastSearchJSON.searchMode;
            defaultGeo.address = lastSearchJSON.address;
            defaultGeo.cityName = lastSearchJSON.address;
            defaultGeo.lat = lastSearchJSON.lat;
            defaultGeo.lng = lastSearchJSON.lng;

          }   
      

          if (searchMode == 1){
            const monthly_default = moment().add(2,'day');
            //console.log(defaultGeo);
            
            // Monthly
            locationDefault = {
              ...defaultGeo,
              search_type: 'parking_space',
              searchMode:1,    //0 = default casual, 1=monthly
              booking_monthly_startdate: monthly_default,
              booking_monthly_enddate: null,
              startDate:monthly_default,
              startTime:monthly_default,
              endDate:null, //monthly_default,
              endTime:null, //monthly_default,
              //duration: later.diff(now, 'minutes'),
              sortBy: 1, // default to price (1=distance)
              includeAll: 0
            }    
  
          } else {
            // Casual
            locationDefault = {
              ...defaultGeo,
              search_type: 'parking_space',
              searchMode:0,    //0 = default casual, 1=monthly
              booking_monthly_startdate: null,
              booking_monthly_enddate: null,
              startDate: now,
              startTime: now,
              endDate: later,
              endTime: later,
              //duration: later.diff(now, 'minutes'),
              sortBy: 1, // default to price (1=distance)
              includeAll: 0
            }    
          }


        }


        // MSM/Meriton users center on their default_building
        if (props.WPMode === 'bays'){
          this.setState({
            askPermissionMobile:false,
            geoWaiting:null,
            searching: false,
            searchCounter:searchCounter+1,
            forceSearchCounter:searchCounter+1,
            ...locationDefault
          });
          return;
        }




        const askPermissionMobile = checkUserLocationPermissions();

        // Trigger initial render with default map location from post_code
        this.setState({
            askPermissionMobile,    
            geoWaiting: null,
            searching: false,
            //spaceData: props.spaceData,
            searchCounter,
            ...locationDefault
        });




        // Mobile startup is finished, will display a permission screen to user
        if (askPermissionMobile){
            //console.log("### setupDefaultMap - return");
            return;
        }
            
            

        let that = this;
        let geoWaiting = 'search';
        let geoTimeout = TIMEOUT_GEOLOCATION_SEARCH;

        // Safari Desktop - doesn't support Permissions API
        if (!navigator.permissions){

            //console.log("### setupDefaultMap - Safari startup");
            that.setState({geoWaiting});
            getGeoLocation(TIMEOUT_GEOLOCATION_SEARCH).then((location) => {
                if (location.error){
                    //console.log("### setupDefaultMap - Safari error");

                    // failed: force search at current default location
                    that.setState({
                        geoWaiting:null,
                        searchCounter:searchCounter+1
                    });

                } else {
                    //console.log("### setupDefaultMap - Safari success",location);
                    // Update address and force parking_search in the new location
                    that.setState({
                        ...location, 
                        geoWaiting:null,
                        searchCounter:searchCounter+1
                    });
                }
            });
            return;
        }

        // Standard Desktop (Chrome/Firefox etc)
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
            //console.log("### setupDefaultMap - Desktop");
    
            if (permissionStatus.state === 'denied'){
                //console.log("### setupDefaultMap - Desktop - denied");
                // denied -> trigger parking_search for current default location
                that.setState({searchCounter: searchCounter+1});    //ready:true
                return;

            } else if (permissionStatus.state === 'prompt'){
                //console.log("### setupDefaultMap - Desktop - prompt");
                // prompt -> wait for the user to respond with Allow/Block
                geoTimeout = TIMEOUT_GEOLOCATION_DESKTOP_PROMPT;
                geoWaiting = 'prompt';

                // TODO: maybe wait for user response
                /* permissionStatus.onchange = function() {
                    that.setState({geoWaiting:'answer was:'+this.state});

                    if (this.state === 'denied'){
                        // denied -> trigger parking_search for current default location
                        that.setState({ready:true, geoWaiting:null, searchCounter: searchCounter+1});
                    } 
                    };*/
            } 
            
            if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'){
                //console.log("### setupDefaultMap - Desktop - start granted/prompt");
                that.setState({geoWaiting});
                getGeoLocation(geoTimeout).then((location) => {
                    if (location.error){
                        //console.log("### setupDefaultMap - Desktop - start fail");
                        // granted->failed (or timeout): force search at current default location
                        that.setState({
                            geoWaiting:null,
                            searchCounter:searchCounter+1
                        });

                    } else {
                        //console.log("### setupDefaultMap - Desktop - start success",location);
                        // Update address and force parking_search in the new location
                        that.setState({
                            ...location, 
                            geoWaiting:null,
                            searchCounter:searchCounter+1
                        });
                    }
                });
            }

        }); //<- navigator.permissions
 
    }

    componentWillUnmount() {
      //console.log("Search:componentWillUnmount()");
        /////////////////this.props.forceHideNav(false);
      window.removeEventListener('resize', this.updateDimensions);

      if (this.props.saveSearchParams){
        this.props.saveSearchParams(this.state);
      }  
    }

    // Pass updates down through to BookingConfirmation component
    updateMSMDates = (startDate, endDate) => {

      // Check for SAME date, don't allow
      if (moment(startDate).isSame(moment(endDate))){
        //console.log("Same day, add one day");
        endDate = moment(startDate).add(1,'days').format("YYYY-MM-DD");
      }

      // startDate>enddate... enddate = startdate+1day
      if (moment(startDate).isAfter(moment(endDate))){
        endDate = moment(startDate).add(1,'days').format("YYYY-MM-DD");        
      }


      this.setState({booking_msm_startdate:startDate, booking_msm_enddate:endDate});
    }

    setMSMStartDate = (startDate) => {
      let booking_msm_startdate = moment(startDate).format("YYYY-MM-DD");
      this.updateMSMDates(booking_msm_startdate, this.state.booking_msm_enddate);
    }
    setMSMEndDate = (endDate) => {
      if (!endDate){
        this.updateMSMDates(this.state.booking_msm_startdate, null);
      } else {
        let booking_msm_enddate = moment(endDate).format("YYYY-MM-DD");
        this.updateMSMDates(this.state.booking_msm_startdate, booking_msm_enddate);
      }
    }


    handleInputChange = (event) => {
        const target = event.target;
        // Special case to handle check boxes - their value resides in checked not value
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    handleLocationSelected = (address, latLng) => {
        //console.log("address:",address);

        if (address !== 'error'){
            // On successful geolocation, force search in this new location
            const searchCounter = this.state.searchCounter || 0;

            this.setState({
                address: address,
                lat: latLng.lat,
                lng: latLng.lng,
                searchCounter: searchCounter+1
            });
        }
        // ignore errors for now (timeout or permission denied by user)
       
    }

    handleDateSelected = (start) => {
      // Will trigger entire new search, as if they clicked handleSearchClick()
      //console.log("handleDateSelected:",start);
      this.setState({
        msm_startdate:start,
        booking_msm_startdate:start,
        booking_msm_enddate:null
          //startTime: start,
          //endTime: end,
      })
  }



    handleTimeRangeSelected = (start, end) => {
      //console.log("handleTimeRangeSelected...");

      //console.log({start});
      //console.log({end});
      // Will trigger entire new search, as if they clicked handleSearchClick()
        this.setState({
            startTime: start,
            endTime: end,
        })
    }

    handleAvailabilitySelected = (availability_mode) => {
      // Will trigger entire new search, as if they clicked handleSearchClick()
      //console.log("handleAvailabilitySelected",availability_mode);
      this.setState({
        availability_mode
        //msm_startdate:start
          //startTime: start,
          //endTime: end,
      })
    }


    handleSearchModeChange = (searchMode) =>{
      //console.log({searchMode});

      // If set to monthly, need appropriate date range
      if (searchMode === 1){
        const monthly_default = moment().add(2,'day');
        //const now = round_time(moment().add(1,'hour'), moment.duration(15, "minutes"), "ceil");
        //const later = round_time(moment().add(1+1,'hour'), moment.duration(15, "minutes"), "ceil"); //+1 hour

        // This is also done in processParams... need to unify all of this when we get time

        //console.log({monthly_default});
        //console.log("switching to Monthly");
        this.setState({
          searchMode,
          startDate:monthly_default,
          startTime:monthly_default,
          endDate:null, //monthly_default,
          endTime:null, //monthly_default,
          booking_monthly_startdate: monthly_default,
          booking_monthly_enddate: null
        });
  
      } else {
        // Reset default
        const now = round_time(moment().add(1,'hour'), moment.duration(15, "minutes"), "ceil");
        const later = round_time(moment().add(1+1,'hour'), moment.duration(15, "minutes"), "ceil"); //+1 hour

        //console.log("switching to Casual");
        this.setState({
          searchMode,
          startDate: now,
          startTime: now,
          endDate: later,
          endTime: later,
          booking_monthly_startdate: null,
          booking_monthly_enddate: null

        });
  
      }
      //
/*
            startDate: now,
            startTime: now,
            endDate: later,
            endTime: later,

*/

    }

    handleSortByChange = (sortBy) => {
      // Will trigger entire new search, as if they clicked handleSearchClick()
      //console.log("handleAvailabilitySelected",availability_mode);
      this.setState({
        sortBy
        //msm_startdate:start
          //startTime: start,
          //endTime: end,
      })
    }

    handleSearchClick = () => {
      // Update searchCounter key on child components to force a fresh search
      //console.log("handleSearchClick");  
      const searchCounter = this.state.searchCounter;
        this.setState({
            searchCounter: searchCounter+1,
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
    }

    handleMarkerClick = (uuid) => {
        this.setState({
            selectedSpace: uuid,
        });
    }

    // Mobile Only - Permissions Prompt
    handleDismissPermissions = () => {
        // Force search of currently loaded default address
        const searchCounter = this.state.searchCounter;
        this.setState({
            askPermissionMobile: false,
            searchCounter: searchCounter+1,
        });
    }

    // Mobile Only - Permissions Prompt
    handleAllowPermissions = () => {
        requestLocationPermissions(() => this.setState({askPermissionMobile: false}));

        const searchCounter = 0;
        let that = this;
        getGeoLocation(TIMEOUT_GEOLOCATION_SEARCH).then((location) => {
            if (location.error){
                // failed: force search at current default location
                that.setState({
                    geoWaiting:null,
                    searchCounter:searchCounter+1
                });

            } else {
                // Update address and force parking_search in the new location
                that.setState({
                    ...location, 
                    geoWaiting:null,
                    searchCounter:searchCounter+1
                });
            }
        });        
    }

    //==================================================================
    // RENDER
    //==================================================================
    render() {
      //console.log("Search.js RENDER state:",this.state);
      const { WPDomain, WPMode } = this.props;
      const { browserCSSMode } = this.state;
      

      //console.log({WPMode});
      if (!this.state){
            return null;

        } else if (this.state.askPermissionMobile) {
            return (
                <PermissionsPrompt 
                    onDismiss={this.handleDismissPermissions}
                    onAllow={this.handleAllowPermissions}
                />
            );

        } else if (this.state.over24hrs) {
            return (
                <BookingLimitExceeded 
                    onDismiss={() => this.setState({over24hrs: false})}
                    buttonMessage="Return to Search"
                />
            );
        }

        //console.log(this.state.sortBy);
        return (
          <div className="search-container">
            {/*this.state.geoWaiting == 'search' && geoWaitingModal_Desktop_Search*/}
            {/*this.state.geoWaiting == 'prompt' && geoWaitingModal_Desktop_Prompt*/}

            {WPMode === 'bays' ?
              <div className="lwp-header-mobile"><MainNavWP user={this.props.user} hasNotifications={this.props.CMS.hasNotification}/></div>:
              <div className="oscar-header-mobile"><MainNav user={this.props.user} hasNotifications={this.props.CMS.hasNotification}/></div>
            }

            <ParkingMap 
              WPDomain={WPDomain}
              WPMode={WPMode}
              browserCSSMode={this.state.browserCSSMode}
              searchCounter={this.state.searchCounter}
              forceSearchCounter={this.state.forceSearchCounter}
              address={this.state.address} 
              center={{lat: this.state.lat, lng: this.state.lng}}
              
              zoom={DEFAULT_ZOOM}
              //setReady={() => this.setState({loading: false})}
              user={this.props.user}
              authChange={this.props.authChange}
              history={this.props.history}
              desktopDisplay={browserCSSMode === 'desktop' ? true:false} // DAMIAN
              spaceData={this.state.spaceData}
              //saveSpaceData={this.props.saveSpaceData}
              initialUuid={this.props.match.params.uuid}

              search_type={this.state.search_type}
              searchMode={this.state.searchMode}
              msm_startdate={this.state.msm_startdate}
              //msm_enddate={this.state.msm_enddate}
              booking_msm_startdate={this.state.booking_msm_startdate}
              booking_msm_enddate={this.state.booking_msm_enddate}
              setMSMStartDate={this.setMSMStartDate}
              setMSMEndDate={this.setMSMEndDate}

              booking_monthly_startdate={this.state.booking_monthly_startdate}
              booking_monthly_enddate={this.state.booking_monthly_enddate}
              start={this.state.startTime}
              end={this.state.endTime}

              availability_mode={this.state.availability_mode}
              lat={this.state.lat}
              lng={this.state.lng} 
              startTime={this.state.startTime}  // Oscar?
              endTime={this.state.endTime}      // Oscar?
              sortBy={this.state.sortBy || 0}  //$$DAMIAN XX
              includeAll={this.state.includeAll || 0}
              onLocationSelected = {this.handleLocationSelected}
              onDateSelected={this.handleDateSelected}
              onTimeRangeSelected = {this.handleTimeRangeSelected}
              onSearch={this.handleSearchClick}
              onOver24HrPicked={() => this.setState({over24hrs: true})}
              onAvailibilitySelected={this.handleAvailabilitySelected}
              onSortByChange={this.handleSortByChange}
              onSearchModeChange={this.handleSearchModeChange}
            />
          </div>
        );
    }
}