import React, {Component} from 'react';

import { 
  Button,
  Grid,
  LinearProgress,
  Typography,
  Hidden, 
  IconButton,
} from '@material-ui/core';
//import LoadingModal from '../components/LoadingModal.js';
import { CircularProgress, Paper } from "@material-ui/core";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    padding: "0",
    top: "0",
    left: "0",
    right:"0",
    bottom:"0",
    //height: "100vh",
    //width: "100vw",
    backgroundColor: "rgba(256, 256, 256, 0.5)",
    zIndex: "1300",
  }
};


export default class StyleGuide extends Component {
  state = {
    loading: false,
    debugOutline: false
}


  
  render() {
    const {loading, debugOutline} = this.state;
    return(
      <>
      <div className={"styleguide-container" + (this.state.debugOutline ? " debug-outline":"")}>

        <div className="styleguide-wpadmin">
          <span>ColorScheme</span>
          <div>
            <div className="sg-color" style={{backgroundColor:'var(--heroColor)'}}><span>--heroColor</span></div>
            <div className="sg-color" style={{backgroundColor:'var(--heroColor2)'}}><span>--heroColor2</span></div>
            <div className="sg-color" style={{backgroundColor:'var(--wpbg-button)'}}><span>--wpbg-button</span></div>
            <div className="sg-color" style={{backgroundColor:'var(--wpbg-button_hover)'}}><span>--wpbg-button_hover</span></div>            
            <div className="sg-color" style={{backgroundColor:'var(--heroColorHover)'}}><span>--heroColorHover</span></div>            
            <div className="sg-color" style={{backgroundColor:'var(--heroColorSelected)'}}><span>--heroColorSelectd</span></div>            
            <div className="sg-loader" onClick={()=>this.setState({loading:true})}>Show Loading Screen</div>
            <div className="sg-loader" onClick={()=>this.setState({debugOutline:!debugOutline})}>Toggle Debug Outline</div>
            {loading && this.render_loader()}
          </div>
        </div>

        <div className="styleguide-wpadmin">
          <span>--font-default</span>
          <div className="wpadmin-content top-padded-mobile">
            <h1 style={{fontFamily:"var(--font-default)"}}>h1 tag</h1>
            <h2 style={{fontFamily:"var(--font-default)"}}>h2 tag</h2>
            <h3 style={{fontFamily:"var(--font-default)"}}>h3 tag</h3>
            <h4 style={{fontFamily:"var(--font-default)"}}>h4 tag</h4>
            <h5 style={{fontFamily:"var(--font-default)"}}>h5 tag</h5>
            <h6 style={{fontFamily:"var(--font-default)"}}>h6 tag</h6>
            <p style={{fontFamily:"var(--font-default)"}}>p tag - "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
            <p style={{fontFamily:"var(--font-default)"}}>This is how <a href="#">link url text</a> looks.</p>

          </div>
        </div>

        <div className="styleguide-wpadmin">
          <span>--font-default-bold</span>
          <div className="wpadmin-content top-padded-mobile">
            <h1 style={{fontFamily:"var(--font-default-bold)"}}>h1 tag</h1>
            <h2 style={{fontFamily:"var(--font-default-bold)"}}>h2 tag</h2>
            <h3 style={{fontFamily:"var(--font-default-bold)"}}>h3 tag</h3>
            <h4 style={{fontFamily:"var(--font-default-bold)"}}>h4 tag</h4>
            <h5 style={{fontFamily:"var(--font-default-bold)"}}>h5 tag</h5>
            <h6 style={{fontFamily:"var(--font-default-bold)"}}>h6 tag</h6>
            <p style={{fontFamily:"var(--font-default-bold)"}}>p tag - "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."</p>
          </div>
        </div>


        <div className="styleguide-wpadmin">
          <span>wpadmin-content top-padded-mobile</span>
          <div className="wpadmin-content top-padded-mobile">
            <h1>H1 <span>span</span> - My Spots</h1>
            <h2>H2 <span>span</span> - In progress</h2>
            <h3>H3 Title - Based on the above, you could be earning $X/month from your car park space</h3>
            <p>Standard p text</p>

            


            <Button className="wp-button">wp-button</Button>    
            <p></p>
            <Button className="wp-button" fullWidth>wp-button fullWidth</Button>    
            <p></p>
            <Button className="wp-textbutton">wp-textbutton</Button>    
          </div>
        </div>

        <div className="styleguide-wpadmin">
          <span>mainConent top-colored</span>
          <div className="mainContent top-colored">
              <h1>H1 - Edit Profile / Payment Details</h1>
          </div>
        </div>


        <div className="styleguide-wpadmin">
          <span>sd-container sd-details</span>
          <div className="sd-container">
            <div className="sd-details">
              <h1>H1 <span>span</span> - Building on <span>Street name</span></h1>
              <h2>H2 <span>span</span> - Description / Instruction</h2>
              <p>p - Description content etc. Description content etc. Description content etc. Description content etc. Description content etc. Description content etc. Description content etc.</p>
              
              

              <Button className="wp-button">wp-button</Button>    
              <p></p>
              <Button className="wp-button" fullWidth>wp-button fullWidth</Button>    
              <p></p>
              <Button className="wp-textbutton">wp-textbutton</Button>    

            </div>
          </div>
        </div>
        <div className="styleguide-wpadmin">
          <span>sd-actions</span>
          <div className="sd-actions">
            <Button className="wp-button">wp-button</Button>    
          </div>
          <div className="sd-actions">
            <Button className="wp-button">wp-button</Button>    
            <Button className="wp-button">wp-button</Button>    
          </div>
          <div className="sd-actions">
            <Button className="wp-button">wp-button</Button>    
            <Button className="wp-button">wp-button</Button>    
            <Button className="wp-button">wp-button</Button>    
          </div>

        </div>
      </div>
      </>
    );


  }

  render_loader(){
    return (
      <Paper style={styles.container} onClick={()=>this.setState({loading:false})} >
        <CircularProgress style={{color:'#ba925a'}}
         //style={this.props.color ? { color: this.props.color } : {}}
        />
        {this.props.status ? <div>{this.props.status.map(text => { return <p>{text}</p>})}</div> : null}
      </Paper>
    );
  }

}