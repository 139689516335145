import React, {Component} from 'react';

import DateTimeRangePicker from '../components/DateTimeRangePicker.js';

import {
    Button,
    Grid,
    Paper,
} from '@material-ui/core/';
import GooglePlacesSearch from './GooglePlacesSearch.js';


const styles = {
    dateDropper: {
        float: 'right',
        marginRight: '20px',
    },

    searchControl: {
        //margin: '8px',
        //padding: '10px',
    },

    addressSearch: {
        borderBottom: '1px solid #e5e5e5'
    },

    searchButton: {
        height: '56px',
        width: '116px',
        fontSize: '20px',
    }
}

export default class ParkingSearch extends Component {
    render() {
        if (this.props.desktopDisplay === true) {
            return(
                <Grid container spacing={8} alignItems='center' style={{position: 'relative'}}>
                    <Grid item style={{flexGrow: 1}}>
                        <div style={{backgroundColor: 'white', borderRadius: '5px'}}>
                            <GooglePlacesSearch 
                                name="address" 
                                onLocationSelected={this.props.onLocationSelected} 
                                address={this.props.address}
                                desktopDisplay={true}
                            />
                        </div>
                    </Grid>
                    <DateTimeRangePicker 
                        withDate={true}
                        start={this.props.startTime}
                        end={this.props.endTime}
                        startText="Arriving"
                        endText="Leaving"
                        onTimeRangeSelected={this.props.onTimeRangeSelected}
                        enforceFuture={true}
                        singleDayRange={true}
                        desktopDisplay={this.props.desktopDisplay}
                        onOver24HrPicked={this.props.onOver24HrPicked}
                    />
                    <Grid item>
                        <Button style={styles.searchButton} variant='contained' color='primary'
                        onClick={this.props.onSearch}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
            )
        }
        return(
            <Paper elevation={10} style={styles.searchControl}>
                <Grid container spacing={0}>
                    <Grid item xs={12} style={styles.addressSearch}>
                        <GooglePlacesSearch 
                            name="address" 
                            onLocationSelected={this.props.onLocationSelected} 
                            address={this.props.address}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateTimeRangePicker 
                            withDate={true}
                            start={this.props.startTime}
                            end={this.props.endTime}
                            startText="Arriving"
                            endText="Leaving"
                            onTimeRangeSelected={this.props.onTimeRangeSelected}
                            enforceFuture={true}
                            singleDayRange={true}
                            onOver24HrPicked={this.props.onOver24HrPicked}
                        />
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}