/* Edit Item - allows custom processing of form/data on load and submit etc */
import React, { Component } from 'react';

import moment from 'moment';
import '../styles/EditItem.css';

import CircularProgress from '@material-ui/core/CircularProgress';
import EditItem_Form from './EditItem_Form.js';


export default class EditItem extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
    
        //console.log("[DataGrid:constructor]",this.props);

        let itemType = this.props.itemType;
        let itemId = this.props.match.params.reference;
        let parentId = this.props.match.params.parentId;

        // New items need a zero UUID to pass url validation on server
        if (itemId === 'new'){
            itemId = '00000000-0000-0000-0000-000000000000';
        }
        
        
        this.state = {
            itemType,
            itemId,
            parentId,
            initialLoad:false,
            data:null,
            searchText:'',
            searchTextFinal:''
        }

    }


    componentWillUnmount(){
        this._isMounted = false;
    }    

    componentDidMount(){
        this._isMounted = true;
        
        //this.loadData();
        /*let that = this;
        setTimeout(() => {
          //that.loadData();
          console.log("bbbbb");
          that.setState({initialLoad:true});
        }, 1000);*/

        
    }    

    render() {
        //console.log("Edit:Render:",this.props);
        //console.log(this.state);
        const {itemType, itemId, parentId, initialLoad, isUpdating, errorMessage} = this.state;
        const data = this.state.data || {};
        const meta = this.state.meta;
        const CMS = this.props.CMS;
  
        if (!itemType){
            return (<div>Missing Type</div>);
        }
        if (false && !initialLoad){
            return (
                <div className="datagrid">
                    <h1>Loading {itemType}...</h1>
                    <div style={{width:'100%',paddingTop:'30vh',textAlign:'center'}}>
                      <CircularProgress  size={80} thickness={4.5} style={{color:"#1AA5A2"}} />
                    </div>
                    
                </div>
            );
        }
/* 
            <h1>EDIT - {this.props.itemType}</h1>

            <div>CUSTOM CONTENT HERE</div>
            Container for item {this.props.itemType} id {itemId}

*/
        return (<div className="edititem">
            <EditItem_Form
                history={this.props.history}
                formId={`edit_${this.props.itemType}`}
                itemType={this.props.itemType}
                itemId={itemId}
                parentId={parentId}
               
        
             />
            </div>
        );
    }

}