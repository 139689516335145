import React, { Component } from "react";

import { CircularProgress, Paper } from "@material-ui/core";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    padding: "0",
    top: "0",
    left: "0",
    right:"0",
    bottom:"0",
    //height: "100vh",
    //width: "100vw",
    backgroundColor: "rgba(256, 256, 256, 0.5)",
    //backgroundColor: "rgba(0, 0, 0, 0.5)",
    boxShadow:'none',
    zIndex: "1300"
  }
};

export default class LoadingModalWP extends Component {
  
  render() {
    //let loadColor;
    //#ba925a
    return (
      <div>
        {this.props.open ? (
          <Paper style={styles.container}>
            <CircularProgress
              style={{color: "var(--heroColor)" }}
            />
            {this.props.status ? <div>{this.props.status.map(text => { return <p>{text}</p>})}</div> : null}
          </Paper>
        ) : null}
      </div>
    );
  }
}
