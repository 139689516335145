import React, { Component } from "react";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  withStyles,
  Dialog,
  Icon,
} from "@material-ui/core";
import { whiteInputStyles } from "../styles/OscarTheme.js";
import IconBack from "@material-ui/icons/KeyboardArrowLeft";
import IconForward from "@material-ui/icons/KeyboardArrowRight";
import IconEdit from "@material-ui/icons/Edit";


import {
  createUserPayoutMethod,
  getUserPayoutMethod,
  savePaymentCard,
  updateUserPayoutMethod,
  getUserPaymentPreferences,
  updateUserPaymentPreferences
} from "../api/Oscar-api";

import LoadingModal from "../components/LoadingModal";
import FullPageModal from "../utils/FullPageModal";
import CRUDForm from "../components/CRUDForm";
import HiddenField from "../components/forms/HiddenField.js";
import SelectField from "../components/forms/SelectField.js";
import SliderSwitch from "../components/forms/SliderSwitch.js";
import { updateUserProfileAjax } from "../utils/Authentication.js";
import CardWithBrand from "../components/CardWithBrand";
import AddCreditCard from "../components/AddCreditCard";
import AddCreditCard_Braintree from "../components/AddCreditCard_Braintree";
import AddDirectDebit from "../components/AddDirectDebit";

import PayoutDetailsV3 from "./PayoutDetailsV3.js";


let theme = {
  saveButton: {
    marginTop: "16px",
    marginBottom: "16px",
    color: "#1AA5A2",
    backgroundColor: "white",
    "&:hover, &:focus": {
      backgroundColor: "white",
    },
  },
};

//...whiteInputStyles,
theme = {  ...theme };

const styles = {
  rightCornerButton: {
    position: "absolute",
    top: "8px",
    right: "10px",
  },
  nextButton: {
    position: "absolute",
    bottom: 16,
    right: 16,
  },

  /*topColored: {
    paddingTop: "80px",
    backgroundColor: "#1AA5A2",
    paddingBottom: "8px",
  },*/
  paymentSetting: {
    paddingTop: "8px",
    paddingBottom: "8px",
    borderBottom: "1px solid #efefef",
  },
  switchField: {
    //borderTop: '1px solid #acacac',
    //borderBottom: '1px solid #acacac',
    paddingTop: "4px",
    paddingBottom: "4px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  transactionsContainer: {
    marginTop: "16px",
  },
  transactionContainer: {
    borderTop: "1px solid #efefef",
    paddingTop: "8px",
    paddingBottom: "6px",
  },
  switchBorder: {
    marginTop: "24px",
    borderTop: "1px solid #efefef",
  },
};

/*function displayCardWithBrand(profile) {
  const brandIcon =
    profile.card_brand === "Visa"
      ? "https://www.sharewithoscar.com.au/assets/graphics/cardbrand_visa.svg"
      : profile.card_brand === "American Express"
      ? "https://www.sharewithoscar.com.au/assets/graphics/cardbrand_amex.svg"
      : "https://www.sharewithoscar.com.au/assets/graphics/cardbrand_mastercard.svg";

  return (
    <Typography variant="body1">
      <img src={brandIcon} style={{ width: "32px", verticalAlign: "middle" }} alt="card brand" />
      <span style={{ verticalAlign: "sub", marginLeft: "16px", marginRight: "8px" }}>**** **** ****</span>
      {profile.card_last4}
    </Typography>
  );
}*/

class AutomaticTopupsForm extends Component {
  state = {
    autoTopUp: false,
    topupAmount: "$50",
    loading: true,
  };

  componentWillMount() {
    getUserPaymentPreferences()
      .then((response) => {
        //console.log(response)

        this.setState({
          loading: false,
          autoTopUp: response.data.auto_recharge,
          topupAmount: response.data.auto_recharge_amount === 100 ? "$100" : "$50",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleSavePreferences = () => {
    this.setState({ loading: true });
    updateUserPaymentPreferences({
      auto_recharge: this.state.autoTopUp,
      auto_recharge_amount: this.state.topupAmount === "$100" ? 100 : 50,
    })
      .then((response) => {
        this.setState({ loading: false });
        this.props.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <LoadingModal open={this.state.loading} />
        <div className="up-container">
          <div className="up-header">
            <IconButton className="leftCornerButton" onClick={this.props.onClose}>
              <IconBack style={{ color: "white" }} />
            </IconButton>

            <h1>Automatic Top Up</h1>
          </div>
          <div style={{ backgroundColor: "white", paddingTop: "24px", flexGrow: 1 }} className="mainContent">
            <Typography variant="body1">
              We charge 50c for transactions below $25. You can save by opting to top up your credit when your balance
              drops below $10
            </Typography>

            <Grid style={styles.switchBorder} container justify="space-between" alignItems="center">
              <Grid>
                <Typography variant="body1">Automatic Top Up</Typography>
              </Grid>
              <Grid>
                <SliderSwitch
                  checked={this.state.autoTopUp}
                  onChange={() => this.setState({ autoTopUp: !this.state.autoTopUp })}
                />
              </Grid>
            </Grid>

            {this.state.autoTopUp && (
              <FormControl fullWidth>
                <SelectField
                  label="Top up amount"
                  name="type"
                  placeHolder="Select your top up amount"
                  options={["$50", "$100"]}
                  value={this.state.topupAmount}
                  onChange={(e) => this.setState({ topupAmount: e.target.value })}
                />
              </FormControl>
            )}
          </div>
          <Grid container justify="flex-end">
            <Button color="primary" variant="contained" style={{ margin: "16px" }} onClick={this.handleSavePreferences}>
              Save
            </Button>
          </Grid>
        </div>
      </>
    );
  }
}



class PayoutForm extends Component {
  state = {
    monthlyPayout: window.WPDomain? false:true,
    loading: true,
    isBusiness:false
  };

  componentWillMount() {
    getUserPayoutMethod()
      .then((response) => {
        //console.log(response.data)
        this.setState({
          loading: false,
          endPoint: updateUserPayoutMethod,
          formValues: response.data,
          monthlyPayout: response.data.monthly_payout,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          endPoint: createUserPayoutMethod,
        });
      });
  }

  render() {
    const { classes } = this.props;
    const isBusiness = this.state.isBusiness;
// style={{ height: "100%", overflowY: "auto" }}
    return (
      <>
        <LoadingModal open={this.state.loading} />

        <div className="up-container">
        <div className="up-header">
          <IconButton className="leftCornerButton" onClick={this.props.onClose}>
            <IconBack style={{ color: "white" }} />
          </IconButton>

          <h1>Payout Details</h1>

          </div>
        <div className="up-content" style={{paddingTop:'20px'}}>             
          {this.state.loading ? null : (
            <div>
              <Typography variant="body1">
                We transfer your earnings to your nominated account on the 15th of every month.
              </Typography>

              <CRUDForm
                endpoint={this.state.endPoint}
                onSuccess={this.props.onClose}
                hydrateFromObject={{
                  values: { ...this.state.formValues },
                  errors: {},
                }}
              >
                <Grid container spacing={8} justify="flex-end" style={{marginBottom:'50px'}}>
                  
                  <Grid item xs={12}>
                    <TextField
                      label="Account Name"
                      name="account_name"
                      type="text" 
                      FormHelperTextProps={{ classes: { root: classes.helperText, error: classes.helperTextError,}}}
                      InputProps={{ classes: { root: classes.input, error: classes.inputError}}}
                      InputLabelProps={{classes: { root: classes.label }, FormLabelClasses: {root: classes.formLabelRoot, error: classes.formLabelError, focused: classes.formLabelFocused}, shrink: true }}
                      placeholder="Your account name"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="BSB Number"
                      name="bsb"
                      type="text"
                      FormHelperTextProps={{ classes: { root: classes.helperText, error: classes.helperTextError}}}
                      InputProps={{classes: {root: classes.input, error: classes.inputError}, inputProps: { maxLength: 6, pattern: `${"\\"}d*`, }}}
                      InputLabelProps={{ classes: { root: classes.label }, FormLabelClasses: { root: classes.formLabelRoot, error: classes.formLabelError,focused: classes.formLabelFocused,}, shrink: true }}
                      placeholder="123456"
                      //fullWidth
                      margin="normal"
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField
                      label="Account Number"
                      name="account_number"
                      type="text"
                      FormHelperTextProps={{ classes: { root: classes.helperText, error: classes.helperTextError }}}
                      InputProps={{ classes: { root: classes.input, error: classes.inputError}, inputProps: { maxLength: 9, pattern: `${"\\"}d*`}}}
                      InputLabelProps={{ classes: { root: classes.label }, FormLabelClasses: { root: classes.formLabelRoot, error: classes.formLabelError, focused: classes.formLabelFocused }, shrink: true }}
                      placeholder="012345678"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  
                  
                  { window.WPMode === 'bays' ?
                      <HiddenField
                      value={this.state.monthlyPayout}
                      name="monthly_payout"
                      customValue={this.state.monthlyPayout}
                      //ref={(c) => (this._toggleSwitch = c)}
                    />:                
                    <Grid item xs={12}>
                      <Grid style={styles.switchField} container justify="space-between" alignItems="center">
                        <Grid item>
                          <Typography variant="body1">
                            Keep my earnings as Oscar coins
                          </Typography>
                        </Grid>
                        <Grid item>
                          <SliderSwitch
                            checked={this.state.monthlyPayout}
                            onChange={() => {
                              this._toggleSwitch.reportChange(!this.state.monthlyPayout);
                              this.setState({ monthlyPayout: !this.state.monthlyPayout });
                            }}
                          />
                          <HiddenField
                            value={this.state.monthlyPayout}
                            name="monthly_payout"
                            customValue={this.state.monthlyPayout}
                            ref={(c) => (this._toggleSwitch = c)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    }



                    <Grid item xs={12}>
                      <h3>For us to transfer your earnings, please provide your details below:</h3>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid style={styles.switchField} container justify="space-between" alignItems="center">
                        <Grid item>
                          <Typography variant="body1">
                            I am operating as a Business with ABN
                          </Typography>
                        </Grid>
                        <Grid item>
                          <SliderSwitch
                            checked={this.state.isBusiness}
                            onChange={() =>  this.setState({isBusiness: !this.state.isBusiness})}
                          />
                        </Grid>
                      </Grid>
                    </Grid>




                  <Grid item xs={12}>
                    <TextField
                      label={isBusiness ? "Business Name":"Full Name"}
                      name="full_name"
                      type="text" 
                      FormHelperTextProps={{ classes: { root: classes.helperText, error: classes.helperTextError,}}}
                      InputProps={{ classes: { root: classes.input, error: classes.inputError}}}
                      InputLabelProps={{classes: { root: classes.label }, FormLabelClasses: {root: classes.formLabelRoot, error: classes.formLabelError, focused: classes.formLabelFocused}, shrink: true }}
                      placeholder={isBusiness ? "Your Business name":"Your full name"}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={isBusiness ? "ABN":"Date of Birth"}
                      name="abn_dob"
                      type="text"
                      FormHelperTextProps={{ classes: { root: classes.helperText, error: classes.helperTextError}}}
                      InputProps={{classes: {root: classes.input, error: classes.inputError}, inputProps: { maxLength: 12, pattern: `${"\\"}d*`, }}}
                      InputLabelProps={{ classes: { root: classes.label }, FormLabelClasses: { root: classes.formLabelRoot, error: classes.formLabelError,focused: classes.formLabelFocused,}, shrink: true }}
                      placeholder={isBusiness ? "00 000 000 000":"dd/mm/yyyy"}
                      //fullWidth
                      margin="normal"
                    />
                  </Grid>




                  <Grid item xs={12}>
                    <TextField
                      label="Primary Address"
                      name="address"
                      type="text" 
                      FormHelperTextProps={{ classes: { root: classes.helperText, error: classes.helperTextError,}}}
                      InputProps={{ classes: { root: classes.input, error: classes.inputError}}}
                      InputLabelProps={{classes: { root: classes.label }, FormLabelClasses: {root: classes.formLabelRoot, error: classes.formLabelError, focused: classes.formLabelFocused}, shrink: true }}
                      placeholder="Your primary residential address"
                      fullWidth
                      margin="normal"
                    />
                  </Grid>







                </Grid>

                {/*<Button fullWidth className="wp-button" type="button">Next</Button>*/}

                {<Button fullWidth className="wp-button" type="submit">Save</Button>}
                {/*<Button fullWidth className="wp-button" onClick={this.handleCreatePartialAvailability} type="submit">Save</Button>*/}


              </CRUDForm>
            </div>
          )}
        </div>
        </div>
      </>
    );
  }
}
function PayoutFormStyled(props) {
  const StyledForm = withStyles(theme)(PayoutForm);
  return <StyledForm {...props} />;
}

export default class PaymentMenu extends Component {
  state = {
    loading: false,
    topupsOpen: false,
    payoutFormOpen: false,
    addCardOpen: false,
    addDDOpen: false,
    browserCSSMode: window.innerWidth >= 960 ? "desktop":"mobile",
    
  };

  handleTopUps = () => {
    this.setState({ topupsOpen: true });
  };

  handleStripeToken = (response) => {
    //console.log(response)
    this.setState({ loading: true });
    let token = response.id;

    savePaymentCard({ stripe_token: token }).then((resp) => {
      //console.log(resp)
      updateUserProfileAjax().then((response) => {
        //console.log(response)
        if (this.props.authChange) {this.props.authChange();} // DAMIAN - MISSING PROPS? this.props.authChange();
        this.setState({
          loading: false,
          topupsOpen: token !== "delete",
          addCardOpen: false,
        });
      });
    });
  };
  
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }


  // TEMP: needed because we need to pass it from app etc.
  updateDimensions = () => {
    const browserCSSMode = window.innerWidth >= 960 ? "desktop":"mobile";
    if (browserCSSMode !== this.state.browserCSSMode){
      this.setState({ browserCSSMode });    //width: window.innerWidth, height: window.innerHeight
    } 
  };

  
  render() {
    const {browserCSSMode, addCardOpen, addDDOpen} = this.state;
    //console.log({browserCSSMode});

    let strModalClass = "oscar modal-braintree";
    let strContainerClass = "oscar mbt-container";
    if (addCardOpen || addDDOpen){
      strContainerClass += " open";
      strModalClass+= " open";
    }

    // NOTE: we will be removing Stripe altogether

    //width="164px" height ="44px"

/* 
                <img src="https://www.sharewithoscar.com.au/assets/logo-mc.svg" height="20px" />
                <img src="https://www.sharewithoscar.com.au/assets/logo-visa.svg" height="10px" />
                <a href="https://www.braintreegateway.com/merchants/k9nt2j7vfbfmsq5b/verified" target="_blank">
                  <img src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png" height="30px" border="0"/>
                </a>

*/


          // Header (duplicate in PaymentDetails.js)
          let name = "Share with Oscar";
          let image = "https://www.sharewithoscar.com.au/assets/graphics/oscar_logo_white.svg";
          let strClass = "braintree";
          if (window.WPMode === 'bays'){
            if (window.WPDomain === 'community'){
              // same as oscar
              strClass+=" community";
              name = "Share with Oscar";
  
            } else if (window.WPDomain === 'demo') {
                  name = "Demo by Oscar";
                  image="https://demo.parkwithoscar.com.au/assets/graphics/logo-demo.png";
                  strClass+=" demo";
  
              } else {
                  name = "Meriton by Oscar";
                  image="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg";
                  strClass+=" meriton";
                  //<img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-white3.svg" alt="Meriton powered by Oscar"></img>
              }
          } else {
            strClass+=" oscar";
          }


    return (
      <>
        {browserCSSMode === 'mobile' && window.PaymentMode === "BRAINTREE" &&
          <>
            <div className={strContainerClass}>
            

              <div className="mbt-header">
                {addCardOpen && <div>Add Card Details</div>}
                {addDDOpen && <div>Add Direct Debit Details</div>}
              </div>
              <div className="mbt-body">
                {addCardOpen && <AddCreditCard_Braintree onClose={() => {this.setState({addCardOpen:false})}} WPDomain={window.WPDomain} WPMode={window.WPMode} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} />}
                {addDDOpen && <AddDirectDebit WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addDDOpen: false }); }}/>}
              </div>
            </div>
          <div className={strModalClass} onClick={() => {this.setState({addCardOpen:false,addDDOpen:false})}}></div>
          </>
        }
        <LoadingModal open={this.state.loading} />

        <FullPageModal open={this.state.payoutFormOpen}>
          {/*<PayoutFormStyled onClose={() => this.setState({ payoutFormOpen: false })} />*/}
          <PayoutDetailsV3 onClose={() => this.setState({ payoutFormOpen: false })}  />
        </FullPageModal>

        <FullPageModal open={this.state.topupsOpen}>
          <AutomaticTopupsForm onClose={() => this.setState({ topupsOpen: false })} />
        </FullPageModal>

        <div className="up-container">
        <div className="up-header">
          <IconButton className="leftCornerButton" onClick={this.props.onClose}>
            <IconBack style={{ color: "white" }} />
          </IconButton>

          <h1>Payment Details</h1>
        </div>
        <div className="up-content">

        {browserCSSMode === 'desktop' &&
            <Dialog aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
              open={this.state.addDDOpen}
              onClose={() => { this.setState({ addDDOpen: false }); }}>

              <div className={"v3_payment-methods " + (window.WPDomain || 'oscar')}>
                <div className="header"><img src={image} /></div>
                <AddDirectDebit WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addDDOpen: false }); }}/>
              </div>


            </Dialog>
        }

          {browserCSSMode === 'desktop' &&
            <Dialog aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description"
              open={this.state.addCardOpen}
              onClose={() => { this.setState({ addCardOpen: false }); }}>
              
              <div className={"v3_payment-methods " + (window.WPDomain || 'oscar')}>
                <div className="header"><img src={image} /></div>
                <AddCreditCard_Braintree WPDomain={window.WPDomain} WPMode={window.WPMode} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addCardOpen: false }); }}/>
              </div>
  {/* 
                  <div className={"v3_payment-methods " + (window.WPDomain || 'oscar')}>
                    <div className="header"><img src={image} /></div>
                      {allowDirectDebit &&
                        <div className="button-box">
                          <Button className={paymentMethod === "card" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"card"})}>Card</Button>
                          <Button className={paymentMethod === "dd" ? "pm-button is-selected":"pm-button"} type="button" disabled={this.state.loading} onClick={() => this.setState({paymentMethod:"dd"})}>Direct Debit</Button>
                        </div>
                      }
                      {paymentMethod === "card" && <AddCreditCard_Braintree WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addCardOpen: false }); }}/>}
                      {paymentMethod === "dd" && <AddDirectDebit WPDomain={window.WPDomain} browserCSSMode={browserCSSMode} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addCardOpen: false }); }}/>}
                  </div>


  */}


              {/*(window.PaymentMode == "BRAINTREE") ? 
                <AddCreditCard_Braintree browserCSSMode={browserCSSMode} WPDomain={window.WPDomain} loading={this.state.loading} authChange={this.props.authChange} onClose={() => {this.setState({ addCardOpen: false }); }}/>
              :
              <div style={{ width: 400, height: 400, maxWidth: "100%" }}>
                <AddCreditCard WPDomain={window.WPDomain} token={this.handleStripeToken} loading={this.state.loading} />
              </div>

  */}
            </Dialog>
          }


          {this.props.userProfile.card_last4 !== null ? 
            <Grid style={styles.paymentSetting}
            container
            justify="space-between"
            alignItems="center"
            className="wp-hovercursor"
            onClick={() => this.setState({ addCardOpen: true })}
            >
              {/*<Grid item>{displayCardWithBrand(this.props.userProfile)}</Grid>*/}
              <Grid item><CardWithBrand profile={this.props.userProfile} /></Grid>

              <Grid item>
                <IconButton>
                    <IconEdit
                    />
                </IconButton>
              </Grid>
          </Grid>:
          
            <Grid style={styles.paymentSetting}
            container
            justify="space-between"
            alignItems="center"
            className="wp-hovercursor"
            onClick={() => this.setState({ addCardOpen: true })}
            >
              <Grid item>
                <Typography
                  variant="body1"
                >
                  Add Card Payment Method
                </Typography>
              </Grid>
              <Grid item>
                <IconButton>
                    <IconForward />
                </IconButton>
              </Grid>
            </Grid>
          }




{this.props.userProfile.dd_account_label !== null ? 
            <Grid style={styles.paymentSetting}
            container
            justify="space-between"
            alignItems="center"
            className="wp-hovercursor"
            onClick={() => this.setState({ addDDOpen: true })}
            >
              {/*<Grid item>{displayCardWithBrand(this.props.userProfile)}</Grid>*/}
              {/*<Grid item><CardWithBrand profile={this.props.userProfile} /></Grid>*/}
              {<Grid item>Direct Debit Bank Account - {this.props.userProfile.dd_account_label}</Grid>}

              <Grid item>
                <IconButton>
                    <IconEdit
                    />
                </IconButton>
              </Grid>
          </Grid>:
          
            <Grid style={styles.paymentSetting}
            container
            justify="space-between"
            alignItems="center"
            className="wp-hovercursor"
            onClick={() => this.setState({ addDDOpen: true })}
            >
              <Grid item>
                <Typography
                  variant="body1"
                >
                  Add Direct Debit Payment Method
                </Typography>
              </Grid>
              <Grid item>
                <IconButton>
                    <IconForward />
                </IconButton>
              </Grid>
            </Grid>
          }





          <Grid
            style={styles.paymentSetting}
            container
            justify="space-between"
            alignItems="center"
            className="wp-hovercursor"
            onClick={() => this.setState({ payoutFormOpen: true })}
          >
            <Grid item>
              <Typography variant="body1">Add Payout Details</Typography>
            </Grid>
            <Grid item>
              <IconButton>
                <IconForward/>
              </IconButton>
            </Grid>
          </Grid>
          
          {!window.WPDomain &&
            <Grid
            style={styles.paymentSetting}
            container
            justify="space-between"
            alignItems="center"
            onClick={this.handleTopUps}
          >
            <Grid item>
              <Typography variant="body1">Automatic Top-ups</Typography>
            </Grid>
            <Grid item>
              <IconButton>
                <IconForward color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          }

        </div>
        </div>

      </>
    );
  }
}
