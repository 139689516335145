import React, {Component} from 'react';
import { Route } from 'react-router-dom';

import CRUDForm from '../components/CRUDForm';
import LoadingModalWP from '../components/LoadingModalWP.js';

import {
    checkMissingInfo,
    updateUserProfile,
} from '../utils/Authentication.js';
import {
    updateUserData,
    updatePhoneNumber,
    verifyPhoneNumber,
    updateTenantData,
    getBuildingsOptionsList,
    getRedirectToken
} from '../api/Oscar-api.js';

import ENUMS from '../api/ENUMS.json';
import { FieldType } from '../api/fieldTypes.js';

import {
    Button,
    Grid,
    IconButton,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import {whiteInputStyles} from '../styles/OscarTheme.js';


import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import HiddenField from '../components/forms/HiddenField';
import SelectFieldWP from '../components/forms/SelectFieldWP.js';
import AutoComplete from '../components/AutoComplete';


// Duplicate in AuthWP - to allow correct scrolling while remaining vertically centered
let logoMobile;
if (window.WPDomain === 'community'){
  logoMobile = (<div className="lwpauth-logo-mobile"><img src="https://community.sharewithoscar.com.au/assets/graphics/logo-oscar-community.svg" alt="Community Portal"/></div>);

} else if (window.WPDomain === 'demo'){
  logoMobile = (<div className="lwpauth-logo-mobile"><img src="https://meriton.sharewithoscar.com.au/assets/demo-logo2.svg" alt="Demo Portal"/></div>);
} else {
  logoMobile = (<div className="lwpauth-logo-mobile"><img src="https://meriton.sharewithoscar.com.au/assets/meriton-logo-blue3.svg" alt="Meriton powered by Oscar"/></div>);
}


const styles = {
    noAccount: {
        borderRadius: '32px',
        color: 'white',
        border: '2px solid white',
    },
    rightCornerButton: {
        position: 'absolute',
        top: 5,
        right: 0,
        color: 'white',
    },
    progressButton: {
        backgroundColor: 'white',
        boxShadow: 'none',
    },
    title: {
        marginTop: '80px'
    },
    codeInput: {
        fontSize: '40px',
        width: '150px',
        marginTop: '20px'
    }
}

class ProfileForm extends Component {

  constructor(props) {
    super(props)
   
    let forceLoading = false;
    if (window.WPDomain !== 'community' && this.props.formName === 'tenant'){
      forceLoading = true;
    }
    this.state = {
      forceKey:1,
      forceLoading,
      optionsList:[]
    }
    this._isMounted = false;

    this.doRedirect = this.doRedirect.bind(this);
    
  }

  componentDidMount(){
    this._isMounted = true;
    if (this.state.forceLoading){
      
      let that = this;
      let {forceKey} = this.state;
      getBuildingsOptionsList().then(response => {
        that.setState({
            forceLoading:false
        })
        //response.queryParams = this.state.values
        //this.props.onSuccess(response);
        let optionsList = [];

        if (response && response.data){
          const data = response.data;
          ///console.log("response:",data);
          for (let i=0;i<data.length;i++){
            optionsList.push({value: data[i].uuid, label: `${data[i].building_name} on ${data[i].street_name}, ${data[i].suburb}`});
          }
 
        }
        
        that.setState({
          forceKey:forceKey+1,
          forceLoading:false,
          optionsList,
          buildings:response.data   //store this to pass to user profile for initial search
        });


      }).catch(error => {
        console.error(error);
        that.setState({
          forceKey:forceKey+1,
          forceLoading:false
        });

      });
    }
    
  }

  onFormSuccess = ({queryParams}) => {

    //console.log(JSON.stringify(queryParams));

    // Add building details to user profile on first creation (will reload full details from server on next /users request)
    // ???????????????
    if (queryParams.default_building){
      if (window.WPDomain === 'community'){
        //queryParams.default_building = thisBuilding;
        //queryParams.default_building = thisBuilding;
    
      } else {
        let thisBuilding = this.state.buildings.filter(b => b.uuid == queryParams.default_building)[0];
        queryParams.default_building = thisBuilding;
  
      }
    }

    updateUserProfile(queryParams);
    this.props.updateUser();

    if (this.props.nextForm === `/verify/`) {
        this.props.history.push(`/finishprofile${this.props.nextForm}${queryParams.mobile_number}/`);
    } else if (this.props.nextForm === '/search'){
      // FORCE a full page reload on navigation to refresh user data
      const customRedirect = localStorage.getItem('FINISH_PROFILE_REDIRECT');
      if (customRedirect !== null) {
        localStorage.removeItem('FINISH_PROFILE_REDIRECT');
        window.location=customRedirect;
        //this.props.history.push(customRedirect)
      } else {
        window.location='/search';
        //this.props.history.push('/search');
      }

    } else {
      //console.log("nextForm:",this.props.nextForm)
      if (this.props.nextForm === 'tenant' && window.WPDomain === 'community'){
        let nextForm = "tenant-community";
        this.props.history.push(`/finishprofile/${nextForm}/`);
      } else {
        this.props.history.push(`/finishprofile/${this.props.nextForm}/`);
      } 
      
    }
  }

  doRedirect(){
    //console.log("Redirect to Oscar...");
    this.setState({isRedirecting:true});

    let that = this;
    getRedirectToken().then(response => {
      if (response && response.data){
        const data = response.data;
        //console.log("response:",data);
        //console.log("url:",data.url);
        window.location = data.url;

      } else {
        that.setState({
          isRedirecting:false
        });
  
      }

    }).catch(error => {
      console.error(error);
      that.setState({
        isRedirecting:false
      });

    });

  }

  render() {

    const {classes} = this.props;
    const {forceLoading, isRedirecting} = this.state;
    //const thisField = {"isRequired":true, "id":"default_building", "label":"Building Address","type":FieldType.AUTOCOMPLETE, remoteDomain:'building_address', placeholder:'Your address'};
    //let fieldErrors = {};
    // error={fieldErrors[thisField.id]} onUpdate={this.processChildUpdate} 
    return(
      <div className="lwpauth-form">
        {isRedirecting && <LoadingModalWP open={true}/>}
        {logoMobile}
        <h1>{this.props.title}</h1>

        <IconButton className="auth-btn-back" onClick={this.props.history.goBack}><IconBack /></IconButton>
        <CRUDForm
          submitOnEnter={true}
          key={this.state.forceKey} 
            endpoint={this.props.endpoint}
            onSuccess={this.onFormSuccess}
            loadColor="blue"
            WPDomain={window.WPDomain}
            forceLoading={forceLoading}
        >
          <Grid container spacing={24} justify="center">


              {this.props.fields.map((field, i)=> {
                  if (field.name === 'default_building_community' && window.WPDomain === 'community'){
                    return (<Grid key={i} item xs={12}><AutoComplete key={'autocomplete'} field={field} initialValueJSON={null} onRedirect={this.doRedirect}/></Grid>);

                  } else if (field.name === 'tenant_type' && window.WPDomain !== 'community'){
                    return(
                      <Grid key={i} item xs={12}>
                        
                          <SelectFieldWP
                              name={field.name}
                              label={field.label}
                              placeHolder={field.placeholder}
                              options={ENUMS.OPTIONS_LISTS.tenant_type}
                          />
                      </Grid>    
                      );

                  } else if (field.name === 'default_role' || field.name === 'default_role_community'){
                    return(
                      <Grid key={i} item xs={12} requires={field.requires}>
                        
                          <SelectFieldWP
                              name={field.name}
                              label={field.label}
                              placeHolder={field.placeholder}
                              options={ENUMS.OPTIONS_LISTS.default_role}
                              inputProps={{requires: field.requires}}
                          />
                      </Grid>    
                      );

                  } else if (field.type === 'select'){
                    return(
                      <Grid key={i} item xs={12} requires={field.requires}>
                        
                          <SelectFieldWP
                              name={field.name}
                              label={field.label}
                              placeHolder={field.placeholder}
                              options={this.state.optionsList}
                              inputProps={{requires: field.requires}}
                          />
                      </Grid>    
                      );

                  } else if (field.placeholder === 'dd/mm/yyyy'){

                    return(
                      <Grid key={i} item xs={12} requires={field.requires}>
                        <TextField label={field.label} name={field.name} type="text"
                          InputLabelProps={{ shrink: true }}
                          placeholder={field.placeholder}
                          fullWidth
                          margin="normal"
                          inputProps={{requires: field.requires}}
                        />
                      </Grid>
                      );


                  } else {

                    return(
                      <Grid key={i} item xs={12} requires={field.requires}>
                          <TextField
                              label={field.label}
                              name={field.name}
                              type="text"
                              FormHelperTextProps={{
                                  classes: {
                                      //'root': classes.helperText,
                                      //'error': classes.helperTextError,
                                  }
                              }}
                              inputProps={{requires: field.requires}}
                              /*InputProps={{
                                  classes: {
                                      //'root': classes.input,
                                      //'error': classes.inputError,
                                  }
                              }}*/
                              InputLabelProps={{
                                  //classes: {'root': classes.label },
                                  FormLabelClasses: {
                                      //root: classes.formLabelRoot,
                                      //error: classes.formLabelError,
                                      //focused: classes.formLabelFocused,
                                  },
                                  shrink: true,
                              }}
                              placeholder={field.placeholder}
                              fullWidth
                              margin="normal"
                          />
                      </Grid>    
                      );

                  }

              })}

            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <Grid container justify="flex-end" alignItems="center">
                <Grid item xs={12}>
                  <Button type="submit">Next</Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </CRUDForm>

      </div>            
    );
  }
}

class VerifyForm extends Component {
    handleResend = () => {
        updatePhoneNumber({mobile_number: this.props.match.params.phoneNumber})
    }


    onSuccess = () => {
        updateUserProfile({mobile_number: this.props.match.params.phoneNumber})
        this.props.updateUser()
        //console.log(this.props);
        this.props.history.push('/finishprofile/tenant/');
    }
    render() {
        return(
          <div className="lwpauth-form">
            <IconButton className="auth-btn-back" onClick={this.props.history.goBack}><IconBack /></IconButton>
            {logoMobile}            
                    <h1>Verify</h1>
                    <p>Enter the 4-digit code sent to you at {this.props.match.params.phoneNumber}</p>
                    
                    <CRUDForm 
                        submitOnEnter={true}
                        endpoint={verifyPhoneNumber}
                        onSuccess={this.onSuccess}
                        loadColor="blue"
                        WPDomain={window.WPDomain}
                    >
                        <Grid container spacing={24} justify="center">
                            <Grid item xs={12}>
                                <TextField 
                                    name="entered_pin"
                                    inputProps={{
                                        maxLength: 4,
                                        style:styles.codeInput
                                    }}
                                />
                                <HiddenField name="mobile_number" value={this.props.match.params.phoneNumber} />
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Button color="primary" onClick={this.handleResend}>
                                            Resend code
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                      <Button type="submit">Next</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CRUDForm>
                </div>

        )    
    }
}



function ProfileName(props) {
    
  const formProps = getPropsFor(props.formName); //getPropsFor('name')
    //const Form = ; // withStyles(whiteInputStyles)(ProfileForm)
    return(
        <ProfileForm {...props} {...formProps} />
    )
}
function ProfileEmail(props) {
    const formProps = getPropsFor('email')
    const Form = withStyles(whiteInputStyles)(ProfileForm)
    return(
        <Form {...props} {...formProps} />
    )
}
function ProfilePhone(props) {
    const formProps = getPropsFor('telephone_number')
    //const Form = withStyles(whiteInputStyles)(ProfileForm)
    return(
      <ProfileForm {...props} {...formProps} />
    )
}
function ProfileTenant(props) {
  let formProps;
  //console.log("ProfileTenant");

  if (window.WPDomain === 'community'){
    formProps = getPropsFor('tenant-community')        
    //console.log(formProps);
  } else {
    formProps = getPropsFor('tenant')
  }

  
  //const Form = ; // withStyles(whiteInputStyles)(ProfileForm)
  return(
      <ProfileForm {...props} {...formProps} />
  )
}


function getPropsFor(missingInfo) {
    const missing = checkMissingInfo()
    const misIndex = missing.indexOf(missingInfo)
    const next = misIndex + 1 < missing.length ? missing[misIndex + 1] : '/'

    //console.log({missing});
    //console.log({misIndex});
    //console.log({next});

    switch (missingInfo) {
        case 'name':
            return {
                endpoint: updateUserData,
                fields:[
                    {'label': 'First name', 'name': 'first_name', 'placeholder': 'Your first name'},                        
                    {'label': 'Last name', 'name': 'last_name', 'placeholder': 'Your last name'},
                    {'label': 'Postcode', 'name': 'post_code', 'placeholder': '0000'}
                ],
                formName:'name',
                nextForm: next,  
                title:"What's your name?", 
            }
        case 'email':
            return {
                endpoint: updateUserData,
                fields:[
                    {'label': 'Email address', 'name': 'email', 'placeholder': 'Your email address'},                        
                ],
                formName:'email',
                nextForm: next, 
                title: "What's your email?",
            }
        case 'telephone_number':
            return {
                endpoint: updatePhoneNumber,
                fields:[
                    {'format':'tel', 'label': 'Mobile number', 'name': 'mobile_number', 'placeholder': 'eg 0400 000 000'},                        
                ],
                formName:'telephone_number',
                nextForm: '/verify/',   
                title: "What's your mobile number?",
            }
        case 'tenant':
          return {
              endpoint: updateTenantData,
              fields:[
                  {'type':'select', 'label': 'Are you an existing tenant?', 'name': 'tenant_type', 'placeholder': 'Please select'},                        
                  {'requires':'tenant_type:1', 'type':'select', 'label': 'What are you looking for?', 'name': 'default_role', 'placeholder': 'Please select'},                        
                  {'requires':'tenant_type:1', 'type':'select', 'label': 'Building', 'name': 'default_building', 'placeholder': 'Select your apartment building'},                        
                  {'requires':'tenant_type:1', 'label': 'Unit#', 'name': 'tenant_id', 'placeholder': 'Your Unit or Apartment Number'},                        
                  {'requires':'tenant_type:1', 'label': 'End of lease date', 'name': 'lease_end_date', 'placeholder': 'dd/mm/yyyy'},
              ],
              formName:'tenant',
              nextForm: '/search',   
              title:"Almost there...", 
          }
            case 'name-community':
              return {
                  endpoint: updateUserData,
                  fields:[
                      {'label': 'First name', 'name': 'first_name', 'placeholder': 'Your first name'},                        
                      {'label': 'Last name', 'name': 'last_name', 'placeholder': 'Your last name'},
                  ],
                  formName:'name-community',
                  nextForm: next,  
                  title:"What's your name?", 
              }
          case 'email-community':
              return {
                  endpoint: updateUserData,
                  fields:[
                      {'label': 'Email address', 'name': 'email', 'placeholder': 'Your email address'},                        
                  ],
                  formName:'email-community',
                  nextForm: next, 
                  title: "What's your email?",
              }
          case 'telephone_number-community':
              return {
                  endpoint: updatePhoneNumber,
                  fields:[
                      {'format':'tel', 'label': 'Mobile number', 'name': 'mobile_number', 'placeholder': 'eg 0400 000 000'},                        
                  ],
                  formName:'telephone_number-community',
                  nextForm: '/verify/',   
                  title: "What's your mobile number?",
              }
            case 'tenant-community':
              return {
                  endpoint: updateTenantData,
                  fields:[
                    {"id":"default_building_community", "name":"default_building_community", "label":"Building Address","type":FieldType.AUTOCOMPLETE, remoteDomain:'building_address', placeholder:'Your address'},
                    {'xxrequires':'tenant_type:1', 'type':'select', 'label': 'What are you looking for?', 'name': 'default_role_community', 'placeholder': 'Please select'},                        
                  ],
                  formName:'tenant-community',
                  nextForm: '/search',   
                  title:"Almost there...", 
              }
    
              

          default:
            return null;
    }
}

export default class FinishProfileWP extends Component {
    componentDidMount() {
        // Check which user profile properties are missing
        const missing = checkMissingInfo();
        //console.log("missing!");
        //console.log({missing});

        if (missing && missing.length > 0){

          this.props.history.replace(`/finishprofile/${missing[0]}/`);
        } else {
          //Force a full page reload to clean up whatever caused "undefined" or invalid url
          window.location = "/login";
        }
        
    }

    render() {
        return(
            <>
                <Route exact path={'/finishprofile/name'} render={(props) => <ProfileName formName={'name'} updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={'/finishprofile/email'} render={(props) => <ProfileEmail updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={'/finishprofile/telephone_number'} render={(props) => <ProfilePhone updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={`/finishprofile/verify/:phoneNumber`} render={(props) => <VerifyForm updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={'/finishprofile/tenant'} render={(props) => <ProfileTenant updateUser={this.props.updateUser} {...props}/>} />

                <Route exact path={'/finishprofile/name-community'} render={(props) => <ProfileName formName={'name-community'} updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={'/finishprofile/email-community'} render={(props) => <ProfileEmail updateUser={this.props.updateUser} {...props}/>} />
                <Route exact path={'/finishprofile/telephone_number-community'} render={(props) => <ProfilePhone updateUser={this.props.updateUser} {...props}/>} />
                {/*<Route exact path={`/finishprofile/verify/:phoneNumber`} render={(props) => <VerifyForm updateUser={this.props.updateUser} {...props}/>} />*/}
                <Route exact path={'/finishprofile/tenant-community'} render={(props) => <ProfileTenant updateUser={this.props.updateUser} {...props}/>} />
            </>
        )
    }
}



/*
const BUILDINGS_OPTION_LIST = [
  {value:"f39aaf86-3738-49b5-93ff-61bfb7973c53",label:"The Peninsula"}, 
  {value:"f9ab71ba-7565-4a19-8bc2-29b6ca92f878",label:"World Square"}, 
  {value:"70bd55b1-0b34-4f94-b4d6-b3662cf42bc9",label:"Retreat Celeste"}, 
  {value:"4",label:"Invalid building"},                                                 
];

*/