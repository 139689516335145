import React, { Component } from 'react';
//import {Select,Progress,Card,Divider,Transition, Dimmer, Loader, Grid,Form, Segment, Menu, Container, Step, Label, Button, Input, Message, Icon, List, Dropdown, Modal, Header } from 'semantic-ui-react';
//import {Form, Input, Label} from 'semantic-ui-react';

import {
    Button,
    FormControl,
    Grid,
    IconButton,
    LinearProgress,
    TextField,
    InputAdornment,
    Typography
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';


import { FieldType } from '../api/fieldTypes.js';


class Money extends Component {
  constructor(props) {
    super(props);

    //console.log("[MONEY:constructor]");
    //console.log("Money: ",this.props);

    let inValue = this.props.initialValue || '';

    var placeholder;
    var labelLeft;
    var labelRight;
    var labelPosition;
    var maxLength;
    const myType = this.props.field.type;

    var className;

    if (myType === FieldType.MONEY_ONLY_POSITIVE_DOLLARS) {
      //console.log("FORMAT: MONEY ONLY POSITIVE DOLLARS");
      labelPosition = 'right';
      placeholder = this.props.placeholder || '0';  // set to building default?
      maxLength = 10;
      labelLeft = "$"; // <Label basic>$</Label>;
      labelRight = ".00"; // <Label basic>.00</Label>;
      className = 'field-money';

    } else if (myType === FieldType.MONEY_ONLY_POSITIVE_DOLLARS_AND_CENTS) {
      labelPosition = 'right';
      placeholder = '0.00'; // this.props.placeholder || '0.00';  // set to building default?
      maxLength = 10;
      labelLeft = "$"; // <Label basic>$</Label>;
      //labelRight = ".00"; // <Label basic>.00</Label>;
      className = 'field-money';
      if (inValue){
        //console.log({inValue});
        inValue = parseFloat(inValue).toFixed(2);
      }

    } else if (myType === FieldType.PERCENTAGE) {
      //console.log("FORMAT: PERCENTAGE");
      labelPosition = 'right';
      placeholder = '0';
      labelRight = "%"; // <Label basic>%</Label>;
      maxLength = 3;
      className = 'field-percentage';

    } else if (myType === FieldType.INTEGER_ONLY_POSITIVE) {
      //console.log("FORMAT: INTEGER ONLY POSITIVE");
      placeholder = '0';
      maxLength = 10;
      className = 'field-integer';

    } else if (myType === FieldType.INTEGER_YEAR) {
      //console.log("FORMAT: YEAR");
      placeholder = '####';
      maxLength = 4;
      className = 'field-year';


    } else {
      console.log("UNKNOWN FORMAT");
      placeholder = '0';
      maxLength = 0;
      className = 'field-money';

    }

    var errorMessage = this.props.error; //start null

    this.state = {
      isVisible: true,
      value: inValue,
      labelPosition,
      placeholder,
      maxLength,
      labelLeft,
      labelRight,
      className:className + ' ',
      errorMessage

  };


    this.onFocus = this.onFocus.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }


  onFocus(e) {
    e.target.select();
  }

  onKeyPress(e) {
    /*    if (this.state.isLocked){
          return;
        }
    */
    if (e.key === 'Enter') {
      e.target.blur();
    }

  }

  //========================
  //Live Validation
  //========================
  //Accept only valid characters while typing
  onChange(e) {
    /*    if (this.state.isLocked){
          return;
        }
        if (!this.state.enabled){
          // console.log('disabled');
          return;
        }
    */
    var inText = e.target.value;

    let regex;

    if (this.props.field.type === FieldType.MONEY_ONLY_POSITIVE_DOLLARS_AND_CENTS){
      regex = new RegExp("^[0-9]*(\.[0-9]{0,2})?$", "i");

    } else {
      regex = new RegExp("^[0-9]{0,10}$", "i");
    }
    if (regex.test(inText)) {
      this.setState({value: inText}); //, validationState: null, validationMessage: "" });
    }

  }


  //========================
  //Final Validation
  //========================
  //Trim leading 0's
  onBlur(e) {
    var inText = e.target.value;
    var finalValue = inText;



    var strErrorMessage = null;

    //Send the valid value to the parent form
    this.props.onUpdate({id:this.props.field.id,finalValue});
    this.setState({errorMessage:strErrorMessage});

  }


  render() {

    const {errorMessage, labelLeft,labelRight, labelPosition} = this.state;

    return (
        <TextField
            onChange={this.onChange}  
            onBlur={this.onBlur}
            onFocus={this.onFocus}        
            style={{display:'block'}}
            label={this.props.field.label}
            name={this.props.field.id}
            type="text"
            InputProps={{
                startAdornment: <InputAdornment position="start"><AttachMoneyIcon style={{color:'var(--heroColor2)'}} /></InputAdornment>,
            }}
            InputLabelProps={{
                shrink: true,
            }}
            placeholder={this.state.placeholder}
            value={this.state.value}
            margin="normal"
            error={this.props.error ? true:false}
            helperText={this.props.error}
        />    
    );

  }

}

export default Money;
