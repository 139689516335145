import React, {useState, useEffect, useLayoutEffect} from "react";

import { savePaymentDD} from "../api/Oscar-api";
import { updateUserProfileAjax } from "../utils/Authentication.js";

import GooglePlacesSearchWP from './GooglePlacesSearchWP.js';
import { Button } from "@material-ui/core";

import CircularProgress from '@material-ui/core/CircularProgress';

export default function AddDirectDebit( { onClose, authChange } ) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(null);
  const [hasChangesCount, setHasChangesCount] = useState(0);
  const [fieldValues, setFieldValues] = useState({});
  const [summaryData, setSummaryData] = useState(null);

  useEffect( () => {
    let ignore = false;

    return () => {
      //console.log("useEffect cleanup, setting ignore=true");
      ignore = true;
    };

  },[]);


  const onLocationSelected_FullDetails = (newValue,latLng, street_name, suburb, state_name, postcode) => {
    //console.log("onLocationSelected_FullDetails:", newValue);
    //fieldValues[inJSON.id] = newValue;
    let fieldValues2 = fieldValues;
    fieldValues2['address'] = newValue.split(',')[0];
    fieldValues2['latitude'] = latLng.lat;
    fieldValues2['longitude'] = latLng.lng;
    fieldValues2['street_name'] = street_name;
    fieldValues2['suburb'] = suburb;
    fieldValues2['state'] = state_name;
    fieldValues2['postcode'] = postcode;

    setFieldValues(fieldValues2);
  }




  /*const onSuccess = () => {
    //console.log("onSuccess");
    onClose();
  }  

  const onCancel = () => {
    //console.log("onCancel");
    onClose();
  } */ 


  const onBackClick = () => {
    //console.log("onBackClick!");
    //setSummaryData(null);
    onClose();
  }  


  const onConfirmClick = () => {
    //console.log("onConfirmClick!");
    
    setIsLoading(true);
    setHasError(null);
    //console.log("confirming DD info...");

    savePaymentDD({confirm_account:true}).then((resp) => {
      //console.log("savePaymentDDConfirm response:", resp.data);

      updateUserProfileAjax().then((response) => {

        //console.log({response});
        setIsLoading(false);
        //return;
        if (authChange) {
          //console.log("authchange!");
          authChange();
          
        } else {
          //console.log("no authchange!");
        }
         
        onClose('dd');
      });
  

    }).catch(function(err) {
      //console.log("ERRORC1...");
      if (err.response && err.response.data && typeof err.response.data === 'string' && err.response.data.length < 150){
        console.error(err.response.data);
        setHasError(err.response.data);

      } else {
        //console.log("error:");
        console.error(err);
        setHasError("Please check your bank details");
      }
      setIsLoading(false);

    });


  }  



  const handleSubmit = (event) => {
    //console.log("handleSubmit");
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let account_name = formData.get("account_name");
    let account_bsb = formData.get("account_bsb");
    let account_number = formData.get("account_number");

    let address = fieldValues['address'];
    let street_name = fieldValues['street_name'];
    let suburb = fieldValues['suburb'];
    let state_name = fieldValues['state'];
    let postcode = fieldValues['postcode'];


    //console.log({account_name});
    //console.log({account_bsb});
    //console.log({account_number});

    //console.log({fieldValues});
    

    if (isLoading){
      //console.log("isLoading, ignore");
      return;
    }


    setIsLoading(true);
    setHasError(null);
    //console.log("saving DD info...");

    savePaymentDD({ account_name, account_bsb, account_number, address, street_name, suburb, state_name, postcode }).then((resp) => {
      //console.log("savePaymentDD response:", resp.data);
      const data = resp.data || {};
      
      if (data.show_summary){
        //console.log("show_summary!");
        //console.log("bank_name=" + data.bank_name);
        setSummaryData(data);
        setIsLoading(false);        
        return;
      }
      

      /*if (ignore){
        console.log("IGNORE ALL RESPONSES");
        return;
      }*/
      //setIsLoading(false);
      //setHasError((resp && resp.data) || "UNKNOWN ERROR?");
      //return;

      updateUserProfileAjax().then((response) => {

        //console.log({response});
        setIsLoading(false);
        //return;
        if (authChange) {
          //console.log("authchange!");
          authChange();
          
        } else {
          //console.log("no authchange!");
        }
         
        onClose();
      });
  

    }).catch(function(err) {
      //console.log("ERROR1...");
      if (err.response && err.response.data && typeof err.response.data === 'string' && err.response.data.length < 150){
        //console.error(err.response.data);
        setHasError(err.response.data);

      } else {
        //console.log("error:");
        console.error(err);
        setHasError("Please check your bank details");
      }
      setIsLoading(false);

    });




    //onSuccess();
  }  

  const browserCSSMode = window.innerWidth >= 960 ? "desktop":"mobile";

  //console.log({fieldValues});
/* 
{
  "show_summary": true,
  "creditor_name": "Share with Oscar",
  "creditor_id": "488299",
  "date": "07/10/2022",
  "bank_name": "Bank of Queensland Limited",
  "account_name": "aaaaaaaaaa",
  "account_bsb": "124899",
  "account_number": "3333333333",
  "pdf_url": "https://mandate-previews-sandbox.gocardless.com/?token=eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIn0..2AnB4pvVXAZsMUVI.09hjd9L8BCJfqzqWCojSiWAgr4EPxcADnZRlkN90lPHH5fha4y7Nj83FMHJaRTn1DJF7tyMfldi-MM_warr-TnX8pR3-8F37wLz9bjfqp5V1CRDIxXKlsvnyP8KYBeXXJgoWmEohcfHQEaWbMDlc-OEdpkboZJ6sn1vyBlbyvl3SSlaRJnhHZgDf17PbNsTYeChxIXTJIcmHTviekrM--Ldz1coHrBL5_peWQv6Y3ma_kvUcVeg8kMpLset3f3V1emFsV79blgfpSavgFm4bY4xNcxQCaIG72HNU6GXTRE6xhmqcwBhF1M4dUUHUNrlP5HnWjld0008tTgF03PBjju4Aw2YJlXZeIDjZ9k2mz-ZvT_n9o-sx8xTdJvhwPC7tt5FhF6zI1qBzgztvOgKxuIfJ2-NKwUW5vYIUBwd06csOkjU4mxIlvg6BqjT8KoS0pf7AVS-2N_K9ezAMBbll8Yfh03_CUWmWrp2ubY0SXcOXV8WZEPCs_8mIdhhMHz1nuba-wk05xuQjJRpw0p52hDgxi5s9NHnqzBwN6zy_CnkcMMzjo-pmK61PaiJ7wpWqOgmlr4e2u1B-9Vj3g2qGV00eWj-OxOa7SP6Wuhy6UbiGgs5DJY4Aey9fo4jzvVaNM6rxTqqwh9Grti9jxuui8NOFBKkXDZ7qUwOqeWqSz2MvtI8R_p4FfF-A6IVbWB8tbWDluH02830Br0peO7iVwxbv_30NUhk7_jW20KSoL9JZTyiYhnz92r6y9sX2_m94ep7ASls6zOWp6gw_3YrOaU4Wy0h-uaDyPMowoI5doRsQa-updcXrA7xDhSWImQcrw8cF7A0ekcTUmkprUjiNweQx_JWQNwCd_OUd6iotA59CtoSubM7dy7CCAZaPf2Ebxd85LOYo5PZzgTT45n7noVQFp4dHjS8jnxW5Gu1fGR5liF1i6S96hWeK0EXVnlraeT772jPuOkxmibxKLNeOMRIrWdm3vh4WTs1B09m3IgZnZbUwoAL9ALAP6vPU1nbieKyHq0qXhDRWxu8UNWmPdg9fIeRF061TR7Awg2lanz57J7t8wOiqWUHBjXhssoSsvoQSjnhuae8wNZYewXR4iEe0fQADqnmr4RQhUI1EWQXXPjiHdFHpgdYxUeUfIH0lxR0uAOy-ARfVDTbfawoZwEFxPwo6tl4lHK2-k7K74-sYLF-YuNIZS0hcYXna3Iq1bDngnQoi0wbIVkNA1OAr3L855oej5VYOhkY40TxxDoAHlJXEptqrrpgctehHAU2p94lrMbmWK98zXpotmR7d5nS_e3sgRq7IyUtJ40cO6059nMArTHzzuha-MD604X8B-7NIZ5TtAKpqX5V8V-c4YytE1fFrBtpM8SChZxV3NtFMgNBh8ZhQXc5Sx-7l9R37MDpApA1AL1e-jkb16scJnjvwjkmu2KjeDE3NPRwak6FyapEuC_ZBsw.Wel18ytJczCq7Shz9q3C2Q"
}

*/


  return (
    <div className="v3_dd-container">
      {isLoading && <div className="pm-is-loading"><CircularProgress  size={70} thickness={4.5} style={{color:"var(--heroColor)",position:'absolute',left:'calc(50% - 35px)',top:'calc(50%)'}} /></div>}
      
      {summaryData &&
      <div className="v3_dd-summary">
        <h1>Direct Debit Request</h1>
        
        <div className="v3_dd-summary_row">
          <div><h6>Creditor Name</h6><p>{summaryData.creditor_name}</p></div>
          <div><h6>Creditor Id</h6><p>{summaryData.creditor_id}</p></div>
        </div>
        
        <h6>Date</h6><p>{summaryData.date}</p>
        <h6>Account Name</h6><p>{summaryData.account_name}</p>
        <h6>Bank Name</h6><p>{summaryData.bank_name}</p>
        
        <div className="v3_dd-summary_row">
          <div><h6>BSB</h6><p>{summaryData.account_bsb}</p></div>
          <div><h6>Account Number</h6><p>{summaryData.account_number}</p></div>
        </div>


        {/*<pre>{JSON.stringify(summaryData,null,2)}</pre>*/}

        {/*<a className="pm-ddpdf" href={summaryData.pdf_url} target='_blank'>Direct Debit Request and Direct Debit Service Agreement</a>*/}
        <div className="pm-ddstatement"><span>Share with Oscar</span> will appear on your bank account statement.</div>

        <div className="pm-ddconditions">By signing and/or providing us with a valid instruction in respect to your Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements between you and Merchant Name as set out in this Request and in your <a className="pm-ddpdf" href={summaryData.pdf_url} target='_blank'>Direct Debit Service Agreement</a>. You agree to execute this document by electronic signature and you are aware that by electronically signing this document you are executing a legally binding document.</div>
        
        <div className="pm-dderrormsg">{hasError}</div>


        <div className='v3_dd-summary-footer'>
          <Button tabIndex={4} className="obt-back" type="button" disabled={isLoading} onClick={() => onBackClick()}>Cancel</Button>                
          <Button tabIndex={5} className="obt-next" type="button" disabled={isLoading} onClick={() => onConfirmClick()}>{isLoading ? "Please wait...":"Confirm"}</Button>        
        </div>
      </div>
      }




    {!summaryData &&

      <form onSubmit={handleSubmit}>
  
        <label>Your Address</label>
        <GooglePlacesSearchWP
                  name="address" 
                  placeholder="Enter your address"
                  onLocationSelected_FullDetails={onLocationSelected_FullDetails} 
                  address={fieldValues['address'] || ''}
                  desktopDisplay={browserCSSMode === 'desktop' ? true:false} // this is not used anymore???
                  browserCSSMode={browserCSSMode}  // destkop/mobile
              />



          <label htmlFor="account_name">Account holder name</label>
          <input defaultValue={''} tabIndex={1} autoComplete="off" disabled={isLoading} required={false} name="account_name" type="text" aria-invalid={undefined} placeholder="" />


          <label htmlFor="account_bsb">BSB number</label>
          <input defaultValue={''} tabIndex={2} autoComplete="off" disabled={isLoading} required={false} name="account_bsb" type="phone" aria-invalid={undefined} placeholder="" />

          <label htmlFor="account_number">Account number</label>
          <input  defaultValue={''} tabIndex={3} autoComplete="off" maxLength={10} disabled={isLoading} required={false} name="account_number" type="phone" aria-invalid={undefined} placeholder="" />

          <div className="pm-dderrormsg">{hasError}</div>

          <div className="pm-ddprivacy">Payments by GoCardless.<br/>Read the <a target="_blank" href="https://gocardless.com/privacy/">GoCardless privacy notice.</a></div>          



          <Button tabIndex={5} className="wp-button" type="submit" disabled={isLoading}>{isLoading ? "Please wait...":"Add Bank Details"}</Button>

      </form> 
      }

      {/*<button onClick={() => onCancel()}>Cancel</button>*/}

    </div>
  );

 
}
//<button tabIndex={5} type="submit" disabled={isLoading} aria-busy={isLoading}>{isLoading ? "Please wait...":"Add Bank Details"}</button>