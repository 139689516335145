import React, { Component } from "react";

import {
    createLocalAccount,
    requestLocalToken,
    processTokenResponse,
    logOut
} from '../utils/Authentication';
import CRUDForm from "../components/CRUDForm";

import {
    Button,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    withStyles,
} from '@material-ui/core';
import { whiteInputStyles } from '../styles/OscarTheme.js';

import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconClear from '@material-ui/icons/Cancel';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import HiddenField from "../components/forms/HiddenField";

//import FacebookOAuthButton from '../components/OauthFacebook.js';
//import GoogleOAuthButton from '../components/OauthGoogle.js';

const styles = {
    noAccount: {
        borderRadius: '32px',
        color: 'white',
        border: '2px solid white',
    },
    rightCornerButton: {
        position: 'absolute',
        top: 10,
        right: 5,
        color: 'white',
    },
    progressButton: {
        backgroundColor: 'white',
        boxShadow: 'none',
    },
    oauthButton: {
        backgroundColor: 'white',
        paddingTop: '14px',
        paddingBottom: '14px',
        borderRadius: '32px',
        color: '#1AA5A2',
    },
    signupButton: {
        marginTop: '16px',
        marginLeft: '16px',
        marginRight: '16px',
        fontWeight: 'bold',
    }
}

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            referralCode: props.match.params.referralCode !== undefined ? props.match.params.referralCode : undefined,
            signupCode: props.match.params.promoCode !== undefined ? props.match.params.promoCode : undefined
        };
    }

    onSuccess = (response) => {
        requestLocalToken({
            username:response.queryParams.email,
            password:response.queryParams.password
        })
        .then(response => {
            processTokenResponse(response).then(() => {
                //console.log("Yay!")
                this.props.authChange()
                this.props.history.push('/finishprofile')
            })
        })
    }

    authSuccess = () => {
        // We successfully authenticated, so return to the landing page.
        this.props.authChange();
        this.setState({loading: false});
        this.props.history.push('/search');
    }
    authError = (error) => {
        // TODO: validation messages for errors
        console.log(error)
        this.setState({loading: false});
    }

    updateFields = () => {
        const vals = this._mainForm.getState().values

        const form1 = vals['email'] ? vals['email'].length > 0 : false
        const form2 = vals['password'] ? vals['password'].length > 0 : false
        this.setState({form1: form1, form2: form2})
    }

    render() {
        const {classes} = this.props

        return (
            <div className="gradientPage" style={{height: '100%', overflowY: 'auto'}}>
                <div className="mainContent topPadded">
                    <IconButton className="leftCornerButton"
                    onClick={() => { logOut(); this.props.authChange(); this.props.history.goBack() }} style={{color: 'white'}}>
                        <IconBack />
                    </IconButton>
                    <Typography style={{color: 'white'}} color="primary" variant="display1">
                        Sign up
                    </Typography>
                    <CRUDForm
                        submitOnEnter={true}
                        endpoint={createLocalAccount}
                        onSuccess={this.onSuccess}
                        ref={(component) => {this._mainForm = component}}
                        onChange={this.updateFields}
                    >
                        <Grid container spacing={24} justify="center">
                            <Grid item xs={12}>
                                <TextField
                                    label="Email Address"
                                    name="email"
                                    type="email"
                                    FormHelperTextProps={{
                                        classes: {
                                            'root': classes.helperText,
                                            'error': classes.helperTextError,
                                        }
                                    }}
                                    ref={(component) => {this.form1Field = component}}
                                    InputProps={{
                                        classes: {
                                            'root': classes.input,
                                            'error': classes.inputError,
                                        },//&& this._mainForm.getState().values['email'].length > 0
                                        endAdornment: this.state.form1 && <InputAdornment position="end">
                                                <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'email', value: ''}})} />
                                            </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        classes: {'root': classes.label },
                                        FormLabelClasses: {
                                            root: classes.formLabelRoot,
                                            error: classes.formLabelError,
                                            focused: classes.formLabelFocused,
                                        },
                                        shrink: true,
                                    }}
                                    placeholder="Your email address"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.username}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Re-type Email Address"
                                    name="email2"
                                    type="email"
                                    FormHelperTextProps={{
                                        classes: {
                                            'root': classes.helperText,
                                            'error': classes.helperTextError,
                                        }
                                    }}
                                    ref={(component) => {this.form1Field = component}}
                                    InputProps={{
                                        classes: {
                                            'root': classes.input,
                                            'error': classes.inputError,
                                        },//&& this._mainForm.getState().values['email'].length > 0
                                        endAdornment: this.state.form1 && <InputAdornment position="end">
                                                <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'email2', value: ''}})} />
                                            </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        classes: {'root': classes.label },
                                        FormLabelClasses: {
                                            root: classes.formLabelRoot,
                                            error: classes.formLabelError,
                                            focused: classes.formLabelFocused,
                                        },
                                        shrink: true,
                                    }}
                                    placeholder="Confirm your email address"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.username}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    label="Create Password"
                                    name="password"
                                    type="password"
                                    FormHelperTextProps={{
                                        classes: {
                                            'root': classes.helperText,
                                            'error': classes.helperTextError,
                                        }
                                    }}
                                    InputProps={{
                                        classes: {
                                            'root': classes.input,
                                            'error': classes.inputError,
                                        },
                                        endAdornment: this.state.form2 && <InputAdornment position="end">
                                                <IconClear onClick={() => this._mainForm.handleInputChange({target: {name: 'password', value: ''}})} />
                                        </InputAdornment>
                                    }}
                                    InputLabelProps={{
                                        classes: {'root': classes.label },
                                        FormLabelClasses: {
                                            root: classes.formLabelRoot,
                                            error: classes.formLabelError,
                                            focused: classes.formLabelFocused,
                                        },
                                        shrink: true,
                                    }}
                                    placeholder="Your password here"
                                    fullWidth
                                    margin="normal"
                                    value={this.state.password}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <HiddenField value={this.state.referralCode} name="referral"/>
                            <HiddenField value={this.state.signupCode} name="signupCode"/>
                            <Grid style={{textAlign:'left',width:'100%',paddingLeft:'15px'}}>
                              <p style={{color:'#ffffff'}}>By creating an account, you agree to our <a href="https://www.sharewithoscar.com.au/terms-and-conditions/" target="_blank" style={{fontWeight:'700',color:'#ffff',textDecoration:'underline'}}>terms and conditions</a></p>
                            </Grid>
                            
                            {/*
                            <Grid xs={12} style={{margin: '12px'}}>
                                <div style={{display: 'flex'}}>
                                    <div style={{flexGrow: 1}}>
                                        <hr style={{border: '0.25px solid rgba(255, 255, 255, 0.15)'}} />
                                    </div>
                                    <div>
                                    <Typography variant="body2" style={{ margin: '-3px 10px', color: 'white'}}>
                                        or
                                    </Typography>
                                    </div>
                                    <div style={{flexGrow: 1}}>
                                        <hr style={{border: '0.25px solid rgba(255, 255, 255, 0.15)'}} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid xs={12}>
                            <div id="oauth_facebook" style={styles.signupButton} onClick={() => this.setState({loading: true})}>
                                <FacebookOAuthButton 
                                    authSuccess={this.authSuccess}
                                    authError={this.authError}
                                    prompt="Sign up with Facebook"
                                />
                            </div>
                            </Grid>
                            <Grid xs={12}>
                            <div id="oauth_google" style={styles.signupButton} onClick={() => this.setState({loading: true})}>
                                <GoogleOAuthButton 
                                    authSuccess={this.authSuccess}
                                    authError={this.authError}
                                    prompt="Sign up with Google"
                                />
                            </div>
                            </Grid>
                            */}

                            <Grid item xs={12}  style={{marginTop: '32px'}}>
                                <Grid container justify="flex-end" alignItems="center">
                                    <Grid item>
                                        <Button style={styles.progressButton} variant="fab" type="submit">
                                            <IconForward color="primary"/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CRUDForm>
                </div>
            </div>
        )
    }
}

export default withStyles(whiteInputStyles)(SignUp)