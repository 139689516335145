import React, {Component} from 'react';
import moment from 'moment'

import {
    CalendarMonth,
    ParseDateTimeParameters,
} from '../utils/TimeFormats.js';

import { 
    Typography 
} from '@material-ui/core';

const styles={
    microCalendar: {
        transform: 'scale(0.5)'
    },
    monthContainer: {
        paddingTop: '20px',
        width: '394px',
        maxWidth: '90%',
        marginLeft: '16px',
        display: 'inline-block',
    },
    microMonthContainer : {
        paddingTop: '20px',
        transform: 'scale(0.65)',
        transformOrigin: 'top left',
    },
    monthTitle: {
        paddingLeft: '16px',
        paddingBottom: '8px',
    },
    calendarMonth: {
        width: '100%',
        //borderCollapse: 'collapse',
    },
    calendarDay: {
        height: '24px',
    },
    calendarCell: {
        height: '46px',
        width: '40px',
        border: '1px solid #dfdfdf',
        align: 'center',
        valign: 'center',
    },
    emptyCell: {
        color: '#dfdfdf',
    },
    bookedCell: {
        color: 'white',
        backgroundColor: '#1AA5A2'
    },
    availableCell: {
        color: '#2c2c2c',
    },
    currentDayCell: {
        border: '1px solid #2c2c2c',
        borderWidth: '1.5px',
    },
}

const weekdayKeys = ['day_sunday', 'day_monday', 'day_tuesday', 'day_wednesday', 'day_thursday', 'day_friday', 'day_saturday']

function CalendarCell(props) {
    return(
        <td onClick={() => {
            props.onClick ? props.onClick(props.momentDate) : void(0)
        }} style={{...styles.calendarCell, ...props.cellStyle}}>
            <Typography color="inherit" variant={props.calendarTextVariant} align="center">
                {props.date}
            </Typography>
        </td>
    )
}

export default class Calendar extends Component {
    render() {
        const calendarDays = ["Su", "M", "Tu", "W", "Th", "F", "Sa"]
        const calendarTextVariant = this.props.fullSize ? "caption" : "body1"

        // Always start drawing from the current month
        let start = moment()
        const now = moment()

        // Process all of the availability endpoints into moment objects
        this.props.availabilities.forEach(element => {
            element.start = moment(`${element.start_date} ${element.start_time}`, ParseDateTimeParameters)
            element.end = moment(`${element.cease_date} ${element.cease_time}`, ParseDateTimeParameters)
        })

        // Find the availability with the latest cease_date to use as the stopping point
        let end = this.props.availabilities.reduce((lastCeaseDate, current) => {
            return current.end.isAfter(lastCeaseDate) ? moment(current.end) : lastCeaseDate 
        }, moment(start))

        // Set the date of start to be the first day of the month we're drawing
        start.date(1)
        // Set end to the last day of its month
        end.date(end.daysInMonth())

        // Draw all months between start and end inclusive or until we reach maxMonthsToDraw
        let months = []
        while(months.length < this.props.maxMonthsToDraw) {
            months.push(moment(start))
            start.add(1, 'month')
        }
//start.isBefore(end) && 
        //console.log(months)

        return(
            <div >
                {months.map((month, monthIndex) => {
                    let calendarCells = []
                    let firstDay = moment(month)
                    
                    // Fill in blank cells for each month
                    for (let i = 0; i !== firstDay.day(); i++) {
                        calendarCells.push(<CalendarCell key={calendarCells.length} cellStyle={styles.emptyCell} />)
                    }

                    // For each day in the month, place the appropriate cell based on bookings and availabilities
                    for(; firstDay.month() === month.month(); firstDay.add(1, 'day')) {
                        let cellStyle = styles.emptyCell
                        let cellClick = undefined

                        // Check if there is an availability for this day
                        cellStyle = this.props.availabilities.find(avail => {
                            if (firstDay.isBetween(avail.start, avail.end, 'days', "[]") && avail[weekdayKeys[firstDay.day()]] ) {
                                return true
                            }
                            return false
                        }) ? styles.availableCell : cellStyle
                        
                        // Check if there are any bookings on this date
                        if (this.props.bookings !== undefined) {
                            if (this.props.bookings.find(booking => {
                                if (firstDay.isSame(booking.start, 'days')) {
                                    return true
                                }
                                return false
                            })) {
                                cellStyle = styles.bookedCell
                                cellClick = this.props.onBookingDayClick
                            }
                        }

                        // Check if this cell is the current day
                        if (firstDay.isSame(now, 'days')) {
                            //console.log("today is", firstDay.format())
                            cellStyle = {...cellStyle, ...styles.currentDayCell}
                        }

                        calendarCells.push(<CalendarCell 
                            key={calendarCells.length} 
                            cellStyle={cellStyle} 
                            date={firstDay.date()} 
                            calendarTextVariant={calendarTextVariant}
                            onClick={cellClick}
                            momentDate={moment(firstDay)}
                        />)
                    }

                    // Make sure the total number of cells is always a multiple of 7 so all rows are complete rows
                    for(; calendarCells.length % 7 !== 0; ) {
                        calendarCells.push(<CalendarCell key={calendarCells.length} cellStyle={styles.emptyCell} />)
                    }

                    // Turn the calendar cells into a 2d array
                    let calendarTable = []
                    for (let i = 0; i < calendarCells.length; i++) {
                        if (i % 7 === 0) {
                            calendarTable.push([])
                        }
                        calendarTable[calendarTable.length - 1].push(calendarCells[i])
                    }

                    // Draw the calendar table
                    return(
                        <div key={monthIndex} style={this.props.fullSize ? styles.monthContainer : styles.microMonthContainer}>
                            {this.props.fullSize && <Typography style={styles.monthTitle}>
                                {month.format(CalendarMonth)}
                            </Typography>}

                            <table style={styles.calendarMonth} cellSpacing={0}>
                                <thead>
                                    <tr>
                                        {calendarDays.map((day, i) => {
                                        return(
                                            <th key={i} style={styles.calendarDay}>
                                                <Typography variant={calendarTextVariant}>
                                                    {day}
                                                </Typography>
                                            </th>
                                        )
                                    })}
                                    </tr>
                                </thead>
                                <tbody>
                                {calendarTable.map((row, i) => {
                                    return(
                                        <tr key={i}>
                                            {row.map(cell =>{
                                                return cell
                                            })}
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    )
                })}
            </div>
        )
    }
}