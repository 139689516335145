import React, { Component } from 'react';
//import {Select,Progress,Card,Divider,Transition, Dimmer, Loader, Grid,Form, Segment, Menu, Container, Step, Label, Button, Input, Message, Icon, List, Dropdown, Modal, Header } from 'semantic-ui-react';
//import {Form, Input, Label, Select} from 'semantic-ui-react';

import {
    InputLabel,
    Select,
    Input,
    MenuItem,
    FormHelperText
} from "@material-ui/core"

import { FieldType } from '../api/fieldTypes.js';
import ENUMS from '../api/ENUMS.json';


class SelectDropDown extends Component {
  constructor(props) {
    super(props);

    //console.log("[SELECTDROPDOWN:constructor]");
    //console.log("select: ",this.props);

    let inValue = this.props.initialValue || '';

    var placeholder;
    var labelLeft;
    var labelRight;
    var labelPosition;
    var maxLength;
    const myType = this.props.field.type;

    const domains = ENUMS.OPTIONS_LISTS;
    //console.log("domains:",domains);

    var arrOptions;
    if (domains){
        //let thisDomain = domains.filter(u => u.id === this.props.field.domain)[0];
        //user = users.filter(u => u.id === building.company)[0];
        let thisDomain = domains[this.props.field.domain];
        //console.log(thisDomain);
        if (thisDomain){
            arrOptions = thisDomain;
        }
      

    }

    if (!arrOptions){
      console.log(`ERROR: No domain info available for this field [${this.props.field.domain}]`);
      arrOptions = [];
    }


    if (myType === FieldType.SELECT_DROPDOWN) {
      //console.log("FORMAT: SELECT DROPDOWN");
      placeholder = 'Select DropDown';
      maxLength = 100;

    } else {
      console.log("UNKNOWN FORMAT");
      placeholder = '0';
      maxLength = 0;

    }


/*    const arrOptions = [
      { key: 'm', text: 'Mr', value: 'mr' },
      { key: 'f', text: 'Mrs', value: 'mrs' },
      { key: 'o', text: 'Ms', value: 'ms' }
    ];

*/

    var errorMessage = this.props.error; //start null

    this.state = {
      isVisible: true,
      value: inValue,
      placeholder,
      maxLength,
      arrOptions,

      errorMessage
    };


    this.onChange = this.onChange.bind(this);
  }


  //========================
  //Live Validation
  //========================
  onChange(e, inData) {
    /*    if (this.state.isLocked){
          return;
        }
        if (!this.state.enabled){
          // console.log('disabled');
          return;
        }
    */

    //console.log(inData.props);

    var inValue = inData.props.value;

    this.setState({value: inValue}); //, validationState: null, validationMessage: "" });
    this.props.onUpdate({id: this.props.field.id, finalValue: inValue});

    /*    var regex = new RegExp("^[0-9]{0,10}$", "i");
        if (regex.test(inText)) {
        }
    */
  }


  render() {

    //console.log("MONEY:RENDER");
//jsonErrors[thisId]
    //      error=null
    const {errorMessage, arrOptions} = this.state;

    //const {labelLeft,labelRight, labelPosition} = this.state;


/*    <Form.Field
      control={Select}
      options={genderOptions}
      label={{ children: 'Gender', htmlFor: 'form-select-control-gender' }}
      placeholder='Gender'
      search
      searchInput={{ id: 'form-select-control-gender' }}
    />
*/

/*
        <Input type='text' value={this.state.value} placeholder={this.state.placeholder} className={"field-text"}
               maxLength={this.state.maxLength} onChange={this.onChange} onBlur={this.onBlur} onFocus={this.onFocus}
               onKeyPress={this.onKeyPress} autoComplete="new-password" autoCorrect="off" autoCapitalize="words"
               spellCheck="false">
          <input/>
        </Input>

 */
/*
          <option value=''>--- Please Select---</option>
          <option value='A'>A</option>
          <option value='B'>B</option>

 */


/*
        <Dropdown.Menu>
          <Dropdown.Item>Important</Dropdown.Item>
          <Dropdown.Item>Announcement</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item>Discussion</Dropdown.Item>
        </Dropdown.Menu>

 */
//clearable

let classes = this.props.classes
if (classes === undefined) {
    classes = {}
}
//console.log("arrOptions:",arrOptions);
  // TODO: clean this up - it's a quick hack to get the Meriton forms working
  // Need to setup proper css etc
  return (
    <div className="select-dd">
    <InputLabel 
        FormLabelClasses={{
            root: classes.formLabelRoot,
            error: classes.formLabelError,
            focused: classes.formLabelFocused,
        }} 
        error={this.props.error ? true:false} shrink>{this.props.field.label}
    </InputLabel>
    <Select
        input={
        <Input classes={{
            'root': classes.input,
            'error': classes.inputError,
        }} 
        name={this.props.name} id={this.props.name} />}
        fullWidth
        displayEmpty
        error={this.props.error ? true:false}
        value={this.props.initialValue || ""}
        onChange={this.onChange}
        
    >
        {this.props.field.placeHolder !== undefined && 
        <MenuItem value={undefined} disabled>
            this.props.field.placeHolder
        </MenuItem>}
        {this.props.nullable === true && 
        <MenuItem value={null}>
            this.props.nullOption
        </MenuItem>
        }
        {arrOptions.map((option, i) => {
            return(
                <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
            )
        })}
    </Select>
    {this.props.error && 
    <FormHelperText classes={{
            root: classes.helperText,
            error: classes.helperTextError
        }} error={this.props.error ? true:false}>
        {this.props.error}
    </FormHelperText>}
</div>
    );
/*
return (
  <Form.Field inline id={this.props.field.id} title={this.props.field.id}
              className='field-selectdropdown'
    control={Select}
    options={arrOptions}
    label={{ children: this.props.field.label }}
    placeholder='--- Please Select ---'
    onChange={this.onChange}
    value={this.state.value}
    error={errorMessage}

  />
);
*/
/*
return (
  <Form.Field inline id={this.props.field.id} error={errorMessage ? true:false}  className='field-selectdropdown'>
  <label>{this.props.field.label}</label>
  <select value={this.state.value} onChange={this.onChange}>
          <option value="">--- Please Select ---</option>
          { this.state.arrOptions.map(f => <option key={f.value} value={f.value}>{f.label}</option>) }
        </select>
  <label>{errorMessage}</label>

</Form.Field>
  );*/

/*
  <Input type={this.state.keyboardType} value={this.state.value} placeholder={this.state.placeholder} className={"field-text"}
         maxLength={this.state.maxLength} onChange={this.onChange} onBlur={this.onBlur} onFocus={this.onFocus}
         onKeyPress={this.onKeyPress} autoComplete="new-password" autoCorrect="off" autoCapitalize="words"
         spellCheck="false">
    <input/>
  </Input>

*/
  
/*    return (
      <Form.Field title={this.props.field.id}>
        <label>{this.props.field.label}</label>
        <select value={this.state.value} onChange={this.onChange}>
          <option value="">--- Please Select ---</option>
          { this.state.arrOptions.map(f => <option key={f.value} value={f.value}>{f.label}</option>) }
        </select>


      </Form.Field>
    );
*/

  }

}

export default SelectDropDown;
