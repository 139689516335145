import React, {Component} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';

import {getNotifications, setNotificationsRead} from '../api/Oscar-api.js';

import { 
    Grid,
    IconButton,
    Typography, 
    Hidden
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import LoadingModal from '../components/LoadingModal';

const styles = {
    timestamp: {
        textTransform: 'uppercase',
    },
    notificationContainer: {
        paddingTop: '2px'
    },
    notification: {
        padding: '4px',
        border: '1px solid #efefef',
        marginTop: '4px',
        flexWrap: 'nowrap',
        cursor:'pointer'
    }
}

function notificationDescription(notification) {
  switch(notification.notification_type) {
        case "Message":
          if (notification.description === 'manager sent message'){
            return `Meriton Manager sent you a message`;
          } else {
            return `${notification.booking_name} sent you a message`;
          } 
          
        case "Booking made":
            return `${notification.booking_name} booked your spot` 
        case "Booking cancelled":
            return `${notification.booking_name} cancelled their booking` 
        case "Booking extended":
            return `${notification.booking_name} extended their booking` 
        case "Spot reviewed positive":
            return `${notification.booking_name} gave you a thumbs up` 
        case "Spot reviewed negative":
            return `${notification.booking_name} gave you a thumbs down` 
        default:
            return notification.description
    }
}
function notificationAction(WPDomain,notification, history, user) {
  
  switch(notification.notification_type) {
        case "Message":
            return () => {history.push(`/Messages/${notification.booking}/${notification.is_driver ? 'driver': 'owner'}`)}
        case "Booking made":
        case "Booking request":
        case "Booking request cancelled by driver":
        return () => {history.push(`/ownerbookingdetails/${notification.booking}/`)}
        /*case "Booking cancelled":
            return () => {history.push(`/Calendar/`)}
        case "Booking extended":
            return () => {history.push(`/Calendar/${notification.booking}/`)}*/
        case "Expire soon":
        case "Booking accepted":          
        case "Booking request declined by owner":
            return () => {history.push(`/BookingDetails/${notification.booking}/`)}

        default:
            return () => null
    }
}
function showArrow(notification) {
    switch(notification.notification_type) {
        case "Message":
            return true
        case "Booking request":
        case "Booking request cancelled by driver":
        case "Booking accepted":          
        case "Booking request declined by owner":
        case "Booking made":
        case "conversation_to_owner":
        case "conversation_to_driver":
          return true
        case "Booking cancelled":
          return true
        case "Booking extended":
          return true
        case "Expire soon":
          return true
        default:
          return false
    }    
}

export default class NotificationsWP extends Component {
    state = {loading: true }

    componentWillMount() {
        getNotifications().then(response => {
            //console.log(response)
            this.setState({
                loading: false,
                notifications: response.data
            })
            setNotificationsRead();
            if (this.props.CMS){
              let CMS = this.props.CMS;
              CMS.hasNotification = 0;
              this.props.setCMS(CMS);
            } 

        }).catch(error => {
            console.error(error)
        })
    }


    render() {
        return(
          <div className="wpadmin-content top-padded-mobile">
            <LoadingModal open={this.state.loading} />
            <Hidden mdUp><IconButton className="leftCornerButton" component={Link} to="/search"><CloseIcon /></IconButton></Hidden>

                

                  <h1>Notifications</h1>
                    {(this.state.notifications && this.state.notifications.length === 0) && <h2>You don't have any notifications</h2>}
                    {this.state.notifications !== undefined && <div style={styles.notificationContainer}>
                        {this.state.notifications.map((notification, i) => {
                          let strClass = "notification-cp";
                          if (!notification.displayed){
                            strClass+=" unseen";
                          }
                          
                            return(
                                <div key={i}>
                                    <Grid container style={styles.notification} justify="space-between" alignItems="center" className={strClass + " noselect"}
                                    onClick={notificationAction(window.WPDomain,notification, this.props.history, this.props.user)}>
                                        <Grid item>
                                            <Typography style={styles.timestamp} variant="caption">
                                              {moment(notification.update_date).fromNow()}
                                            </Typography>
                                            <Typography variant="body1">
                                              <b>{notification.booking}</b> {notificationDescription(notification)}
                                            </Typography>
                                        </Grid>
                                        {showArrow(notification) && <Grid item>
                                            <IconButton>
                                                <IconForward />
                                            </IconButton>
                                        </Grid>}
                                    </Grid>
                                </div>
                            )
                        })}
                    </div>}
                
            </div>
        )
    }
}
