import React, {Component} from 'react';

import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';
import moment from 'moment';
import { CircularProgress, Paper } from "@material-ui/core";

import DateTimeRangePicker from '../components/DateTimeRangePicker.js';
//import DamianDate from '../components/DamianDate.js';

import {
    Button,
    Grid,
    //Paper,
    //InputAdornment,
    TextField,
    FormControl,
} from '@material-ui/core/';

/*import {
  MuiPickersUtilsProvider,
  //KeyboardTimePicker,
  //KeyboardDatePicker,
} from '@material-ui/pickers';
*/
import GooglePlacesSearchWP from './GooglePlacesSearchWP.js';
import SelectFieldWP from '../components/forms/SelectFieldWP';

import CalendarIcon from '@material-ui/icons/CalendarToday';


const styles = {
    dateDropper: {
        float: 'right',
        marginRight: '20px',
    },

    searchControl: {
        //margin: '8px',
        //padding: '10px',
    },

    addressSearch: {
        borderBottom: '1px solid #e5e5e5'
    },

    searchButton: {
        height: '56px',
        width: '116px',
        fontSize: '20px',
    }
}


/*function MyInputComponent(props) {
  const { component: Component, inputRef, ...other } = props;

  // implement `InputElement` interface
  React.useImperativeHandle(inputRef, () => ({
    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
    },
    // hiding the value e.g. react-stripe-elements
  }));

  // `Component` will be your `SomeThirdPartyComponent` from below
  //return <Component {...other} />;
  //console.log(props);
  return <div>(val:{props.value}) [def:{props.defaultValue}]</div>;
}*/

let root = {
  position: 'relative',
  backgroundColor:'#cccccc',
  width:'100%',
  height:'100%',
  cursor:'pointer',
  fontSize:'16px',
  fontWeight:'700',
  fontFamily:'Rubik'
};

//: any
// Custom DatePicker display (will upgrade to MaterialUI v4 later)
/*
function InputComponent({ defaultValue, inputRef, ...props }) {
  //const classes = useStyles();
  const [value, setValue] = React.useState(() => props.value || defaultValue);
  var inputRef2;

  const handleChange = event => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const handleSelect = event => {
    //console.log("select:",props);
    //inputRef.openDialog();
    //inputRef.openDialog();
    //console.log(inputRef2);
    //console.log(inputRef2.picker);
    //inputRef2.show();
    //inputRef.focus();
    //inputRef.ref.focus();
  };

  const setRef = ref => {
    inputRef2 = ref;
  };

  let strValue = moment(value).format('MMM DD, YYYY');
  
  return (
    <>
    <div>{strValue}</div>
    <input ref={r => setRef(r)} {...props} onChange={handleChange} onSelect={handleSelect} value={value} open={true} />
    </>
  );

}*/

/*
class ENUM_ParkingBay_AvailablityTypes(models.IntegerChoices):
  Not_Available = 1
  All_Days_24_7 = 2   #DEFAULT
  Weekdays_Only = 3
*/

const AVAILABILITY_OPTION_LIST = [
  {value:"0",label:"All parking options"}, 
  {value:"2",label:"24/7 only"}, 
  {value:"3",label:"Weekdays only"}
];




export default class ParkingSearchWP extends Component {
  constructor(props) {
    super(props)
   //not used?
    this.state = {
    }
  }

  
  render() {

    //["All Parking Options", "24/7 Access Only", "Weekdays Only","all"]
    return (
      <div className="wps-container noselect">
        {/*(this.props.searchInProgress) && <div className="wps-searchinprogress">
          <CircularProgress style={{color: "#ff0000" }}/>
          </div>*/}
        <div className="wps-searchcontrols">

          <p>Find Parking Near</p>
          <GooglePlacesSearchWP
            name="address" 
            onLocationSelected={this.props.onLocationSelected} 
            address={this.props.address}
            desktopDisplay={this.props.desktopDisplay}
          />

          <p>Monthly Parking Starting</p>
          <div style={{position:'relative'}}>
            <CalendarIcon className="wps-startdate2-icon" />
            <DatePicker
              selected={moment(this.props.msm_startdate).toDate()}
              onChange={(date) => this.props.onDateSelected(moment(date).format("YYYY-MM-DD"))}
              minDate={addDays(new Date(),1)}
              maxDate={addDays(new Date(),365)}
              withPortal
              dateFormat="dd MMM yyyy"
              className="wps-startdate2"
            />
          </div>

          <FormControl style={{marginLeft:'10px'}}>
              <SelectFieldWP
                  name="availability_type"
                  value={this.props.availability_mode}
                  options={AVAILABILITY_OPTION_LIST}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  onChange={(e) => this.props.onAvailibilitySelected(e.target.value)}
              >
              </SelectFieldWP>
          </FormControl>


        </div>
      </div>
    );
        
  }
}