import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';

import {AjaxEnable} from '../utils/AjaxEnable';
import {getTrips} from '../api/Oscar-api';

import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const styles = {
    heading: {
        color: '#3cb9c4',
        marginTop: '20px'
    },
    subHeading: {
        color:'grey'
    },
    arrowIcon: {
        color:'black',
        position: 'relative',
        top: '7px'
    }
}

function Trip(props) {
    const booking = props.booking;
    const start = moment(`${booking.start_date} ${booking.start_time}`)
    const end = moment(`${booking.cease_date} ${booking.cease_time}`)

    const startDate = start.format('Do MMMM YYYY - h:mm A');
    const total = ((end.diff(start, 'minutes') / 60) * booking.parking_space.rate_hourly).toFixed(2);    

    return(
        <div>
            <ListItem >
                <ListItemText primary={startDate} secondary={booking.parking_space.address} />
                <ListItemSecondaryAction style={styles.subHeading}>
                    ${total}
                    {/* <ArrowForward style={styles.arrowIcon} /> */}
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </div>
    )
}

class MyTrips extends Component {
    state = {
        inProgress: [],
        complete: [],
        tab1Style: {
            borderBottom: '5px solid #3cb9c4'
        },
        tab2Style: {},
        selectedTab: 0
    }
    componentWillMount() {
        // Seperate the data into complete and in progress
        let complete = [];
        let inProgress = [];

        //console.log(this.props.data)
        const now = moment()
        this.props.data.forEach(booking => {
            //console.log(`${booking.cease_date} ${booking.cease_time}`)
            const endTime = moment(`${booking.cease_date} ${booking.cease_time}`)
            //console.log(endTime.format("YYYY-MM-DD HH:mm"), now.format("YYYY-MM-DD HH:mm"))
            if (endTime.isAfter(now)) {
                inProgress.push(booking)
            } else {
                complete.push(booking)
            }
        })

        this.setState({
            inProgress: inProgress,
            complete: complete,
        })
    }

    handleChangeTab = (event, value) => {
        this.setState({
            selectedTab: value
        });

        if (value === 0) {
            this.setState({
                tab1Style: {
                    borderBottom: '5px solid #3cb9c4'
                },
                tab2Style: {
                }
            });
        } else {
            this.setState({
                tab1Style: {
                    
                },
                tab2Style: {
                    borderBottom: '5px solid #3cb9c4'
                }
            });
        }
    }

    render() {
        let trips = (this.state.selectedTab === 0) ? this.state.inProgress : this.state.complete;
        return (
            <div>
                <Tabs
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    fullWidth={true}
                    >
                    <Tab label="Upcoming" style={this.state.tab1Style} />
                    <Tab label="Past" style={this.state.tab2Style} />
                </Tabs><br/>
                <List>
                {trips.map(booking => {
                    return(
                        <Trip key={booking.uuid} booking={booking} />
                    )
                })}
                </List>

                <br/><br/>
            </div>
        );
    }
}

export default class Trips extends Component {
    render() {
        const AjaxTrips = withRouter(AjaxEnable(MyTrips, getTrips))

        return(
            <div>
                <Typography gutterBottom variant="display1" color="primary">
                    My Trips
                </Typography>
                <AjaxTrips />
            </div>
        )
    }
}