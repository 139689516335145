import React, {Children, Component} from 'react';

const styles = {
    loadingDot: {
        display: 'inline-block',
        height: '12px',
        width: '12px',
        margin: '7px',
        marginTop: '0px',
        marginBottom: '0px',
        backgroundColor: 'white',
        borderRadius: '20px',

        animationDuration: '1s',
        //animationDirection: 'alternate',
        animationIterationCount: 'infinite',
    }
}

export default class LoadingButton extends Component {
    replaceText = (children, loader) => {
        return Children.map(children, (child, i) => {
            return React.cloneElement(child, {
                children: loader,
                onClick: function() { void(0); }
            })
        })
//        console.log(children)
//        console.log(children[0].children)
    }

    render() {
        const LoadingAnimation = (
            <div>
                <span style={{...styles.loadingDot, animationName: 'ldot1'}}></span>
                <span style={{...styles.loadingDot, animationName: 'ldot2'}}></span>
                <span style={{...styles.loadingDot, animationName: 'ldot3'}}></span>
            </div>
        )

        return(
            <div>
                {this.props.loading 
                ? this.replaceText(this.props.children, LoadingAnimation)
                : this.props.children
                }
            </div>
        )
    }
}