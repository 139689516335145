import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import moment from 'moment';
import ImageCompressor from 'image-compressor.js';

import {
  createParkingBay,
  updateParkingBay,
  uploadParkingBayImages,
} from '../api/Oscar-api.js';
import { isNativeWebview } from '../utils/IsNativeWebview.js';

import {
    QueryParamDate,
    QueryParamTime
} from '../utils/TimeFormats.js';
import ENUMS from '../api/ENUMS.json'

import {checkUserNotificationPermissions} from '../utils/PushNotification.js';
import {getImagePreviewForBuilding} from '../utils/GetImageListForSpace.js';

import {
    Button,
    FormControl,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Typography
} from '@material-ui/core';
import IconBack from '@material-ui/icons/KeyboardArrowLeft';
import IconForward from '@material-ui/icons/KeyboardArrowRight';
import IconPlus from '@material-ui/icons/Add';
import IconDelete from '@material-ui/icons/Close';
import CloseIcon from '@material-ui/icons/Close';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import CRUDForm from '../components/CRUDForm.js';

import SelectField from '../components/forms/SelectField';
import SelectFieldWP from '../components/forms/SelectFieldWP';

import GooglePlacesField from '../components/forms/GooglePlacesField.js';
import HiddenField from '../components/forms/HiddenField.js';
import NumberField from '../components/forms/NumberField.js';
import SliderSwitch from '../components/forms/SliderSwitch.js';
import WeekDaySelector from '../components/forms/WeekDaySelector.js';
import Calendar from '../components/Calendar.js';
import DateTimeRangePicker from '../components/DateTimeRangePicker.js';
import LoadingModal from '../components/LoadingModal.js';

import { updateUserProfileAjax } from '../utils/Authentication.js';

const styles = {
    topBar: {
        //position: 'absolute',
        position:'relative',
        zIndex: '2',
        width: '100%',
        top:'50px',
        paddingTop: '0',
        paddingBottom: '0',
        backgroundColor: '#ffeeee',
    },
    /*formContainer: {
        padding: '16px',
        paddingTop: '80px'
    },*/
    leftTopButton :{
        //marginTop: '-5px',
        //position: 'absolute',
        //left: 5,
    },
    rightTopButton: {
        position: 'absolute',
        right: 5
    },
    nextButton: {
        position: 'absolute',
        bottom: 16,
        right: 16,
    },
    switchBorder: {
        borderTop: '1px solid #efefef',
        borderBottom: '1px solid #efefef'
    },
    fileInput: {
        display: 'none'
    },
    uploadImagePreview: {
        width: '110px',
        height: '70px',
    },
    uploadContainer: {
    },
    skipPhotos: {
        textTransform: 'none',
        color:'var(--heroColor2)',
        border: '1px solid var(--heroColor2)'
    },
    removeImage: {
        position: 'absolute',
        backgroundColor: 'white',
        height: '24px',
        cursor:'pointer'
    }
}

const MAX_IMAGE_UPLOAD_SIZE = 1000000

function generateTemporaryLocalUuid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return "TEMPORARY_LOCAL_" + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

// TEMP!!!!
const base = "https://meriton.sharewithoscar.com.au/images";
function imagesFromListing(listing) {
  //console.log("imagesFromListing:",listing);
    let images = {'1': [], '2': [], '3': []}

    for (let i = 0; i < listing.image_set.length; i++ ) {
        if (listing.image_set[i].image_type === 1) {
            images['1'].push({preview: `${base}/${listing.image_set[i].path}`})
        }
        if (listing.image_set[i].image_type === 2) {
            images['2'].push({preview: `${base}/${listing.image_set[i].path}`})
        }
        if (listing.image_set[i].image_type === 3) {
            images['3'].push({preview: `${base}/${listing.image_set[i].path}`})
        }
    }


    return images;
}

const defaultHourly = 2
const defaultWeekly = 50
const weekdays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

export default class ListSpaceWP extends Component {
  constructor(props) {
      super(props)
      this._endPoint = createParkingBay
      this._hydrateForm = undefined
      this._creating = true
      this._firstTime = props.match.params.first === "first"
      this._requestPushPermissions = checkUserNotificationPermissions()

      if (props.location.state) {
          //state passed int?
          //console.log("# State passed in:",props.location.state);

        if (props.location.state.hydrateState) {
            //???
              this.state = {
                  ...props.location.state.hydrateState.local,
                  startTime:  moment("7:00 AM", 'h:mm A'),
                  endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
              }
              this._hydrateForm = props.location.state.hydrateState.form
          } else if (props.location.state.completeListing) {
              //???????
              this._creating = false
              this.state = {
                  currentUuid: props.location.state.completeListing.uuid,
          
                  loading: false,
                  activePage: props.location.state.completeListing.availabilityrecurring_set.length > 0 ? 5 : 3,
                  pageHistory: [],


                  oscarRates: props.location.state.completeListing.uuid,
                  alwaysAvailable: true,
                  repeatWeekly: true,
          
                  rate_hourly: defaultHourly,
                  rate_weekly: defaultWeekly,
                  selectedDays: [false, false, false, false, false, false, false],
                  startTime: moment("7:00 AM", 'h:mm A'),
                  endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                  
                  previewImages: {"1": [], "2": [], "3": []},
                  imageFiles: {"1": [], "2": [], "3": []},
                  deletedImages: [],

                  spaceImages: {"1": [], "2": [], "3": []}
              }                
          } else if (props.location.state.editListing) {
            //console.log("EDIT LISTING");
              this._hydrateForm = {values: props.location.state.editListing, errors:{}}
              //console.log("hydrateForm:",this._hydrateForm);
              //console.log("PAGE?:",props.location.state.page);
              let activePage = props.location.state.page;

              this._endPoint = updateParkingBay
              this._editUuid = props.location.state.editListing.uuid
              this._creating = false

              const imageObject = imagesFromListing(props.location.state.editListing)

              this.state = {
                  currentUuid: props.location.state.editListing.uuid,
          
                  loading: false,
                  activePage,
                  pageHistory: [],


                  oscarRates: props.location.state.editListing.price_by_oscar,
                  auto_approve: props.location.state.editListing.auto_approve,

                  alwaysAvailable: true,
                  repeatWeekly: true,
          
                  rate_hourly: defaultHourly,
                  rate_weekly: defaultWeekly,
                  selectedDays: [false, false, false, false, false, false, false],
                  startTime: moment("7:00 AM", 'h:mm A'),
                  endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
                  
                  previewImages: imageObject,
                  imageFiles: {"1": [], "2": [], "3": []},
                  deletedImages: [],

                  spaceImages: imageObject
              }
          }
      } else {
          //Initial load
          //console.log("# initial Load");
          this.state = {
              currentUuid: generateTemporaryLocalUuid(),
      
              loading: false,
              activePage: 0,
              pageHistory: [],


              oscarRates: true,
              alwaysAvailable: true,
              repeatWeekly: true,
      
              rate_hourly: defaultHourly,
              rate_weekly: defaultWeekly,
              selectedDays: [false, false, false, false, false, false, false],
              startTime: moment("7:00 AM", 'h:mm A'),
              endTime: moment("6:30 PM", 'h:mm A').add(7, 'days').add(100, 'years'),
              
              previewImages: {"1": [], "2": [], "3": []},
              imageFiles: {"1": [], "2": [], "3": []},
              deletedImages: [],

              spaceImages: {"1": [], "2": [], "3": []}
          }    
      }
  }

  checkValidRate(rate) {
    if (!this.props.user.profile){
      return false;
    }

    const thisBuilding = window.WPDomain === 'community' ? this.props.user.profile.default_building_community: this.props.user.profile.default_building;
    if (thisBuilding){

      if (isNaN(rate)){
        return false;
      }
      let intRate = parseInt(rate);
      if (isNaN(intRate)){
        return false;
      }

      if (intRate < thisBuilding.rate_monthly_min || intRate > thisBuilding.rate_monthly_max){
        return false;
      }
      return true;
      

    } else {
      return false;
    }




  }
  checkPageReady = () => {
      let p1Ready = false;
      let p2Ready = false;
      
      if (this._mainForm){
        const formValues = this._mainForm.getState().values
        if (formValues.bay_marking !== undefined &&
            formValues.vehicle_type !== undefined &&
            //formValues.access_method !== undefined &&
            formValues.description !== undefined &&
            formValues.instruction !== undefined) {
            p1Ready = true;
        }

        if (formValues.availability_type !== undefined && 
            this.checkValidRate(formValues.rate_monthly)
          ) {
            p2Ready = true;
        }

      }

      this.setState({
          p1Ready: p1Ready,
          p2Ready: p2Ready,

      })
  }

  componentDidMount() {
      this.checkPageReady()
  }

  handleNext = () => {

    const active = this.state.activePage
    const history = this.state.pageHistory
    history.push(active)
    this.setState({
        pageHistory: history,
        activePage: active + 1
    });

/*
    const active = this.state.activePage
      const history = this.state.pageHistory
      if (active === 5) {
          // special case for getting to the end
      } else {
          history.push(active)
          this.setState({
              pageHistory: history,
              activePage: active + 1
          })
      }*/
  }
  handleBack = () => {
      if (this.state.activePage === 3) {
          this.props.history.push('/Listings')
          return
      }
      if (this.state.pageHistory.length === 0) {
          this.props.history.goBack()
      } else {
          const history = this.state.pageHistory
          const page = history.pop()
          this.setState({
              pageHistory:  history,
              activePage: page
          })
      } 
  }
  handleExit = () => {
      // Save the current data to local storage.
      //const stateSnapshot = {local: this.state, form: this._mainForm.getState()}
      
      // Multiple users same browser...... have to make sure these saved spots are destroyed with the user creds
      /*let localData = localStorage.getItem("LOCAL_DATA")
      if (localData) {
          //console.log('local data found')
          localData = JSON.parse(localData)
          if (!('LISTINGS_IN_PROGRESS' in localData)) {
              //console.log('creating listings key in local data')
              localData['LISTINGS_IN_PROGRESS'] = {}
          }
          if (!(this.state.currentUuid in localData['LISTINGS_IN_PROGRESS'])) {
              localData['LISTINGS_IN_PROGRESS'][this.state.currentUuid] = undefined
          }
          localData['LISTINGS_IN_PROGRESS'][this.state.currentUuid] = stateSnapshot
      } else {
          //console.log('local data not found, creating new object')
          localData = {}
          localData['LISTINGS_IN_PROGRESS'] = { [this.state.currentUuid]: stateSnapshot }
      }*/
      //console.log('saving progress to local data')
      //console.log("localData:",localData);
      /////localStorage.setItem("LOCAL_DATA", JSON.stringify(localData))

      this.props.history.push('/listings')
  }
  handleError =  (error) => {
    
      try {
        // detail !== null is required here
        /*if (error.response && error.response.data && error.response.data.detail !== null){
          console.error("General error:",error.response.data.detail);
          this.setState({activePage: 0});    //2

        } else*/
        
        if ('address' in error.response.data || 
          'latitude' in error.response.data || 
          'type' in error.response.data ||
          'street_name' in error.response.data ||
          'vehicle_type' in error.response.data) {
          this.setState({activePage: 0})
        } else if ('description' in error.response.data ||
              'instruction' in error.response.data) {
            this.setState({activePage: 1})
        } else {
            this.setState({activePage: 0})    //2
        }

        console.log("ERROR IS:",error.response.data);
        

      } catch (e){
        // General FAIL from Django
        console.error("General error.");
        this.setState({activePage: 0})

      }


  }
  handleDaySelect = (index) => {
      let actives = this.state.selectedDays;
      actives[index] = !actives[index]
      this.setState({selectedDays: actives})
  }
  handleTimeRangeSelected = (start, end) => {
      let newStart = moment(this.state.startTime)
      let newEnd = moment(this.state.endTime)

      newStart.hour(start.hour())
      newStart.minute(start.minute())
      newEnd.hour(end.hour())
      newEnd.minute(end.minute())

      this.setState({startTime: newStart, endTime: newEnd})
  }
  handleToggleRepeatWeekly = () => {
      if (this.state.repeatWeekly) {
          this.setState({
              repeatWeekly: false,
              endTime: moment(this.state.endTime).subtract(100, 'years')
          })
      } else {
          this.setState({
              repeatWeekly: true,
              endTime: moment(this.state.endTime).add(100, 'years')
          })
      }
  }

      // Shared toggle code - checkPageReadyFlag:true will trigger ONE execution, easier than managing the form
      toggleSlider = (val) => {
        if (val === 'auto_approve'){
          this._toggleSliderAuto.reportChange(!this.state.auto_approve);
          this.setState({auto_approve:!this.state.auto_approve, checkPageReadyFlag:true});
        } /*else if (val === 'allow_casual'){
          this._toggleSliderCasual.reportChange(!this.state.allow_casual);
          this.setState({allow_casual:!this.state.allow_casual, checkPageReadyFlag:true});
        } else if (val === 'allow_monthly'){
          this._toggleSliderMonthly.reportChange(!this.state.allow_monthly);
          this.setState({allow_monthly:!this.state.allow_monthly, checkPageReadyFlag:true});
        }*/
  
      }
  
  
    
  handleImagePickerClick = (event, imageType) => {
      if (isNativeWebview()) {
          event.preventDefault()
          event.stopPropagation()

          // Send the postMessage to the native side saying we want to log in through google
          window.ReactNativeWebView.postMessage(JSON.stringify({action: "image"}));
          if (window.document.isAndroid) {
              window.document.addEventListener("message", this.handleNativeResponse )
          } else {
              window.addEventListener("message", this.handleNativeResponse )
          }

          this.setState({
              loading: true,
              pickingImageType: imageType,
          })
      }        
  }
  handleNativeResponse = (event) => {
      // Process the response token as normal with our backend
      const nativeResponse = JSON.parse(event.data);
      if (nativeResponse.message === "image_upload") {
          this.compressAndSaveImage(b64toBlob(nativeResponse.image_data), this.state.pickingImageType, .8)
      }
      if (nativeResponse.message === "image_error") {
          // handle image errors here.
          this.setState({imageAddError: true})
      }
      this.setState({ loading: false})
      window.removeEventListener("message", this.handleNativeResponse);
  }

  compressAndSaveImage = (file, imageType, quality) => {
      // console.log(file.size)
      if (file.size < 1000) {
          this.setState({imageAddError: true, loading:false})
          return
      }
      //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: file.size}));
      if (file.size < MAX_IMAGE_UPLOAD_SIZE) {
          this.saveImage(file, imageType)
          return
      }

      //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "compressing"}));
      new ImageCompressor(file, {
          quality: quality,
          checkOrientation: false,
          width: 800,

          success: (result) => {      
              if (result.size < MAX_IMAGE_UPLOAD_SIZE) {
                  //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "compressing"}));
                  this.saveImage(result, imageType)
              }    
              else {
                  this.compressAndSaveImage(result, imageType, quality / 1.5)
              }            
          },
          error: (e) => {
              console.log(e.message);
              //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: e.message}));
              // handle image errors here.
              this.setState({imageAddError: true, loading:false});
          },
      });
  }

  saveImage = (result, imageType) => {
      const reader = new FileReader()
      //window.postMessage(JSON.stringify({action: "nothing_dont_use", message: "saving"}));
      reader.onload = () => {
          let imgs = this.state.spaceImages
          imgs[imageType].push({preview: reader.result, file: result}) 
          this.setState({ 
              spaceImages: imgs,
              loading: false,
              imageAddError: false,
          }); 
      }
      try {
          reader.readAsDataURL(result);
      } catch (e) {
          void(0);
      }    
  }

  handleFileChange = (e, file, imageType) => {
      file = file || e.target.files[0]

      if (file.type === 'image/jpeg' || file.type === 'image/png'){
          this.setState({loading: true});
          //TODO: AWS Lambda will compress and resize automatically later
          this.compressAndSaveImage(file, imageType, .8);
  
      } else {
          this.setState({imageAddError: true, loading:false});
      }
      
  }

  handleSkipPhotos = () => {
      this.finishFlow()
  }
  handleSavePhotos = () => {
      this.setState({loading: true})
      console.log(this.state.currentUuid);

      uploadParkingBayImages(this.state.spaceImages, this.state.deletedImages, this.state.currentUuid).then(response => {
          //console.log('yay')
          this.finishFlow()
      }).catch(error => {
          console.log(error.response)
          this.setState({loading:false})
      })
  }
  handleRemoveImage = (imageType, imageIndex) => {
      let previews = this.state.spaceImages
      let img = previews[imageType].splice(imageIndex, 1)

      let deletedImages = this.state.deletedImages
      if (img[0].file === undefined) {
        let strFilename = img[0].preview;
        // TEMP
        strFilename = strFilename.replace("https://www.sharewithoscar.com.au/images/", "");
        strFilename = strFilename.replace("https://meriton.sharewithoscar.com.au/images/", "");
        
        //deletedImages.push(img[0].preview.replace("https://www.sharewithoscar.com.au/images/", ""))
        deletedImages.push(strFilename);
      }

      this.setState({
          spaceImages: previews,
          deletedImages: deletedImages
      })
  }

  finishFlow = () => {
      if (this._firstTime) {
          // Show the bell
          this.props.history.push('/listings')
      } else if (this._requestPushPermissions) {
          // Show the push prompt
          //this.props.history.push('/PermissionsPush') // Disabled for Meriton demo
          this.props.history.push('/listings')
      } else {
          this.props.history.push('/listings')
      }
  }

  handleCreateSuccess = (response) => {
    //console.log("handleCreateSuccess:",response)  
    // Delete this temporary listing from local storage if its saved there
/*      let localData = localStorage.getItem("LOCAL_DATA")
      if (localData) {
          localData = JSON.parse(localData)
          if ('LISTINGS_IN_PROGRESS' in localData) {
              if (this.state.currentUuid in localData['LISTINGS_IN_PROGRESS']) {
                  delete localData['LISTINGS_IN_PROGRESS'][this.state.currentUuid]
                  localStorage.setItem("LOCAL_DATA", JSON.stringify(localData))
              }    
          }
      }*/
      let hist = this.state.pageHistory
      hist.push(2)
      this.setState({
          pageHistory: hist,
          activePage: 2, //this._creating ? 2 : 3,
          currentUuid: response.data.uuid
      })

      updateUserProfileAjax().then(response => {
          this.props.authChange()
      })
  }
  //=======================================================================================


  //=======================================================================================
  render() {
    //???
    const ImageUpload = (props) => (
        <Grid style={{marginBottom: '20px'}} container justify="space-between" alignItems="center">
            <Grid item>
                <Typography variant="body1">
                    {props.description}
                </Typography>
            </Grid>
            <Grid item>
                <IconButton
                onClick={(evt) => this.handleImagePickerClick(evt, props.imageType)}>
                    <label>
                        <IconPlus style={{color:'var(--heroColor2)'}} />
                        <input 
                            style={styles.fileInput} 
                            type="file" 
//                                accept="image/*"
                            onChange={(e, file) => this.handleFileChange(e, file, props.imageType)} 
                            
                        />
                    </label>
                </IconButton>
            </Grid>
            <Grid item xs={12}>
                {this.state.spaceImages[props.imageType].map((image, i) => {
                    return(
                        <div key={i} style={{display: 'inline-block', position: 'relative'}}>
                            <div style={styles.removeImage} onClick={() => this.handleRemoveImage(props.imageType, i)}>
                                <IconDelete style={{color:'#ff0000'}} />
                            </div>
                            <img style={styles.uploadImagePreview} src={image.preview} key={i} alt={props.description}/>
                        </div>
                    )
                })}
            </Grid>
        </Grid>
    );    

    let thisBuilding;
    let thisAddress;

    if (window.WPDomain == 'community'){
      thisBuilding = this.props.user.profile.default_building_community;
      thisAddress = this.props.user.profile.entrance_address_community;

    } else {
      thisBuilding = this.props.user.profile.default_building;
      thisAddress = `${thisBuilding.address}, ${thisBuilding.suburb} ${thisBuilding.state} ${thisBuilding.postcode}`;


    }

    if (!thisBuilding){
      return (
        <div className="wpadmin-content top-padded-mobile edit-spot">
          <h1>Sorry, only tenants may list their parking spots.</h1>
        </div>
      );

    }

    const rate_monthly_default = thisBuilding.rate_monthly_default;
    const rate_monthly_min = thisBuilding.rate_monthly_min;
    const rate_monthly_max = thisBuilding.rate_monthly_max;

    const default_access_method = thisBuilding.access_method;
    const default_vehicle_type = thisBuilding.vehicle_type;

    const buildingImage = getImagePreviewForBuilding(thisBuilding);

    const divThisBuilding = (
      <div className="li-spot" style={{padding:'0 0 15px 0',borderBottom:'1px solid #cccccc', marginBottom:'20px'}}>
        <div className="photo-box" style={{margin:'0 15px 0 0'}}>{buildingImage}</div>
         <div className="details">      
          <h2 style={{margin:"0"}}>{thisBuilding.building_name}</h2>
          <p style={{margin:"0 0 20px 0"}}>{thisAddress}</p>
        </div>
      </div>
    );



/*
    <div className="li-spot-parent">
    <div className="li-spot" key={spot.uuid}>
        <div className="details">      
          <h2><span>{building.building_name}</span> on {building.street_name}</h2>
          <p>Bay# {spot.bay_marking}</p>
          <p>{spot.description}</p>
          <p>{ENUMS.availability_types[spot.availability_type]}</p>
        </div>
        <div className="photo-box">{img}</div>
    </div>
    <div className="wp-actions">
      <Button className="wp-button" onClick={() => props.that.handleEdit(spot)}>Edit Listing</Button>    
      <Button className="wp-button" onClick={() => props.that.handleEditAvailability(spot)}>Edit Availability</Button>    
    </div>
    </div>
*/
    //console.log("activePage:", this.state.activePage);

    //console.log("state:",this.state);
    return(
      <div className="wpadmin-content top-padded-mobile edit-spot">
        <LoadingModal open={this.state.loading} />
          
        <Hidden mdUp>
          <IconButton className="leftCornerButton" component={Link} to="/search"><CloseIcon /></IconButton>
        </Hidden>


        {/*<div style={styles.topBar}>
            {<IconButton onClick={this.handleBack} style={styles.leftTopButton}>
                <IconBack />
                </IconButton>}
            <LinearProgress variant="determinate" value={this.state.activePage * 15 + 10} />

            {this._creating && <Button style={styles.rightTopButton} className="wp-textbutton" onClick={this.handleExit}>
                Save and Exit
            </Button>}
    </div>*/}

        <>
          <div style={this.state.activePage <= 1 ? {display: 'block'} : {display:'none'}}>
                  <CRUDForm
                  submitOnEnter={false}
                  endpoint={this._endPoint}
                  uuid={this._editUuid}
                  onChange={this.checkPageReady}
                  onError={this.handleError}
                  onSuccess={this.handleCreateSuccess}
                  ref={(component) => {this._mainForm = component}}
                  hydrateFromObject={this._hydrateForm}
              >
                  <div style={this.state.activePage === 0 ? {display: 'block'} : {display:'none'} }>
                      <h1>Tell us about your parking spot</h1>
                      {divThisBuilding}

                      <FormControl >
                        <TextField
                            className="wp-spacer"
                            label="Bay Number #"
                            name="bay_marking"
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="Enter bay number"
                            margin="normal"
                        />
                      </FormControl>


                      <FormControl fullWidth className="wp-spacer" >
                          <SelectFieldWP 
                            label="Maximum size" 
                              name="vehicle_type"
                              placeHolder="How big is your spot?"
                              nullable={false}
                              value={default_vehicle_type}
                              options={ENUMS.OPTIONS_LISTS.vehicle_types}
                          />
                      </FormControl>
                      <FormControl fullWidth className="wp-spacer">
                          <SelectFieldWP
                            label="Access Type" 
                              name="access_method"
                              placeHolder="How do you get into your space?"
                              nullable={false}
                              value={default_access_method}
                              options={ENUMS.OPTIONS_LISTS.access_methods}                              
                          />
                      </FormControl>

                      <h2>Describe your parking spot</h2>
                      <TextField
                          //multiline
                          //rows={3}
                          label="Description"
                          name="description"
                          type="text"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          placeholder="E.g. floor number, accessibility, size of space"
                          fullWidth
                          margin="normal"
                      />
                      <TextField
                          //multiline
                          //rows={3}
                          label="Access instructions"
                          name="instruction"
                          type="text"
                          InputLabelProps={{
                              shrink: true,
                          }}
                          placeholder="Describe how the driver can find and access your space"
                          fullWidth
                          margin="normal"
                      />


                      <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '24px',marginBottom:'50px'}}>
                          <Button className="wp-button thirdWidth" onClick={this.handleNext} disabled={!this.state.p1Ready}>Next<IconForward /></Button>
                      </div>
                  </div>
                  <div style={this.state.activePage === 1 ? {display: 'block'} : {display:'none'} }>
                      
                      <h1>Availabilities &amp; Price</h1>
                      <h3>When is your car space available?</h3>
                      <FormControl fullWidth  className="wp-spacer">
                          <SelectFieldWP
                              label="Select One" 
                              name="availability_type"
                              placeHolder="- Please select -"
                              nullable={false}
                              options={ENUMS.OPTIONS_LISTS.availability_types}
                          />
                      </FormControl>

                      {/*<h3>Instantly Acccept Booking Requests</h3>*/}
                                    <SliderSwitch checked={this.state.auto_approve} onChange={this.toggleSlider.bind(this,'auto_approve')} />
                                    <span style={{color:'rgba(0,0,0,0.54)',fontWeight:'700'}}>INSTANTLY ACCEPT BOOKING REQUESTS</span>
                                    <p style={{marginTop:'0'}}>Accept Instant Bookings to increase your earnings and chances of your parking space being booked. If instant bookings are not allowed, you will need to accept each booking that comes through.</p>
                                    <HiddenField name="auto_approve" value={this.state.auto_approve} ref={(c) => this._toggleSliderAuto = c}/>



                      <h3 style={{marginTop:'30px'}}>Set a monthly price</h3>

                        <TextField
                            label="Monthly Rate"
                            name="rate_monthly"
                            type="text"
                            InputProps={{
                              startAdornment: <InputAdornment position="start"><AttachMoneyIcon style={{color:'var(--heroColor2)'}} /></InputAdornment>,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder={`${rate_monthly_default}`}
                            margin="normal"
                        />

                      {window.WPDomain !== 'community' && <p>Please ensure your prices is between ${rate_monthly_min} and ${rate_monthly_max} for the best chance of receiving a booking.</p>}
                      <p>*Your earnings will be transferred to you on a monthly basis, minus a <a href={"https://www.sharewithoscar.com.au/"+window.WPDomain+"-faq/"} target="_blank">service fee.</a></p>

                      <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
                        <Button className="wp-button thirdWidth" type="submit" disabled={!this.state.p2Ready}>Next <IconForward /></Button>
                      </div>
                  </div>
              </CRUDForm>
              
          </div>
          <div style={this.state.activePage === 2 ? {display: 'block'} : {display:'none'}}>

                  <div>
                      <h1>Add photos of your spot</h1>
                      <h3>Help drivers identify your parking spot. More photos are better but you can always start with one and add more later.</h3>
                      
                      {this.state.imageAddError && <div style={{padding: '16px', border: '1px solid red', borderRadius: '6px'}}>
                          <p className="error">Unable to add that image. Please check it is not a live photo.</p>
                      </div>}
                      <div style={styles.uploadContainer}>
                          <ImageUpload description="General photos of your spot" imageType="1"/>  
                          <ImageUpload description="Photos of the spot from the street" imageType="2"/>  
                          <ImageUpload description="Photos showing access to the spot" imageType="3"/>  
                      </div>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '50px',marginBottom:'50px'}}>
                    <Button className="wp-button thirdWidth" onClick={this.handleSkipPhotos}>Done <IconForward /></Button>
                  </div>

          </div>
        </>

      </div>
    );
  }
}

/*
<div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '50px' }}>
<Button className="wp-button thirdWidth" type="submit" disabled={!this.state.p2Ready}>Next <IconForward /></Button>
</div>
*/

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


export {defaultHourly, defaultWeekly}