import React, {Component} from 'react';
import ReactDOM from 'react-dom'

import PlacesAutocomplete, 
{
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import {requestLocation} from '../utils/Location.js';

import {
    Card,
    CardContent,
    Grid,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core'

import BackIcon from '@material-ui/icons/ArrowBackIos';
import IconClear from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';

import PlacesInputMobileAutoFocus from './forms/PlacesInputMobileAutoFocus';

import FullPageModal from '../utils/FullPageModal';
import { EventRounded } from '@material-ui/icons';
/*
const styles = {
  addressBox: {
    border:'1px solid #ff0000',
    padding: '9px',
  },
  closeButton: {
    position: 'absolute',
    top: '5px',
    left: '5px',
  },
  searchBoxContainer: {
    backgroundColor: '#f5f5f5',
    margin: '10px',
    marginTop: '42px',
    padding: '8px 24px 8px 24px',
  },
  suggestionsContainer: {
    marginTop: '10px'
  },
  suggestionsContainerDesktop: {
    position: 'absolute',
    zIndex: '5',
    width: 'calc(100% - 40px)',
    marginTop: '10px',
    minHeight:'300px'
  },
  suggestionStyle: {
    paddingBottom: '10px',
    borderBottom: '1px solid #f5f5f5',
    marginBottom: '10px'
  }
}
*/


class LocationButton extends Component {
    handleLocationRequest = () => {
      requestLocation().then(({address, latLng}) => {
          //console.log(address, latLng)
          this.props.onLocationSelected(address, latLng)
      })
    }
  
    render() {
      return(
        <LocationSearchingIcon onClick={this.handleLocationRequest}/>
      )
    }
}

export default class GooglePlacesSearchWP extends Component {
    constructor(props) {
        super(props)

        this.state = {
          isOpen:null,
            address: props.address === ' ' ? undefined : props.address,
            addressMobile:''
        }
    }


    handleOpenModal = (name) => {
      let isOpen = name;   
      
      let addressMobile = this.state.addressMobile;
      if (isOpen){
        addressMobile = '';
      }
      this.setState({isOpen, addressMobile});
    }

    handleChange = address => {
      this.setState({ address });
      };

    handleChangeMobile = addressMobile => {
      this.setState({ addressMobile });
    };

     
    handleSelect = address => {
      //console.log("address:",address);
      address = address.replace(", Australia","");
      this.setState({ address, addressMobile:address, selection:address });
      
/* 
        geocodeByAddress(address).then(results => {
            const result = results[0]
            // Report the new street name, postcode, state, latitude and longitude
            
            const postcode = (result.address_components.find(elem => {
                return elem.types.indexOf("postal_code") !== -1
            }) || {short_name: '0000'}).short_name;

            // Not every address suggestion will have a street
            const streetResult = result.address_components.find(elem => {
                return elem.types.indexOf("route") !== -1
            })
            const street = streetResult ? streetResult.short_name : undefined

            const state = result.address_components.find(elem => { 
                return elem.types.indexOf("administrative_area_level_1") !== -1 
            }).short_name

            getLatLng(result).then(latLng => {
                //console.log(latLng)
                //console.log(street, state, postcode)
                this.updateHiddenFields(latLng.lat, latLng.lng, postcode, state, street)
            })
        })
*/


        

        const newAddr = address;
        let postcode;
        let street_name;
        let state_name;
        let suburb;
        geocodeByAddress(address)
            .then(results => {
            //console.log(results)
              
              postcode = (results[0].address_components.find(elem => {
                return elem.types.indexOf("postal_code") !== -1
              }) || {short_name: '0000'}).short_name;

              // Not every address suggestion will have a street
              const streetResult = results[0].address_components.find(elem => {
                return elem.types.indexOf("route") !== -1
              })
              street_name = streetResult ? streetResult.short_name : undefined

              //console.log(results[0].address_components);

              suburb = results[0].address_components.find(elem => { 
                return elem.types.indexOf("locality") !== -1 
              }).short_name;

              state_name = results[0].address_components.find(elem => { 
                  return elem.types.indexOf("administrative_area_level_1") !== -1 
              }).short_name;



              return getLatLng(results[0]);

            })
            .then(latLng => {
                //console.log('Success', latLng)
                
                if (this.props.onLocationSelected_FullDetails){
                  this.props.onLocationSelected_FullDetails(newAddr, latLng, street_name, suburb, state_name, postcode);
                } else {
                  this.props.onLocationSelected(newAddr, latLng);
                }
                
                this.handleOpenModal(null);
            })
        .catch(error => console.error('Error', error));
    };


    handleBlur = () => {
      if (this.state.address == '' || !this.state.selection){
        this.setState({address:this.state.originalAddress});
      }
    }





    // We try to keep it clean with just one component that adjusts for desktop/mobile
    render() {
      const {isOpen} = this.state;
      const {browserCSSMode} = this.props;
      //console.log({browserCSSMode}); // browserCSSMode === "mobile"

      let strModalClass;
      let strContainerClass;
      let strTitle;
      let strStartFrom;

      // ====================================================
      // MOBILE
      // ====================================================
      if (!window.WPDomain && browserCSSMode === 'mobile'){
        strModalClass = "oscar modal-places";
        strContainerClass = "oscar mplaces-container";
        if (isOpen === 'places'){
          strContainerClass += " open";
          strModalClass+= " open";
        }

        return (
          <>
          {/*ReactDOM.createPortal(*/
            <>
            <div className={strContainerClass}>
              
              {isOpen &&
                <PlacesAutocomplete value={this.state.addressMobile} onChange={this.handleChangeMobile} onSelect={this.handleSelect} searchOptions={{location: new window.google.maps.LatLng(-34, 151), radius: 2000 }}>
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <>
                      <div className="mplaces-header">
                      <div className="mplaces-header-back" onClick={() => {this.handleOpenModal(null)}}><BackIcon /></div>
                      <PlacesInputMobileAutoFocus
                      {...getInputProps({
                        autoFocus: true,
                        placeholder: this.props.placeholder || 'Where are you going?',
                        className: 'wpsac-search-input',
                      // InputProps: {disableUnderline: true, spellCheck: false, autoFocus:true},
                        })}
                      />
                      </div>
                      <div className="mplaces-body">
                        {suggestions.length === 0 ?
                          <p></p>
                        :  
                          <div className="mplaces-wpsac-results">
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                ? 'mplaces-suggestion-item--active'
                                : 'mplaces-suggestion-item';
                                //const headline = `${suggestion.terms[0].value}`
                                //console.log(suggestion);
                                const headline = suggestion.formattedSuggestion.mainText;
                                const caption = suggestion.formattedSuggestion.secondaryText;
                                /*caption += suggestion.terms[1] ? `${suggestion.terms[1].value} ` : ''
                                
                                if (suggestion.terms[2] && suggestion.terms[2].value !== 'Australia'){
                                  caption += `${suggestion.terms[2].value} `;
                                }
                                if (suggestion.terms[3] && suggestion.terms[3].value !== 'Australia'){
                                  caption += `${suggestion.terms[3].value} `;
                                }*/
                                suggestion.id = suggestion.placeId; // missing key for React
                                
                                return (<div {...getSuggestionItemProps(suggestion, {className})}>{headline}<span>{caption}</span></div>);
                            })}
                          </div>
                        }
                      </div>
                    </>
                  )}
                </PlacesAutocomplete> 
              }

            </div>
            <div className={strModalClass} onClick={() => {this.handleOpenModal(null)}}>
            </div></>
          /*,document.body)}*/}

          <div className="wps-places" onClick={() => {this.handleOpenModal('places')}}>
            <SearchIcon />
            <div className="wpsac-container-mobile">
              {this.state.address || <span className="placeholder">Enter your address</span>}
            </div>
          </div>

          </>
        );
      }
  

      // ====================================================
      // DESKTOP
      // ====================================================
      return (
          <div className="wps-places">
            <SearchIcon />
            <div className="wpsac-container">
              <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect} searchOptions={{location: new window.google.maps.LatLng(-34, 151), radius: 2000 }}>
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <>
                  <TextField
                    onFocus={event => {
                        if (event.target != null){
                          //console.log("onfocus");  
                          event.preventDefault();
                          //event.target.select();
                          const originalAddress = this.state.address;
                          this.setState({address:'',originalAddress});
                        }
                      }
                    }
                    //onBlur={()=>{console.log("blur2");}}

                      //ref={input => this.inputField = input}
                      {...getInputProps({
                        onBlur:this.handleBlur,
                      //auoFocus: false,
                      placeholder:  this.props.placeholder || 'Where are you going?',
                      className: 'wpsac-search-input',
                      InputProps: {disableUnderline: true, spellCheck: false }
                      })}
                  />
                {suggestions.length > 0 && 
                  <div className="wpsac-results">
                    {suggestions.map(suggestion => {
                        const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                        //const headline = `${suggestion.terms[0].value}`
                        //let caption = '';
                        //console.log(suggestion.terms);
                        //caption += suggestion.terms[1] ? `${suggestion.terms[1].value} ` : ''
                        const headline = suggestion.formattedSuggestion.mainText;
                        const caption = suggestion.formattedSuggestion.secondaryText;
                
                        /*if (suggestion.terms[2] && suggestion.terms[2].value !== 'Australia'){
                          caption += `${suggestion.terms[2].value} `;
                        }
                        if (suggestion.terms[3] && suggestion.terms[3].value !== 'Australia'){
                          caption += `${suggestion.terms[3].value} `;
                        }*/
                        suggestion.id = suggestion.placeId; // missing key for React
                        
                        return (<div {...getSuggestionItemProps(suggestion, {className})}>{headline}<span>{caption}</span></div>);
                    })}
                  </div>
                }
                </>
                )}
              </PlacesAutocomplete> 
            </div>
          </div>


      );
    }
}
